import React from "react";
import Form from 'react-bootstrap/Form';
import { normalizeChildDatasetName } from "../utils/attribute_name_utils";
import {getIntegrationDetail} from "../utils/event_handling"
import Load from "./loadAction";
import QualdoDataTable from '../components/bootstrapTable';
import $ from "jquery";

class ViewContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.getIntegrationDetail  = getIntegrationDetail.bind(this);
        this.getPartitionTable     = this.getPartitionTable.bind(this);
        this.SpecifiedDatasetTable = this.SpecifiedDatasetTable.bind(this);
    }

    renderOption(viewDetails){

        let notificationChannel = viewDetails["consume_group_type"]
        let description = viewDetails["config_details"]["description"]
        if ( notificationChannel === "slack" ) {
            return (<> 
                        <div className="col-md-10 offset-md-1">
                            <div className="form-group row">
                                <label className="col-md-5 text-md-right">Slack Channel Name :</label>
                                <div className="col-md-7 text-muted text-break">
                                    <span>{viewDetails["config_details"]["channel_name"]}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-5 text-md-right">Description :</label>
                                <div className="col-md-7 text-muted text-break">
                                    <span>{description !== "" ? description : "-"}</span>
                                </div>
                            </div>
                        </div>
                    </>)
        }
        else if (notificationChannel === "email"){
               return (<>
                            <div className="col-md-10 offset-md-1">
                                <div className="form-group row">
                                    <label className="col-md-5 text-md-right">Emails :</label>
                                    <div className="col-md-7 text-muted text-break">
                                        <span>{viewDetails["config_details"]["email_id"].join(', ')}</span>
                                    </div>
                                </div>
                            </div>
                        </>)
        }
        else if (notificationChannel === "MicrosoftTeams") {
            return (<> 
                        <div className="col-md-10 offset-md-1">
                            <div className="form-group row">
                                <label className="col-md-5 text-md-right">Url :</label>
                                <div className="col-md-7 text-muted text-break">
                                    <span>{viewDetails["config_details"]["url"]}</span>
                                </div>
                            </div>
                        </div>
                    </>)
        }
    }

    getPartitionTable() {
        let inputData =this.state.ex_edit_integration_details.integration_config.partitions;
        let headers=["Dataset Name", "Partition Name", "Partition By", "Partition Identifier"];
        let final_op=[];

        setTimeout(
                () => {
                    $("#ds_non_sticky_table .react-bootstrap-table").removeClass("action-sticky-cell");
                    var el = document.getElementById('partition_cnt');
                    if (el != null) {
                        document.getElementById("partition_cnt").innerHTML = inputData.length
                    }},
                500
        );

        for(let i=0; i < inputData.length; i++) {
            let row=[];
            let d = inputData[i];
            row.push({"value": d["dataset_name"], "type": "td"});
            row.push({"value": d["partition_name"], "type": "td"});
            row.push({"value": d["partition_type"], "type": "td"});
            row.push({"value": d["column_name"], "type": "td"});
            final_op.push(row);
        }
        let table_data ={"headers": headers, "data": final_op};
        return table_data;
    }

    SpecifiedDatasetTable() {
        let inputData =this.state.ex_edit_integration_details.integration_config.table_names;
        let headers=["Dataset Name", "Match Dataset Name", "Incremental Data Identifier", "Start with"];
        let final_op=[];

        setTimeout(
            () => {
                $("#ds_non_sticky_table .react-bootstrap-table").removeClass("action-sticky-cell");
                var el = document.getElementById('spec_cnt');
                if (el != null) {
                    document.getElementById("spec_cnt").innerHTML = inputData.length
                }},
            500
          );


        for(let i=0; i < inputData.length; i++) {
            let row=[];
            let d = inputData[i];
            let isStartingIndexChecked = d["start_index_check_box"] === "true";
            let startWith = d["start_with"] === "" ? d["start_with_query"] : d["start_with"]
            startWith = isStartingIndexChecked ? startWith : "";
            row.push({"value": d["table"], "type": "td"});
            row.push({"value": d["match_exact"]==="match_exact" ? "Exact" : "---", "type": "td"});
            row.push({"value": d["created_time"], "type": "td"});
            row.push({"value": startWith, "type": "td"});
            final_op.push(row);
        }
        let table_data ={"headers": headers, "data": final_op};
        return table_data;
    }

    componentDidMount(){
        if(this.props.component === "Datasource"){
            this.getIntegrationDetail(this.props.viewDetails.integration_id);
        }
    }
    render() {

        let hadPartition = false
        if(this.props.component === "Datasource" && this.state.ex_edit_integration_details!==undefined){
            let integration_config = this.state.ex_edit_integration_details.integration_config
            if( "partitions" in integration_config && integration_config.partitions.length > 0) {
                hadPartition = true
                $(".modal-dialog").addClass("modal-xl")
                $(".modal-dialog").removeClass("modal-lg")
            }
        }
    // (this.state.ex_edit_integration_details === null){
    // }

       let viewDetails = this.props.viewDetails;
       if (this === undefined || this.props.viewDetails === undefined) {
            return;
        }

        let notifications = [];
        let pipelines = [];

        switch(this.props.component) {
            case "Datasource":
                return this.state.ex_edit_integration_details === null || this.state.ex_edit_integration_details === undefined ?
                <div className="text-center col-md-12"><Load/></div>:
     <>
             {/* <div className="row py-3"> */}
    <div className="col-md-12" id="ds_non_sticky_table">
    { this.state.ex_edit_integration_details.integration_config.datasetToBeProcessed!== undefined  && this.state.ex_edit_integration_details.integration_config.datasetToBeProcessed!== "" ?
       (hadPartition && <h5 className="mb-4">"Datasource Info</h5>)
       :""}
       <div className="form-group row">
          <label className="col-md-5 text-md-right mb-0">Environment Name:</label>
          <div className="col-md-7 text-muted"><span>{viewDetails["env_name"]}</span></div>
       </div>
       <div className="form-group row">
          <label className="col-md-5 text-md-right mb-0">Datasource Name:</label>
          <div className="col-md-7 text-muted"><span>{viewDetails["integration_name"]}</span></div>
       </div>
       <div className="form-group row">
          <label className="col-md-5 text-md-right mb-0">Datasource Type:</label>
          <div className="col-md-7 text-muted"><span>{viewDetails["integration_type"]}</span></div>
       </div>
       <div className="form-group row">
          <label className="col-md-5 text-md-right mb-0">Schema Learning Status:</label>
          <div className="col-md-7 text-muted"><span>{viewDetails["discovery_status"]}</span></div>
       </div>
       <div className="form-group row">
          <label className="col-md-5 text-md-right mb-0">Datastore Type:</label>
          <div className="col-md-7 text-muted"><span>{viewDetails["data_store_type"]}</span></div>
       </div>
       <div className="form-group row">
          <label className="col-md-5 text-md-right mb-0">Created Time:</label>
          <div className="col-md-7 text-muted"><span>{viewDetails["created_time"]}</span></div>
       </div>
       { this.state.ex_edit_integration_details.integration_config.datasetToBeProcessed!== undefined  && this.state.ex_edit_integration_details.integration_config.datasetToBeProcessed!== "" ?
       <div className="form-group row">
          <label className="col-md-5 text-md-right mb-0">Dataset to be processed:</label>
          <div className="col-md-7 text-muted">
             <Form.Check
                custom
                checked
                type="checkbox"
                id="all_dataset"
                label={this.state.ex_edit_integration_details.integration_config.datasetToBeProcessed === "allDataset" ? "All Datasets" :"Specified Datasets"}
                />
          </div>
       </div> : ""
         }
     { this.state.ex_edit_integration_details.integration_config.table_names!== undefined  && this.state.ex_edit_integration_details.integration_config.table_names!== "" && this.state.ex_edit_integration_details.integration_config.table_names.length > 0 ?
<div class="row py-3 border-top" id="">
<div class="col-md-12">
<h5 class="mb-4 d-flex">{this.state.ex_edit_integration_details.integration_config.datasetToBeProcessed === "allDataset" ? "All Datasets" :"Specified Datasets"} <span class="badge badge-count badge-secondary ml-1" id="spec_cnt"></span></h5>
<QualdoDataTable
key={"specifieddataset"}
paginationSize={5}
component_name="datasourceView"
data={this.SpecifiedDatasetTable()}
/></div></div>
:""}

{ this.state.ex_edit_integration_details.integration_config.partitions!== undefined  && this.state.ex_edit_integration_details.integration_config.partitions!== "" && this.state.ex_edit_integration_details.integration_config.partitions.length > 0 ?
<div class="row py-3 border-top">
<div class="col-md-12">
<h5 class="mb-4 d-flex">Partitions <span class="badge badge-count badge-secondary ml-1" id="partition_cnt"></span></h5>
<QualdoDataTable
key={"partition"}
paginationSize={5}
component_name="datasourceView"
data={this.getPartitionTable()}
/></div></div>
:""}
    </div>
 </>

 case "Pipeline source":
                return (<><div className="col-md-10 offset-md-1">
                <div className="form-group row">
                    <label className="col-md-5 text-md-right">Environment Name :</label>
                    <div className="col-md-7 text-muted text-break">
                        <span>{viewDetails["env_name"]}</span>
                    </div>
                </div>
            </div>
            <div className="col-md-10 offset-md-1">
                <div className="form-group row">
                    <label className="col-md-5 text-md-right">Pipeline Sources Name:</label>
                    <div className="col-md-7 text-muted text-break">
                        <span>{viewDetails["pipeline_integration_name"]}</span>
                    </div>
                </div>
            </div>
            <div className="col-md-10 offset-md-1">
                <div className="form-group row">
                    <label className="col-md-5 text-md-right">Pipeline Sources Type :</label>
                    <div className="col-md-7 text-muted text-break">
                        <span>{viewDetails["pipeline_type"]}</span>
                    </div>
                </div>
            </div>
            <div className="col-md-10 offset-md-1">
                <div className="form-group row">
                    <label className="col-md-5 text-md-right">Status :</label>
                    <div className="col-md-7 text-muted text-break">
                        <span>{viewDetails["discovery_status"]}</span>
                    </div>
                </div>
            </div>
            <div className="col-md-10 offset-md-1">
                <div className="form-group row">
                    <label className="col-md-5 text-md-right">Created Time :</label>
                    <div className="col-md-7 text-muted text-break">
                        <span>{viewDetails["created_time"]}</span>
                    </div>
                </div>
            </div>
            </>)

           case "Model":
              return (<><div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Model Name :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["model_name"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">ML Tech-stack :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["ml_stack"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Model Type :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["ml_model_type"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Environment Name :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["env_name"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Datasource Name :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["integration_name"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Author :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["author"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Created Time :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["created_time"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Modified Time :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["modified_time"]}</span>
                            </div>
                        </div>
                    </div>
                    </>)
           case "Metric":
              return (<><div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Metric Name :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["metric_name"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Dataset Name :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{normalizeChildDatasetName(viewDetails["data_set_name"])}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Created Time :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["created_time"]}</span>
                            </div>
                        </div>
                    </div>
                    </>)
           case "Notification Channel":
              let notificationChannel = viewDetails["consume_group_type"]
              return (<><div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Notification Name :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["consume_group_name"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Notification Type :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{notificationChannel}</span>
                            </div>
                        </div>
                    </div>
                    {this.renderOption(viewDetails)}
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Created Time :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["created_time"]}</span>
                            </div>
                        </div>
                    </div>

                    </>)
           case "Pipeline Channel":
              return (<><div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Pipeline Channel Name :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["pipeline_trigger_name"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Pipeline Channel Type :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["pipeline_trigger_type"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">URL :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["configuration"]["url"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Created Time :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["created_time"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Modified Time :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["modified_time"]}</span>
                            </div>
                        </div>
                    </div>
                    </>)
           case "Custom Data Quality Alert":
              notifications = viewDetails["consume_group_name"].join(', ')
              pipelines = viewDetails["pipeline_trigger_name"].join(', ')
              return (<><div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Alert Name :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["alert_name"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Alert Period :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["alert_period"] !== null ? viewDetails["alert_period"] : "-"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Alert Type :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["alert_type"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Configured Notifications :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{notifications.length > 0 ? notifications : "-"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Configured Pipeline Channels :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{pipelines.length > 0 ? pipelines : "-"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Alert Condition :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["metrics_definition"]["gates"]}</span>
                            </div>
                        </div>
                    </div>
                    </>)
           case "Custom Model Alert":
              notifications = viewDetails["consume_group_name"].join(', ')
              pipelines = viewDetails["pipeline_trigger_name"].join(', ')
              return (<><div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Alert Name :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["alert_name"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Alert Period :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["alert_period"] !== null ? viewDetails["alert_period"] : "-"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Alert Type :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["alert_type"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Configured Notifications :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{notifications.length > 0 ? notifications : "-"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Configured Pipeline Channels :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{pipelines.length > 0 ? pipelines : "-"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">Alert Condition :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{viewDetails["metrics_definition"]["gates"]}</span>
                            </div>
                        </div>
                    </div>
                    </>)
           default:
              return ''

       }


    }


}

export default ViewContent;
