import { ui_service_ip } from "./ip_details";
import moment from 'moment';
import {
    METRIC_SCALE_MAPPING,
    // metricCategory, 
    // metricMapping 
} from "../utils/constant"
import { call_post_api, call_get_api } from "./event_handling"
import { normalizeChildDatasetName } from "../utils/attribute_name_utils";
import {
    FILL_RATE_RANGE_ONE,
    FILL_RATE_RANGE_TWO,
    FILL_RATE_RANGE_THREE,
    ROW_COUNT_METRIC_CARD,
    OUTLIERS_METRIC_CARD,
    WITH_OUTLIERS_METRIC_CARD,
    WITHOUT_OUTLIERS_METRIC_CARD,

    RECENCY_METRIC_CARD_ONE,
    RECENCY_METRIC_CARD_TWO,
    RECENCY_METRIC_CARD_THREE,
    RECENCY_METRIC_CARD_FOUR,

    CONFORMITY_METRIC_KEYS,
    cardTitlesMap,
    UNIQUENESS_METRIC_CARD_ONE,
    UNIQUENESS_METRIC_CARD_TWO,
    UNIQUENESS_METRIC_CARD_THREE,

    CONSISTENCY_MAPPING,
    CONSISTENCY_KEY,
    RELATIONSHIP_METRIC_KEYS,
} from "../utils/constant"

import {extractDatasetInfoForMonitorListView} from "./common_utils"

import {
    normalizeAttributeName,
} from "./attribute_name_utils";


export function getDateObject(timeStr) {
    if(timeStr === undefined){
        return timeStr
    }
    let dateObj;
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
        dateObj = new Date(timeStr.replace(/\s/, 'T'));
        if(dateObj === "Invalid Date"){
            dateObj = new Date(timeStr);
        }
    } else {
        dateObj = new Date(timeStr);
    }
    return dateObj;
}


let teamID = localStorage.getItem('team_id')
let tenantData = { "tenant_id": teamID };
export function formDatasetsList(datasets) {
    let childDatasets = []
    let parentDatasets = []
    let child = {}
    let childDatasetMap = []
    parentDatasets.push({ "integration_id": "-1", "label": "All", "value": "*" })
    datasets.map((data) => {
        if (data.is_child_dataset === true) {
            data['label'] = normalizeChildDatasetName(data.label)
            childDatasets.push(data)
            if (child[data.parent_data_set_id] === undefined) {
                child[data.parent_data_set_id] = [{
                    "label": "All", "value": "*",
                    is_child_dataset: true,
                    parent_data_set_id: data.parent_data_set_id
                }]
            }
            child[data.parent_data_set_id].push(data)
            if (childDatasetMap[data.parent_data_set_id] === undefined) {
                childDatasetMap[data.parent_data_set_id] = []
            }
            childDatasetMap[data.parent_data_set_id] = child[data.parent_data_set_id].concat(data)


        }
        else {
            parentDatasets.push(data)
        }
        return data;
    })
    let finalList = []
    parentDatasets.map((data) => {
        if (child[String(data.value)] !== undefined) {
            let partitionsLength = child[String(data.value)].length - 1
            data['label'] = data.label + '( ' + String(partitionsLength) + ' Partitions)'
            data['children'] = child[String(data.value)]
            finalList.push(data)
        }
        else {
            finalList.push(data)
        }
        return data;
    }
    )
    finalList = { "childDatasetMap": child, "parentDatasets": finalList }
    return finalList
}

export function formAttributesDict(attributes) {
    let finalDict = {}
    attributes.map((data) => {
        let id = data.dataset_id === undefined ? data.data_set_id : data.dataset_id
        if (finalDict[id] === undefined) {
            finalDict[id] = []
            // finalDict[id] = [{
            //     "label": "All", "value": "*","data_set_id":id,"dataset_id":id
            // }]
        }

        data['dataset_id'] = id
        finalDict[id].push(data)
        return data;
    })
    return finalDict
}

export function getMappedMetadatas(){

    call_get_api(ui_service_ip, "metadata/mapping/latest",{"tenant_id":localStorage.getItem('team_id')}).then(
        (result) => {
            let mappedDatasetInfo = extractDatasetInfoForMonitorListView(result.meta_data);
            this.setState({metaData:mappedDatasetInfo, mappedDatasetInfo:mappedDatasetInfo, showLoader: false})
        }        
    )
}

export function getFollowAttributes() {
    let endPoint = "follow_attributes";
    call_get_api(ui_service_ip, endPoint, { "tenant_id": localStorage.getItem('team_id') }).then(
      (result) => {
        if (result !== undefined && result !== null) {
            this.setState({follow_attributes:result.follow_attributes})
        }
      }
    );
  }

// export function getMetricsInfo() {
//     const endPoint = "alert_applicable_metrics";
//     call_get_api(ui_service_ip, endPoint,{"tenant_id":localStorage.getItem('team_id')}).then(
//         (result) => {
//             if (result === undefined || result === null) {
//                 // const error = `Unable to fetch metadata details for tenant: ${tenantID}`;
//                 // postUIlogs(error);
//                 return;
//             }
//             let metadataDetails = result.metadata_details
//             this.setState({metrics_info:metadataDetails})
//         }
//     );
// }


export function getMetaDatas(tab = null, maxLastProfilingTime = null) {
    let endPoint = "metadata_mapping";
    let data = { "tenant_id": localStorage.getItem('team_id') }

    let api2 = false
    call_get_api(ui_service_ip, endPoint, data).then(
        (result) => {
            if (result !== undefined && result !== null && result.data !== undefined) {
                let filterData = {}
                filterData["environmentOptions"] = result.data['environments']
                filterData["datasourceOptions"] = result.data['integrations']
                filterData["datasetsOptions"] = result.data['datasets']
                filterData["attributesOptions"] = result.data['attributes']
                let childDatasets = []

                // TODO Need to append values in existingList without this iteration
                let existingList = []
                if(result.data['environments'].length > 0 && result.data['integrations'].length > 0 && result.data['datasets'].length > 0 && result.data['attributes'].length > 0 )
                {
                    result.data['environments'].map((data) =>
                    existingList = existingList.concat(data.value)
                    )
                    result.data['integrations'].map((data) =>
                        existingList = existingList.concat(data.value)
                    )
                    result.data['datasets'].map((data) =>
                        existingList = existingList.concat(data.value)
                    )
                    result.data['attributes'].map((data) =>
                        existingList = existingList.concat(data.value)
                    )

                    if(result.data['integrations'].length === 1 && tab === null){
                        // Checking the In-progress status for the First Configured Datasource
                        const teamId = localStorage.getItem('team_id');
                        let data = {"tenant_id":teamId, "integration_id": result.data['integrations'][0].value}
                        let endPoint = "dataset_progress_info";
                        call_get_api(ui_service_ip, endPoint,data).then((response) => {
                            if (response === undefined) {
                                this.setState({errorOccurred: true});
                            } else if (response !== null && response.code === 200) {
                                api2 = true
                                this.setState({progressStatus: response["progress_percentage"]});
                            }
                            if(response["progress_percentage"] !== 100){
                                this.setState({inProgress:true, showLoader:false})
                            }
                            else{
                                this.setState({inProgress:false, showLoader:false})
                            }
                        });
                    }
                    else if (result.data['integrations'].length === 0){
                        api2=true
                        this.setState({noDataFound:true})
                    }
                    else{
                        api2=true
                        this.setState({inProgress:false, noDataFound:false})
                    }
                }
                
                this.setState({ existingList: existingList })
                // TODO Need to append values in existingList without this iteration


                let integrationId;
                let preSeletedDataset, preSeletedAttribute;

                if (tab === 'attribute') {
                    // SetUp Datasource Options for environment
                    let mappedDatasources = {}
                    filterData['datasourceOptions'].map((data) => {
                        if (mappedDatasources[data.env_id] === undefined) {
                            mappedDatasources[data.env_id] = []
                        }
                        mappedDatasources[data.env_id].push(data)
                        return data;
                    })

                    // Map datasets for the datasource
                    let mappedDatasets = {}
                    filterData['datasetsOptions'].map((data) => {
                        if (mappedDatasets[data.integration_id] === undefined) {
                            mappedDatasets[data.integration_id] = []
                        }
                        mappedDatasets[data.integration_id].push(data);
                        return data;
                    })
                    // Map Attributes for the datasets
                    let mappedAttributes = {}
                    filterData['attributesOptions'].map((data) => {
                        let id = data.dataset_id === undefined ? data.data_set_id : data.dataset_id
                        data['dataset_id'] = id
                        if (mappedAttributes[data.dataset_id] === undefined) {
                            mappedAttributes[data.dataset_id] = []
                        }

                        if (data.dataset_id !== undefined) {
                            mappedAttributes[data.dataset_id].push(data)
                        }
                        return data;
                    })

                    this.setState({
                        environmentOptions: result.data['environments'],
                        dataSourceOptions: mappedDatasources,
                        dataSetsOptions: mappedDatasets,
                        attributesOptions: mappedAttributes,
                    })
                    this.setState({ processTiming: maxLastProfilingTime })

                    integrationId = maxLastProfilingTime.integration_id
                    // let environment = this.state.environmentOptions
                    let datasource = this.state.dataSourceOptions
                    let dataset = this.state.dataSetsOptions
                    let attribute = this.state.attributesOptions
                    let preSelectedEnvironment = []
                    result.data['integrations'].map((data) => {
                        if (data.value !== null && data.value !== undefined && data.value === Number(integrationId)) {
                            preSelectedEnvironment = data
                        }
                        return data;

                    })

                    // let chipData = []
                    // eslint-disable-next-line
                    this.setState({ preSelectedEnvironment, preSelectedEnvironment })
                    let preSelectedDataSource = datasource[preSelectedEnvironment.value]
                    // eslint-disable-next-line
                    this.setState({ preSelectedDataSource, preSelectedDataSource })


                    preSeletedDataset = dataset[integrationId][0]
                    this.setState({ preSelectedDatasets: dataset[integrationId] })

                    preSeletedAttribute = attribute[preSeletedDataset.value][0]
                }

                for (let level in result.data) {
                    // eslint-disable-next-line
                    result.data[level].filter((data) => {
                        if (data.is_child_dataset !== undefined && data.is_child_dataset === true) {
                            childDatasets.push(data.value)
                        }
                        return data;
                    })
                }
                let datasets = result.data['datasets']

                let formDatasetOption = formDatasetsList(datasets)
                let attributesList = filterData["attributesOptions"]
                let formAttributeOption = formAttributesDict(attributesList)

                filterData["childDatasets"] = childDatasets
                filterData["childDatasetMap"] = formDatasetOption['childDatasetMap']
                filterData["parentDatasets"] = formDatasetOption['parentDatasets']
                filterData["attributesOptions"] = formAttributeOption



                if (tab === 'attribute') {
                    //Need to add default values
                    let datasourceOptions = filterData["datasourceOptions"].filter((data) =>
                        data.value === integrationId
                    )

                    let attr_datasource_value;
                    let attr_datasource_id;

                    if (datasourceOptions.length > 0) {
                        attr_datasource_value = datasourceOptions[0]["label"];
                        attr_datasource_id = datasourceOptions[0]["value"];
                    }

                    
                    let datasetsOptions = filterData["datasetsOptions"].filter((data) =>
                        parseInt(data.value) === parseInt(preSeletedDataset.value)
                    )

                    let attr_dataset_value;
                    let attr_dataset_id;
                    if (datasetsOptions.length > 0) {
                        attr_dataset_value = datasetsOptions[0]["label"];
                        attr_dataset_id = datasetsOptions[0]["value"];
                    }
                    // 
                    this.attributeProfileFilterSubmit(preSeletedDataset.value, preSeletedAttribute.value)

                    let attributesOptions = [];
                    if (filterData["attributesOptions"][parseInt(preSeletedDataset.value)] !== undefined) {
                        attributesOptions = filterData["attributesOptions"][preSeletedDataset.value].filter((data) =>
                            data.value === preSeletedAttribute.value
                        )
                    }

                    let attr_attribute_value;
                    let attr_attribute_id;
                    if (attributesOptions.length > 0) {
                        attr_attribute_value = attributesOptions[0]["label"];
                        attr_attribute_id = attributesOptions[0]["value"];
                    }


                    let dataset_data = filterData["datasetsOptions"].filter((data) =>
                                parseInt(data.integration_id) === parseInt(preSeletedDataset.integration_id) || data.integration_id === "-1"
                        )


                    let attribute_data = filterData["attributesOptions"][parseInt(preSeletedDataset.value)].filter((data) =>
                        parseInt(data.dataset_id) === parseInt(preSeletedDataset.value)
                    )
                    let sortedDatasetsOptions = dataset_data.sort((a, b) => a.value - b.value)

                    this.setState({
                        attr_filter_datset_option: sortedDatasetsOptions,
                        attr_filter_attr_option: attribute_data,
                        attr_datasource_value: attr_datasource_value,
                        attr_datasource_id: attr_datasource_id,
                        attr_dataset_value: attr_dataset_value,
                        attr_dataset_id: attr_dataset_id,
                        attr_attribute_value: attr_attribute_value,
                        attr_attribute_id: attr_attribute_id
                    });

                }

                let showLoader = true
                if(api2 === true){
                    showLoader = false
                }
                this.setState({
                    initialData: { "value": filterData, "label": "tempvalue" }, filterData: filterData, showLoader:showLoader 
                })
                }
        }
    );
}

/*function numAvg(num) {
    var total = 0;
    for (var i = 0; i < num.length; i++) {
        total += num[i];
    }
    return total / num.length;
}*/


function getAggregatedTotalData(totalDatas, d, existingList) {
    /*if (existingList !== undefined && existingList !== null && existingList.includes(Number(d.dataset_id))) {
        let metricValue;
        let datasetLevel = totalDatas[d.metric_type]["dataset_level"]
        if (datasetLevel === null || datasetLevel === undefined) {
            totalDatas[d.metric_type]["dataset_level"] = {}
        }
        let metricData = totalDatas[d.metric_type]["dataset_level"][d.dataset_id]
        if (metricData === null || metricData === undefined) {
            d["count"] = 1
            d["metric_list"] = [d["metric_value"]]
            metricValue = d["metric_value"]
            d["metrics_threshold_list"] = [d["metrics_threshold"]]
            totalDatas[d.metric_type]["dataset_level"][d.dataset_id] = d
        } else {
            let curr_count = metricData["count"] + 1
            metricData["metric_list"].push(d["metric_value"])
            metricData["metrics_threshold_list"].push(d["metrics_threshold"])
            metricValue = numAvg(metricData["metric_list"])
            metricData["metric_value"] = metricValue
            metricData["metrics_threshold"] = numAvg(metricData["metrics_threshold_list"])
            metricData["has_error"] = d["has_error"]
            metricData["count"] = curr_count
            totalDatas[d.metric_type]["dataset_level"][d.dataset_id] = metricData
        }
    }*/
    let datasetLevel = totalDatas[d.metric_type]["dataset_level"]
    if (datasetLevel === null || datasetLevel === undefined) {
        totalDatas[d.metric_type]["dataset_level"] = {}
    }
    totalDatas[d.metric_type]["dataset_level"][d.dataset_id] = d
    return totalDatas
}

function getAttrAggregatedTotalData(totalDatas, d, existingList) {
    /*if (existingList !== undefined && existingList !== null && existingList.includes(Number(d.dataset_id))) {
        let metricValue;
        let datasetLevel = totalDatas[d.metric_type]["attr_level"]
        if (datasetLevel === null || datasetLevel === undefined) {
            totalDatas[d.metric_type]["attr_level"] = {}
        }
        let metricData = totalDatas[d.metric_type]["attr_level"][d.attribute_id]
        if (metricData === null || metricData === undefined) {
            d["count"] = 1
            d["metric_list"] = [d["metric_value"]]
            metricValue = d["metric_value"]
            d["metrics_threshold_list"] = [d["metrics_threshold"]]
            totalDatas[d.metric_type]["attr_level"][d.attribute_id] = d
        } else {
            let curr_count = metricData["count"] + 1
            metricData["metric_list"].push(d["metric_value"])
            metricData["metrics_threshold_list"].push(d["metrics_threshold"])
            metricValue = numAvg(metricData["metric_list"])
            metricData["metric_value"] = metricValue
            metricData["metrics_threshold"] = numAvg(metricData["metrics_threshold_list"])
            metricData["has_error"] = d["has_error"]
            metricData["count"] = curr_count
            totalDatas[d.metric_type]["attr_level"][d.attribute_id] = metricData
        }
    }*/
    let datasetLevel = totalDatas[d.metric_type]["attr_level"]
    if (datasetLevel === null || datasetLevel === undefined) {
        totalDatas[d.metric_type]["attr_level"] = {}
    }
    totalDatas[d.metric_type]["attr_level"][d.attribute_id] = d
    return totalDatas
}


function getAggregatedData(totalDatas) {
    let attrLevelDetails = {}
    let datasetLevelDetails = {}
    let metrics = []

    for (const [key, value] of Object.entries(totalDatas)) {
        metrics.push(key)
        let dataset_level = value["dataset_level"];
        let attr_level = value["attr_level"]
        if (dataset_level !== null && dataset_level !== undefined) {
            for (const [dKey, dValue] of Object.entries(dataset_level)) {
                let data = {}
                data[dValue["metric_type"]] = dValue["metric_value"]
                data["error"] = dValue["has_error"]
                if (datasetLevelDetails[dKey] === null || datasetLevelDetails[dKey] === undefined) {
                    datasetLevelDetails[dKey] = []
                }
                datasetLevelDetails[dKey].push(data)
            }
        }
        if (attr_level !== null && attr_level !== undefined) {
            for (const [aKey, aValue] of Object.entries(attr_level)) {
                let data = {}
                data[aValue["metric_type"]] = aValue["metric_value"]
                data["error"] = aValue["has_error"]
                if (attrLevelDetails[aKey] === null || attrLevelDetails[aKey] === undefined) {
                    attrLevelDetails[aKey] = []
                }
                attrLevelDetails[aKey].push(data)
            }
        }
    }
    return { "dataset_level": datasetLevelDetails, "attr_level": attrLevelDetails, "metric_list" : metrics }
}

export function formProfileData(profile_details, existingList = null) {
    let totalDatas = {}
    // let bk = 0
    let allAttributes = []
    let allDatasets = []
    // let overAlldata = []
    for (let i in profile_details) {

        let datasetLevel = profile_details[i]['dataset_level']
        let attributeLevel = profile_details[i]['attribute_level']
        for (let d of datasetLevel) {
            if (totalDatas[d.metric_type] === undefined) {
                totalDatas[d.metric_type] = {}
            }
            // allDatasets.push(totalDatas)
            totalDatas = getAggregatedTotalData(totalDatas, d, existingList)
            //totalDatas[d.metric_type][d.dataset_id] = d
        }

        for (let key in attributeLevel) {
            if (attributeLevel[key] === null) {
                attributeLevel[key] = {}
            }
        }
        // let atb = {}

        // Combined both DatasetLevel and attribute level
        for (let key in attributeLevel) {
            for (let a of attributeLevel[key]) {
                if (totalDatas[a.metric_type] === undefined) {
                    totalDatas[a.metric_type] = {}
                }
                // allAttributes.push(a)
                totalDatas = getAttrAggregatedTotalData(totalDatas, a, existingList)
                //totalDatas[a.metric_type][a.attribute_id] = a
            }
        }
    }
    let aggDatas = getAggregatedData(totalDatas)
    let allDatas = {
        'totalDatas': totalDatas, 'allDatasets': allDatasets, 'allAttributes': allAttributes,
        'aggDatas': aggDatas
    }
    return allDatas
}

export function formTableData(data, tab, filterDatas = null) {


    let headers = ["DataSource", "DataSet", "Attribute Name", "Metric", "Metric Value"]
    let tableRows = [];

    let relationShipTableRows = {
        "headers": headers,
        "data": tableRows
    };

    if (data === undefined || data === null) {
        return data;
    }
    let cardData = {}


    let totalRows = 0;
    let outlierCount = 0;
    let NonOutlierCount = 0;
    let outlierIndex = 0

    let recencyOne = 0;
    let recencyTwo = 0;
    let recencyThree = 0;
    let recencyFour = 0;


    let datasetWithDuplicates = 0;
    let duplicateAttributeCount = 0;
    let duplicateRows = 0;
    let overAllDatas = []
    for (let metric in data) {

        let recencyIndex = 0;

        let index = 0;

        let rangeOne = 0;
        let rangeTwo = 0;
        let rangeThree = 0;

        let rangeOneIndex = 0;
        let rangeTwoIndex = 0;
        let rangeThreeIndex = 0;

        if (tab === 2 && metric === "Fill Rate") {
            cardData[FILL_RATE_RANGE_ONE] = "NA"
            cardData[FILL_RATE_RANGE_TWO] = "NA"
            cardData[FILL_RATE_RANGE_THREE] = "NA"
            cardData[FILL_RATE_RANGE_THREE] = "NA"
        }
        else if (tab === 2 && metric === "Row Count") {
            cardData[ROW_COUNT_METRIC_CARD] = "NA"
        }
        else if (tab === 3 && metric === "Data Outlier") {
            cardData[OUTLIERS_METRIC_CARD] = "NA"
            cardData[WITH_OUTLIERS_METRIC_CARD] = "NA"
            cardData[WITHOUT_OUTLIERS_METRIC_CARD] = "NA"
        }
        else if (tab === 4 && metric === "Recency") {
            cardData[RECENCY_METRIC_CARD_ONE] = "NA"
            cardData[RECENCY_METRIC_CARD_TWO] = "NA"
            cardData[RECENCY_METRIC_CARD_THREE] = "NA"
            cardData[RECENCY_METRIC_CARD_FOUR] = "NA"

        }
        else if (CONFORMITY_METRIC_KEYS.includes(metric)) {
            cardData[cardTitlesMap[metric]] = "NA"
        }
        else if (tab === 7 && metric === "Unique Values") {
            cardData[UNIQUENESS_METRIC_CARD_ONE] = "NA"
            cardData[UNIQUENESS_METRIC_CARD_TWO] = "NA"
            cardData[UNIQUENESS_METRIC_CARD_THREE] = "NA"
        }



        for (let level in data[metric]) {
            let metricValue = 0;

            index = 0
            let totalRangeIndex = 0
            let totalRecencyIndex = 0
            let totalOutliers = 0
            let dataValues = data[metric][level]

            /**Filter values under Metric level iteration for card data */
            for (let key in dataValues) {
                if (level === "dataset_level") {
                    if (filterDatas !== null && filterDatas['fiteredDatseset'] !== undefined) {
                        if (filterDatas['fiteredDatseset'].includes(Number(key))) {
                            // continue
                        }
                        else {
                            continue
                        }
                    }
                }
                if (level === "attr_level") {
                    if (filterDatas !== null && filterDatas['fitlerdAttr'] !== undefined && filterDatas['fitlerdAttr'].length > 0) {
                        if (filterDatas['fitlerdAttr'].includes(Number(key))) {
                            // continue
                        }
                        else {
                            continue
                        }
                    }
                }
            }

            for (let key in dataValues) {
                let values = dataValues[key]

                /** To handle values like -1 will be skipping the iteration */

                if (values.metric_value < 0) {
                    // This Will Restrict storing NA or -1 Values in Table and attribute/ datasets with this value will be skipped
                    continue;
                }

                overAllDatas = overAllDatas.concat(values)

                if (filterDatas !== null && Object.keys(filterDatas).length > 0) {
                    // if (level !== "filterdDatsource" && level !== "fitlerdDate" && filterDatas[level].length > 0) {

                    // }
                    if (values.dataset_id !== undefined && filterDatas['fiteredDatseset'] !== undefined) {
                        /**Applying Filter for Dataset level */
                        if (filterDatas['fiteredDatseset'].includes(values.dataset_id)) {
                            // continue;
                        }
                        else {
                            // skip if current data is not in filtered value
                            continue;
                        }
                    }
                    if (values.attribute_id !== undefined && filterDatas['fitlerdAttr'] !== undefined && filterDatas['fitlerdAttr'].length > 0) {
                        /**Applying Filter for Attribute level */

                        if (filterDatas['fitlerdAttr'].includes(values.attribute_id)) {
                            // continue;
                        }
                        else {
                            // skip if current data is not in filtered value
                            continue;
                        }
                    }
                }

                // Skip Attribute level computation for Metrics : Timeliness && Recency 
                if (values.attribute_id !== undefined && (values.metric_type === "Data Timeliness" || values.metric_type === "Recency")) {
                    continue
                }

                let rowData = [];
                let sufix = METRIC_SCALE_MAPPING[metric]
                let metric_value = values.metric_value


                /** Completeness Tab Cards computation */

                /**Fill Rate metrics */
                if (metric === "Fill Rate" && tab === 2 && level === "attr_level") {
                    if (metric_value < 25) {
                        rangeOne = rangeOne + metric_value
                        rangeOneIndex = rangeOneIndex + 1
                    }
                    else if (metric_value >= 25 && metric_value <= 75) {
                        rangeTwo = rangeTwo + metric_value
                        rangeTwoIndex = rangeTwoIndex + 1
                    }
                    else if (metric_value > 75) {
                        rangeThree = rangeThree + metric_value
                        rangeThreeIndex = rangeThreeIndex + 1
                    }
                    totalRangeIndex = totalRangeIndex + 1
                }
                /**Row Count metrics */
                if (metric === "Row Count") {
                    totalRows = totalRows + metric_value
                }
                /** Completeness Tab Cards computation Ends */

                /**Accuracy Card Tab value computation Starts */
                // Totally Compute values for three cards

                if (values.metric_type === "Data Outlier" && tab === 3 && level === "attr_level") {
                    outlierIndex = outlierIndex + 1
                    if (metric_value === 0) {
                        NonOutlierCount = NonOutlierCount + 1
                    }
                    else if (metric_value > 0) {
                        outlierCount = outlierCount + 1
                    }
                    totalOutliers = totalOutliers + 1
                }

                /**Accuracy Card Tab value computation Ends */


                /** Timeliness Tab Card value computation */
                //Four Cards value computations
                if (values.metric_type === "Recency") {
                    if (metric_value < 1) {
                        recencyOne = recencyOne + 1
                    }
                    if (metric_value > 0 && metric_value < 7) {
                        recencyTwo = recencyTwo + 1
                    }
                    if (metric_value > 7 && metric_value < 30) {
                        recencyThree = recencyThree + 1
                    }
                    if (metric_value > 30) {
                        recencyFour = recencyFour + 1
                    }
                    totalRecencyIndex = totalRecencyIndex + 1
                }

                if (values.metric_type === "Data Timeliness" && level === "dataset_level") {
                    recencyIndex = recencyIndex + 1
                }
                /** Timeliness Tab Card value computation End */

                /**Uniqueness Tab Card value computation */
                if (metric === "Unique Values") {
                    if (values.attribute_id !== undefined && values.metric_value !== values.row_count) {
                        if(values.has_duplicates===true){
                            duplicateAttributeCount = duplicateAttributeCount + 1
                        }
                    }
                    else if (values.attribute_id === undefined && values.metric_value !== values.row_count) {
                        datasetWithDuplicates = datasetWithDuplicates + 1
                        if(values.has_duplicates===true){
                            duplicateRows = duplicateRows + values.duplicate_rows
                        }
                    }
                }

                if(metric === "Unique Values" && values.attribute_id === undefined){
                    values['metric_type'] = "Unique Rows"                    
                }
                /**Uniqueness Tab Card value computation End */


                /** */
                let datasetName = "-";
                if (values.attribute_id !== undefined) {
                    datasetName = values.dataset_name
                }
                else {
                    datasetName = values.name
                }

                /**Handling floot value to decimal for whole Number values */
                if (metric_value % 1 !== 0) {
                    metric_value = String(metric_value.toFixed(2)) + " " + sufix
                }
                else {
                    metric_value = String(metric_value) + " " + sufix
                }

                rowData.push({ "value": values.integration_name, "type": "td" });
                rowData.push({ "value": normalizeChildDatasetName(datasetName), "type": "td" });
                rowData.push({ "value": values.attribute_id !== undefined ? normalizeAttributeName(values.name) : "-", "type": "td" });
                rowData.push({ "value": values.metric_type, "type": "td" });
                rowData.push({ "value": metric_value, "type": "td" });

                tableRows.push(rowData);


                // Allowed only for Attribute level and Recency level
                if(level === "attr_level" || metric === "Data Timeliness" || metric === "recency"){
                    index = index + 1;
                    metricValue = metricValue + values.metric_value
                }

            }

            if (metric === "Data Timeliness") {
                index = recencyIndex
            }


            /**TODO: Can be added these below conditions into separate function */

            /**Total Number of Condition ===> 7 */
            
            /**Card Value computation for Relationship Tab */
            // No of Cards ==> 5
            
            if (RELATIONSHIP_METRIC_KEYS.includes(metric)) {
                if (cardData[metric] !== undefined && metricValue > 0) {
                    let current_value = metricValue / index
                    cardData[metric] = (current_value + Number(cardData[metric])) / 2
                }
                else {
                    if (metricValue >= 0 && index !== 0) {
                        let new_value = metricValue / index
                        cardData[metric] = new_value
                    }
                }
            }

            /**Completeness Tab Adding values to the Cards    */
            // No of Cards ==> 4
            else if (metric === "Fill Rate" && tab === 2 && level === "attr_level") {
                cardData[FILL_RATE_RANGE_ONE] = rangeOneIndex / totalRangeIndex * 100
                cardData[FILL_RATE_RANGE_TWO] = rangeTwoIndex / totalRangeIndex * 100
                cardData[FILL_RATE_RANGE_THREE] = rangeThreeIndex / totalRangeIndex * 100
            } 
            else if (metric === "Row Count") {
                cardData[ROW_COUNT_METRIC_CARD] = totalRows
            }
            /**Completeness Tab Adding values to the Cards Ends    */


            /** Accuracy Tab Adding card values */
            // No of Cards ==> 3
            else if (metric === "Data Outlier" && level === "attr_level") {
                cardData[OUTLIERS_METRIC_CARD] = metricValue > 0 ? metricValue / index : metricValue   //(outlierCount + NonOutlierCount) / totalOutliers
                cardData[WITH_OUTLIERS_METRIC_CARD] = outlierCount
                cardData[WITHOUT_OUTLIERS_METRIC_CARD] = NonOutlierCount
            }
            /** Accuracy Tab Adding card values Ends */


            /**Timeliness Tab Adding card Values */
            // No of Cards ==> 4
            else if (metric === "Recency") {
                // if (metricValue > 0) {
                cardData[RECENCY_METRIC_CARD_ONE] = (recencyOne > 0 ? recencyOne / totalRecencyIndex * 100 : recencyOne)
                cardData[RECENCY_METRIC_CARD_TWO] = (recencyTwo > 0 ? recencyTwo / totalRecencyIndex * 100 : recencyTwo)
                cardData[RECENCY_METRIC_CARD_THREE] = (recencyThree > 0 ? recencyThree / totalRecencyIndex * 100 : recencyThree)
                cardData[RECENCY_METRIC_CARD_FOUR] = (recencyFour > 0 ? recencyFour / totalRecencyIndex * 100 : recencyFour)
                // }
            }
            /**Timeliness Tab Adding card Values Ends */


            /**Conformity Tabs Adding cards values */
            // No of Cards ==> 9
            else if (CONFORMITY_METRIC_KEYS.includes(metric) && level === "attr_level") {
                if (metricValue >= 0 && metricValue !== -1) {
                    metricValue = metricValue > 0 ? metricValue / index : metricValue
                    cardData[cardTitlesMap[metric]] = metricValue
                }
            }
            /**Conformity Tabs Adding cards values Ends*/


            /**Uniqueness Tabs Adding cards values */
            // No of Cards ==> 3
            else if (metric === "Unique Values" && tab === 7 && level === "attr_level") { //&& tab === 7 && level !== "attr_level") {
                cardData[UNIQUENESS_METRIC_CARD_ONE] = datasetWithDuplicates
                cardData[UNIQUENESS_METRIC_CARD_TWO] = duplicateAttributeCount
                cardData[UNIQUENESS_METRIC_CARD_THREE] = duplicateRows
            
            }
            /**Accuracy Tabs Adding cards values Ends*/


            /**Consistency Tabs Adding cards values */
            // No of Cards ==> 3            
            else if (CONSISTENCY_KEY.includes(metric) && tab === 6 && level === "attr_level") {
                metricValue = metricValue / index
                cardData[CONSISTENCY_MAPPING[metric]] = metricValue
            }
            /**Consistency Tabs Adding cards values Ends*/


            else if(level === "attr_level") {
                if (cardData[metric] !== undefined && metricValue > 0) {
                    let current_value = metricValue / index
                    cardData[metric] = (current_value + Number(cardData[metric])) / 2
                }
                else {
                    if (metricValue >= 0 && index !== 0) {
                        metricValue = metricValue / index
                        cardData[metric] = metricValue
                    }
                }
            }
            /**TODO: Can be added these Above conditions into separate function */

        }
    }
    for (let metric in cardData) {
        let value = cardData[metric]
        /**Add suffix */
        if (value !== "NA") {
            if (value % 1 !== 0) {
                value = String(value.toFixed(2)) + " " + METRIC_SCALE_MAPPING[metric]
            }
            else {
                value = String(value) + " " + METRIC_SCALE_MAPPING[metric]
            }
            cardData[metric] = value
        }
    }
    let relationShipData = { tableData: relationShipTableRows, cardData: cardData, chart_data: overAllDatas }
    return relationShipData;
}



export function getDatasForTab(params, filteredDatas = null, dataFilter = null) {
    let endPoint = "profile/realtime";
    params["tenant_id"] = localStorage.getItem('team_id');
    params["start_date"] = moment(params["start_date"]).format("Y/MM/D");
    params["end_date"] = moment(params["end_date"]).format("Y/MM/D");
    
    let data = params
    call_get_api(ui_service_ip, endPoint, data).then(
        (result) => {
            if (result !== undefined && result !== null && result.response !== undefined) {
                
                let seletedDatasourceId = ""
                let seletedDatasourceName = ""
                let last_processed_time = ""

                if (result.integration_id !== undefined && result.integration_id !== null){
                    seletedDatasourceId = result.integration_id
                    seletedDatasourceName = result.integration_name
                    last_processed_time = result.last_processed_time
                } 
                
                if (Object.keys(result.response).length === 0 || Object.keys(result.response.profile_details).length === 0) {
                    this.setState({
                        tableData: {
                            "headers": ["DataSource", "DataSet", "Attribute Name", "Metric", "Metric Value"],
                            "data": []
                        },
                        newCardData: [],
                        relationShipchartData: [],

                        datasetLevelChart: [],
                        attributeLevelChart: [],

                        chartDatas: [],

                        showLoader: false,
                        startDate: params["start_date"],
                        endDate: params["end_date"],
                        // filteredValues: {},
                        seletedDatasourceId : seletedDatasourceId,
                        seletedDatasourceName : seletedDatasourceName,
                        last_processed_time : last_processed_time
                    })
                    return
                }

                let profile_details = result.response.profile_details

                let existingList = this.state.existingList;
                let profileData = formProfileData(profile_details, existingList);
                let relationShipData = []

                if (dataFilter === true) {
                    relationShipData = formTableData(profileData['totalDatas'], params["tab_type"], filteredDatas)
                }
                else {
                    relationShipData = formTableData(profileData['totalDatas'], params["tab_type"])
                }

                let datasetLevelChart = []
                let attributeLevelChart = []
                if (result.response.chart_details !== undefined) {
                    result.response.chart_details['dataset_level'].map((data) => {
                        if (this.state.existingList.includes(Number(data.dataSetId))) {
                            datasetLevelChart = datasetLevelChart.concat(data)
                        }
                        return datasetLevelChart
                    }
                    )
                    result.response.chart_details['attribute_level'].map((data) => {
                        if (this.state.existingList.includes(Number(data.dataSetId))) {
                            attributeLevelChart = attributeLevelChart.concat(data)
                        }
                        return attributeLevelChart
                    }
                    )
                }

                this.setState({
                    tableData: relationShipData['tableData'],
                    newCardData: relationShipData['cardData'],
                    datasetLevelChart: datasetLevelChart,
                    attributeLevelChart: attributeLevelChart,
                    chartDatas: relationShipData['chart_data'],
                    showLoader: false,
                    profileData: profileData,
                    seletedDatasourceId: seletedDatasourceId,
                    seletedDatasourceName: seletedDatasourceName,
                    chartErrors: profileData['aggDatas'],
                    // filteredValues: {},
                    filteredDatas:filteredDatas,
                    startDate: params["start_date"],
                    endDate: params["end_date"],
                    last_processed_time:last_processed_time
                })
            }
        }
    );
}

export function profileTabPrefetch(filteredDatas, tab) {
    let endPoint = "profile/prefetch";
    let data = { "tenant_id": localStorage.getItem('team_id'), "tab_type": tab }
    call_get_api(ui_service_ip, endPoint, data).then(
        (result) => {
            if (result !== undefined && result !== null && result.response !== undefined) {
                let seletedDatasourceId = ""
                let seletedDatasourceName = ""
                let last_processed_time = ""

                if (result.integration_id !== undefined && result.integration_id !== null){
                    seletedDatasourceId = result.integration_id
                    seletedDatasourceName = result.integration_name
                    last_processed_time = result.last_processed_time
                } 

                if (Object.keys(result.response).length === 0 || Object.keys(result.response.profile_details).length === 0) {
                    this.setState({
                        tableData: {
                            "headers": ["DataSource", "DataSet", "Attribute Name", "Metric", "Metric Value"],
                            "data": []
                        },
                        newCardData: [],
                        relationShipchartData: [],
                        datasetLevelChart: [],
                        attributeLevelChart: [],
                        chartDatas: [],
                        showLoader: false,
                        seletedDatasourceId: seletedDatasourceId,
                        seletedDatasourceName: seletedDatasourceName,
                        last_processed_time : last_processed_time
                    })
                    return
                }

                let profile_details = result.response.profile_details
                let existingList = this.state.existingList;
                let profileData = formProfileData(profile_details, existingList);
                let relationShipData = []

                if (Object.keys(filteredDatas).length>0) {
                    relationShipData = formTableData(profileData['totalDatas'], tab, filteredDatas)
                }
                else {
                    relationShipData = formTableData(profileData['totalDatas'], tab)
                }
                let datasetLevelChart = []
                let attributeLevelChart = []
                if (result.response.chart_details !== undefined) {
                    result.response.chart_details['dataset_level'].map((data) => {
                        if (this.state.existingList.includes(Number(data.dataSetId))) {
                            datasetLevelChart = datasetLevelChart.concat(data)
                        }
                        return datasetLevelChart
                    }
                    )
                    result.response.chart_details['attribute_level'].map((data) => {
                        if (this.state.existingList.includes(Number(data.dataSetId))) {
                            attributeLevelChart = attributeLevelChart.concat(data)
                        }
                        return attributeLevelChart
                    }
                    )
                }
                this.setState({
                    tableData: relationShipData['tableData'],
                    newCardData: relationShipData['cardData'],
                    datasetLevelChart: datasetLevelChart,
                    attributeLevelChart: attributeLevelChart,
                    chartDatas: relationShipData['chart_data'],
                    showLoader: false,
                    seletedDatasourceId: seletedDatasourceId,
                    seletedDatasourceName: seletedDatasourceName,
                    last_processed_time:last_processed_time,
                    startDate: result.response.start_date,
                    endDate: result.response.end_date,
                    chartErrors: profileData['aggDatas'],
                    // filteredValues: {},
                    filteredDatas:filteredDatas,
                    profileData: profileData,

                })
            }
        }
    );
}



export function getInfo() {

    let startDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00');
    let endDate = moment().format('YYYY-MM-DD 23:59:59');

    // let profileRequestData = {
    //     "integrations": 12,
    //     "tenant_id": tenantData.tenant_id,
    //     "payload_start_time": startDate,
    //     "payload_end_time": endDate
    // }
    let page = 12;
    tenantData["page"] = page;

    call_post_api(ui_service_ip, "profile/data/relationship/latest", tenantData).then(
        (result) => {
            // let infoData = {};
            if (result !== undefined && result !== null && result.integration !== null) {
                let profileRequestData = {
                    "integrations": 35,
                    "tenant_id": tenantData.tenant_id,
                    "payload_start_time": startDate,
                    "payload_end_time": endDate
                }
                call_post_api(ui_service_ip, "profile/info/latest", profileRequestData).then(
                    (profileResult) => {
                        //                        let infoData = {};
                        if (profileResult !== undefined && profileResult !== null) {
                        }
                    }
                );
            }
        }
    );
}

export function getLastProcessing(pmtype, integrationid = "") {
    const tenantId = localStorage.getItem('team_id');

    let endPoint = "metrics_store_payload_created_time";
    let data = { "tenant_id": tenantId }

    call_get_api(ui_service_ip, endPoint, data).then((response) => {
        if (response !== undefined && response.code === 200) {
            let results = response["results"];
            if(results.length === 0 || this.state.inProgress === true){
                return
            }
            this.setState({profiletimeresult: results})
            if (pmtype === "getonlyprofiletime") {
                /**While clicking attributes from other tab will get into this if condition will be called */
                let datasourceOptions = response["results"].filter((data) =>
                    data.integration_id === integrationid
                )
                this.setState({processTiming: datasourceOptions[0],last_processed_time:datasourceOptions[0] })
                // this.getMetaDatas('attribute', datasourceOptions[0]);

            } else {
                /** By default while coming inside the attribute tab will get into this else condition   */

                // Get integrations with atleast one dataset
                let integrations = []
                this.props.filterData['datasourceOptions'].filter((data)=> data.data_set_ids.length > 0 &&  integrations.push(data.value)) 
                results = results.filter((data)=> integrations.includes(data.integration_id))

                //Getting the latest Datasource among the filtered integration
                let maxLastProfilingTime = results.reduce((a, b) => Number(getDateObject(a['last_processed_time'])) > Number(getDateObject(b['last_processed_time'])) ? a : b);
                this.getMetaDatas('attribute', maxLastProfilingTime);
            }
        }
    }
    )
}
export function getIntegrations() {
    const team_id = localStorage.getItem('team_id');
    if (team_id !== undefined && team_id !== null) {
        let data = { tenant_id: team_id }
        call_get_api(ui_service_ip, "/integrations", data).then((response) => {
            if (response === undefined) {
                this.setState({ errorOccurred: true });
            } else if (response.code === 200) {
                let integrations = response["integrations"];
                let integrationsMap = {};
                for (let integration of integrations) {
                    integrationsMap[integration["integration_id"]] = integration;
                }
                this.setState({
                    integrationsMap: integrationsMap,
                });
            }
            else if (response.code === 403) {
                localStorage.removeItem("sessionToken");
                window.location = '/';
            }
            else {
                this.setState({ errorOccurred: true });
            }
        });
    }
}

export function attributeProfileFilterSubmit(dataSetId, attributeId) {
    if (dataSetId !== undefined && attributeId !== undefined) {
        let teamId = localStorage.getItem('team_id');
        let attData = { "tenant_id": teamId, "attribute_id": attributeId, "data_set_id": dataSetId };
        call_get_api(ui_service_ip, "attributes/dataprofile", attData).then((response) => {
            if (response === undefined) {
                this.setState({ errorOccurred: true });
            } else if (response.code === 200) {
                let apiStatusCode = response.code;
                this.setState({ showLoader: false })
                this.setApiResponse(response, apiStatusCode, true);
            } else {
                this.setState({ errorOccurred: true });
            }
        });
    }
}