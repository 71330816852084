import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationCircle, faPercentage, faServer, faTimesCircle, faTag,
    faLayerGroup, faCube, faDatabase, faStopwatch, faCalendarDay,
    faBug
} from "@fortawesome/free-solid-svg-icons";
import {
    DEFAULT_METRICS_MAP,
    NO_ERROR_COLOR_CLASS_MAP,
    METRICS_INFO,
    IS_NONE_CHECK,
    MODEL_AB_COMPARE_SRC
} from "../../utils/constant";

import { getAliasNameForMetricAttributeLevel,getAliasNameForMetric, getErrorTypeForStatsComponent, getFullNameOfMetric } from "../../utils/common_utils";
import RcTooltip from 'rc-tooltip';
import { ModelABCVStats } from "./statsForModelABCompareView";
import {normalizeAttributeName, normalizeChildDatasetName} from "../../utils/attribute_name_utils";
import StatsAttributeName from "./statsAttributeName";

import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

class MonitorStatsComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        let showPercentageSymbol = (DEFAULT_METRICS_MAP.dq.includes(this.props.metricName) ||
            DEFAULT_METRICS_MAP.ml.includes(this.props.metricName));
        let showDate = DEFAULT_METRICS_MAP.recency.includes(this.props.metricName)

        this.createIssue = this.createIssue.bind(this);
        this.cancelCreateIssue = this.cancelCreateIssue.bind(this);
        this.renderComponent = this.renderComponent.bind(this);

        this.renderMetricNameLine = this.renderMetricNameLine.bind(this);
        this.renderMetricNameLineErr = this.renderMetricNameLineErr.bind(this);


        this.renderDatasetLevelContent = this.renderDatasetLevelContent.bind(this);
        this.renderAttributeLevelContent = this.renderAttributeLevelContent.bind(this);
        this.renderComponentWithAddonButton = this.renderComponentWithAddonButton.bind(this);

        this.renderNonPercentageMetricNameLine = this.renderNonPercentageMetricNameLine.bind(this);
        this.renderNonPercentMetricNameLineErr = this.renderNonPercentMetricNameLineErr.bind(this);

        this.state = {
            containerLabel: this.props.containerLabel,
            containerName: this.props.containerName,
            metricLabel: this.props.metricLabel,
            metricName: getFullNameOfMetric(this.props.metricName),
            errorPercentage: this.props.errorPercentage,
            lastErrorPercentage: this.props.lastErrorPercentage,
            errorDivClass: this.props.errorDivClass === undefined ? '' : this.props.errorDivClass,
            parentDivClass: this.props.parentDivClass === undefined ? '' : this.props.parentDivClass,
            driftLabel: this.props.driftLabel,
            showDate: showDate,
            showPercentageSymbol: showPercentageSymbol,
            openCreateIssue: false
        }
    }

    cancelCreateIssue() {
       this.setState({openCreateIssue: false});
    }

    createIssue() {
       this.setState({openCreateIssue: true});
    }

    getSplitMetrics(metric_name) {
        const metric_name_lower_case = metric_name.toLowerCase()
        if (metric_name_lower_case === "precision vs recall" || metric_name_lower_case === "sensitivity vs specificity") {
            const split_metric_name_lc = metric_name_lower_case.split(" ");
            const split_metric_name = metric_name.split(" ");
            return (
                <>
                    <span>
                        <label>{this.state.metricLabel}</label>
                        <RcTooltip 
                            placement="top"
                            tooltipInfo={"Metric info"}
                            overlay={
                                <span id={`tooltipId`}>
                                    {METRICS_INFO[split_metric_name_lc[0]]}<br />
                                </span>
                            }
                            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                        >
                            <span className={this.props.color === null || this.props.color === undefined ? "f-16 lh-20 text-danger" : "f-16 lh-20 text-purple bdr-dashed-purple"}>{getAliasNameForMetric(split_metric_name[0])}</span>
                        </RcTooltip>
                    </span>

                    <span className={this.props.color === null || this.props.color === undefined ? "f-16 lh-20 text-danger" : "f-16 lh-20 text-purple "}>&nbsp;{getAliasNameForMetric(split_metric_name[1])}&nbsp;</span>

                    <span>
                        <RcTooltip 
                            placement="top"
                            tooltipInfo={"Metric info"}
                            overlay={
                                <span id={`tooltipId`}>
                                    {METRICS_INFO[split_metric_name_lc[2]]}<br />
                                </span>
                            }
                            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                        >
                            <span className={this.props.color === null || this.props.color === undefined ? "f-16 lh-20 text-danger" : "f-16 lh-20 text-purple bdr-dashed-purple"}>{getAliasNameForMetric(split_metric_name[2])}</span>
                        </RcTooltip>
                    </span>
                </>
            );
        }
    }

    getToolTip(metric_name) {
        const metric_name_lc = metric_name.toLowerCase();
        let m = ["precision vs recall", "sensitivity vs specificity"];
        let exclude_tooltip = ["memory_usage", "model_latency", "overhead_latency", "cpu_usage"]
        if (!(m.includes(metric_name_lc))) {
            return (
                <>

                    <i><FontAwesomeIcon icon={faCube} /></i>
                    <span>
                        <label>{this.state.metricLabel}</label>
                        { (!exclude_tooltip.includes(metric_name_lc)) ?
                            <RcTooltip
                                placement="top"
                                tooltipInfo={"Metric info"}
                                overlay={
                                    <span id={`tooltipId`}>
                                        {METRICS_INFO[metric_name_lc]}<br />
                                    </span>
                                }
                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                            >
                                <span className={this.props.color === null || this.props.color === undefined ?
                                    "f-16 lh-20 text-danger" : "f-16 lh-20 text-purple bdr-dashed-purple"
                                }>
                                    {getAliasNameForMetric(metric_name)}
                                </span>
                            </RcTooltip>
                            :
                            <span className={this.props.color === null || this.props.color === undefined ?
                                    "f-16 lh-20 text-danger" : "f-16 lh-20 text-purple"
                                }>
                                    {getAliasNameForMetric(metric_name)}
                            </span>
                        }
                    </span>

                </>
            );
        }
        else {
            return (
                <>
                    <i><FontAwesomeIcon icon={faExclamationCircle} /></i>
                    {this.getSplitMetrics(metric_name)}
                </>
            );
        }
    }

    renderMetricNameLineErr(){
        if (this.state.metricName === "Data Drift"){
            return "";
        }

        return (
            <p>
            <i><FontAwesomeIcon icon={faPercentage} /></i>
            <span>
                <label>{this.state.metricName}{":"}</label>
            </span>
                <span className="badge">
                    <label>Previous:</label>{this.props.lastErrorPercentage}
                    {
                        this.state.showPercentageSymbol ?
                            '%'
                            :
                            ''
                    }
                </span>
                <span className="badge">
                    <label>Latest:</label>{this.props.errorPercentage}
                    {

                        this.state.showPercentageSymbol ?
                            '%'
                            :
                            ''
                    }
                </span>
        </p>
        );
    }

    renderMetricNameLine(){
        if (this.state.metricName === "Data Drift"){
            return "";
        }

        return (
            <p>
            <i><FontAwesomeIcon icon={faPercentage} /></i>
            <span>
                <label>{this.state.metricName}:</label>
            </span>
                <span className="badge">
                    <label>Previous:</label>{
                        this.props.lastErrorPercentage - Math.round(this.props.lastErrorPercentage) === 0 ?
                            Math.round(this.props.lastErrorPercentage)
                            :
                            this.props.lastErrorPercentage
                    }
                    {'%'}
                </span>
                <span className="badge">
                    <label>Latest:</label>{
                        this.props.lastErrorPercentage - Math.round(this.props.lastErrorPercentage) === 0 ?
                            Math.round(this.props.lastErrorPercentage)
                            :
                            this.props.lastErrorPercentage
                    }
                    {'%'}
                </span>
        </p>
        )
    }

    renderNonPercentMetricNameLineErr() {
        return (
            <p>
                {this.state.showDate ?
                    <i><FontAwesomeIcon icon={faCalendarDay} /></i>
                    :
                    <i><FontAwesomeIcon icon={faStopwatch} /></i>
                }
                <span>
                    <label>{this.state.metricName}{":"}</label>
                </span>
                <span className="badge">
                    <label>Previous:</label>{Math.round(this.props.lastErrorPercentage)}
                    {this.state.showDate ?
                        ' Days'
                        :
                        ''
                    }
                </span>
                <span className="badge">
                    <label>Latest:</label>{Math.round(this.props.errorPercentage)}
                    {
                        this.state.showDate ?
                            ' Days'
                            :
                            ''
                    }
                </span>
            </p>
        );
    }

    renderNonPercentageMetricNameLine() {
        return (
            <p>
                {this.state.showDate ?
                    <i><FontAwesomeIcon icon={faCalendarDay} /></i>
                    :
                    <i><FontAwesomeIcon icon={faStopwatch} /></i>
                }
                <span>
                    <label>{this.state.metricName}:</label>
                </span>
                <span className="badge">
                    <label>Previous:</label>{Math.round(this.props.lastErrorPercentage)}
                    {this.state.showDate ?
                        ' Days'
                        :
                        ''
                    }
                </span>
                <span className="badge">
                    <label>Latest:</label>{Math.round(this.props.errorPercentage)}
                    {this.state.showDate ?
                        ' Days'
                        :
                        ''
                    }
                </span>
            </p>
        );
    }

    renderComponent(dataSetName, isABCompareView, isCombinedChart,
                    chartData, reqAttributeName, canShowErrorVal){
        /**
         * This function is introduced since we need to render based on condition.
         * 'has-track-btn' class should be parent only when there is issue.
         * For normal cases, that parent div is not needed.
         *
         * So we have created this function to render common components. When 'has-track-btn' parent is
         * needed, we will call this function inside the parent.
         * For other cases, we will call this function without that parent div.
         * */

        return (
            <div className={`error-detail ${this.state.errorDivClass}`}>
                    {
                        this.props.isModelPerformance === true ?
                            this.props.variant === "mlModelPerformanceListView" || this.props.variant === "mlModelPerformanceChartView" ?

                                <>

                                    <p>{this.getToolTip(this.state.metricName)}</p>
                                    <p>
                                        <i><FontAwesomeIcon icon={faDatabase} /></i>
                                        <span>
                                            <label>{this.state.containerLabel}</label>
                                            {this.state.containerName}
                                        </span>
                                    </p>
                                    {
                                        this.state.metricName === "mean_shap_value" &&
                                        <p>
                                            <i><FontAwesomeIcon icon={faLayerGroup} /></i>
                                            <span>
                                                <label>Dataset: </label>
                                                <span className={this.props.color === null || this.props.color === undefined ? "f-16 lh-20 text-danger" : NO_ERROR_COLOR_CLASS_MAP[this.props.color]}>{normalizeChildDatasetName(dataSetName)}</span>
                                            </span>
                                        </p>
                                    }
                                    {
                                        reqAttributeName !== undefined &&
                                        <StatsAttributeName
                                            color={this.props.color}
                                            attributeName={reqAttributeName}>
                                        </StatsAttributeName>
                                    }
                                </> :
                                <>
                                    <p>
                                        <i><FontAwesomeIcon icon={faCube} /></i>
                                        <span>
                                            <label>{this.state.metricLabel}</label>
                                            <span className={this.props.color === null || this.props.color === undefined ? "f-16 lh-20 text-danger" : NO_ERROR_COLOR_CLASS_MAP[this.props.color]}>{getAliasNameForMetric(this.state.metricName)}</span>
                                        </span>
                                    </p>

                                    {isABCompareView && isCombinedChart ?
                                        <ModelABCVStats data={chartData} />
                                        :
                                        <p>
                                            <i><FontAwesomeIcon icon={faDatabase} /></i>
                                            <span><label>{this.state.containerLabel}</label>
                                                {this.state.containerName}
                                            </span>
                                        </p>
                                    }

                                </>
                            :
                            <>
                                {
                                    (reqAttributeName === undefined) ?
                                        (this.props.dataSetName === undefined) ?
                                            '' :
                                            <p>
                                                <i><FontAwesomeIcon icon={faLayerGroup} /></i>
                                                <span>
                                                    <label>Dataset: </label>
                                                    <span className={this.props.color === null || this.props.color === undefined ? "f-16 lh-20 text-danger" : NO_ERROR_COLOR_CLASS_MAP[this.props.color]}>{normalizeChildDatasetName(this.props.dataSetName)}</span>
                                                </span>
                                            </p> :
                                        <StatsAttributeName
                                            color={this.props.color}
                                            attributeName={reqAttributeName}>
                                        </StatsAttributeName>

                                }
                                <p>
                                    <i><FontAwesomeIcon icon={faCube} /></i>
                                    <span>
                                        <label>{this.state.metricLabel}</label>
                                        <span className={this.props.color === null || this.props.color === undefined ? "f-16 lh-20 text-danger" : NO_ERROR_COLOR_CLASS_MAP[this.props.color]}>
                                        {(reqAttributeName === undefined) ?
                                            getAliasNameForMetric(this.state.metricName) : 
                                            getAliasNameForMetricAttributeLevel(this.state.metricName) }
                                            </span>
                                    </span>
                                </p>

                                {
                                    (this.props.dataSetName !== undefined && reqAttributeName !== undefined) ?
                                        <p>
                                            <i><FontAwesomeIcon icon={faLayerGroup} /></i>
                                            <span>
                                                <label>Dataset: </label>
                                                {normalizeChildDatasetName(this.props.dataSetName)}
                                            </span>
                                        </p> :
                                        ''
                                }

                                {
                                    this.props.referenceDataSetName === undefined ?
                                        '' :
                                        <p>
                                            <i><FontAwesomeIcon icon={faTag} /></i>
                                            <span>
                                                <label>{this.props.referenceDataSetLabel}: </label>
                                                {this.props.referenceDataSetName}
                                            </span>
                                        </p>
                                }

                                <p>
                                    <i><FontAwesomeIcon icon={faDatabase} /></i>
                                    <span>
                                        <label>{this.state.containerLabel}</label>
                                        {this.state.containerName}
                                    </span>
                                </p>

                                {!canShowErrorVal ?
                                    this.state.showPercentageSymbol ?
                                        this.renderMetricNameLine()
                                        :
                                        this.renderNonPercentageMetricNameLine()
                                    :
                                    ''
                                }

                                {canShowErrorVal ?
                                    this.state.showPercentageSymbol ?
                                        this.renderMetricNameLineErr()
                                        :
                                        this.renderNonPercentMetricNameLineErr()
                                    :
                                    ''
                                }
                            </>
                    }

                </div>
        )
    }

    renderComponentWithAddonButton(dataSetName, isABCompareView, isCombinedChart,
        chartData, reqAttributeName, canShowErrorVal) {
        /**
         * This function is introduced to avoid frequent conflicts
         * with enterprise branch where
         * they we have introduced create issue button
         */
        if (this.props.isPreviewPage){
            return (
                <div className="has-track-btn">
                    {this.renderComponent(dataSetName, isABCompareView, isCombinedChart,
                        chartData, reqAttributeName, canShowErrorVal)}
                    {this.props.isDqErrorPreview === true ?
                        <div className="action">
                        <button
                            className="btn btn-sm btn-outline-dark text-nowrap"
                            onClick={this.createIssue}
                            data-toggle="modal"
                            data-target="#modal__track-issue">
                            <FontAwesomeIcon className="text-primary" icon={faBug}/>
                            Create Issue
                        </button>
                    </div> : ""}
                </div>
            );
        }

        return this.renderComponent(dataSetName, isABCompareView, isCombinedChart,
            chartData, reqAttributeName, canShowErrorVal)

        
    }

    renderDatasetLevelContent(dataSetName, reqAttributeName) {
        const chartData = this.props.chart_data;
        let canShowErrorVal = this.props.canShowError;
        let isABCompareView = false;
        let isCombinedChart = false;

        if (!IS_NONE_CHECK.includes(chartData)) {
            isABCompareView = chartData.source === MODEL_AB_COMPARE_SRC;
            isCombinedChart = chartData.isCombinedChart === true;
        }

        return (
            <>
            <Modal show={this.state.openCreateIssue}  centered onHide={this.cancelCreateIssue}>
                <Modal.Header closeButton className="border-0 justify-content-center d-block">
                    <h5 className="modal-title">
                        Track this issue</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"/>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label className="form-label">Assign to :</label>
                      <Select
                         placeholder=""
                         classNamePrefix='select-control'
                         options={[{"label": "Azure Blob", "value":1},{"label": "Azure Data Lake", "value":2},{"label": "Snowflake", "value":3}]}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label className="form-label">Description or Problem :</label>
                      <textarea className="form-control"></textarea>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className=" border-0 justify-content-center" centered>
                    <button type="button" className="btn btn-outline btn-grey btn-circle mr-2" onClick={this.cancelCreateIssue}>Cancel</button>
                    <button type="button" onClick={this.cancelCreateIssue}
                        className="btn btn-primary btn-circle">Submit</button>
                </Modal.Footer>
                </Modal>
            <div ref={this.props.footerRef}
                className={`qd-monitor__footer ${this.state.parentDivClass}`}>

                {this.renderComponentWithAddonButton(dataSetName, isABCompareView,
                    isCombinedChart, chartData, reqAttributeName, canShowErrorVal)}

                {this.props.driftLabel === undefined || this.props.driftLabel === null
                    ?
                    ''
                    :
                    <div className="error-status flex-shrink-1">
                        <i className="text-danger">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </i>
                        <p>{getErrorTypeForStatsComponent(this.props.driftLabel)}</p>
                    </div>
                }
            </div>
            </>
        );
    }

    renderAttributeLevelContent(){
        return (
            <div className={`qd-monitor__footer ${this.state.parentDivClass}`}>
                <div className={`error-detail ${this.state.errorDivClass}`}>
                    <p>
                        <i><FontAwesomeIcon icon={faServer} /></i>
                        <span>
                            <label>Data Set:</label>
                            {normalizeChildDatasetName(this.props.dataSetName)}
                        </span>
                    </p>
                    <p>
                        <i><FontAwesomeIcon icon={faCube} /></i>
                        <span>
                            <label>{this.state.metricLabel}</label>
                            {this.state.metricName}
                        </span>
                    </p>
                </div>

                {this.props.driftLabel === undefined || this.props.driftLabel === null
                    ?
                    ''
                    :
                    <div className="error-status flex-shrink-1">
                        <i className="text-danger">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </i>
                        <p>{getErrorTypeForStatsComponent(this.props.driftLabel)}</p>
                    </div>
                }
            </div>
        );
    }

    render() {
        const chartData = this.props.chart_data;

        if (this.state.metricName === undefined) {
            return ""
        }

        let dataSetName = normalizeChildDatasetName(this.props.dataSetName)
        if (this.state.metricName === "mean_shap_value" &&
            chartData !== undefined && chartData !== null) {
            dataSetName = normalizeChildDatasetName(chartData["dataSetName"])
        }

        let reqAttributeName = normalizeAttributeName(this.props.attributeName);

        let isAttribute = this.props.isAttribute;
        if (isAttribute === undefined || isAttribute === null) {
            // Render components required for dataset level
            return this.renderDatasetLevelContent(dataSetName, reqAttributeName);
        }

        // Render components required for attribute level
        return this.renderAttributeLevelContent();
    }
}

export default MonitorStatsComponent;
