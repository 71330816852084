import React from 'react';
import {postDefaultMetrics} from "../utils/event_handling";
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import {DISABLE_CHECKBOXES_IN_CARDS, WINDOW_METRICS, DATA_DRIFT} from "../utils/constant";
import * as yup from 'yup';


class DefaultMetricsEdit extends React.Component {
    constructor(props) {
        super(props);
        this.postDefaultMetrics = postDefaultMetrics.bind(this);
        this.handleEditButton = this.handleEditButton.bind(this);
        this.disableButton = this.disableButton.bind(this);

        this.state = {
            activeStatus: this.props.activeStatus,
            hideSubmitButton: false,
            selectedCheck: "",
            showCheck:"",
            metricsThreshold: this.props.exMetricDetails.metrics_definition.metrics_threshold !== undefined ? this.props.exMetricDetails.metrics_definition.metrics_threshold : "",
            driftThreshold: "",
            initial:{
              checkValue : "",
              metricsThreshold: this.props.exMetricDetails.metrics_definition.metrics_threshold !== undefined ? this.props.exMetricDetails.metrics_definition.metrics_threshold : "",
              windowCount: this.props.exMetricDetails.metrics_definition.window_count !== undefined ?this.props.exMetricDetails.metrics_definition.window_count : ""

            },
            windowCount: this.props.exMetricDetails.metrics_definition.window_count !== undefined ?this.props.exMetricDetails.metrics_definition.window_count : ""
        }
    }


    componentDidMount() {
        let metricsDefinition = this.props.exMetricDetails.metrics_definition;
        const existingChecks = metricsDefinition.checks;
        let checkOptions = [
            {"label": "Date Check", "value": "date_check"},
            {"label": "Not Null Check", "value": "not_null_check"},
            {"label":"IP Address Check","value":"ip_address_check"},
            {"label":"Url Check","value":"url_check"},
            {"label":"Email Check","value":"email_check"},
            {"label":"Gender Check","value":"gender_check"}];

        let selectedCheck = null;
        let check = null
        let value = null
        let showCheck = false;
        if (existingChecks.length > 0) {
            check = existingChecks[0];
            let checkName = check.name;
            value = check.value;
            selectedCheck = {"value":checkName}

            let matched = checkOptions.filter(x => x["value"] === checkName);
            if (matched.length > 0) {
                selectedCheck = matched[0];
                showCheck = true
            }
        }
        this.setState({selectedCheck:selectedCheck, showCheck:showCheck});
        let initial = this.state.initial;
        initial.checkValue = value;
        this.setState({initial:initial});

        let driftThresholdValue = metricsDefinition.drift_threshold;
        this.setState({driftThreshold: driftThresholdValue})

        let metricsThreshold = metricsDefinition.metrics_threshold
        this.setState({metricsThreshold:metricsThreshold})

        let windowCount = metricsDefinition.window_count;
        this.setState({windowCount: windowCount})

    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    renderExample(value) {
        if (value !== null) {
            switch (Object.values(value)[1]) {
                case  "gender_check":
                    return (<>Example: "^M(ale)?$|^F(emale)?$"</>);
                case "not_null_check":
                    return (<>Example: ["NA","", "Not Available"]</>);
                default:
                    return ""
            }
        }
    }

    getMetricValue(){
        let metricName = this.props.metricName;
        if (metricName ===  "Fill Rate"){
            return "List of invalid values";
        } else {
            return "Pattern to identify possible values"
        }
    }

    handleEditButton(event) {
        let a= (this.state.activeStatus) ? false : true;
        this.setState({activeStatus: a});
    }

    render() {

                let masType = this.props.exMetricDetails.metrics_definition.mas_type;
                const currentMetricName = this.props.metricName;

                let schema = yup.object({
                    metricsThreshold: yup.number().min(0,"Threshold cannot be less than 0").max(100,"Threshold cannot be greater than 100").required("This field is a required field"),
                });

                if(WINDOW_METRICS.includes(currentMetricName)){
                    schema = yup.object({
                        metricsThreshold: yup.number().min(0,"Threshold cannot be less than 0").max(100,"Threshold cannot be greater than 100").required("This field is a required field"),
                        windowCount: yup.number().min(1,"Window Size cannot be less than 1").max(2000,"Window Size cannot be greater than 2000").required("This field is a required field"),
                    }); 
                }
                 return (
                            <Formik
                                validationSchema={schema}  //commented this temporarily so that user can edit the ml metrics
                                onSubmit={this.postDefaultMetrics}
                                initialValues={this.state.initial}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                            }) => {
                                return (
                                    <Form className="needs-validation" onSubmit={handleSubmit}>
                                        <Form.Group controlId="metricName" className="row">
                                            <Form.Label className="col-md-4 col-form-label">
                                                Metric Name :
                                            </Form.Label>
                                            <div className="col-md-8 col-lg-5">
                                                <Form.Control
                                                    type="text"
                                                    name="metricName"
                                                    value={currentMetricName}
                                                    className="form-control"
                                                    placeholder="Metric Name"
                                                    disabled
                                                />
                                            </div>
                                        </Form.Group>
                                        {masType === "dqm" && this.state.selectedCheck !== null && this.state.showCheck === true ?
                                            <Form.Group controlId="checkValue" className="row">
                                                <Form.Label
                                                    className="col-md-4 col-form-label">
                                                    {this.getMetricValue()} :
                                                </Form.Label>
                                                <div className="col-md-8 col-lg-5">
                                                    <Form.Control
                                                        type="text"
                                                        name="checkValue"
                                                        onChange={handleChange}
                                                        value={values.checkValue}
                                                        isInvalid={errors.checkValue && touched.checkValue}
                                                        className="form-control"
                                                        placeholder={this.state.selectedCheck.value === "not_null_check" ? "Value" : "Regex"}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {touched.checkValue && errors.checkValue}
                                                    </Form.Control.Feedback>
                                                    <span
                                                        className="form-text text-muted">
                                                        {this.renderExample(this.state.selectedCheck)}
                                                    </span>
                                                </div>
                                            </Form.Group>
                                            : ""
                                        }
                                        <Form.Group controlId="metricsThreshold" className="row">
                                            {currentMetricName !== undefined && currentMetricName === "Recency" ?
                                                <Form.Label className="col-md-4 col-form-label">
                                                    Metrics Threshold (in Days) :
                                                </Form.Label>
                                                :
                                                <Form.Label className="col-md-4 col-form-label">
                                                    Metrics Threshold (in %) :
                                                </Form.Label>
                                            }
                                            <div className="col-md-8 col-lg-5">
                                                <Form.Control
                                                    type="number"
                                                    name="metricsThreshold"
                                                    // defaultValue={this.props.exMetricDetails.metrics_definition.metricsThreshold}
                                                    value = {values.metricsThreshold}
                                                    onChange={handleChange}                                                    
                                                    className="form-control"
                                                    placeholder="Metrics Threshold"
                                                    isInvalid={touched.metricsThreshold && errors.metricsThreshold}
                                                    onBlur={handleBlur}
                                                />
                                                <ErrorMessage component="div" className="error-text" name="metricsThreshold" />
                                            </div>
                                        </Form.Group>
                                        {currentMetricName !== undefined && WINDOW_METRICS.includes(currentMetricName) ?
                                        <Form.Group controlId="windowCount" className="row">
                                                <Form.Label className="col-md-4 col-form-label">
                                                    Window Size:
                                                </Form.Label>
                                                <div className="col-md-8 col-lg-5">
                                                    <div className="d-flex align-items-center">
                                                        <Form.Control
                                                            type="number"
                                                            name="windowCount"
                                                            value ={values.windowCount}
                                                            className="form-control"
                                                            isInvalid={ touched.windowCount && errors.windowCount }
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            placeholder="Window Size"

                                                        />
                                                        <span className="ml-2">Refreshes</span>
                                                    </div>
                                                    <ErrorMessage component="div" className="error-text" name="windowCount" />
                                                        <Form.Text 
                                                            className="text-muted">
                                                                {currentMetricName === DATA_DRIFT?
                                                                "The window size denotes the number of refreshes which is used to measure the drift in the data.":
                                                                "The window size denotes the number of refreshes which is used to measure the drift in the "+ currentMetricName + "."}</Form.Text>
                                                </div>
                                        </Form.Group>
                                        : ''}

                                        {DISABLE_CHECKBOXES_IN_CARDS.includes(currentMetricName)
                                            ?
                                            ''
                                            :
                                            <Form.Group controlId="enableMetric" className="row">
                                                <div className="col-md-8 col-lg-5 offset-md-4">
                                                    <div className="custom-control custom-switch">
                                                        <Form.Control
                                                            type="checkbox"
                                                            onChange={this.handleEditButton}
                                                            checked={this.state.activeStatus}
                                                            className="custom-control-input"
                                                        />
                                                        <Form.Label className="custom-control-label">
                                                            <strong className="ml-2">Enable</strong>
                                                        </Form.Label>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        }

                                        <Modal.Footer>
                                            <button type="button" onClick={() => this.props.closeModal()}
                                                    className="btn btn-outline btn-grey btn-circle mr-2">Cancel
                                            </button>
                                            <button type="submit"
                                                    disabled={this.state.hideSubmitButton}
                                                    className="btn btn-primary btn-circle show-success-toast"
                                                    data-dismiss="modal">
                                                <FontAwesomeIcon icon={faCheck}/> Update
                                            </button>
                                        </Modal.Footer>
                                    </Form>
                                );
                            }}
                            </Formik>
        );

    }
}

export default DefaultMetricsEdit;
