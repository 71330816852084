import React from 'react';
import ChartBody from '../components/chartBody';
import NoErrorComponent from '../monitor/components/monitorNoResult';
import { DATASOURCE } from "./discoverConstants";

class RelationshipChartView extends React.Component {


     render() {
          let chartData = this.props.dataModule.relationship[this.props.selectedDataSource];
          let hidePartitions = this.props.sectionName === DATASOURCE;
          if (chartData === undefined) {
               return (
                    <NoErrorComponent message={`Relationship not available for the selected ${this.props.sectionName}.`} />
               );
          }

          return (
               <ChartBody
                    id="chartRel"
                    showMetricValues={false}
                    showChartFilter={false}
                    chartType="hierarchicalEdgeBundlingDiscover"
                    page="profile_discover"
                    video_url=""
                    classValue={"text-center table-responsive"}
                    title="Relationships & Errors within a Datasource"
                    changeTab={this.props.changeTab}
                    chart_data={chartData}
                    metaData={this.props.dataModule.metaData}
                    hidePartitions={hidePartitions}
                    datasetVersion={this.props.dataModule.datasetVersion}
                    metricInfo={this.props.dataModule.info[this.props.selectedDataSource]}
                    filteredValues={this.props.filteredValues}
                    datasetLevelChart={[1]}
                    showZoom={true}
                    attributeLevelChart={false}
               />
          );
     }

}

export default RelationshipChartView;