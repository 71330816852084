/**
 * Represents the Monitor page -> Model Error -> List View tab contents
 * */
import React from 'react';
import { connect } from "react-redux";
import {
    getGroupedData,
    formModelErrorDataForListView, mapModelToDataSource, convertDataForListView, getChartFilterData
} from "../utils/common_utils";
import { DISPLAY_DATE_FORMAT, MAX_DATA_POINTS_FOR_LIST_VIEW } from "../utils/constant";
import BasicPortlet from "../components/basicPortlet";
import QualdoDataTable from "../components/bootstrapTable";
import CustomListInCell from "./components/customListInCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCalendar, faChartArea } from "@fortawesome/free-solid-svg-icons";
import ThumbnailChartList from "./components/thumbnailChartList";
import MonitorButton from "./components/monitorButton";
import ModelErrListFullScreen from "./modelErrListFullScreen";
import _ from 'lodash';
import Image from '../components/image';
import ChartFilterHeader from './components/chartFilterHeader';
import moment from 'moment';
import {
    getChartType,
    convertDecimalDigits,
    filterBoxPlotData,
    getRowCountContributionFromData
} from "../utils/common_utils";
import Load from '../components/loadAction';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import NoErrorComponent from './components/monitorNoResult';
import {
    NO_ERROR_CHART_COLORS,
    LIGHT_COLOR_MAP,
    ML_BOX_PLOT_LIST,
    SINGLE_ARROW_CHART_TYPE, GENERIC_CONSTANTS, FEATURE_DRIFT_ALIASES, MONITOR_MODEL_ERROR_LIST_VIEW_HEADERS
} from "../utils/constant";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getDateObject } from "../charts/browser_utils";



class ModelErrorListView extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.handleMore = this.handleMore.bind(this);
        this.getModelErrListTableComponents = this.getModelErrListTableComponents.bind(this);
        this.handleTimeFilter = this.handleTimeFilter.bind(this);
        this.ShowAttributeModal = this.ShowAttributeModal.bind(this);
        this.closeAttributeModal = this.closeAttributeModal.bind(this);
        this.ShowDatasetModal = this.ShowDatasetModal.bind(this);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.changeModelInFullScreen = this.changeModelInFullScreen.bind(this);
        this.getCustomClassForActions = this.getCustomClassForActions.bind(this);
        this.getGroupedData = getGroupedData.bind(this);
        this.changeModelErrListViewTable = this.changeModelErrListViewTable.bind(this);
        this.closeFullScreenMode = this.closeFullScreenMode.bind(this);
        this.closeDatasetModal = this.closeDatasetModal.bind(this);
        this.state = {
            startDate: moment(new Date()),
            showMore: false,
            selectedModel: null,
            tableUpdated: this.props.tableUpdated,
            selectedModelID: null,
            fullScreenClassKey: '',
            fullScreenClassKeyAttr: '',
            fullScreenClassKeyDataset: '',
            isInFullScreenMode: false,
            timeFilterApplied: false,
            lastProfilingTimeModel: null,
            lastProfilingTimeModelInside: null,
            showAttributeFull: false,
            showDatasetFull: false,
            completeTableData: _.cloneDeep(this.props.tableData),
            currentTableData: _.cloneDeep(this.props.tableData),
            modelFilteredData: [],
            showMoreData: _.cloneDeep(this.props.showMoreData),
            mlModelOptions: this.props.mlModelOptions,
            selectedDataSource: this.props.selectedDataSource
        };
    }

    getCustomClassForActions() {
        /**
         * This function returns a "function" which will provide the class for <td> entries
         * i.e the cells of the tables
         * */

        return (cell, row, rowIndex, colIndex) => {
            if (colIndex === 7 || colIndex === 6) {
                return "align-middle";
            }

            return "";
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableData !== this.props.tableData) {
            this.setState({ completeTableData: _.cloneDeep(this.props.tableData), currentTableData: _.cloneDeep(this.props.tableData), tableUpdated: !this.state.tableUpdated })
        }
        if (prevProps.showMoreData !== this.props.showMoreData) {
            this.setState({ showMoreData: _.cloneDeep(this.props.showMoreData) });
        }

        if (prevProps.mlModelOptions !== this.props.mlModelOptions) {
            this.setState({
                mlModelOptions: this.props.mlModelOptions
            })
        }

    }

    handleMore() {
        this.setState({ showMore: true })
    }

    ShowAttributeModal(values) {
        let modelId = values.modelId
        this.setState({ showAttributeFull: true, fullScreenClassKeyAttr: "show", modelId: modelId, attributeList: values.cell })
    }
    closeAttributeModal() {
        this.setState({ showAttributeFull: false, fullScreenClassKeyAttr: '' })
    }
    ShowDatasetModal(details, datasourceName) {
        let attributeData = details.attributeLevelData
        let datasetData = details.datasetLevelData
        let issuedAttributes = details.issuedAttributes
        let datasetId = details.data_set_id
        let filteredValues = details.filteredValues
        let latestError = details.latestError
        this.setState({
            showDatasetFull: true, latestError: latestError,
            fullScreenClassKeyDataset: "show", attributeData: attributeData,
            datasetData: datasetData, datasetName: details.data_set_name,
            datasourceName: datasourceName, issuedAttributes: issuedAttributes,
            datasetId: datasetId, filteredValues: filteredValues, lastProfilingTime: details.lastProfiled
        });
    }
    closeDatasetModal() {
        this.setState({ showDatasetFull: false, fullScreenClassKeyDataset: '' })
    }


    setFullScreen(selectedModelID, integrationId) {
        const mlModelMapping = this.props.monitorModule.mlModelMapping;
        let selectedModelInFullScreen = {
            "value": selectedModelID,
            "label": mlModelMapping[selectedModelID]
        };
        let lastProfilingTimeModelInside = this.props.dataModule.lastProfilingTime[integrationId]



        //let fullScreenData = this.getGroupedData(this.state.completeData, selectedModelInFullScreen)
        selectedModelInFullScreen.value = String(selectedModelID);

        this.setState({
            fullScreenClassKey: "show",
            isInFullScreenMode: true,
            lastProfilingTimeModelInside: lastProfilingTimeModelInside,
            selectedModelInFullScreen: selectedModelInFullScreen
        });
    }

    closeFullScreenMode() {
        this.setState({
            fullScreenClassKey: '',
            isInFullScreenMode: false
        });
    }

    changeModelInFullScreen(selectedModel) {
        this.setState({ selectedModelInFullScreen: selectedModel });
    }


    getModelErrListTableComponents(actionType, headerValue, cell) {
        if (headerValue === "Performance Metrics with Issues") {
            const listData = cell.value;
            if (cell.value.length > 0 && cell.performanceError === true) {
                return (
                    <ThumbnailChartList listData={listData}
                        key={"modelErrorsListView"}
                        isErrorChart={true}
                        isModel={true}
                        noModelConfigured={cell.noModelConfigured}
                    >
                    </ThumbnailChartList>
                );
            }
            else {
                return (<div className="tbl-placeholder">
                    <h2>
                        <FontAwesomeIcon icon={faChartArea} />
                    </h2>
                    <h4>{cell.value.length === 0 ? "No Charts Found" : "No Errors Found"}</h4>
                </div>)
            }
        } else if (headerValue === "Model Summary") {
            return <CustomListInCell listData={cell.value} isModel={true} inProgress={cell.inProgress} />
        } else if (headerValue === "Model Error Summary") {
            return <CustomListInCell listData={cell.value} noModelConfigured={cell.noModelConfigured} isModel={true} />
        } else if (headerValue === "Action") {
            return (
                <div className="show-more monitor-row_show-more"
                    onClick={this.setFullScreen.bind(this, cell.value, cell.integrationId)}>
                    <span className="icon">
                        <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <p>Detailed Charts</p>
                </div>
            );
        } else if (headerValue === "Data Quality Issues") {
            let finalElement = []
            if (cell === null || cell === undefined) {
                return finalElement;
            }
            let modelDatasets = cell.value
            let inProgress = cell.inProgress;
            if (inProgress === true) {
                return <div className="tbl-placeholder">
                    <h2>
                        <FontAwesomeIcon icon={faChartArea} />
                    </h2>
                    <h4>{"No data found"}</h4>
                </div>
            }
            let refreshedDatasets = []
            let notRefreshedDatasets = []
            for (let i = 0; i < modelDatasets.length; i++) {
                if (modelDatasets[i]["latestError"] === "not refreshed") {
                    notRefreshedDatasets.push(modelDatasets[i])
                } else {
                    refreshedDatasets.push(modelDatasets[i])
                }
            }
            if (notRefreshedDatasets.length > 0) {
                finalElement.push(
                    <div className="card card_fieldset-group" key={"notRefreshed"}>
                        <label className="form-label">Not Refreshed</label>

                        <div>
                            <MonitorButton cell={notRefreshedDatasets}
                                datasourceName={cell.datasourceName}
                                key={cell.datasourceName}
                                columnName="dqIssues"
                                closeDatasetModal={this.closeDatasetModal}
                                ShowDatasetModal={this.ShowDatasetModal} />
                        </div>

                    </div>
                )
            } if (refreshedDatasets.length > 0) {
                finalElement.push(
                    <div className="card card_fieldset-group" key={"refreshed"}>
                        <label className="form-label">Refreshed</label>

                        <div>
                            <MonitorButton cell={refreshedDatasets}
                                key={cell.datasourceName}
                                datasourceName={cell.datasourceName}
                                columnName="dqIssues"
                                closeDatasetModal={this.closeDatasetModal}
                                ShowDatasetModal={this.ShowDatasetModal} />
                        </div>

                    </div>
                );

            }
            return finalElement

        } else if (headerValue === "Serving Features Drifting") {
            if (cell.value.attributes !== undefined && cell.value.attributes.length !== 0) {
                return (
                    <div className="table-vertical-btns">
                        <MonitorButton cell={cell.value.attributes}
                            modelId={cell.modelId}
                            columnName="servingFeatureDrifts"
                            closeAttributeModal={this.closeAttributeModal}
                            ShowAttributeModal={this.ShowAttributeModal} />
                    </div>
                )

            } else {
                return (<div className="tbl-placeholder">
                    <h2>
                        <FontAwesomeIcon icon={faChartArea} />
                    </h2>
                    <h4>{cell.noModelConfigured === false ? "No Drift Found" : "Model not configured"}</h4>
                </div>)
            }
        }

        return cell;
    }

    filterDqIssues(rowData, selectedDate, noModelConfigured, createdTime) {
        let dqErrorInfo = rowData.find(x => x.type === "dqErrorInfo");
        if (dqErrorInfo === undefined || dqErrorInfo === null) {

            return;
        }
        let dataSetInfo = dqErrorInfo["value"]
        const endDate = moment(selectedDate).endOf("day").toDate();

        dataSetInfo.forEach((dataset) => {
            let isError = false
            let prevRefreshDate;
            let latestRefreshDate;
            let firstRefreshDate;
            let fullErrorInfo = dataset["issuedAttributes"]
            for (let i = 0; i < fullErrorInfo.length; i++) {
                let time = fullErrorInfo[i]["refresh_time"]
                if (moment(time).isSameOrBefore(moment(endDate)) && moment(time).isSameOrAfter(moment(selectedDate))) {
                    let latestError = fullErrorInfo[i]["issued_attributes"]
                    if (latestError !== undefined) {
                        dataset["latestError"] = latestError.length
                        dataset["filteredValues"] = latestError
                        isError = true
                        break
                    }
                }
                if (moment(time).isSameOrBefore(moment(endDate))) {
                    if (prevRefreshDate !== undefined && moment(time).isSameOrAfter(moment(prevRefreshDate))) {
                        prevRefreshDate = time
                    } else if (prevRefreshDate === undefined) {
                        prevRefreshDate = time
                    }

                }
                if (moment(time).isSameOrAfter(moment(createdTime))) {
                    firstRefreshDate = time
                }
                if (moment(time).isSameOrAfter(moment(selectedDate)) && latestRefreshDate === undefined) {
                    latestRefreshDate = time
                }
            }
            if (isError === false) {
                dataset["latestError"] = "not refreshed"
                dataset["prevRefreshDate"] = prevRefreshDate
                dataset["filteredValues"] = []
                dataset["latestRefreshDate"] = latestRefreshDate
                dataset["firstRefreshDate"] = noModelConfigured === true ? firstRefreshDate : undefined;
            }

            dataset["lastProfiled"] = fullErrorInfo[0]["refresh_time"]


        });


    }

    filterModelAttributesData(rowData, selectedDate, noModelConfigured) {
        let selectedFeatureDriftsAttributes = rowData.find(x => x.type === "servingFeatureDrift");
        if (selectedFeatureDriftsAttributes === undefined || selectedFeatureDriftsAttributes === null) {
            return;
        }
        let newAttributes = []
        let existedAttributes = selectedFeatureDriftsAttributes["value"]["attributeInfo"]
        if (existedAttributes !== undefined) {
            const endDate = moment(selectedDate).endOf("day").toDate();
            for (let i = 0; i < existedAttributes.length; i++) {
                let timeList = existedAttributes[i]["time"]
                timeList.forEach((time) => {
                    if (moment(time).isSameOrBefore(moment(endDate)) && moment(time).isSameOrAfter(moment(selectedDate))) {
                        let index = timeList.indexOf(time);
                        let selectedDriftPattern = existedAttributes[i]["drift_patterns"][index]
                        if (!newAttributes.includes(existedAttributes[i]["attribute_name"]) && selectedDriftPattern !== "not_computed" && selectedDriftPattern !== "NO_DRIFT") {
                            newAttributes.push(existedAttributes[i]["attribute_name"])
                        }
                    }
                });
            }

        }
        selectedFeatureDriftsAttributes.value.attributes = newAttributes;
        selectedFeatureDriftsAttributes.noModelConfigured = noModelConfigured;

    }

    filterModelErrorSummaryData(rowData, selectedDate, noModelConfigured, mlModelId) {
        let modelErrorSummary = rowData.find(x => x.type === "errModelSummary");
        if (modelErrorSummary === undefined || modelErrorSummary === null) {
            return;
        }

        const endDate = selectedDate.endOf("day").toDate();
        const startDate = selectedDate.startOf("day").toDate();
        const propsDataModuleInfo = this.props.dataModule.info;
        let fullModelInfo = this.props.monitorModule.mlModelDetails
        const modelDataSourceDetails = mapModelToDataSource(fullModelInfo);
        const errorSummaryData = formModelErrorDataForListView([], modelDataSourceDetails,
            propsDataModuleInfo, mlModelId, true, startDate, endDate);
        modelErrorSummary.value = errorSummaryData;
        modelErrorSummary.noModelConfigured = noModelConfigured
    }


    handleTimeFilter(event, picker) {
        let selectedDate = picker.startDate;
        let clonedData = _.cloneDeep(this.state.completeTableData);
        let filteredRows = [];
        let modelFilteredRows = [];
        for (let rowData of clonedData) {
            let detailedChart = rowData.find(x => x.type === "detailedChart");
            let modelSummary = rowData.find(x => x.type === "modelSummary");
            let modelId = detailedChart.value
            let selectedModel = this.state.selectedModelID;

            let modelCreatedDate = modelSummary["value"][3]
            let createdTime = moment(detailedChart.createdTime).startOf("day").toDate();
            let noModelConfigured = moment(selectedDate).isBefore(createdTime)
            if (modelCreatedDate !== undefined && noModelConfigured === true) {
                modelCreatedDate["highlight"] = true
            } else if (modelCreatedDate !== undefined) {
                modelCreatedDate["highlight"] = false
            }
            this.filterModelErrorSummaryData(rowData, selectedDate, noModelConfigured, modelId);
            this.filterModelAttributesData(rowData, selectedDate, noModelConfigured);
            this.filterDqIssues(rowData, selectedDate, noModelConfigured, detailedChart.createdTime);
            let thumbnailData = rowData.find(x => x.type === "thumbnailCharts");

            const thumbnailVal = thumbnailData.value;
            let chartDate = selectedDate.endOf("day").toDate()
            for (let _cData of thumbnailVal) {
                let updatedThumbnailCharts = [];
                for (let _singleThumbnailData of _cData.data) {
                    const actChartData = _singleThumbnailData.chartData;
                    let timeArr = actChartData.time;
                    if (timeArr === undefined) {
                        continue;
                    }
                    let filteredArr = timeArr.filter(x => moment(x).isSameOrBefore(chartDate));
                    const filteredLength = filteredArr.length;
                    if (filteredLength === 0) {
                        continue;
                    }
                    actChartData.time = timeArr.slice(0, filteredLength);
                    actChartData.values = actChartData.values.slice(0, filteredLength);
                    if (actChartData.Y2_Values !== undefined) {
                        actChartData.Y2_Values = actChartData.Y2_Values.slice(0, filteredLength);
                    }
                    updatedThumbnailCharts.push(_singleThumbnailData);
                }
                _cData.data = updatedThumbnailCharts;
            }
            thumbnailData.noModelConfigured = noModelConfigured
            if (selectedModel !== null && Number(modelId) === Number(selectedModel)) {
                modelFilteredRows.push(rowData);
            }

            filteredRows.push(rowData);
        }
        this.setState({
            currentTableData: filteredRows,
            modelFilteredData: modelFilteredRows,
            tableUpdated: !this.state.tableUpdated,
            startDate: selectedDate,
            timeFilterApplied: true
        });
    }

    renderAttributeGroup() {
        if (this.state.attributeData === undefined) {
            return '';
        }
        let attributeData = Object.values(this.state.attributeData)
        let displayNumber = 0
        return attributeData.map((chartData, index) => {
            let convertedData = convertDataForListView(Object.values(chartData), this.state.datasetName, this.state.datasourceName, true, this.state.filteredValues)
            let attributeName = Object.values(chartData)[0].attribute_name
            displayNumber = displayNumber + 1
            return (
                <div className="qd-chart-group m-0 mb-4" key={index}>
                    <div className="qd-chart-group-heading">
                        {displayNumber === 1 ?
                            <h4 className="qd-chart-group-title">

                                {"Attribute Level Charts"}
                            </h4>
                            : ""
                        }
                        <h2 className="qd-chart-group-title">
                            <span className="circle-number">
                                {displayNumber}
                            </span>
                            {attributeName}
                        </h2>
                        <p className="m-0">
                            {""}
                        </p>

                    </div>
                    <div className="qd-chart-group-body">
                        <div className="row row-sm">
                            {this.renderModalChartDq("Attribute", convertedData)}
                        </div>
                    </div>
                </div>
            );

        });

    }

    renderModalChartDq(chartLevel, chartDataList) {
        //let profileKeys = ["avg", "duplicate", "empty","max","min","mode","std","unique"];
        let colorIndex = ["Purple", "Green", "Blue", "Yellow"];
        let chartCount = 0;
        // To append color for attribute level charts
        let noErrorCount = 0;
        let is_same_col = false;
        let j = 1;
        let attributeList = []
        // To append color for dataset level charts
        let noErrorDatasetCount = 0;
        let is_same_data_col = false;
        let k = 1;
        let datasetList = []
        chartDataList = Object.values(chartDataList)
        if (chartDataList.length === 0) {
            return (<NoErrorComponent />)
        }
        if (chartDataList) {
            return chartDataList.map((chartData, index) => {
                chartData["drift"] = convertDecimalDigits(chartData["drift"])
                const attribute_name = chartData.attribute_name;
                if (chartLevel === "Dataset" ||
                    (attribute_name !== undefined && attribute_name !== null && chartLevel === "Attribute")) {

                    let id = null;
                    let aggregateOptions = null;

                    id = chartData["data_set_id"];

                    let key = `modal_${chartData.name}_${id}`;
                    let title = this.props.title !== undefined ? this.props.title : '';
                    let compareDatasetOptions = null
                    let compareType = null;
                    let compareAttributeOptions = null;
                    let idValue = ''
                    let showAggregateError = false;
                    if (chartLevel === "Attribute") {
                        key = key + "_" + attribute_name;
                        idValue = chartData["ml_model_id"] + "_" + chartData["data_set_id"] + "_" + attribute_name + "_" + chartData["name"].replace(/ /g, "_");
                        compareType = chartLevel;
                        compareAttributeOptions = _.reject(this.state.compareAttributeOptions, function (d) {
                            return ((d.label === attribute_name && d.data_set_id === chartData.data_set_id) || d.value.split("$")[1] !== chartData.name ||
                                d.data_set_id !== chartData.data_set_id);
                        });
                        //                        aggregateOptions = this.state.aggregateOptions.filter(function (d) { return d.attribute_name === attribute_name && d.data_set_id === chartData.data_set_id});
                        //                        let isAggregateError = aggregateOptions.filter(function (d) {return d.is_error === true});
                        //                        if (isAggregateError.length > 0) {
                        //                            showAggregateError = true;
                        //                        }
                    }
                    if (chartLevel === "Dataset") {
                        idValue = chartData["ml_model_id"] + "_" + chartData["data_set_id"] + "_" + chartData["name"].replace(/ /g, "_");
                        compareType = chartLevel;
                        compareDatasetOptions = _.reject(this.state.compareDatasetOptions, function (d) { return (d.value === chartData["data_set_id"] || d.type !== chartData.name) });
                    }

                    title = title.replace(" ", "_");
                    /*let selectedSource = this.props.selectedIntegration;
                    chartData["selectedDataSource"] = selectedSource;
                    if (selectedSource === undefined) {
                        chartData["selectedMlModel"] = defaultModel;
                    }*/
                    chartCount = chartCount + 1;

                    let chartType = getChartType(chartData, chartCount);
                    const customGridKey = this.props.customKey;
                    if (customGridKey !== undefined) {
                        key = `${key}_${customGridKey}`;
                    }

                    if (chartData["key"] !== undefined) {
                        // Since we are using single complete model data for all Grids
                        // related to model error, we will append the existing chart key with
                        // current Grid's custom key.
                        chartData = _.cloneDeep(chartData);

                        key = `${key}_${chartData["key"]}`;
                        chartData["key"] = key;
                    }

                    if (chartLevel === "Dataset") {
                        if (chartData["type"] === "NO_DRIFT" || chartData["type"] === "not_computed") {
                            if (datasetList.length === 0) {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                if (chartCount % 2 !== 0) {
                                    is_same_data_col = true;
                                }
                            } else {
                                if (is_same_data_col === true && datasetList.length === 1) {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                    is_same_data_col = false;
                                } else {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[k]];
                                    if (is_same_data_col === false) {
                                        is_same_data_col = true;
                                    } else {
                                        is_same_data_col = false;
                                        k = k + 1;
                                    }
                                    if (k === 4) {
                                        k = 0;
                                    }
                                }
                            }
                            datasetList.push(noErrorDatasetCount);
                            noErrorDatasetCount = noErrorDatasetCount + 1;
                            chartData["errorChart"] = false;
                            if (chartType === "barWithError") {
                                chartType = "barChart";
                            } else {
                                if (chartType === "areaWithError") {
                                    chartType = "areaChart";
                                }
                            }
                        }
                    }
                    if (chartLevel === "Attribute") {
                        if (chartData["type"] === "NO_DRIFT" || chartData["type"] === "not_computed") {
                            if (attributeList.length === 0) {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                if (chartCount % 2 !== 0) {
                                    is_same_col = true;
                                }
                            } else {
                                if (is_same_col === true && attributeList.length === 1) {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                    is_same_col = false;
                                } else {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[j]];
                                    if (is_same_col === false) {
                                        is_same_col = true;
                                    } else {
                                        is_same_col = false;
                                        j = j + 1;
                                    }
                                    if (j === 4) {
                                        j = 0;
                                    }
                                }
                            }
                            attributeList.push(noErrorCount);
                            noErrorCount = noErrorCount + 1;
                            chartData["errorChart"] = false;
                            if (chartType === "barWithError") {
                                chartType = "barChart";
                            } else {
                                if (chartType === "areaWithError") {
                                    chartType = "areaChart";
                                }
                            }
                        }
                    }
                    if (ML_BOX_PLOT_LIST.includes(chartData["name"]) && chartData["chartType"] === "boxPlotZoomable") {
                        chartData["boxPlotData"] = filterBoxPlotData(chartData["boxPlotData"])
                        chartType = "boxPlotZoomable";
                    }

                    const rowValue = getRowCountContributionFromData(chartData)
                    if (FEATURE_DRIFT_ALIASES.includes(chartData["name"]) && rowValue !== GENERIC_CONSTANTS.NA) {
                        chartType = SINGLE_ARROW_CHART_TYPE;
                    }
                    if (chartData["dq_chart_points"].length === 0) {
                        return ""
                    }

                    if (chartData["name"] === "Data Drift") {
                        let isBoxplot = chartData["boxplot_data"].filter(x => x !== "NA")
                        if (isBoxplot.length > 0) {
                            chartData["boxPlotData"] = filterBoxPlotData(chartData["boxPlotData"])
                            if (chartData["chartType"] === "boxPlotZoomable") {
                                chartType = "boxPlotZoomable";
                            }
                        }

                    }

                    let filteredData = null;
                    let startDate = this.state.startDate;
                    let endDate = moment(this.state.startDate).endOf("day");
                    if (startDate !== null && endDate !== null) {
                        filteredData = getChartFilterData(chartData, startDate,
                            endDate, chartType, false, true, MAX_DATA_POINTS_FOR_LIST_VIEW);
                        let timeVals = null;
                        if (filteredData !== null) {
                            timeVals = filteredData.time;
                        }

                        if (timeVals !== undefined && timeVals !== null && timeVals.length > 0) {
                            startDate = moment(getDateObject(timeVals[0]));
                            // propsEndDate = moment(new Date(timeVals[timeVals.length - 1]));
                        }
                    }

                    return (
                        <div key={key} className="col-md-6" id={idValue}>
                            <LazyLoadComponent placeholder={<Load isBootStrapColumn={true} />}>
                                <ChartFilterHeader
                                    yValue={chartData["name"]}
                                    showHeatMap={true}
                                    tab="listView"
                                    showSettingsMenu={true}
                                    data={chartData}
                                    scale={chartData["scale"]}
                                    colorCode={chartData["color"]}
                                    lineColorCode={LIGHT_COLOR_MAP[chartData["color"]]}
                                    dataSetId={id}
                                    mlModelId={id}
                                    chartLevel={chartLevel}
                                    showContribution={chartLevel === "Dataset"}
                                    selectedIntegration={this.props.selectedIntegration}
                                    compareAttributeOptions={compareAttributeOptions}
                                    compareDataSrc={compareDatasetOptions}
                                    aggregateOptions={aggregateOptions}
                                    title={title}
                                    filteredData={filteredData}
                                    startDate={startDate}
                                    endDate={endDate}
                                    timeFilterApplied={this.state.timeFilterApplied}
                                    compareType={compareType}
                                    chartType={chartType}
                                    variant={this.state.variant}
                                    chartTimeFilter={this.chartTimeFilter}
                                    dataSetName={chartData["datasetName"]}
                                    attributeName={chartData["attribute_name"]}
                                    hideDistanceFilter={true}
                                    referenceDataSetName={chartData["referenceDataSetName"]}
                                    referenceDataSetLabel={chartData["referenceDataSetLabel"]}
                                    showAggregateError={showAggregateError}
                                />
                            </LazyLoadComponent>
                        </div>

                    );
                }
                return '';
            });
        }
    }

    renderDqGroup() {
        if (this.state.datasetData === undefined) {
            return '';
        }
        let convertedData = []
        if (this.state.latestError === "not refreshed") {
            let timelinessData = []
            let timelinessShowMore = this.props.timelinessShowMore.DefaultDataSet[0]
            if (timelinessShowMore !== undefined) {
                timelinessData = timelinessShowMore.data;
            }
            convertedData = timelinessData.filter(x => x.data_set_id === this.state.datasetId)
        } else {
            convertedData = convertDataForListView(this.state.datasetData, this.state.datasetName, this.state.datasourceName, true)
        }

        return (
            <>{this.state.lastProfilingTime !== undefined && this.state.lastProfilingTime !== null ? <div className="alert moniker-alert d-inline-block mt-3"
                style={{ minWidth: '700px' }}
                role="alert">
                <p className="mb-0">
                    <strong> {`Your data last profiled at ${this.state.lastProfilingTime}`}
                    </strong>
                </p>
            </div> : ""}
                <div className="qd-chart-group m-0 mb-4">
                    <div className="qd-chart-group-heading">
                        <h4 className="qd-chart-group-title">
                            {"Dataset Level Charts"}
                        </h4>
                        <h2 className="qd-chart-group-title">
                            <span className="circle-number">
                                {1}
                            </span>
                            {this.state.datasetName}
                        </h2>
                    </div>
                    <div className="qd-chart-group-body">
                        <div className="row row-sm">
                            {this.renderModalChartDq("Dataset", convertedData)}
                        </div>
                    </div>
                </div>
            </>
        );

    }

    renderGroup() {
        let initialCount = 0
        let attributeData = this.state.showMoreData.attribute;
        let finalData = attributeData.filter(x => x.metric === "Model Drift")
        return finalData.map((groupData, index) => {
            return (
                <div className="qd-chart-group m-0 mb-4"
                    key={groupData.key === undefined ? index : groupData.key}>
                    <div className="qd-chart-group-heading">
                        <h4 className="qd-chart-group-title">
                            <span className="circle-number">
                                {initialCount + index + 1}
                            </span>
                            {groupData.header}
                        </h4>
                        <p className="m-0">
                            {groupData.description}
                        </p>

                    </div>
                    <div className="qd-chart-group-body">
                        <div className="row row-sm">
                            {this.renderModalChart(groupData.level, groupData.data)}
                        </div>
                    </div>
                </div>
            );
        });

    }

    renderModalChart(chartLevel, chartDataList) {
        let profileKeys = ["avg", "duplicate", "empty", "max", "min", "mode", "std", "unique"];
        //let colorIndex = ["Purple", "Green", "Blue", "Yellow"];
        chartDataList = _.reject(chartDataList, function (d) { return (profileKeys.includes(d.name)); });
        if (chartDataList === undefined || chartDataList === null || chartDataList.length === 0) {
            return (<NoErrorComponent />);
        }

        let isMLModel = this.props.showModelFilter;

        let chartCount = 0;
        if (chartDataList) {
            return chartDataList.map((chartData, index) => {
                chartData["drift"] = convertDecimalDigits(chartData["drift"])
                const attribute_name = chartData.attribute_name;
                let lastDiv = (chartDataList.length === index + 1 && chartCount === 0)
                if (chartData["ml_model_id"] !== this.state.modelId || !this.state.attributeList.includes(attribute_name)) {
                    return "";
                }
                let isUnderPerformingGrid = (this.state.underPerformModelIDs !== undefined)
                if (isMLModel && this.state.showOnlyErrorData && this.state.selectedModel === null && isUnderPerformingGrid) {
                    const chartHasNoError = chartData["hasDrift"] === false;
                    let modelID = String(chartData["ml_model_id"]);
                    if (!this.state.underPerformModelIDs.includes(modelID)) {
                        return "";
                    }



                    if (chartHasNoError && lastDiv === true) {
                        return (<NoErrorComponent key={chartData["key"]} />);
                    } else if (chartHasNoError) {
                        return "";
                    }
                } else if (isMLModel && this.state.showOnlyErrorData && String(this.state.selectedModel["value"]) !== String(chartData["ml_model_id"]) && lastDiv === true) {
                    return (<NoErrorComponent key={chartData["key"]} />);
                } else if (isMLModel && this.state.showOnlyErrorData && String(this.state.selectedModel["value"]) !== String(chartData["ml_model_id"])) {
                    return ""
                }

                if (chartLevel === "Dataset" ||
                    (attribute_name !== undefined && attribute_name !== null && chartLevel === "Attribute")) {

                    let id = null;
                    let compareModelOptions = null;
                    let compareMLAttributeOptions = null;
                    if (this.props.showModelFilter) {
                        id = chartData["ml_model_id"];
                        compareModelOptions = _.reject(this.state.compareModelOptions, function (d) { return (d.label === chartData.data_set_name || d.value !== chartData.name); });
                    } else {
                        id = chartData["data_set_id"];
                    }

                    let key = `modal_${chartData.name}_${id}`;
                    let title = this.props.title !== undefined ? this.props.title : '';
                    let compareDatasetOptions = null
                    let compareType = null;
                    let compareAttributeOptions = null;
                    if (attribute_name !== undefined && attribute_name !== null && this.props.showModelFilter) {
                        key = key + "_" + attribute_name;
                        compareMLAttributeOptions = _.reject(this.state.compareAttributeOptions, function (d) { return (d.label === attribute_name || d.value !== chartData.name || d.dataSetName !== chartData.data_set_name); });
                    }

                    let idValue = ''
                    let showAggregateError = false;
                    if (chartLevel === "Attribute") {
                        key = key + "_" + attribute_name;
                        idValue = chartData["ml_model_id"] + "_" + chartData["data_set_id"] + "_" + attribute_name + "_" + chartData["name"].replace(/ /g, "_");
                        compareType = chartLevel;
                        compareAttributeOptions = _.reject(this.state.compareAttributeOptions, function (d) {
                            return ((d.label === attribute_name && d.data_set_id === chartData.data_set_id) || d.value.split("$")[1] !== chartData.name ||
                                d.data_set_id !== chartData.data_set_id);
                        });

                    }


                    title = title.replace(" ", "_");

                    chartCount = chartCount + 1;

                    let chartType = getChartType(chartData, chartCount);
                    const customGridKey = this.props.customKey;
                    if (customGridKey !== undefined) {
                        key = `${key}_${customGridKey}`;
                    }

                    if (chartData["key"] !== undefined) {
                        // Since we are using single complete model data for all Grids
                        // related to model error, we will append the existing chart key with
                        // current Grid's custom key.
                        chartData = _.cloneDeep(chartData);

                        key = `${key}_${chartData["key"]}`;
                        chartData["key"] = key;
                    }

                    if (chartLevel === "Attribute") {
                        if (chartData["type"] === "NO_DRIFT" || chartData["type"] === "not_computed") {
                            return ""
                        }
                    }
                    if (ML_BOX_PLOT_LIST.includes(chartData["name"]) && chartData["chartType"] === "boxPlotZoomable") {
                        chartData["boxPlotData"] = filterBoxPlotData(chartData["boxPlotData"])
                        chartType = "boxPlotZoomable";
                    }

                    const rowValue = getRowCountContributionFromData(chartData)
                    if (FEATURE_DRIFT_ALIASES.includes(chartData["name"]) && rowValue !== GENERIC_CONSTANTS.NA) {
                        chartType = SINGLE_ARROW_CHART_TYPE;
                    }

                    let filteredData = null;
                    let startDate = this.state.startDate;
                    let endDate = moment(this.state.startDate).endOf("day");
                    if (startDate !== null && endDate !== null) {
                        filteredData = getChartFilterData(chartData, startDate,
                            endDate, chartType, false, true, MAX_DATA_POINTS_FOR_LIST_VIEW);
                        let timeVals = null;
                        if (filteredData !== null) {
                            timeVals = filteredData.time;
                        }

                        if (timeVals !== undefined && timeVals !== null && timeVals.length > 0) {
                            startDate = moment(getDateObject(timeVals[0]));
                            // propsEndDate = moment(new Date(timeVals[timeVals.length - 1]));
                        }
                    }



                    return (
                        <div key={key} className="col-md-6" id={idValue}>
                            <LazyLoadComponent placeholder={<Load isBootStrapColumn={true} />}>
                                <ChartFilterHeader
                                    tab="listView"
                                    hideTopMenuOptions={chartType === SINGLE_ARROW_CHART_TYPE}
                                    yValue={chartData["name"]}
                                    showHeatMap={true}
                                    showSettingsMenu={true}
                                    scale={chartData.scale}
                                    colorCode={chartData["color"]}
                                    lineColorCode={LIGHT_COLOR_MAP[chartData["color"]]}
                                    data={chartData}
                                    dataSetId={id}
                                    mlModelId={id}
                                    chartLevel={chartLevel}
                                    showContribution={chartLevel === "Dataset"}
                                    selectedIntegration={this.props.selectedIntegration}
                                    //selectedMLModel={defaultModel}
                                    compareAttributeOptions={compareAttributeOptions}
                                    compareMLAttributeOptions={compareMLAttributeOptions}
                                    compareDataSrc={compareDatasetOptions}
                                    compareModelOptions={compareModelOptions}
                                    aggregateOptions={[]}
                                    title={title}
                                    startDate={startDate}
                                    endDate={endDate}
                                    filteredData={filteredData}
                                    timeFilterApplied={this.state.timeFilterApplied}
                                    compareType={compareType}
                                    chartType={chartType}
                                    variant={this.state.variant}
                                    chartTimeFilter={this.chartTimeFilter}
                                    hideDistanceFilter={true}
                                    dataSetName={chartData["datasetName"]}
                                    attributeName={chartData["attribute_name"]}
                                    referenceDataSetName={chartData["referenceDataSetName"]}
                                    referenceDataSetLabel={chartData["referenceDataSetLabel"]}
                                    showAggregateError={showAggregateError}
                                />
                            </LazyLoadComponent>
                        </div>

                    );
                } else if (lastDiv === true) {
                    return (<NoErrorComponent />);
                }
                return '';
            });
        }
    }

    changeModelErrListViewTable(selectedModel) {
        let selectedModelID = selectedModel.value;

        let filteredRows = [];
        let integrationId;
        for (let rowData of this.state.currentTableData) {
            if (Number(rowData[6].value) === Number(selectedModelID)) {
                integrationId = rowData[6]["integrationId"]
                filteredRows.push(rowData);
            }
        }
        let lastProfilingTimeModel = null;
        if (integrationId !== undefined && integrationId !== null) {
            lastProfilingTimeModel = this.props.dataModule.lastProfilingTime[integrationId]
        }
        this.setState({ modelFilteredData: filteredRows, lastProfilingTimeModel: lastProfilingTimeModel, selectedModelID: selectedModelID, selectedModel: selectedModel });
    }


    render() {
        let tableData = {
            "data": (this.state.modelFilteredData.length > 0 ? this.state.modelFilteredData : this.state.currentTableData),
            "headers": MONITOR_MODEL_ERROR_LIST_VIEW_HEADERS
        };
        const customKey = `${tableData.data.length}_${this.state.tableUpdated}_${this.state.filterApplied}`;

        const currSelectedData = this.state.startDate;
        const currentSelectedDateAsTxt = currSelectedData === null ?
            ' ' :
            currSelectedData.format(DISPLAY_DATE_FORMAT);

        let message = null;
        if (this.state.mlModelOptions !== null && this.state.mlModelOptions.length > 0) {
            message = "in-progress";
        }

        const selectedDataSource = this.props.dataModule.selectedDataSource;
        let lastProfilingTime = this.state.lastProfilingTimeModel !== null ? this.state.lastProfilingTimeModel : this.props.dataModule.lastProfilingTime[selectedDataSource];

        return (
            this.state.showAttributeFull ? (
                <div className={"qd-fullscreen fade " + this.state.fullScreenClassKeyAttr}>
                    <div className="qd-fs_dialog">
                        <div className="qd-fs_content">
                            <div className="qd-fs_header">
                                <div className="header_content">
                                    <Image
                                        className="qd-header__brand-logo-default"
                                        src="logo"
                                    />
                                    <h4 className="header_title">
                                        {""}
                                    </h4>
                                </div>
                                <button type="button" onClick={this.closeAttributeModal} className="close" aria-label="Close">
                                    <span aria-hidden="true" />
                                    <span className="sr-only">Close</span>
                                </button>
                            </div>
                            <div className="qd-fs_body overflow-auto">
                                <div className="qd-grid qd-grid-monitor">
                                    <div className="qd-grid-item qd-section">
                                        {this.renderGroup()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
                :
                (this.state.showDatasetFull === true ?
                    <div className={"qd-fullscreen fade " + this.state.fullScreenClassKeyDataset}>
                        <div className="qd-fs_dialog">
                            <div className="qd-fs_content">
                                <div className="qd-fs_header">
                                    <div className="header_content">
                                        <Image
                                            className="qd-header__brand-logo-default"
                                            src="logo"
                                        />
                                        <h4 className="header_title">
                                            {""}
                                        </h4>
                                    </div>
                                    <button type="button" onClick={this.closeDatasetModal} className="close" aria-label="Close">
                                        <span aria-hidden="true" />
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="qd-fs_body overflow-auto">
                                    <div className="qd-grid qd-grid-monitor">
                                        <div className="qd-grid-item qd-section">
                                            {this.renderDqGroup()}
                                            {this.renderAttributeGroup()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="qd-tab__content-action">
                            <div className="caption">
                                <div className="alert moniker-alert" role="alert">
                                    <p className="mb-0">
                                        <strong> {lastProfilingTime === null || lastProfilingTime === undefined ? "--" : `Your data last profiled at ${lastProfilingTime}`}</strong>
                                    </p>
                                </div>
                            </div>
                            <div className="actions">
                                <div className="action-left">
                                    <Select
                                        name="models"
                                        filterOption={({ label }, query) => label.includes(query)}
                                        id="chooseModelME"
                                        options={this.state.mlModelOptions}
                                        onChange={this.changeModelErrListViewTable}
                                        value={this.state.selectedModel}
                                        classNamePrefix='form-control'
                                        placeholder="Choose Model"
                                    />
                                </div>
                                <div className="action-right">
                                    <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                        singleDatePicker={true}
                                        onApply={this.handleTimeFilter}>
                                        <i>
                                            <FontAwesomeIcon icon={faCalendar} />
                                        </i>
                                        <span className="d-inline-block">
                                            {currentSelectedDateAsTxt}
                                        </span>
                                    </DateRangePicker>
                                </div>
                            </div>
                        </div>
                        {this.state.isInFullScreenMode ?
                            <ModelErrListFullScreen fullScreenClassVal={this.state.fullScreenClassKey}
                                title={"Model Errors - Dashboard"}
                                key={this.state.selectedModelInFullScreen}
                                showModelFilter={true}
                                lastProfilingTimeModelInside={lastProfilingTime}
                                timeFilterApplied={this.state.timeFilterApplied}
                                mlModelOptions={this.state.mlModelOptions}
                                attributeOptions={this.props.attributeOptions}
                                variant={"listViewFullScreenModelError"}
                                startDate={moment(this.state.startDate.startOf("day"))}
                                endDate={moment(this.state.startDate).endOf("day")}
                                changeModelInFullScreen={this.changeModelInFullScreen}
                                closeFullScreen={this.closeFullScreenMode}
                                selectedMlModel={this.state.selectedModelInFullScreen}
                                modalData={this.state.showMoreData}
                            >
                            </ModelErrListFullScreen>
                            :
                            (<BasicPortlet
                                key={`monitorModelErrorListView_${customKey}`}
                                className="pb-0"
                                id="monitorModelErrorListView"
                                title="Model Errors List View"
                                content={
                                    this.props.showLoader === true && this.props.firstDatasetUp === false ?
                                        <Load />
                                        :
                                        <QualdoDataTable
                                            id="monitorModelErrorListView"
                                            customGetActionComponent={this.getModelErrListTableComponents}
                                            data={tableData}
                                            paginationSize={2}
                                            message={message}
                                            key={`M_ME_table_${customKey}`}
                                            component_name="modelErrorListViewTable"
                                            getCustomClassForColumns={this.getCustomClassForActions()}
                                        />
                                }
                            />)
                        }
                    </>)
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(ModelErrorListView);