import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

class CardHelpContent extends Component {

  constructor(props) {
        super(props);
        this.state = {
        }
  }

  renderAttributeCard(name){
     switch(name) {
         case "Unique values":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value on this card represents the count of unique values present in the selected attribute.</p>
                       </>
                     );
         case "Duplicate values":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value on this card represents the count of duplicate values present in the selected attribute.</p>
                      </>
                     );
         case "Empty values":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value on this card represents the count of empty rows for the selected attribute.</p>
                      </>
                     );
         case "Error values":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value on this card represents the number of different types of data quality errors found in the selected attribute.</p>
                      </>
                     );
         default:
                  return '';

      }
  }

  renderModelContent(name) {
      switch(name) {
         case "Model Performance":
              return(<><h4 className="text-info">{name}</h4>
                        <p>The value on this card represents the model performance (F1 score for classification type of models and RMSE for regression type of models) for the selected model.</p>
                      </>
               );
         case "ML Data Consistency Error":
              return(<><h4 className="text-info">{name}</h4>
                        <p>The value on this card represents Data consistency error detected in the serving data in comparison with the training data for the selected model.</p>
                      </>
               );
         case "No of Serving Features Drift":
              return(<><h4 className="text-info">{name}</h4>
                        <p>The value on this card is the count of the features that are drifting in the serving data over a period of time for the selected model.</p>
                      </>
               );
         case "No of Serving Features Drift Over Training":
              return(<><h4 className="text-info">{name}</h4>
                        <p>The value on this card is the count of the features that are drifting in the serving data in comparison with the training data for the selected model. </p>
                      </>
               );
         case "Accuracy Drift":
               return(<><h4 className="text-info">{name}</h4>
                          <h5>Visualization</h5>
                          <p>1. Visualization gives an overview of drop in fill rates of attributes in the data source.</p>
	                      <p>2. Attributes are on the circumference and each of the concentric circle represents a fill rate in percentage.</p>
                       </>
                     );
         case "Feature Drift":
               return(<><h4 className="text-info">{name}</h4>
                          <h5>Visualization</h5>
                          <p>1. Visualization captures the different data accuracy issues present across the data source at a glimse.</p>
	                      <p>2. Different types of accuracy issues are represented as bigger circles and are surrounded by the attribute names affected by the respective issue.</p>
                      </>
                     );
         case "ML Data Consistency":
               return(<><h4 className="text-info">{name}</h4>
                          <h5>Visualization</h5>
                          <p>1. Visualization captures the different data accuracy issues present across the data source at a glimse.</p>
	                      <p>2. Different types of accuracy issues are represented as bigger circles and are surrounded by the attribute names affected by the respective issue.</p>
                      </>
                     );
         case "Model Drift":
               return(<><h4 className="text-info">{name}</h4>
                          <h5>Visualization</h5>
                          <p>1. Visualization captures the different data accuracy issues present across the data source at a glimse.</p>
	                      <p>2. Different types of accuracy issues are represented as bigger circles and are surrounded by the attribute names affected by the respective issue.</p>
                      </>
                     );
         case "Prediction Drift":
               return(<><h4 className="text-info">{name}</h4>
                          <h5>Visualization</h5>
                          <p>1. Visualization captures the different data accuracy issues present across the data source at a glimse.</p>
	                      <p>2. Different types of accuracy issues are represented as bigger circles and are surrounded by the attribute names affected by the respective issue.</p>
                      </>
                     );
         case "Response Drift":
               return(<><h4 className="text-info">{name}</h4>
                          <h5>Visualization</h5>
                          <p>1. Visualization captures the different data accuracy issues present across the data source at a glimse.</p>
	                      <p>2. Different types of accuracy issues are represented as bigger circles and are surrounded by the attribute names affected by the respective issue.</p>
                      </>
                     );

         default:
                  return '';

      }

  }

  renderContent(name) {
      if(this.props.page === "attribute") {
         return(this.renderAttributeCard(this.props.name));

      }
      switch(name) {
         case "Data Outliers":
               return(<><h4 className="text-info">{name}</h4>
	                      <p>The value shown on this card is an average of the outliers percentage detected on the attributes in the selected data source by default or the attributes present as per the applied filters.</p>
                       </>
                     );
         case "No of Numerical Attributes with Outliers":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is the count of attributes with outliers in the selected data source by default or based on the applied filters.</p>
                      </>
               );
         case "No of Numerical Attributes without Outliers":
             return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is the count of attributes without outliers in the selected data source by default or based on the applied filters.</p>
                      </>
               );

         case "Data Completeness":
               return(<><h4 className="text-info">{name}</h4>
                          <p>The value shown on this card is an average of the fill rate of the individual attributes present in the selected data source by default or the individual attributes present as per the applied filters.</p>
                       </>
                     );
         case "Data Timeliness":
               return(<><h4 className="text-info">{name}</h4>
	                    <p>The value shown on this card is an average of the recency values of the datasets present in the selected data source by default or the datasets present as per the applied filters.</p>
                       </>
                     );
         case "Data Drift":
               return(<><h4 className="text-info">{name}</h4>
                          <h5>Visualization</h5>
                          <p>1. Visualization gives an overview of drop in fill rates of attributes in the data source.</p>
	                      <p>2. Attributes are on the circumference and each of the concentric circle represents a fill rate in percentage.</p>
                       </>
                     );
         case "Credit Card Conformity Errors":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is an average of the conformity errors on the attributes auto-detected as “credit card” in the selected data source by default or in the attributes present as per the applied filters.</p>
                       </>
                     );
         case "Categorical Encoding Errors":
               return(<><h4 className="text-info">{name}</h4>
                          <h5>Visualization</h5>
                          <p>1. Visualization gives an overview of drop in fill rates of attributes in the data source.</p>
	                      <p>2. Attributes are on the circumference and each of the concentric circle represents a fill rate in percentage.</p>
                       </>
                     );
         case "Categorical Ordinal Errors":
               return(<><h4 className="text-info">{name}</h4>
                          <h5>Visualization</h5>
                          <p>1. Visualization gives an overview of drop in fill rates of attributes in the data source.</p>
	                      <p>2. Attributes are on the circumference and each of the concentric circle represents a fill rate in percentage.</p>
                       </>
                     );
         case "Data Conformity Errors":
               return(<><h4 className="text-info">{name}</h4>
	                      <p>The value shown on this card is an average of the conformity errors detected on the applicable attributes present in the selected data source by default or the applicable attributes present as per the applied filters.</p>
                       </>
                     );
         case "Data Accuracy Errors":
               return(
                   <><h4 className="text-info">{name}</h4>
                   <p>The value shown on this card is an average of the data outliers detected on the attributes present in the selected data source by default or the attributes present as per the applied filters.</p>
                   </>
               );
         case "Data Consistency Errors":
              return(
                 <><h4 className="text-info">{name}</h4>
                 <p>The value shown on this card is an average of the consistency errors detected on the applicable attributes present in the selected data source by default or the applicable attributes present as per the applied filters.</p>
                 </>
              );
         case "Categorical Consistency Errors":
              return(
                 <><h4 className="text-info">{name}</h4>
                 <p>The value shown on this card is the percentage of attributes with categorical consistency errors to the overall attributes qualified as “categorical” in the selected data source by default or the attributes present as per the applied filters.</p>
                 </>
              );
         case "Data Type Consistency Errors":
              return(
                 <><h4 className="text-info">{name}</h4>
                 <p>The value shown on this card is the percentage of attributes with data type consistency errors (data type mismatch) to the overall attributes in the selected data source by default or the attributes present as per the applied filters.</p>
                 </>
              );
         case "Normalization Consistency Errors":
              return(
                 <><h4 className="text-info">{name}</h4>
                 <p>The value shown on this card is the percentage of attributes with normalization consistency errors to the overall attributes qualified as “numerical” in the selected data source by default or the attributes present as per the applied filters.</p>
                 </>
              );
         case "Attribute Fill Rate < 25%":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card represents the contribution of attributes with a fill rate of less than or equal to 25% to the overall fill rate at a data source level by default or as per the applied filters. If the top filter is selected till attribute, then this card value shows the actual fill rate of the selected attribute if it falls in this category.</p>
                      </>
                     );
         case "Attribute Fill Rate 25% to 50%":
               return(<><h4 className="text-info">{name}</h4>
	                    <p>The value shown on this card represents the contribution of attributes with a fill rate greater than 25% but less than or equal to 50% to the overall fill rate at a data source level by default or as per the applied filters. If the top filter is selected till attribute, then this card value shows the actual fill rate of the selected attribute if it falls in this category.</p>
                      </>
                     );
         case "Attribute Fill Rate 50% to 75%":
               return(<><h4 className="text-info">{name}</h4>
                         <p>The value shown on this card represents the contribution of attributes with a fill rate greater than 50% but less than or equal to 75% to the overall fill rate at a data source level by default or as per the applied filters. If the top filter is selected till attribute, then this card value shows the actual fill rate of the selected attribute if it falls in this category.</p>
                      </>
                     );
         case "Attribute Fill Rate  > 75%":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card represents the contribution of attributes with a fill rate of greater than 25% to the overall fill rate at a data source level by default or as per the applied filters. If the top filter is selected till attribute, then this card value shows the actual fill rate of the selected attribute if it falls in this category.</p>
                      </>
                     );
         case "Attribute Fill Rate 25% to 75%":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card represents the contribution of attributes with a fill rate greater than 25% but less than or equal to 75% to the overall fill rate at a data source level by default or as per the applied filters. If the top filter is selected till attribute, then this card value shows the actual fill rate of the selected attribute if it falls in this category.</p>
                      </>
                     );
         case "Total number of Rows":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is the total number of rows present in the datasets of the selected datasource by default or based on the applied filters.</p>
                      </>
                     );
         case "Datasets with Recency < One Day":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is a percentage of total datasets having recency less than or equal to one day in the selected data source by default or based on the applied filters. The card is applicable at a dataset or data source only.</p>
                      </>
                     );
         case "Datasets with Recency < One Week":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is a percentage of total datasets having recency less than or equal to one week in the selected data source by default or based on the applied filters. The card is applicable at a dataset or data source only.</p>
                      </>
                     );
         case "Datasets with Recency < One Month":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is a percentage of total datasets having recency less than or equal to one month in the selected data source by default or based on the applied filters. The card is applicable at a dataset or data source only.</p>
                      </>
                     );
         case "Datasets with Recency > One Month":
               return(<><h4 className="text-info">{name}</h4>
	                    <p>The value shown on this card is a percentage of total datasets having recency greater than one month in the selected data source by default or based on the applied filters. The card is applicable at a dataset or data source only.</p>
                      </>
                     );
         case "URL Conformity Errors":
               return(<><h4 className="text-info">{name}</h4>
                          <p>The value shown on this card is an average of the conformity errors on the attributes auto-detected as “URL” in the selected data source by default or the attributes present as per the applied filters.</p>
                      </>
                     );
         case "SSN Conformity Errors":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is an average of the conformity errors on the attributes auto-detected as “SSN” in the selected data source by default or the attributes present as per the applied filters.</p>
                      </>
                     );
         case "IP Conformity Errors":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is an average of the conformity errors on the attributes auto-detected as “IP” in the selected data source by default or the attributes present as per the applied filters.</p>
                      </>
                     );
         case "Http Conformity Errors":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is an average of the conformity errors on the attributes auto-detected to contain “HTTP response” in the selected data source by default or in the attributes present as per the applied filters.</p>
                      </>
                     );
         case "Gender Conformity Errors":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is an average of the conformity errors on the attributes auto-detected as “gender” in the selected data source by default or in the attributes present as per the applied filters.</p>
                      </>
                     );
         case "Email Conformity Errors":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is an average of the conformity errors on the attributes auto-detected as “email” in the selected data source by default or the attributes present as per the applied filters.</p>
                      </>
                     );
         case "Date Conformity Errors":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is an average of the conformity errors on the attributes auto-detected as “date” in the selected data source by default or the attributes present as per the applied filters.</p>
                      </>
                     );
         case "Normalization Consistency":
               return(<><h4 className="text-info">{name}</h4>
                          <h5>Visualization</h5>
                          <p>1. Visualization captures the different data accuracy issues present across the data source at a glimse.</p>
	                      <p>2. Different types of accuracy issues are represented as bigger circles and are surrounded by the attribute names affected by the respective issue.</p>
                      </>
                     );
         case "No. of Datasets having Duplicate":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is the count of datasets having duplicate rows in the selected data source by default or based on the applied filters.</p>
                      </>
                     );
         case "Boolean Conformity Errors":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is an average of the conformity errors on the attributes auto-detected as “boolean” in the selected data source by default or the attributes present as per the applied filters.</p>
                     </>
                     );
         case "Total no of attributes having duplicate values":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is the count of attributes having duplicate values in the selected data source by default or based on the applied filters.</p>
                     </>
                     );
         case "Total no of duplicate rows in Datasets":
               return(<><h4 className="text-info">{name}</h4>
                        <p>The value shown on this card is the count of rows having duplicate values in the selected data source by default or based on the applied filters.</p>
                      </>
                      );
         default:
                  return ''

      }

  }

  render() {

     return (
              <Modal show={this.props.show} onHide={this.props.onHide} dialogClassName="modal-vertical shadow" className="fixed-right model__help" size="md">
                  <Modal.Header className="modal-header py-3 align-items-center bg-dark text-white" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                              <h4 className="mb-0"><i><FontAwesomeIcon icon={faQuestionCircle}/></i>Help</h4>
                        </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="qd-search-bar d-none">
                        <div className="input-group border">
                          <div className="input-group-prepend">
                            <button id="button-addon4" type="button" className="btn"><FontAwesomeIcon icon={faSearch}/></button>
                          </div>
                          <input type="search" placeholder="Search" className="form-control"/>
                        </div>
                    </div>
                    <div className="model__help-content">
                        {this.props.page === "model" ? this.renderModelContent(this.props.name): this.renderContent(this.props.name)}
                     </div>
                  </Modal.Body>
                  {/* <Modal.Footer>
                   </Modal.Footer> */}
              </Modal>
    );
  }
}

export default CardHelpContent;
