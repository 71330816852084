import React from "react";
import moment from 'moment';
import Image from '../../components/image';
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faDownload} from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
//faFileExport
// import { faAngleDown, faCalendarAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from 'react-bootstrap/Dropdown';
import { getApi } from "../../utils/event_handling";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// import Form from 'react-bootstrap/Form';
import $ from "jquery";
import '../../charts/dqreportCharts/dqreports.css'
import ReactMonthPicker from "react-month-picker";
import "../../../node_modules/react-month-picker/css/month-picker.css"
import DQReports from "../../charts/dqreportCharts/dqReports"
import { Button } from "react-bootstrap";
import Load from "../../components/loadAction";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import Tab from 'react-bootstrap/Tab';
// import Nav from 'react-bootstrap/Nav';
// import Chart1 from "../../assets/img/chart_1.png";
// import Chart2 from "../../assets/img/chart_2.png";
// import Chart3 from "../../assets/img/chart_3.png";
// import Chart4 from "../../assets/img/chart_4.png";
// import Chart5 from "../../assets/img/chart_5.png";

const MONTH_ARR = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
]
const range = {
    min: { year: "2022", month: "12" },
    max: { year: "2024", month: "12" }
};


class DQReportFullScreen extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.getApi = getApi.bind(this);
        this.pickAMonth = React.createRef()
        let startDate = moment().subtract(30, 'days');
        this.state = {
            fullScreenData: {},
            isDrxReportFullscreenMode: this.props.isDrxReportFullscreenMode,
            dq_reports_data: "",
            current_month: '',
            previous_month: '',
            previous_of_previous_month: '',
            current_report_month: '',
            monthviewyear: startDate.format("YYYY"),
            monthviewmonth: startDate.format("MMM"),
            filtermonthviewyear: startDate.format("YYYY"),
            filtermonthviewmonth: startDate.format("MMM"),
            startDate:startDate.format('YYYY-MM-DD 00:00:00'),
            isVisible: false,
            filter_selected:"top_25",
            filter_selected_label:"Top 25",

            apicall:"loading",
            err:"",
            singleValue: {year: 2014, month: 11},
        }
        this.getData = this.getData.bind(this);
        this.showMonthPicker = this.showMonthPicker.bind(this);
        this.handleOnDismiss = this.handleOnDismiss.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleTopFilterChange = this.handleTopFilterChange.bind(this);
        this.applyFilter    = this.applyFilter.bind(this);
        this.saveimage      = this.saveimage.bind(this);
        this.savepdf        = this.savepdf.bind(this);
    }
    handleTopFilterChange(event){
       // this.setState({ filter_selected:event.currentTarget.name});
        //alert(event.currentTarget.name);
        let label='';
        this.setState({ filter_selected: event.currentTarget.name }, function() {
         
        if(this.state.filter_selected === "all"){
           label='All';
        }
        else if(this.state.filter_selected === "top_25"){
           label='Top 25';
        }
        else if(this.state.filter_selected === "top_50"){
           label='Top 50';
        }
        else if(this.state.filter_selected === "top_75"){
           label='Top 75';
        }
        else if(this.state.filter_selected === "top_100"){
           label='Top 100';
        }
        this.setState({ filter_selected_label:label});
        this.getData();
        });
    }
    pdfoveralldownload() {
        var HTML_Width = $(".canvas_div_pdf").width();
        var HTML_Height = $(".canvas_div_pdf").height();
        var top_left_margin = 15;
        var PDF_Width = HTML_Width + (top_left_margin * 2);
        var PDF_Height = (PDF_Width * 1.2) + (top_left_margin * 2);
        var canvas_image_width = HTML_Width;
        var canvas_image_height = HTML_Height;
        var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
        var pdf;
        html2canvas($(".canvas_div_pdf")[0], { allowTaint: true }).then(function (canvas) {
            canvas.getContext('2d');
            var imgData = canvas.toDataURL("image/jpeg", 2.0);
            pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'PNG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
            for (var i = 1; i <= totalPDFPages; i++) {
                pdf.addPage(PDF_Width, PDF_Height);
                pdf.addImage(imgData, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
            }
            pdf.save("dq_reports.pdf");
        });
    }

    saveimage(event) {
        let monthviewmonth = this.state.filtermonthviewmonth;
        let monthviewyear  = this.state.filtermonthviewyear;
        let widgetid = event.target.getAttribute("data-widgetid");
        let widgetname = event.target.getAttribute("data-widgetname");
        setTimeout(function () {
            const captureElement = document.querySelector('#'+widgetid)
            html2canvas(captureElement)
                .then(canvas => {
                    canvas.style.display = 'none'
                    document.body.appendChild(canvas)
                    return canvas
                })
                .then(canvas => {
                    const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
                    const a = document.createElement('a')
                    a.setAttribute('download', monthviewmonth+"_"+monthviewyear+'_'+widgetname+'.png')
                    a.setAttribute('href', image)
                    a.click()
                    canvas.remove()
                })
        }, 200);
    }
    savepdf(event) {
        $("body").click();
        let monthviewmonth = this.state.filtermonthviewmonth;
        let monthviewyear  = this.state.filtermonthviewyear;
        let widgetid = event.target.getAttribute("data-widgetid");
        let widgetname = event.target.getAttribute("data-widgetname");
        setTimeout(function () {
        var HTML_Width = $("#"+widgetid).width();
        var HTML_Height = $("#"+widgetid).height();
        var top_left_margin = 15;
        var PDF_Width = HTML_Width + (top_left_margin * 2);
        var PDF_Height = (PDF_Width * 1.2) + (top_left_margin * 2);
        var canvas_image_width = HTML_Width;
        var canvas_image_height = HTML_Height;
        var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
        var pdf;
        html2canvas($("#"+widgetid)[0], { allowTaint: true }).then(function (canvas) {
            canvas.getContext('2d');
            var imgData = canvas.toDataURL("image/jpeg", 2.0);
            pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'PNG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
            for (var i = 1; i <= totalPDFPages; i++) {
                pdf.addPage(PDF_Width, PDF_Height);
                pdf.addImage(imgData, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
            }
            pdf.save(monthviewmonth+"_"+monthviewyear+'_'+widgetname+".pdf");
        });

        }, 200);

    }
    copyimage() {

    }

    getChartTitle(pmtitle) {
        if (this.state.dq_reports_data !== "") {
            let monthviewmonth = this.state.filtermonthviewmonth;
            let monthviewyear  = this.state.filtermonthviewyear;
            if(pmtitle === "customchart") {
                // let addtitle = this.state.dq_reports_data.id_name_mapping.chart_title[pmtitle].split("in");
                return "Data Quality in Percentage by Datasource" +" - "+monthviewmonth+" "+monthviewyear;
            }
            else if(pmtitle === "new_issues_title") {
                let addtitle = this.state.dq_reports_data.id_name_mapping.chart_title[pmtitle].split("in");
                return addtitle[0] +" - "+monthviewmonth+" "+monthviewyear;
            }else{
                return this.state.dq_reports_data.id_name_mapping.chart_title[pmtitle]+" - "+monthviewmonth+" "+monthviewyear;
            }
            
        }
    }

    getmappingvalue(pmtype, pmvalue) {
        let dq_reports_data = this.state.dq_reports_data;
        let filterData;
        switch (pmtype) {
            case "environments":
                filterData = dq_reports_data.id_name_mapping.environments;
                break;
            case "datasets":
                filterData = dq_reports_data.id_name_mapping.datasets;
                break;
            case "integrations":
                filterData = dq_reports_data.id_name_mapping.integrations;
                break;
            case "metrics":
                filterData = dq_reports_data.id_name_mapping.metrics;
                break;
            case "metadata":
                filterData = dq_reports_data.id_name_mapping.metadata;
                break;
            default:
                filterData = dq_reports_data.id_name_mapping.metadata;
                break;

        }

        for (let key in filterData) {
            if (parseInt(key) === parseInt(pmvalue)) {
                return filterData[key];
                // break;
            }
        }
    }
    getData() {
        
        this.setState({
        "apicall":"loading",
        "err":"",
        "filtermonthviewyear":this.state.monthviewyear,
        "filtermonthviewmonth":this.state.monthviewmonth
        })

        this.getApi("dq_reports");
    }

    componentDidMount() {
        this.getData();
    }

    getMonthValue() {
        let month = this.state.monthviewmonth
        let year = this.state.monthviewyear
        return month && year ? `${month}-${year}` : "Select Month";
    };

    handleOnDismiss = () => {
        this.setState({ isVisible: !this.state.isVisible });
    };

    handleOnChange = (year, month) => {
        let startDate = moment(1+"/"+MONTH_ARR[month - 1]+"/"+year).format('YYYY-MM-DD 00:00:00')
        this.setState({
                startDate:startDate,
                isVisible: !this.state.isVisible,
                monthviewyear: year,
                monthviewmonth: MONTH_ARR[month - 1]
     }, function() {
            this.applyFilter();
          });

        // let startDate = moment(1+"/"+MONTH_ARR[month - 1]+"/"+year).format('YYYY-MM-DD 00:00:00')
        // this.setState({
        //     startDate:startDate,
        //     isVisible: !this.state.isVisible,
        //     monthviewyear: year,
        //     monthviewmonth: MONTH_ARR[month - 1],
        // });
        // this.applyFilter()
    };

    showMonthPicker() {
        this.setState({ isVisible: !this.state.isVisible });
    };

    applyFilter(){
        $("body").click();
         let label=''; 
         if(this.state.filter_selected === "all"){
            label='All';
         }
         else if(this.state.filter_selected === "top_25"){
            label='Top 25';
         }
         else if(this.state.filter_selected === "top_50"){
            label='Top 50';
         }
         else if(this.state.filter_selected === "top_75"){
            label='Top 75';
         }
         else if(this.state.filter_selected === "top_100"){
            label='Top 100';
         }
         this.setState({ filter_selected_label:label});
         this.getData();
    }
    applyCancel(){
        $("body").click();
    }

    render() {
        const {filter_selected} = this.state;
        return (
            <>
            <div className={"qd-fullscreen qd-report-fullscreen fade " + this.props.fullScreenClassVal}>
                <div className="qd-fs_dialog">
                    <div className="qd-fs_content">
                        <div className="qd-fs_header">
                            <div className="header_content">
                                <Image
                                    className="qd-header__brand-logo-default"
                                    src="logo"
                                />
                                <h4 className="header_title">Data Quality Report <sup><Badge>Beta</Badge></sup></h4>
                            </div>
                            <div className="actions">
                                <ButtonGroup className="report-filter-btn-group" >
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light-blue" id="dropdown-basic">
                                            {this.state.filter_selected_label}
                                            <i>
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_6900_9165)">
                                                    <path d="M11.9104 6.96742L9.00035 9.87742L6.09035 6.96742C5.79785 6.67492 5.32535 6.67492 5.03285 6.96742C4.74035 7.25992 4.74035 7.73242 5.03285 8.02492L8.47535 11.4674C8.76785 11.7599 9.24035 11.7599 9.53285 11.4674L12.9753 8.02492C13.2678 7.73242 13.2678 7.25992 12.9753 6.96742C12.6829 6.68242 12.2029 6.67492 11.9104 6.96742Z" fill="black"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_6900_9165">
                                                    <rect width="18" height="18" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item name="all" onClick={this.handleTopFilterChange} active={filter_selected === 'all'}>All</Dropdown.Item>
                                            <Dropdown.Item name="top_25" onClick={this.handleTopFilterChange} active={filter_selected === 'top_25'}>Top 25</Dropdown.Item>
                                            <Dropdown.Item name="top_50" onClick={this.handleTopFilterChange} active={filter_selected === 'top_50'}>Top 50</Dropdown.Item>
                                            <Dropdown.Item name="top_75" onClick={this.handleTopFilterChange} active={filter_selected === 'top_75'}>Top 75</Dropdown.Item>
                                            <Dropdown.Item name="top_100" onClick={this.handleTopFilterChange} active={filter_selected === 'top_100'}>Top 100</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Button variant="light-blue" className="p-0">
                                        <div className="MonthYearPicker mb-0">
                                            <div onClick={this.showMonthPicker}>
                                            <i>
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </i>
                                            <span className="ml-2 d-none d-md-inline-block">{this.getMonthValue()}</span>
                                            </div>

                                            <ReactMonthPicker
                                                show={this.state.isVisible}
                                                lang={MONTH_ARR}
                                                years={range}
                                                className="month-picker-align-right"
                                                value={this.state.monthviewmonth + "-" + this.state.monthviewyear}
                                                onChange={this.handleOnChange}
                                                onDismiss={this.handleOnDismiss}
                                            />
                                        </div>
                                    </Button>
                                </ButtonGroup>
                                <div className="actions_icon ml-2" title="Download">
                                    <FontAwesomeIcon icon={faDownload}/>
                                </div>
                                {/* <Dropdown className="qd_filter-btn">
                                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                                        <Image
                                            src="icon_filter"
                                        />
                                        <span className="ml-0 ml-lg-2">Filter</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-right dropdown-menu-sm">
                                        <div className="qd_filter__body">
                                            <Form>
                                            <Form.Group className="mb-2">
                                                <Form.Label>Choose top level</Form.Label>
                                                <div className="d-block">
                                                    <Form.Check
                                                        type="radio"
                                                        label="All"
                                                        value="all"
                                                        name="reportlevel"
                                                        checked={this.state.filter_selected === "all" ? "checked" : ""}
                                                        onChange={this.handleTopFilterChange}
                                                        className={this.state.filter_selected === "all" ? "custom-radio custom-control custom-radio-outlined --is-checked" : "custom-radio custom-control custom-radio-outlined"}
                                                        id="reportlevel5"
                                                    /> 
                                                    <Form.Check
                                                        type="radio"
                                                        label="Top 25"
                                                        value="top_25"
                                                        name="reportlevel"
                                                        id="reportlevel1"
                                                        onChange={this.handleTopFilterChange}
                                                        checked={this.state.filter_selected === "top_25" ? "checked" : ""}
                                                        className={this.state.filter_selected === "top_25" ? "custom-radio custom-control custom-radio-outlined --is-checked" : "custom-radio custom-control custom-radio-outlined"}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label="Top 50"
                                                        value="top_50"
                                                        name="reportlevel"
                                                        onChange={this.handleTopFilterChange}
                                                        checked={this.state.filter_selected === "top_50" ? "checked" : ""}
                                                        className={this.state.filter_selected === "top_50" ? "custom-radio custom-control custom-radio-outlined --is-checked" : "custom-radio custom-control custom-radio-outlined"}
                                                        id="reportlevel2"
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label="Top 75"
                                                        value="top_75"
                                                        name="reportlevel"
                                                        onChange={this.handleTopFilterChange}
                                                        checked={this.state.filter_selected === "top_75" ? "checked" : ""}
                                                        className={this.state.filter_selected === "top_75" ? "custom-radio custom-control custom-radio-outlined --is-checked" : "custom-radio custom-control custom-radio-outlined"}
                                                        id="reportlevel3"
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label="Top 100"
                                                        value="top_100"
                                                        name="reportlevel"
                                                        onChange={this.handleTopFilterChange}
                                                        checked={this.state.filter_selected === "top_100" ? "checked" : ""}
                                                        className={this.state.filter_selected === "top_100" ? "custom-radio custom-control custom-radio-outlined --is-checked" : "custom-radio custom-control custom-radio-outlined"}
                                                        id="reportlevel4"
                                                    />   
                                                </div>       
                                            </Form.Group>
                                                <h4 className="filter_title">Select Month</h4>
                                                <div className="MonthYearPicker mb-3">
                                                    <div onClick={this.showMonthPicker}>
                                                    <i>
                                                       <FontAwesomeIcon icon={faCalendarAlt}/>
                                                    </i>
                                                    <span className="ml-2 d-none d-md-inline-block">{this.getMonthValue()}</span>
                                                    </div>

                                                    <ReactMonthPicker
                                                        show={this.state.isVisible}
                                                        lang={MONTH_ARR}
                                                        years={range}
                                                        value={this.state.monthviewmonth + "-" + this.state.monthviewyear}
                                                         onChange={this.handleOnChange}
                                                         onDismiss={this.handleOnDismiss}
                                                    />
                                                </div>
                                            </Form>
                                        </div>
                                        <div className="d-flex justify-content-end border-top p-3">
                                            <Button onClick={this.applyCancel}className="btn-outline btn-grey btn-circle mr-2">Cancel</Button>
                                            <Button onClick={this.applyFilter} type="submit" className="btn-primary btn-circle"><FontAwesomeIcon icon={faCheck} /> Apply</Button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown> */}

                                {/* <OverlayTrigger placement="bottom"
                                    overlay={
                                        <Tooltip>
                                            Download Report
                                        </Tooltip>
                                    }>
                                    <button className="btn btn-extralight-blue px-2 border-0 ml-2" type="button" onClick={this.pdfoveralldownload}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <rect id="Rectangle_2867" data-name="Rectangle 2867" width="18" height="18" transform="translate(2059 -1127)" fill="none" />
                                                </clipPath>
                                            </defs>
                                            <g id="Group_5021" data-name="Group 5021" transform="translate(-2056 1130)">
                                                <rect id="Rectangle_2866" data-name="Rectangle 2866" width="24" height="24" transform="translate(2056 -1130)" fill="none" />
                                                <g id="Mask_Group_21" data-name="Mask Group 21" clip-path="url(#clip-path)">
                                                    <path id="download-solid" d="M7.594,0h2.813a.842.842,0,0,1,.844.844V6.75h3.083a.7.7,0,0,1,.5,1.2L9.482,13.3a.681.681,0,0,1-.96,0L3.168,7.949a.7.7,0,0,1,.5-1.2H6.75V.844A.842.842,0,0,1,7.594,0ZM18,13.219v3.938a.842.842,0,0,1-.844.844H.844A.842.842,0,0,1,0,17.156V13.219a.842.842,0,0,1,.844-.844H6L7.724,14.1a1.8,1.8,0,0,0,2.552,0L12,12.375h5.157A.842.842,0,0,1,18,13.219Zm-4.359,3.094a.7.7,0,1,0-.7.7A.705.705,0,0,0,13.641,16.313Zm2.25,0a.7.7,0,1,0-.7.7A.705.705,0,0,0,15.891,16.313Z" transform="translate(2059 -1127)" fill="#a1a5b7" />
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </OverlayTrigger> */}
                            </div>
                            <button type="button" onClick={this.props.closedrxReportfullscreen}
                                className="close" aria-label="Close">
                                <span aria-hidden="true" />
                                <span className="sr-only">Close</span>
                            </button>
                        </div>

                        <div className="qd-fs_body overflow-auto" >
                            <div className="qd-grid">
                                <div className="qd-grid-item qd-section">
                                    <div className="row">
                                        <div id="testpdf" className="mt4 col-xl-8 offset-xl-2 canvas_div_pdf">
                                            {/* <Tab.Container defaultActiveKey="dq-reports"> */}
                                                {/* <div className="tabbable-line">
                                                    <Nav variant="tabs" id="page-nav-tabs">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="dq-reports" >
                                                                <span className="qd-menu__link-text">Data Quality Report</span>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="cost-analysis">
                                                                <span className="qd-menu__link-text">Cost Analysis Report</span>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div> */}
                                                {/* <Tab.Content className="pt-2">
                                                    <Tab.Pane eventKey="dq-reports"> */}
                                                        <div className={this.state.err === "No Data Found" || this.state.apicall === "loading" ? "qd-report-card" : "qd-report-card  d-none"}>
                                                        <div className="rc-empty-state p-4 p-sm-5">
                                                            
                                                            
                                                            <p className="rc-empty-state_text">
                                                            {this.state.err === "No Data Found" ? 
                                                                <>
                                                                <div className="rc-empty-state_icon">
                                                                <Image src="icon_empty_state" />
                                                            </div> No data available </> : "" }
                                                            {
                                                                this.state.apicall === "loading" ? <Load/> : ""
                                                            }   
                                                            </p>
                                                            {/* <a href="#" className="rc-empty-state_link">Link dataset</a> */}
                                                        </div>
                                                        </div>
                                                        <>
                                                        {/* Report Card 1 */}
                                                        <div className={this.state.err === "No Data Found" || this.state.apicall === "loading" ? "qd-report-card d-none" : "qd-report-card"} id="widget1">
                                                            <div className="qd-rc_top">
                                                                <h1 className="qd-rc_title">{this.getChartTitle("new_issues_title")}</h1>
                                                                {/*<Dropdown className="qd-rc_dropdown">
                                                                    <Dropdown.Toggle variant=" " id="dropdown-basic" className="p-1"></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" onClick={this.saveimage} data-widgetid="widget1" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160320" data-name="Group 160320" transform="translate(-2 -2)">
                                                                                        <g id="Group_160319" data-name="Group 160319" transform="translate(2 2)">
                                                                                            <path id="Path_83115" data-name="Path 83115" d="M9.705,12.995c.006.006.015.008.022.014a.691.691,0,0,0,.205.137h0a.7.7,0,0,0,.761-.152l3.5-3.5a.7.7,0,1,0-.99-.99L10.9,10.81V2.7a.7.7,0,1,0-1.4,0v8.11L7.195,8.505a.7.7,0,1,0-.99.99Z" transform="translate(-3.2 -2)" fill="#747474" />
                                                                                            <path id="Path_83116" data-name="Path 83116" d="M14.6,15.7v2.1a.7.7,0,0,1-.7.7H4.1a.7.7,0,0,1-.7-.7V15.7a.7.7,0,0,0-1.4,0v2.1a2.1,2.1,0,0,0,2.1,2.1h9.8A2.1,2.1,0,0,0,16,17.8V15.7a.7.7,0,1,0-1.4,0Z" transform="translate(-2 -5.9)" fill="#747474" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={this.savepdf} data-widgetid="widget1" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.72" viewBox="0 0 392.528 482.139">
                                                                                    <g id="icon_pdf" transform="translate(-44.806)">
                                                                                        <g id="Group_159867" data-name="Group 159867">
                                                                                            <path id="Path_82935" data-name="Path 82935" d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782a34.005,34.005,0,0,0-8.132.773v25.69a30.811,30.811,0,0,0,6.588.5C135.57,324.379,142.024,319.1,142.024,310.194Z" fill="#707070" />
                                                                                            <path id="Path_82936" data-name="Path 82936" d="M202.709,297.681a40.555,40.555,0,0,0-8.9.772v56.9a35.6,35.6,0,0,0,6.841.394c17.809.126,29.424-9.677,29.424-30.449C230.2,307.231,219.611,297.681,202.709,297.681Z" fill="#707070" />
                                                                                            <path id="Path_82937" data-name="Path 82937" d="M315.458,0H121.811A51.39,51.39,0,0,0,70.5,51.315V241.069H65.484a20.675,20.675,0,0,0-20.678,20.679v125.4a20.673,20.673,0,0,0,20.678,20.677H70.5v22.995a51.37,51.37,0,0,0,51.315,51.315H386.034a51.37,51.37,0,0,0,51.3-51.315V121.449ZM99.053,284.379a162.089,162.089,0,0,1,26.579-1.8c12.128,0,20.772,2.315,26.58,6.965a25.422,25.422,0,0,1,9.292,20.127c0,8.51-2.837,15.745-8,20.646-6.714,6.32-16.643,9.157-28.258,9.157a50.931,50.931,0,0,1-6.714-.379v31.1H99.053ZM386.034,450.713H121.811a19.906,19.906,0,0,1-19.874-19.889V407.829h246.31a20.674,20.674,0,0,0,20.679-20.677v-125.4a20.675,20.675,0,0,0-20.679-20.679H101.937V51.315a19.9,19.9,0,0,1,19.874-19.858l181.89-.19V98.5a35.6,35.6,0,0,0,35.587,35.587l65.862-.189.741,296.925A19.892,19.892,0,0,1,386.034,450.713ZM174.065,369.8V284.379a177.116,177.116,0,0,1,26.58-1.8c16.516,0,27.226,2.963,35.618,9.282,9.031,6.714,14.7,17.416,14.7,32.781,0,16.643-6.06,28.133-14.453,35.224-9.157,7.612-23.1,11.222-40.125,11.222A170.242,170.242,0,0,1,174.065,369.8Zm140.827-50.575v16h-31.23V370.2h-19.74V283.229h53.16v16.122h-33.42v19.875Z" fill="#707070" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as PDF</Dropdown.Item>
                                                                        <Dropdown.Item href="#">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="icon_copy" transform="translate(-1 -1)">
                                                                                        <path id="Path_82939" data-name="Path 82939" d="M12,13a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V6A2,2,0,0,1,3,4V5A1.014,1.014,0,0,0,2,6v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1ZM6,2A1.014,1.014,0,0,0,5,3v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1V3a1.014,1.014,0,0,0-1-1ZM6,1h7a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V3A2,2,0,0,1,6,1Z" fill="rgba(112,112,112,0.96)" />
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Copy as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160345" data-name="Group 160345" transform="translate(-1 -1)">
                                                                                        <g id="Group_160323" data-name="Group 160323" transform="translate(10.958 1)">
                                                                                            <g id="Group_160322" data-name="Group 160322" transform="translate(0 0)">
                                                                                                <g id="Group_160321" data-name="Group 160321">
                                                                                                    <path id="Path_83117" data-name="Path 83117" d="M48.69,5.042a.453.453,0,0,1-.452-.452V1.9H45.552a.452.452,0,0,1,0-.9H48.69a.453.453,0,0,1,.452.452V4.59A.453.453,0,0,1,48.69,5.042Z" transform="translate(-45.1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160326" data-name="Group 160326" transform="translate(1 10.958)">
                                                                                            <g id="Group_160325" data-name="Group 160325" transform="translate(0)">
                                                                                                <g id="Group_160324" data-name="Group 160324">
                                                                                                    <path id="Path_83118" data-name="Path 83118" d="M4.59,49.142H1.452A.453.453,0,0,1,1,48.69V45.552a.452.452,0,0,1,.9,0v2.687H4.59a.452.452,0,1,1,0,.9Z" transform="translate(-1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160329" data-name="Group 160329" transform="translate(10.958 10.958)">
                                                                                            <g id="Group_160328" data-name="Group 160328">
                                                                                                <g id="Group_160327" data-name="Group 160327">
                                                                                                    <path id="Path_83119" data-name="Path 83119" d="M48.69,49.142H45.552a.452.452,0,0,1,0-.9h2.687V45.552a.452.452,0,0,1,.9,0V48.69A.453.453,0,0,1,48.69,49.142Z" transform="translate(-45.1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160332" data-name="Group 160332" transform="translate(1 1)">
                                                                                            <g id="Group_160331" data-name="Group 160331" transform="translate(0 0)">
                                                                                                <g id="Group_160330" data-name="Group 160330">
                                                                                                    <path id="Path_83120" data-name="Path 83120" d="M1.452,5.042A.453.453,0,0,1,1,4.59V1.452A.453.453,0,0,1,1.452,1H4.59a.452.452,0,0,1,0,.9H1.9V4.59A.453.453,0,0,1,1.452,5.042Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160335" data-name="Group 160335" transform="translate(1 1)">
                                                                                            <g id="Group_160334" data-name="Group 160334" transform="translate(0)">
                                                                                                <g id="Group_160333" data-name="Group 160333">
                                                                                                    <path id="Path_83121" data-name="Path 83121" d="M5.923,6.374a.446.446,0,0,1-.316-.135L1.135,1.768a.437.437,0,0,1,0-.632.437.437,0,0,1,.632,0L6.239,5.606a.437.437,0,0,1,0,.632.446.446,0,0,1-.316.135Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160338" data-name="Group 160338" transform="translate(9.626 9.648)">
                                                                                            <g id="Group_160337" data-name="Group 160337">
                                                                                                <g id="Group_160336" data-name="Group 160336">
                                                                                                    <path id="Path_83122" data-name="Path 83122" d="M44.123,44.652a.446.446,0,0,1-.316-.135l-4.471-4.448a.447.447,0,1,1,.632-.632l4.471,4.471a.437.437,0,0,1,0,.632.54.54,0,0,1-.316.113Z" transform="translate(-39.2 -39.3)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160341" data-name="Group 160341" transform="translate(9.626 1)">
                                                                                            <g id="Group_160340" data-name="Group 160340" transform="translate(0 0)">
                                                                                                <g id="Group_160339" data-name="Group 160339">
                                                                                                    <path id="Path_83123" data-name="Path 83123" d="M39.652,6.374a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.437.437,0,0,1,.632,0,.437.437,0,0,1,0,.632L39.99,6.239a.46.46,0,0,1-.339.135Z" transform="translate(-39.2 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160344" data-name="Group 160344" transform="translate(1 9.626)">
                                                                                            <g id="Group_160343" data-name="Group 160343" transform="translate(0)">
                                                                                                <g id="Group_160342" data-name="Group 160342">
                                                                                                    <path id="Path_83124" data-name="Path 83124" d="M1.452,44.574a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.447.447,0,1,1,.632.632L1.768,44.439a.446.446,0,0,1-.316.135Z" transform="translate(-1 -39.2)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Open in Fullscreen</Dropdown.Item> 
                                                                    </Dropdown.Menu>
                                                                </Dropdown>*/}
                                                            </div>
                                                            <p className="qd-rc_desc">This chart gives the breakdown of the number of Data Quality issues in each environment over a 3 month period.</p>
                                                            <div className="qd-rc_body">
                                                                {/* <img src={reportChart1} className="d-none" style={{ width: '90%' }} /> */}
                                                                <div id="newissue"></div>
                                                                {
                                                                    this.state.dq_reports_data !== "" ?
                                                                        <DQReports
                                                                            chartid="newissue"
                                                                            chartdata={this.state.dq_reports_data}
                                                                            monthviewyear={this.state.filtermonthviewyear}
                                                                            monthviewmonth={this.state.filtermonthviewmonth}
                                                                        />
                                                                        : ""
                                                                }
                                                                <ul class="legend">
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#F68B96'}}></span>New Issue</li>
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#6A91BE'}}></span>Old Issues</li>
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#6FC4DF'}}></span>Solved Issues</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        {/* Report Card 2 */}
                                                        <div className={this.state.err === "No Data Found" || this.state.apicall === "loading" ? "qd-report-card d-none" : "qd-report-card"} id="widget2" >
                                                            <div className="qd-rc_top">
                                                                <h1 className="qd-rc_title">{this.getChartTitle("env_title")}</h1>
                                                                <Dropdown className="qd-rc_dropdown d-none">
                                                                    <Dropdown.Toggle variant=" " id="dropdown-basic" className="p-1"></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" onClick={this.saveimage} data-widgetid="widget2" data-widgetname="Environments data quality issues">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160320" data-name="Group 160320" transform="translate(-2 -2)">
                                                                                        <g id="Group_160319" data-name="Group 160319" transform="translate(2 2)">
                                                                                            <path id="Path_83115" data-name="Path 83115" d="M9.705,12.995c.006.006.015.008.022.014a.691.691,0,0,0,.205.137h0a.7.7,0,0,0,.761-.152l3.5-3.5a.7.7,0,1,0-.99-.99L10.9,10.81V2.7a.7.7,0,1,0-1.4,0v8.11L7.195,8.505a.7.7,0,1,0-.99.99Z" transform="translate(-3.2 -2)" fill="#747474" />
                                                                                            <path id="Path_83116" data-name="Path 83116" d="M14.6,15.7v2.1a.7.7,0,0,1-.7.7H4.1a.7.7,0,0,1-.7-.7V15.7a.7.7,0,0,0-1.4,0v2.1a2.1,2.1,0,0,0,2.1,2.1h9.8A2.1,2.1,0,0,0,16,17.8V15.7a.7.7,0,1,0-1.4,0Z" transform="translate(-2 -5.9)" fill="#747474" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={this.savepdf} data-widgetid="widget2" data-widgetname="Environments data quality issues">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.72" viewBox="0 0 392.528 482.139">
                                                                                    <g id="icon_pdf" transform="translate(-44.806)">
                                                                                        <g id="Group_159867" data-name="Group 159867">
                                                                                            <path id="Path_82935" data-name="Path 82935" d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782a34.005,34.005,0,0,0-8.132.773v25.69a30.811,30.811,0,0,0,6.588.5C135.57,324.379,142.024,319.1,142.024,310.194Z" fill="#707070" />
                                                                                            <path id="Path_82936" data-name="Path 82936" d="M202.709,297.681a40.555,40.555,0,0,0-8.9.772v56.9a35.6,35.6,0,0,0,6.841.394c17.809.126,29.424-9.677,29.424-30.449C230.2,307.231,219.611,297.681,202.709,297.681Z" fill="#707070" />
                                                                                            <path id="Path_82937" data-name="Path 82937" d="M315.458,0H121.811A51.39,51.39,0,0,0,70.5,51.315V241.069H65.484a20.675,20.675,0,0,0-20.678,20.679v125.4a20.673,20.673,0,0,0,20.678,20.677H70.5v22.995a51.37,51.37,0,0,0,51.315,51.315H386.034a51.37,51.37,0,0,0,51.3-51.315V121.449ZM99.053,284.379a162.089,162.089,0,0,1,26.579-1.8c12.128,0,20.772,2.315,26.58,6.965a25.422,25.422,0,0,1,9.292,20.127c0,8.51-2.837,15.745-8,20.646-6.714,6.32-16.643,9.157-28.258,9.157a50.931,50.931,0,0,1-6.714-.379v31.1H99.053ZM386.034,450.713H121.811a19.906,19.906,0,0,1-19.874-19.889V407.829h246.31a20.674,20.674,0,0,0,20.679-20.677v-125.4a20.675,20.675,0,0,0-20.679-20.679H101.937V51.315a19.9,19.9,0,0,1,19.874-19.858l181.89-.19V98.5a35.6,35.6,0,0,0,35.587,35.587l65.862-.189.741,296.925A19.892,19.892,0,0,1,386.034,450.713ZM174.065,369.8V284.379a177.116,177.116,0,0,1,26.58-1.8c16.516,0,27.226,2.963,35.618,9.282,9.031,6.714,14.7,17.416,14.7,32.781,0,16.643-6.06,28.133-14.453,35.224-9.157,7.612-23.1,11.222-40.125,11.222A170.242,170.242,0,0,1,174.065,369.8Zm140.827-50.575v16h-31.23V370.2h-19.74V283.229h53.16v16.122h-33.42v19.875Z" fill="#707070" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as PDF</Dropdown.Item>
                                                                        {/* <Dropdown.Item href="#/action-3">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="icon_copy" transform="translate(-1 -1)">
                                                                                        <path id="Path_82939" data-name="Path 82939" d="M12,13a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V6A2,2,0,0,1,3,4V5A1.014,1.014,0,0,0,2,6v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1ZM6,2A1.014,1.014,0,0,0,5,3v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1V3a1.014,1.014,0,0,0-1-1ZM6,1h7a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V3A2,2,0,0,1,6,1Z" fill="rgba(112,112,112,0.96)" />
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Copy as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160345" data-name="Group 160345" transform="translate(-1 -1)">
                                                                                        <g id="Group_160323" data-name="Group 160323" transform="translate(10.958 1)">
                                                                                            <g id="Group_160322" data-name="Group 160322" transform="translate(0 0)">
                                                                                                <g id="Group_160321" data-name="Group 160321">
                                                                                                    <path id="Path_83117" data-name="Path 83117" d="M48.69,5.042a.453.453,0,0,1-.452-.452V1.9H45.552a.452.452,0,0,1,0-.9H48.69a.453.453,0,0,1,.452.452V4.59A.453.453,0,0,1,48.69,5.042Z" transform="translate(-45.1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160326" data-name="Group 160326" transform="translate(1 10.958)">
                                                                                            <g id="Group_160325" data-name="Group 160325" transform="translate(0)">
                                                                                                <g id="Group_160324" data-name="Group 160324">
                                                                                                    <path id="Path_83118" data-name="Path 83118" d="M4.59,49.142H1.452A.453.453,0,0,1,1,48.69V45.552a.452.452,0,0,1,.9,0v2.687H4.59a.452.452,0,1,1,0,.9Z" transform="translate(-1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160329" data-name="Group 160329" transform="translate(10.958 10.958)">
                                                                                            <g id="Group_160328" data-name="Group 160328">
                                                                                                <g id="Group_160327" data-name="Group 160327">
                                                                                                    <path id="Path_83119" data-name="Path 83119" d="M48.69,49.142H45.552a.452.452,0,0,1,0-.9h2.687V45.552a.452.452,0,0,1,.9,0V48.69A.453.453,0,0,1,48.69,49.142Z" transform="translate(-45.1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160332" data-name="Group 160332" transform="translate(1 1)">
                                                                                            <g id="Group_160331" data-name="Group 160331" transform="translate(0 0)">
                                                                                                <g id="Group_160330" data-name="Group 160330">
                                                                                                    <path id="Path_83120" data-name="Path 83120" d="M1.452,5.042A.453.453,0,0,1,1,4.59V1.452A.453.453,0,0,1,1.452,1H4.59a.452.452,0,0,1,0,.9H1.9V4.59A.453.453,0,0,1,1.452,5.042Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160335" data-name="Group 160335" transform="translate(1 1)">
                                                                                            <g id="Group_160334" data-name="Group 160334" transform="translate(0)">
                                                                                                <g id="Group_160333" data-name="Group 160333">
                                                                                                    <path id="Path_83121" data-name="Path 83121" d="M5.923,6.374a.446.446,0,0,1-.316-.135L1.135,1.768a.437.437,0,0,1,0-.632.437.437,0,0,1,.632,0L6.239,5.606a.437.437,0,0,1,0,.632.446.446,0,0,1-.316.135Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160338" data-name="Group 160338" transform="translate(9.626 9.648)">
                                                                                            <g id="Group_160337" data-name="Group 160337">
                                                                                                <g id="Group_160336" data-name="Group 160336">
                                                                                                    <path id="Path_83122" data-name="Path 83122" d="M44.123,44.652a.446.446,0,0,1-.316-.135l-4.471-4.448a.447.447,0,1,1,.632-.632l4.471,4.471a.437.437,0,0,1,0,.632.54.54,0,0,1-.316.113Z" transform="translate(-39.2 -39.3)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160341" data-name="Group 160341" transform="translate(9.626 1)">
                                                                                            <g id="Group_160340" data-name="Group 160340" transform="translate(0 0)">
                                                                                                <g id="Group_160339" data-name="Group 160339">
                                                                                                    <path id="Path_83123" data-name="Path 83123" d="M39.652,6.374a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.437.437,0,0,1,.632,0,.437.437,0,0,1,0,.632L39.99,6.239a.46.46,0,0,1-.339.135Z" transform="translate(-39.2 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160344" data-name="Group 160344" transform="translate(1 9.626)">
                                                                                            <g id="Group_160343" data-name="Group 160343" transform="translate(0)">
                                                                                                <g id="Group_160342" data-name="Group 160342">
                                                                                                    <path id="Path_83124" data-name="Path 83124" d="M1.452,44.574a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.447.447,0,1,1,.632.632L1.768,44.439a.446.446,0,0,1-.316.135Z" transform="translate(-1 -39.2)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Open in Fullscreen</Dropdown.Item> */}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                            <p className="qd-rc_desc">This chart gives the trend of the total number of Data Quality issues in each environment over a 3 month period</p>
                                                            <div className="qd-rc_body">

                                                                <div className="qd-heatmap-table table-responsive" >
                                                                    `<table class="table" >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Environment</th>
                                                                                <th id="dataqualityissuebyenviromentthismonth"></th>
                                                                                <th id="dataqualityissuebyenviromentpreviousmonth"></th>
                                                                                <th id="dataqualityissuebyenviromentpreviousofpreviousmonth"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="dataqualityissuebyenviromentheatmap">
                                                                        </tbody>
                                                                    </table>
                                                                    <br />
                                                                    <div class="dq-report-graph-title">Data quality issues by Environment</div>
                                                                    <div id="dataqualityissuebyenviromentheatmaplegend"></div>
                                                                    {
                                                                        this.state.dq_reports_data !== "" ?
                                                                            <DQReports
                                                                                chartid="dataqualityissuebyenviromentheatmap"
                                                                                chartdata={this.state.dq_reports_data}
                                                                                monthviewyear={this.state.filtermonthviewyear}
                                                                                monthviewmonth={this.state.filtermonthviewmonth}
                                                                            />
                                                                            : ""
                                                                    }
                                                                    {/* <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                    <th className="border-right-0">Enviroinment > Datasource</th>
                                                                    <th>Datasets</th>
                                                                    <th>This month</th>
                                                                    <th>March 2022</th>
                                                                    <th>February 2022</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                    <td rowspan="6" className="text-dark text-center border-right-0">My Environment  Datasource 1</td>
                                                                    <td>My Environment</td>
                                                                    <td className="text-black" style={{backgroundColor: "rgba(0, 255, 0, 1)"}}><span
                                                                        className="caret caret-up"></span>(13.44%)</td>
                                                                    </tr>
                                                                </tbody>
                                                                </table> */}
                                                                </div>
                                                                <hr />
                                                                <div className={"center-block"} style={{ "width": "100%", "height": "520px" }} id="dataqualityissuebyenviromentdonut">
                                                                </div>
                                                                <br />
                                                                {
                                                                    this.state.dq_reports_data !== "" ?
                                                                        <DQReports
                                                                            chartid="dataqualityissuebyenviromentdonut"
                                                                            chartdata={this.state.dq_reports_data}
                                                                            monthviewyear={this.state.filtermonthviewyear}
                                                                            monthviewmonth={this.state.filtermonthviewmonth}

                                                                        />
                                                                        : ""
                                                                }
                                                                <div class="dq-report-graph-title">Data quality issues by Environment</div>
                                                            </div>
                                                        </div>
                                                        {/* Report Card 3 */}
                                                        <div className={this.state.err === "No Data Found" || this.state.apicall === "loading" ? "qd-report-card d-none" : "qd-report-card"} id="widget3">
                                                            <div className="qd-rc_top">
                                                                <h1 className="qd-rc_title">{this.getChartTitle("integration_title")}</h1>
                                                                <Dropdown className="qd-rc_dropdown d-none">
                                                                    <Dropdown.Toggle variant=" " id="dropdown-basic" className="p-1"></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" onClick={this.saveimage} data-widgetid="widget3" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160320" data-name="Group 160320" transform="translate(-2 -2)">
                                                                                        <g id="Group_160319" data-name="Group 160319" transform="translate(2 2)">
                                                                                            <path id="Path_83115" data-name="Path 83115" d="M9.705,12.995c.006.006.015.008.022.014a.691.691,0,0,0,.205.137h0a.7.7,0,0,0,.761-.152l3.5-3.5a.7.7,0,1,0-.99-.99L10.9,10.81V2.7a.7.7,0,1,0-1.4,0v8.11L7.195,8.505a.7.7,0,1,0-.99.99Z" transform="translate(-3.2 -2)" fill="#747474" />
                                                                                            <path id="Path_83116" data-name="Path 83116" d="M14.6,15.7v2.1a.7.7,0,0,1-.7.7H4.1a.7.7,0,0,1-.7-.7V15.7a.7.7,0,0,0-1.4,0v2.1a2.1,2.1,0,0,0,2.1,2.1h9.8A2.1,2.1,0,0,0,16,17.8V15.7a.7.7,0,1,0-1.4,0Z" transform="translate(-2 -5.9)" fill="#747474" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={this.savepdf} data-widgetid="widget3" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.72" viewBox="0 0 392.528 482.139">
                                                                                    <g id="icon_pdf" transform="translate(-44.806)">
                                                                                        <g id="Group_159867" data-name="Group 159867">
                                                                                            <path id="Path_82935" data-name="Path 82935" d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782a34.005,34.005,0,0,0-8.132.773v25.69a30.811,30.811,0,0,0,6.588.5C135.57,324.379,142.024,319.1,142.024,310.194Z" fill="#707070" />
                                                                                            <path id="Path_82936" data-name="Path 82936" d="M202.709,297.681a40.555,40.555,0,0,0-8.9.772v56.9a35.6,35.6,0,0,0,6.841.394c17.809.126,29.424-9.677,29.424-30.449C230.2,307.231,219.611,297.681,202.709,297.681Z" fill="#707070" />
                                                                                            <path id="Path_82937" data-name="Path 82937" d="M315.458,0H121.811A51.39,51.39,0,0,0,70.5,51.315V241.069H65.484a20.675,20.675,0,0,0-20.678,20.679v125.4a20.673,20.673,0,0,0,20.678,20.677H70.5v22.995a51.37,51.37,0,0,0,51.315,51.315H386.034a51.37,51.37,0,0,0,51.3-51.315V121.449ZM99.053,284.379a162.089,162.089,0,0,1,26.579-1.8c12.128,0,20.772,2.315,26.58,6.965a25.422,25.422,0,0,1,9.292,20.127c0,8.51-2.837,15.745-8,20.646-6.714,6.32-16.643,9.157-28.258,9.157a50.931,50.931,0,0,1-6.714-.379v31.1H99.053ZM386.034,450.713H121.811a19.906,19.906,0,0,1-19.874-19.889V407.829h246.31a20.674,20.674,0,0,0,20.679-20.677v-125.4a20.675,20.675,0,0,0-20.679-20.679H101.937V51.315a19.9,19.9,0,0,1,19.874-19.858l181.89-.19V98.5a35.6,35.6,0,0,0,35.587,35.587l65.862-.189.741,296.925A19.892,19.892,0,0,1,386.034,450.713ZM174.065,369.8V284.379a177.116,177.116,0,0,1,26.58-1.8c16.516,0,27.226,2.963,35.618,9.282,9.031,6.714,14.7,17.416,14.7,32.781,0,16.643-6.06,28.133-14.453,35.224-9.157,7.612-23.1,11.222-40.125,11.222A170.242,170.242,0,0,1,174.065,369.8Zm140.827-50.575v16h-31.23V370.2h-19.74V283.229h53.16v16.122h-33.42v19.875Z" fill="#707070" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as PDF</Dropdown.Item>
                                                                        {/* <Dropdown.Item href="#/action-3">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="icon_copy" transform="translate(-1 -1)">
                                                                                        <path id="Path_82939" data-name="Path 82939" d="M12,13a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V6A2,2,0,0,1,3,4V5A1.014,1.014,0,0,0,2,6v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1ZM6,2A1.014,1.014,0,0,0,5,3v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1V3a1.014,1.014,0,0,0-1-1ZM6,1h7a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V3A2,2,0,0,1,6,1Z" fill="rgba(112,112,112,0.96)" />
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Copy as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160345" data-name="Group 160345" transform="translate(-1 -1)">
                                                                                        <g id="Group_160323" data-name="Group 160323" transform="translate(10.958 1)">
                                                                                            <g id="Group_160322" data-name="Group 160322" transform="translate(0 0)">
                                                                                                <g id="Group_160321" data-name="Group 160321">
                                                                                                    <path id="Path_83117" data-name="Path 83117" d="M48.69,5.042a.453.453,0,0,1-.452-.452V1.9H45.552a.452.452,0,0,1,0-.9H48.69a.453.453,0,0,1,.452.452V4.59A.453.453,0,0,1,48.69,5.042Z" transform="translate(-45.1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160326" data-name="Group 160326" transform="translate(1 10.958)">
                                                                                            <g id="Group_160325" data-name="Group 160325" transform="translate(0)">
                                                                                                <g id="Group_160324" data-name="Group 160324">
                                                                                                    <path id="Path_83118" data-name="Path 83118" d="M4.59,49.142H1.452A.453.453,0,0,1,1,48.69V45.552a.452.452,0,0,1,.9,0v2.687H4.59a.452.452,0,1,1,0,.9Z" transform="translate(-1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160329" data-name="Group 160329" transform="translate(10.958 10.958)">
                                                                                            <g id="Group_160328" data-name="Group 160328">
                                                                                                <g id="Group_160327" data-name="Group 160327">
                                                                                                    <path id="Path_83119" data-name="Path 83119" d="M48.69,49.142H45.552a.452.452,0,0,1,0-.9h2.687V45.552a.452.452,0,0,1,.9,0V48.69A.453.453,0,0,1,48.69,49.142Z" transform="translate(-45.1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160332" data-name="Group 160332" transform="translate(1 1)">
                                                                                            <g id="Group_160331" data-name="Group 160331" transform="translate(0 0)">
                                                                                                <g id="Group_160330" data-name="Group 160330">
                                                                                                    <path id="Path_83120" data-name="Path 83120" d="M1.452,5.042A.453.453,0,0,1,1,4.59V1.452A.453.453,0,0,1,1.452,1H4.59a.452.452,0,0,1,0,.9H1.9V4.59A.453.453,0,0,1,1.452,5.042Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160335" data-name="Group 160335" transform="translate(1 1)">
                                                                                            <g id="Group_160334" data-name="Group 160334" transform="translate(0)">
                                                                                                <g id="Group_160333" data-name="Group 160333">
                                                                                                    <path id="Path_83121" data-name="Path 83121" d="M5.923,6.374a.446.446,0,0,1-.316-.135L1.135,1.768a.437.437,0,0,1,0-.632.437.437,0,0,1,.632,0L6.239,5.606a.437.437,0,0,1,0,.632.446.446,0,0,1-.316.135Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160338" data-name="Group 160338" transform="translate(9.626 9.648)">
                                                                                            <g id="Group_160337" data-name="Group 160337">
                                                                                                <g id="Group_160336" data-name="Group 160336">
                                                                                                    <path id="Path_83122" data-name="Path 83122" d="M44.123,44.652a.446.446,0,0,1-.316-.135l-4.471-4.448a.447.447,0,1,1,.632-.632l4.471,4.471a.437.437,0,0,1,0,.632.54.54,0,0,1-.316.113Z" transform="translate(-39.2 -39.3)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160341" data-name="Group 160341" transform="translate(9.626 1)">
                                                                                            <g id="Group_160340" data-name="Group 160340" transform="translate(0 0)">
                                                                                                <g id="Group_160339" data-name="Group 160339">
                                                                                                    <path id="Path_83123" data-name="Path 83123" d="M39.652,6.374a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.437.437,0,0,1,.632,0,.437.437,0,0,1,0,.632L39.99,6.239a.46.46,0,0,1-.339.135Z" transform="translate(-39.2 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160344" data-name="Group 160344" transform="translate(1 9.626)">
                                                                                            <g id="Group_160343" data-name="Group 160343" transform="translate(0)">
                                                                                                <g id="Group_160342" data-name="Group 160342">
                                                                                                    <path id="Path_83124" data-name="Path 83124" d="M1.452,44.574a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.447.447,0,1,1,.632.632L1.768,44.439a.446.446,0,0,1-.316.135Z" transform="translate(-1 -39.2)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Open in Fullscreen</Dropdown.Item> */}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>   
                                                            <p className="qd-rc_desc">This chart gives the trend of the total number of Data Quality issues by Datasource over a period of 2 months</p>
                                                            <div className="qd-rc_body">
                                                                <div id="slopechart" style={{ "width": "100%", "height": "500px" }} ></div>
                                                                {
                                                                    this.state.dq_reports_data !== "" ?
                                                                        <DQReports
                                                                            chartid="slopechart"
                                                                            chartdata={this.state.dq_reports_data}
                                                                            monthviewyear={this.state.filtermonthviewyear}
                                                                            monthviewmonth={this.state.filtermonthviewmonth}

                                                                        />
                                                                        : ""
                                                                }

                                                                <div class="dq-report-graph-title">Data quality issues by Datasource</div>
                                                                <ul class="legend">
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#F68B96'}}></span>Increase</li>
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#6A91BE'}}></span>No Change</li>
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#6FC4DF'}}></span>Decrease</li>
                                                                </ul>
                                                                {/* <img src={reportChart4} style={{ width: '80%' }} className="d-none" /> */}
                                                                <hr />
                                                                <div id="datasource_quality_issue_donut" style={{ "width": "100%", "height": "520px" }}></div>
                                                                {
                                                                    this.state.dq_reports_data !== "" ?
                                                                        <DQReports
                                                                            chartid="datasource_quality_issue_donut"
                                                                            chartdata={this.state.dq_reports_data}
                                                                            monthviewyear={this.state.filtermonthviewyear}
                                                                            monthviewmonth={this.state.filtermonthviewmonth}

                                                                        />
                                                                        : ""
                                                                }
                                                                <br />
                                                                <div class="dq-report-graph-title">Data quality issue by Datasource</div>
                                                                {/* <img src={reportChart5} style={{ width: '50%' }} className="d-none" /> */}
                                                                <hr/>
                                                                <div id="datasourcenewissue"></div>
                                                                {
                                                                    this.state.dq_reports_data !== "" ?
                                                                        <DQReports
                                                                            chartid="datasourcenewissue"
                                                                            chartdata={this.state.dq_reports_data}
                                                                            monthviewyear={this.state.filtermonthviewyear}
                                                                            monthviewmonth={this.state.filtermonthviewmonth}
                                                                        />
                                                                        : ""
                                                                }
                                                                <ul class="legend">
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#F68B96'}}></span>New Issue</li>
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#6A91BE'}}></span>Old Issues</li>
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#6FC4DF'}}></span>Solved Issues</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className={this.state.err === "No Data Found" || this.state.apicall === "loading" ? "qd-report-card d-none" : "qd-report-card"} id="widget1">
                                                            <div className="qd-rc_top">
                                                                <h1 className="qd-rc_title">{this.getChartTitle("customchart")}</h1>
                                                                {/*<Dropdown className="qd-rc_dropdown">
                                                                    <Dropdown.Toggle variant=" " id="dropdown-basic" className="p-1"></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" onClick={this.saveimage} data-widgetid="widget1" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160320" data-name="Group 160320" transform="translate(-2 -2)">
                                                                                        <g id="Group_160319" data-name="Group 160319" transform="translate(2 2)">
                                                                                            <path id="Path_83115" data-name="Path 83115" d="M9.705,12.995c.006.006.015.008.022.014a.691.691,0,0,0,.205.137h0a.7.7,0,0,0,.761-.152l3.5-3.5a.7.7,0,1,0-.99-.99L10.9,10.81V2.7a.7.7,0,1,0-1.4,0v8.11L7.195,8.505a.7.7,0,1,0-.99.99Z" transform="translate(-3.2 -2)" fill="#747474" />
                                                                                            <path id="Path_83116" data-name="Path 83116" d="M14.6,15.7v2.1a.7.7,0,0,1-.7.7H4.1a.7.7,0,0,1-.7-.7V15.7a.7.7,0,0,0-1.4,0v2.1a2.1,2.1,0,0,0,2.1,2.1h9.8A2.1,2.1,0,0,0,16,17.8V15.7a.7.7,0,1,0-1.4,0Z" transform="translate(-2 -5.9)" fill="#747474" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={this.savepdf} data-widgetid="widget1" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.72" viewBox="0 0 392.528 482.139">
                                                                                    <g id="icon_pdf" transform="translate(-44.806)">
                                                                                        <g id="Group_159867" data-name="Group 159867">
                                                                                            <path id="Path_82935" data-name="Path 82935" d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782a34.005,34.005,0,0,0-8.132.773v25.69a30.811,30.811,0,0,0,6.588.5C135.57,324.379,142.024,319.1,142.024,310.194Z" fill="#707070" />
                                                                                            <path id="Path_82936" data-name="Path 82936" d="M202.709,297.681a40.555,40.555,0,0,0-8.9.772v56.9a35.6,35.6,0,0,0,6.841.394c17.809.126,29.424-9.677,29.424-30.449C230.2,307.231,219.611,297.681,202.709,297.681Z" fill="#707070" />
                                                                                            <path id="Path_82937" data-name="Path 82937" d="M315.458,0H121.811A51.39,51.39,0,0,0,70.5,51.315V241.069H65.484a20.675,20.675,0,0,0-20.678,20.679v125.4a20.673,20.673,0,0,0,20.678,20.677H70.5v22.995a51.37,51.37,0,0,0,51.315,51.315H386.034a51.37,51.37,0,0,0,51.3-51.315V121.449ZM99.053,284.379a162.089,162.089,0,0,1,26.579-1.8c12.128,0,20.772,2.315,26.58,6.965a25.422,25.422,0,0,1,9.292,20.127c0,8.51-2.837,15.745-8,20.646-6.714,6.32-16.643,9.157-28.258,9.157a50.931,50.931,0,0,1-6.714-.379v31.1H99.053ZM386.034,450.713H121.811a19.906,19.906,0,0,1-19.874-19.889V407.829h246.31a20.674,20.674,0,0,0,20.679-20.677v-125.4a20.675,20.675,0,0,0-20.679-20.679H101.937V51.315a19.9,19.9,0,0,1,19.874-19.858l181.89-.19V98.5a35.6,35.6,0,0,0,35.587,35.587l65.862-.189.741,296.925A19.892,19.892,0,0,1,386.034,450.713ZM174.065,369.8V284.379a177.116,177.116,0,0,1,26.58-1.8c16.516,0,27.226,2.963,35.618,9.282,9.031,6.714,14.7,17.416,14.7,32.781,0,16.643-6.06,28.133-14.453,35.224-9.157,7.612-23.1,11.222-40.125,11.222A170.242,170.242,0,0,1,174.065,369.8Zm140.827-50.575v16h-31.23V370.2h-19.74V283.229h53.16v16.122h-33.42v19.875Z" fill="#707070" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as PDF</Dropdown.Item>
                                                                        <Dropdown.Item href="#">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="icon_copy" transform="translate(-1 -1)">
                                                                                        <path id="Path_82939" data-name="Path 82939" d="M12,13a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V6A2,2,0,0,1,3,4V5A1.014,1.014,0,0,0,2,6v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1ZM6,2A1.014,1.014,0,0,0,5,3v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1V3a1.014,1.014,0,0,0-1-1ZM6,1h7a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V3A2,2,0,0,1,6,1Z" fill="rgba(112,112,112,0.96)" />
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Copy as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160345" data-name="Group 160345" transform="translate(-1 -1)">
                                                                                        <g id="Group_160323" data-name="Group 160323" transform="translate(10.958 1)">
                                                                                            <g id="Group_160322" data-name="Group 160322" transform="translate(0 0)">
                                                                                                <g id="Group_160321" data-name="Group 160321">
                                                                                                    <path id="Path_83117" data-name="Path 83117" d="M48.69,5.042a.453.453,0,0,1-.452-.452V1.9H45.552a.452.452,0,0,1,0-.9H48.69a.453.453,0,0,1,.452.452V4.59A.453.453,0,0,1,48.69,5.042Z" transform="translate(-45.1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160326" data-name="Group 160326" transform="translate(1 10.958)">
                                                                                            <g id="Group_160325" data-name="Group 160325" transform="translate(0)">
                                                                                                <g id="Group_160324" data-name="Group 160324">
                                                                                                    <path id="Path_83118" data-name="Path 83118" d="M4.59,49.142H1.452A.453.453,0,0,1,1,48.69V45.552a.452.452,0,0,1,.9,0v2.687H4.59a.452.452,0,1,1,0,.9Z" transform="translate(-1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160329" data-name="Group 160329" transform="translate(10.958 10.958)">
                                                                                            <g id="Group_160328" data-name="Group 160328">
                                                                                                <g id="Group_160327" data-name="Group 160327">
                                                                                                    <path id="Path_83119" data-name="Path 83119" d="M48.69,49.142H45.552a.452.452,0,0,1,0-.9h2.687V45.552a.452.452,0,0,1,.9,0V48.69A.453.453,0,0,1,48.69,49.142Z" transform="translate(-45.1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160332" data-name="Group 160332" transform="translate(1 1)">
                                                                                            <g id="Group_160331" data-name="Group 160331" transform="translate(0 0)">
                                                                                                <g id="Group_160330" data-name="Group 160330">
                                                                                                    <path id="Path_83120" data-name="Path 83120" d="M1.452,5.042A.453.453,0,0,1,1,4.59V1.452A.453.453,0,0,1,1.452,1H4.59a.452.452,0,0,1,0,.9H1.9V4.59A.453.453,0,0,1,1.452,5.042Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160335" data-name="Group 160335" transform="translate(1 1)">
                                                                                            <g id="Group_160334" data-name="Group 160334" transform="translate(0)">
                                                                                                <g id="Group_160333" data-name="Group 160333">
                                                                                                    <path id="Path_83121" data-name="Path 83121" d="M5.923,6.374a.446.446,0,0,1-.316-.135L1.135,1.768a.437.437,0,0,1,0-.632.437.437,0,0,1,.632,0L6.239,5.606a.437.437,0,0,1,0,.632.446.446,0,0,1-.316.135Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160338" data-name="Group 160338" transform="translate(9.626 9.648)">
                                                                                            <g id="Group_160337" data-name="Group 160337">
                                                                                                <g id="Group_160336" data-name="Group 160336">
                                                                                                    <path id="Path_83122" data-name="Path 83122" d="M44.123,44.652a.446.446,0,0,1-.316-.135l-4.471-4.448a.447.447,0,1,1,.632-.632l4.471,4.471a.437.437,0,0,1,0,.632.54.54,0,0,1-.316.113Z" transform="translate(-39.2 -39.3)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160341" data-name="Group 160341" transform="translate(9.626 1)">
                                                                                            <g id="Group_160340" data-name="Group 160340" transform="translate(0 0)">
                                                                                                <g id="Group_160339" data-name="Group 160339">
                                                                                                    <path id="Path_83123" data-name="Path 83123" d="M39.652,6.374a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.437.437,0,0,1,.632,0,.437.437,0,0,1,0,.632L39.99,6.239a.46.46,0,0,1-.339.135Z" transform="translate(-39.2 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160344" data-name="Group 160344" transform="translate(1 9.626)">
                                                                                            <g id="Group_160343" data-name="Group 160343" transform="translate(0)">
                                                                                                <g id="Group_160342" data-name="Group 160342">
                                                                                                    <path id="Path_83124" data-name="Path 83124" d="M1.452,44.574a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.447.447,0,1,1,.632.632L1.768,44.439a.446.446,0,0,1-.316.135Z" transform="translate(-1 -39.2)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Open in Fullscreen</Dropdown.Item> 
                                                                    </Dropdown.Menu>
                                                                </Dropdown>*/}
                                                            </div>
                                                            <p className="qd-rc_desc">This chart gives the breakdown of the Data Quality metrics, in percentage, in each datasource.</p>
                                                            <div className="qd-rc_body">
                                                                {/* <img src={reportChart1} className="d-none" style={{ width: '90%' }} /> */}
                                                                <div id="customreportnewissue"></div>
                                                                {
                                                                    this.state.dq_reports_data !== "" ?
                                                                        <DQReports
                                                                            chartid="customreportnewissue"
                                                                            chartdata={this.state.dq_reports_data}
                                                                            monthviewyear={this.state.filtermonthviewyear}
                                                                            monthviewmonth={this.state.filtermonthviewmonth}
                                                                        />
                                                                        : ""
                                                                }
                                                                <ul class="legend">
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#F68B96'}}></span>Completeness</li>
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#6A91BE'}}></span>Accuracy</li>
                                                                    <li className="dq-report-gbar-legend"><span style={{backgroundColor: '#6FC4DF'}}></span>Consistency</li>
                                                                </ul>

                                                                <hr/>
                                                                <div className="qd-heatmap-table table-responsive" >
                                                                    `<table class="table" >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Environment</th>
                                                                                <th>Datasource</th>
                                                                                <th>Completeness</th>
                                                                                <th>Accuracy</th>
                                                                                <th>Consistency</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="customdataqualityissuebydatasetheatmap">
                                                                        </tbody>
                                                                    </table>

<br />
                                                                    <div className="col-md-12">
                                                                        <div class="dq-report-graph-title">Data Quality in Percentage by Datasource</div>
                                                                        <div id="customdataqualityissuebyattrheatmaplegend" ></div>
                                                                    </div>
                                                                    {
                this.state.dq_reports_data !== "" ?
                    <DQReports
                        chartid="customdataqualityissuebydatasetheatmap"
                        chartdata={this.state.dq_reports_data}
                        monthviewyear={this.state.filtermonthviewyear}
                        monthviewmonth={this.state.filtermonthviewmonth}

                    />
                    : ""
            }

                                                                   
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {/* Report Card 4 */}
                                                        <div className={this.state.err === "No Data Found" || this.state.apicall === "loading" ? "qd-report-card d-none" : "qd-report-card"} id="widget4">
                                                            <div className="qd-rc_top">
                                                                <h1 className="qd-rc_title">{this.getChartTitle("dataset_title")}</h1>
                                                                <Dropdown className="qd-rc_dropdown d-none">
                                                                    <Dropdown.Toggle variant=" " id="dropdown-basic" className="p-1"></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" onClick={this.saveimage} data-widgetid="widget4" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160320" data-name="Group 160320" transform="translate(-2 -2)">
                                                                                        <g id="Group_160319" data-name="Group 160319" transform="translate(2 2)">
                                                                                            <path id="Path_83115" data-name="Path 83115" d="M9.705,12.995c.006.006.015.008.022.014a.691.691,0,0,0,.205.137h0a.7.7,0,0,0,.761-.152l3.5-3.5a.7.7,0,1,0-.99-.99L10.9,10.81V2.7a.7.7,0,1,0-1.4,0v8.11L7.195,8.505a.7.7,0,1,0-.99.99Z" transform="translate(-3.2 -2)" fill="#747474" />
                                                                                            <path id="Path_83116" data-name="Path 83116" d="M14.6,15.7v2.1a.7.7,0,0,1-.7.7H4.1a.7.7,0,0,1-.7-.7V15.7a.7.7,0,0,0-1.4,0v2.1a2.1,2.1,0,0,0,2.1,2.1h9.8A2.1,2.1,0,0,0,16,17.8V15.7a.7.7,0,1,0-1.4,0Z" transform="translate(-2 -5.9)" fill="#747474" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={this.savepdf} data-widgetid="widget4" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.72" viewBox="0 0 392.528 482.139">
                                                                                    <g id="icon_pdf" transform="translate(-44.806)">
                                                                                        <g id="Group_159867" data-name="Group 159867">
                                                                                            <path id="Path_82935" data-name="Path 82935" d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782a34.005,34.005,0,0,0-8.132.773v25.69a30.811,30.811,0,0,0,6.588.5C135.57,324.379,142.024,319.1,142.024,310.194Z" fill="#707070" />
                                                                                            <path id="Path_82936" data-name="Path 82936" d="M202.709,297.681a40.555,40.555,0,0,0-8.9.772v56.9a35.6,35.6,0,0,0,6.841.394c17.809.126,29.424-9.677,29.424-30.449C230.2,307.231,219.611,297.681,202.709,297.681Z" fill="#707070" />
                                                                                            <path id="Path_82937" data-name="Path 82937" d="M315.458,0H121.811A51.39,51.39,0,0,0,70.5,51.315V241.069H65.484a20.675,20.675,0,0,0-20.678,20.679v125.4a20.673,20.673,0,0,0,20.678,20.677H70.5v22.995a51.37,51.37,0,0,0,51.315,51.315H386.034a51.37,51.37,0,0,0,51.3-51.315V121.449ZM99.053,284.379a162.089,162.089,0,0,1,26.579-1.8c12.128,0,20.772,2.315,26.58,6.965a25.422,25.422,0,0,1,9.292,20.127c0,8.51-2.837,15.745-8,20.646-6.714,6.32-16.643,9.157-28.258,9.157a50.931,50.931,0,0,1-6.714-.379v31.1H99.053ZM386.034,450.713H121.811a19.906,19.906,0,0,1-19.874-19.889V407.829h246.31a20.674,20.674,0,0,0,20.679-20.677v-125.4a20.675,20.675,0,0,0-20.679-20.679H101.937V51.315a19.9,19.9,0,0,1,19.874-19.858l181.89-.19V98.5a35.6,35.6,0,0,0,35.587,35.587l65.862-.189.741,296.925A19.892,19.892,0,0,1,386.034,450.713ZM174.065,369.8V284.379a177.116,177.116,0,0,1,26.58-1.8c16.516,0,27.226,2.963,35.618,9.282,9.031,6.714,14.7,17.416,14.7,32.781,0,16.643-6.06,28.133-14.453,35.224-9.157,7.612-23.1,11.222-40.125,11.222A170.242,170.242,0,0,1,174.065,369.8Zm140.827-50.575v16h-31.23V370.2h-19.74V283.229h53.16v16.122h-33.42v19.875Z" fill="#707070" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as PDF</Dropdown.Item>
                                                                        {/* <Dropdown.Item href="#/action-3">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="icon_copy" transform="translate(-1 -1)">
                                                                                        <path id="Path_82939" data-name="Path 82939" d="M12,13a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V6A2,2,0,0,1,3,4V5A1.014,1.014,0,0,0,2,6v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1ZM6,2A1.014,1.014,0,0,0,5,3v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1V3a1.014,1.014,0,0,0-1-1ZM6,1h7a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V3A2,2,0,0,1,6,1Z" fill="rgba(112,112,112,0.96)" />
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Copy as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160345" data-name="Group 160345" transform="translate(-1 -1)">
                                                                                        <g id="Group_160323" data-name="Group 160323" transform="translate(10.958 1)">
                                                                                            <g id="Group_160322" data-name="Group 160322" transform="translate(0 0)">
                                                                                                <g id="Group_160321" data-name="Group 160321">
                                                                                                    <path id="Path_83117" data-name="Path 83117" d="M48.69,5.042a.453.453,0,0,1-.452-.452V1.9H45.552a.452.452,0,0,1,0-.9H48.69a.453.453,0,0,1,.452.452V4.59A.453.453,0,0,1,48.69,5.042Z" transform="translate(-45.1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160326" data-name="Group 160326" transform="translate(1 10.958)">
                                                                                            <g id="Group_160325" data-name="Group 160325" transform="translate(0)">
                                                                                                <g id="Group_160324" data-name="Group 160324">
                                                                                                    <path id="Path_83118" data-name="Path 83118" d="M4.59,49.142H1.452A.453.453,0,0,1,1,48.69V45.552a.452.452,0,0,1,.9,0v2.687H4.59a.452.452,0,1,1,0,.9Z" transform="translate(-1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160329" data-name="Group 160329" transform="translate(10.958 10.958)">
                                                                                            <g id="Group_160328" data-name="Group 160328">
                                                                                                <g id="Group_160327" data-name="Group 160327">
                                                                                                    <path id="Path_83119" data-name="Path 83119" d="M48.69,49.142H45.552a.452.452,0,0,1,0-.9h2.687V45.552a.452.452,0,0,1,.9,0V48.69A.453.453,0,0,1,48.69,49.142Z" transform="translate(-45.1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160332" data-name="Group 160332" transform="translate(1 1)">
                                                                                            <g id="Group_160331" data-name="Group 160331" transform="translate(0 0)">
                                                                                                <g id="Group_160330" data-name="Group 160330">
                                                                                                    <path id="Path_83120" data-name="Path 83120" d="M1.452,5.042A.453.453,0,0,1,1,4.59V1.452A.453.453,0,0,1,1.452,1H4.59a.452.452,0,0,1,0,.9H1.9V4.59A.453.453,0,0,1,1.452,5.042Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160335" data-name="Group 160335" transform="translate(1 1)">
                                                                                            <g id="Group_160334" data-name="Group 160334" transform="translate(0)">
                                                                                                <g id="Group_160333" data-name="Group 160333">
                                                                                                    <path id="Path_83121" data-name="Path 83121" d="M5.923,6.374a.446.446,0,0,1-.316-.135L1.135,1.768a.437.437,0,0,1,0-.632.437.437,0,0,1,.632,0L6.239,5.606a.437.437,0,0,1,0,.632.446.446,0,0,1-.316.135Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160338" data-name="Group 160338" transform="translate(9.626 9.648)">
                                                                                            <g id="Group_160337" data-name="Group 160337">
                                                                                                <g id="Group_160336" data-name="Group 160336">
                                                                                                    <path id="Path_83122" data-name="Path 83122" d="M44.123,44.652a.446.446,0,0,1-.316-.135l-4.471-4.448a.447.447,0,1,1,.632-.632l4.471,4.471a.437.437,0,0,1,0,.632.54.54,0,0,1-.316.113Z" transform="translate(-39.2 -39.3)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160341" data-name="Group 160341" transform="translate(9.626 1)">
                                                                                            <g id="Group_160340" data-name="Group 160340" transform="translate(0 0)">
                                                                                                <g id="Group_160339" data-name="Group 160339">
                                                                                                    <path id="Path_83123" data-name="Path 83123" d="M39.652,6.374a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.437.437,0,0,1,.632,0,.437.437,0,0,1,0,.632L39.99,6.239a.46.46,0,0,1-.339.135Z" transform="translate(-39.2 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160344" data-name="Group 160344" transform="translate(1 9.626)">
                                                                                            <g id="Group_160343" data-name="Group 160343" transform="translate(0)">
                                                                                                <g id="Group_160342" data-name="Group 160342">
                                                                                                    <path id="Path_83124" data-name="Path 83124" d="M1.452,44.574a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.447.447,0,1,1,.632.632L1.768,44.439a.446.446,0,0,1-.316.135Z" transform="translate(-1 -39.2)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Open in Fullscreen</Dropdown.Item> */}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>   
                                                            <p className="qd-rc_desc">A deployment includes Data analysis, Monitoring and other Metrics functions with features, search and analyze data.</p>
                                                        <div className="qd-rc_body">

                                                                <div className="qd-heatmap-table table-responsive" >
                                                                    `<table class="table" >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Environment > Datasource</th>
                                                                                <th>Dataset</th>
                                                                                <th id="dataqualityissuebydatasetthismonth"></th>
                                                                                <th id="dataqualityissuebydatasetpreviousmonth"></th>
                                                                                <th id="dataqualityissuebydatasetpreviousofpreviousmonth"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="dataqualityissuebydatasetheatmap">

                                                                        </tbody>
                                                                    </table>

                                                                    {
                                                                        this.state.dq_reports_data !== "" ?
                                                                            <DQReports
                                                                                chartid="dataqualityissuebydatasetheatmap"
                                                                                chartdata={this.state.dq_reports_data}
                                                                                monthviewyear={this.state.filtermonthviewyear}
                                                                                monthviewmonth={this.state.filtermonthviewmonth}

                                                                            />
                                                                            : ""
                                                                    }

                                                                    <div className="col-md-12">
                                                                        {/* <ul class="pagination react-bootstrap-table-page-btns-ul"><li class="page-item" title="first page"><a href="#" class="page-link">&lt;&lt;</a></li><li class="page-item" title="previous page"><a href="#" class="page-link">&lt;</a></li><li class="page-item" title="178"><a href="#" class="page-link">178</a></li><li class="page-item" title="179"><a href="#" class="page-link">179</a></li><li class="page-item" title="180"><a href="#" class="page-link">180</a></li><li class="page-item" title="181"><a href="#" class="page-link">181</a></li><li class="active page-item" title="182"><a href="#" class="page-link">182</a></li></ul> */}
                                                                    </div>
                                                                    <br />
                                                                    <div className="col-md-12">
                                                                        <div class="dq-report-graph-title">Data quality issues by Dataset</div>
                                                                        <div id="dataqualityissuebydatasetheatmaplegend" ></div>
                                                                    </div>
                                                                </div>
                                                                {/* <img src={reportChart2} style={{ width: '90%' }} className="d-none"  /> */}
                                                                <hr />
                                                                <div id="dataset_quality_issue_donut" style={{ "width": "100%", "height": "520px" }}></div>
                {
                                                                        this.state.dq_reports_data !== "" ?
                                                                            <DQReports
                                                                                chartid="dataset_quality_issue_donut"
                                                                                chartdata={this.state.dq_reports_data}
                                                                                monthviewyear={this.state.filtermonthviewyear}
                                                                                monthviewmonth={this.state.filtermonthviewmonth}

                                                                            />
                                                                            : ""
                }
                                                                <br />
                                                                <div class="dq-report-graph-title">Data quality issues by Dataset</div>
                                                                {/* <img src={reportChart6} style={{ width: '50%' }} className="d-none" /> */}
                                                            </div>
                                                        </div>
                                                        {/* Report Card 5 */}
                                                        <div className={this.state.err === "No Data Found" || this.state.apicall === "loading" ? "qd-report-card d-none" : "qd-report-card"} id="widget5">
                                                            <div className="qd-rc_top">
                                                                <h1 className="qd-rc_title">{this.getChartTitle("metadata_title")}</h1>
                                                                <Dropdown className="qd-rc_dropdown d-none">
                                                                    <Dropdown.Toggle variant=" " id="dropdown-basic" className="p-1"></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" onClick={this.saveimage} data-widgetid="widget5" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160320" data-name="Group 160320" transform="translate(-2 -2)">
                                                                                        <g id="Group_160319" data-name="Group 160319" transform="translate(2 2)">
                                                                                            <path id="Path_83115" data-name="Path 83115" d="M9.705,12.995c.006.006.015.008.022.014a.691.691,0,0,0,.205.137h0a.7.7,0,0,0,.761-.152l3.5-3.5a.7.7,0,1,0-.99-.99L10.9,10.81V2.7a.7.7,0,1,0-1.4,0v8.11L7.195,8.505a.7.7,0,1,0-.99.99Z" transform="translate(-3.2 -2)" fill="#747474" />
                                                                                            <path id="Path_83116" data-name="Path 83116" d="M14.6,15.7v2.1a.7.7,0,0,1-.7.7H4.1a.7.7,0,0,1-.7-.7V15.7a.7.7,0,0,0-1.4,0v2.1a2.1,2.1,0,0,0,2.1,2.1h9.8A2.1,2.1,0,0,0,16,17.8V15.7a.7.7,0,1,0-1.4,0Z" transform="translate(-2 -5.9)" fill="#747474" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={this.savepdf} data-widgetid="widget5" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.72" viewBox="0 0 392.528 482.139">
                                                                                    <g id="icon_pdf" transform="translate(-44.806)">
                                                                                        <g id="Group_159867" data-name="Group 159867">
                                                                                            <path id="Path_82935" data-name="Path 82935" d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782a34.005,34.005,0,0,0-8.132.773v25.69a30.811,30.811,0,0,0,6.588.5C135.57,324.379,142.024,319.1,142.024,310.194Z" fill="#707070" />
                                                                                            <path id="Path_82936" data-name="Path 82936" d="M202.709,297.681a40.555,40.555,0,0,0-8.9.772v56.9a35.6,35.6,0,0,0,6.841.394c17.809.126,29.424-9.677,29.424-30.449C230.2,307.231,219.611,297.681,202.709,297.681Z" fill="#707070" />
                                                                                            <path id="Path_82937" data-name="Path 82937" d="M315.458,0H121.811A51.39,51.39,0,0,0,70.5,51.315V241.069H65.484a20.675,20.675,0,0,0-20.678,20.679v125.4a20.673,20.673,0,0,0,20.678,20.677H70.5v22.995a51.37,51.37,0,0,0,51.315,51.315H386.034a51.37,51.37,0,0,0,51.3-51.315V121.449ZM99.053,284.379a162.089,162.089,0,0,1,26.579-1.8c12.128,0,20.772,2.315,26.58,6.965a25.422,25.422,0,0,1,9.292,20.127c0,8.51-2.837,15.745-8,20.646-6.714,6.32-16.643,9.157-28.258,9.157a50.931,50.931,0,0,1-6.714-.379v31.1H99.053ZM386.034,450.713H121.811a19.906,19.906,0,0,1-19.874-19.889V407.829h246.31a20.674,20.674,0,0,0,20.679-20.677v-125.4a20.675,20.675,0,0,0-20.679-20.679H101.937V51.315a19.9,19.9,0,0,1,19.874-19.858l181.89-.19V98.5a35.6,35.6,0,0,0,35.587,35.587l65.862-.189.741,296.925A19.892,19.892,0,0,1,386.034,450.713ZM174.065,369.8V284.379a177.116,177.116,0,0,1,26.58-1.8c16.516,0,27.226,2.963,35.618,9.282,9.031,6.714,14.7,17.416,14.7,32.781,0,16.643-6.06,28.133-14.453,35.224-9.157,7.612-23.1,11.222-40.125,11.222A170.242,170.242,0,0,1,174.065,369.8Zm140.827-50.575v16h-31.23V370.2h-19.74V283.229h53.16v16.122h-33.42v19.875Z" fill="#707070" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as PDF</Dropdown.Item>
                                                                        {/* <Dropdown.Item href="#/action-3">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="icon_copy" transform="translate(-1 -1)">
                                                                                        <path id="Path_82939" data-name="Path 82939" d="M12,13a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V6A2,2,0,0,1,3,4V5A1.014,1.014,0,0,0,2,6v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1ZM6,2A1.014,1.014,0,0,0,5,3v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1V3a1.014,1.014,0,0,0-1-1ZM6,1h7a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V3A2,2,0,0,1,6,1Z" fill="rgba(112,112,112,0.96)" />
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Copy as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160345" data-name="Group 160345" transform="translate(-1 -1)">
                                                                                        <g id="Group_160323" data-name="Group 160323" transform="translate(10.958 1)">
                                                                                            <g id="Group_160322" data-name="Group 160322" transform="translate(0 0)">
                                                                                                <g id="Group_160321" data-name="Group 160321">
                                                                                                    <path id="Path_83117" data-name="Path 83117" d="M48.69,5.042a.453.453,0,0,1-.452-.452V1.9H45.552a.452.452,0,0,1,0-.9H48.69a.453.453,0,0,1,.452.452V4.59A.453.453,0,0,1,48.69,5.042Z" transform="translate(-45.1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160326" data-name="Group 160326" transform="translate(1 10.958)">
                                                                                            <g id="Group_160325" data-name="Group 160325" transform="translate(0)">
                                                                                                <g id="Group_160324" data-name="Group 160324">
                                                                                                    <path id="Path_83118" data-name="Path 83118" d="M4.59,49.142H1.452A.453.453,0,0,1,1,48.69V45.552a.452.452,0,0,1,.9,0v2.687H4.59a.452.452,0,1,1,0,.9Z" transform="translate(-1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160329" data-name="Group 160329" transform="translate(10.958 10.958)">
                                                                                            <g id="Group_160328" data-name="Group 160328">
                                                                                                <g id="Group_160327" data-name="Group 160327">
                                                                                                    <path id="Path_83119" data-name="Path 83119" d="M48.69,49.142H45.552a.452.452,0,0,1,0-.9h2.687V45.552a.452.452,0,0,1,.9,0V48.69A.453.453,0,0,1,48.69,49.142Z" transform="translate(-45.1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160332" data-name="Group 160332" transform="translate(1 1)">
                                                                                            <g id="Group_160331" data-name="Group 160331" transform="translate(0 0)">
                                                                                                <g id="Group_160330" data-name="Group 160330">
                                                                                                    <path id="Path_83120" data-name="Path 83120" d="M1.452,5.042A.453.453,0,0,1,1,4.59V1.452A.453.453,0,0,1,1.452,1H4.59a.452.452,0,0,1,0,.9H1.9V4.59A.453.453,0,0,1,1.452,5.042Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160335" data-name="Group 160335" transform="translate(1 1)">
                                                                                            <g id="Group_160334" data-name="Group 160334" transform="translate(0)">
                                                                                                <g id="Group_160333" data-name="Group 160333">
                                                                                                    <path id="Path_83121" data-name="Path 83121" d="M5.923,6.374a.446.446,0,0,1-.316-.135L1.135,1.768a.437.437,0,0,1,0-.632.437.437,0,0,1,.632,0L6.239,5.606a.437.437,0,0,1,0,.632.446.446,0,0,1-.316.135Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160338" data-name="Group 160338" transform="translate(9.626 9.648)">
                                                                                            <g id="Group_160337" data-name="Group 160337">
                                                                                                <g id="Group_160336" data-name="Group 160336">
                                                                                                    <path id="Path_83122" data-name="Path 83122" d="M44.123,44.652a.446.446,0,0,1-.316-.135l-4.471-4.448a.447.447,0,1,1,.632-.632l4.471,4.471a.437.437,0,0,1,0,.632.54.54,0,0,1-.316.113Z" transform="translate(-39.2 -39.3)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160341" data-name="Group 160341" transform="translate(9.626 1)">
                                                                                            <g id="Group_160340" data-name="Group 160340" transform="translate(0 0)">
                                                                                                <g id="Group_160339" data-name="Group 160339">
                                                                                                    <path id="Path_83123" data-name="Path 83123" d="M39.652,6.374a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.437.437,0,0,1,.632,0,.437.437,0,0,1,0,.632L39.99,6.239a.46.46,0,0,1-.339.135Z" transform="translate(-39.2 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160344" data-name="Group 160344" transform="translate(1 9.626)">
                                                                                            <g id="Group_160343" data-name="Group 160343" transform="translate(0)">
                                                                                                <g id="Group_160342" data-name="Group 160342">
                                                                                                    <path id="Path_83124" data-name="Path 83124" d="M1.452,44.574a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.447.447,0,1,1,.632.632L1.768,44.439a.446.446,0,0,1-.316.135Z" transform="translate(-1 -39.2)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Open in Fullscreen</Dropdown.Item> */}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>   
                                                            <p className="qd-rc_desc">This chart gives the trend of the total number of Data Quality issues by Attribute</p>
                                                            <div className="qd-rc_body">
                                                                {/* <img src={reportChart7} style={{ width: '90%' }}  /> */}
                                                                <div className="qd-heatmap-table table-responsive" >
                                                                    `<table class="table" >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Environment > Datasource</th>
                                                                                <th>Dataset</th>
                                                                                <th>Attribute</th>
                                                                                <th id="dataqualityissuebyattributethismonth"></th>
                                                                                <th id="dataqualityissuebyattributepreviousmonth"></th>
                                                                                <th id="dataqualityissuebyattributepreviousofpreviousmonth"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="dataqualityissuebyattributeheatmap">
                                                                        </tbody>
                                                                    </table>
                                                                    {
                this.state.dq_reports_data !== "" ?
                    <DQReports
                        chartid="dataqualityissuebyattributeheatmap"
                        chartdata={this.state.dq_reports_data}
                        monthviewyear={this.state.filtermonthviewyear}
                        monthviewmonth={this.state.filtermonthviewmonth}

                    />
                    : ""
            }
                                                                    <br/>
                                                                    <div class="dq-report-graph-title">Data quality issues by Attribute</div>
                                                                    <div id="dataqualityissuebyattrheatmaplegend"></div>
                                                                <hr />
``
                                                                <div id="attribute_quality_issue_donut" style={{ "width": "100%", "height": "520px" }}></div>
                {
                                                                        this.state.dq_reports_data !== "" ?
                                                                            <DQReports
                                                                                chartid="attribute_quality_issue_donut"
                                                                                chartdata={this.state.dq_reports_data}
                                                                                monthviewyear={this.state.filtermonthviewyear}
                                                                                monthviewmonth={this.state.filtermonthviewmonth}

                                                                            />
                                                                            : ""
                }

                                                                <br />
                                                                <div class="dq-report-graph-title d-none">Data quality issues by Attribute</div>
                                                                <hr style={{ "display":"none"}} />
                                                                    <div class="dq-report-graph-title">Data quality issues by Attribute</div>
                                                                    <ul class="legend d-none">
                                                                        <li class="dq-report-gbar-legend"><span style={{backgroundColor: 'rgba(246, 78, 96, 0.2)'}}></span>Enviroinment</li>
                                                                        <li class="dq-report-gbar-legend"><span style={{backgroundColor: 'rgba(255, 168, 0, 0.2)'}}></span>Datasource</li>
                                                                        <li class="dq-report-gbar-legend"><span style={{backgroundColor: 'rgba(255, 168, 0, 0.2)'}}></span>Dataset</li>
                                                                        <li class="dq-report-gbar-legend"><span style={{backgroundColor: 'rgba(78, 210, 204, 0.8)'}}></span>Attribute</li></ul>
                                                                    {/* <div id="dataqualityissuebyattributeheatmaplegend" ></div> */}
                                                                </div>

                                                                {/* <div id="sankeychart" style={{ width: '100%' }}></div> */}
                                                                {/* <img src={reportChart8} style={{ width: '90%' }} /> */}
                                                            </div>
                                                        </div>
                                                        {/* Report Card 8 */}
                                                        <div style={{display:"none"}} className={this.state.err === "No Data Found" || this.state.apicall === "loading" ? "qd-report-card d-none" : "qd-report-card"} id="widget8">
                                                            <div className="qd-rc_top">
                                                            <h1 className="qd-rc_title">Overall Data Quality Issues {"- "+this.state.filtermonthviewmonth+" "+this.state.filtermonthviewyear}</h1>
                                                                <Dropdown className="qd-rc_dropdown d-none">
                                                                    <Dropdown.Toggle variant=" " id="dropdown-basic" className="p-1"></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" onClick={this.saveimage} data-widgetid="widget6" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160320" data-name="Group 160320" transform="translate(-2 -2)">
                                                                                        <g id="Group_160319" data-name="Group 160319" transform="translate(2 2)">
                                                                                            <path id="Path_83115" data-name="Path 83115" d="M9.705,12.995c.006.006.015.008.022.014a.691.691,0,0,0,.205.137h0a.7.7,0,0,0,.761-.152l3.5-3.5a.7.7,0,1,0-.99-.99L10.9,10.81V2.7a.7.7,0,1,0-1.4,0v8.11L7.195,8.505a.7.7,0,1,0-.99.99Z" transform="translate(-3.2 -2)" fill="#747474" />
                                                                                            <path id="Path_83116" data-name="Path 83116" d="M14.6,15.7v2.1a.7.7,0,0,1-.7.7H4.1a.7.7,0,0,1-.7-.7V15.7a.7.7,0,0,0-1.4,0v2.1a2.1,2.1,0,0,0,2.1,2.1h9.8A2.1,2.1,0,0,0,16,17.8V15.7a.7.7,0,1,0-1.4,0Z" transform="translate(-2 -5.9)" fill="#747474" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={this.savepdf} data-widgetid="widget6" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.72" viewBox="0 0 392.528 482.139">
                                                                                    <g id="icon_pdf" transform="translate(-44.806)">
                                                                                        <g id="Group_159867" data-name="Group 159867">
                                                                                            <path id="Path_82935" data-name="Path 82935" d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782a34.005,34.005,0,0,0-8.132.773v25.69a30.811,30.811,0,0,0,6.588.5C135.57,324.379,142.024,319.1,142.024,310.194Z" fill="#707070" />
                                                                                            <path id="Path_82936" data-name="Path 82936" d="M202.709,297.681a40.555,40.555,0,0,0-8.9.772v56.9a35.6,35.6,0,0,0,6.841.394c17.809.126,29.424-9.677,29.424-30.449C230.2,307.231,219.611,297.681,202.709,297.681Z" fill="#707070" />
                                                                                            <path id="Path_82937" data-name="Path 82937" d="M315.458,0H121.811A51.39,51.39,0,0,0,70.5,51.315V241.069H65.484a20.675,20.675,0,0,0-20.678,20.679v125.4a20.673,20.673,0,0,0,20.678,20.677H70.5v22.995a51.37,51.37,0,0,0,51.315,51.315H386.034a51.37,51.37,0,0,0,51.3-51.315V121.449ZM99.053,284.379a162.089,162.089,0,0,1,26.579-1.8c12.128,0,20.772,2.315,26.58,6.965a25.422,25.422,0,0,1,9.292,20.127c0,8.51-2.837,15.745-8,20.646-6.714,6.32-16.643,9.157-28.258,9.157a50.931,50.931,0,0,1-6.714-.379v31.1H99.053ZM386.034,450.713H121.811a19.906,19.906,0,0,1-19.874-19.889V407.829h246.31a20.674,20.674,0,0,0,20.679-20.677v-125.4a20.675,20.675,0,0,0-20.679-20.679H101.937V51.315a19.9,19.9,0,0,1,19.874-19.858l181.89-.19V98.5a35.6,35.6,0,0,0,35.587,35.587l65.862-.189.741,296.925A19.892,19.892,0,0,1,386.034,450.713ZM174.065,369.8V284.379a177.116,177.116,0,0,1,26.58-1.8c16.516,0,27.226,2.963,35.618,9.282,9.031,6.714,14.7,17.416,14.7,32.781,0,16.643-6.06,28.133-14.453,35.224-9.157,7.612-23.1,11.222-40.125,11.222A170.242,170.242,0,0,1,174.065,369.8Zm140.827-50.575v16h-31.23V370.2h-19.74V283.229h53.16v16.122h-33.42v19.875Z" fill="#707070" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as PDF</Dropdown.Item>
                                                                        {/* <Dropdown.Item href="#/action-3">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="icon_copy" transform="translate(-1 -1)">
                                                                                        <path id="Path_82939" data-name="Path 82939" d="M12,13a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V6A2,2,0,0,1,3,4V5A1.014,1.014,0,0,0,2,6v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1ZM6,2A1.014,1.014,0,0,0,5,3v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1V3a1.014,1.014,0,0,0-1-1ZM6,1h7a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V3A2,2,0,0,1,6,1Z" fill="rgba(112,112,112,0.96)" />
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Copy as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160345" data-name="Group 160345" transform="translate(-1 -1)">
                                                                                        <g id="Group_160323" data-name="Group 160323" transform="translate(10.958 1)">
                                                                                            <g id="Group_160322" data-name="Group 160322" transform="translate(0 0)">
                                                                                                <g id="Group_160321" data-name="Group 160321">
                                                                                                    <path id="Path_83117" data-name="Path 83117" d="M48.69,5.042a.453.453,0,0,1-.452-.452V1.9H45.552a.452.452,0,0,1,0-.9H48.69a.453.453,0,0,1,.452.452V4.59A.453.453,0,0,1,48.69,5.042Z" transform="translate(-45.1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160326" data-name="Group 160326" transform="translate(1 10.958)">
                                                                                            <g id="Group_160325" data-name="Group 160325" transform="translate(0)">
                                                                                                <g id="Group_160324" data-name="Group 160324">
                                                                                                    <path id="Path_83118" data-name="Path 83118" d="M4.59,49.142H1.452A.453.453,0,0,1,1,48.69V45.552a.452.452,0,0,1,.9,0v2.687H4.59a.452.452,0,1,1,0,.9Z" transform="translate(-1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160329" data-name="Group 160329" transform="translate(10.958 10.958)">
                                                                                            <g id="Group_160328" data-name="Group 160328">
                                                                                                <g id="Group_160327" data-name="Group 160327">
                                                                                                    <path id="Path_83119" data-name="Path 83119" d="M48.69,49.142H45.552a.452.452,0,0,1,0-.9h2.687V45.552a.452.452,0,0,1,.9,0V48.69A.453.453,0,0,1,48.69,49.142Z" transform="translate(-45.1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160332" data-name="Group 160332" transform="translate(1 1)">
                                                                                            <g id="Group_160331" data-name="Group 160331" transform="translate(0 0)">
                                                                                                <g id="Group_160330" data-name="Group 160330">
                                                                                                    <path id="Path_83120" data-name="Path 83120" d="M1.452,5.042A.453.453,0,0,1,1,4.59V1.452A.453.453,0,0,1,1.452,1H4.59a.452.452,0,0,1,0,.9H1.9V4.59A.453.453,0,0,1,1.452,5.042Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160335" data-name="Group 160335" transform="translate(1 1)">
                                                                                            <g id="Group_160334" data-name="Group 160334" transform="translate(0)">
                                                                                                <g id="Group_160333" data-name="Group 160333">
                                                                                                    <path id="Path_83121" data-name="Path 83121" d="M5.923,6.374a.446.446,0,0,1-.316-.135L1.135,1.768a.437.437,0,0,1,0-.632.437.437,0,0,1,.632,0L6.239,5.606a.437.437,0,0,1,0,.632.446.446,0,0,1-.316.135Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160338" data-name="Group 160338" transform="translate(9.626 9.648)">
                                                                                            <g id="Group_160337" data-name="Group 160337">
                                                                                                <g id="Group_160336" data-name="Group 160336">
                                                                                                    <path id="Path_83122" data-name="Path 83122" d="M44.123,44.652a.446.446,0,0,1-.316-.135l-4.471-4.448a.447.447,0,1,1,.632-.632l4.471,4.471a.437.437,0,0,1,0,.632.54.54,0,0,1-.316.113Z" transform="translate(-39.2 -39.3)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160341" data-name="Group 160341" transform="translate(9.626 1)">
                                                                                            <g id="Group_160340" data-name="Group 160340" transform="translate(0 0)">
                                                                                                <g id="Group_160339" data-name="Group 160339">
                                                                                                    <path id="Path_83123" data-name="Path 83123" d="M39.652,6.374a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.437.437,0,0,1,.632,0,.437.437,0,0,1,0,.632L39.99,6.239a.46.46,0,0,1-.339.135Z" transform="translate(-39.2 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160344" data-name="Group 160344" transform="translate(1 9.626)">
                                                                                            <g id="Group_160343" data-name="Group 160343" transform="translate(0)">
                                                                                                <g id="Group_160342" data-name="Group 160342">
                                                                                                    <path id="Path_83124" data-name="Path 83124" d="M1.452,44.574a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.447.447,0,1,1,.632.632L1.768,44.439a.446.446,0,0,1-.316.135Z" transform="translate(-1 -39.2)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Open in Fullscreen</Dropdown.Item> */}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                            <p className="qd-rc_desc">This chart gives a hierarchical view of the data quality issues by Environment, Datasource, Dataset and Attributes.</p>
                                                            <div className="qd-rc_body pb-0">
                                                            <div id="packedChart" style={{"width": "800px", "height": "600px", "margin": "0 auto" }}></div>
                                                                {
                                                                    this.state.dq_reports_data !== "" ?
                                                                        <DQReports
                                                                            chartid="packedChart"
                                                                            chartdata={this.state.dq_reports_data}
                                                                            monthviewyear={this.state.filtermonthviewyear}
                                                                            monthviewmonth={this.state.filtermonthviewmonth}
                                                                        />
                                                                        : ""}<br/>
                                                            </div>
                                                        </div>
                                                        {/* Report Card 6 */}
                                                        <div className={this.state.err === "No Data Found" || this.state.apicall === "loading" ? "qd-report-card d-none" : "qd-report-card"} id="widget6">
                                                            <div className="qd-rc_top">
                                                            <h1 className="qd-rc_title">Top 5 types of Data Quality Issues by Percentage {"- "+this.state.filtermonthviewmonth+" "+this.state.filtermonthviewyear} </h1>
                                                                <Dropdown className="qd-rc_dropdown d-none">
                                                                    <Dropdown.Toggle variant=" " id="dropdown-basic" className="p-1"></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" onClick={this.saveimage} data-widgetid="widget6" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160320" data-name="Group 160320" transform="translate(-2 -2)">
                                                                                        <g id="Group_160319" data-name="Group 160319" transform="translate(2 2)">
                                                                                            <path id="Path_83115" data-name="Path 83115" d="M9.705,12.995c.006.006.015.008.022.014a.691.691,0,0,0,.205.137h0a.7.7,0,0,0,.761-.152l3.5-3.5a.7.7,0,1,0-.99-.99L10.9,10.81V2.7a.7.7,0,1,0-1.4,0v8.11L7.195,8.505a.7.7,0,1,0-.99.99Z" transform="translate(-3.2 -2)" fill="#747474" />
                                                                                            <path id="Path_83116" data-name="Path 83116" d="M14.6,15.7v2.1a.7.7,0,0,1-.7.7H4.1a.7.7,0,0,1-.7-.7V15.7a.7.7,0,0,0-1.4,0v2.1a2.1,2.1,0,0,0,2.1,2.1h9.8A2.1,2.1,0,0,0,16,17.8V15.7a.7.7,0,1,0-1.4,0Z" transform="translate(-2 -5.9)" fill="#747474" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={this.savepdf} data-widgetid="widget6" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.72" viewBox="0 0 392.528 482.139">
                                                                                    <g id="icon_pdf" transform="translate(-44.806)">
                                                                                        <g id="Group_159867" data-name="Group 159867">
                                                                                            <path id="Path_82935" data-name="Path 82935" d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782a34.005,34.005,0,0,0-8.132.773v25.69a30.811,30.811,0,0,0,6.588.5C135.57,324.379,142.024,319.1,142.024,310.194Z" fill="#707070" />
                                                                                            <path id="Path_82936" data-name="Path 82936" d="M202.709,297.681a40.555,40.555,0,0,0-8.9.772v56.9a35.6,35.6,0,0,0,6.841.394c17.809.126,29.424-9.677,29.424-30.449C230.2,307.231,219.611,297.681,202.709,297.681Z" fill="#707070" />
                                                                                            <path id="Path_82937" data-name="Path 82937" d="M315.458,0H121.811A51.39,51.39,0,0,0,70.5,51.315V241.069H65.484a20.675,20.675,0,0,0-20.678,20.679v125.4a20.673,20.673,0,0,0,20.678,20.677H70.5v22.995a51.37,51.37,0,0,0,51.315,51.315H386.034a51.37,51.37,0,0,0,51.3-51.315V121.449ZM99.053,284.379a162.089,162.089,0,0,1,26.579-1.8c12.128,0,20.772,2.315,26.58,6.965a25.422,25.422,0,0,1,9.292,20.127c0,8.51-2.837,15.745-8,20.646-6.714,6.32-16.643,9.157-28.258,9.157a50.931,50.931,0,0,1-6.714-.379v31.1H99.053ZM386.034,450.713H121.811a19.906,19.906,0,0,1-19.874-19.889V407.829h246.31a20.674,20.674,0,0,0,20.679-20.677v-125.4a20.675,20.675,0,0,0-20.679-20.679H101.937V51.315a19.9,19.9,0,0,1,19.874-19.858l181.89-.19V98.5a35.6,35.6,0,0,0,35.587,35.587l65.862-.189.741,296.925A19.892,19.892,0,0,1,386.034,450.713ZM174.065,369.8V284.379a177.116,177.116,0,0,1,26.58-1.8c16.516,0,27.226,2.963,35.618,9.282,9.031,6.714,14.7,17.416,14.7,32.781,0,16.643-6.06,28.133-14.453,35.224-9.157,7.612-23.1,11.222-40.125,11.222A170.242,170.242,0,0,1,174.065,369.8Zm140.827-50.575v16h-31.23V370.2h-19.74V283.229h53.16v16.122h-33.42v19.875Z" fill="#707070" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as PDF</Dropdown.Item>
                                                                        {/* <Dropdown.Item href="#/action-3">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="icon_copy" transform="translate(-1 -1)">
                                                                                        <path id="Path_82939" data-name="Path 82939" d="M12,13a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V6A2,2,0,0,1,3,4V5A1.014,1.014,0,0,0,2,6v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1ZM6,2A1.014,1.014,0,0,0,5,3v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1V3a1.014,1.014,0,0,0-1-1ZM6,1h7a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V3A2,2,0,0,1,6,1Z" fill="rgba(112,112,112,0.96)" />
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Copy as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160345" data-name="Group 160345" transform="translate(-1 -1)">
                                                                                        <g id="Group_160323" data-name="Group 160323" transform="translate(10.958 1)">
                                                                                            <g id="Group_160322" data-name="Group 160322" transform="translate(0 0)">
                                                                                                <g id="Group_160321" data-name="Group 160321">
                                                                                                    <path id="Path_83117" data-name="Path 83117" d="M48.69,5.042a.453.453,0,0,1-.452-.452V1.9H45.552a.452.452,0,0,1,0-.9H48.69a.453.453,0,0,1,.452.452V4.59A.453.453,0,0,1,48.69,5.042Z" transform="translate(-45.1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160326" data-name="Group 160326" transform="translate(1 10.958)">
                                                                                            <g id="Group_160325" data-name="Group 160325" transform="translate(0)">
                                                                                                <g id="Group_160324" data-name="Group 160324">
                                                                                                    <path id="Path_83118" data-name="Path 83118" d="M4.59,49.142H1.452A.453.453,0,0,1,1,48.69V45.552a.452.452,0,0,1,.9,0v2.687H4.59a.452.452,0,1,1,0,.9Z" transform="translate(-1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160329" data-name="Group 160329" transform="translate(10.958 10.958)">
                                                                                            <g id="Group_160328" data-name="Group 160328">
                                                                                                <g id="Group_160327" data-name="Group 160327">
                                                                                                    <path id="Path_83119" data-name="Path 83119" d="M48.69,49.142H45.552a.452.452,0,0,1,0-.9h2.687V45.552a.452.452,0,0,1,.9,0V48.69A.453.453,0,0,1,48.69,49.142Z" transform="translate(-45.1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160332" data-name="Group 160332" transform="translate(1 1)">
                                                                                            <g id="Group_160331" data-name="Group 160331" transform="translate(0 0)">
                                                                                                <g id="Group_160330" data-name="Group 160330">
                                                                                                    <path id="Path_83120" data-name="Path 83120" d="M1.452,5.042A.453.453,0,0,1,1,4.59V1.452A.453.453,0,0,1,1.452,1H4.59a.452.452,0,0,1,0,.9H1.9V4.59A.453.453,0,0,1,1.452,5.042Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160335" data-name="Group 160335" transform="translate(1 1)">
                                                                                            <g id="Group_160334" data-name="Group 160334" transform="translate(0)">
                                                                                                <g id="Group_160333" data-name="Group 160333">
                                                                                                    <path id="Path_83121" data-name="Path 83121" d="M5.923,6.374a.446.446,0,0,1-.316-.135L1.135,1.768a.437.437,0,0,1,0-.632.437.437,0,0,1,.632,0L6.239,5.606a.437.437,0,0,1,0,.632.446.446,0,0,1-.316.135Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160338" data-name="Group 160338" transform="translate(9.626 9.648)">
                                                                                            <g id="Group_160337" data-name="Group 160337">
                                                                                                <g id="Group_160336" data-name="Group 160336">
                                                                                                    <path id="Path_83122" data-name="Path 83122" d="M44.123,44.652a.446.446,0,0,1-.316-.135l-4.471-4.448a.447.447,0,1,1,.632-.632l4.471,4.471a.437.437,0,0,1,0,.632.54.54,0,0,1-.316.113Z" transform="translate(-39.2 -39.3)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160341" data-name="Group 160341" transform="translate(9.626 1)">
                                                                                            <g id="Group_160340" data-name="Group 160340" transform="translate(0 0)">
                                                                                                <g id="Group_160339" data-name="Group 160339">
                                                                                                    <path id="Path_83123" data-name="Path 83123" d="M39.652,6.374a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.437.437,0,0,1,.632,0,.437.437,0,0,1,0,.632L39.99,6.239a.46.46,0,0,1-.339.135Z" transform="translate(-39.2 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160344" data-name="Group 160344" transform="translate(1 9.626)">
                                                                                            <g id="Group_160343" data-name="Group 160343" transform="translate(0)">
                                                                                                <g id="Group_160342" data-name="Group 160342">
                                                                                                    <path id="Path_83124" data-name="Path 83124" d="M1.452,44.574a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.447.447,0,1,1,.632.632L1.768,44.439a.446.446,0,0,1-.316.135Z" transform="translate(-1 -39.2)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Open in Fullscreen</Dropdown.Item> */}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                            <p className="qd-rc_desc">This chart gives the top 5 types of Data Quality Issues based on their percentage of occurrence.</p>
                                                            <div className="qd-rc_body">
                                                                <svg id="top5_data_quality">
                                                                    <g id="canvas">
                                                                        <g id="art" />
                                                                        <g id="labels" />
                                                                    </g>
                                                                </svg>
                                                                {
                                                                    this.state.dq_reports_data !== "" ?
                                                                        <DQReports
                                                                            chartid="canvas"
                                                                            art="art"
                                                                            labels="labels"
                                                                            chartdata={this.state.dq_reports_data}
                                                                            monthviewyear={this.state.filtermonthviewyear}
                                                                            monthviewmonth={this.state.filtermonthviewmonth}
                                                                        />
                                                                        : ""
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* Report Card 7 */}
                                                        <div className={this.state.err === "No Data Found" || this.state.apicall === "loading" ? "qd-report-card d-none" : "qd-report-card"} id="widget7">
                                                            <div className="qd-rc_top">
                                                                <h1 className="qd-rc_title">{this.getChartTitle("dq_issue_title")} </h1>
                                                                <Dropdown className="qd-rc_dropdown d-none">
                                                                    <Dropdown.Toggle variant=" " id="dropdown-basic" className="p-1"></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" onClick={this.saveimage} data-widgetid="widget7" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160320" data-name="Group 160320" transform="translate(-2 -2)">
                                                                                        <g id="Group_160319" data-name="Group 160319" transform="translate(2 2)">
                                                                                            <path id="Path_83115" data-name="Path 83115" d="M9.705,12.995c.006.006.015.008.022.014a.691.691,0,0,0,.205.137h0a.7.7,0,0,0,.761-.152l3.5-3.5a.7.7,0,1,0-.99-.99L10.9,10.81V2.7a.7.7,0,1,0-1.4,0v8.11L7.195,8.505a.7.7,0,1,0-.99.99Z" transform="translate(-3.2 -2)" fill="#747474" />
                                                                                            <path id="Path_83116" data-name="Path 83116" d="M14.6,15.7v2.1a.7.7,0,0,1-.7.7H4.1a.7.7,0,0,1-.7-.7V15.7a.7.7,0,0,0-1.4,0v2.1a2.1,2.1,0,0,0,2.1,2.1h9.8A2.1,2.1,0,0,0,16,17.8V15.7a.7.7,0,1,0-1.4,0Z" transform="translate(-2 -5.9)" fill="#747474" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={this.savepdf} data-widgetid="widget7" data-widgetname="New Issue">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.72" viewBox="0 0 392.528 482.139">
                                                                                    <g id="icon_pdf" transform="translate(-44.806)">
                                                                                        <g id="Group_159867" data-name="Group 159867">
                                                                                            <path id="Path_82935" data-name="Path 82935" d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782a34.005,34.005,0,0,0-8.132.773v25.69a30.811,30.811,0,0,0,6.588.5C135.57,324.379,142.024,319.1,142.024,310.194Z" fill="#707070" />
                                                                                            <path id="Path_82936" data-name="Path 82936" d="M202.709,297.681a40.555,40.555,0,0,0-8.9.772v56.9a35.6,35.6,0,0,0,6.841.394c17.809.126,29.424-9.677,29.424-30.449C230.2,307.231,219.611,297.681,202.709,297.681Z" fill="#707070" />
                                                                                            <path id="Path_82937" data-name="Path 82937" d="M315.458,0H121.811A51.39,51.39,0,0,0,70.5,51.315V241.069H65.484a20.675,20.675,0,0,0-20.678,20.679v125.4a20.673,20.673,0,0,0,20.678,20.677H70.5v22.995a51.37,51.37,0,0,0,51.315,51.315H386.034a51.37,51.37,0,0,0,51.3-51.315V121.449ZM99.053,284.379a162.089,162.089,0,0,1,26.579-1.8c12.128,0,20.772,2.315,26.58,6.965a25.422,25.422,0,0,1,9.292,20.127c0,8.51-2.837,15.745-8,20.646-6.714,6.32-16.643,9.157-28.258,9.157a50.931,50.931,0,0,1-6.714-.379v31.1H99.053ZM386.034,450.713H121.811a19.906,19.906,0,0,1-19.874-19.889V407.829h246.31a20.674,20.674,0,0,0,20.679-20.677v-125.4a20.675,20.675,0,0,0-20.679-20.679H101.937V51.315a19.9,19.9,0,0,1,19.874-19.858l181.89-.19V98.5a35.6,35.6,0,0,0,35.587,35.587l65.862-.189.741,296.925A19.892,19.892,0,0,1,386.034,450.713ZM174.065,369.8V284.379a177.116,177.116,0,0,1,26.58-1.8c16.516,0,27.226,2.963,35.618,9.282,9.031,6.714,14.7,17.416,14.7,32.781,0,16.643-6.06,28.133-14.453,35.224-9.157,7.612-23.1,11.222-40.125,11.222A170.242,170.242,0,0,1,174.065,369.8Zm140.827-50.575v16h-31.23V370.2h-19.74V283.229h53.16v16.122h-33.42v19.875Z" fill="#707070" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Save as PDF</Dropdown.Item>
                                                                        {/* <Dropdown.Item href="#/action-3">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="icon_copy" transform="translate(-1 -1)">
                                                                                        <path id="Path_82939" data-name="Path 82939" d="M12,13a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V6A2,2,0,0,1,3,4V5A1.014,1.014,0,0,0,2,6v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1ZM6,2A1.014,1.014,0,0,0,5,3v7a1.014,1.014,0,0,0,1,1h7a1.014,1.014,0,0,0,1-1V3a1.014,1.014,0,0,0-1-1ZM6,1h7a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V3A2,2,0,0,1,6,1Z" fill="rgba(112,112,112,0.96)" />
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Copy as Image</Dropdown.Item>
                                                                        <Dropdown.Item href="#">
                                                                            <i className="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                                                    <g id="Group_160345" data-name="Group 160345" transform="translate(-1 -1)">
                                                                                        <g id="Group_160323" data-name="Group 160323" transform="translate(10.958 1)">
                                                                                            <g id="Group_160322" data-name="Group 160322" transform="translate(0 0)">
                                                                                                <g id="Group_160321" data-name="Group 160321">
                                                                                                    <path id="Path_83117" data-name="Path 83117" d="M48.69,5.042a.453.453,0,0,1-.452-.452V1.9H45.552a.452.452,0,0,1,0-.9H48.69a.453.453,0,0,1,.452.452V4.59A.453.453,0,0,1,48.69,5.042Z" transform="translate(-45.1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160326" data-name="Group 160326" transform="translate(1 10.958)">
                                                                                            <g id="Group_160325" data-name="Group 160325" transform="translate(0)">
                                                                                                <g id="Group_160324" data-name="Group 160324">
                                                                                                    <path id="Path_83118" data-name="Path 83118" d="M4.59,49.142H1.452A.453.453,0,0,1,1,48.69V45.552a.452.452,0,0,1,.9,0v2.687H4.59a.452.452,0,1,1,0,.9Z" transform="translate(-1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160329" data-name="Group 160329" transform="translate(10.958 10.958)">
                                                                                            <g id="Group_160328" data-name="Group 160328">
                                                                                                <g id="Group_160327" data-name="Group 160327">
                                                                                                    <path id="Path_83119" data-name="Path 83119" d="M48.69,49.142H45.552a.452.452,0,0,1,0-.9h2.687V45.552a.452.452,0,0,1,.9,0V48.69A.453.453,0,0,1,48.69,49.142Z" transform="translate(-45.1 -45.1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160332" data-name="Group 160332" transform="translate(1 1)">
                                                                                            <g id="Group_160331" data-name="Group 160331" transform="translate(0 0)">
                                                                                                <g id="Group_160330" data-name="Group 160330">
                                                                                                    <path id="Path_83120" data-name="Path 83120" d="M1.452,5.042A.453.453,0,0,1,1,4.59V1.452A.453.453,0,0,1,1.452,1H4.59a.452.452,0,0,1,0,.9H1.9V4.59A.453.453,0,0,1,1.452,5.042Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160335" data-name="Group 160335" transform="translate(1 1)">
                                                                                            <g id="Group_160334" data-name="Group 160334" transform="translate(0)">
                                                                                                <g id="Group_160333" data-name="Group 160333">
                                                                                                    <path id="Path_83121" data-name="Path 83121" d="M5.923,6.374a.446.446,0,0,1-.316-.135L1.135,1.768a.437.437,0,0,1,0-.632.437.437,0,0,1,.632,0L6.239,5.606a.437.437,0,0,1,0,.632.446.446,0,0,1-.316.135Z" transform="translate(-1 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160338" data-name="Group 160338" transform="translate(9.626 9.648)">
                                                                                            <g id="Group_160337" data-name="Group 160337">
                                                                                                <g id="Group_160336" data-name="Group 160336">
                                                                                                    <path id="Path_83122" data-name="Path 83122" d="M44.123,44.652a.446.446,0,0,1-.316-.135l-4.471-4.448a.447.447,0,1,1,.632-.632l4.471,4.471a.437.437,0,0,1,0,.632.54.54,0,0,1-.316.113Z" transform="translate(-39.2 -39.3)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160341" data-name="Group 160341" transform="translate(9.626 1)">
                                                                                            <g id="Group_160340" data-name="Group 160340" transform="translate(0 0)">
                                                                                                <g id="Group_160339" data-name="Group 160339">
                                                                                                    <path id="Path_83123" data-name="Path 83123" d="M39.652,6.374a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.437.437,0,0,1,.632,0,.437.437,0,0,1,0,.632L39.99,6.239a.46.46,0,0,1-.339.135Z" transform="translate(-39.2 -1)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_160344" data-name="Group 160344" transform="translate(1 9.626)">
                                                                                            <g id="Group_160343" data-name="Group 160343" transform="translate(0)">
                                                                                                <g id="Group_160342" data-name="Group 160342">
                                                                                                    <path id="Path_83124" data-name="Path 83124" d="M1.452,44.574a.446.446,0,0,1-.316-.135.437.437,0,0,1,0-.632l4.471-4.471a.447.447,0,1,1,.632.632L1.768,44.439a.446.446,0,0,1-.316.135Z" transform="translate(-1 -39.2)" fill="#747474" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                            Open in Fullscreen</Dropdown.Item> */}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>   
                                                                {/* Top 50 data issues impacting quality the most */}
                                                            <p className="qd-rc_desc">This table gives the {this.state.filter_selected_label} Data issues that have the most impact on the Data Quality</p>
                                                            <div className="qd-rc_body">

                                                                <div className="react-bootstrap-table table-responsive">
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Severity</th>
                                                                                <th>Environment</th>
                                                                                <th>Datasource</th>
                                                                                <th>Dataset</th>
                                                                                <th>Attributes</th>
                                                                                <th>Metric Name</th>
                                                                                <th>Metric value</th>
                                                                                {/* <th>Metric value</th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                this.state.dq_reports_data !== "" ?
                                                                                    this.state.dq_reports_data.top_50_data_quality_issues.map((top50, index) =>
                                                                                        <tr>
                                                                                        <td>{top50.severity}</td>
                                                                                        <td>{this.getmappingvalue("environments", top50.env_id)}</td>
                                                                                        <td>{this.getmappingvalue("integrations", top50.integration_id)}</td>
                                                                                        <td>{this.getmappingvalue("datasets", top50.data_set_id)}</td>
                                                                                        <td>{top50.meta_data_id !== null ? this.getmappingvalue("metadata", top50.meta_data_id) : "-"}</td>
                                                                                        <td>{top50.metrics_id !== null ? this.getmappingvalue("metrics", top50.metrics_id) : "-" }</td>
                                                                                        <td>{top50.metric_value !== null ? top50.metric_value +top50.unit : "-"}</td>
                                                                                        </tr>

                                                                                    )
                                                                                    : "No Data Found"
                                                                            }
                                                                        </tbody>
                                                                    </table>

                                                                    {
                                                                        // top50dataissueline("thumb_1")

                                                                        // this.state.dq_reports_data !=="" ?     
                                                                        // this.state.dq_reports_data.top_50_data_quality_issues.map((top50,index) =>
                                                                        // top50dataissueline("thumb_"+index)
                                                                        // )
                                                                        //     : "No Data Found"
                                                                    }



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="qd-report-card d-none">
                                                            <div className="rc-empty-state p-4 p-sm-5">
                                                                <div className="rc-empty-state_icon">
                                                                    <Image src="iconEmptyState" />
                                                                </div>
                                                                <p className="rc-empty-state_text">No data available.</p>
                                                                {/* <a href="#" className="rc-empty-state_link">Link dataset</a> */}
                                                            </div>
                                                        </div>
                                                        <div className="qd-report-card d-none">
                                                            <div className="rc-empty-state p-4 p-sm-5">
                                                                <div className="rc-empty-state_icon">
                                                                    <Image src="iconExclamationCircle" />
                                                                </div>
                                                                <p className="rc-empty-state_text">Unable to load chart.</p>
                                                                {/* <a href="#" className="rc-empty-state_link">Reload</a> */}
                                                            </div>
                                                        </div>
                                                        <div className="qd-report-card d-none" >
                                                            <div className="p-4 p-sm-5">
                                                                <div className="ghost-loader my-3">
                                                                    <div className="ghost-loader_wrap">
                                                                        <div className="position-relative">
                                                                            <div className="vertical-strip"></div>
                                                                            <div className="vertical-strip"></div>
                                                                            <div className="vertical-strip"></div>
                                                                        </div>
                                                                        <div className="ghost ghost-rectangle ghost-rectangle-lg"></div>
                                                                        <div className="ghost ghost-rectangle ghost-rectangle-sm"></div>
                                                                        <div className="ghost ghost-rectangle ghost-rectangle-md"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> 
                                                    {/* </Tab.Pane>
                                                    <Tab.Pane eventKey="cost-analysis">
                                                        <div className="qd-report-card">
                                                            <img className="w-100" src={Chart1} alt="" />
                                                        </div>
                                                        <div className="qd-report-card">
                                                            <img className="w-100" src={Chart2} alt=""/>
                                                        </div>
                                                        <div className="qd-report-card">
                                                            <img className="w-100" src={Chart3} alt=""/>
                                                        </div>
                                                        <div className="qd-report-card">
                                                            <img className="w-100" src={Chart4} alt=""/>
                                                        </div>
                                                        <div className="qd-report-card">
                                                            <img className="w-100" src={Chart5} alt=""/>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>     */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div id="heat_map_tooltip" class="d3-tip e">
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(DQReportFullScreen);
