import React from 'react';
import Toast from 'react-bootstrap/Toast';
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Notification from './Notification';
import PipelineTrigger from './PipelineTrigger';
import Alert from './Alert';
import {CopyRightsFooter} from '../components/copyrights';
import { getApi, postUIlogs } from "../utils/event_handling";
import {IS_NONE_CHECK} from "../utils/constant";
import {toastObj, getFeatureAccess} from "../utils/common_utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorHandler from '../components/error_500';
import {postMixpanel} from '../mixpanel';
import {logoutPageRedirect} from "../utils/common_utils";



class Consume extends React.Component {

    constructor(props) {
        super(props);
        this.getApi = getApi.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.handleTab = this.handleTab.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        this.state={
            toastStatus: false,
            data: null,
            tab: "dq",
            featureAccess: getFeatureAccess(),
            modelOptions: null,
            integration_option: null,
            pipeline_data: null,
            alertsDataDq: null,
            alertsDataMl: null,
            activeKey: "no",
            current_tab: "no",
            errors: {
                email: '',
            },
            dqmData: "",
            mlmData: "",
            details: {
               data_set: null,
               dqMetrics: [],
               mlMetrics: []
            }
        }
    }

    componentDidMount() {
        let logoutPage = logoutPageRedirect()
        if (logoutPage) {
            return ''
        }
        this.getData();

    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    getData() {
         this.getApi("consume_group");
         this.getApi("get_alerts_for_tenants");
         this.getApi("pipeline_trigger");
         this.getApi("alertDatasets");
         //this.getApi("getMetrics");
         this.getApi("completeMetricInfo");
         this.getApi("models");
         this.getApi("integrationOptions");

    }

    closeToast() {
        this.setState({toastStatus:false});
    }

    showToast(status, msg, link) {
       this.getData();
       this.setState({
          toastStatus: true,
          toastMsg: msg,
          toastLink: link,
          toastClass: toastObj[status]["className"],
          toastIcon: toastObj[status]["icon"],
          toastHeading: toastObj[status]["heading"],
        });
    }

    handleTab(key,tab) {
        postMixpanel("Alerts -> "+ key +" tab Page")
        this.setState ({current_tab: key,activeKey: key});
        if(tab === "dq" || tab === "ml") {
           this.setState({tab:tab});
        }
    }

    render() {
        let logoutPage = logoutPageRedirect()
        if (logoutPage) {
            return ''
        }
        let hideModelAlertsTab = true;

        if (!IS_NONE_CHECK.includes(this.state.featureAccess)) {
            const modelAlerts = this.state.featureAccess["models"];
            hideModelAlertsTab = modelAlerts === false;
        }

        let details = {"dqMetrics": this.state.dqmData, "mlMetrics": this.state.mlmData, "data_set": this.state.details.data_set};
        return (
            this.state.errorOccurred ?
                <ErrorHandler/>
            :
            <>
                <div id="toastMsg">
                    <Toast className={this.state.toastClass}
                           onClose={() =>  this.closeToast()}
                           show={this.state.toastStatus}
                           delay={3000} autohide>
                        <div className="d-flex align-items-center">
                            {this.state.toastStatus ?
                                <i className="toast-icon">
                                    <FontAwesomeIcon icon={this.state.toastIcon}/>
                                </i>
                                :
                                ''
                            }

                            <Toast.Body>
                               <strong className="toast-title">{this.state.toastHeading}</strong>
                               <p>{this.state.toastMsg}</p>
                            </Toast.Body>
                            <button type="button"
                                    onClick = {this.closeToast}
                                    className="close"
                                    data-dismiss="toast"
                                    aria-label="Close"/>
                        </div>
                    </Toast>
                </div>
                <NavigationHeader page="alerts"/>
                <main>
                    <BreadCrumb icon='hourGlass' title='Alerts' />
                    <Tab.Container defaultActiveKey="no" activeKey={this.state.activeKey} onSelect={this.handleTab}>
                        <div className="qd-tab__header">
                            <div className="container-fluid">
                                <div className="qd-tab__header-wrapper">
                                    <div className="qd-tab__header-menu">
                                        <Nav variant="tabs">
                                            <Nav.Item>
                                                <Nav.Link eventKey="no">
                                                    <span className="qd-menu__link-text">Notification Channels</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="pipe">
                                                    <span className="qd-menu__link-text">Pipeline Channels</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="alert">
                                                    <span className="qd-menu__link-text">Alerts</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="qd-container">
                            <div className="qd-body">
                                <div className="qd-tab__content" id={this.state.activeKey + "Tab"}>
                                    <Tab.Content>
                                        <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="no">
                                            <Notification data={this.state.data} showToast={this.showToast} />
                                        </Tab.Pane>
                                        <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="pipe">
                                            <PipelineTrigger hideModelAlertsTab={hideModelAlertsTab} pipeData={this.state.pipeline_data} changeTab={this.handleTab} showToast={this.showToast}/>
                                        </Tab.Pane>
                                        <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="alert">
                                            <Alert tab={this.state.tab}
                                                   details={details}
                                                   hideModelAlertsTab={hideModelAlertsTab}
                                                   modelOptions={this.state.modelOptions}
                                                   integrationOption={this.state.integration_option}
                                                   showToast={this.showToast}
                                                   alertsDataDq={this.state.alertsDataDq} alertsDataMl={this.state.alertsDataMl}/>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </main>
                <CopyRightsFooter/>
            </>
        );
    }
}

export default Consume;