import React from 'react';
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import {CopyRightsFooter} from '../components/copyrights';
import { getApi, postUIlogs, postRecentSearch } from "../utils/event_handling";
import $ from 'jquery';
import ErrorHandler from '../components/error_500';
import BasicPortlet from '../components/basicPortlet';
import DiscoverResults from './discoverResults';
import DiscoverForm from './discoverForm';
import {connect} from "react-redux";
import Autosuggest from 'react-autosuggest';
import './react-autosuggest.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock} from '@fortawesome/free-regular-svg-icons';
import { faServer,faDatabase,faTag} from '@fortawesome/free-solid-svg-icons';
import { faLayerGroup, faExclamation, faCodeBranch} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  addRecentHistory, fetchLastRefreshOfDatasets,
  storeDiscoverPageDataForDataSrc
} from "../redux/actions";
import RcTooltip from 'rc-tooltip';
//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { createBrowserHistory } from "history";
import { Scrollbars } from 'react-custom-scrollbars';
import {getFeatureAccess, checkSessionForRedirection} from "../utils/common_utils";
import {IS_NONE_CHECK, SKIP_SUGGESTION_RENDER} from "../utils/constant";
import {
  normalizeAttributeName,
  METADATA_MAP_KEY_SEPARATOR,
  METADATA_MAP_VALUE_SEPARATOR,
  normalizeChildDatasetName
} from '../utils/attribute_name_utils';



// Teach Autosuggest how to calculate suggestions for any given input value.
// const getSuggestions = value => {
//   const inputValue = value.trim().toLowerCase();
//   const inputLength = inputValue.length;

//   return inputLength === 0 ? [] : languages.filter(lang =>
//     lang.name.toLowerCase().slice(0, inputLength) === inputValue
//   );
// };
var totalSearchList = 0;
const formatMultiSection = (searchStr,data) => {

  const inputValue = searchStr.trim().toLowerCase();
  let environment = data.filter(value => {
    return value.type === "Environment" && value.name.toLowerCase().includes(inputValue);
  });

  let datasource = data.filter(value => {
    return value.type === "Datasource" && value.name.toLowerCase().includes(inputValue);
  });



  let dataset = data.filter(value => {
    return value.type === "Dataset" && value.name.toLowerCase().includes(inputValue);
  });

  let attribute = data.filter(value => {
    return value.type === "Attribute" && value.name.toLowerCase().includes(inputValue);
  });

  let recentSearch = []
  if(searchStr.trim() === "" ) {
    recentSearch = data.filter(value => {
        return value.type === "Recent";
      });
  }



let envObj = {
    type: "Environment",
    results: environment
};

let datasourceObj = {
    type: "Datasource",
    results: datasource
};

let datasetObj = {
    type: "Dataset",
    results: dataset
};

let attributeObj = {
    type: "Attribute",
    results: attribute
};

let recentSearchObj = {
    type: "Recent",
    results: recentSearch
};

let suggestions = [];

if (recentSearchObj.results.length > 0) {
    suggestions.push(recentSearchObj);
}

//let envlen = envObj.results.length;

if(envObj.results.length > 0) {
     suggestions.push(envObj);
}

if (datasourceObj.results.length > 0) {
    suggestions.push(datasourceObj);
}

if (datasetObj.results.length > 0) {
    suggestions.push(datasetObj);
}

if (attributeObj.results.length > 0) {
    suggestions.push(attributeObj);
}

totalSearchList = envObj.results.length+datasourceObj.results.length+datasetObj.results.length+attributeObj.results.length;
  return suggestions;
};


$("body").on("click",".btn-clear", function(){
  $(".qd_search-box").removeClass("has-btn-close")
});

$("body").on("keyup",".react-autosuggest__input", function(){
  if($(this).val() !== ""){
  $(".qd_search-box").addClass("has-btn-close")

  }else {
    $(".qd_search-box").removeClass("has-btn-close")
    //$(".qd_search-box").removeClass("is-focused");
    $(".qd_search-box").removeClass("ad-filter-open");
      let countId = document.getElementById(".filter-count")
      if(!countId){
        $("#btn-filter").removeClass("active");
      }
  }
   $(".filter-count").hide();
   $("#btn-filter").removeClass("active");
   $(".qd_search-box").removeClass("ad-filter-open");
   $(".qd_search-box").addClass("is-focused");

});

$(document).on("click", ".datalink", function(e) {
  if(document.getElementById($(this).attr("data-link"))!==null){
    document.getElementById($(this).attr("data-link")).scrollIntoView();
  }
});


function shouldRenderSuggestions(value, reason) {
  if (SKIP_SUGGESTION_RENDER.includes(reason)) {
    return false;
  }

  return value.trim().length > 2;
}


function renderSuggestionsContainer({ containerProps, children, query }) {
  if (children === null && query === "") {
    return null;
  }

  if (children === null && query !== "") {
    return (<div {...containerProps}>
      <div className="row row-sm">
        <div className="col-md-6">
          <h4 className="qd_autosuggest-title">Environment</h4>
          <p className="no-search-result"><FontAwesomeIcon icon={faExclamation} />No results were found</p>
        </div>
        <div className="col-md-6">
          <h4 className="qd_autosuggest-title">Datasource</h4>
          <p className="no-search-result"><FontAwesomeIcon icon={faExclamation} />No results were found</p>
        </div>
        <div className="col-md-6">
          <h4 className="qd_autosuggest-title">Dataset</h4>
          <p className="no-search-result"><FontAwesomeIcon icon={faExclamation} />No results were found</p>
        </div>
        <div className="col-md-6">
          <h4 className="qd_autosuggest-title">Attribute</h4>
          <p className="no-search-result"><FontAwesomeIcon icon={faExclamation} />No results were found</p>
        </div>
      </div>
    </div>)
  }

  // Children is not null. Query string is empty
  if (query.trim() === "") {
    let checkRecent = children[0].props.children[0].props.section.results.filter(x => x.type === "Recent")
    if (checkRecent.length === 0) {
      return ""
    }
  }

    // Children is not null. Query string is also not empty
  let recentFlag = "";
  let environmentFlag = "";
  let datasourceFlag = "";
  let datasetFlag = "";
  let attributeFlag = "";

  let environmentFlaglen = 0;
  let datasourceFlaglen = 0;
  let datasetFlaglen = 0;
  let attributeFlaglen = 0;


  children.map((item, i) => {

    if (item.props.children[0].props.section.results[0]['type'] === "Recent" && query.trim() === "") {
      recentFlag = i;
    }
    if (item.props.children[0].props.section.results[0]['type'] === "Environment") {
      environmentFlag = i;
      environmentFlaglen = item.props.children[0].props.section.results.length;
      environmentFlaglen = environmentFlaglen > 6 ? 168 : 28 * environmentFlaglen
    }
    if (item.props.children[0].props.section.results[0]['type'] === "Datasource") {
      datasourceFlag = i;
      datasourceFlaglen = item.props.children[0].props.section.results.length;
      datasourceFlaglen = datasourceFlaglen > 6 ? 168 : 28 * datasourceFlaglen

    }
    if (item.props.children[0].props.section.results[0]['type'] === "Dataset") {
      datasetFlag = i;
      datasetFlaglen = item.props.children[0].props.section.results.length;
      datasetFlaglen = datasetFlaglen > 6 ? 168 : 28 * datasetFlaglen

    }
    if (item.props.children[0].props.section.results[0]['type'] === "Attribute") {
      attributeFlag = i;
      attributeFlaglen = item.props.children[0].props.section.results.length;
      attributeFlaglen = attributeFlaglen > 6 ? 168 : 28 * attributeFlaglen

    }
    return 1;
  }
  );
  $(".qd_search-box").removeClass("ad-filter-open");
  $(".qd_search-box").addClass("is-focused");


  return (
    <div {...containerProps}>
      <div className="row row-sm">
        {recentFlag !== "" && query === "" ? <div className="col-md-12">
          <h4 className="qd_autosuggest-title">Recent Searches:</h4>
          <div>{children[recentFlag]}</div>
        </div> : ""}
        {query !== "" ? <><div className="col-md-6">
          <h4 className="qd_autosuggest-title">Environment</h4>

          {environmentFlag !== "" ? (
            <Scrollbars style={{ height: environmentFlaglen }} className="custom-scrollbar"
              autoHeightMin={0}
              autoHeightMax={200}
              thumbMinSize={30}
              universal={true}>
              <div style={{ paddingRight: 5 }}>{children[environmentFlag]}</div>
            </Scrollbars>
          ) : (
            <p className="no-search-result"><FontAwesomeIcon icon={faExclamation} />No results were found</p>
          )}

        </div>
          <div className="col-md-6">
            <hr className="d-block d-sm-none" />
            <h4 className="qd_autosuggest-title">Datasource</h4>

            {datasourceFlag !== "" ? (
              <Scrollbars style={{ height: datasourceFlaglen }} className="custom-scrollbar"
                autoHeightMin={0}
                autoHeightMax={200}
                thumbMinSize={30}
                universal={true}>
                <div style={{ paddingRight: 5 }} >{children[datasourceFlag]}</div>
              </Scrollbars>
            ) : (
              <p className="no-search-result"><FontAwesomeIcon icon={faExclamation} />No results were found</p>
            )}

          </div>
          <div className="col-md-6">
            <hr />
            <h4 className="qd_autosuggest-title">Dataset</h4>


            {datasetFlag !== "" ? (
              <Scrollbars style={{ height: datasetFlaglen }} className="custom-scrollbar"
                autoHeightMin={0}
                autoHeightMax={200}
                thumbMinSize={30}
                universal={true}>
                <div style={{ paddingRight: 5 }}>{children[datasetFlag]}</div>
              </Scrollbars>
            ) : (
              <p className="no-search-result"><FontAwesomeIcon icon={faExclamation} />No results were found</p>
            )}

          </div>
          <div className="col-md-6">
            <hr />
            <h4 className="qd_autosuggest-title">Attribute</h4>

            {attributeFlag !== "" ? (
              <Scrollbars style={{ height: attributeFlaglen }} className="custom-scrollbar"
                autoHeightMin={0}
                autoHeightMax={200}
                thumbMinSize={30}
                universal={true}>
                <div style={{ paddingRight: 5 }}>{children[attributeFlag]}</div>
              </Scrollbars>
            ) : (
              <p className="no-search-result"><FontAwesomeIcon icon={faExclamation} />No results were found</p>
            )}

          </div>
          <div className="col-md-12">
            <hr />
            <p className="search-result__count"><strong>{totalSearchList}</strong> Search Results</p>
          </div></> : ""}
      </div>

    </div>
  );

}



//const getSuggestions = (value,autoCompleteData) => {
//  const inputValue = value.trim().toLowerCase();
//  const inputLength = inputValue.length;
//
//  return inputLength === 0 ? [] : autoCompleteData.filter(lang =>
//    lang.name.toLowerCase().slice(0, inputLength) === inputValue
//  );
//};



const renderSectionTitle = section => {
    // return (
    //   // <div style={{ display: "flex", alignItems: "flex-end" }}>
    //   //   <div style={{ marginRight: 8 }}>
    //   //   </div>
    //   //   <strong style={{ color: "#1D2C4C" }}><b>{section.type}</b></strong>
    //   // </div>
    //   // <div>
    //   //   <h4>{section.type}</h4>
    //   // </div>
    // );

};



// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
//const renderSuggestion1 = suggestion => (
//  <div>
// {suggestion.name}
//  </div>
//);

const getSectionSuggestions = section => {
  return section.results;
};

let openflag = false;
$(document).on('click', function(e) {
  var container = $(".qd_search-filter-form");
  var btnfilter = $("#btn-filter");
  if ((!$(e.target).closest(container).length) && (!$(e.target).closest(btnfilter).length))  {
    $(".qd_search-filter-rst").removeClass("d-block")
    $(".qd_search-box").removeClass("ad-filter-open");
    var modelView = $(".react-autosuggest__suggestions-container--open")
    if(modelView.length === 0){
      $(".qd_search-box").removeClass("is-focused");
    }
    if ($('.filter-count').css('display') === 'none') {
        $("#btn-filter").removeClass("active");
    }
    openflag = true;
  }
});

class Discover extends React.Component {

    constructor(props) {
        super(props);
        this.getApi = getApi.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        this.postRecentSearch = postRecentSearch.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.changeCount = this.changeCount.bind(this);
        this.closeAdvancedFilter = this.closeAdvancedFilter.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
        this.renderSuggestionsContainer = renderSuggestionsContainer.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.handleAdvancedFilter = this.handleAdvancedFilter.bind(this);
        this.convertData = this.convertData.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        let searchText = ""
        if(props.location.state !== undefined) {
          searchText = props.location.state["search_text"]
          const history = createBrowserHistory();
          let state = { ...history.location.state };
          delete state.transaction;
          history.replace({ ...history.location, state });
        }

        this.state={
            nestedData: {},
            newData: {},
            showTreeView: false,
            initialSelection: {},
            isSearchHappened: false,
            errorOccurred: false,
            autoCompleteData: [],
            subString: null,
            searchCount: 0,
            fullData: [],
            isFilterOpen: false,
            isNoResult: false,
            searchText: searchText,
            filteredData: [],
            isRedirect: false,
            value: searchText,
            suggestions: [],
            preSelectedEnv: null,
            preSelectedDataset: null,
            preSelectedDatasource: null,
            preSelectedAttribute: null,
            envCount: null,
            datasourceCount: null,
            datasetCount: null,
            attributeCount: null,
        }
    }

    closeAdvancedFilter() {

      this.setState({isFilterOpen: false})
      // this.setState({value: ""});
      // $("#filter-count").hide();
      // $(".filtericon").show();
      openflag = false
      if(this.state.envCount === null) {
         $("#btn-filter").removeClass("active");
      }

      $(".qd_search-box").removeClass("ad-filter-open");
    }



    handleAdvancedFilter(){
      let isFilterOpen = !this.state.isFilterOpen
       if(isFilterOpen || openflag) {
         this.setState({value: ""});
         if(openflag){
           $(".qd_search-filter-rst").addClass("d-block")
           this.setState({isFilterOpen: true})
           openflag = false
         }else {
           this.setState({isFilterOpen: isFilterOpen})
         }
         $("#filter-count").hide();
         $("#btn-filter").addClass("active");
         $(".qd_search-box").addClass("ad-filter-open");
       }else {
          $(".qd_search-box").removeClass("ad-filter-open");
          this.setState({isFilterOpen: false});

          if(this.state.envCount === null) {
             $("#btn-filter").removeClass("active");
          }


       }
    }

  

    // Auto Complete
    onChange = (event, { newValue }) => {

      this.setState({
        value: newValue
      });
    };

  
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
      //suggestions: getSuggestions(value,this.state.autoCompleteData)
      this.setState({
        suggestions: formatMultiSection(value,this.state.autoCompleteData)
      });

    };

  renderSuggestion(suggestion, { query }) {
    this.setState({ isFilterOpen: false })
    const suggestionText = suggestion.name;
    let uniqueKey = `${suggestion.envId}_${suggestion.datasourceId}_${suggestion.datasetId}_${suggestion.attributeId}`
    uniqueKey = uniqueKey.replace(undefined, "_")
    let icon = '';
    let connectionIcon = '';
    let path = '';
    switch (suggestion.type) {
      case "Recent":
        icon = '';
        break;
      case "Environment":
        icon = faServer;
        connectionIcon = '';
        path = suggestion.name;
        break;
      case "Datasource":
        icon = faDatabase;
        connectionIcon = suggestion.envName;
        path = suggestion.envName + "/" + suggestion.name;
        break;
      case "Dataset":
        icon = faLayerGroup;
        connectionIcon = suggestion.datasourceName;
        path = suggestion.envName + "/" + suggestion.datasourceName + "/" + suggestion.name;
        break;
      case "Attribute":
        icon = faTag;
        connectionIcon = suggestion.datasetName;
        path = suggestion.envName + "/" + suggestion.datasourceName + "/" + suggestion.datasetName + "/" + suggestion.name;
        break;
      default:
        icon = '';
        connectionIcon = '';
        break;
    }

    // Handle recency section here and return corresponding component
    if (suggestion.type === "Recent") {
      return (
        <div className={"suggestion-content"}>
          <p className="qd_autosuggest-option">
            <FontAwesomeIcon icon={faClock} />&nbsp;
            {this.getHighlightedText(suggestionText, query)}
            <i className="icon-close" title="Close"></i>
          </p>
        </div>
      );
    }

    const renderTooltipText = (props) => (
      <span {...props}>
        {path}
      </span>
    );

    return (
      <div data-link={uniqueKey} className="datalink"
        style={{ textDecoration: "none" }}>

        <div className={"qd_autosuggest-content"}>
            <div className="qd_autosuggest-option">
              <RcTooltip 
                placement="top" 
                overlay={renderTooltipText}
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
              >
                <div className="qd_autosuggest-option-inner">
                  <FontAwesomeIcon icon={icon} />
                  {this.getHighlightedText(suggestionText, query)}
                </div>
              </RcTooltip>
            </div>
          
          
          {connectionIcon.length > 0
            ?
            <RcTooltip 
                placement="top" 
                overlay={connectionIcon}
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
              >
            <span><FontAwesomeIcon icon={faCodeBranch} />
              {connectionIcon}
            </span>
            </RcTooltip>
            : ''
          }

        </div>

      </div>
    );

  }
  
   getHighlightedText(text, highlight) {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return( parts.map((part, i) =>
            <span key={i}>
                { part.toLowerCase() === highlight.toLowerCase() ? <strong>{part}</strong> : part }
            </span>)
         );
    }

    changeCount(envCount,datasourceCount, datasetCount, attributeCount){
       this.setState({envCount: envCount, datasourceCount:datasourceCount,
                      datasetCount: datasetCount, attributeCount:attributeCount})
      openflag = true
    }


    applyFilter(type, envOption=null, datasourceOption=null, datasetOption=null, attributeOption=null) {
         let nestedData = _.cloneDeep(this.state.nestedData)
         let newData = {}
         let initialSelection = {"type": type}
         let envId = null
         let envName = null
         let datasourceId = null
         let datasourceName = null
         let datasetId = null
         let datasetName = null
         let attributeId = null
         let attributeName = null
         if(envOption !== null) {
           envId = envOption.value;
           envName = envOption.label;
           initialSelection["envId"] = envId
           initialSelection["envName"] = envName
           newData[envId] = nestedData[envId]
           if(type === "Environment") {
              newData[envId]["isShow"] = true
           }
           newData[envId]["isOpen"] = true
         }
         if(datasourceOption !== null) {
            datasourceId = datasourceOption.value;
            datasourceName = datasourceOption.label;
            initialSelection["datasourceId"] = datasourceId
            initialSelection["datasourceName"] = datasourceName
            if(type === "Datasource") {
              newData[envId]["childNodes"][datasourceId]["isShow"] = true
            }
            newData[envId]["childNodes"][datasourceId]["isOpen"] = true
         }
         if(datasetOption !== null) {
            datasetId = datasetOption.value;
            datasetName = datasetOption.label;
            initialSelection["datasetId"] = datasetId
            initialSelection["datasetName"] = datasetName
            if(type === "Dataset") {
               newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["isShow"] = true
            }
            newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["isOpen"] = true
         }
         if(attributeOption !== null) {
            attributeId = attributeOption.value;
            attributeName = attributeOption.label;
            initialSelection["attributeId"] = attributeId
            initialSelection["attributeName"] = attributeName
            newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"][attributeId]["isOpen"] = true
            newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"][attributeId]["isShow"] = true
            }

            this.setState({newData: newData,
                      initialSelection: initialSelection,
                       isSearchHappened: true, searchText: "",
                       preSelectedAttribute: attributeOption, preSelectedDataset: datasetOption,
                       preSelectedDatasource: datasourceOption, preSelectedEnv: envOption});
    }
    handleSelected = (event, data) => {
         let name = data.suggestion.name
         let type = data.suggestion.type
         if(name === "") {
           return
         }
         let initialSelection = data.suggestion
         $(".qd_search-box").addClass("has-btn-close")
         if(type === "Recent"){
           return
         }
          $(".filter-count").hide();
          $("#filtericon").show();
          $("#btn-filter").removeClass("active");
          $(".qd_search-box").removeClass("is-focused");

          this.setState({isRedirect: false, preSelectedEnv: null,preSelectedDatasource: null,
                            preSelectedDataset: null, preSelectedAttribute: null, envCount: null,
                            datasourceCount: null, datasetCount: null, attributeCount: null,  subString: null});

         this.setState({value: ""});
         $(".qd_search-box").removeClass("has-btn-close")
         this.convertData(initialSelection, this.state.fullData, this.state.nestedData, name, false)
    }

    convertData(initialSelection, fullData, nestedData, searchText, isRedirect){

       let filteredData = [];
       let searchCount = 0;
       let name;
       if(initialSelection === null) {
         filteredData = fullData.filter(x=>(x.name.includes(searchText) && x.type !== "Recent"))
         searchCount = filteredData.length
         if(filteredData.length === 0) {
           this.setState({newData: {},
                        isSearchHappened: true,
                        isRedirect: isRedirect,
                        isNoResult: true,
                        searchText: searchText});
           return
         }
         initialSelection = filteredData[0]
         name = initialSelection["name"]
       }else {
         if(initialSelection.type === "Recent") {
           filteredData = fullData.filter(x=>(x.name === searchText && x.type !== "Recent"))
           initialSelection = filteredData[0]
         }else {
           filteredData = fullData.filter(x=>x.name === initialSelection.name && x.id === initialSelection.id && x.type === initialSelection.type)
         }
         name = searchText
       }
       if(initialSelection.type === "Datasource") {
            initialSelection["datasourceName"] = name
         }else if(initialSelection.type === "Dataset") {
           initialSelection["datasetName"] = name
         }else if(initialSelection.type === "Attribute") {
           initialSelection["attributeName"] = name
         }else if(initialSelection.type === "Environment") {
           initialSelection["envName"] = name
         }
         let newData = {}
         nestedData = _.cloneDeep(nestedData)

         for(let i=0;i<filteredData.length;i++) {
            let data = filteredData[i]
            let type = data["type"]

            let envId = data["envId"]
            let datasourceId = data["datasourceId"]
            let datasetId = data["datasetId"]
            let attributeId = data["attributeId"]
            if(!Object.keys(newData).includes(envId)){
               newData[envId] = nestedData[envId]
               if(type === "Environment" || isRedirect) {
                 newData[envId]["isShow"] = true
               }
               newData[envId]["isOpen"] = true
            }

            if(datasourceId !== undefined) {
               newData[envId]["childNodes"][datasourceId]["isOpen"] = true
               if(type === "Datasource" || isRedirect) {
                 newData[envId]["childNodes"][datasourceId]["isShow"] = true
               }
            }

            if(datasetId !== undefined) {
               newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["isOpen"] = true
               if(type === "Dataset" || isRedirect) {
                  newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["isShow"] = true
               }
            }

            if(attributeId !== undefined) {
               newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"][attributeId]["isOpen"] = true
               newData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"][attributeId]["isShow"] = true
            }




         }
         let recentHistory = this.props.dataModule.recentHistory
         if(!recentHistory.includes(name)) {
            this.postRecentSearch(name);
            recentHistory.push(name);
            fullData.push({"type": "Recent", "name": name});
            this.props.addRecentHistory(recentHistory);
         }
           this.setState({isRedirect: isRedirect, searchCount: searchCount});

         this.setState({newData: newData,
                        initialSelection: initialSelection,
                        isSearchHappened: true,
                        isNoResult: false,
                        filteredData: filteredData,
                        fullData: fullData,
                        searchText: searchText});

    }

  
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {

      this.setState({
        suggestions: [],
      });
    };
    // End Auto Complete 

    componentDidMount() {
        checkSessionForRedirection()
        if(this.props.dataModule.datasetLastProcessInfoOver === false) {
          this.getApi("fetchDatasourceChartData");

          // Get Dataset Last processed time from API 
          this.getApi("fetchDatasetRefreshInfo");
       }

        this.getData();

    }


    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    componentDidUpdate(prevProps){
          if(prevProps.dataModule.metaData !== this.props.dataModule.metaData) {
             this.getData();
          }
    }

    getData() {
        let metaData = this.props.dataModule.metaData
        let nestedData = {}
        let fullData = []
        let envId = null
        let datasourceId = null
        let datasetId = null
        let prevEnvId = null
        let prevDatasourceId = null
        let prevDatasetId = null
        let attributeId = null
        let recentSearchData = this.props.dataModule.recentHistory
        for(let i=0;i<recentSearchData.length;i++) {
           fullData.push({"type": "Recent", "name": recentSearchData[i]});
        }


        for(const [key, value] of Object.entries(metaData)) {
           let keySplit = key.split(METADATA_MAP_KEY_SEPARATOR)
           let valueSplit = value.split(METADATA_MAP_VALUE_SEPARATOR)
           envId = keySplit[0]
           datasourceId = keySplit[1]
           datasetId = keySplit[2]
           attributeId = keySplit[3]
          if (attributeId === undefined) {
            continue;
          }

           let envName = valueSplit[0]
           let datasourceName = valueSplit[1]
           let datasetName = valueSplit[2]
           datasetName = normalizeChildDatasetName(datasetName)
           let attributeName = valueSplit[valueSplit.length-1]
           attributeName = normalizeAttributeName(attributeName);
           if(prevEnvId === null || prevEnvId !== envId){
             fullData.push({"name": envName, "envId": envId, "type": "Environment", "id": envId})
           }
           if(prevDatasourceId === null || prevDatasourceId !== datasourceId) {
              fullData.push({"name": datasourceName, "envName": envName, "id": datasourceId,
                             "datasourceId": datasourceId, "type": "Datasource", "envId": envId})

              
           }
           if(prevDatasetId === null || prevDatasetId !== datasetId) {
             fullData.push({"name": datasetName, "id": datasetId, "datasetId": datasetId, "type": "Dataset", "envId": envId,
                             "envName": envName, "datasourceId": datasourceId, "datasourceName": datasourceName})

           }


           fullData.push({"name": attributeName, "id": attributeId, "attributeId": attributeId, "type": "Attribute","envId": envId, "envName": envName,
                          "datasourceName": datasourceName, "datasourceId": datasourceId,  "datasetId": datasetId, "datasetName": datasetName})

           if(!Object.keys(nestedData).includes(envId)) {
                 nestedData[envId] = {"title": envName, "childNodes": {}, "type": "Environment", "id": envId}
                 nestedData[envId]["childNodes"][datasourceId] = {"title": datasourceName, "childNodes": {}, "type": "Datasource", "id": datasourceId}
                 nestedData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]={"title": datasetName, "childNodes": {}, "type": "Dataset", "id": datasetId}
                 nestedData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"][attributeId]={"title": attributeName, "type": "Attribute", "id": attributeId}

           }else{
               if(!Object.keys(nestedData[envId]["childNodes"]).includes(datasourceId)){
                 nestedData[envId]["childNodes"][datasourceId] = {"title": datasourceName, "childNodes": {}, "type": "Datasource", "id": datasourceId}
                 nestedData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]={"title": datasetName, "childNodes": {}, "type": "Dataset", "id": datasetId}
                 nestedData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"][attributeId]={"title": attributeName, "type": "Attribute", "id": attributeId}
               }else {
                 if(!Object.keys(nestedData[envId]["childNodes"][datasourceId]["childNodes"]).includes(datasetId)){
                   nestedData[envId]["childNodes"][datasourceId]["childNodes"][datasetId] = {"title": datasetName, "childNodes": {}, "type": "Dataset", "id": datasetId}
                   nestedData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"][attributeId] = {"title": attributeName, "type": "Attribute", "id": attributeId}
                 }else {
                   nestedData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"][attributeId] = {"title": attributeName, "type": "Attribute", "id": attributeId}
                 }
               }

           }

           prevDatasourceId = datasourceId
           prevDatasetId = datasetId
           prevEnvId = envId




        }
        let searchText = this.state.searchText;
        let isRedirect = false
        let subString = null
        if(searchText !== undefined && searchText !== null && searchText !== "") {
          isRedirect = true
          subString = searchText
          this.convertData(null, fullData, nestedData, searchText, isRedirect)
          $(".qd_search-box").addClass("has-btn-close")

        }

        //let autoCompleteData = fullData.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name && t.type===v.type))===i)

        this.setState({nestedData: nestedData,
                       autoCompleteData: fullData,
                       fullData: fullData,
                       subString: subString,
                       metaData: metaData
                       });

    }


    clearSearch() {
        $(".qd_search-box").removeClass("is-focused");
        this.setState({newData: {},
                       searchText: "",
                       subString: null, value: "",
                       isNoResult: false,
                       searchCount: 0, isRedirect: false, isSearchHappened: false, initialSelection: {}})
    }






    render() {
      if ([false].includes(this.props.dataModule.datasetLastProcessInfoOver)) {
        return "";
      }

      let datasourceData = this.props.dataModule.discoverPageDataSource;
      // if (this.props.monitorModule.recencyPreview !== undefined) {
      //   datasourceData["Data Timeliness"] = this.props.monitorModule.recencyPreview.data;
      // }
      const { value, suggestions } = this.state;

      // Autosuggest will pass through all these props to the input.
      const inputProps = {
        placeholder: 'Search for any thing! Just start typing...',
        value,
        onChange: this.onChange,
      };

      const featureAccess = getFeatureAccess();
      let enableDiscoveryTab = false;
      if (!IS_NONE_CHECK.includes(featureAccess)) {
        const discoverTab = featureAccess["discover"];
        enableDiscoveryTab = discoverTab === true;
      }

        return (
            this.state.errorOccurred ?
                <ErrorHandler/>
            :enableDiscoveryTab?
            <>
                <NavigationHeader page="discover"/>
                <main>
                    <BreadCrumb icon='faSearchEngine' title='Discover' />
                    <div className="qd-container">
                            <div className="qd-body no-tabs">
                                <div className="qd-tab__content" id={"Tab"}>
                                     <div className="qd_search-container">
                                          <div className="qd_search-box autocomplete">
                                            <Autosuggest
                                                multiSection={true}
                                                suggestions={suggestions}
                                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                getSuggestionValue={getSuggestionValue}
                                                renderSuggestion={this.renderSuggestion}
                                                inputProps={inputProps}
                                                alwaysRenderSuggestions={value.trim() === "" ? true: false}
                                                renderSectionTitle={renderSectionTitle}
                                                getSectionSuggestions={getSectionSuggestions}
                                                onSuggestionSelected={this.handleSelected}
                                                highlightFirstSuggestion={true}
                                                shouldRenderSuggestions={shouldRenderSuggestions}
                                                renderSuggestionsContainer = {this.renderSuggestionsContainer}
                                            />
                                            {this.state.value.trim() !== "" ? <button className="btn btn-clear" title="Clear" onClick={this.clearSearch}><i className="icon-close"></i></button> : ""}
                                            <div className="qd_search-bar-divider">
                                                   <span>or</span>
                                            </div>
                                            <div className="qd-filtered_button">
                                              <button onClick={this.handleAdvancedFilter} id="btn-filter" className="btn btn-light-secondary">
                                                <i id="filtericon"><svg xmlns="http://www.w3.org/2000/svg" fill="#0021a5" height="24" viewBox="0 0 24 24" width="24">
                                                  <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
                                                  <path d="M0 0h24v24H0z" fill="none"></path>
                                                </svg></i>
                                                <span className="filter-count" style={{ display:"none"}}>3</span><span>Filters</span>
                                              </button>
                                            </div>
                                          </div>


                                          <div className={this.state.isFilterOpen ? "qd_search-filter-rst d-none d-block":"qd_search-filter-rst d-none"} data-select2-id="29">
                                               {this.state.isFilterOpen ? 
                                               <DiscoverForm nestedData={this.state.nestedData} 
                                               applyFilter={this.applyFilter}  
                                               changeCount = {this.changeCount} 
                                               closeAdvancedFilter={this.closeAdvancedFilter} 
                                               filterResultData = {this.state.autoCompleteData}
                                               preSelectedEnv = {this.state.preSelectedEnv}
                                               envCount={this.state.envCount} datasourceCount={this.state.datasourceCount}
                                               datasetCount={this.state.datasetCount} attributeCount={this.state.attributeCount}
                                               preSelectedDataset = {this.state.preSelectedDataset}
                                               preSelectedDatasource = {this.state.preSelectedDatasource}
                                               preSelectedAttribute = {this.state.preSelectedAttribute}
                                               /> : ""}
                                          </div>
                                     </div>
                                     <input type="hidden" id="zoomlevel" />
                                          <BasicPortlet
                                            className="mb-0 mt-2 mt-sm-3"
                                            id="discoverSearch"
                                            showHelpSection={true}
                                            searchText={this.state.searchText === "" || (!this.state.isRedirect) ? "" : this.state.searchText}
                                            bodyClassName="p-0"
                                            title={this.state.searchText === "" || (!this.state.isRedirect) ? "DISCOVER" : "Search results"}
                                            content={<DiscoverResults
                                                          metaData={this.state.metaData}
                                                          datasourceData={datasourceData}
                                                          isRedirect={this.state.isRedirect}
                                                          filteredData={this.state.filteredData}
                                                          subString={this.state.subString}
                                                          searchCount={this.state.searchCount}
                                                          clearSearch={this.clearSearch}
                                                          isNoResult={this.state.isNoResult}
                                                          autoCompleteData={this.state.autoCompleteData}
                                                          originalData={this.state.nestedData}
                                                          nestedData={Object.values(this.state.newData).length === 0  ? (this.state.isNoResult ? this.state.newData : this.state.nestedData) : this.state.newData}
                                                          initialSelection={this.state.initialSelection}
                                                          dataModule={this.props.dataModule}
                                                          isSearchHappened={this.state.isSearchHappened}
                                                          metricData={this.props.monitorModule.monitorDQListView}
                                                          datasetRefreshInfo={this.props.dataModule.datasetLastProcessInfo}
                                                          />}
                                        />
                                </div>
                            </div>
                    </div>
                </main>
                <CopyRightsFooter/>
            </>
            :<></>
        );
    }
}


const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, {
  addRecentHistory,
  fetchLastRefreshOfDatasets,
  storeDiscoverPageDataForDataSrc
})(Discover);
