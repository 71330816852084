import React from 'react';
import _ from 'lodash';
import {
    RELATIONSHIP_METRIC,
    RELATIONSHIP_STATIC_CARD,
    RELATIONSHIP_METRIC_ORDER,
    RELATIONSHIP_METRIC_COLOR,
} from '../utils/constant';
import {getAllMetricRecencyInfo} from '../utils/common_utils';
import RcTooltip from 'rc-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardHelpContent from '../components/cardHelpContent';
import { faQuestionCircle, faCloud, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import ChartBody from '../components/chartBody';
import moment from 'moment';
import NoErrorComponent from '../monitor/components/monitorNoResult';
import Col from 'react-bootstrap/Col';
import Image from '../components/image';
import Load from '../components/loadAction';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

const renderTooltip = (props) => (
    <span {...props}>
      Need Help ?
    </span>
  );
class MetricsCards extends React.Component {
      constructor(props, context) {
        super(props, context);
        this.getCardData = this.getCardData.bind(this);
        this.filterInfoData = this.filterInfoData.bind(this);
        this.filterTimeData = this.filterTimeData.bind(this);
        this.state={
            showModal: false,
            cardName: ""
        }
      }
      filterInfoData(filteredValues, infoData) {
        let updatedInfoData = {}
        filteredValues.forEach((filteredValue) => {
            const filtered = Object.keys(infoData)
                .filter(key => key.startsWith(filteredValue))
                .reduce((obj, key) => {
                    obj[key] = infoData[key];
                    return obj;
                }, {});
            updatedInfoData = {...updatedInfoData, ...filtered}
        });
        return updatedInfoData;
    }

      getCardData(){
          if (this.props.selectedDataSource !== -1) {
            let cardInfo = [];
            let filteredValues = this.props.filteredValues;
            let allMetricRecencyInfo = getAllMetricRecencyInfo(this.props.dataModule.info, this.props.dataModule.recencyDetails)
            let allMetricInfo = _.cloneDeep(allMetricRecencyInfo[this.props.selectedDataSource]);
            if(allMetricInfo === undefined) {
              return cardInfo;
            }
            if (filteredValues.length !== 0) {
                allMetricInfo = this.filterInfoData(filteredValues, allMetricInfo);
            }
            let commonId = 0;
            RELATIONSHIP_METRIC_ORDER.forEach((key) => {
                let attributeMetricList = [];
                //let chartMetricList = [];
                for (const metricInfo in allMetricInfo) {
                    let metaData = this.props.dataModule.metaData[metricInfo];
                    if (metaData !== undefined || key === 19) {
                        if (key !== "Data Conformity Errors" && key !== "Data Consistency Errors") {
                            if (key in allMetricInfo[metricInfo]) {
                                attributeMetricList.push(allMetricInfo[metricInfo][key]);
                                //chartMetricList.push(allMetricInfo[metricInfo][`${key}_time_value`]);
                            }
                        } else {
                            //eslint-disable-next-line
                            RELATIONSHIP_METRIC[key].forEach((subMetricId) => {
                                if (subMetricId in allMetricInfo[metricInfo]) {
                                        attributeMetricList.push(allMetricInfo[metricInfo][subMetricId]);
                                    //chartMetricList.push(allMetricInfo[metricInfo][`${subMetricId}_time_value`]);
                                }
                            });
                        }
                    }
                }
                let tempInfo = {};
                if (key !== "Data Conformity Errors" && key !== "Data Consistency Errors") {
                    tempInfo["metric_name"] = RELATIONSHIP_METRIC[key];
                } else {
                    tempInfo["metric_name"] = key;
                }
                tempInfo["background"] = RELATIONSHIP_METRIC_COLOR[key].cardBackground;
                tempInfo["textColor"] = RELATIONSHIP_METRIC_COLOR[key].textColor;
                if (attributeMetricList.length !== 0) {
                    if (key === "6" || key === "Data Conformity") {
                        attributeMetricList = attributeMetricList.filter((item) => item !== 0);
                    }
                    let computedMetricValue = `${(attributeMetricList.reduce((a, b) => a + b, 0) / attributeMetricList.length).toFixed(2)}`;
                    if (isNaN(computedMetricValue)) {
                        computedMetricValue = 0.00
                    }
                    tempInfo["metric_value"] = computedMetricValue;
                } else {
                    tempInfo["metric_value"] = "NA";
                }

                tempInfo["hide_edit"] = true;
                tempInfo["cardType"] = "chart";
                tempInfo["chartData"] = this.filterTimeData(key);
                tempInfo["idValue"] = `relationship${commonId}`;
                commonId = commonId + 1;
                cardInfo.push(tempInfo);
            })
            return cardInfo
        }
      }
      handleClick(data) {
        this.setState({showModal: true, cardName: data.metric_name});
      }
      hideModal = () => {
        this.setState({showModal: false});
      }
      filterTimeData(key) {
        let tempStartDate = moment(this.props.dataModule.startDate).format('YYYY-MM-DD 00:00:00')
        let timeList = [];
        for (let i = 0; i < 8; i++) {
            timeList.push(tempStartDate);
            tempStartDate = moment(tempStartDate).add(1, 'days').format('YYYY-MM-DD 00:00:00');
        }

        return {"time": timeList, "values": RELATIONSHIP_STATIC_CARD[key]}
    }
      render() {
         let cardData = this.getCardData();
         if(this.props.sectionName === "Attribute") {
           let profileData = this.props.profileData;
           return (profileData === null ? <Load/> : <><Row bsPrefix="form-row">
                                <Col lg={12}>
                                    <Row bsPrefix="form-row">
                                        <Col lg={4} md={6} xs={12}>
                                            <Card className="card-attribute">
                                                <Card.Body>
                                                <div className="card-content">
                                                    <Card.Title>Errors Found</Card.Title>
                                                    <h1 className="card-text">{this.props.outlier ? this.props.outlier : "0"}</h1>
                                                </div>
                                                <div className="card-image">
                                                <Image
                                                className="card_line-graph"
                                                 src="linegraph"
                                                />
                                                </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={4} md={6} xs={12}>
                                            <Card className="card-attribute">
                                                <Card.Body>
                                                <div className="card-content">
                                                    <Card.Title>Total Space Occupied</Card.Title>
                                                    <h1 className="card-text">{this.props.size ? this.props.size : "0 KB"}</h1>
                                                </div>
                                                <div className="card-image">
                                                <i><FontAwesomeIcon icon={faCloud} /></i>
                                                </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={4} md={6} xs={12}>
                                            <Card className="card-attribute">
                                                <Card.Body>
                                                    <div className="card-content">
                                                        <Card.Title>Number of Records</Card.Title>
                                                        <h1 className="card-text">{this.props.count ? this.props.count : "0"}</h1>
                                                    </div>
                                                    <div className="card-image">
                                                    <i><FontAwesomeIcon icon={faFolderOpen} /></i>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row><div className="form-row"> <Col lg={3} md={6} xs={12}>
                                                <div className="dashboard-stat dashboard-stat-info">
                                                    <div className="details">
                                                        <div className="desc-header">
                                                            <div className="title text-info">Empty values</div>
                                                        </div>
                                                        <div className="desc">
                                                            <span className="desc-value">{this.props.profileData.empty.value !== null ? this.props.profileData.empty.value :"NA"}</span>
                                                        </div>
                                                        <Image className="desc-img" src="icon_emptyData" />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={3} md={6} xs={12}>
                                            <div className="dashboard-stat dashboard-stat-success">
                                                    <div className="details">
                                                        <div className="desc-header">
                                                            <div className="title text-success">Unique values</div>
                                                        </div>
                                                        <div className="desc">
                                                            <span className="desc-value">{this.props.profileData.unique.value !== null ? this.props.profileData.unique.value :"NA"}</span>
                                                        </div>
                                                        <Image className="desc-img" src="icon_uniqueData" />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={3} md={6} xs={12}>
                                            <div className="dashboard-stat dashboard-stat-warning">
                                                    <div className="details">
                                                        <div className="desc-header">
                                                            <div className="title text-warning">Duplicate values</div>
                                                        </div>
                                                        <div className="desc">
                                                            <span className="desc-value">{this.props.profileData.duplicate.value !== null ? this.props.profileData.duplicate.value : "NA"}</span>
                                                        </div>
                                                        <Image className="desc-img" src="icon_duplicateData" />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={3} md={6} xs={12}>
                                            <div className="dashboard-stat dashboard-stat-danger">
                                                    <div className="details">
                                                        <div className="desc-header">
                                                            <div className="title text-danger">Error values</div>
                                                        </div>
                                                        <div className="desc">
                                                            <span className="desc-value">{this.props.outlier !== null ? this.props.outlier : 0}</span>
                                                        </div>
                                                        <Image className="desc-img" src="icon_errorData" />
                                                    </div>
                                                </div>
                                            </Col></div></>)
         }
         return(
               <div className="form-row">
                    {
                    this.state.showModal ?
                        <CardHelpContent
                            show={this.state.showModal}
                            onHide={this.hideModal}
                            name={this.state.cardName}
                            page={"data"}
                        /> : ""
                }
                    {cardData.length === 0 ?
                    <NoErrorComponent message={`Profile metrics not available for the selected ${this.props.sectionName}.`}/>
                    :
                    cardData.map((data) => {
                        let cardBackground = `dashboard-stat ${data.background}`;
                        let textColor = `title ${data.textColor}`;
                        let suffixText = "%";
                        if (data.metric_name === "Data Drift") {
                            suffixText = "";
                        } else if (data.metric_name === "Data Timeliness") {
                            suffixText = "days";
                        }
                        return (
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                                 key={data.idValue}
                            >
                                <div className={cardBackground}>
                                    <div className="details">
                                        <div className="desc-header">
                                            <div className={textColor}>
                                                {data.metric_name}
                                            </div>
                                            <RcTooltip placement="top" overlay={renderTooltip} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                <div className="help-icon"
                                                        onClick={this.handleClick.bind(this, data)} data-toggle="modal"
                                                        data-target="#model__help"><i><FontAwesomeIcon
                                                    icon={faQuestionCircle}/></i>
                                                </div>
                                            </RcTooltip>
                                        </div>
                                        <div className="desc">
                                            <span className="desc-value">{data.metric_value}</span>
                                            {data.metric_value !== "NA" ?
                                                <span className="desc-percentage">{suffixText}</span> : ""}
                                        </div>
                                        <ChartBody
                                            classValue="chart"
                                            id={data.idValue}
                                            customHeight={50}
                                            chartType="cardArea"
                                            style={{height: "50px"}}
                                            chart_data={data.chartData}
                                            colorType={data.textColor}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>);
      }

}

export default MetricsCards;