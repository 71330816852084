import React from 'react';
import $ from 'jquery';
import * as d3Module from 'd3';
import d3Tip from 'd3-tip';
import {event as currentEvent} from 'd3-selection';

const d3 = {
  ...d3Module,
  tip: d3Tip
};

class PipelineLineageErr extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          height:this.props.height
        }
        this.showGraph = this.showGraph.bind(this);
    }

    showGraph(){
        $(".lg-tooltip").hide(); // On very first line of scripts.js file


  var icon_job = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="icon_job" transform="translate(-8449 726)">
    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
  </g>
</svg>
`
  var icon_task = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <clipPath id="clip-path">
      <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)" fill="#fff"/>
    </clipPath>
  </defs>
  <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
    <path id="Path_8121" data-name="Path 8121" d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z" transform="translate(1 1)" fill="currentColor"/>
  </g>
</svg>
`

/*
  var icon_dataset = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="icon_datasouce" transform="translate(-8523 726)">
    <rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24" transform="translate(8523 -726)" fill="none"/>
    <path id="database-solid" d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z" transform="translate(8527 -723)" fill="currentColor"/>
  </g>
</svg>`
*/



  var icon_dataset_icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="icon_dataset" transform="translate(-8487 726)">
    <rect id="Rectangle_1920" data-name="Rectangle 1920" width="24" height="24" transform="translate(8487 -726)" fill="none"/>
    <path id="layer-group-solid" d="M.434,5.2,8.623,8.911a.9.9,0,0,0,.748,0L17.561,5.2a.785.785,0,0,0,0-1.407L9.372.074a.9.9,0,0,0-.748,0L.434,3.789A.786.786,0,0,0,.434,5.2ZM17.561,8.3l-2.042-.926L9.836,9.95a2.026,2.026,0,0,1-1.678,0L2.476,7.374.434,8.3a.785.785,0,0,0,0,1.406l8.189,3.712a.9.9,0,0,0,.748,0l8.19-3.712a.785.785,0,0,0,0-1.406Zm0,4.493-2.034-.922L9.836,14.45a2.026,2.026,0,0,1-1.678,0l-5.69-2.579-2.035.922a.785.785,0,0,0,0,1.406l8.189,3.712a.9.9,0,0,0,.748,0l8.19-3.712A.785.785,0,0,0,17.561,12.793Z" transform="translate(8490.003 -722.993)" fill="currentColor"/>
  </g>
</svg>`

  var icon_database = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="icon-database" transform="translate(-1786 -116)">
    <path id="database-solid_1_" data-name="database-solid (1)" d="M15.75,2.813V4.5c0,1.554-3.526,2.813-7.875,2.813S0,6.054,0,4.5V2.813C0,1.259,3.526,0,7.875,0S15.75,1.259,15.75,2.813ZM13.823,7.548A6.877,6.877,0,0,0,15.75,6.543v3.582c0,1.554-3.526,2.813-7.875,2.813S0,11.679,0,10.125V6.543A6.787,6.787,0,0,0,1.927,7.548a18.343,18.343,0,0,0,5.948.889A18.343,18.343,0,0,0,13.823,7.548ZM0,12.168a6.787,6.787,0,0,0,1.927,1.005,18.343,18.343,0,0,0,5.948.889,18.343,18.343,0,0,0,5.948-.889,6.877,6.877,0,0,0,1.927-1.005v3.02C15.75,16.741,12.224,18,7.875,18S0,16.741,0,15.188Z" transform="translate(1790 119)" fill="currentColor"/>
    <rect id="Rectangle_4" data-name="Rectangle 4" width="24" height="24" transform="translate(1786 116)" fill="none"/>
  </g>
</svg>`

 var icon_asterisk = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
 <g id="Group_1" data-name="Group 1" transform="translate(-973 -626)">
   <path id="Path_4" data-name="Path 4" d="M12.708,8.83,9.375,7l3.333-1.83a.562.562,0,0,0,.216-.774L12.468,3.6a.562.562,0,0,0-.779-.2L8.437,5.376l.081-3.8A.562.562,0,0,0,7.956,1H7.043a.562.562,0,0,0-.562.575l.081,3.8L3.311,3.4a.563.563,0,0,0-.779.2L2.075,4.4a.562.562,0,0,0,.216.774L5.625,7,2.292,8.83a.562.562,0,0,0-.216.774l.457.791a.562.562,0,0,0,.779.2L6.562,8.624l-.081,3.8A.563.563,0,0,0,7.043,13h.913a.563.563,0,0,0,.562-.575l-.081-3.8L11.689,10.6a.562.562,0,0,0,.779-.2l.457-.791A.562.562,0,0,0,12.708,8.83Z" transform="translate(973.667 627)" fill="currentColor"/>
   <rect id="Rectangle_4" data-name="Rectangle 4" width="16" height="16" transform="translate(973 626)" fill="none"/>
 </g>
</svg>`

var icon_external_link = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g id="icon_file_open" transform="translate(-254 -412)">
  <rect id="Rectangle_1" data-name="Rectangle 1" width="16" height="16" transform="translate(254 412)" fill="none"/>
  <path id="file_open_FILL0_wght400_GRAD0_opsz24" d="M161.178-868.221a1.135,1.135,0,0,1-.832-.346A1.134,1.134,0,0,1,160-869.4v-9.423a1.134,1.134,0,0,1,.346-.832,1.134,1.134,0,0,1,.832-.346h4.712l3.534,3.534v3.534h-1.178v-2.945H165.3v-2.945h-4.123v9.423h5.3v1.178Zm9.394.221-1.737-1.737v1.31h-1.178v-3.328h3.328v1.178h-1.325l1.737,1.737Zm-9.394-1.4v0Z" transform="translate(96.3 1294)" fill="currentColor"/>
</g>
</svg>`

var icon_feature_search = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g id="icon_feature_search" transform="translate(-312 -412)">
  <rect id="Rectangle_3" data-name="Rectangle 3" width="16" height="16" transform="translate(312 412)" fill="none"/>
  <path id="feature_search_FILL0_wght400_GRAD0_opsz24" d="M89.366-873.415l1.171,1.171v2.78a1.127,1.127,0,0,1-.344.827,1.127,1.127,0,0,1-.827.344h-8.2a1.127,1.127,0,0,1-.827-.344,1.127,1.127,0,0,1-.344-.827v-8.2a1.127,1.127,0,0,1,.344-.827,1.127,1.127,0,0,1,.827-.344h3.22a2.616,2.616,0,0,0-.176.563q-.059.3-.088.607H81.171v8.2h8.2Zm.761-2.546L92-874.088l-.82.82-1.873-1.873a3.646,3.646,0,0,1-.659.293,2.343,2.343,0,0,1-.746.117,2.54,2.54,0,0,1-1.866-.768,2.54,2.54,0,0,1-.768-1.866,2.54,2.54,0,0,1,.768-1.866A2.54,2.54,0,0,1,87.9-880a2.54,2.54,0,0,1,1.866.768,2.54,2.54,0,0,1,.768,1.866,2.345,2.345,0,0,1-.117.746A3.647,3.647,0,0,1,90.127-875.961ZM87.9-875.9a1.413,1.413,0,0,0,1.039-.424,1.413,1.413,0,0,0,.424-1.039,1.413,1.413,0,0,0-.424-1.039,1.413,1.413,0,0,0-1.039-.424,1.413,1.413,0,0,0-1.039.424,1.413,1.413,0,0,0-.424,1.039,1.413,1.413,0,0,0,.424,1.039A1.413,1.413,0,0,0,87.9-875.9Zm-6.732,2.488v0Z" transform="translate(234 1294.133)" fill="currentColor"/>
</g>
</svg>`

var icon_troubleshoot = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g id="icon_troubleshoot" transform="translate(-283 -412)">
  <rect id="Rectangle_2" data-name="Rectangle 2" width="16" height="16" transform="translate(283 412)" fill="none"/>
  <path id="troubleshoot_FILL1_wght400_GRAD0_opsz24" d="M91.16-868.6l-2.82-2.82a4.933,4.933,0,0,1-1.357.75,4.594,4.594,0,0,1-1.582.27,4.6,4.6,0,0,1-2.437-.66,4.893,4.893,0,0,1-1.717-1.74h1.47a3.821,3.821,0,0,0,1.192.877,3.448,3.448,0,0,0,1.493.323,3.472,3.472,0,0,0,2.55-1.05A3.472,3.472,0,0,0,89-875.2a3.471,3.471,0,0,0-1.05-2.55,3.471,3.471,0,0,0-2.55-1.05,3.465,3.465,0,0,0-2.437.953,3.507,3.507,0,0,0-1.147,2.348h-1.2a4.658,4.658,0,0,1,1.493-3.2A4.624,4.624,0,0,1,85.4-880a4.633,4.633,0,0,1,3.4,1.395A4.633,4.633,0,0,1,90.2-875.2a4.6,4.6,0,0,1-.27,1.583,4.931,4.931,0,0,1-.75,1.358L92-869.44Zm-6.4-4.2-.945-3.12-.78,2.22H80v-.9h2.4l.99-2.85h.9l.915,3.06.645-2.01h.9l.9,1.8h.45v.9h-1l-.7-1.41-.75,2.31Z" transform="translate(205 1294.333)" fill="currentColor"/>
</g>
</svg>`


var zoom;
//$("#outputContainerFlowchartErr").html("");
var width = $("#outputContainerFlowchartErr").width();
//var height = $("#outputContainerFlowchartErr").height();
var height = this.state.height;

if(height===undefined){ return 1}
//alert(height)
$("#outputContainerFlowchartErr").html("<svg height='"+height+"' width='"+width+"' id='graphWrapper'></svg>");

//The SVG Container
var svgContainer = d3.select("#graphWrapper").attr("cursor", "grabbing");

var g = svgContainer.append("g")
      .attr("width", width).attr("height", height)
      .attr("class", "everything");


      
/*

rect1 = g.append("rect")
                            .attr("x", 20)
                            .attr("y", 10)
                            .attr("width", 150)
                            .attr("height", 30);

rect2 = g.append("rect")
                            .attr("x", 300)
                            .attr("y", 300)
                            .attr("width", 300)
                            .attr("height", 300);

x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;

x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;

 midx  = ((x2 - x1) / 2) + x1

*/

g.append("defs").append("marker")
                .attr("id", "arrow")
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 5)
                .attr("orient", "auto")
                .append("svg:path")
                        .attr("d", "M0,-5L10,0L0,5")
                        .style("fill","#CED4DA");

              // var arrow = g.append("defs").append("marker")
              //       .attr("id", "arrow")
              //       .attr("viewBox", "0 0 12 12")
              //       .attr("refX", 6)
              //       .attr("refY", 6)
              //       .attr("markerWidth", 12)
              //       .attr("markerUnits", "strokeWidth")
              //       .attr("markerHeight", 12)
              //       .attr("orient", "auto")
              //       .append("svg:path")
              //       .attr("d", "M2,2 L10,6 L2,10 L6,6 L2,2")
              //       .style("fill","#808080");

                    /*

//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
                 

                 

lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  


//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 1)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)");  
                            //.style("marker-mid", "url(#arrow)");



                            */
    var graph;
   if(this.props.type === "15"){
    graph = {
      "nodes": [
          {
              "id": "Pipeline1",
              "type": 1,
              "group": "Pipeline1",
              "task": [],
              "Dataset": []
          },
          {
              "id": "Dataset1",
              "type": 2,
              "group": "dummyPrdVip",
              "task": [
                  {
                      "id": "taskname",
                      "name": "taskname1"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname2"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname4"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname5"
                  }
              ],
              "Dataset": [
                  {
                      "id": "Dataset",
                      "name": "Dataset1"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset2"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset3"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset4"
                  }
              ]
          },
          {
              "id": "Dataset2",
              "type": 2,
              "group": "dummyPrdLtmp",
              "task": [
                  {
                      "id": "taskname",
                      "name": "taskname1"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname2"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname4"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname5"
                  }
              ],
              "Dataset": [
                  {
                      "id": "Dataset",
                      "name": "Dataset1"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset2"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset3"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset4"
                  }
              ]
          },
          {
              "id": "ExternalAPIUser",
              "type": 1,
              "group": "ExternalAPIUser",
              "task": [],
              "Dataset": []
          },
          {
              "id": "ExternalAPIUser3",
              "type": 1,
              "group": "ExternalAPIUser3",
              "task": [],
              "Dataset": []
          },
          {
              "id": "Dataset3",
              "type": 2,
              "group": "dummyPrdPool",
              "task": [
                  {
                      "id": "taskname",
                      "name": "taskname1"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname2"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname4"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname5"
                  }
              ],
              "Dataset": [
                  {
                      "id": "Dataset",
                      "name": "Dataset1"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset2"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset3"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset4"
                  }
              ]
          },
          {
              "id": "Dataset4",
              "type": 2,
              "group": "dummyPrdPool1",
              "task": [
                  {
                      "id": "taskname",
                      "name": "taskname1"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname2"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname4"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname5"
                  }
              ],
              "Dataset": [
                  {
                      "id": "Dataset",
                      "name": "Dataset1"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset2"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset3"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset4"
                  }
              ]
          },
          {
              "id": "Dataset5",
              "type": 2,
              "group": "dummy2PrdVip",
              "task": [
                  {
                      "id": "taskname",
                      "name": "taskname1"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname2"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname4"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname5"
                  }
              ],
              "Dataset": [
                  {
                      "id": "Dataset",
                      "name": "Dataset1"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset2"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset3"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset4"
                  }
              ]
          },
          {
              "id": "External API",
              "type": 1,
              "group": "external",
              "task": [],
              "Dataset": []
          },
          {
              "id": "Dataset6",
              "type": 2,
              "group": "Dataset6",
              "task": [
                  {
                      "id": "taskname",
                      "name": "taskname1"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname2"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname4"
                  },
                  {
                      "id": "taskname",
                      "name": "taskname5"
                  }
              ],
              "Dataset": [
                  {
                      "id": "Dataset",
                      "name": "Dataset1"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset2"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset3"
                  },
                  {
                      "id": "taskname",
                      "name": "Dataset4"
                  }
              ]
          }
      ],
      "links": [
          {
              "source": "Pipeline1",
              "target": "Dataset1",
              "value": 1
          },
          {
              "source": "Dataset1",
              "target": "Dataset2",
              "value": 1
          },
          {
              "source": "Dataset3",
              "target": "Dataset5",
              "value": 1
          },
          {
              "source": "Dataset2",
              "target": "Dataset3",
              "value": 1
          },
          {
              "source": "Dataset3",
              "target": "Dataset4",
              "value": 1
          },
          {
              "source": "Dataset4",
              "target": "JPipeline1ob5",
              "value": 1
          }
      ]
  }
   } else {
    graph = {
    "nodes": [{

      "id": "Pipeline1",
      "type":1,
      "group": "Pipeline1",
      "task": [],
      "Dataset": []
    },
    {
      "id": "Job1",
      "type":2,
      "group": "dummyPrdVip",
      "task": [{
        "id": "taskname",
        "name": "taskname1"
      },
      {
        "id": "taskname",
        "name": "taskname2"
      },
      {
        "id": "taskname",
        "name": "taskname4"
      },
      {
        "id": "taskname",
        "name": "taskname5"
      }
      ],
      "Dataset": [{
        "id": "Dataset",
        "name": "Dataset1"
      },
      {
        "id": "taskname",
        "name": "Dataset2"
      },
      {
        "id": "taskname",
        "name": "Dataset3"
      },
      {
        "id": "taskname",
        "name": "Dataset4"
      }
      ]
    },
    {
      "id": "Job2",
      "type":2,
      "group": "dummyPrdLtmp",
      "task": [{
        "id": "taskname",
        "name": "taskname1"
      },
      {
        "id": "taskname",
        "name": "taskname2"
      },
      {
        "id": "taskname",
        "name": "taskname4"
      },
      {
        "id": "taskname",
        "name": "taskname5"
      }
      ],
      "Dataset": [{
        "id": "Dataset",
        "name": "Dataset1"
      },
      {
        "id": "taskname",
        "name": "Dataset2"
      },
      {
        "id": "taskname",
        "name": "Dataset3"
      },
      {
        "id": "taskname",
        "name": "Dataset4"
      }
      ]

    },

    {

"id": "ExternalAPIUser",
"type":1,
"group": "ExternalAPIUser",
"task": [],
"Dataset": []
},

{

"id": "ExternalAPIUser3",
"type":1,
"group": "ExternalAPIUser3",
"task": [],
"Dataset": []
},
    {
      "id": "Job3",
      "type":2,
      "group": "dummyPrdPool",
      "task": [{
        "id": "taskname",
        "name": "taskname1"
      },
      {
        "id": "taskname",
        "name": "taskname2"
      },
      {
        "id": "taskname",
        "name": "taskname4"
      },
      {
        "id": "taskname",
        "name": "taskname5"
      }
      ],
      "Dataset": [{
        "id": "Dataset",
        "name": "Dataset1"
      },
      {
        "id": "taskname",
        "name": "Dataset2"
      },
      {
        "id": "taskname",
        "name": "Dataset3"
      },
      {
        "id": "taskname",
        "name": "Dataset4"
      }
      ]

    },
    {
      "id": "Job4",
      "type":2,
      "group": "dummyPrdPool1",
      "task": [{
        "id": "taskname",
        "name": "taskname1"
      },
      {
        "id": "taskname",
        "name": "taskname2"
      },
      {
        "id": "taskname",
        "name": "taskname4"
      },
      {
        "id": "taskname",
        "name": "taskname5"
      }
      ],
      "Dataset": [{
        "id": "Dataset",
        "name": "Dataset1"
      },
      {
        "id": "taskname",
        "name": "Dataset2"
      },
      {
        "id": "taskname",
        "name": "Dataset3"
      },
      {
        "id": "taskname",
        "name": "Dataset4"
      }
      ]

    },
    {
      "id": "Job5",
      "type":2,
      "group": "dummy2PrdVip",
      "task": [{
        "id": "taskname",
        "name": "taskname1"
      },
      {
        "id": "taskname",
        "name": "taskname2"
      },
      {
        "id": "taskname",
        "name": "taskname4"
      },
      {
        "id": "taskname",
        "name": "taskname5"
      }
      ],
      "Dataset": [{
        "id": "Dataset",
        "name": "Dataset1"
      },
      {
        "id": "taskname",
        "name": "Dataset2"
      },
      {
        "id": "taskname",
        "name": "Dataset3"
      },
      {
        "id": "taskname",
        "name": "Dataset4"
      }
      ]

    },
    {
"id": "External API",
"type":1,
"group": "external",
"task": [],
"Dataset": []
},
{
      "id": "Job6",
      "type":2,
      "group": "Job6",
      "task": [{
        "id": "taskname",
        "name": "taskname1"
      },
      {
        "id": "taskname",
        "name": "taskname2"
      },
      {
        "id": "taskname",
        "name": "taskname4"
      },
      {
        "id": "taskname",
        "name": "taskname5"
      }
      ],
      "Dataset": [{
        "id": "Dataset",
        "name": "Dataset1"
      },
      {
        "id": "taskname",
        "name": "Dataset2"
      },
      {
        "id": "taskname",
        "name": "Dataset3"
      },
      {
        "id": "taskname",
        "name": "Dataset4"
      }
      ]

    }
    ],
    "links": [{
      "source": "Pipeline1",
      "target": "Job1",
      "value": 1
    },
    {
      "source": "Job1",
      "target": "Job2",
      "value": 1
    }
      ,
    {
      "source": "Job3",
      "target": "Job5",
      "value": 1
    }
      ,
    {
      "source": "Job2",
      "target": "Job3",
      "value": 1
    }
      ,
    {
      "source": "Job3",
      "target": "Job4",
      "value": 1
    }
      ,
    {
      "source": "Job4",
      "target": "JPipeline1ob5",
      "value": 1
    }
    ]
  }
}

  let rectWidth = 280;
    let rectHeight = 420;
    var colors = {
    dummyprodVip: "#1eb543",
    dummyaccVip: "#30cc30",
    dummyprodLtmp: "#3be264",
    dummyaccLtmp: "#74e874",
    dummyprodPool: "#82ffa0",
    dummyaccPool: "#bcffbc",
    dummy2prodBVip: "#ff8438",
    dummy2accAVip: "#becc6a",
    dummy2prodBLtmp: "#ffac39",
    dummy2accALtmp: "#d9e590",
    dummy2prodBPool: "#ffdb3a",
    dummy2accAPool: "#f6ffc1",
  }

     g.selectAll("foreignObject")
      g.append("xhtml:div")
       .attr("id","tooltipHeader")
       .html(function (d) {
        return `<div>Testing</div>`;
        /*return `<span  class="lg-tooltip">
    <div class="lg-tooltip__header">
      <p><label>Pipeline:</label>Job2</p>
      <p><label>Run Status:</label>
        <i class="status-icon">
          <svg id="icon_close" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
            <path id="Path_8127" data-name="Path 8127" d="M0,0H24V24H0Z" fill="none" />
            <path id="Path_8128" data-name="Path 8128"
              d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"
              fill="#ff4934" />
          </svg>
        </i>
        Failed
      </p>
      <p><label>Start time:</label>Apr 26, 2023 02:01:05 PM</p>
    </div>
  </span>`; */
      })
//g.append("foreignObject").attr("x",100).attr("y",100).attr("width","100").attr("height",100).append("xhtml:div").html(function (d, i) { "<div>Testing</div>" })


g.selectAll("foreignObject")
      .data(graph.nodes)
      .enter()
      .append("foreignObject")
      .attr("x", function (d,i) {
        if(i===0 || i===1){
          return  rectWidth+(i*350);
        }
        else if(i===2){
          return  rectWidth+(2*350);
        }
        else if(i===3){
          return  rectWidth+(2*350);
        }
        else if(i===4){
          return  rectWidth+(2*350);
        }
        else if(i===5){
          return  rectWidth+(2*350);
        }
        else if(i===6){
          return  rectWidth+(2*350);
        }
        else if(i===7){
          return  rectWidth+(3*350);
        }
        else if(i===8){
          return  rectWidth+(4*350);
        }
        else if(i===9){
          return  rectWidth+(3*350);
        }
        else {
          return  rectWidth+(i*350);
        }
      })
      .attr("y", function (d,i) {
        if(d.type===1){
          if(i===3){
            return rectHeight+105;
          }
          if(i===4){
            return rectHeight+205;
          }
          else{
            return rectHeight+172;
          }
        }else {

          if(i===2){
            return rectHeight-rectHeight;
          } 
          else if(i===5){
            return 2*rectHeight;
          }
          else if(i===6){
            return 3*rectHeight;
          }
          else if(i===7){
            return rectHeight-rectHeight;
          }
          else if(i===8){
            return 13*rectHeight;
          }

          else {

          if(i%2){
            return rectHeight;
          }else {
            return rectHeight;
          }

        }

        }
      })
      .attr("width", rectWidth)
      .attr("height", function (d) {

        if(d.type===1){
          return 50
        }else {
          return rectHeight;
        }

      })
      .attr("class", function (d) {
        return ("graphNode " + d.group)
      })
      .style("background-color", function (d) {
        return colors[d.group];
      })
      .append("xhtml:div")
     // .classed("lg-panel", true)
    // .classed("lg-panel", true)
       .attr('class', function (d) {
        if(d.type===2){
          return 'lg-panel';
        }else {
          return '';
        }
      })
      .html(function (d, i) {
        //console.log(d.id);

        if(d.type===2){
          // eslint-disable-next-line
        let nodeHtml = "";
        let strippedId = d.id.replace(/\/.*\//, "")
        if (d.virtual !== undefined) {
          nodeHtml = "<div><div>Task</div>" + d.partition + "</div>"
        } else if (d.policy !== undefined) {
          nodeHtml = "<div><div>Task</div>" + d.partition + "</div>"

        } else if (d.pool !== undefined) {
          nodeHtml = "<div><div>Task</div>" + d.partition + "</div>"
        }

        let task = '<div class="lg-list__heading">Task</div>';
        for (let looptask = 0; looptask < d.task.length; looptask++) {
          // eslint-disable-next-line
          task += '<div class="lg-list__item tltip highlights ' + d.task[looptask]["name"] + '"><a href="#" data-type="task" id="' + d.id + '_task' + looptask + '" data-classname = "'+d.task[looptask]["name"]+'" class="highlights jobstooltiptrigger ' + d.task[looptask]["name"] + '"><div class="lg-list__label tooltipTask">' + icon_task +'<span>'+ d.task[looptask]["name"] + '</span>' + '</div>' + '<div class="lg-list__actions"><div class="lg-list__actions-left"><i>' + icon_troubleshoot + '</i><i>' + icon_feature_search + '</i></div><div class="lg-list__actions-right"><i>' + icon_external_link + '</i></div>' + '</div></a></div>';
        }

        let dataset = '<div class="lg-list__heading">Dataset</div>';
        for (let looptask = 0; looptask < d.Dataset.length; looptask++) {
          dataset += '<div class="lg-list__item tltip highlights ' + d.Dataset[looptask]["name"] + '"><a href="#" data-type="dataset" id="' + d.id + '_dataset' + looptask + '" data-classname = "'+d.Dataset[looptask]["name"]+'"  class="highlights jobstooltiptrigger ' + d.Dataset[looptask]["name"] + '"><div class="lg-list__label tooltipDataset">' + icon_dataset_icon + '&nbsp;' + d.Dataset[looptask]["name"] + '</div></a></div>';
        }
        nodeHtml = "<div><div>Task</div>" + d.partition + "</div>"
        if (d.task.length > 0 && d.Dataset.length > 0) {
          // eslint-disable-next-line
          return "<div class='lg-panel-header tltip tooltipHeading'><a  data-type='tooltip' id='" + d.id + '_jobs' + i + "' href='#' class='highlights jobstooltiptrigger'><span class='lg-panel-title'>" + icon_job + strippedId + "</span>" + "</a></div><div class='lg-panel-body'>" + '<div class="lg-list">' + task + '</div><div class="lg-list">' + dataset + '</div></div>';
        } else {
          return "<div class='lg-panel lg-panel--source'><label>" + icon_database + '&nbsp;' + strippedId + '</label></div>';

        }
      } 
      else{
          let strippedId = d.id.replace(/\/.*\//, "")
          return "<div class='lg-panel lg-panel--source'><label>" + icon_asterisk + strippedId + '</label></div>';
      }
      })
    //   .on('mouseover', function(d) {

    //     return;

    //     let pos = d3.select(this).node().getBBox().width;
    //             console.log(pos);

    //     $(document).mousemove(function(e) {
    //  //    console.log(e.pageX);
    //      //console.log(e.pageY);
    //     });

        
    //     return;





        
    //     //.getBoundingClientRect()
    //     return;

    //     var fo = g.append('foreignObject')
    //                     .attr({
    //                         'x':200,
    //                         'y': 200,
    //                         'width': 200,
    //                         'class': 'svg-tooltip'
    //                     });
    //                 var div = fo.append('xhtml:div')
    //                     .append('div')
    //                     .attr({
    //                         'class': 'tooltip'
    //                     });
    //                 div.append('p')
    //                     .attr('class', 'lead')
    //                     .html('Holmes was certainly not a difficult man to live with.');
    //                 div.append('p')
    //                     .html('He was quiet in his ways, and his habits were regular. It was rare for him to be up after ten at night, and he had invariably breakfasted and gone out before I rose in the morning.');

          
    //   })


      
     g.append("foreignObject")
     .attr("id", "jobstooltip")
    // .attr("width", 480)
    // .attr("height", 500)
    .append("xhtml:div")
    .style("font", "14px 'Helvetica Neue'")
    .html(`<span  class="lg-tooltip">
    <div class="lg-tooltip__header">
      <p><label>Pipeline:</label>Job2</p>
      <p><label>Run Status:</label>
        <i class="status-icon">
          <svg id="icon_close" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
            <path id="Path_8127" data-name="Path 8127" d="M0,0H24V24H0Z" fill="none" />
            <path id="Path_8128" data-name="Path 8128"
              d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"
              fill="#ff4934" />
          </svg>
        </i>
        Failed
      </p>
      <p><label>Start time:</label>Apr 26, 2023 02:01:05 PM</p>
    </div>
  </span>`);


  g.append("foreignObject")
     .attr("id", "tasktooltip")
    // .attr("width", 480)
    // .attr("height", 500)
    .append("xhtml:div")
    .style("font", "14px 'Helvetica Neue'")
    .html(`  <div id="tooltipTask" class="lg-tooltip">
    <div class="lg-tooltip__header">
      <p><label>Task:</label>taskname1</p>
      <p><label>Start time:</label>Apr 26, 2023 02:01:05 PM</p>
    </div>
    <div class="lg-tooltip__body">
      <div class="lg-tooltip__section">
        <dl>
          <dt>Read from</dt>
          <dd>
            <i>
              <svg xmlns="httdl://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                <g id="icon_datasouce" transform="translate(-8523 726)">
                  <rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24"
                    transform="translate(8523 -726)" fill="none" />
                  <path id="database-solid"
                    d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z"
                    transform="translate(8527 -723)" fill="#898989" />
                </g>
              </svg>
            </i>
            SAP - Daily Global Sales Extract
          </dd>
        </dl>
        <dl>
          <dt>Write to</dt>
          <dd>
            <i>
              <svg xmlns="httdl://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                <g id="icon_datasouce" transform="translate(-8523 726)">
                  <rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24"
                    transform="translate(8523 -726)" fill="none" />
                  <path id="database-solid"
                    d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z"
                    transform="translate(8527 -723)" fill="#898989" />
                </g>
              </svg>
            </i>
            S3 - Raw Daily Global Sales Extract
          </dd>
        </dl>
      </div>
    </div>
  </div>`);


  g.append("foreignObject")
     .attr("id", "datsettooltip")
    // .attr("width", 480)
    // .attr("height", 500)
    .append("xhtml:div")
    .style("font", "14px 'Helvetica Neue'")
    .html(` <span id="tooltipDataset" class="lg-tooltip">
    <div class="lg-tooltip__header">
      <p><label>Dataset:</label>Dataset1</p>
    </div>
    <div class="lg-tooltip__body">
      <div class="lg-tooltip__section">
        <dl>
          <dt>Read tasks</dt>
          <dd>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
                viewBox="0 0 24 24">
                <defs>
                  <clipPath id="clip-path">
                    <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                      fill="#fff" />
                  </clipPath>
                </defs>
                <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
                  <path id="Path_8121" data-name="Path 8121"
                    d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                    transform="translate(1 1)" fill="#898989" />
                </g>
              </svg>
            </i>
            load_prior_day_na_sales_to_databricks_bronze
          </dd>
        </dl>
        <dl>
          <dt>Written Tasks</dt>
          <dd>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
                viewBox="0 0 24 24">
                <defs>
                  <clipPath id="clip-path">
                    <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                      fill="#fff" />
                  </clipPath>
                </defs>
                <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
                  <path id="Path_8121" data-name="Path 8121"
                    d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                    transform="translate(1 1)" fill="#898989" />
                </g>
              </svg>
            </i>
            extract_regional_sales_to_s3
          </dd>
        </dl>
      </div>
      <div class="lg-tooltip__section">
        <dl>
          <dt>1 Missing Operation</dt>
          <dd>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
                viewBox="0 0 24 24">
                <defs>
                  <clipPath id="clip-path">
                    <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                      fill="#fff" />
                  </clipPath>
                </defs>
                <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
                  <path id="Path_8121" data-name="Path 8121"
                    d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                    transform="translate(1 1)" fill="#898989" />
                </g>
              </svg>
            </i>
            Data wasn't written by task <strong>extract_regional_sales_to_s3</strong>
          </dd>
        </dl>
      </div>
    </div>
  </span>`);


var rect1 = g.selectAll(".Pipeline1")
var rect2 = g.selectAll(".dummyPrdVip")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx  = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
var lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)");  



rect1 = g.selectAll(".dummyPrdVip")
rect2 = g.selectAll(".dummyPrdLtmp")
x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
 midx  = ((x2 - x1) / 2) + x1
//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)");  


rect1 = g.selectAll(".dummyPrdVip")
rect2 = g.selectAll(".dummyPrdPool")
x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
 midx  = ((x2 - x1) / 2) + x1
//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)");  


rect1 = g.selectAll(".dummyPrdVip")
rect2 = g.selectAll(".dummyPrdPool1")
x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
 midx  = ((x2 - x1) / 2) + x1
//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)"); 




rect1 = g.selectAll(".dummyPrdLtmp")
rect2 = g.selectAll(".dummy2PrdVip")
x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
 midx  = ((x2 - x1) / 2) + x1
//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)"); 


                            
rect1 = g.selectAll(".ExternalAPIUser")
rect2 = g.selectAll(".dummy2PrdVip")
x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
 midx  = ((x2 - x1) / 2) + x1
//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)");
                            
rect1 = g.selectAll(".ExternalAPIUser3")
rect2 = g.selectAll(".dummy2PrdVip")
x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
 midx  = ((x2 - x1) / 2) + x1
//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)");                            



rect1 = g.selectAll(".dummy2PrdVip")
rect2 = g.selectAll(".external")
x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
 midx  = ((x2 - x1) / 2) + x1
//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)");
                            
                            
rect1 = g.selectAll(".dummyPrdLtmp")
rect2 = g.selectAll(".Job6")
x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
 midx  = ((x2 - x1) / 2) + x1
//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)");


rect1 = g.selectAll(".dummyPrdLtmp")
rect2 = g.selectAll(".Job6")
x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
 midx  = ((x2 - x1) / 2) + x1
//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)");
                        

                            
rect1 = g.selectAll(".dummyPrdPool")
rect2 = g.selectAll(".Job6")
x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
 midx  = ((x2 - x1) / 2) + x1
//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)");


rect1 = g.selectAll(".dummyPrdPool1")
rect2 = g.selectAll(".Job6")
x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
y1 =parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
x2 = parseInt(rect2.attr("x"));
y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
 midx  = ((x2 - x1) / 2) + x1
//The data for our line
lineData = [{ "x": x1,   "y": y1},  
                 { "x": midx,  "y": y1}, 
                 { "x": midx,  "y": y2}, 
                 { "x": x2-10,  "y": y2}];
lineFunction =  d3.line()
  .x(function(d) { return d.x; })
  .y(function(d) { return d.y; })
  .curve(d3.curveBasis);  
//The line SVG Path we draw
 g.append("path")
                            .attr("d", lineFunction(lineData))
                            .attr("stroke", "#CED4DA")
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end","url(#arrow)");

$(document).on('click', 'a.highlights', function () {
   $(".lineage-graph-sidebar").addClass("show")
  //  $(".highlights").removeClass("related-active").removeClass("active");
  //  $("."+$(this).attr("data-classname")).addClass("related-active") 
  //  $(this).find('div').removeClass("related-active").addClass("active");

  });


$(document).on('mouseout', 'a.jobstooltiptrigger', function () {
   // $("#tooltipHeader,#tooltipTask,#tooltipDataset,#jobstooltip,#tasktooltip").hide();
   $("#jobstooltip,#tasktooltip,#datsettooltip").hide();
});


  $(document).on('mouseover', 'a.jobstooltiptrigger', function (event) {
    var elems = this;

    var tooltip = $("#" + elems.id).attr("data-type"); // will return the string "123"
    // var lefttooltip = $("#" + elems.id).offset().left;
    // var toptooltip = $("#" + elems.id).offset().top;


    var newposx = parseInt($("#" + elems.id).closest(".graphNode").attr("x"))+parseInt($("#" + elems.id).closest(".graphNode").attr("width"));
    var newposy = parseInt($("#" + elems.id).closest(".graphNode").attr("y"))
    // alert($(".lg-panel").width());

    //console.log($("#" + elems.id).closest(".graphNode").attr("x"));

    if(tooltip === "tooltip") {

      $("#tooltipHeader").show();
      $("#jobstooltip").show();

      
      $("#jobstooltip").attr("y", newposy-47);
      $("#jobstooltip").attr("x", newposx);
      $("#jobstooltip").attr("width", "200");
      $("#jobstooltip").attr("height", "200");

      // $("#tooltipHeader").css("top", toptooltip + "px");
      // $("#tooltipHeader").css("left", (lefttooltip + $(".lg-panel").width()) + "px");
    }
    else if(tooltip === "task") {
      $("#tasktooltip").show();
      $("#tasktooltip").attr("y", newposy);
      $("#tasktooltip").attr("x", newposx);
      $("#tasktooltip").attr("width", "300");
      $("#tasktooltip").attr("height", "300");
      // $("#tooltipTask").css("top", toptooltip + "px");
      // $("#tooltipTask").css("left", (lefttooltip + $(".lg-panel").width()) + "px");
    }
    else if(tooltip === "dataset") {

      

      $("#datsettooltip").show();
      $("#datsettooltip").attr("y", newposy+160);
      $("#datsettooltip").attr("x", newposx);
      $("#datsettooltip").attr("width", "300");
      $("#datsettooltip").attr("height", "300");


      // $("#tooltipDataset").show();
      // $("#tooltipDataset").css("top", toptooltip + "px");
      // $("#tooltipDataset").css("left", (lefttooltip + $(".lg-panel").width()) + "px");
    }
    
  });


  $(document).on('click', 'a.jobstooltiptrigger', function () {
    var elems = this;
    var tooltip = $("#" + elems.id).attr("data-type"); // will return the string "123"
    var lefttooltip = $("#" + elems.id).offset().left;
    var toptooltip = $("#" + elems.id).offset().top;
    if(tooltip === "tooltip") {
      $("#tooltipHeader").show();
      $("#tooltipHeader").attr("y", toptooltip);
      $("#tooltipHeader").attr("x", (lefttooltip + $(".lg-panel").width()));
      $("#tooltipHeader").attr("width", "200");
      $("#tooltipHeader").attr("height", "200");


      // $("#tooltipHeader").css("top", toptooltip + "px");
      // $("#tooltipHeader").css("left", (lefttooltip + $(".lg-panel").width()) + "px");
    }
    else if(tooltip === "task") {
      $("#tooltipTask").show();
      $("#tooltipTask").css("top", toptooltip + "px");
      $("#tooltipTask").css("left", (lefttooltip + $(".lg-panel").width()) + "px");
    }
    else if(tooltip === "dataset") {
      $("#tooltipDataset").show();
      $("#tooltipDataset").css("top", toptooltip + "px");
      $("#tooltipDataset").css("left", (lefttooltip + $(".lg-panel").width()) + "px");
    }
    
  });



//add zoom capabilities
var svg = d3.select("#graphWrapper");

    zoom = d3.zoom()
      .on("zoom", zoom_actions);
    zoom(svg);


    //Zoom functions
function zoom_actions() {
      g.attr("transform", currentEvent.transform)
}


// function zoomIn() {

// console.log(zoom);

// d3.select('svg')
//   .transition()
//   .call(zoom.scaleBy, 2);
// }

// function zoomOut() {

// d3.select('svg')
//   .transition()
//   .call(zoom.scaleBy, 0.5);
// }

// function resetZoom() {
// d3.select('svg')
//   .transition()
//   .call(zoom.scaleTo, 1);
// }

// function center() {
// d3.select('svg')
//   .transition()
//   .call(zoom.translateTo, 0.5 * width, 0.5 * height);
// }

// function panLeft() {
// d3.select('svg')
//   .transition()
//   .call(zoom.translateBy, -50, 0);
// }

// function panRight() {
// d3.select('svg')
//   .transition()
//   .call(zoom.translateBy, 50, 0);
// }

// function resetSelection() {
//   $(".highlights").removeClass("related-active");
//   $(".highlights").removeClass("active");
// }

    }

    componentDidMount() {
        setTimeout(
            function() {
            //  $("#outputContainerFlowchartErr").html("");
             // alert(this.props.height)
                this.showGraph();
                setTimeout(
                  function() {
                      // this.showGraph();
                  }
                  // eslint-disable-next-line
                  .bind(this),
                  100
              );
            }
            .bind(this),
            500
        );
    }

    render() {
        return (<div id="outputContainerFlowchartErr" class="lineage-graph-container">Loading please wait...</div>)
    }
}
export default PipelineLineageErr;