import React from 'react';
import Image from '../components/image';
import Banner from '../components/banner';
import Login from './components/login';
import ErrorHandler from '../components/error_500';
import { getFeatureAccess } from "../utils/common_utils";
import { IS_SAAS_EDITION } from "../utils/constant"
class Home extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.setPasswordPage = this.setPasswordPage.bind(this);
        this.setLoginPage = this.setLoginPage.bind(this);
        this.state = {
            isLoginPage: true,
            errorOccurred: false,
            featureAccess: getFeatureAccess(),
        };
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
    }

    setPasswordPage() {
        this.setState({ isLoginPage: false });
    }
    setLoginPage() {
        this.setState({ isLoginPage: true });
    }

    render() {
        // let LOGIN_ENVIRONMENT = process.env.REACT_APP_CUSTOM_NODE_ENV_LOGIN;
        // commenting for common footer className={"user-prelogin" + (LOGIN_ENVIRONMENT ? ' has-sticky-footer': '')}
        return (
            this.state.errorOccurred ?
                <ErrorHandler></ErrorHandler>
                :
                <div className={"user-prelogin"}>
                    <div className="row gutter-reset">
                        <div className="col">

                            <div className="prelogin-container">
                                {
                                    IS_SAAS_EDITION?
                                        <Image
                                            className="login-logo"
                                            src='logo'
                                        />
                                    :
                                        <Image
                                            className="login-logo"
                                            src='logoDrx'
                                        />
                                }                                
                                <Login router={this.props.router} isFirstTimeLogin={this.props.route.isFirstTimeLogin}/> 
                            </div>
                        </div>
                        <Banner />
                    </div>
                </div>
        );
    }
}

export default Home;