import React from 'react';
import { faLayerGroup, faTag, faCalendar, faDatabase } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import $ from 'jquery';
import {
    DISPLAY_DATE_FORMAT,
    TAB_KEY_MAPPING,
    LIST_VIEW_OPTIONS
} from '../../utils/constant';
import Select, {
    components,
} from 'react-select';
import CustomTreeSelect from '../../components/customTreeSelect';

import {
    handleSubmit
} from "../components/filter_common_utils";

import {
    normalizeAttributeName,
} from "../../utils/attribute_name_utils";

const customSelectDatasource = ({ children, ...props }) => (
    <components.Control {...props} className="has-icon">
        <FontAwesomeIcon
            icon={faDatabase}
            className="form-control__icon"
        />
        {children}
    </components.Control>

);
class CustomTreeFilter extends React.Component {
    constructor(props) {
        super(props);
        this.handleDatasourceoptions = this.handleDatasourceoptions.bind(this)
        this.handleDatasetsoptions = this.handleDatasetsoptions.bind(this)
        //this.handleAttributeoptions = this.handleAttributeoptions.bind(this)
        this.handleSelectedAttributes = this.handleSelectedAttributes.bind(this)
        this.handleSelectDate = this.handleSelectDate.bind(this);
        this.handleClearAll = this.handleClearAll.bind(this);
        this.loadFilter = this.loadFilter.bind(this)
        this.handleExpand = this.handleExpand.bind(this);
        this.handleExpandAttributes = this.handleExpandAttributes.bind(this);
        this.handleAttributesSelection = this.handleAttributesSelection.bind(this);
        this.handleSubmit = handleSubmit.bind(this);
        this.handleSelectedMetrics = this.handleSelectedMetrics.bind(this);
        
        // let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        // let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });

        let startDate = moment(this.props.startDate);
        let endDate = moment(this.props.endDate);

        this.state = {
            filterData: this.props.data,
            filterDataInitial: this.props.data,
            dateDisplay: startDate.format(DISPLAY_DATE_FORMAT) + ' - ' + endDate.format(DISPLAY_DATE_FORMAT),
            startDate: startDate,
            endDate: endDate,
            attributeOptions: [],
            datasetOptions: [],
            renderDatasetDD: false,
            seletedDatasource: this.props.seletedDatasourceId,
            seletedDatasourceLabel: this.props.seletedDatasourceName,
            filteredDatas: {},
            completeTableData:this.props.completeTableData,
            fullscreenview:this.props.fullscreenview,
            metricsSelectedOptions:this.props.metrics_data,
        }
    }
    handleClearAll() {
        this.props.data["parentDatasets"] = JSON.parse(JSON.stringify(this.props.data["parentDatasets"]).replaceAll(/"checked":true/g,'"checked":false'));
        this.setState({attributeOptions:[]});
        this.props.clearAllFilterData();
    }
    handleExpand(pmData) {
        let expand = this.state.filterData;
        expand['parentDatasets'] = pmData;
        this.setState({ filterData: expand });
    }
   
    componentDidMount() {

        let data = this.state.filterData;
        if (data !== "" && data !== undefined && this.state.filterData['datasetsOptions'] !== undefined) {
            let filterIntegrationData = this.state.filterData['parentDatasets'].filter((data) =>
                (data.integration_id === Number(this.state.seletedDatasource) && data.checked === true)
                ||
                (data.children !== undefined && data.integration_id === Number(this.state.seletedDatasource))
            )

            this.setState({ attributeOptions: [] })
            let mappedAttributes = this.state.filterData['attributesOptions']

            let finalList; // = this.state.attributeOptions
            let parentAttribute = []

            filterIntegrationData.map((selectedList, i) => {
                if (selectedList.checked === true || selectedList.children !== undefined) {
                /** OR condition is included If only child dataset is seleted */
                    let splitLable = selectedList.label.split('( ')
                    let loopData = []
                    if (i === 0) {
                        parentAttribute.push({ "label": "All", "value": "*" })
                    }
                    if(mappedAttributes[selectedList.value]!==undefined){
                            if(selectedList.checked === true){
                                parentAttribute.push({ "label": splitLable[0], "disabled": true })
                                let checked = false;
                                let parentAttributeList = mappedAttributes[selectedList.value]
                                parentAttributeList.forEach((attributeDetails) => {
                                    let attribute_name = attributeDetails.label
                                    let req_attribute_name = normalizeAttributeName(attribute_name)
                                    attributeDetails['label'] = req_attribute_name
                                })
                                let AllChecked = parentAttributeList.filter((data)=> data.checked === true)
                                if(parentAttributeList.length === AllChecked.length){
                                    /**If all attributes check from parent dataset marked All options as marked on attribute Dropdown */
                                    checked=true
                                }                
                                            
                                parentAttribute.push({ "label": "All", "value": "*","checked":checked, "dataset_id": selectedList.value})
                                parentAttribute = parentAttribute.concat(mappedAttributes[selectedList.value])                            
                            }
                            
                            let childAttributes = []
                            let parentName = null                        
                            if (selectedList.children !== undefined && selectedList.children.length > 0) {
                                selectedList.children.map((selectedListchild, j) => {
                                    if (selectedListchild.checked === true && selectedListchild.value !== "*") {
                                        let checked=false;
                                        let childAttributeList = mappedAttributes[selectedListchild.value] //.slice(1,mappedAttributes[selectedListchild.value].length)
                                        childAttributeList.forEach((attributeDetails) => {
                                            let attribute_name = attributeDetails.label
                                            let req_attribute_name = normalizeAttributeName(attribute_name)
                                            attributeDetails['label'] = req_attribute_name
                                        })
                                        let AllChecked = childAttributeList.filter((data)=> data.checked === true)
                                        if(childAttributeList.length === AllChecked.length){
                                            /**If all attributes check from child-data set marked child-dataset as marked on attribute Dropdown */
                                            checked=true
                                        }                
                                        
                                        
                                        let expand=false
                                        if(AllChecked.length > 0){
                                        /**Expand the dropdown if anyone of the attribute is selected from the child dataset */
                                            expand = true
                                        }
    
                                        childAttributes = [{ "label": selectedListchild.label, "value": selectedListchild.value,"expanded":expand, checked:checked, "children": childAttributeList }]
    
                                        if(parentName === null ){
                                            parentName = selectedListchild.label.split('$')
                                            loopData = [{ "label": splitLable[0] + " - Partitions", "disabled": true }]
                                            parentAttribute = parentAttribute.concat(loopData)    
                                        }
                                        parentAttribute = parentAttribute.concat(childAttributes)
                                        return selectedListchild;
                                    }
                                    return selectedListchild;
                                })
                            }                    
                    }
                    finalList = parentAttribute
                }
    
                return selectedList;
            }
            )
            this.setState({ attributeOptions: finalList })
        }
    }    
    handleExpandAttributes(pmData) {
        let expand = this.state.filterData;
        expand['parentDatasets'] = pmData;
        this.setState({ filterData: expand });
        this.setState({ filterData: expand });
    }

    handleSelectDate(event, picker) {

        let startDate = picker.startDate;
        let endDate = picker.endDate;
        let customlabel = picker.chosenLabel === "Custom Range" ? startDate.format(DISPLAY_DATE_FORMAT) + ' - ' + endDate.format(DISPLAY_DATE_FORMAT) : picker.chosenLabel;
        this.setState({ dateDisplay: customlabel, startDate: startDate, endDate: endDate })
        this.props.applyDateFilter(startDate,endDate,customlabel);
    }

    handleDatasourceoptions(key) {
        // this.props.data["parentDatasets"] = JSON.parse(JSON.stringify(this.props.data["parentDatasets"]).replaceAll(/"checked":true/g,'"checked":false'));
        $(".textfield-outlined").removeClass("customselect-active");
        let datasourceID = key.value;
        let datasourceName = key.label;
        let loadparams = {
            "integration_id": datasourceID,
            "integration_name": datasourceName,
            "tab_type": TAB_KEY_MAPPING[this.props.tab],
            "start_date": this.props.startDate,
            "end_date": this.props.endDate
        }
        let tempData = this.state.filterData;
        for(let loop=0;loop<tempData["datasetsOptions"].length;loop++){
            if(tempData["datasetsOptions"][loop]["checked"] ===true){
                tempData["datasetsOptions"][loop]["checked"] = false;
            }
        }
        this.setState({
            renderDatasetDD: true,
            seletedDatasource: datasourceID, 
            seletedDatasourceLabel: key.label,
            filteredDatas:loadparams,
            attributeOptions:[],
            filterData: tempData
        })
        this.props.loadDataSourceRender(loadparams)
    }

    handleDatasetsoptions(key) {

    }

    /*
    handleAttributeoptions(selectedList) {


        // let data = selectedList;
        // let filterIntegrationData = data.filter((data)=> 
        //     data.integration_id === this.state.seletedDatasource && data.checked=== true
        // )

        let mappedAttributes = this.state.filterData['attributesOptions']
        let finalList = this.state.attributeOptions
        let data=[]
        let splitLable = selectedList.label.split('( ')
        // if (selectedList.value === "all") {
            // for(let child in mappedAttributes){
            //     childDatasetList = mappedAttributes[selectedList.value].concat(childDatasetList)
            //     this.state.filterData['parentDatasets'].map((data) => {
            //         if (data.children !== undefined && data.value === selectedList.value) {
            //             finalList.push({ "label": splitLable[0], "disabled": true })
            //             data.children.map((child) => {
            //                 let childDataset = []
            //                 childDatasetList.push({ "label": child.label, "disabled": true })
            //                 childDataset = [{ "label": child.label, "value": selectedList.value, "children": mappedAttributes[child.value] }]
            //                 childDatasetList = childDatasetList.concat(childDataset)
            //                 // finalList.push({"label":selectedList.label, "disabled": true })
            //             })

            //             finalChild = mappedAttributes[selectedList.value].concat(childDatasetList)
            //         }
            //     })
            // }
          //  return
        //}

        if (selectedList.checked === false) {
            finalList = finalList.filter((data) => data.dataset_id !== selectedList.value && data.label !== splitLable[0] && data.label !== selectedList.label)
        }
        else {
            let childDatasetList = []
            let finalChild = mappedAttributes[selectedList.value].concat(childDatasetList)
            // let SelectedDataset = selectedList

            if (selectedList._children !== undefined && selectedList._children.length > 0) {
                this.state.filterData['parentDatasets'].map((data) => {
                    if (data.children !== undefined && data.value === selectedList.value) {
                        finalList.push({ "label": splitLable[0], "disabled": true })
                        data.children.map((child) => {
                            let childDataset = []
                            childDatasetList.push({ "label": child.label, "disabled": true })
                            childDataset = [{ "label": child.label, "value": selectedList.value, "children": mappedAttributes[child.value] }]
                            childDatasetList = childDatasetList.concat(childDataset)
                            return child;
                            // finalList.push({"label":selectedList.label, "disabled": true })
                        })

                        finalChild = mappedAttributes[selectedList.value].concat(childDatasetList)
                    }
                    return data;
                })
            }
            data = [{ "label": selectedList.label, "value": selectedList.value, "children": finalChild }]
        }
        finalList = finalList.concat(data)
        this.setState({ attributeOptions: finalList })
    }*/

    handleAttributesSelection(selectedList) {
        this.setState({ attributeOptions: selectedList })
    }

    handleSelectedMetrics(selectedList){
        this.setState({ metricsSelectedOptions: selectedList })
    }
    /*
    handleSelectedAttributes(selectedList) {

        let data = selectedList;
        let filterIntegrationData = data.filter((data) =>
            parseInt(data.integration_id) === parseInt(this.state.seletedDatasource) && data.checked === true
        )
        // return;
        this.setState({ attributeOptions: [] })
        let childDatasetList = []
        let mappedAttributes = this.state.filterData['attributesOptions']
        let finalList; // = this.state.attributeOptions
        let finalChild = []
        filterIntegrationData.map((selectedList, i) => {
            if (selectedList.checked === true) {
                let splitLable = selectedList.label.split('( ')
                let loopData = []
                if (i === 0) {
                    finalChild.push({ "label": "All", "value": "*" })
                }
                finalChild.push({ "label": splitLable[0], "disabled": true })
                finalChild.push({ "label": "All (" + splitLable[0] + ")", "value": "*", "dataset_id": selectedList.value,"checked":""})
                if(mappedAttributes[selectedList.value]!==undefined){
                    finalChild = finalChild.concat(mappedAttributes[selectedList.value])
                }
                let childDataset = []
                if (selectedList.children !== undefined && selectedList.children.length > 0) {
                    selectedList.children.map((selectedListchild, j) => {
                        if (j === 0) {
                            loopData = [{ "label": splitLable[0] + " - Partitions", "disabled": true ,"checked":"" }]
                        }
                        if (selectedListchild.checked === true && selectedListchild.value !== "*") {
                            childDataset = [{ "label": selectedListchild.label, "value": selectedListchild.value, "children": mappedAttributes[selectedListchild.value],"checked":"" }]
                            childDatasetList = childDatasetList.concat(childDataset)
                            return selectedListchild;
                        }
                        return selectedListchild;
                    })
                }
                loopData = loopData.concat(childDatasetList)
                finalList = finalChild.concat(loopData)
            }
            return selectedList;
        }
        )
        if(finalList!==undefined){
            finalList = JSON.parse(String(JSON.stringify(finalList)).replace(/"checked":true/g, '"checked":""'))
        }
        this.setState({ attributeOptions:finalList})
    }*/

    handleSelectedAttributes(selectedList, selectedNode=null) {
        let data = selectedList;
        let filterIntegrationData = data.filter((data) =>
            (data.integration_id === Number(this.state.seletedDatasource) && data.checked === true) 
        
            ||
            /** OR condition is included If only child dataset is seleted */
            (data.children !== undefined && data.integration_id === Number(this.state.seletedDatasource) )
        )
        // return;

        this.setState({ attributeOptions: [] })
        let mappedAttributes = this.state.filterData['attributesOptions']
        let finalList; // = this.state.attributeOptions
        let parentAttribute = []
        filterIntegrationData.map((selectedList, i) => {
            if (selectedList.checked === true || selectedList.children !== undefined) {
            /** OR condition is included If only child dataset is seleted */
                let splitLable = selectedList.label.split('( ')
                let loopData = []
                if (i === 0) {
                    parentAttribute.push({ "label": "All", "value": "*" })
                }
                if(mappedAttributes[selectedList.value]!==undefined){
                        if(selectedList.checked === true){
                            parentAttribute.push({ "label": splitLable[0], "disabled": true })
                            let checked = false;
                            let parentAttributeList = mappedAttributes[selectedList.value]
                            parentAttributeList.forEach((attributeDetails) => {
                                let attribute_name = attributeDetails.label
                                let req_attribute_name = normalizeAttributeName(attribute_name)
                                attributeDetails['label'] = req_attribute_name
                            })
                            let AllChecked = parentAttributeList.filter((data)=> data.checked === true)
                            if(parentAttributeList.length === AllChecked.length){
                                /**If all attributes check from parent dataset marked All options as marked on attribute Dropdown */
                                checked=true
                            }                
                                        
                            parentAttribute.push({ "label": "All", "value": "*","checked":checked, "dataset_id": selectedList.value})
                            parentAttribute = parentAttribute.concat(mappedAttributes[selectedList.value])                            
                        }
                        
                        let childAttributes = []
                        let parentName = null                        
                        if (selectedList.children !== undefined && selectedList.children.length > 0) {
                            selectedList.children.map((selectedListchild, j) => {
                                if (selectedListchild.checked === true && selectedListchild.value !== "*") {
                                    let checked=false;
                                    let childAttributeList = mappedAttributes[selectedListchild.value] //.slice(1,mappedAttributes[selectedListchild.value].length)
                                    childAttributeList.forEach((attributeDetails) => {
                                        let attribute_name = attributeDetails.label
                                        let req_attribute_name = normalizeAttributeName(attribute_name)
                                        attributeDetails['label'] = req_attribute_name
                                    })
                                    let AllChecked = childAttributeList.filter((data)=> data.checked === true)
                                    if(childAttributeList.length === AllChecked.length){
                                        /**If all attributes check from child-data set marked child-dataset as marked on attribute Dropdown */
                                        checked=true
                                    }                
                                    
                                    
                                    let expand=false
                                    if(AllChecked.length > 0){
                                    /**Expand the dropdown if anyone of the attribute is selected from the child dataset */
                                        expand = true
                                    }

                                    childAttributes = [{ "label": selectedListchild.label, "value": selectedListchild.value,"expanded":expand, checked:checked, "children": childAttributeList }]

                                    if(parentName === null ){
                                        parentName = selectedListchild.label.split('$')
                                        loopData = [{ "label": splitLable[0] + " - Partitions", "disabled": true }]
                                        parentAttribute = parentAttribute.concat(loopData)    
                                    }
                                    parentAttribute = parentAttribute.concat(childAttributes)
                                    return selectedListchild;
                                }
                                return selectedListchild;
                            })
                        }                    
                }
                finalList = parentAttribute
            }

            return selectedList;
        }
        )

        this.setState({ attributeOptions: finalList })
    }

    loadFilterFullsceen(pm){
        let obj = {"datasource":pm.filterdDatsource,
        "dataset":pm.fiteredDatseset,
        "attribute":pm.fitlerdAttr,
        "metrics":this.state.metricsSelectedOptions,
        "startdate":pm.fitlerdDate[0],
        "enddate":pm.fitlerdDate[1]
        }
        this.props.applyDataset(obj);
    }

    loadFilter(pm) {
        let obj = {"datasource":pm.filterdDatsource,
        "dataset":pm.fiteredDatseset,
        "attribute":pm.fitlerdAttr,
        //"metrics":this.state.metricsSelectedOptions,
        "metrics":pm.filterMetricsId,
        "startdate":pm.fitlerdDate[0],
        "enddate":pm.fitlerdDate[1]
        }
        this.props.applyDataset(obj);
    }

    handleFocus() {
        // $("#chooseDatasourceForDqError").parent(".textfield-outlined").addClass('customselect-active');   
    }

    handleBlur() {
        $(".textfield-outlined").removeClass("customselect-active");
    }

    render() {


        let attributeOptions = [];
        if(this.state.attributeOptions !== undefined && this.state.attributeOptions.length > 0){
            attributeOptions =this.state.attributeOptions
        }

        let datasetOptions = this.state.filterData['parentDatasets'];
        if (this.state.filterData['parentDatasets'] !== undefined && this.state.filterData['parentDatasets'].length > 0) {
            datasetOptions = this.state.filterData['parentDatasets'].filter((data) => parseInt(data.integration_id) === parseInt(this.state.seletedDatasource) || data.value === "*")
        }
    
        let ranges = {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        };
    
        return (
            <>
            <div className="qd-filter-section">
                <div className="qd-filter__content">
                    <div className="qd-filter__header">
                        <h5 className="qd-filter__title">Data Relationship Filter</h5>
                        <button type='button' className="close"><span className="sr-only">Close</span></button>
                    </div>
                    <div className="qd-filter__body">
                        <div className="qd-filter__options">
                            <div className={this.props.clearAllFilter === true &&  this.props.datasourcechange!==false ? "has-error textfield-outlined" : "textfield-outlined"}>
                                <Form.Label>Datasource</Form.Label>
                                <Select
                                //datasourcechange
                                    isDisabled={this.props.datasourcechange===false ? true : false}
                                    onFocus={this.handleFocus}
                                    value={this.props.clearAllFilter === true && this.props.datasourcechange!==false  ? { value: "-1", label: "" } : { value: this.props.seletedDatasourceId, label: this.props.seletedDatasourceName }}
                                    id="chooseDatasourceForDqError"
                                    name="data_sources"
                                    options={this.state.filterData['datasourceOptions']}
                                    onChange={this.handleDatasourceoptions}
                                    classNamePrefix='form-control'
                                    placeholder="Choose Datasource"
                                    components={{ Control: customSelectDatasource }}
                                />
                                {/* <CustomTreeSelect
                                    id="chooseDatasourceForDqError"
                                    name="data_sources"
                                    // data={data_dataset}
                                    data={this.state.filterData['datasourceOptions']}
                                    classNamePrefix='form-control'
                                    loadFilter={this.loadFilter}
                                    prependIcon={faDatabase}
                                    placeholder={"Choose Datasource"}
                                />*/}
                            </div>
                            {this.props.showDatasets === true ?
                            <div className="textfield-outlined ">
                                <Form.Label>Datasets</Form.Label>
                                <CustomTreeSelect
                                    id="chooseDatassetForDqError"
                                    name="data_set"
                                    classNamePrefix='form-control'
                                    loadFilterDataset={this.loadFilter}
                                    loadFilterDatasetFullScreen={this.loadFilterFullsceen}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    seletedDatasource={this.state.seletedDatasource}
                                    data={(this.props.clearAllFilter === true && this.props.datasourcechange!==false) || (datasetOptions.length <= 1) ? [{ "integration_id": this.state.seletedDatasource, "label": "No options", "disabled": true }] : datasetOptions}
                                    prependIcon={faLayerGroup}
                                    placeholder={"Choose Dataset"}
                                    metrics_data={this.state.metricsSelectedOptions}
                                    handleSelectedAttributes={this.handleSelectedAttributes}
                                    noMatches="No options"
                                    handleExpand={this.handleExpand}
                                    renderDatasetDD={this.state.renderDatasetDD} 
                                    fullscreenviewselect={this.props.fullscreenviewselect}
                                    />
                            </div> : "" }
                            {this.props.showAttributes === true ?
                            <div className="textfield-outlined ">
                                <Form.Label>Attributes</Form.Label>
                                <CustomTreeSelect
                                    id="chooseAttributeForDqError"
                                    name="attributes"
                                    loadFilterDataset={this.loadFilter}
                                    loadFilterDatasetFullScreen={this.loadFilterFullsceen}
                                    data={(this.props.clearAllFilter === true && this.props.datasourcechange!==false) || attributeOptions === undefined || (attributeOptions !== undefined && attributeOptions.length === 0) ? [{ "integration_id": this.state.seletedDatasource, "label": "No options", "disabled": true }] : attributeOptions}
                                    dataset_data={(this.props.clearAllFilter === true && this.props.datasourcechange!==false) ? [{ "integration_id": this.state.seletedDatasource, "label": "No options", "disabled": true }] : this.state.filterData['parentDatasets']}
                                    seletedDatasource={this.state.seletedDatasource}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    classNamePrefix='form-control'
                                    loadFilter={this.loadFilter}
                                    prependIcon={faTag}
                                    placeholder={"Choose Attributes"}
                                    noMatches="No options"
                                    metrics_data={this.state.metricsSelectedOptions}
                                    handleExpand={this.handleExpandAttributes}
                                    handleAttributesSelection={this.handleAttributesSelection}
                                    fullscreenviewselect={this.props.fullscreenviewselect}
                                />
                            </div> : ""}
                            {this.props.showMetrics === true ?
                            <div className="textfield-outlined ">
                                <Form.Label>Metrics</Form.Label>
                                <CustomTreeSelect
                                    id="chooseMetricsForDqError"
                                    name="metrics"
                                    dataset_data={(this.props.clearAllFilter === true && this.props.datasourcechange!==false) ? [{ "integration_id": this.state.seletedDatasource, "label": "No options", "disabled": true }] : this.state.filterData['parentDatasets']}
                                    attribute_data={(this.props.clearAllFilter === true && this.props.datasourcechange!==false) || attributeOptions === undefined || (attributeOptions !== undefined && attributeOptions === 0) ? [{ "integration_id": this.state.seletedDatasource, "label": "No options", "disabled": true }] : attributeOptions}
                                    seletedDatasource={this.state.seletedDatasource}
                                    loadFilterDataset={this.loadFilter}
                                    data={this.props.metrics_data}
                                    metrics_data={this.state.metricsSelectedOptions}
                                    classNamePrefix='form-control'
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    loadFilter={this.loadFilter}
                                    prependIcon={faTag}
                                    placeholder={"Choose Metrics"}
                                    noMatches="No options"
                                    handleSelectedAttributes={this.handleSelectedMetrics}
                                    fullscreenviewselect={this.props.fullscreenviewselect}
                                    selectedGroup={this.props.selectedGroup}
                                />
                            </div> : ""
                            }
                            <span className="dot-separator"></span>
                            <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onApply={this.handleSelectDate}
                                ranges={ranges}>
                                <i>
                                    <FontAwesomeIcon icon={faCalendar} />
                                </i>
                                <span>
                                    {this.state.dateDisplay}
                                    {/* {this.state.endDate.format(DISPLAY_DATE_FORMAT)+' - '+ this.state.startDate.format(DISPLAY_DATE_FORMAT)} */}
                                </span>
                                <i className="icon-close ml-2"></i>
                            </DateRangePicker>
                            {this.props.showAttributesCheckbox === true ?
                            <div className="custom-control custom-checkbox text-muted ml-2">
                                <Form.Control
                                    type="checkbox"
                                    className="custom-control-input"
                                    defaultChecked={this.props.showerrors}
                                    onChange={this.props.changeShowErrors}
                                />
                                <Form.Label className="custom-control-label">Show Attributes</Form.Label>
                            </div> : "" } 
                            {this.props.showErrorsOnlyCheckbox === true ?
                            <div className="custom-control custom-checkbox text-muted">
                                <Form.Control
                                    type="checkbox"
                                    className="custom-control-input"
                                />
                                <Form.Label className="custom-control-label">Show Errors Only</Form.Label>
                            </div> : "" }
                            <span className="line-separator"></span>
                                <button className="btn btn-filter-reset shadow-none px-2" onClick={this.handleClearAll}>Clear All</button>
                        </div>
                    </div>
                </div>
            </div>
              {/* <div className="qd-fs_body overflow-auto"> */}
              {this.props.viewOptions === true || this.props.showErrorCheckbox === true || this.props.showParitionCheckbox ?
            <div className="d-flex align-items-center mb-3">
                {this.props.viewOptions === true ?
                    <div className="row align-items-center">
                        <div className="col-auto pr-0">
                            <label className="col-form-label">View: </label>
                        </div>
                        <div className="col-auto" style={{minWidth: '225px'}}>
                        <Select
                                                            classNamePrefix='select-control'
                                                            value={this.props.selectedGroup}
                                                            // value ={{"label": "Dataset + Attribute", "value": "all"}}
                                                            options={LIST_VIEW_OPTIONS}
                                                            isOptionDisabled={(option) => option.disabled}
                                                            onChange={this.props.changeGroup}
                                                        />
                        </div>
                    </div> : ""}
                    {this.props.showErrorCheckbox === true ?
                    <Form.Check 
                        custom
                        type='checkbox'
                        id='errors_only'
                        label="Show Errors Only"
                        defaultChecked={this.props.showerrors}
                        onChange={this.props.changeShowErrors}
                        className="ml-3"
                    /> : "" }
                {this.props.showParitionCheckbox === true ?
                    <Form.Check 
                        custom
                        type='checkbox'
                        id='chartfs_include-partitions'
                        label="Include Partitions"
                        defaultChecked={this.props.showpartitions}
                        onChange={this.props.changePartitions}
                        className="ml-3"
                    /> : ""
                }
            </div> : ""}
                  
          {/* </div> */}
          </>
        );
    }
}
export default CustomTreeFilter;