import React from 'react';
import BreadCrumb from '../components/breadCrumb';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import NavigationHeader from '../components/navigationHeader';
import {CopyRightsFooter} from './copyrights';
import {
    CONFIGURE_DATA_SOURCE_URL, CONFIGURE_MODEL_URL, PIPELINE_CHANNEL_URL,
    MONITOR_MODEL_URL, MONITOR_DATA_URL,
    PROFILE_DATA_URL, PROFILE_MODEL_URL, NOTIFICATION_URL, DATA_QUALITY_URL,
    MONITOR_MODEL_PERFORMANCE_URL, IS_NONE_CHECK
} from "../utils/constant";
import {getFeatureAccess} from "../utils/common_utils";
import {Mediakind} from "./mediaKind"

class VideoHelp extends React.Component {

    constructor(props) {
        super(props);
        this.handleTab = this.handleTab.bind(this);
        this.state={
            current_tab:"quick_start",
            featureAccess: getFeatureAccess()
        }
    }


    renderVideoContent(value){
        let DEPLOYED_ENVIRONMENT = process.env.REACT_APP_CUSTOM_NODE_ENV;
        let quick_start_message="Learn how to setup a datasource and a model to start monitoring the performance metrics.";
        let setup_alert_message="Learn how to create custom data quality and model metrics and custom queries for your alert.";
        if(DEPLOYED_ENVIRONMENT === "managed_service"){
            quick_start_message="Learn how to setup a datasource to start monitoring the performance metrics.";
            setup_alert_message="Learn how to create custom data quality and custom queries for your alert.";
        }
        switch(value){
            case "quick_start":
                return(
                    <div className="qd-qs_tab-content">
                        <h4 className="qd-qs_tab-title">Quick Start Video Tutorial</h4>
                        <p className="text-info"><strong>Watch this short video tutorial to quickly start using Qualdo™</strong></p>
                        <p>{quick_start_message}</p>
                        <div id="quick_start">
                        <Mediakind
                            url= {CONFIGURE_DATA_SOURCE_URL}
                        />
                        </div>
                    </div>
                );
            case "host_tab":
                return(
                    <div className="qd-qs_tab-content">
                        <h4 className="qd-qs_tab-title">Configure a Datasource</h4>
                        <p className="text-info"><strong>Watch this short video tutorial to configure datasource</strong></p>
                        <p>Learn how to add and organize datasources for Qualdo™ to start monitoring for data quality issues and metrics.</p>
                        <div id="host_tab">
                        <Mediakind
                            url= {CONFIGURE_DATA_SOURCE_URL}
                        />
                        </div>
                    </div>
                );
            case "dashboard_creation":
                return (
                    <div className="qd-qs_tab-content">
                      <h4 className="qd-qs_tab-title">Configure a Model</h4>
                        <p className="text-info">Watch this short video tutorial to configure Models</p>
                        <p>Learn how to add and organize models within Qualdo™ to start monitoring for performance issues and metrics.
                        View this tutorial to configure training, test and serving data of your model.</p>
                      <div id="dashboard_creation">
                      <Mediakind
                            url= {CONFIGURE_MODEL_URL}
                        />
                      </div>
                    </div>
                );

            case "moniter_creation":
                 return (
                    <div className="qd-qs_tab-content">
                      <h4 className="qd-qs_tab-title">Profile a Datasource</h4>
                        <p className="text-info">Watch this short video tutorial to profile datasources and datasets.</p>
                        <p>Learn how to profile different datasources for various data quality metrics.
                           View this tutorial to understand data relationships, completeness, accuracy, timeliness, conformity and consistency.</p>
                        <div id="monitor_creation">
                        <Mediakind
                            url= {PROFILE_DATA_URL}
                        />
                        </div>
                    </div>
                 );

            case "invite_team_mates":
                  return(
                     <div className="qd-qs_tab-content">
                        <h4 className="qd-qs_tab-title">Profile a Model</h4>
                        <p className="text-info">Watch this short video tutorial to profile the configured models.</p>
                        <p>Learn how to profile multiple models for various performance metrics.
                           View this tutorial to quickly understand model drifts and performance metrics.</p>
                        <div id="invite_team">
                        <Mediakind
                            url= {PROFILE_MODEL_URL}
                        />
                        </div>
                     </div>);
            case "install_integration":
                  return (
                     <div className="qd-qs_tab-content">
                         <h4 className="qd-qs_tab-title">Monitor DQ Metrics</h4>
                        <p className="text-info">Watch this short video tutorial to monitor data quality issues and metrics.</p>
                        <p>Learn how to monitor data issues, data quality errors and data quality performance of multiple datasources.</p>
                        <div id="install_integration">
                        <Mediakind
                            url= {MONITOR_DATA_URL}
                        />
                        </div>
                     </div>);

            case "overview_video":
                  return(
                     <div className="qd-qs_tab-content">
                         <h4 className="qd-qs_tab-title">Monitor Model Metrics</h4>
                        <p className="text-info">Watch this short video tutorial to monitor model issues and metrics.</p>
                        <p>Learn how to monitor model issues, errors and model performance metrics.</p>
                        <div id="overview">
                        <Mediakind
                            url= {MONITOR_MODEL_URL}
                        />
                        </div>
                     </div>);
            case "model_performance_monitoring":
                  return(
                     <div className="qd-qs_tab-content">
                         <h4 className="qd-qs_tab-title">Monitor Model Performance Metrics</h4>
                        <p className="text-info">Watch this short video tutorial to monitor model performance issues and metrics.</p>
                        <p>Learn how to monitor model performance issues, errors and model performance metrics.</p>
                        <div id="overview">
                        <Mediakind
                            url= {MONITOR_MODEL_PERFORMANCE_URL}
                        />
                        </div>
                     </div>);
            case "setup_notification":
                return(
                   <div className="qd-qs_tab-content">
                       <h4 className="qd-qs_tab-title">Setup a notification</h4>
                        <p className="text-info">Watch this short video tutorial to setup notifications from Qualdo™.</p>
                        <p>Learn how to send notifications to email and slack or any other medium of your choice.</p>
                        <div id="setup_notification">
                        <Mediakind
                            url= {NOTIFICATION_URL}
                        />
                        </div>
                   </div>);
            case "pipeline_trigger":
                return(
                   <div className="qd-qs_tab-content">
                       <h4 className="qd-qs_tab-title">Setup a Pipeline Trigger</h4>
                        <p className="text-info">Watch this short video tutorial to setup triggers from Qualdo™.</p>
                        <p>Learn how to setup triggers for webhooks or a pipeline tech-stack of your choice.</p>
                        <div id="pipeline_trigger">
                        <Mediakind
                            url= {PIPELINE_CHANNEL_URL}
                        />
                        </div>
                   </div>);
            case "setup_alert":
                return(
                   <div className="qd-qs_tab-content">
                    <h4 className="qd-qs_tab-title">Setup an Alert</h4>
                        <p className="text-info">Watch this short video tutorial to setup custom metrics and alerts.</p>
                        <p>{setup_alert_message}</p>
                        <div id="setup_alert">
                        <Mediakind
                            url= {DATA_QUALITY_URL}
                        />
                        </div>
                   </div>);
            default:
                  break;
        }
    }

    handleTab(key) {
        this.setState ({current_tab: key});
    }

    render() {
        let sessionToken = localStorage.getItem("sessionToken");
        if (sessionToken === "undefined" || sessionToken === "null" || sessionToken === null || sessionToken === undefined) {
            window.location = '/';
            return;
        }

        let hideModelInfo = true;

        if (!IS_NONE_CHECK.includes(this.state.featureAccess)) {
            const modelInfo = this.state.featureAccess["models"];
            hideModelInfo = modelInfo === false;
        }


        return (
            <>
                <NavigationHeader {...this.props}/>
                <main>
                    <BreadCrumb icon='quickStart' title='Quick Start' />
                        <div className="qd-container">
                                <div className="qd-body no-tabs qd-grid">
                                    <div className="col-md-10 offset-md-1 p-3">
                                       
                                        <div className="qd_quick-start-wrapper">
                                            <div className="row">
                                                <Tab.Container defaultActiveKey="quick_start" onSelect={this.handleTab}>
                                                    <div className="qd-qs_aside">
                                                        <Nav className="nav flex-column" variant="pills" id="qd_quick-start_tab" aria-orientation="vertical">
                                                            <Nav.Link eventKey="quick_start" aria-controls="qd-qs_zero"  id="qd-qs_zero-tab" >
                                                            Quick Start
                                                            </Nav.Link>
                                                            <Nav.Link eventKey="host_tab" aria-controls="qd-qs_first"  id="qd-qs_first-tab" >
                                                            Configure a Datasource
                                                            </Nav.Link>
                                                            {hideModelInfo ? "" : <Nav.Link eventKey="dashboard_creation" aria-controls="qd-qs_second" id="qd-qs_second-tab" >
                                                            Configure a ML Model
                                                            </Nav.Link>}
                                                            <Nav.Link eventKey="moniter_creation" aria-controls="qd-qs_third" id="qd-qs_third-tab">
                                                            Profile a Datasource
                                                            </Nav.Link>
                                                            {hideModelInfo ? "" : <Nav.Link eventKey="invite_team_mates" id="qd-qs_four" aria-controls="qd-qs_four-tab">
                                                            Profile a Model
                                                            </Nav.Link>}
                                                            <Nav.Link eventKey="install_integration" id="qd-qs_five" aria-controls="qd-qs_five-tab">
                                                            Monitor DQ Metrics
                                                            </Nav.Link>
                                                            {hideModelInfo ? "" : <><Nav.Link eventKey="overview_video" id="qd-qs_six" aria-controls="qd-qs_six-tab">
                                                            Monitor Model Metrics
                                                            </Nav.Link>
                                                            <Nav.Link eventKey="model_performance_monitoring" id="qd-qs_ten" aria-controls="qd-qs_six-tab">
                                                            Model Performance Metrics
                                                            </Nav.Link></>}
                                                            <Nav.Link eventKey="setup_notification" id="qd-qs_seven" aria-controls="qd-qs_seven-tab">
                                                            Setup a Notification
                                                            </Nav.Link>
                                                            <Nav.Link eventKey="pipeline_trigger" id="qd-qs_eight" aria-controls="qd-qs_eight-tab">
                                                            Setup a Pipeline-Trigger
                                                            </Nav.Link>
                                                            <Nav.Link eventKey="setup_alert" id="qd-qs_nine" aria-controls="qd-qs_nine-tab">
                                                            Setup an Alert
                                                            </Nav.Link>
                                                        </Nav>
                                                    </div>
                                                    <div className="col qd-qs_content">
                                                         <Tab.Content id="qd_quick-start_tabContent">
                                                             <Tab.Pane eventKey="quick_start" mountOnEnter={true} unmountOnExit={true}>
                                                              {this.renderVideoContent(this.state.current_tab)}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="host_tab" mountOnEnter={true} unmountOnExit={true}>
                                                              {this.renderVideoContent(this.state.current_tab)}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="dashboard_creation" mountOnEnter={true} unmountOnExit={true}>
                                                              {this.renderVideoContent(this.state.current_tab)}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="moniter_creation" mountOnEnter={true} unmountOnExit={true}>
                                                             {this.renderVideoContent(this.state.current_tab)}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="invite_team_mates" mountOnEnter={true} unmountOnExit={true}>
                                                             {this.renderVideoContent(this.state.current_tab)}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="install_integration" mountOnEnter={true} unmountOnExit={true}>
                                                             {this.renderVideoContent(this.state.current_tab)}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="overview_video" mountOnEnter={true} unmountOnExit={true}>
                                                             {this.renderVideoContent(this.state.current_tab)}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="model_performance_monitoring" mountOnEnter={true} unmountOnExit={true}>
                                                             {this.renderVideoContent(this.state.current_tab)}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="setup_notification" mountOnEnter={true} unmountOnExit={true}>
                                                             {this.renderVideoContent(this.state.current_tab)}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="pipeline_trigger" mountOnEnter={true} unmountOnExit={true}>
                                                             {this.renderVideoContent(this.state.current_tab)}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="setup_alert" mountOnEnter={true} unmountOnExit={true}>
                                                             {this.renderVideoContent(this.state.current_tab)}
                                                            </Tab.Pane>
                                                         </Tab.Content>
                                                    </div>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                </main>

                <CopyRightsFooter/>
               </>
            );
        }
    }

export default VideoHelp;