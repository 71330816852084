import React from 'react';
import Select from 'react-select';
import { faPlusCircle, faDownload, faCloudUploadAlt, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleFileSubmit }  from "../../utils/event_handling";
import {userValidation} from "../../utils/common_utils";
import Modal from 'react-bootstrap/Modal';
import Dropzone from 'react-dropzone';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { call_post_api }  from "../../utils/event_handling";
import {ui_service_ip} from "../../utils/ip_details";
import Image from "../../components/image";
import {postMixpanel} from '../../mixpanel';
import ReactDOM from 'react-dom';


class DSEnvironment extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.showFileUpload = this.showFileUpload.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.checkEnv= this.checkEnv.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.setFile = this.setFile.bind(this);
        this.showButtonAction = this.showButtonAction.bind(this);
        this.addEnvironment = this.addEnvironment.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.state = {
            errors: {
            },
            env_id:null,
            show: false,
            show_add_datasource_modal: false,
            uploadClick: false,
            file:[],
            actionType: '',
            showButton: false,
            clickAction: false,
            hideSubmitButton: false
        }
    }

    disableButton() {
            this.setState({hideSubmitButton: true});
    }

    showFileUpload() {
        postMixpanel("Clicked Bulk-add Datasource in Configure->data")
        this.setState({show_add_datasource_modal:this.props.dataLimitExceed,actionType:"upload"});
        if(this.props.dataLimitExceed === false){
          this.setState({show:true});
        }
    }

    handleClose() {
        this.setState({show: false});
        this.setState({uploadClick:false});
    }

    goToChoosePlan = () => {
        this.props.router.push("/choose-plan");
    }

    checkEnv() {
        postMixpanel("Clicked Add datasource in configure -> data tab")
        let env = this.state.env_id;
        this.setState({clickAction:true});
        this.props.setEnvData(env)
        if(env !== null) {
            var topOfElement = document.querySelector("#jump-to_datasource").offsetTop;
            window.scroll({ top: topOfElement+200, behavior: "smooth" });
            this.setState({show_add_datasource_modal:this.props.dataLimitExceed, actionType: "add"});
            if(this.props.dataLimitExceed === false){
               this.props.setPage(true);
            }else{
               this.props.setPage(false);
            }
        }else {
            const containerchooseenv = ReactDOM.findDOMNode(this.refs.containerchooseenv)
            window.scrollTo({top:containerchooseenv.offsetTop,behavior: 'smooth'});
        }
    }

    setFile(file){
        this.setState({
            file
        });
    }

    handleChange = (selectedOption) => {
        this.setState({ env_id:selectedOption["value"],env_name:selectedOption['label']});
        let data = {env_id:selectedOption["value"],env_name:selectedOption['label']}
        this.props.setEnvData(data['env_id'])
    }

    addEnvironment(event) {
        let {environment} = event;
        let session = localStorage.getItem("sessionToken");
        const env_data = {env_name: environment, tenant_id: localStorage.getItem('team_id')};
        call_post_api(ui_service_ip, 'environment', env_data, session).then((response) => {
            if (response.code === 200) {
                this.props.showToast("alert-success", "Environment added successfully", '')
                let tenant_id = localStorage.getItem('team_id')
                let key = `environments_${tenant_id}`
                sessionStorage.removeItem(key);
                let env_data = response.env_data;
                this.setState({showButton: false});
                this.props.updateEnvironmentList(env_data);
            } else {
                this.props.showToast("alert-danger", response.message, '')
            }
        });
        postMixpanel('New Environment created');
    }

    downloadSampleData = (e) => {
        e.preventDefault();
		fetch('https://samplecsv.blob.core.windows.net/mlmodel/datasources_template.json')
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = 'datasources_template.json';
					a.click();
				});
		});
	}
	showButtonAction () {
	    this.setState({showButton: true});
	}

	closeModal(){
	    this.setState({show_add_datasource_modal:false});
	}

	goToChoosePlan = () => {
        this.props.router.push("/choose-plan");
    }

    setConfirm=()=>{
       this.props.setDataLimit();
       this.setState({acceptCondition:true});
       this.setState({show_add_datasource_modal:false});
       if(this.state.actionType === "add") {
         this.props.setPage(true);
       }else if(this.state.actionType === "upload") {
         this.setState({show:true});
       }

    }
    render() {
       const envSchema = yup.object({
        environment: yup.string().min(1,"Name must be at least 1 characters").required("Environment is a required field").test(
            'environmentName test',
            'invalid Environment name',        
            function test(value) {        
               let val = userValidation(value,"input");       
               return val;
        }
        ),
       });

       return(
            <>
                <Modal show={this.state.show_add_datasource_modal}
                      className="modal__upgrade text-center"
                      onHide={this.closeModal}
                      centered={true}>
                {this.props.planName !== "free" && this.props.planName !== null ? <><Modal.Header closeButton className="border-0 justify-content-center d-block">
                        <h5 className="modal-title" id="updagrade_plan">
                        You are going to exceed your data usage limit</h5>
                        <p className="text-muted">For extra data you will be charged $52 per 100GB as part of the next month billing cycle.
                            To use additional data, please provide us the confirmation for further processing.</p>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"/>
                </Modal.Header>
                <Modal.Body>
                    <Image src="exceedModelLimit"/>
                </Modal.Body>
                <Modal.Footer className=" border-0 justify-content-center" centered>
                    <button type="button" onClick={this.closeModal} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                    <button type="button" onClick={this.setConfirm}
                        className="btn btn-primary btn-circle">Confirm <FontAwesomeIcon className="mr-0 ml-2" icon={faArrowRight}/></button>
                </Modal.Footer></>
                :<><Modal.Header closeButton className="border-0 justify-content-center d-block">
                    <h5 className="modal-title" id="updagrade_plan">
                        You are currently on a Basic Plan (Free)</h5>
                        <p className="text-muted">Upgrade now to use this feature</p>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"/>
                </Modal.Header>
                <Modal.Body>
                    <Image src="upgradePlanIcon"/>
                </Modal.Body>
                <Modal.Footer className=" border-0 justify-content-center" centered>
                    <button type="button" onClick={this.closeModal} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                    <button type="button" onClick={this.goToChoosePlan}
                        className="btn btn-primary btn-circle">Choose another plan <FontAwesomeIcon className="mr-0 ml-2" icon={faArrowRight}/></button>
                </Modal.Footer></>}
            </Modal>
                <div className="row" ref="containerchooseenv">
                        <div className="vertical-stepper vertical-stepper-sm">
                            <div className="vertical-stepper__heading">
                            <span className="vs-heading__circle">1</span>
                            <div className="vs-heading__title">Add or Choose Environment</div>
                            </div>
                            <div className="vertical-stepper__content">
                              <p>Environment is used to organize related datasources (Eg. Dev, Staging, Production). Add or select an existing environment.</p>
                              <Formik
                                 initialValues={{ environment: '' }}
                                 validationSchema={envSchema}
                                 onSubmit={this.addEnvironment}
                              >
                              {({
                                   values,
                                   errors,
                                   touched,
                                   handleChange,
                                   handleSubmit,
                                   isSubmitting,
                                   isValid,
                              }) => (
                                  <Form className="needs-validation" noValidate method="post" ref={form => this.form = form}  name='add_environment' onSubmit={handleSubmit}>
                                    <div className="qd-get-configure">
                                        <div className="form-row mb-1">
                                            <div className="col-lg-5">
                                                <div className={(this.state.env_id !== null || this.state.clickAction === false) ? "card" : "card has-error"}>
                                                    <Form.Label>Choose Environment</Form.Label>
                                                    <Form.Group className="mb-1">
                                                    <Select name="env" onChange={this.handleChange}
                                                            filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                            options={ this.props.environment !== null ? this.props.environment : [] }
                                                            placeholder=" "
                                                            classNamePrefix='select-control'
                                                            id="choose_environment_add_datasource"
                                                            noOptionsMessage={() => this.props.environment === null ? "Loading..." : "No options" }
                                                    />
                                                    </Form.Group>
                                                    <Form.Text className="text-muted font-italic">Please select an Environment</Form.Text>
                                                </div>
                                            </div>
                                            <div className="col-lg-1 d-flex align-items-center justify-content-center">
                                                <h4 className="text-muted">OR</h4>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="card">
                                                        <Form.Label>Add Environment</Form.Label>
                                                        { this.state.showButton !== false ?
                                                           <>
                                                                <div className="form-inline d-flex">
                                                                    <Form.Control type="text" className="form-control-solid placeholder-no-fix mb-1 flex-grow-1"
                                                                    autoComplete="off" placeholder=" " name="environment" onChange={handleChange} isInvalid={!!errors.environment}/>
                                                                    <button type="submit" className="btn btn-outline-primary mb-1">
                                                                        <FontAwesomeIcon icon={faPlusCircle}/>
                                                                        Add Environment
                                                                    </button>
                                                                    <Form.Control.Feedback type="invalid">
                                                                         { errors.environment }
                                                                    </Form.Control.Feedback>
                                                                </div>
                                                                {errors.environment ? '' : <Form.Text className="text-muted font-italic">Please add Environment</Form.Text>}
                                                           </>:
                                                           <>
                                                                <div className="form-inline d-flex">
                                                                    <button type="button"  onClick={() => this.showButtonAction()} className="btn btn-outline-primary mb-1">
                                                                        <FontAwesomeIcon icon={faPlusCircle}/>
                                                                        Add Environment
                                                                    </button>
                                                                </div>
                                                                <Form.Text className="text-muted font-italic">Please add Environment</Form.Text>
                                                           </>
                                                        }
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                  </Form>)}
                              </Formik>
                            </div>
                            <div className="vertical-stepper__heading">
                                <span className="vs-heading__circle">2</span>
                            <div className="vs-heading__title">Add or Bulk-Add Datasources </div>
                            </div>
                            <div className="vertical-stepper__content pb-0">
                                <p>Datasources are the files and databases containing the data that need to be monitored. Datasource is a collection of datasets. Use the sample template to configure multiple datasources.</p>
                                <p><a className="show-toast" onClick={this.downloadSampleData} href="!#"><i><FontAwesomeIcon icon={faDownload}/></i> Download the sample JSON for bulk upload </a></p>
                                <button type="submit" onClick={this.showFileUpload} className="btn btn-outline-primary btn-circle mr-2 mb-1" data-toggle="modal" data-target="#modal__upload-datasources">
                                    <FontAwesomeIcon icon={faCloudUploadAlt}/> Bulk-Add Datasources
                                </button>
                                <button id='integration_data_source' onClick={this.checkEnv} type="submit" className="btn btn-primary btn-circle action__to-show mb-1">
                                    <FontAwesomeIcon icon={faPlusCircle}/> Add Datasource
                                </button>
                            </div>
                        </div>
                </div>
                <Modal show={this.state.show}  centered onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">Datasources</h5>
                    </Modal.Header>
                     <form method="post" name='upload_integration' onSubmit={handleFileSubmit.bind(this)}  >
                    <Modal.Body>
                            <Dropzone accept=".json" onDrop={this.setFile}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div className="dropzone dropzone-file-area" {...getRootProps()}>
                                            <i className="fa">
                                                <FontAwesomeIcon icon={faCloudUploadAlt}/>
                                            </i>
                                            {this.state.file.length > 0 ? <><h3 className="sbold">{this.state.file[0].name} file has been selected </h3>
                                            <input {...getInputProps()} /><p>Click the Upload Datasources button.</p></>
                                            : <><h3 className="sbold">Drop files here or click to upload </h3>
                                            <input {...getInputProps()} />
                                            </>}
                                        </div>
                                        {this.state.file.length === 0 && this.state.uploadClick === true ? <div className="error-text text-center mt-2">Select file</div> : ''}
                                    </section>
                                )}
                            </Dropzone>
                    </Modal.Body>
                    <Modal.Footer className="d-block text-center">
                        <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle show-success-toast" data-dismiss="modal">
                          <FontAwesomeIcon icon={faCloudUploadAlt}/>  Upload Datasources</button>
                    </Modal.Footer>
                    </form>
                </Modal>
            </>
        );
    }
}

export default DSEnvironment;