import React from 'react';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { postDatasource } from "../../../../utils/event_handling";
import { userValidation } from "../../../../utils/common_utils"
import { TEST_CONNECTION_MESSAGES } from "../../../../utils/constant";
import { faEye, faLock, faEyeSlash,faPlusCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'react-bootstrap/InputGroup';
import Select from 'react-select';
import {faTimesCircle} from '@fortawesome/fontawesome-free-regular';
import RcTooltip from 'rc-tooltip';
import Image from '../../../../components/image.js';
import Alert from 'react-bootstrap/Alert';
//#Partition Code
//#Partition Code
const SpecificDataset = (props) => {
    if (props.clickedValue === undefined){
        return '';
    }
    
    let tablelist;
    tablelist = props.clickedValue.map(function(elem){ 
        return elem.table; }).join(",");
    tablelist = tablelist.split(",");

    return props.clickedValue.map((value,idx) => {
        let name = props.name;
        // if(props.clickedValue[idx][name] === undefined){
        let checked = props.clickedValue[idx]["match_exact"] === "match_exact" ? "checked" : "";
        return (
    <div className="dataset-partition-card">
                                       {(idx===0 && props.datasetToBeProcessed === "specifiedDataset" && props.editAction === false) ? "" : <i onClick={props.removeSpecificDataset.bind(null,idx,props.name,props.clickedValue[idx][name])} className="icon-close text-danger"><FontAwesomeIcon icon={faTimesCircle} /></i> }
                                          <div className="dp-card__content">
                                          { props.datasetToBeProcessed === "allDataset" ?
                                               <div>
                                               <span className="dp-card__label">Add Incremental Data Identification (Optional)</span>
                                               <i onClick={props.removeSpecificDataset.bind(null, idx, props.name, props.clickedValue[idx][name])} className="icon-close text-danger"> <FontAwesomeIcon icon={faTimesCircle} /></i>
                                               </div>
                                                :
                                                <span className="dp-card__label">Dataset {idx+1} to be processed</span>}
                                                <div className="form-row">
                                                      <Form.Group className="col-md-6 col-lg-4">
                                                            <Form.Label>Dataset Name 
                                                                {props.datasetToBeProcessed === "specifiedDataset" ?
                                                                <span class="text-danger">*</span> : "" }
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={
                                                                        <span>This Dataset will get partition.</span>
                                                                    }
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                    >
                                                                    <i><Image className="ml-1" width="16px" src="icon_info_circle" /></i>
                                                                </RcTooltip>
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  className={(props.clickedValue[idx]["table"]==="" || props.clickedValue[idx]["table"]===undefined  || (props.clickedValue[idx]["table"]!=="" && props.clickedValue[idx]["table"]!==undefined && tablelist.filter(x => x===props.clickedValue[idx]["table"]).length > 1))   ? "form-control is-invalid" : "form-control"}
                                                                  placeholder="Dataset Name"
                                                                  name={"specic_dataset_name_"+(idx+1)}
                                                                  id={"specic_dataset_name_"+(idx+1)}
                                                                  data-index={(idx)}
                                                                  data-key={"table"}
                                                                  value={((props.clickedValue[idx]["table"]==="" || props.clickedValue[idx]["table"]===undefined)) ? "" : props.clickedValue[idx]["table"]}
                                                                  onChange={(e) => {props.changeDatasetText(e)}}
                                                            />
                        {
                        (props.clickedValue[idx]["table"]==="" || props.clickedValue[idx]["table"]===undefined)  ? <div class="error-text">Dataset Name is required field</div> : ""
                      }
                       {
                        (props.clickedValue[idx]["table"]!=="" && props.clickedValue[idx]["table"]!==undefined &&  tablelist.filter(x => x===props.clickedValue[idx]["table"]).length > 1) ? <div class="error-text">Dataset Name  already added</div> : ""
                      }               
                                                                {checked !=="" ?
                                  
                                                            <Form.Text className="text-muted">
                                                                <Form.Check
                                                                    custom
                                                                    type="checkbox"
                                                                    label="Exact match only"
                                                                    id={"exact-match_"+(idx+1)}
                                                                    data-index={(idx)}
                                                                    data-key={"match_exact"}
                                                                    name={"specic_exact_match_"+(idx+1)}
                                                                    defaultChecked={(true)}
                                                                    onChange={(e) => {props.handleExactMatch(e)}}
                                                                />
                                                            </Form.Text>
                                                            :
                                                            <Form.Text className="text-muted">
                                                            <Form.Check
                                                                custom
                                                                type="checkbox"
                                                                label="Exact match only"
                                                                id={"exact-match_"+(idx+1)}
                                                                data-index={(idx)}
                                                                data-key={"match_exact"}
                                                                name={"specic_exact_match_"+(idx+1)}
                                                                onChange={(e) => {props.handleExactMatch(e)}}
                                                            />
                                                        </Form.Text>    
                                                        }
                                                      </Form.Group>
                                                      <Form.Group className="col-md-6 col-lg-4">
                                                            <Form.Label>Incremental Data Identifier</Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  className={(props.datasetToBeProcessed === "allDataset" || (props.datasetToBeProcessed === "specifiedDataset" && props.clickedValue[idx]["start_index_check_box"] === "true" && props.clickedValue[idx]["start_index_type"] === "incremental data identifier")) && (props.clickedValue[idx]["created_time"] === "" || props.clickedValue[idx]["created_time"] === undefined) ? "form-control is-invalid" : "form-control"}
                                                                  value={(props.clickedValue[idx]["created_time"]==="" || props.clickedValue[idx]["created_time"]===undefined) ? "" : props.clickedValue[idx]["created_time"]}
                                                                  data-index={(idx)}
                                                                  data-key={"created_time"}
                                                                  id={"specic_incremental_dataidentifier_"+(idx+1)}
                                                                  name={"specic_incremental_dataidentifier_"+(idx+1)}
                                                                  onChange={(e) => {props.changeDatasetText(e)}}
                                                                  placeholder="Incremental data identifier"
                                                            />
                                                     {(props.datasetToBeProcessed === "allDataset" || (props.datasetToBeProcessed === "specifiedDataset" && props.clickedValue[idx]["start_index_check_box"] === "true"&& props.clickedValue[idx]["start_index_type"] === "incremental data identifier" ))  &&
                                                                    (props.clickedValue[idx]["created_time"] === "" || props.clickedValue[idx]["created_time"] === undefined)
                                                                    ? <div class="error-text">Incremental Data Identifier is required field</div> : ""}
                                                      </Form.Group>


                                                    <Form.Group className="col-md-6 col-lg-4">
                                                        <div className="custom-control custom-checkbox mb-2">
                                                            <Form.Control  
                                                                id={"start_index_check_box_"+(idx+1)}
                                                                name={"start_index_check_box_"+(idx+1)}
                                                                data-index={(idx)}
                                                                data-key={"start_index_check_box"}
                                                                type="checkbox"
                                                                checked={props.clickedValue[idx]["start_index_check_box"]=== "true" ? true : false}
                                                                className="custom-control-input"
                                                                onChange={(e) => {props.handleStartIndex(e)}}
                                                               />
                                                            <Form.Label className="custom-control-label" htmlFor={"start_index_check_box_"+(idx+1)}>
                                                                Starting Index (Optional)
                                                            </Form.Label>
                                                        </div>
                                                        <div>
                                                            {/* <Form.Check 
                                                            value={"incremental data identifier"}
                                                            checked={props.clickedValue[idx]["start_index_type"]==="incremental data identifier"}
                                                            disabled={(props.clickedValue[idx]["start_index_check_box"]=== "false" || props.clickedValue[idx]["start_index_check_box"]=== undefined)? "disabled" : ""}
                                                            custom inline label="By Value" 
                                                            name={idx+"startindex_radio"} 
                                                            type="radio"
                                                            onChange={(e) => {props.handleStartIndexRadio(e)}}
                                                            id={idx+"startindex_radio_option1"} 
                                                            data-index={(idx)}
                                                            data-key={"start_index_type"}
                                                            />
                                                            <RcTooltip
                                                                    placement="top"
                                                                    overlay={
                                                                        <span>This Dataset will get partition.</span>
                                                                    }
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                    >
                                                                    <i><Image className="ml-1" width="16px" src="icon_info_circle" /></i>
                                                                </RcTooltip> */}

<div className="custom-control custom-radio">
                                <input 
                                                                                           value={"incremental data identifier"}
                                                                                           checked={props.clickedValue[idx]["start_index_type"]==="incremental data identifier"}
                                                                                           disabled={(props.clickedValue[idx]["start_index_check_box"]=== "false" || props.clickedValue[idx]["start_index_check_box"]=== undefined)? "disabled" : ""}
                                                                                           custom inline label="By Value" 
                                                                                           name={idx+"startindex_radio"} 
                                                                                           type="radio"
                                                                                           onChange={(e) => {props.handleStartIndexRadio(e)}}
                                                                                           id={idx+"startindex_radio_option1"} 
                                                                                           data-index={(idx)}
                                                                                           data-key={"start_index_type"}
                               
                                className="custom-control-input" />
                                <label className="custom-control-label" htmlFor={idx+"startindex_radio_option1"}>
                                By Value

                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={
                                                                        <span>The value in Incremental Data Identifier which will be used as Starting Index"</span>
                                                                    }
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                    >
                                                                    <i><Image className="ml-1" width="16px" src="icon_info_circle" /></i>
                                                                </RcTooltip>

                                </label>
                            </div>    

                            <div className="custom-control custom-radio">
                                <input 
                                                            checked={props.clickedValue[idx]["start_index_type"]==="custom query"}
                                                            value={"custom query"}
                                                            disabled={(props.clickedValue[idx]["start_index_check_box"]=== "false" || props.clickedValue[idx]["start_index_check_box"]=== undefined) ? "disabled" : ""}
                                                            custom inline label="By Query" 
                                                            name={idx+"startindex_radio"} 
                                                            type="radio" 
                                                            onChange={(e) => {props.handleStartIndexRadio(e)}}
                                                            id={idx+"startindex_radio_option2"} 
                                                            data-index={(idx)}
                                                            data-key={"start_index_type"}

                                className="custom-control-input" />
                                <label className="custom-control-label" htmlFor={idx+"startindex_radio_option2"}>
                                By Query

                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={
                                                                        <span>The SQL condition/query which will be used for Starting Index</span>
                                                                    }
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                    >
                                                                    <i><Image className="ml-1" width="16px" src="icon_info_circle" /></i>
                                                                </RcTooltip>

                                </label>
                            </div>


                                                            {/* <Form.Check 
                                                            checked={props.clickedValue[idx]["start_index_type"]==="custom query"}
                                                            value={"custom query"}
                                                            disabled={(props.clickedValue[idx]["start_index_check_box"]=== "false" || props.clickedValue[idx]["start_index_check_box"]=== undefined) ? "disabled" : ""}
                                                            custom inline label="By Query" 
                                                            name={idx+"startindex_radio"} 
                                                            type="radio" 
                                                            onChange={(e) => {props.handleStartIndexRadio(e)}}
                                                            id={idx+"startindex_radio_option2"} 
                                                            data-index={(idx)}
                                                            data-key={"start_index_type"}
                                                            />
                                                            <RcTooltip
                                                                    placement="top"
                                                                    overlay={
                                                                        <span>This Dataset will get partition.</span>
                                                                    }
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                    >
                                                                    <i><Image className="ml-1" width="16px" src="icon_info_circle" /></i>
                                                                </RcTooltip> */}
                                                        </div>
                                                    </Form.Group>
{(props.clickedValue[idx]["start_index_check_box"]=== "false" || props.clickedValue[idx]["start_index_check_box"]=== undefined) ?
"" : props.clickedValue[idx]["start_index_type"]=== "incremental data identifier" || props.clickedValue[idx]["start_index_type"]=== undefined ? 
                                                      <Form.Group className="col-md-6 col-lg-4">
                                                    <Form.Label>Starting Index Value</Form.Label>
                                                    <Form.Control
                                                            type="text"
                                                            value={(props.clickedValue[idx]["start_with"]==="" || props.clickedValue[idx]["start_with"]===undefined) ? "" : props.clickedValue[idx]["start_with"]}
                                                            data-index={(idx)}
                                                            disabled={(props.datasetToBeProcessed === "specifiedDataset" || props.datasetToBeProcessed === "allDataset" ) && (props.clickedValue[idx]["created_time"]==="" || props.clickedValue[idx]["created_time"]===undefined)  ? "disabled" : ""}
                                                            data-key={"start_with"}
                                                            className={((props.clickedValue[idx]["created_time"] !=="" && props.clickedValue[idx]["created_time"]!==undefined) && props.clickedValue[idx]["start_with"] === "")? "form-control is-invalid" : "form-control"} 
                                                            name={"start_with_"+(idx+1)}
                                                            placeholder={"Starting Index Value"}
                                                            onChange={(e) => {props.changeDatasetText(e)}}
                                                    />
                                                    {((props.clickedValue[idx]["created_time"] !=="" && props.clickedValue[idx]["created_time"]!==undefined) && props.clickedValue[idx]["start_with"] === "")? <div class="error-text">Starting Index Value is required field</div> : ""}
                                                </Form.Group> 
                                                :
                                                
                                                            <Form.Group className="col-md-12 col-lg-8">
                                                            <Form.Label>Starting Index Query</Form.Label>
                                                            <Form.Control
                                                                  as="textarea" 
                                                                  data-index={(idx)}
                                                                  data-key={"start_with_query"}
                                                                  name={"start_with_query"+(idx+1)}
                                                                  className={( props.clickedValue[idx]["start_with_query"] === "")? "form-control is-invalid" : "form-control"}
                                                                  rows={2}
                                                                //   disabled={(props.datasetToBeProcessed === "specifiedDataset" || props.datasetToBeProcessed === "allDataset" ) && (props.clickedValue[idx]["created_time"]==="" || props.clickedValue[idx]["created_time"]===undefined)  ? "disabled" : ""}
                                                                  value={(props.clickedValue[idx]["start_with_query"]==="" || props.clickedValue[idx]["start_with_query"]===undefined) ? "" : props.clickedValue[idx]["start_with_query"]}
                                                                  placeholder="Starting Index Query"
                                                                  onChange={(e) => {props.changeDatasetText(e)}}
                                                            />
                                                            {(props.clickedValue[idx]["start_with_query"] === "")? <div class="error-text">Starting Index query is required field</div> : ""}
                                                            <Form.Text className="text-muted"><strong className="mr-1">Note:</strong>This query will be used for Starting Index.</Form.Text>
                                                      </Form.Group>
                                            }
                                                </div>
                                          </div>
    </div>)
  //}
    });
}
  
  const Partitions = (props) => {
      if (props.clickedValue === undefined){
          return '';
      }
  
      let tablelist;
      tablelist = props.clickedValue.map(function(elem){ 
          return elem.dataset_name; }).join(",");
      tablelist = tablelist.split(",");
  
  
      return props.clickedValue.map((value,idx) => {
          let name = props.name;
          let filterdatasetlist =
          props.partitiondatasetlist.filter(d =>
          d.value === props.clickedValue[idx]["dataset_name"]
          ).length
      return (<div className="dataset-partition-card">
          <i className="icon-close text-danger" onClick={props.removePartition.bind(null,idx,props.name,props.clickedValue[idx][name])}><FontAwesomeIcon icon={faTimesCircle} /></i>
      <div className="dp-card__content">
            <span className="dp-card__label">Partition {idx+1}</span>
            <div className="form-row">
              {props.datasetToBeProcessed === "allDataset" ?
                  <Form.Group className="col-md-6 col-lg-4">
                        <Form.Label>Dataset Name</Form.Label>
                        <Form.Control
                              type="text"
                              data-index={(idx)}
                              data-key={"dataset_name"}
                              // className="form-control"
                              value={props.clickedValue[idx]["dataset_name"]==="" || props.clickedValue[idx]["dataset_name"]===undefined ? "" : props.clickedValue[idx]["dataset_name"]}
                              className={((props.clickedValue[idx]["dataset_name"]==="" || props.clickedValue[idx]["dataset_name"]===undefined) || (props.clickedValue[idx]["dataset_name"]!=="" && props.clickedValue[idx]["dataset_name"]!==undefined &&  tablelist.filter(x => x===props.clickedValue[idx]["dataset_name"]).length > 1)) ? "form-control is-invalid" : "form-control"}
                              name={"dataset_name_"+(idx+1)}
                              placeholder="Dataset Name"
                              onChange={(e) => {props.changePartitionText(e)}}
                        />
                        {
                          props.clickedValue[idx]["dataset_name"]==="" || props.clickedValue[idx]["dataset_name"]===undefined ? <div class="error-text">Dataset Name is required field</div> : ""
                        }
                        {
                          (props.clickedValue[idx]["dataset_name"]!=="" && props.clickedValue[idx]["dataset_name"]!==undefined &&  tablelist.filter(x => x===props.clickedValue[idx]["dataset_name"]).length > 1) ? <div class="error-text">Dataset Name  already added</div> : ""
                        }  
                  </Form.Group> :
                  <Form.Group className="col-md-6 col-lg-4">
                  <Form.Label>Select Dataset</Form.Label>
                  <Select
                              name={"dataset_name_"+(idx+1)}
                              data-index={(idx)}
                              value={props.clickedValue[idx]["dataset_name"]==="" || props.clickedValue[idx]["dataset_name"]===undefined || filterdatasetlist ===0 ? "" : { label: props.clickedValue[idx]["dataset_name"], value: props.clickedValue[idx]["dataset_name"] }}
                              data-key={"dataset_name"}
                              className={props.clickedValue[idx]["dataset_name"]==="" || props.clickedValue[idx]["dataset_name"]===undefined || filterdatasetlist ===0 ? "select-control is-invalid" : "select-control"}
                              filterOption={({label}, query) => label.includes(query)}
                              id={"dataset_name_"+(idx+1)}
                              onChange={(e) => {props.changePartitionSelect(e,"dataset_name",idx)}}
                              options={props.partitiondatasetlist}
                              placeholder="Dataset"
                          />
                          {
                          props.clickedValue[idx]["dataset_name"]==="" || props.clickedValue[idx]["dataset_name"]===undefined || filterdatasetlist ===0 ? <div class="error-text">Dataset Name is required field</div> : ""
                        }
                        {
                          (props.clickedValue[idx]["dataset_name"]!=="" && props.clickedValue[idx]["dataset_name"]!==undefined &&  tablelist.filter(x => x===props.clickedValue[idx]["dataset_name"]).length > 1) ? <div class="error-text">Dataset Name is already selected</div> : ""
                        }
                          
                          </Form.Group> }
                  
                  <Form.Group className="col-md-6 col-lg-4">
                        <Form.Label>Partition Name</Form.Label>
                        <Form.Control
                              type="text"
                              data-index={(idx)}
                              data-key={"partition_name"}
                              value={props.clickedValue[idx]["partition_name"]==="" || props.clickedValue[idx]["partition_name"]===undefined ? "" : props.clickedValue[idx]["partition_name"]}
                              className={props.clickedValue[idx]["partition_name"]==="" || props.clickedValue[idx]["partition_name"]===undefined ? "form-control is-invalid" : "form-control"}
                              name={"partition_name_"+(idx+1)}
                              onChange={(e) => {props.changePartitionText(e)}}
                              placeholder="Partition Name"
                        />
                        {
                          props.clickedValue[idx]["partition_name"]==="" || props.clickedValue[idx]["partition_name"]===undefined ? <div class="error-text">Partition Name is required field</div> : ""
                        }
                  </Form.Group>
                  <Form.Group className="col-md-6 col-lg-4">
                        <Form.Label>Partition By</Form.Label>
                        <div>
  <div className="custom-control custom-radio custom-control-inline mt-1">
                                                      <Form.Control
                                                              onChange={selectedOption => {
                                                              props.changePartitionBy(selectedOption.currentTarget.name,(idx+1),selectedOption.currentTarget.value);
                                                              }}
                                                            type="radio"
                                                            data-key={"partition_by"}
                                                            id={"partitionByColumn"+(idx+1)}
                                                            name={"partitionBy"+(idx+1)}
                                                            value="Column"
                                                            checked={(value["partitionBy"+(parseInt(idx)+parseInt(1))] === "Column" || value["partitionBy"+(parseInt(idx)+parseInt(1))] === undefined ) ? true : false}
                                                            className="custom-control-input" />
                                                      <Form.Label className="custom-control-label" htmlFor={"partitionByColumn"+(idx+1)}>Column</Form.Label>
  </div>
  <div className="custom-control custom-radio custom-control-inline mt-1">
                                                      <Form.Control
                                                           onChange={selectedOption => {
                                                          props.changePartitionBy(selectedOption.currentTarget.name,(idx+1),selectedOption.currentTarget.value);
                                                          }}
                                                            type="radio"
                                                            data-key={"partition_by"}
                                                            id={"partitionByCustom"+(idx+1)}
                                                            name={"partitionBy"+(idx+1)}
                                                            value="Custom Query"
                                                            disabled={"disabled"}
                                                            checked={value["partitionBy"+(parseInt(idx)+parseInt(1))] === "Custom Query" ? true : false}
                                                            className="custom-control-input" />
                                                      <Form.Label className="custom-control-label" htmlFor={"partitionByCustom"+(idx+1)}>Custom Query</Form.Label>
  </div>
  </div>
                  </Form.Group>
                  {(value["partitionBy"+(parseInt(idx)+parseInt(1))] === "Column" || value["partitionBy"+(parseInt(idx)+parseInt(1))] === undefined) ?      
                  <Form.Group className="col-md-6 col-lg-4">
                        <Form.Label>Partition Identifier</Form.Label>
                        <Form.Control
                              type="text"
                              data-index={(idx)}
                              data-key={"column_name"}
                              value={props.clickedValue[idx]["column_name"]==="" || props.clickedValue[idx]["column_name"]===undefined ? "" : props.clickedValue[idx]["column_name"]}
                              className={props.clickedValue[idx]["column_name"]==="" || props.clickedValue[idx]["column_name"]===undefined ? "form-control is-invalid" : "form-control"}
                              placeholder="Partition Identifier"
                              name={"partition_identifier_with_"+(idx+1)}
                              onChange={(e) => {props.changePartitionText(e)}}
                        />
                        {
                          props.clickedValue[idx]["column_name"]==="" || props.clickedValue[idx]["column_name"]===undefined ? <div class="error-text">Partition Identifier is required field</div> : ""
                        }
  
                  </Form.Group> :"" }
                   {value["partitionBy"+(parseInt(idx)+parseInt(1))] === "Custom Query" ?      
                  <Form.Group className="col-md-12 col-lg-8">
                                                              <Form.Label>Custom Query</Form.Label>
                                                              <Form.Control
                                                                    as="textarea" 
                                                                    rows={2}
                                                                    name="custom_query"
                                                                    placeholder="Enter your custom query here..."
                                                              />
                                                              <Form.Text className="text-muted"><strong className="mr-1">Note:</strong>This query will be used to partition and monitor the Dataset.</Form.Text>
                                                        </Form.Group> : "" }
            </div>
      </div>
  </div>)
  })
  }
  //#Partition Code  
class AzureSynapseDSForm extends React.Component {
      constructor(props) {
            super(props);
            this.fill_details = this.fill_details.bind(this);
            this.postDatasource = postDatasource.bind(this);
            this.postAzureData = this.postAzureData.bind(this);
            this.disableButton = this.disableButton.bind(this);
            this.ChangesecretKeyIcon = this.ChangesecretKeyIcon.bind(this);
            //#Partition Code
            this.handleDatasetChange = this.handleDatasetChange.bind(this);
            this.addPartition = this.addPartition.bind(this);
            this.removePartition = this.removePartition.bind(this);
            this.addSpecificDataset = this.addSpecificDataset.bind(this);
            this.removeSpecificDataset = this.removeSpecificDataset.bind(this);
            this.changePartitionBy = this.changePartitionBy.bind(this);
            this.changePartitionText = this.changePartitionText.bind(this);
            this.changeDatasetText = this.changeDatasetText.bind(this);
            this.changePartitionSelect = this.changePartitionSelect.bind(this);
            this.handleExactMatch = this.handleExactMatch.bind(this) 
            this.handleStartIndex = this.handleStartIndex.bind(this)
            this.handleStartIndexRadio = this.handleStartIndexRadio.bind(this)
               
            this.state = {
                  formType_option: null,
                  isChecked: true,
                  hideSubmitButton: false,
                  selectOptionKey: "emptyFileType",
                  integration_data: this.props.integration_data,
                  integration_data_edit: "",
                  secretKeyIcon: faEye,
                  secretKeyType: 'password',
                  //#Partition Code
                  datasetToBeProcessed:"allDataset",
                  partition:[],
                  specificDatasets:[],
                  //specificDatasets:[{"table":"","created_time":"","start_with":"","match_exact":"match_exact"}],
                  default_partition_by:"Column",
                  partitiondatasetlist:[],
                  dataset_processed_edit:true,
                  selectedPartitionRemoved:null
            };
      }
      componentDidMount() {
            this.fill_details();
           //#Partition Code
           if(this.props.ex_integration_details!==null){
            let partitiondatasetlist = [];
            if(this.props.ex_integration_details.integration_config.table_names!==undefined){
                for(let loop=0;loop<this.props.ex_integration_details.integration_config.table_names.length;loop++){
                    let selectobj = {};
                    selectobj.label= this.props.ex_integration_details.integration_config.table_names[loop]["table"];
                    selectobj.value= this.props.ex_integration_details.integration_config.table_names[loop]["table"];
                    partitiondatasetlist[loop] = selectobj;
               }
                     
let elems = document.querySelectorAll("#edit_ds_model .partition_alert_info");
[].forEach.call(elems, function(el) {
    el.classList.remove("d-none");
});

            }
        this.setState({
                partitiondatasetlist:partitiondatasetlist,
                datasetToBeProcessed:this.props.ex_integration_details.integration_config.datasetToBeProcessed === undefined ? "allDataset" : this.props.ex_integration_details.integration_config.datasetToBeProcessed,
                dataset_processed_edit : this.props.ex_integration_details.integration_config.datasetToBeProcessed === undefined ? true : false,
                //datasetToBeProcessed:this.props.ex_integration_details.integration_config.datasetToBeProcessed,
                specificDatasets:this.props.ex_integration_details.integration_config.table_names,
                partition:this.props.ex_integration_details.integration_config.partitions
        });
        if(this.props.ex_integration_details.integration_config.datasetToBeProcessed === undefined){
            this.setState((prevState) => ({
                partition:[],
                selectedPartitionRemoved:null,
                partitiondatasetlist:[],
                specificDatasets:[]
                //specificDatasets: [{"table":"","created_time":"","start_with":"","match_exact":"match_exact"}]
            }))
        }
        }
        //#Partition Code
        }
      changePartitionBy(name,idx,value){
            let partition = [...this.state.partition];
            partition[idx-1][name] = value;
            this.setState({partition:partition});
        }
        changeDatasetText(e){
            let specificDatasets = [...this.state.specificDatasets];
            let partitiondatasetlist = [...this.state.partitiondatasetlist];
            let partition = [...this.state.partition]
            let selectedPartitionRemoved = this.state.selectedPartitionRemoved
            
            if(e.currentTarget.getAttribute("data-key")==="table"){
                let selectobj = {};
                selectobj.label= e.currentTarget.value;
                selectobj.value= e.currentTarget.value;
                partitiondatasetlist[e.currentTarget.getAttribute("data-index")] = selectobj;
            }
            if (this.state.datasetToBeProcessed === "specifiedDataset"){
            if(e.currentTarget.getAttribute("data-key")==="created_time"){
                if(e.currentTarget.value===""){
                  specificDatasets[e.currentTarget.getAttribute("data-index")]["start_with"] = "";
                }
            }}    
    
            if(e.currentTarget.getAttribute("data-key")==="start_with_query"){
                specificDatasets[e.currentTarget.getAttribute("data-index")]["start_with"] = "";
            }
            if(e.currentTarget.getAttribute("data-key")==="start_with"){
                specificDatasets[e.currentTarget.getAttribute("data-index")]["start_with_query"] = "";
            } 
    
            specificDatasets[e.currentTarget.getAttribute("data-index")][e.currentTarget.getAttribute("data-key")] = e.currentTarget.value;
            specificDatasets[e.currentTarget.getAttribute("data-index")]["partition_type"] = "column";
            
            this.setState({
                specificDatasets:specificDatasets,
                partitiondatasetlist:partitiondatasetlist});

            let partitionList = []
            if (this.state.datasetToBeProcessed === "specifiedDataset"){
                for (let loop=0;loop<specificDatasets.length;loop++)  {
                    partitionList.push(specificDatasets[loop]['table'])
                }

                for(let loop=0;loop<partition.length;loop++){
                    if (!partitionList.includes(partition[loop]['dataset_name'])){
                        selectedPartitionRemoved = false
                    }
                }

                this.setState({selectedPartitionRemoved:selectedPartitionRemoved});
            }
        }
    
        changePartitionSelect(e,key,index){
            let partition = [...this.state.partition];
            partition[index][key] = e.value;
            partition[index]["partition_type"] = "column";
            this.setState({partition:partition,
                        selectedPartitionRemoved:false});
        }
        changePartitionText(e){
            let partition = [...this.state.partition];
            partition[e.currentTarget.getAttribute("data-index")][e.currentTarget.getAttribute("data-key")] = e.currentTarget.value;
            partition[e.currentTarget.getAttribute("data-index")]["partition_type"] = "column";
            this.setState({partition:partition});
        }
        handleExactMatch(e){
            let specificDatasets = [...this.state.specificDatasets];
            specificDatasets[e.currentTarget.getAttribute("data-index")][e.currentTarget.getAttribute("data-key")] = e.target.checked === true ? "match_exact" : "";
            this.setState({specificDatasets:specificDatasets});
        }
        handleStartIndex(e){
            let specificDatasets = [...this.state.specificDatasets];
            specificDatasets[e.currentTarget.getAttribute("data-index")][e.currentTarget.getAttribute("data-key")] = e.target.checked === true ? "true" : "false";
            if(e.target.checked){
                specificDatasets[e.currentTarget.getAttribute("data-index")]["start_index_type"] = "incremental data identifier";
            }else {
                specificDatasets[e.currentTarget.getAttribute("data-index")]["start_index_type"] = "";
            }
            this.setState({specificDatasets:specificDatasets});
        }
    
    
        handleStartIndexRadio(e){
            let specificDatasets = [...this.state.specificDatasets];
            specificDatasets[e.currentTarget.getAttribute("data-index")][e.currentTarget.getAttribute("data-key")] = e.target.value;
            this.setState({specificDatasets:specificDatasets});
        }
        handleDatasetChange(e){
            if(e.target.value==="specifiedDataset"){
            // let tempData = null
                // let idx;
                //tempData = Object.assign({});
                // idx = this.state.specificDatasets.length-1;
                    this.setState((prevState) => ({
                        //specificDatasets: [...prevState.specificDatasets, tempData]
                        partition:[],
                        partitiondatasetlist:[],
                        specificDatasets: [{"table":"","created_time":"","start_with":"","match_exact":"match_exact","start_with_query":"","start_index_check_box":"false","start_index_type":""}]
                    }))
                }else {
                //  let tempData = null
                    // let idx;
                    //tempData = Object.assign({});
                    this.setState((prevState) => ({
                        partition:[],
                        partitiondatasetlist:[],
                        specificDatasets:[]
                       // specificDatasets: [{"table":"","created_time":"","start_with":"","match_exact":"match_exact"}]
                    }))
                }
                this.setState({datasetToBeProcessed:e.target.value});
        }
    
        removePartition = (idx,name) => {
            this.state.partition.splice(idx,1)
            this.setState({partition:this.state.partition});
        }
    
        addSpecificDataset(e){
            e.preventDefault();
            let checkpartitionvalidation = true;
            let specificDatasets = this.state.specificDatasets;
            if (this.state.datasetToBeProcessed === "specifiedDataset"){
                for(let loop=0;loop<specificDatasets.length;loop++){
                    if(specificDatasets[loop]['table']==="" ||
                    specificDatasets[loop]['table']=== undefined ||
                    (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "incremental data identifier" && specificDatasets[loop]['start_with']=== undefined) ||
                    (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "custom query" && specificDatasets[loop]['start_with_query']=== undefined) ||
                    (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "incremental data identifier" && specificDatasets[loop]['start_with']=== "") ||
                    (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "custom query" && specificDatasets[loop]['start_with_query']=== "")
                    ){
                        checkpartitionvalidation = false;
                        break;
                    }
                }
            }
            else {
    
    
                for(let loop=0;loop<specificDatasets.length;loop++){
    
                    if(specificDatasets[loop]['table']==="" ||
                    specificDatasets[loop]['table']=== undefined ||
                    specificDatasets[loop]['created_time']==="" ||
                    specificDatasets[loop]['created_time']=== undefined ||
                    (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "incremental data identifier" && specificDatasets[loop]['start_with']=== undefined) ||
                    (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "custom query" && specificDatasets[loop]['start_with_query']=== undefined) ||
                    (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "incremental data identifier" && specificDatasets[loop]['start_with']=== "") ||
                    (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "custom query" && specificDatasets[loop]['start_with_query']=== "")
                    ){
                        checkpartitionvalidation = false;
                        break;
                    }
                }
            }
            if(checkpartitionvalidation===true){
            let tempData = null
            // tempData = Object.assign({});
              tempData  = {"table":"","created_time":"","start_with":"","match_exact":"match_exact","start_with_query":"","start_index_check_box":"false","start_index_type":"incremental data identifier"}
            // idx = this.state.specificDatasets.length-1;
                this.setState((prevState) => ({
                    specificDatasets: [...prevState.specificDatasets, tempData]
                }));
    
            }
        }
    
        removeSpecificDataset  = (idx,name) => {
            this.state.specificDatasets.splice(idx,1)
            this.state.partitiondatasetlist.splice(idx,1)
            this.setState({specificDatasets:this.state.specificDatasets,
                partitiondatasetlist:this.state.partitiondatasetlist
            });
        }
    
        addPartition(e){
            if(this.state.datasetToBeProcessed === "specifiedDataset"){
                for(let loop=0;loop<this.state.specificDatasets.length;loop++){
                    if(this.state.specificDatasets[loop]["table"]===""){
                        document.getElementById("specic_dataset_name_"+(loop+1)).focus()
                         return;
                    }else if(this.state.selectedPartitionRemoved === true){
                        return;
                    }
                }
            }
    
            let partitiondata = this.state.partition;
            let checkpartitionvalidation = true;
            for(let loop=0;loop<partitiondata.length;loop++){
                if(partitiondata[loop]['dataset_name']==="" ||
                   partitiondata[loop]['dataset_name']=== undefined ||
                   partitiondata[loop]['partition_type']==="" ||
                   partitiondata[loop]['partition_type']=== undefined ||
                   partitiondata[loop]['partition_name']=== undefined ||
                   partitiondata[loop]['partition_name']=== "" ||
                   partitiondata[loop]['column_name']==="" ||
                   partitiondata[loop]['column_name']=== undefined 
                ){
                    if(partitiondata.length!==0){
                        checkpartitionvalidation = false;
                        break;
                    }
                }
            }
    
            if(checkpartitionvalidation === true){    
            let tempData = null
            // let idx;
            tempData = Object.assign({});
            // idx = this.state.partition.length-1;
                this.setState((prevState) => ({
                    partition: [...prevState.partition, tempData]
                }));
            }
        }
    
    //#Partition Code
    
      toggleChange = () => {
            this.setState({ isChecked: !this.state.isChecked });
      }

      fill_details() {
            let exIntegrationDetails = this.props.ex_integration_details;

            if ( exIntegrationDetails !== undefined && exIntegrationDetails !== null) {
                  let integration_data = this.state.integration_data;
                  integration_data.integration_name = exIntegrationDetails["integration_name"];
                  integration_data.integration_id = exIntegrationDetails["integration_id"];

                  let exIntegrationConfig = exIntegrationDetails.integration_config;
                  integration_data.error_storage = exIntegrationConfig.error_storage;
                  integration_data.created_time = exIntegrationConfig.created_time;

                  let defaultErrorStorage = exIntegrationConfig.default_error_storage;
                  if (defaultErrorStorage === undefined) {
                        defaultErrorStorage = true;
                  }

                  this.setState({ isChecked: defaultErrorStorage });

                  integration_data.synapse_table_name = exIntegrationConfig.synapse_table_name;
                  integration_data.database_name = exIntegrationConfig.database_name;
                  integration_data.schema = exIntegrationConfig.schema;
                  integration_data.synapse_sql_host = exIntegrationConfig.synapse_sql_host;

                  let exAuthConfig = exIntegrationDetails.auth_config;
                  integration_data.synapse_password = exAuthConfig.synapse_password;
                  integration_data.synapse_user = exAuthConfig.synapse_user;

                  this.setState({
                        integration_type: this.props.formType,
                        integration_data_edit: integration_data
                  });
            }

      }

      postAzureData(event) {
        let partitiondata = this.state.partition;
        let datasetToBeProcessed= this.state.datasetToBeProcessed;
       let specificDatasets = this.state.specificDatasets;
       let selectedPartitionRemoved = this.state.selectedPartitionRemoved;
       let checkpartitionvalidation = true;

       let tablelistparition,incrementaldataset;
       tablelistparition = partitiondata.map(function(elem){ 
                   return elem.dataset_name; }).join(",");
       if(tablelistparition!==""){
           tablelistparition = tablelistparition.split(",");
       }

       incrementaldataset = specificDatasets.map(function(elem){ 
                       return elem.table; }).join(",");
           if(incrementaldataset!==""){
           incrementaldataset = incrementaldataset.split(",");   
           }
           if (datasetToBeProcessed === 'specifiedDataset'){
            if(specificDatasets.length === 0){
                document.getElementById("addSpecificDataset").click();
                return;                   
            }
               for(let loop=0;loop<specificDatasets.length;loop++){
                   if(specificDatasets[loop]['table']==="" ||
              //     specificDatasets[loop]['table']=== undefined || 
                   selectedPartitionRemoved === true ||
                   (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "incremental data identifier" && specificDatasets[loop]['start_with']=== undefined) ||
                   (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "custom query" && specificDatasets[loop]['start_with_query']=== undefined) ||
                   (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "incremental data identifier" && specificDatasets[loop]['start_with']=== "") ||
                   (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "custom query" && specificDatasets[loop]['start_with_query']=== "")
                   ){
                       checkpartitionvalidation = false;
                       break;
                   }
                   else {
                   if(incrementaldataset.filter(x => x===specificDatasets[loop]["table"]).length > 1) {
                       checkpartitionvalidation = false;
                       break;
                   }
               }
           }
           }
           else {
               for(let loop=0;loop<specificDatasets.length;loop++){
                   if(specificDatasets[loop]['table']==="" ||
                   // specificDatasets[loop]['table']=== undefined ||
                   specificDatasets[loop]['created_time']==="" ||
                   // specificDatasets[loop]['created_time']=== undefined
                    (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "incremental data identifier" && specificDatasets[loop]['start_with']=== undefined) ||
                    (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "custom query" && specificDatasets[loop]['start_with_query']=== undefined) ||
                   (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "incremental data identifier" && specificDatasets[loop]['start_with']=== "") ||
                   (specificDatasets[loop]['start_index_check_box']=== "true" &&  specificDatasets[loop]['start_index_type']=== "custom query" && specificDatasets[loop]['start_with_query']=== "")
                   ){
                       checkpartitionvalidation = false;
                       break;
                   }
                   else {
                   if(incrementaldataset.filter(x => x===specificDatasets[loop]["table"]).length > 1) {
                       checkpartitionvalidation = false;
                       break;
                   }
               }
           }
           }
   
        
       for(let loop=0;loop<partitiondata.length;loop++){
           if(partitiondata[loop]['dataset_name']==="" ||
              partitiondata[loop]['dataset_name']=== undefined ||
              partitiondata[loop]['partition_type']==="" ||
              partitiondata[loop]['partition_type']=== undefined ||
              partitiondata[loop]['partition_name']=== undefined ||
              partitiondata[loop]['partition_name']=== "" ||
              partitiondata[loop]['column_name']==="" ||
              partitiondata[loop]['column_name']=== undefined 
           ){
               checkpartitionvalidation = false;
               break;
           }else {
               if(tablelistparition.filter(x => x===partitiondata[loop]["dataset_name"]).length > 1) {
                   checkpartitionvalidation = false;
                   break;
               }
           } 
       }

       // Partition/Dataset Chcek Already Saved Connection
       if (this.props.ex_integration_details !== null) {
           let paritioneditcheck = true;
           let elems = document.querySelectorAll("#edit_ds_model .partition_alert_check");
           [].forEach.call(elems, function(el) {
               el.classList.add("d-none");
           });
           if (this.props.ex_integration_details.integration_config.table_names !== undefined) {
               let table_names = this.props.ex_integration_details.integration_config.table_names;
               if (table_names.length > 0) {
                   table_names = this.props.ex_integration_details.integration_config.table_names;
                   for (let loop = 0; loop < table_names.length; loop++) {
                       if (table_names.length <= specificDatasets.length) {
                           if (specificDatasets[loop]['table'] !== table_names[loop]['table'] ||
                               specificDatasets[loop]['start_with'] !== table_names[loop]['start_with'] ||
                               specificDatasets[loop]['partition_type'] !== table_names[loop]['partition_type'] ||
                               specificDatasets[loop]['match_exact'] !== table_names[loop]['match_exact'] ||
                               specificDatasets[loop]['created_time'] !== table_names[loop]['created_time'] ||
                               specificDatasets[loop]['start_with_query'] !== table_names[loop]['start_with_query']

                           ) {
                               paritioneditcheck = false;
                               let elems = document.querySelectorAll("#edit_ds_model .partition_alert_check");
                               [].forEach.call(elems, function(el) {
                                   el.classList.remove("d-none");
                               });
                           }
                       } else {
                           paritioneditcheck = false;
                           let elems = document.querySelectorAll("#edit_ds_model .partition_alert_check");
                           [].forEach.call(elems, function(el) {
                               el.classList.remove("d-none");
                           });
                       }
                   }
               }
           }
           if (this.props.ex_integration_details.integration_config.partitions !== undefined) {
               let edit_partition_list = this.props.ex_integration_details.integration_config.partitions;
               if (edit_partition_list.length > 0) {
                   edit_partition_list = this.props.ex_integration_details.integration_config.partitions;
                   for (let loop = 0; loop < edit_partition_list.length; loop++) {
                       if (edit_partition_list.length <= partitiondata.length) {
                           if (partitiondata[loop]['dataset_name'] !== edit_partition_list[loop]['dataset_name'] ||
                               partitiondata[loop]['partition_type'] !== edit_partition_list[loop]['partition_type'] ||
                               partitiondata[loop]['partition_name'] !== edit_partition_list[loop]['partition_name'] ||
                               partitiondata[loop]['column_name'] !== edit_partition_list[loop]['column_name']
                           ) {
                               paritioneditcheck = false;
                               let elems = document.querySelectorAll("#edit_ds_model .partition_alert_check");
                               [].forEach.call(elems, function(el) {
                                   el.classList.remove("d-none");
                               });
                           }
                       } else {
                           paritioneditcheck = false;
                           let elems = document.querySelectorAll("#edit_ds_model .partition_alert_check");
                           [].forEach.call(elems, function(el) {
                               el.classList.remove("d-none");
                           });
                       }
                   }
               }
           }
           if (!paritioneditcheck) {
               let partioncheck = document.getElementById("partion-checkbox");
               if (partioncheck.checked === false) {
                   partioncheck.focus()
                   return;
               }
           }
       }
       // End Partition/Dataset Chcek Already Saved Connection    
       if(!checkpartitionvalidation){
           return;
       }
       this.disableButton();
            const { integration_name, error_storage, 
                  synapse_user, synapse_password, synapse_table_name,
                  created_time, 
                  database_name, synapse_sql_host, schema } = event;

            let azureData = {
                  "integration_name": integration_name,
                  "integration_id": this.state.integration_data.integration_id,
                  "data_store_type": "filesystem",
                  "error_storage": error_storage,
                  "env_id": this.props.env_id,
                  "created_time": created_time,
                  "partitions":this.state.partition,
                  "table_names":specificDatasets,
                  "datasetToBeProcessed":this.state.datasetToBeProcessed
            }
            azureData["database_name"] = database_name
            azureData["synapse_sql_host"] = synapse_sql_host
            azureData["integration_type"] = "azure_synapse_ds"
            azureData["synapse_table_name"] = synapse_table_name
            azureData["synapse_password"] = synapse_password
            azureData["synapse_user"] = synapse_user
            azureData["schema"] = schema

            azureData["isFirstIntegration"] = this.props.isFirstIntegration;
            let default_message = TEST_CONNECTION_MESSAGES[this.props.formType];
            this.props.closeTestConnection();
            this.props.setSubmitButton(true);
            this.props.setData(azureData, default_message);
            this.postDatasource(azureData);

      }


      getSchema() {
            let azureSchema = '';
            if (this.state.isChecked === true) {
                  azureSchema = yup.object({
                        integration_name: yup.string().min(1, "Name must be at least 1 characters").required("Datasource Name is a required field").test(
                              'DatasourceName test',
                              'invalid Datasource name',
                              function test(value) {
                                    let val = userValidation(value, "input");
                                    return val;
                              }
                        ),
                        database_name: yup.string().required('Database is a required field'),
                        synapse_sql_host: yup.string().required('Host is a required field'), 
                        synapse_password: yup.string().required('Password is a required field'),
                        synapse_user: yup.string().required('Username is a required field'),
                        schema: yup.string().required('Schema is a required field'),
                  });

            } else if (this.state.isChecked === false) {
                  azureSchema = yup.object({
                        integration_name: yup.string().min(1, "Name must be at least 1 characters").required("Datasource Name is a required field").test(
                              'DatasourceName test',
                              'invalid Datasource name',
                              function test(value) {
                                    let val = userValidation(value, "input");
                                    return val;
                              }
                        ),
                        database_name: yup.string().required('Database is a required field'),
                        synapse_user: yup.string().required('Username is a required field'),
                        synapse_password: yup.string().required('Password is a required field'),
                        synapse_sql_host: yup.string().required('Host is a required field'), 
                        error_storage: yup.string().required('Error Storage is a required field'),
                        schema: yup.string().required('Schema is a required field'),
                  });
            }

            return azureSchema;
      }

      ChangesecretKeyIcon() {
            let state = this.state.secretKeyIcon;
            if (state.iconName === "eye") {
                  this.setState({ secretKeyIcon: faEyeSlash });
                  this.setState({ secretKeyType: "text" })
            } else {
                  this.setState({ secretKeyIcon: faEye });
                  this.setState({ secretKeyType: "password" })
            }
      }

      disableButton() {
            this.setState({ hideSubmitButton: true });
      }

      render() {
            let azureSchema = this.getSchema();

            let initial_values = {
                  integration_name: '', error_storage: '', 
                  synapse_user: '', synapse_password: '',
                  synapse_table_name: '', database_name: '', 
                  created_time: '',
                  synapse_sql_host: '',
                  schema: ''
            }
            let edit_integration = null;
            if (this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined) {
                  edit_integration = true;
            }
            else {
                  edit_integration = false;
            }

            return (
                  <>
                        <Formik
                              validationSchema={azureSchema}
                              onSubmit={this.postAzureData}
                              validateOnBlur={false}
                              initialValues={(edit_integration === true) ? this.state.integration_data_edit : initial_values}
                              enableReinitialize={true}                        >
                              {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    isValid,
                              }) => (
                                    <Form onSubmit={handleSubmit}>
                                          <div className="form-row">
                                                <Form.Group controlId="integration_name" className="form-group col-md-6">
                                                      <Form.Label>Datasource Name <span className="text-danger">*</span></Form.Label>
                                                      <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            name="integration_name"
                                                            value={values.integration_name === undefined ? "" : values.integration_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.integration_name && touched.integration_name}
                                                            placeholder="Datasource Name"
                                                      />
                                                      <Form.Control.Feedback type="invalid">
                                                            {touched.integration_name && errors.integration_name}
                                                      </Form.Control.Feedback>
                                                </Form.Group>
                                                <>

                                                <Form.Group controlId="synapse_sql_host" 
                                                            className="col-md-6">
                                                            <Form.Label>Host
                                                                  <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder="Host"
                                                                  value={values.synapse_sql_host === undefined ? "" : values.synapse_sql_host}
                                                                  name="synapse_sql_host"
                                                                  onChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  isInvalid={errors.synapse_sql_host && touched.synapse_sql_host}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                  {touched.synapse_sql_host && errors.synapse_sql_host}
                                                            </Form.Control.Feedback>
                                                      </Form.Group>

                                                      <Form.Group controlId="database_name" className="col-md-6">
                                                            <Form.Label>Database <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder="Database"
                                                                  value={values.database_name === undefined ? "" : values.database_name}
                                                                  name="database_name"
                                                                  onChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  isInvalid={errors.database_name && touched.database_name}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                  {touched.database_name && errors.database_name}
                                                            </Form.Control.Feedback>
                                                      </Form.Group>
                                                      <Form.Group controlId="schema" className="col-md-6">
                                                        <Form.Label>Schema <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control
                                                              type="text"
                                                              className="form-control"
                                                              value={values.schema === undefined ? "" : values.schema}
                                                              placeholder="schema"
                                                              onBlur={handleBlur}
                                                              isInvalid={errors.schema && touched.schema}
                                                              disabled={edit_integration ? true : false}
                                                              onChange={handleChange}
                                                              name="schema"/>
                                                        <Form.Control.Feedback type="invalid">
                                                                   { touched.schema && errors.schema }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                      {/* <Form.Group controlId="synapse_table_name" className="col-md-6">
                                                            <Form.Label>Table name <span className="text-danger"></span></Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder="Table name"
                                                                  value={values.synapse_table_name === undefined ? "" : values.synapse_table_name}
                                                                  name="synapse_table_name"
                                                                  onChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  isInvalid={errors.synapse_table_name && touched.synapse_table_name} />
                                                            <Form.Control.Feedback type="invalid">
                                                                  {touched.synapse_table_name && errors.synapse_table_name}
                                                            </Form.Control.Feedback>
                                                      </Form.Group> */}

                                                      <Form.Group controlId="synapse_user" className="col-md-6">
                                                            <Form.Label>Username <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  className="form-control"
                                                                  name="synapse_user"
                                                                  placeholder="Username"
                                                                  value={values.synapse_user === undefined ? "" : values.synapse_user}
                                                                  onBlur={handleBlur}
                                                                  isInvalid={errors.synapse_user && touched.synapse_user}
                                                                  onChange={handleChange} />
                                                            <Form.Control.Feedback type="invalid">
                                                                  {touched.synapse_user && errors.synapse_user}
                                                            </Form.Control.Feedback>
                                                      </Form.Group>

                                                      <Form.Group controlId="synapse_password" className="col-md-6">
                                                            <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                                                            <InputGroup bsPrefix="icon-input-group">
                                                                  <i>
                                                                        <FontAwesomeIcon icon={faLock} />
                                                                  </i>
                                                                  <Form.Control
                                                                        type={this.state.secretKeyType}
                                                                        className="form-control"
                                                                        name="synapse_password"
                                                                        value={values.synapse_password === undefined ? "" : values.synapse_password}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        placeholder='Password'
                                                                        isInvalid={errors.synapse_password && touched.synapse_password}
                                                                        autoComplete={this.props.autocomplete}
                                                                  />
                                                                  <Form.Control.Feedback type="invalid">
                                                                        {touched.synapse_password && errors.synapse_password}
                                                                  </Form.Control.Feedback>
                                                                  <i className="security-eye">
                                                                        <FontAwesomeIcon onClick={this.ChangesecretKeyIcon} icon={this.state.secretKeyIcon} className="security-eye" />
                                                                  </i>
                                                            </InputGroup>
                                                      </Form.Group>

                                                      {/* <Form.Group controlId="created_time" className="col-md-6">
                                                            <Form.Label>Incremental Data Identifier </Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  className="form-control"
                                                                  name="created_time"
                                                                  placeholder="Incremental Data Identifier"
                                                                  value={values.created_time === undefined ? "" : values.created_time}
                                                                  onBlur={handleBlur}
                                                                  disabled={edit_integration ? true : false}
                                                                  onChange={handleChange}
                                                            />
                                                      </Form.Group> */}

<Form.Group className="col-md-6">
<Form.Label>Dataset to be processed</Form.Label>
<div>
<div className="custom-control custom-radio custom-control-inline mt-1">
                                                    <input
                                                          type="radio"
                                                          id="allDataset"
                                                          name="allDataset"
                                                          disabled={edit_integration === true  && this.state.dataset_processed_edit === false  ? true : false}
                                                          checked={this.state.datasetToBeProcessed === "allDataset"}
                                                          value="allDataset"
                                                          onChange={this.handleDatasetChange}
                                                          className="custom-control-input" />
                                                    <Form.Label className="custom-control-label" htmlFor="allDataset">All Datasets</Form.Label>
</div>
<div className="custom-control custom-radio custom-control-inline mt-1">
                                                    <input
                                                          type="radio"
                                                          id="specifiedDataset"
                                                          name="specifiedDataset"
                                                          disabled={edit_integration === true  && this.state.dataset_processed_edit === false  ? true : false}
                                                          value="specifiedDataset"
                                                          onChange={this.handleDatasetChange}
                                                          checked={this.state.datasetToBeProcessed === "specifiedDataset"}
                                                          className="custom-control-input" />
                                                    <Form.Label className="custom-control-label" htmlFor="specifiedDataset">Specified Datasets</Form.Label>
</div>
</div>
</Form.Group>
<div className="col-12 d-none partition_alert_info">
    <Alert variant="warning">
        <p className="mb-0 alert-warning-text"><i className="mr-2"><FontAwesomeIcon icon={faInfoCircle} /></i>Current Metrics and all related information will be lost on modifying existing specified datasets</p>
    </Alert>
</div>
<>
       <SpecificDataset
           clickedValue={this.state.specificDatasets}
           datasetToBeProcessed={this.state.datasetToBeProcessed}
           partitiondatasetlist={this.state.partitiondatasetlist}
           changeDatasetText={this.changeDatasetText}
           removeSpecificDataset={this.removeSpecificDataset}
           handleExactMatch={this.handleExactMatch}
           handleStartIndex={this.handleStartIndex}
           handleStartIndexRadio={this.handleStartIndexRadio}
           editAction={edit_integration}
       />
       <div className="col-md-12"> 
       <a href="!#" id="addSpecificDataset" onClick={this.addSpecificDataset}>
       {
        this.state.datasetToBeProcessed === "specifiedDataset" ? 
        <><i className="mr-1"><FontAwesomeIcon icon={faPlusCircle} /></i>Add Another Dataset</>
        :
        <><i className="mr-1"><FontAwesomeIcon icon={faPlusCircle} /></i>Add Incremental Data Identification</>
       }
        </a>
        </div>
       <hr className="clearfix w-100" />
       </>
    
<Partitions 
    clickedValue={this.state.partition}
    datasetToBeProcessed = {this.state.datasetToBeProcessed}
    removePartition={this.removePartition}
    changePartitionBy={this.changePartitionBy}
    changePartitionText={this.changePartitionText}
    changePartitionSelect={this.changePartitionSelect}
    default_partition_by={this.state.default_partition_by}
    partitiondatasetlist={this.state.partitiondatasetlist}
/>

<div className="col-md-6 mb-3">
                                          <button type="button" onClick={this.addPartition} className="btn btn-outline-primary">
                                                <i><FontAwesomeIcon icon={faPlusCircle} /></i>Add Partition
                                          </button>
                                          <RcTooltip
                                                placement="top"
                                                overlay={
                                                      <span>
                                                            Partioning Dataset.
                                                      </span>
                                                }
                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                          >
                                                <sup className="ml-1"><Image width="16px" src="icon_info_circle" /></sup>
                                          </RcTooltip>
                                    </div>
                                    

                                                </>
                                                <div className="col-md-12" style={{ "padding": "10px 5px" }}>
                                                      <div className="custom-control custom-checkbox">
                                                            <Form.Control
                                                                type="checkbox"
                                                                name="errorColumn"
                                                                className="custom-control-input"
                                                                onChange={this.toggleChange}
                                                                checked={this.state.isChecked} 
                                                            />
                                                            <Form.Label className="custom-control-label">Store Data Issues and Errors in Qualdo™</Form.Label>
                                                      </div>
                                                </div>
                                                {this.state.isChecked === false ?
                                                      <Form.Group controlId="error_storage" className="col-md-6">
                                                            <Form.Label>Error Storage Sub Path <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  className="form-control"
                                                                  name="error_storage"
                                                                  placeholder="Error Storage Sub Path"
                                                                  onChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  isInvalid={errors.error_storage && touched.error_storage}
                                                                  value={values.error_storage === undefined ? "" : values.error_storage} />
                                                            <Form.Control.Feedback type="invalid">
                                                                  {touched.error_storage && errors.error_storage}
                                                            </Form.Control.Feedback>
                                                      </Form.Group> : ''}
                                          </div>
                                          <hr className="clearfix" />
                                          <Alert variant="danger" className='d-none partition_alert_check'>
                        <div className="custom-control custom-checkbox custom-checkbox-lg checkbox-valign-middle">
                            <Form.Control
                                type="checkbox"
                                className="custom-control-input"
                                name="threshold"
                                id="partion-checkbox" 
                            />
                            <Form.Label className="custom-control-label pl-2" htmlFor="partion-checkbox">
                                <div className="d-sm-flex align-items-center">
                                    <Image src="icon_layer_partition" className="mr-2" />
                                    <p className="mb-0 alert-danger-text">Current Metrics and all related information will be lost on modifying existing specified datasets or existing incremental data identifiers or existing partitions. <strong>Are you sure you want to go ahead?</strong></p>
                                </div>
                            </Form.Label>
                        </div>
                    </Alert>
                                          
                                          <div className="text-right">
                                                {this.props.showCancel ? <button type="button" onClick={() => this.props.ex_integration_details ? this.props.closeModal() : this.props.setPage(false)} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button> : ''}
                                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle mr-2">Test Connection</button>
                                          </div>
                                    </Form>)}
                        </Formik>
                  </>
            );
      }
}
export default AzureSynapseDSForm;