import React from 'react';
import MonitorPortlet from '../components/monitorPortlet';
import DqErrorGrid from './components/dq_err_grid';
import Load from '../components/loadAction';
import { connect } from "react-redux";
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCheck } from '@fortawesome/free-solid-svg-icons';
import { addMonitorDQPreview, setDataSource } from "../redux/actions";
import NoErrorInPreview from './components/noErrorPreview';
import { getApi } from "../utils/event_handling";
import _ from 'lodash';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from '../components/image';
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";
import { Option, getPlaceholder } from "./components/filter_common_utils";
import CustomSelect from "./components/customSelect";
import {
    filterChartDataForTime,
    getChartTypeForMonitorCharts,
    normalizeChartData,
    canDisableShowMore, hasValidNumberOfDatasets,
    setDQShowMoreDataOnGroupingLevel,
    getAllDQShowMoreCharts
} from "../utils/common_utils";
import {
    DISPLAY_DATE_FORMAT,
    DQ_CHART_POINTS,
    MIN_MAX_SCALE, NO_DATASET_NO_DATA_PROFILE,
    NO_DATASET_NO_ERROR, REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART
} from "../utils/constant";
import { getDateObject } from "../charts/browser_utils";
import {
    METADATA_MAP_KEY_SEPARATOR, METADATA_MAP_VALUE_SEPARATOR
} from "../utils/attribute_name_utils";
import CustomTreeFilter from './components/customTreeFilter';
// import { getMetricsOption} from "./components/filter_common_utils";
import {LIST_VIEW_OPTIONS} from "../utils/constant";
class DqError extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleTime = this.handleTime.bind(this);
        this.changeSource = this.changeSource.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateGridData = this.updateGridData.bind(this);
        this.handleTimeFilter = this.handleTimeFilter.bind(this);
        this.closeLastProfiledTime = this.closeLastProfiledTime.bind(this);
        this.setGridData = this.setGridData.bind(this);
        this.setDqPreview = this.setDqPreview.bind(this);
        this.filterChartDataForTime = filterChartDataForTime.bind(this);
        this.setShowMoreDataOnGroupingLevel = setDQShowMoreDataOnGroupingLevel.bind(this);
        let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });
        this.getApi = getApi.bind(this);

          // New Filter 
        this.clearAllFilterData = this.clearAllFilterData.bind(this)
        this.loadDataSourceRender = this.loadDataSourceRender.bind(this)
        this.applyDataset = this.applyDataset.bind(this)
        this.applyDateFilter = this.applyDateFilter.bind(this)
        this.toggleFilter = this.toggleFilter.bind(this);
        
        if (this.props.monitorModuleProps.dqPreview !== null && this.props.monitorModuleProps.dqPreview !== undefined
            && this.props.monitorModuleProps.dqPreview.dq_metrics["filterStartDate"] != null && this.props.monitorModuleProps.dqPreview.dq_metrics["filterStartDate"] !== undefined) {

            const filterStartDateStr = this.props.monitorModuleProps.dqPreview.dq_metrics["filterStartDate"];
            const startDateObj = getDateObject(filterStartDateStr);
            const filterEndDateStr = this.props.monitorModuleProps.dqPreview.dq_metrics["filterEndDate"];
            const endDateObj = getDateObject(filterEndDateStr);

            startDate = moment(startDateObj).startOf('day');
            endDate = moment(endDateObj).endOf('day');
        }

        // let propsMonitorModule = this.props.monitorModuleProps;
        // let propsDataModule = this.props.dataModule;
        // const allDQShowMore = getAllDQShowMoreCharts(propsMonitorModule, propsDataModule, null);
        
        
        let propsMonitorModule = this.props.monitorModuleProps;
        propsMonitorModule['timelinessDataSetInfo'] = this.props.monitorModule.timelinessDataSetInfo
        propsMonitorModule['timelinessAttributeInfo'] = this.props.monitorModule.timelinessAttributeInfo
        
        let propsDataModule = {"metaData":this.props.metaData};
        let allDQShowMore = getAllDQShowMoreCharts(propsMonitorModule, propsDataModule, null);
        let selectedDataSourceID = null
        if (this.props.selectedDataSrc !== null){
           selectedDataSourceID = this.props.selectedDataSrc.value
        }
        let data = {"selectedDataSource":selectedDataSourceID,
                    "integrations":this.props.integrationMapping}
        allDQShowMore = this.updateTimelinessShowMoreData(allDQShowMore, data);
                let allShowMoreData = propsMonitorModule.timelinessDataSetInfo
        let timelinessData = this.filterTimelinessData(allShowMoreData, data);
        let recencyDict = {}
        recencyDict["Recency"] = timelinessData
        let previewData = propsMonitorModule.dqPreview
        this.state = {
            startDate: startDate,
            endDate: endDate,
            selectedDataSrc: this.props.selectedDataSrc!==null?this.props.selectedDataSrc:null,
            metrics: [{ "label": "All", "value": "*" },{ "label": "Data Drift", "value": "drift" }, { "label": "Data Completeness", "value": "completeness" },
            { "label": "Data Uniqueness", "value": "uniqueness" },
            { "label": "Data Timeliness", "value": "timeliness" }, { "label": "Data Accuracy", "value": "accuracy" },
            { "label": "Data Conformity", "value": "conformity" }, { "label": "Data Consistency", "value": "consistency" }],

            initial: { datasource: "", metrics: "" },
            showDropDown: false,
            timeFilterApplied: false,
            allDQShowMore: allDQShowMore,
            hideLastProfiledTime: false,
            isConsistencyEnabled: null,
            integrationsMap: this.props.integrationsMap,
            completeness: this.setGridData(previewData!==undefined?previewData.dq_metrics["Data Completeness"]:{}, null, startDate, endDate, false),
            uniqueness: this.setGridData(previewData!==undefined?previewData.dq_metrics["Data Uniqueness"]:{}, null, startDate, endDate, false),
            timeliness: this.setGridData(recencyDict, null, startDate, endDate, false),
            accuracy: this.setGridData(previewData!==undefined?previewData.dq_metrics["Data Accuracy"]:{}, null, startDate, endDate, false),
            drift: this.setGridData(previewData!==undefined?previewData.dq_metrics["Data Drift"]:{}, null, startDate, endDate, false),
            conformity: this.setGridData(previewData!==undefined?previewData.dq_metrics["Data Conformity"]:{}, null, startDate, endDate, false),
            consistency: this.setGridData(previewData!==undefined?previewData.dq_metrics["Data Consistency"]:{}, null, startDate, endDate, false),
            completenessDataSetList: allDQShowMore.completenessDataSetList,
            uniquenessDataSetList: allDQShowMore.uniquenessDataSetList,
            timelinessDataSetList: allDQShowMore.timelinessDataSetList,
            accuracyDataSetList: allDQShowMore.accuracyDataSetList,
            conformityDataSetList: allDQShowMore.conformityDataSetList,
            consistencyDataSetList: allDQShowMore.consistencyDataSetList,
            driftDataSetList: allDQShowMore.driftDataSetList,
            completenessShowMore: allDQShowMore.completenessShowMore,
            uniquenessShowMore: allDQShowMore.uniquenessShowMore,
            timelinessShowMore: allDQShowMore.timelinessShowMore,
            accuracyShowMore: allDQShowMore.accuracyShowMore,
            conformityShowMore: allDQShowMore.conformityShowMore,
            consistencyShowMore: allDQShowMore.consistencyShowMore,
            driftShowMore: allDQShowMore.driftShowMore,
            selectedMetrics: [],
            visibility: {
                drift: true,
                completeness: true,
                uniqueness: true,
                timeliness: true,
                accuracy: true,
                conformity: true,
                consistency: true
            },
            // Newfilter 
            selectedDataSourceID:"",
            selectedDataSourceName: "",
            clearAllFilter:false,
            isFilterMode:true,
            inProgress:this.props.inProgress,
            noDataFound:this.props.noDataFound,
            noIntegration:this.props.noIntegration,
        }

    }
    clearAllFilterData(){
        let cur_data = this.state.metrics;
        for (let i = 0; i < cur_data.length; i++) {
            cur_data[i]["checked"] = false;
        }
        this.setState({metrics:cur_data,
            filterData: this.props.filterData,
            clearAllFilter:true}
            )
    }

    applyDataset(pm){

    this.setState({ selectedMetrics: pm.metrics }, function() {
        this.updateGridData(this.state.selectedDataSrc, true);
    });

    }

     applyDateFilter(start_date,end_date){
        let picker = {startDate:start_date,endDate:end_date};
        let event = "";
        this.handleTimeFilter(event, picker);
    }

    toggleFilter() {
        let filterMode = this.state.isFilterMode ? false : true;
        this.setState({
            isFilterMode: filterMode
        })
    }
    
    loadDataSourceRender(selectedDataSource){
        let obj = {
            "datasource": selectedDataSource.integration_id,
            "datasourcename":selectedDataSource.integration_name,
            "start_date": this.state.startDate,
            "end_date": this.state.endDate,
        }
        this.props.setCustomFilterValues(obj);
       let event = {"label":selectedDataSource.integration_name,"value":selectedDataSource.integration_id}
       let initial = {"datasource":selectedDataSource.integration_id,"metrics":""};
       this.props.fetchDataSourceData(event.value, 'dqp_grid_view')
       
       if (selectedDataSource.integration_id === this.state.selectedDataSourceID) {
            // To Avoid Repeated API call for already selected datasource    
            return
        }
        else {
            this.props.fetchDataSourceData(event.value, 'dq_grid_view')
        }
       this.updateGridData(event);
       initial.datasource = event.value;
       this.setState({ selectedDataSrc: event, initial: initial });
    }
    componentDidMount() {
        if(this.props.default_filter_data.datasource!==undefined && this.props.default_filter_data.datasource > 0 ){
            this.setState({
                selectedDataSrc:{value:this.props.default_filter_data.datasource
                    ,label:this.props.default_filter_data.datasourcename},
                seletedDatasourceId:this.props.default_filter_data.datasource,
                seletedDatasourceName:this.props.default_filter_data.datasourcename,
               timeFilterApplied: true
            });
        }

        this.getApi("checkConsistency");
    }

    componentDidUpdate(prevProps) {
        // Current props monitor module
        let cPMonitorModule = this.props.monitorModuleProps;
        cPMonitorModule['timelinessDataSetInfo'] = this.props.monitorModule.timelinessDataSetInfo
        cPMonitorModule['timelinessAttributeInfo'] = this.props.monitorModule.timelinessAttributeInfo
        
        // Previous props module
        let pPMonitorModule = prevProps.monitorModuleProps;
        pPMonitorModule['timelinessDataSetInfo'] = this.props.monitorModule.timelinessDataSetInfo
        pPMonitorModule['timelinessAttributeInfo'] = this.props.monitorModule.timelinessAttributeInfo
                
        let isDataChanged = pPMonitorModule.driftAttributeInfo !== cPMonitorModule.driftAttributeInfo;

        // driftAttributeInfoCount variable will be incremented when the last API call
        // of monitor page is called. [The last API call is Data drift - attribute level]
        isDataChanged = isDataChanged ||
            (pPMonitorModule.driftAttributeInfoCount !== cPMonitorModule.driftAttributeInfoCount)
        if (isDataChanged) {
            let startDate = this.state.startDate;
            let endDate = this.props.endDate;
            if (cPMonitorModule.dqPreview.dq_metrics !== undefined && cPMonitorModule.dqPreview.dq_metrics !== null &&
                cPMonitorModule.dqPreview.dq_metrics["filterStartDate"] != null && cPMonitorModule.dqPreview.dq_metrics["filterStartDate"] !== undefined) {

                const filterStartDateStr = cPMonitorModule.dqPreview.dq_metrics["filterStartDate"];
                const filterEndDateStr = cPMonitorModule.dqPreview.dq_metrics["filterEndDate"];
                const startDateObj = getDateObject(filterStartDateStr);
                const endDateObj = getDateObject(filterEndDateStr);
                startDate = moment(startDateObj).startOf('day');
                endDate = moment(endDateObj).endOf('day');
            }

            let propsMonitorModule = cPMonitorModule;

            let propsDataModule = {"metaData":this.props.metaData};
            let allDQShowMore = getAllDQShowMoreCharts(propsMonitorModule, propsDataModule, null);
            
            let data = {"selectedDataSource":this.props.selectedDataSourceID,
                        "integrations":this.props.integrationMapping}
            allDQShowMore = this.updateTimelinessShowMoreData(allDQShowMore, data);            let allShowMoreData = propsMonitorModule.timelinessDataSetInfo
            let timelinessData = this.filterTimelinessData(allShowMoreData, data);
            let recencyDict = {}
            recencyDict["Recency"] = timelinessData
            this.setState({
                completeness: this.setGridData(propsMonitorModule.dqPreview.dq_metrics["Data Completeness"], null, startDate, endDate, false),
                uniqueness: this.setGridData(propsMonitorModule.dqPreview.dq_metrics["Data Uniqueness"], null, startDate, endDate, false),
                timeliness: this.setGridData(recencyDict, null, startDate, endDate, false),
                accuracy: this.setGridData(propsMonitorModule.dqPreview.dq_metrics["Data Accuracy"], null, startDate, endDate, false),
                drift: this.setGridData(propsMonitorModule.dqPreview.dq_metrics["Data Drift"], null, startDate, endDate, false),
                conformity: this.setGridData(propsMonitorModule.dqPreview.dq_metrics["Data Conformity"], null, startDate, endDate, false),
                consistency: this.setGridData(propsMonitorModule.dqPreview.dq_metrics["Data Consistency"], null, startDate, endDate, false),
                completenessDataSetList: allDQShowMore.completenessDataSetList,
                uniquenessDataSetList: allDQShowMore.uniquenessDataSetList,
                timelinessDataSetList: allDQShowMore.timelinessDataSetList,
                accuracyDataSetList: allDQShowMore.accuracyDataSetList,
                conformityDataSetList: allDQShowMore.conformityDataSetList,
                consistencyDataSetList: allDQShowMore.consistencyDataSetList,
                driftDataSetList: allDQShowMore.driftDataSetList,
                completenessShowMore: allDQShowMore.completenessShowMore,
                uniquenessShowMore: allDQShowMore.uniquenessShowMore,
                timelinessShowMore: allDQShowMore.timelinessShowMore,
                accuracyShowMore: allDQShowMore.accuracyShowMore,
                conformityShowMore: allDQShowMore.conformityShowMore,
                consistencyShowMore: allDQShowMore.consistencyShowMore,
                driftShowMore: allDQShowMore.driftShowMore,
            });
        }
    }

    closeLastProfiledTime() {
        this.setState({
            hideLastProfiledTime: true,
        });
    }

    updateTimelinessShowMoreData(allDQShowMore, propsDataModule) {

        let selectedDataSourceId = propsDataModule.selectedDataSource
        let integrationsDict = propsDataModule.integrations
        let selectedDataSourceName = integrationsDict[selectedDataSourceId]
        if (allDQShowMore.timelinessShowMore.dataSet.length === 0) {
            return allDQShowMore;
        }
        let timelinessShowMoreData = allDQShowMore.timelinessShowMore.dataSet[0].data
        delete allDQShowMore.timelinessShowMore.dataSet[0].data
        let data_source_name = ""
        let newTimelinessDatasetList = []
        
        for ( let data =0; data < timelinessShowMoreData.length;data++ ){
            data_source_name = timelinessShowMoreData[data].data_source_name
            if ( selectedDataSourceName ===  data_source_name) {
                newTimelinessDatasetList.push(timelinessShowMoreData[data])
            }
        }
        allDQShowMore.timelinessShowMore.dataSet[0]["data"] = newTimelinessDatasetList
        return allDQShowMore
    }

    filterTimelinessData(allDQShowMore, propsDataModule) {
        let selectedDataSourceId = propsDataModule.selectedDataSource
        let allDQShowMoreData = allDQShowMore.Recency
        let allData = {}
        if (allDQShowMoreData === undefined || allDQShowMoreData === null) {
            return allData
        }
        for (const [key, value] of Object.entries(allDQShowMoreData)) {
            let keyArr = key.split(METADATA_MAP_KEY_SEPARATOR)
            let data_src_id = keyArr[1]
            if ( Number(selectedDataSourceId) === Number(data_src_id)) {
                allData[key] = value
            }                 

        }
        return allData
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (props.integrationsMap !== state.integrationsMap) {
    //         return {
    //             integrationsMap: props.integrationsMap
    //         }
    //     }

    //     return null;
    // }


    setGridData(dataFromProps, dataSource, startDate, endDate, timeFilterApplied) {
        let data = _.cloneDeep(dataFromProps);
        let gridData = [];
        let timeFilteredData = [];

        if (data === undefined || data === null) {
            return timeFilteredData;
        }

        for (const [key, value] of Object.entries(data)) {
            for (const [attribute, chartData] of Object.entries(value)) {
                const attributeKeysSplit = attribute.split(METADATA_MAP_KEY_SEPARATOR);
                if (dataSource !== null) {
                    if (parseInt(attributeKeysSplit[1]) !== parseInt(dataSource)) {
                        continue;
                    }
                }
                if (gridData.length !== 3) {
                    let metaData = this.props.metaData[attribute];
                    if (metaData === undefined) {
                        metaData = this.props.metaData;
                        // const filtered = Object.keys(metaData)
                        //     .filter(key => key.startsWith(attribute))
                        //     .reduce((obj, key) => {
                        //         obj[key] = metaData[key];
                        //         return obj;
                        //     }, {});
                        metaData = metaData[attribute];
                        if (metaData !== undefined) {
                            let metadataSplitArr = metaData.split(METADATA_MAP_VALUE_SEPARATOR);
                            metaData = metadataSplitArr.slice(0, metadataSplitArr.length - 1).join(METADATA_MAP_VALUE_SEPARATOR);
                        }
                    }
                    if (metaData !== undefined) {
                        const metadataSplit = metaData.split(METADATA_MAP_VALUE_SEPARATOR);
                        let attributeName = null;
                        let datasetName;
                        let toolTiptopHeader;
                        let keysSplitLength = attributeKeysSplit.length;

                        // Skip attribute level charts for consistency related metrics
//                        if (keysSplitLength === 4 && REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART.includes(key)) {
//                            continue;
//                        }

                        if (keysSplitLength === 4) {
                            datasetName = metadataSplit[2];
                            attributeName = metadataSplit[metadataSplit.length - 1];
                            toolTiptopHeader = attributeName;
                        } else {
                            datasetName = metadataSplit[2];
                            toolTiptopHeader = datasetName;
                        }
                        if (chartData["name"] === "Data Drift") {
                            let boxPlotData = {}
                            let statistics = {}
                            let quantile = chartData["boxplot_data"]
                            if (quantile !== undefined && quantile !== null) {
                                let isBoxplot = quantile.filter(x => x !== "NA")
                                if (isBoxplot.length > 0) {
                                    statistics["attribute_name"] = attributeName
                                    statistics["quantile"] = quantile
                                    statistics["drift_patterns"] = chartData["drift_patterns"]
                                    statistics["time"] = chartData["time"]
                                    if (attributeName !== null) {
                                        boxPlotData["chart_level"] = "Attribute"
                                    } else {
                                        boxPlotData["chart_level"] = "Dataset"
                                    }
                                    boxPlotData["statistics"] = [statistics]
                                    chartData["boxPlotData"] = boxPlotData
                                    chartData["chartType"] = "boxPlotZoomable"
                                }

                            }
                        }
                        if (attributeName !== null) {
                            chartData["attribute_name"] = attributeName;
                        }
                        chartData["datasetName"] = datasetName;
                        chartData["toolTiptopHeader"] = toolTiptopHeader;
                        chartData["data_set_id"] = attributeKeysSplit[2];
                        chartData["errorChart"] = true;
                        chartData["title"] = `${metadataSplit.join(" > ")} - ${chartData.name}`;
                        chartData["data_source_name"] = metadataSplit[1];
                        chartData[DQ_CHART_POINTS] = chartData["value"];

                        let chartTypeData = getChartTypeForMonitorCharts(chartData);
                        chartData["type"] = chartTypeData.chartPatternType;
                        let hasError = chartTypeData.hasError;
                        chartData["hasDrift"] = hasError;

                        // We are changing scale of all charts to min max scale
                        // Part of fix for issue 1038
                        chartData["scale"] = MIN_MAX_SCALE;

                        // let isDefault = DEFAULT_METRICS_MAP.dq.includes(key);
                        // let metricType = isDefault ? "default" : "custom";


                        // if (metricType === 'custom' || MIN_MAX_SCALE_LIST.includes(key)) {
                        //     chartData["scale"] = MIN_MAX_SCALE;
                        // }

                        normalizeChartData(chartData, DQ_CHART_POINTS);
                        let chartValues = chartData[DQ_CHART_POINTS];
                        let drift_len = chartValues.length;

                        if (drift_len === 0) {
                            continue;
                        }

                        if (drift_len > 1) {
                            chartData["error_percentage"] = chartValues[(drift_len - 1)].toFixed(2);
                            chartData["last_error"] = chartValues[(drift_len - 2)].toFixed(2);
                        } else if (drift_len === 1) {
                            chartData["error_percentage"] = chartValues[(drift_len - 1)].toFixed(2);
                            chartData["last_error"] = chartValues[(drift_len - 1)].toFixed(2);
                        } else {
                            chartData["error_percentage"] = 0;
                            chartData["last_error"] = 0;
                        }
                        if(chartData["name"] === "Data Outlier"){
                        }
                        if (hasError) {
                            gridData.push(chartData);
                        }
                    }
                }

            }
        }

        for (let data of gridData) {
            let copiedData = {}
            Object.assign(copiedData, data);
            let result = this.filterChartDataForTime(data, startDate, endDate);
            let boxPlotDataAvailable = false;
            if (result.boxPlotData !== undefined && result.boxPlotData !== null) {
                boxPlotDataAvailable = true;
                copiedData.boxPlotData = result.boxPlotData;
            }
            let updatedTime = result.time;
            let yAxis;
            if (result.dq_chart_points !== undefined) {
                yAxis = result.dq_chart_points;
            } else {
                yAxis = result.drift;
            }
            if ((copiedData.chartType === "boxPlotZoomable" && boxPlotDataAvailable === false) ||
                (copiedData.chartType !== "boxPlotZoomable" && updatedTime.length === 0)) {
                continue;
            }
            if (timeFilterApplied === false && updatedTime.length >= 10) {
                updatedTime = updatedTime.slice(Math.max(updatedTime.length - 10, 0));
                yAxis = yAxis.slice(Math.max(yAxis.length - 10, 0));
            }
            copiedData["drift_patterns"] = result.drift_patterns;
            if (result.drift_patterns.length > 0) {
                let latest_drift_index = result.drift_patterns.length - 1;
                copiedData["drift_pattern"] = result.drift_patterns[latest_drift_index];
            }
            copiedData["time"] = updatedTime;
            copiedData["drift"] = yAxis;
            copiedData["dq_chart_points"] = yAxis;
            timeFilteredData.push(copiedData);
        }
        return timeFilteredData;
    }


    changeSource(event, obj) {

        if (event.value === this.state.selectedDataSourceID) {
            // To Avoid Repeated API call for already selected datasource    
            return
        }
        else {
            this.props.fetchDataSourceData(event.value, 'dq_grid_view')
        }
        this.updateGridData(event);
        let initial = this.state.initial;
        initial.datasource = event.value;
        this.setState({ selectedDataSrc: event, initial: initial });

    }

    updateGridData(event, isMetricsSectionFiltered = false) {
        let dataSourceId = event.value;
        let propsMonitorModule = this.props.monitorModuleProps;
        let propsDataModule = {"metaData":this.props.metaData};
        let allDQShowMore = getAllDQShowMoreCharts(propsMonitorModule, propsDataModule, null);
        let selectedDataSourceID = this.props.selectedDataSrc.value
        let data = {"selectedDataSource":selectedDataSourceID,
                    "integrations":this.props.integrationMapping}
        allDQShowMore = this.updateTimelinessShowMoreData(allDQShowMore, data);
        let allShowMoreData = propsMonitorModule.timelinessDataSetInfo
        let timelinessData = this.filterTimelinessData(allShowMoreData, data);
        let recencyDict = {}
        recencyDict["Recency"] = timelinessData
        if (!isMetricsSectionFiltered) {
            this.setState({
                completeness: this.setGridData(allDQShowMore.completenessShowMore, dataSourceId, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
                timeliness: this.setGridData(allDQShowMore.timelinessShowMore, dataSourceId, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
                accuracy: this.setGridData(allDQShowMore.accuracyShowMore, dataSourceId, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
                conformity: this.setGridData(allDQShowMore.conformityShowMore, dataSourceId, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
                consistency: this.setGridData(allDQShowMore.consistencyShowMore, dataSourceId, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
                drift: this.setGridData(allDQShowMore.driftShowMore, dataSourceId, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
                uniqueness: this.setGridData(allDQShowMore.uniquenessShowMore, dataSourceId, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
                completenessDataSetList: allDQShowMore.completenessDataSetList,
                uniquenessDataSetList: allDQShowMore.uniquenessDataSetList,
                timelinessDataSetList: allDQShowMore.timelinessDataSetList,
                accuracyDataSetList: allDQShowMore.accuracyDataSetList,
                conformityDataSetList: allDQShowMore.conformityDataSetList,
                consistencyDataSetList: allDQShowMore.consistencyDataSetList,
                driftDataSetList: allDQShowMore.driftDataSetList,
                completenessShowMore: allDQShowMore.completenessShowMore,
                uniquenessShowMore: allDQShowMore.uniquenessShowMore,
                timelinessShowMore: allDQShowMore.timelinessShowMore,
                accuracyShowMore: allDQShowMore.accuracyShowMore,
                conformityShowMore: allDQShowMore.conformityShowMore,
                consistencyShowMore: allDQShowMore.consistencyShowMore,
                driftShowMore: allDQShowMore.driftShowMore,
            });
        } else {
            let data;
            let visibility = this.state.visibility
            this.state.metrics.map((metrics, index) => {
                let selected = this.state.selectedMetrics.filter(x => x.label === metrics.label);
                if (selected.length === 0) {
                    visibility[metrics.value] = false
                    return 1
                }
                visibility[metrics.value] = true
                data = this.setGridData(this.props.monitorModuleProps.dqPreview.dq_metrics[metrics.label], dataSourceId,
                    this.state.startDate, this.state.endDate, this.state.timeFilterApplied)
                switch (metrics.label) {
                    case "Data Completeness":
                        this.setState({
                            completeness: data, completenessShowMore: allDQShowMore.completenessShowMore,
                            completenessDataSetList: allDQShowMore.completenessDataSetList
                        });
                        break
                    case "Data Drift":
                        this.setState({
                            drift: data, driftShowMore: allDQShowMore.driftShowMore,
                            driftDataSetList: allDQShowMore.driftDataSetList
                        });
                        break
                    case "Data Uniqueness":
                        this.setState({
                            uniqueness: data, uniquenessShowMore: allDQShowMore.uniquenessShowMore,
                            uniquenessDataSetList: allDQShowMore.uniquenessDataSetList
                        });
                        break
                    case "Data Timeliness":              
                        this.setState({
                            timeliness: this.setGridData(recencyDict, dataSourceId, this.state.startDate, this.state.endDate, this.state.timeFilterApplied), 
                            timelinessShowMore: allDQShowMore.timelinessShowMore,
                            timelinessDataSetList: allDQShowMore.timelinessDataSetList
                        });
                        break
                    case "Data Accuracy":
                        this.setState({
                            accuracy: data, accuracyShowMore: allDQShowMore.accuracyShowMore,
                            accuracyDataSetList: allDQShowMore.accuracyDataSetList
                        });
                        break
                    case "Data Conformity":
                        this.setState({
                            conformity: data, conformityShowMore: allDQShowMore.conformityShowMore,
                            conformityDataSetList: allDQShowMore.conformityDataSetList
                        });
                        break
                    case "Data Consistency":
                        this.setState({
                            consistency: data, consistencyShowMore: allDQShowMore.consistencyShowMore,
                            consistencyDataSetList: allDQShowMore.consistencyDataSetList
                        });
                        break

                    default:
                        break
                }

                return 1

            });
            this.setState({ visibility: visibility });
        }


        this.props.setDataSource(dataSourceId);
    }


    setDqPreview(dqPreview) {
        if (dqPreview === undefined || dqPreview === null) {
            return;
        }
        let propsMonitorModule = this.props.monitorModuleProps;
        let propsDataModule = {"metaData":this.props.metaData};
        let allShowMoreData = propsMonitorModule.timelinessDataSetInfo
        let timelinessData = this.filterTimelinessData(allShowMoreData, propsDataModule);
        let recencyDict = {}
        recencyDict["Recency"] = timelinessData
        this.setState({
            completeness: this.setGridData(dqPreview["Data Completeness"], null, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
            uniqueness: this.setGridData(dqPreview["Data Uniqueness"], null, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
            timeliness: this.setGridData(recencyDict, null, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
            accuracy: this.setGridData(dqPreview["Data Accuracy"], null, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
            conformity: this.setGridData(dqPreview["Data Conformity"], null, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
            consistency: this.setGridData(dqPreview["Data Consistency"], null, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
            drift: this.setGridData(dqPreview["Data Drift"], null, this.state.startDate, this.state.endDate, this.state.timeFilterApplied),
        });
    }

    handleTime(event, picker) {
        let startDate = picker.startDate;
        let endDate = picker.endDate;
        this.setState({ startDate: startDate, endDate: endDate });
    }

    // Need remove

    handleTimeFilter(event, picker) {
        let startDate = picker.startDate;
        let endDate = picker.endDate;

        if(this.state.noIntegration === true || this.state.inProgress === true){
            // If there is no integration or integration under progress will be restricting timefilter function
            this.setState({ startDate: startDate, endDate: endDate, timeFilterApplied: true });
            return
        }

        //    let exStartDate =  moment().subtract(180, 'days');
        const dateFromStr = this.props.monitorModuleProps.dqPreview.dq_metrics["dataFrom"];
        const startDateObj = getDateObject(dateFromStr);
        let exStartDate = moment(startDateObj).startOf('day');

        // let valuesAvailable = startDate.isSameOrAfter(exStartDate) && endDate.isSameOrBefore(exEndDate);
        let valuesAvailable = startDate.isSameOrAfter(exStartDate);

        // API calls not needed as of now for time filter. We can filter based on available data
        if (valuesAvailable === true) {
            let grids = ['completeness', 'timeliness', 'accuracy', 'conformity', 'consistency', 'drift', 'uniqueness'];
            const keyNameMapping = {
                "completeness": "Data Completeness",
                "timeliness": "Data Timeliness",
                "accuracy": "Data Accuracy",
                "consistency": "Data Consistency",
                "conformity": "Data Conformity",
                "drift": "Data Drift",
                "uniqueness": "Data Uniqueness",
            }
            let propsMonitorModule = this.props.monitorModuleProps;
            let allShowMoreData = propsMonitorModule.timelinessDataSetInfo
            let data = {"selectedDataSource":this.props.selectedDataSrc.value,
                    "integrations":this.props.integrationMapping}
            let timelinessData = this.filterTimelinessData(allShowMoreData, data);
            let recencyDict = {}
            recencyDict["Recency"] = timelinessData
            for (let grid of grids) {
                let name = keyNameMapping[grid];
                let dataSource = null;
                if (this.state.selectedDataSrc !== undefined && this.state.selectedDataSrc !== null) {
                    dataSource = this.state.selectedDataSrc.value;
                }
                let CompleteData = []
                if (name === 'Data Timeliness'){
                    CompleteData = this.setGridData(recencyDict, dataSource, startDate, endDate, true);
                }
                else{
                    CompleteData = this.setGridData(this.props.monitorModuleProps.dqPreview.dq_metrics[name], dataSource, startDate, endDate, true);
                }
                this.setState({ [grid]: CompleteData });
            }
        }
        this.setState({ startDate: startDate, endDate: endDate, timeFilterApplied: true });
    }

    handleSubmit() {
        // this.props.fetchDataSourceData(this.state.selectedDataSrc.value, 'dqp_grid_view')
        this.setState({ showDropDown: false });
        this.updateGridData(this.state.selectedDataSrc, true);
    }


    reset() {
        this.setState({
            initial: { datasource: "", metrics: "" },
            selectedMetrics: [],
            selectedDataSrc: null
        });
    }

    render() {
        // This is the last API to be called in prefetch. If the data drift - attribute API is called,
        // we can say that prefetch calls for monitor are over
        let allDQShowMore = this.state.allDQShowMore
        let showMoreData = {
            "completenessShowMore": allDQShowMore.completenessShowMore,
            "timelinessShowMore": allDQShowMore.timelinessShowMore,
            "accuracyShowMore": allDQShowMore.accuracyShowMore,
            "conformityShowMore": allDQShowMore.conformityShowMore,
            "consistencyShowMore": allDQShowMore.consistencyShowMore,
            "driftShowMore": allDQShowMore.driftShowMore,
            "uniquenessShowMore": allDQShowMore.uniquenessShowMore,
        }
        const prefetchCompleted = this.props.monitorModuleProps.driftAttributeInfoCount > 0;
        let dqSchema = yup.object({
            // Monitor optimization
            // datasource: yup.string().required("Datasource is a required field"),
            metrics: yup.string().required("Metrics Section is a required field"),
        });
        let ranges = {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        };


        let dataSourceOption = [];
        if (this.props.integrationMapping !== undefined){
            for (const [key, value] of Object.entries(this.props.integrationMapping)) {
                if (key !== undefined && value !== undefined) {
                    dataSourceOption.push({ "label": value, "value": key });
                }
            }
        }
        let dataSetOptions = [];
        if (this.props.dataModule.dataSets !== undefined){
            for (const [key, value] of Object.entries(this.props.dataModule.dataSets)) {
                if (key !== undefined && value !== undefined){
                    dataSetOptions.push({ "label": value, "value": key });
                }
            }
        }
        let selectedDataSource = this.props.selectedDataSrc;
        // let lastProfilingTimeDict = this.props.dataModule.lastProfilingTime;
        let errorsNotYetComputed = true
        if(this.props.monitorModuleProps.dqPreview !== undefined && this.props.monitorModuleProps.dqPreview.dq_metrics !== undefined ){
            errorsNotYetComputed = Object.keys(this.props.monitorModuleProps.dqPreview.dq_metrics).length === 0;
        }
        let lastProfiledTime = this.props.last_processed_time;

//        if (this.state.selectedDataSrc === null && Object.keys(lastProfilingTimeDict).length > 0) {
//            let maxLastProfilingTime = Object.keys(lastProfilingTimeDict).reduce((a, b) => Number(new Date(lastProfilingTimeDict[a])) > Number(new Date(lastProfilingTimeDict[b])) ? a : b);
//
//            lastProfiledTime = lastProfilingTimeDict[maxLastProfilingTime];
//        }
        // this.props.monitorModuleProps.consistencyDataSetInfo, this.props.monitorModuleProps.consistencyAttributeInfo
        const hasValid = hasValidNumberOfDatasets(this.state.integrationsMap, selectedDataSource);

        let lastProfilingTime = lastProfiledTime ?
            `Your data last profiled at ${lastProfiledTime}` : "Data Profiling is In progress ...";

        if (!hasValid && this.state.selectedDataSrc !== undefined && this.state.selectedDataSrc !== null) {
            lastProfilingTime = NO_DATASET_NO_DATA_PROFILE;
        }

        let noDataFoundMessage = `Data profiling is in-progress`;
        if (!hasValid) {
            noDataFoundMessage = NO_DATASET_NO_ERROR;
        } else if (lastProfilingTime !== undefined && lastProfilingTime !== null && lastProfilingTime !== 'Data Profiling is In progress ...') {
            //data profiling completed. But no charts available
            noDataFoundMessage = "No Errors found in the configured Data Source(s)"
        }

        lastProfilingTime= this.props.last_processed_time === null ? "--" : `Your data last profiled at ${this.props.last_processed_time}`;
        if (this.state.noIntegration === true) {
            noDataFoundMessage = null
            lastProfilingTime = "No data available"
        }
        else if(this.state.inProgress === true && this.state.noDataFound === false && this.state.noIntegration === false){
            lastProfilingTime = "Data profiling is in-progress"
            noDataFoundMessage = "Data profiling is in-progress"
        }


        return (

            <>
                {
                    <div className="qd-tab__content-action">
                        <div className="caption">
                            <div className="alert moniker-alert" role="alert">
                                <p className="mb-0">
                                    <strong> {lastProfilingTime}</strong>
                                </p>
                            </div>
                        </div>
                        <div className="actions flex-shrink-0">
                            <button onClick={this.toggleFilter} className="btn btn-light">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#484848" height="24" className="mr-1"
                                    viewBox="0 0 24 24" width="24">
                                    <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                    <path d="M0 0h24v24H0z" fill="none" />
                                </svg>
                                <span className="dot-symbol"></span>
                                <span>Filters</span>
                            </button>
                        </div>
                        <div className="actions" style={{display:"none"}} >
                            <div className="action-left">
                                <Select
                                    id="chooseDatasourceForDqError"
                                    name="data_sources"
                                    filterOption={({ label }, query) => label.includes(query)}
                                    options={dataSourceOption}
                                    onChange={this.changeSource}
                                    classNamePrefix='form-control'
                                    placeholder="Choose Datasource"
                                    value={this.state.selectedDataSrc}
                                />
                            </div>
                            <div className="action-right">
                                <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                    startDate={this.state.startDate}
                                    onApply={this.handleTimeFilter}
                                    endDate={this.state.endDate}
                                    ranges={ranges}>
                                    <i>
                                        <FontAwesomeIcon icon={faCalendar} />
                                    </i>
                                    <span className="d-inline-block">
                                        {this.state.startDate.format(DISPLAY_DATE_FORMAT)} - {this.state.endDate.format(DISPLAY_DATE_FORMAT)}
                                    </span>
                                </DateRangePicker>
                                <Formik
                                    enableReinitialize
                                    validationSchema={dqSchema}
                                    onSubmit={this.handleSubmit}
                                    initialValues={this.state.initial}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        setValues,
                                        setFieldValue,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setErrors,
                                        isValid,
                                    }) => (<><Dropdown className="qd_filter-btn d-inline" alignRight show={this.state.showDropDown}>
                                        <Dropdown.Toggle onClick={() => { this.state.showDropDown === true ? this.setState({ showDropDown: false }) : this.setState({ showDropDown: true }) }} id="dropdown-autoclose-false" className="btn-light">
                                            <Image
                                                src="icon_filter"
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-right">
                                            <Form noValidate name="signup"
                                                className="login-form needs-validation"
                                                onSubmit={handleSubmit}>
                                                <div className="qd_filter__body">
                                                    <h4 className="filter_title">Select Date</h4>
                                                    <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                                        startDate={this.state.startDate}
                                                        onApply={this.handleTime}
                                                        endDate={this.state.endDate}
                                                        key={`date_${this.state.keyData}`}
                                                        ranges={ranges}>
                                                        <i>
                                                            <FontAwesomeIcon icon={faCalendar} />
                                                        </i>
                                                        <span
                                                            className="ml-2 d-none d-md-inline-block">
                                                            {this.state.startDate.format(DISPLAY_DATE_FORMAT)} - {this.state.endDate.format(DISPLAY_DATE_FORMAT)}
                                                        </span>
                                                    </DateRangePicker>
                                                    
                                                    <Form.Group>
                                                        <Form.Label>Datasource</Form.Label>
                                                        <Select
                                                            name="datasource"
                                                            id="datasourceNewFilter"
                                                            placeholder="Choose Datasource"
                                                            options={dataSourceOption}
                                                            isInvalid={errors.datasource && touched.datasource}
                                                            onChange={selectedOption => {
                                                                handleChange("datasource")(selectedOption.value.toString());
                                                                this.setState({ selectedDataSrc: selectedOption })
                                                            }}
                                                            classNamePrefix='select-control__control--is-disabled'
                                                            value={this.state.selectedDataSrc}
                                                            isDisabled={true}

                                                        />
                                                        <ErrorMessage component="div" className="error-text" name="datasource" />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Metrics Section</Form.Label>
                                                        <CustomSelect
                                                            isMulti
                                                            isClearable={false}
                                                            name="metrics"
                                                            id="metricsNewFilter"
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            isInvalid={errors.metrics && touched.metrics}
                                                            components={{ Option }}
                                                            classNamePrefix='select-control'
                                                            options={this.state.metrics}
                                                            onChange={selectedOption => {
                                                                if (selectedOption !== null && selectedOption.length !== 0) {
                                                                    handleChange("metrics")(selectedOption[0].value.toString());
                                                                } else {
                                                                    handleChange("metrics")("");
                                                                }
                                                                this.setState({ selectedMetrics: selectedOption })
                                                            }}
                                                            allowSelectAll={true}
                                                            placeholder={getPlaceholder("Choose Metrics Section", this.state.selectedMetrics, this.state.metrics)}
                                                            value={this.state.selectedMetrics}
                                                            controlShouldRenderValue={false}
                                                        />
                                                        <ErrorMessage component="div" className="error-text" name="metrics" />
                                                    </Form.Group>
                                                </div>
                                                <div className="d-flex justify-content-between border-top p-3 my-1">
                                                    <button type="button" className="btn btn-link" onClick={() => {
                                                        this.reset();
                                                        setTimeout(() => {
                                                            setValues(this.state.initial);
                                                            setTimeout(() => {
                                                                setErrors({})
                                                            }, 1);
                                                        }, 500);
                                                    }}>Reset</button>
                                                    <div>
                                                        <Button className="btn-outline btn-grey btn-circle mr-2" onClick={() => {
                                                            setTimeout(() => {
                                                                setErrors({})
                                                            }, 1); this.setState({ showDropDown: false })
                                                        }}>Cancel</Button>
                                                        <Button type="submit" className="btn-primary btn-circle"><FontAwesomeIcon icon={faCheck} /> Apply</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    </>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>

                }
                {/* <CustomTreeFilter 
                dqChartViewMetrics
                filterData={this.state.filterData} /> */}
                {this.state.selectedDataSrc !== null && this.state.selectedDataSrc !=="" && this.state.isFilterMode === true && this.state.noIntegration === false?
                <CustomTreeFilter
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                seletedDatasourceId={this.state.selectedDataSrc.value}
                seletedDatasourceName={this.state.selectedDataSrc.label}
                data={this.props.filterData}
                clearAllFilterData={this.clearAllFilterData}
                clearAllFilter={this.state.clearAllFilter}
                loadDataSourceRender={this.loadDataSourceRender}
                applyDataset={this.applyDataset}
                applyDateFilter={this.applyDateFilter}
                showMetrics={true}
                metrics_data={this.state.metrics}
                />
                                               :"" }

                {
                    this.props.selectedDataSrc !== -1 ?
                        (this.state.visibility["drift"] ? <MonitorPortlet
                            video_url="monitor_data"
                            key={`monitor_drift_${prefetchCompleted}_${canDisableShowMore(this.state.driftShowMore)}`}
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            title={this.state.selectedDataSrc === undefined || this.state.selectedDataSrc === null ? "Data Drift Errors - Across Datasources " : `Data Drift Errors in "${this.state.selectedDataSrc.label}"`}
                            bodyClassName="pb-0"
                            showHelpIcon={true}
                            helpIconTitle="Data Drift Errors - Across Datasources"
                            index={1}
                            id="driftDqError"
                            selectedDataSrc={this.state.drift.selectedDataSrc}
                            changeTime={this.changeTime}
                            content={
                                canDisableShowMore(this.state.driftShowMore) ?
                                    (this.props.showLoader === true?<Load/>:
                                    <NoErrorInPreview
                                        message={noDataFoundMessage}
                                        footer={''}
                                    />) :
                                    <DqErrorGrid
                                        key={"grid_drift"}
                                        metricType="Data Drift"
                                        data={this.state.drift}
                                        mappedDatasetInfo={this.props.mappedDatasetInfo}
                                        selectedDataSource={this.state.selectedDataSrc}
                                        metricsList={this.props.metricsList}
                                        modalData={this.state.driftShowMore}
                                        disableShowMore={canDisableShowMore(this.state.driftShowMore)}
                                        variant="drift"
                                        dataSourceOption={dataSourceOption}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        id="drift"
                                        showFilter={true}
                                        showMoreData={showMoreData}
                                        srcOption={this.state.driftDataSetList}
                                        title="Data Drift Errors - Dashboard"
                                        showLoader={this.props.showLoader}

                                        // new add
                                        seletedDatasourceId={this.state.selectedDataSrc.value}
                                        seletedDatasourceName={this.state.selectedDataSrc.label}
                                        filterData={this.props.filterData}
                                        clearAllFilterData={this.clearAllFilterData}
                                        clearAllFilter={this.state.clearAllFilter}
                                        loadDataSourceRender={this.loadDataSourceRender}
                                        showAttributes={true}
                                        showDatasets={true}
                                        changeGroup={this.changeGroup}
                                        selectedGroup= {this.state.selectedGroup === null ? LIST_VIEW_OPTIONS[0] :  this.state.selectedGroup}
                                        metrics_data={this.state.metrics}
                                        fullscreenview={this.state.isInFullScreenMode}
                                        type="listView"

                                    />}
                        /> : "")
                        : <MonitorPortlet
                            key={`monitor_drift`}
                            video_url="monitor_data"
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            title="Data Drift Errors - Across Datasources"
                            bodyClassName="pb-0"
                            showHelpIcon={true}
                            index={1}
                            id="driftDqErrorLoading"
                            content={
                                errorsNotYetComputed ?
                                    <NoErrorInPreview
                                        message={noDataFoundMessage}
                                        footer={''}
                                    /> :
                                    <Load />
                            }
                        />
                }
                {
                    this.props.selectedDataSrc !== -1 ?
                        (this.state.visibility["completeness"] ? <MonitorPortlet
                            video_url="monitor_data"
                            key={`monitor_completeness_${prefetchCompleted}_${canDisableShowMore(this.state.completenessShowMore)}`}
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            title={this.state.selectedDataSrc === undefined || this.state.selectedDataSrc === null ? "Data Completeness Errors - Across Datasources" : `Data Completeness Errors in "${this.state.selectedDataSrc.label}"`}
                            bodyClassName="pb-0"
                            showHelpIcon={true}
                            index={2}
                            helpIconTitle="Data Completeness Errors - Across Datasources"
                            id="completenessDqError"
                            selectedDataSrc={this.state.completeness.selectedDataSrc}
                            changeTime={this.changeTime}
                            content={
                                canDisableShowMore(this.state.completenessShowMore) ?
                                (this.props.showLoader === true?<Load/>:
                                <NoErrorInPreview
                                    message={noDataFoundMessage}
                                    footer={''}
                                />) :
                                    <DqErrorGrid
                                        key={"grid_completeness"}
                                        metricType="Data Completeness"
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        showMoreData={showMoreData}
                                        dataSourceOption={dataSourceOption}
                                        mappedDatasetInfo={this.props.mappedDatasetInfo}
                                        data={this.state.completeness}
                                        metricsList={this.props.metricsList}
                                        selectedDataSource={this.state.selectedDataSrc}
                                        modalData={this.state.completenessShowMore}
                                        disableShowMore={canDisableShowMore(this.state.completenessShowMore)}
                                        variant="complete"
                                        id="completeness"
                                        showFilter={true}
                                        srcOption={this.state.completenessDataSetList}
                                        title="Data Completeness Errors - Dashboard"
                                    
                                          // new add
                                          seletedDatasourceId={this.state.selectedDataSrc.value}
                                          seletedDatasourceName={this.state.selectedDataSrc.label}
                                            filterData={this.props.filterData}
                                          clearAllFilterData={this.clearAllFilterData}
                                          clearAllFilter={this.state.clearAllFilter}
                                          loadDataSourceRender={this.loadDataSourceRender}
                                          showAttributes={true}
                                          showDatasets={true}
                                          changeGroup={this.changeGroup}
                                          selectedGroup= {this.state.selectedGroup === null ? LIST_VIEW_OPTIONS[0] :  this.state.selectedGroup}
                                          metrics_data={this.state.metrics}
                                          fullscreenview={this.state.isInFullScreenMode}
                                          type="listView"
                                    />}
                        /> : "")
                        : <MonitorPortlet
                            video_url="monitor_data"
                            key={`monitor_completeness`}
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            index={2}
                            title="Data Completeness Errors - Across Datasources"
                            bodyClassName="pb-0"
                            showHelpIcon={true}
                            id="completenessDqErrorLoading"
                            content={
                                errorsNotYetComputed ?
                                    <NoErrorInPreview
                                        message={noDataFoundMessage}
                                        footer={''}
                                    /> :
                                    <Load />
                            }
                        />
                }
                {
                    this.props.selectedDataSrc !== -1 ?
                        (this.state.visibility["uniqueness"] ? <MonitorPortlet
                            video_url="monitor_data"
                            key={`monitor_uniqueness_${prefetchCompleted}_${canDisableShowMore(this.state.uniquenessShowMore)}`}
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            title={this.state.selectedDataSrc === undefined || this.state.selectedDataSrc === null ? "Data Uniqueness Errors - Across Datasources" : `Data Uniqueness Errors in "${this.state.selectedDataSrc.label}"`}
                            bodyClassName="pb-0"
                            showHelpIcon={true}
                            index={3}
                            helpIconTitle="Data Uniqueness Errors - Across Datasources"
                            id="uniquenessDqError"
                            selectedDataSrc={this.state.uniqueness.selectedDataSrc}
                            changeTime={this.changeTime}
                            content={
                                canDisableShowMore(this.state.uniquenessShowMore) ?
                                (this.props.showLoader === true?<Load/>:
                                <NoErrorInPreview
                                    message={noDataFoundMessage}
                                    footer={''}
                                />) :
                                    <DqErrorGrid
                                        key={"grid_uniqueness"}
                                        metricType="Data Uniqueness"
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        showMoreData={showMoreData}
                                        dataSourceOption={dataSourceOption}
                                        mappedDatasetInfo={this.props.mappedDatasetInfo}
                                        data={this.state.uniqueness}
                                        metricsList={this.props.metricsList}
                                        selectedDataSource={this.state.selectedDataSrc}
                                        modalData={this.state.uniquenessShowMore}
                                        disableShowMore={canDisableShowMore(this.state.uniquenessShowMore)}
                                        variant="unique"
                                        id="uniqueness"
                                        showFilter={true}
                                        srcOption={this.state.uniquenessDataSetList}
                                        title="Data Uniqueness Errors - Dashboard"
                                    
                                      // new add
                                      seletedDatasourceId={this.state.selectedDataSrc.value}
                                      seletedDatasourceName={this.state.selectedDataSrc.label}
                                        filterData={this.props.filterData}
                                      clearAllFilterData={this.clearAllFilterData}
                                      clearAllFilter={this.state.clearAllFilter}
                                      loadDataSourceRender={this.loadDataSourceRender}
                                      showAttributes={true}
                                      showDatasets={true}
                                      changeGroup={this.changeGroup}
                                      selectedGroup= {this.state.selectedGroup === null ? LIST_VIEW_OPTIONS[0] :  this.state.selectedGroup}
                                      metrics_data={this.state.metrics}
                                      fullscreenview={this.state.isInFullScreenMode}
                                      type="listView"
                                    />}
                        /> : "")
                        : <MonitorPortlet
                            video_url="monitor_data"
                            key={`monitor_uniqueness`}
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            index={3}
                            title="Data Uniqueness Errors - Across Datasources"
                            bodyClassName="pb-0"
                            showHelpIcon={true}
                            id="uniquenessDqErrorLoading"
                            content={
                                errorsNotYetComputed ?
                                    <NoErrorInPreview
                                        message={noDataFoundMessage}
                                        footer={''}
                                    /> :
                                    <Load />
                            }
                        />
                }
                {
                    this.props.selectedDataSrc !== -1 ?
                        (this.state.visibility["timeliness"] ? <MonitorPortlet
                            key={`monitor_timeliness_${prefetchCompleted}_${canDisableShowMore(this.state.timelinessShowMore)}`}
                            video_url="monitor_data"
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            title={this.state.selectedDataSrc === undefined || this.state.selectedDataSrc === null ? "Data Timeliness Errors - Across Datasources" : `Data Timeliness Errors in "${this.state.selectedDataSrc.label}"`}
                            index={4}
                            bodyClassName="pb-0"
                            helpIconTitle="Data Timeliness Errors - Across Datasources"
                            id="timelinessDqError"
                            selectedDataSrc={this.state.timeliness.selectedDataSrc}
                            changeTime={this.changeTime}
                            showHelpIcon={true}
                            content={
                                canDisableShowMore(this.state.timelinessShowMore) ?
                                (this.props.showLoader === true?<Load/>:
                                <NoErrorInPreview
                                    message={noDataFoundMessage}
                                    footer={''}
                                />) :
                                    <DqErrorGrid
                                        metricType="Data Timeliness"
                                        key={"grid_timeliness"}
                                        showMoreData={showMoreData}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        data={this.state.timeliness}
                                        mappedDatasetInfo={this.props.mappedDatasetInfo}
                                        dataSourceOption={dataSourceOption}
                                        metricsList={this.props.metricsList}
                                        selectedDataSource={this.state.selectedDataSrc}
                                        modalData={this.state.timelinessShowMore}
                                        disableShowMore={canDisableShowMore(this.state.timelinessShowMore)}
                                        id="timeliness"
                                        showFilter={true}
                                        srcOption={this.state.timelinessDataSetList}
                                        variant="timeliness"
                                        title="Data Timeliness Errors - Dashboard"
                                    
                                          // new add
                                          seletedDatasourceId={this.state.selectedDataSrc.value}
                                          seletedDatasourceName={this.state.selectedDataSrc.label}
                                          filterData={this.props.filterData}
                                          clearAllFilterData={this.clearAllFilterData}
                                          clearAllFilter={this.state.clearAllFilter}
                                          loadDataSourceRender={this.loadDataSourceRender}
                                          showAttributes={true}
                                          showDatasets={true}
                                          changeGroup={this.changeGroup}
                                          selectedGroup= {this.state.selectedGroup === null ? LIST_VIEW_OPTIONS[0] :  this.state.selectedGroup}
                                          metrics_data={this.state.metrics}
                                          fullscreenview={this.state.isInFullScreenMode}
                                          type="listView"
                                    />
                            }
                        /> : "")
                        : <MonitorPortlet
                            key={`monitor_timeliness`}
                            video_url="monitor_data"
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            title="Data Timeliness Errors - Across Datasources"
                            bodyClassName="pb-0"
                            index={4}
                            id="timelinessDqErrorLoading"
                            showHelpIcon={true}
                            content={
                                errorsNotYetComputed ?
                                    <NoErrorInPreview
                                        message={noDataFoundMessage}
                                        footer={''}
                                    /> :
                                    <Load />
                            }
                        />
                }
                {
                    this.props.selectedDataSrc !== -1 ?
                        (this.state.visibility["accuracy"] ? <MonitorPortlet
                            key={`monitor_accuracy_${prefetchCompleted}_${canDisableShowMore(this.state.accuracyShowMore)}`}
                            video_url="monitor_data"
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            title={this.state.selectedDataSrc === undefined || this.state.selectedDataSrc === null ? "Data Accuracy Errors - Across Datasources" : `Data Accuracy Errors in "${this.state.selectedDataSrc.label}"`}
                            index={5}
                            bodyClassName="pb-0"
                            helpIconTitle="Data Accuracy Errors - Across Datasources"
                            id="accuracyDqError"
                            selectedDataSrc={this.state.accuracy.selectedDataSrc}
                            changeTime={this.changeTime}
                            showHelpIcon={true}
                            content={
                                canDisableShowMore(this.state.accuracyShowMore) ?
                                (this.props.showLoader === true?<Load/>:
                                <NoErrorInPreview
                                    message={noDataFoundMessage}
                                    footer={''}
                                />) 
                                :
                                    <DqErrorGrid
                                        key={"grid_accuracy"}
                                        metricType="Data Accuracy"
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        mappedDatasetInfo={this.props.mappedDatasetInfo}
                                        data={this.state.accuracy}
                                        dataSourceOption={dataSourceOption}
                                        metricsList={this.props.metricsList}
                                        showMoreData={showMoreData}
                                        selectedDataSource={this.state.selectedDataSrc}
                                        id="accuracy"
                                        disableShowMore={canDisableShowMore(this.state.accuracyShowMore)}
                                        modalData={this.state.accuracyShowMore}
                                        showFilter={true}
                                        srcOption={this.state.accuracyDataSetList}
                                        variant="accuracy"
                                        title="Data Accuracy Errors - Dashboard"
                                     // new add
                                     seletedDatasourceId={this.state.selectedDataSrc.value}
                                     seletedDatasourceName={this.state.selectedDataSrc.label}
                                     filterData={this.props.filterData}
                                     clearAllFilterData={this.clearAllFilterData}
                                     clearAllFilter={this.state.clearAllFilter}
                                     loadDataSourceRender={this.loadDataSourceRender}
                                     showAttributes={true}
                                     showDatasets={true}
                                     changeGroup={this.changeGroup}
                                     selectedGroup= {this.state.selectedGroup === null ? LIST_VIEW_OPTIONS[0] :  this.state.selectedGroup}
                                     metrics_data={this.state.metrics}
                                     fullscreenview={this.state.isInFullScreenMode}
                                     type="listView"
                                        />
                            }
                        /> : "")
                        : <MonitorPortlet
                            video_url="monitor_data"
                            srcOption={this.state.dataSources}
                            key={`monitor_accuracy`}
                            index={5}
                            className="pb-0" title="Data Accuracy Errors - Across Datasources"
                            bodyClassName="pb-0"
                            id="accuracyDqErrorLoading" showHelpIcon={true}
                            content={
                                errorsNotYetComputed ?
                                    <NoErrorInPreview
                                        message={noDataFoundMessage}
                                        footer={''}
                                    /> :
                                    <Load />
                            }
                        />
                }
                {
                    this.props.selectedDataSrc !== -1 ?
                        (this.state.visibility["conformity"] ? <MonitorPortlet
                            video_url="monitor_data"
                            key={`monitor_conformity_${prefetchCompleted}_${canDisableShowMore(this.state.conformityShowMore)}`}
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            title={this.state.selectedDataSrc === undefined || this.state.selectedDataSrc === null ? "Data Conformity Errors - Across Datasources" : `Data Conformity Errors in "${this.state.selectedDataSrc.label}"`}
                            index={6}
                            bodyClassName="pb-0"
                            helpIconTitle="Data Conformity Errors - Across Datasources"
                            id='conformityDqError'
                            selectedDataSrc={this.state.conformity.selectedDataSrc}
                            changeTime={this.changeTime}
                            showHelpIcon={true}
                            content={
                                canDisableShowMore(this.state.conformityShowMore) ?
                                (this.props.showLoader === true?<Load/>:
                                <NoErrorInPreview
                                    message={noDataFoundMessage}
                                    footer={''}
                                />) :
                                    <DqErrorGrid
                                        key={"grid_conformity"}
                                        metricType="Data Conformity"
                                        showMoreData={showMoreData}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        mappedDatasetInfo={this.props.mappedDatasetInfo}
                                        dataSourceOption={dataSourceOption}
                                        metricsList={this.props.metricsList}
                                        data={this.state.conformity}
                                        selectedDataSource={this.state.selectedDataSrc}
                                        modalData={this.state.conformityShowMore}
                                        disableShowMore={canDisableShowMore(this.state.conformityShowMore)}
                                        id="conformity"
                                        showFilter={true}
                                        srcOption={this.state.conformityDataSetList}
                                        variant="conformity"
                                        title="Data Conformity Errors - Dashboard"
                                      // new add
                                      seletedDatasourceId={this.state.selectedDataSrc.value}
                                      seletedDatasourceName={this.state.selectedDataSrc.label}
                                    filterData={this.props.filterData}
                                      clearAllFilterData={this.clearAllFilterData}
                                      clearAllFilter={this.state.clearAllFilter}
                                      loadDataSourceRender={this.loadDataSourceRender}
                                      showAttributes={true}
                                      showDatasets={true}
                                      changeGroup={this.changeGroup}
                                      selectedGroup= {this.state.selectedGroup === null ? LIST_VIEW_OPTIONS[0] :  this.state.selectedGroup}
                                      metrics_data={this.state.metrics}
                                      fullscreenview={this.state.isInFullScreenMode}
                                      type="listView"
                                    />
                            }
                        /> : "")
                        : <MonitorPortlet
                            video_url="monitor_data"
                            srcOption={this.state.dataSources}
                            key={`monitor_conformity`}
                            index={6}
                            className="pb-0" title="Data Conformity Errors - Across Datasources"
                            bodyClassName="pb-0"
                            id="conformityDqErrorLoading"
                            showHelpIcon={true}
                            content={
                                errorsNotYetComputed ?
                                    <NoErrorInPreview
                                        message={noDataFoundMessage}
                                        footer={''}
                                    /> :
                                    <Load />
                            }
                        />
                }
                {
                    this.props.selectedDataSrc !== -1 ?
                        (this.state.visibility["consistency"] ? <MonitorPortlet
                            key={`monitor_consistency_${prefetchCompleted}_${canDisableShowMore(this.state.consistencyShowMore)}`}
                            video_url="monitor_data"
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            title={this.state.selectedDataSrc === undefined || this.state.selectedDataSrc === null ? "Data Consistency Errors - Across Datasources" : `Data Consistency Errors in "${this.state.selectedDataSrc.label}"`}
                            index={7}
                            helpIconTitle="Data Consistency Errors - Across Datasources"
                            bodyClassName="pb-0"
                            isConsistencyEnabled={this.state.isConsistencyEnabled}
                            id='consistencyDqError'
                            selectedDataSrc={this.state.consistency.selectedDataSrc}
                            changeTime={this.changeTime}
                            showHelpIcon={true}
                            content={
                                canDisableShowMore(this.state.consistencyShowMore) ?
                                (this.props.showLoader === true?<Load/>:
                                <NoErrorInPreview
                                    message={noDataFoundMessage}
                                    footer={''}
                                />) :
                                    <DqErrorGrid
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        key={"grid_consistency"}
                                        showMoreData={showMoreData}
                                        mappedDatasetInfo={this.props.mappedDatasetInfo}
                                        data={this.state.consistency}
                                        metricsList={this.props.metricsList}
                                        dataSourceOption={dataSourceOption}
                                        selectedDataSource={this.state.selectedDataSrc}
                                        id="consistency"
                                        metricType="Data Consistency"
                                        showFilter={true}
                                        modalData={this.state.consistencyShowMore}
                                        disableShowMore={canDisableShowMore(this.state.consistencyShowMore)}
                                        srcOption={this.state.consistencyDataSetList}
                                        variant="consistency"
                                        title="Data Consistency Errors - Dashboard"
                                      // new add
                                      seletedDatasourceId={this.state.selectedDataSrc.value}
                                      seletedDatasourceName={this.state.selectedDataSrc.label}
                                      filterData={this.props.filterData}
                                      clearAllFilterData={this.clearAllFilterData}
                                      clearAllFilter={this.state.clearAllFilter}
                                      loadDataSourceRender={this.loadDataSourceRender}
                                      showAttributes={true}
                                      showDatasets={true}
                                      changeGroup={this.changeGroup}
                                      selectedGroup= {this.state.selectedGroup === null ? LIST_VIEW_OPTIONS[0] :  this.state.selectedGroup}
                                      metrics_data={this.state.metrics}
                                      fullscreenview={this.state.isInFullScreenMode}
                                      type="listView"
                                    />
                            }
                        /> : "")
                        : <MonitorPortlet
                            key={`monitor_consistency`}
                            video_url="monitor_data"
                            srcOption={this.state.dataSources}
                            className="pb-0"
                            index={7}
                            title="Data Consistency Errors - Across Datasources"
                            bodyClassName="pb-0"
                            id="consistencyDqErrorLoading"
                            showHelpIcon={true}
                            content={
                                errorsNotYetComputed ?
                                    <NoErrorInPreview
                                        message={noDataFoundMessage}
                                        footer={''}
                                    /> :
                                    <Load />
                            }
                        />
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps,
    { setDataSource, addMonitorDQPreview }

)(DqError);