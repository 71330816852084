import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faEye,
    faTrash,
    faTrashAlt,
    faCheckCircle,
    faBolt,
    faCoins,
    faSpellCheck,
    //    faCodeBranch
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { handleDelete, handleFollowStatus, formDatasource, formPipelinesource} from "../utils/event_handling";
import { get_edit_component } from "./editComponents";
import ViewContent from "./viewContent";
import Row from 'react-bootstrap/Row';
import TestConnection from '../configure/components/testConnection';
import RcTooltip from 'rc-tooltip';
import { connect } from "react-redux";
import {
    addMLModelMapping,
    setMLModel
} from "../redux/actions";
import { TABLE_DELETE_CLASS, TABLE_EDIT_CLASS, TABLE_VIEW_CLASS } from "../utils/constant";
import { DeleteWarningModal } from "./DeleteWarningModal";


class EditActions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            test_conn: null,
            disableDelete: false
        }
        this.get_edit_component = get_edit_component.bind(this);
        this.formDatasource = formDatasource.bind(this);
        this.formPipelinesource = formPipelinesource.bind(this);
        this.setConnection = this.setConnection.bind(this);
        this.renderActionButton= this.renderActionButton.bind(this);
        this.setPipelineSourceConnection = this.setPipelineSourceConnection.bind(this);
    }

    renderActionButton(componentName, onClickFunction, classNameValue,
        buttonIconName,
        toolTipContent = null,
        showButtonText = true) {
        /*
         * This function will render the action button with / without tooltip based on the tooltip content
         */


        if (toolTipContent === undefined || toolTipContent === null) {
            return (
                <RcTooltip placement="top"
                    overlay={
                        <span id={'tooltip-top'}>
                            {'View'}
                        </span>
                    }
                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                <button onClick={onClickFunction} type="button"
                    className={classNameValue}>
                    <i>
                        <FontAwesomeIcon icon={buttonIconName} />
                    </i>
                    {showButtonText ? <span>{componentName}</span> : ''}
                </button>
                </RcTooltip>
            );
        }

        // We need to show tooltip since tooltip content is not null
        return (
            <>
                <RcTooltip placement="top"
                    overlay={
                        <span id={'tooltip-top'}>
                            {'View'}
                        </span>
                    }
                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                    <button onClick={onClickFunction} type="button"
                        className={classNameValue}>
                        <i>
                            <FontAwesomeIcon icon={buttonIconName} />
                        </i>
                        {showButtonText ? <span>{componentName}</span> : ''}
                    </button>
                </RcTooltip>
            </>
        );
    }

    getFormattedKeys(){
       switch(this.props.component){
          case "Datasource":
                return ["Created Time","Datastore Type","Schema Learning Status","Environment Name",
                        "Datasource Name","Datasource Type"]
          case "Model":
                return ["Author","Created Time","Environment Name","Datasource Name","Model Type",
                        "ML Stack","Model Name","Modified Time","Version"]
          case "Metric":
                return ["Created Time","Dataset Name","Metric Name","Formula","Next Run Time",
                        "Schedule Interval"]
          case "Alert":
                return ["Alert Name","Alert Period","Alert Type","Configured Notifications",
                        "Created Time","Alert If","Configured Pipeline Channels"]
          case "Notification Channel":
               return ["Configuration","Notification Name","Created Time","Modified Time"]
          case "Pipeline":
               return ["Configuration","Created Time","Modified Time","Pipeline Channel Name"]
          case "pipelinesource":
                return ["Created Time","Datastore Type","Pipeline Status","Environment Name",
                        "Datasource Name","Datasource Type"]
          default:
              return ''
       }
    }

    loadViewDetails() {
        if (this === undefined || this.props.view_details === undefined) {
            return;
        }

        const details = this.props.view_details;
        const divItems = [];
        let FormattedKeys = this.getFormattedKeys()
        let i = 0
        Object.keys(details).forEach(function (key) {
            let value;
            if (typeof (details[key]) == 'object') {
                value = JSON.stringify(details[key]);
            }
            else {
                value = details[key];
            }
            if (!(["metric_type", "key", "status_code", "checks", "status_text", "pipeline_trigger_type", "consume_group_type", "hide_edit", "auth_config", "integration_config", "features", "model_details"].includes(key)) && key.endsWith("_id") !== true) {
                divItems.push(
                    <div key={key} className="col-md-10 offset-md-1">
                        <div className="form-group row">
                            <label className="col-md-5 text-md-right">{FormattedKeys[i++]} :</label>
                            <div className="col-md-7 text-muted text-break">
                                <span>{value}</span>
                            </div>
                        </div>
                    </div>
                );
            }
        });
        return divItems;
    }

    setConnection() {
        let test_conn_data = this.props.view_details;
        test_conn_data["is_config_details"] = false;
        this.formDatasource(this.props.view_details);
        this.props.ConnectionShow();

    }
    setPipelineSourceConnection() {
        let test_conn_data = this.props.view_details;
        test_conn_data["is_config_details"] = false;
        this.formPipelinesource(this.props.view_details);
        this.props.ConnectionShow();

    }

    render() {
        // Currently we will be showing View and Edit button with tooltip warning messages,
        // if any warning messages are to be shown

        const isModel = this.props.component === "Model";
        let showButtonText = isModel ? false : true;
        let viewTooltip = isModel ? "View" : this.props.tooltipContent;
        let editTooltip = isModel ? "Edit" : this.props.tooltipContent;

        return (
            <>
                {
                    this.renderActionButton('View',
                        this.props.viewShow,
                        TABLE_VIEW_CLASS,
                        faEye,
                        viewTooltip,
                        showButtonText)
                }

                {/* {
                    this.renderActionButton('Edit',
                        this.props.handleShow,
                        TABLE_EDIT_CLASS,
                        faEdit,
                        editTooltip,
                        showButtonText)
                } */}
                {(editTooltip === undefined || editTooltip === null) ?
                <RcTooltip placement="top"
                    overlay={
                        <span id={'tooltip-top'}>
                            {'Edit'}
                        </span>
                    }
                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.handleShow();
                            this.props.component === "Datasource" && this.props.setAddDS(false)
                        }}
                        type="button"
                        className={TABLE_EDIT_CLASS}>
                        <i>
                            <FontAwesomeIcon icon={faEdit} />
                        </i>
                        {showButtonText ? <span>{"Edit"}</span> : ''}
                    </button>
                    </RcTooltip>

                    :

                    <RcTooltip placement="top"
                        overlay={
                            <span id={'tooltip-top'}>
                                {'Edit'}
                            </span>
                        }
                        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                    >
                        <button
                            onClick={(e) => {
                                e.preventDefault();

                                this.props.handleShow();
                            }}
                            type="button"
                            className={TABLE_EDIT_CLASS}>
                            <i>
                                <FontAwesomeIcon icon={faEdit} />
                            </i>
                            {showButtonText ? <span>{"Edit"}</span> : ''}
                        </button>
                    </RcTooltip>

                }



                <RcTooltip placement="top"
                    overlay={
                        <span id={'tooltip-top'}>
                            {'Delete'}
                        </span>
                    }
                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                    <button onClick={this.props.deleteShow} type="button"
                        className={TABLE_DELETE_CLASS}>
                        <i>
                            <FontAwesomeIcon icon={faTrash} />
                        </i>
                        {showButtonText ? <span>{"Delete"}</span> : ''}
                    </button>
                </RcTooltip>



                {
                    this.props.component === "Datasource" ?
                    <RcTooltip placement="top"
                        overlay={
                            <span id={'tooltip-top'}>
                                {'Test Connection'}
                            </span>
                        }
                        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                    >
                        <button type="button"
                            onClick={this.setConnection}
                            className="btn btn-sm btn-outline-dark mr-1">
                            <i><FontAwesomeIcon icon={faCheckCircle} /></i>
                            <span>Test Connection</span>
                        </button>
                    </RcTooltip>
                        :
                        ''
                }
{/* {
                    this.props.component === "Pipeline source" ?
                        <button type="button"
                                onClick={this.setPipelineSourceConnection}
                                className="btn btn-sm btn-outline-dark mr-1">
                            <i><FontAwesomeIcon icon={faCheckCircle}/></i>
                            <span>Test Connection</span>
                        </button>
                        :
                        ''
                } */}
{
                    this.props.component === "Pipeline source" ?
                        <button type="button"
                                onClick={this.setPipelineSourceConnection}
                                className="btn btn-sm btn-outline-dark mr-1">
                            <i><FontAwesomeIcon icon={faCheckCircle}/></i>
                            <span>Test Connection</span>
                        </button>
                        :
                        ''
                }

                {this.props.followComponent !== undefined ?
                    /*<button type="button"
                            onClick={handleFollowStatus.bind(this, this.props,
                                this.props.followComponent,
                                this.props.followStatus,
                                this.props.updateFollowStatus)}
                            className="btn btn-sm btn-outline-info
                                        show-danger-toast trigger_play-pause">
                        <i><FontAwesomeIcon className="mr-0" icon={this.props.followStatus ?
                            faPlay : faPause}/>
                        </i>
                    </button>*/
                    <div className="custom-control custom-switch custom-switch-sm">
                        <input type="checkbox"
                            checked={this.props.followStatus}
                            onChange={handleFollowStatus.bind(this, this.props,
                                this.props.followComponent,
                                this.props.followStatus,
                                this.props.updateFollowStatus)
                            }
                            className="custom-control-input"
                            id={this.props.view_details.key} />
                        <label className="custom-control-label" htmlFor={this.props.view_details.key} />
                    </div>
                    :
                    ''
                }

                {this.props.component === "Pipeline Channel" ?
                    <>
                        <button type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                let details = this.props.view_details;
                                localStorage.setItem('pipelineTriggerName', details.pipeline_trigger_name);
                                localStorage.setItem('pipelineTriggerType', details.pipeline_trigger_type);
                                localStorage.setItem('pipelineTriggerId', details.pipeline_trigger_id);
                                this.props.changeTab("alert", "dq")
                            }}
                            className="btn btn-sm btn-outline-purple mr-1"><i><FontAwesomeIcon icon={faBolt} /></i>
                            <span>DQ Alert</span></button>
                        {this.props.hideModelAlertsTab ? "" : <button type="button" onClick={(e) => {
                            e.preventDefault();
                            let details = this.props.view_details;
                            localStorage.setItem('pipelineTriggerName', details.pipeline_trigger_name);
                            localStorage.setItem('pipelineTriggerType', details.pipeline_trigger_type);
                            localStorage.setItem('pipelineTriggerId', details.pipeline_trigger_id);
                            this.props.changeTab("alert", "ml")
                        }}
                            className="btn btn-sm btn-outline-brown mr-1"><i><FontAwesomeIcon icon={faCoins} /></i>
                            <span>Model Alert</span></button>}
                    </>
                    : ''
                }

                {
                    // For Create Model AB configuration button in ML Models table
                    this.props.modelABState === undefined ?
                        '' :
                        <RcTooltip placement="top"
                            overlay={
                                <span id={'tooltip-top'}>
                                    {'Create Model AB Test'}
                                </span>
                            }
                            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                        >
                            <button onClick={this.props.modelABShow} type="button"
                                className="btn btn-sm btn-outline-info mr-1">
                                <i>
                                    <FontAwesomeIcon icon={faSpellCheck} />
                                </i>
                                <span />
                            </button>
                        </RcTooltip>

                }

                {/* {
                    // For Create Model Version button in ML Models table
                    this.props.modelVersionState === undefined ?
                        '' :
                        <RcTooltip placement="top"
                                        overlay={
                                            <span id={'tooltip-top'}>
                                                {'Create new version of the model'}
                                            </span>
                                        }
                                        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                        >

                            <button onClick={this.props.modelVersionShow} type="button"
                                    className="btn btn-sm btn-outline-purple action__to-show-1 mr-1">
                                <i><FontAwesomeIcon icon={faCodeBranch}/></i>
                                <span/>
                            </button>

                        </RcTooltip>
                } */}

                <Modal show={this.props.view} size="lg" centered onHide={this.props.viewClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {this.props.modalTitle !== undefined ? `${this.props.modalTitle} Details` : `${this.props.component} Details`}
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="py-3">
                            {<ViewContent component={this.props.component} viewDetails={this.props.view_details} />}
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal show={this.props.connection_show} size="lg" centered onClick={this.props.updateTable} onHide={this.props.connectionClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            Test Connection
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="py-3">
                            {<TestConnection testConnection_data={this.state.test_conn} testConnection={true} />}
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal show={this.props.canShowDelWarning} centered onHide={this.props.closeDeleteWarning}>
                    <Modal.Header closeButton className="d-none">
                        <h5 className="modal-title" id="modelDeleteWarningForAB">
                            Model Delete
                        </h5>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="modal__alert">
                            <div className="modal_alert-icon">
                                <i className="text-danger">
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </i>
                            </div>

                            <div className="modal_alert-text">
                                <h4>You have Model AB tests configured for the selected model</h4>
                                <p>Please delete the model AB configurations which use this model
                                    before trying to delete it</p>
                            </div>

                            <div className="modal_alert-btn-group">
                                <button type="button" onClick={this.props.closeDeleteWarning}
                                    className="btn btn-outline btn-grey btn-circle mr-2">Close
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <DeleteWarningModal delete_show={this.props.delete_show}
                    deleteClose={this.props.deleteClose}
                    modalTitle={this.props.modalTitle}
                    component={this.props.component}
                    disableDelete={this.state.disableDelete}
                    handleDeleteFunction={handleDelete.bind(this, this.props,
                        this.props.delete_component,
                        this.props.deleteClose)}
                >
                </DeleteWarningModal>


                <Modal show={this.props.show} size="xl" centered onHide={this.props.handleClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {this.props.modalTitle !== undefined ? `Edit ${this.props.modalTitle} Details` : `Edit ${this.props.component}`}
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        {this.get_edit_component(this.props)}
                    </Modal.Body>
                </Modal>

                {/* Model AB Configuration model*/}
                {this.props.modelABState ?
                    this.props.showModelABPopup(this.props, this.props.modelABState,
                        this.props.modelABClose, this.props.handleModelABAddition) :
                    ''
                }

                <Modal show={this.props.modelVersionState}
                    size="xl"
                    centered onHide={this.props.modelVersionClose}>

                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="modelVersionCreation">
                            Model Version
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        {/*{this.get_edit_component(this.props)}*/}
                    </Modal.Body>
                </Modal>

            </>
        );

    }
}
//export default EditActions;
const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps, {
    addMLModelMapping,
    setMLModel
}
)(EditActions);
