export const CHART_TITLE_SEPARATOR = " > ";
export const CHART_TITLE_METRIC_SEPARATOR = " - drift in ";
//export const PROTOCOL = window.location.protocol;
 export const PROTOCOL = "https:";
// export const PROTOCOL = "http:";

export const LIST_VIEW_OPTIONS = [{"label": "Dataset + Attribute", "value": "all"},
                                  {"label": "Dataset Level", "value": "datasets"},
                                 {"label": "Attribute Only", "value": "attributes"},
                                 {"label": "Followed Attribute Only", "value": "followedAttributes"}]
export const BUTTON_WAIT_TIME = 2000;
export const ENVIRONMENT = 0;
export const DATA_SOURCE = 1;
export const DATA_SET = 2;
export const ATTRIBUTE = 3;

export const PRIVACY_POLICY_LINK = "https://www.termsfeed.com/live/239e61f8-71af-48ac-8bb8-67cd9ddd24c9"
export const COOKIE_POLICY_LINK = "https://www.termsfeed.com/live/3b7b5f77-42fb-4494-98ad-7b1155fc05b7"
export const TERMS_CONDITIONS_LINK = "https://www.termsfeed.com/live/51fabc0d-1f6b-4290-a0c0-d7ce653cb580"

export const NO_DATA_SET_FOUND_TEXT = "No Datasets found yet";
export const NO_PIPELINES_FOUND = "No Pipelines found yet"

//status code for default metrics run failure
export const DEFAULT_METRICS_RUN_FAILURE = -2;

export const RUN_IN_QUEUE = 0;

export const RUN_TRIGGERED = 200;
export const RUN_STARTED = 201;

export const RUN_PROGRESS_STATES = [RUN_IN_QUEUE, RUN_TRIGGERED, RUN_STARTED];

export const RUN_SUCCESS = 1;
// status code when qualdo skips mcf computation
export const MCF_SKIPPED = -3

// Currently the display message is same as text. But if required the tooltip display can be changed later
export const NO_DATA_SET_FOUND_TOOLTIP = "No Datasets found yet";

// The display message that we show on top of Profile/Monitor page
export const NO_DATASET_NO_DATA_PROFILE = "No data available to profile";
export const NO_DATASET_NO_ERROR = "No data available for processing. Kindly check the configured datasource.";
export const DATA_PROFILING_FAILED = "No data available to profile";
export const ROW_WARN_CLASS = "has-table-warning";
export const ROW_ERROR_CLASS = "has-table-error";


export const configure = {
    ML_MODEL_TYPE: "ML",
    DNN_MODEL_TYPE: "DNN",
    CNN_MODEL_TYPE: "CNN"
}

export const DQ_MONITOR_SECTIONS = {
    COMPLETENESS: "completeness",
    UNIQUENESS: "uniqueness",
    TIMELINESS: "timeliness",
    ACCURACY: "accuracy",
    CONFORMITY: "conformity",
    CONSISTENCY: "consistency",
    DRIFT: "drift",
}

export const defaultMetrics = {
    defaultQuaityGatesCount: 22
}

export const ALERT_NAME_DESC = {
    "Feature Drift" : " Feature Drift of Serving Data vs Training Data",
    "Model Drift": " Feature Drift of Serving Data",
}

export const metricType = {
    DEFAULT: "default",
    COMBINED: "combined"
}

export const triggers = {
    INSTANT_NOTIFICATION: "instant",
    RECURRING_NOTIFICATION: "recurring",
    EMPTY_NOTIFICATION_KEY: "emptyNotificationKey",
    UPDATED_NOTIFICATION_KEY: "updatedNotificationKey",
    EMPTY_ALERT: "emptyAlert",
    EMPTY_PERIOD: "emptyPeriod",
    EMPTY_ASSOCIATED: "emptyAssociated",
    EMPTY_ALERT_SCHEDULE: "emptyAlertScheule",
    EMPTY_DATASET_WITH_KEY: "emptyDatasetWithKey",
    EMPTY_ATTRIBUTE_WITH_KEY: "emptyAttributeWithKey",
    EMPTY_DATA_SOURCE_WITH_KEY: "emptyDataSourceWithKey",
    EMPTY_MODEL_WITH_KEY: "emptyModelWithKey",
    EMPTY_ALERT_MECHANISM_WITH_KEY: "emptyMechanismWithKey",
    UPDATED_ALERT: "updatedAlertTypeWithkey",
    UPDATED_PERIOD: "updatedAlertPeriodWithkey",
    UPDATED_ASSOCIATED: "updatedAssociation",
    UPDATED_ALERT_SCHEDULE: "updatedAlertSchedule",
    UPDATED_DATASET_WITH_KEY: "updatedAlertDatasetWithKey",
    UPDATED_ATTRIBUTE_WITH_KEY: "updatedAlertAttributeWithKey",
    UPDATED_DATASOURCE_WITH_KEY: "updatedAlertDataSourceWithKey",
    UPDATED_MODEL_WITH_KEY: "updatedAlertModelWithKey",
    UPDATED_ALERT_MECHANISM_WITH_KEY: "updatedAlertMechanismWithKey",
    HOUR_SCHEDULE: "1hour",
    DAY_SCHEDULE: "24hours",
    WEEK_SCHEDULE: "1week",
    MONTH_SCHEDULE: "1month",
}

export const dataRunStatuses = {
       "inProgress1": 200,
       "inProgress2": 201,
       "failed": -2,
       "success": 1,
       "inQueue": 0,
       "skipMcf": -3

}

export const IN_PROGRESS_STATUSES = [dataRunStatuses.inProgress1, dataRunStatuses.inProgress2];

export const modelPerformance = {
    CONFUSION_MATRIX: "Confusion matrix",
    F1_SCORE: "f1 score",
    PRECISION: "precision",
    RECALL: "recall",
    MAE: "mae",
    MSE: "mse",
    RMSE: "rmse",
    R2: "r2",
    ROC_CURVE: "ROC Curve",
    SENSITIVITY: "Sensitivity",
    SPECIFICITY: "Specificity",
    ACCURACY: "Accuracy",
    CPU_USAGE: "cpu_usage",
    MEMORY_USAGE: "memory_usage",
    MODEL_LATENCY: "model_latency",
    OVERHEAD_LATENCY: "overhead_latency",
    MEAN_SHAP_VALUE: "mean_shap_value",
    HOMOGENEITY_COMPLETENESS_V_MEASURE_SCORE:"Homogeneity Completeness V-measure Score",
    SILHOUETTE_SCORE: "Silhouette Score"
}

export const chartTypes = {
    CONFUSION_MATRIX: "confusionMatrix",
    HORIZONTAL_BAR_CHART: "horizontalBarChart",
    GROUPED_HORIZONTAL_BAR_CHART: "groupedHorizontalBarChart",
    HORIZONTAL_COMPARE_CHART: "horizontalCompareChart",
    MULTILINE_FEATURE_CHART: "multiLineFeatureChart",
    GROUPED_VERTICAL_BAR_CHART: "groupedVerticalBarChart"
}

export const SEE_FULL_VIEW_ICONS = [chartTypes.MULTILINE_FEATURE_CHART, chartTypes.HORIZONTAL_BAR_CHART, chartTypes.GROUPED_HORIZONTAL_BAR_CHART ]
export const showMoreVariants = ["mlModelPerformanceListView", "mlModelPerformanceChartView",
    "model_perf_comp_view"];

export const UNIQUENESS_METRIC_CARD_ONE = "No. of Datasets having Duplicate"
export const UNIQUENESS_METRIC_CARD_TWO = "Total no of attributes having duplicate values"
export const UNIQUENESS_METRIC_CARD_THREE = "Total no of duplicate rows in Datasets"


export const cardTitlesMap = {
    "Normalization Consistency": "Normalisation Errors",
    "Categorical Ordinal": "Categorical Ordinal Errors",
    "Categorical Encode": "Categorical Encoding Errors",
    "Data Type Consistency": "Data Type Errors",
    "Fill Rate": "Average Fill Rate",
    "Row Count": "Row Count",
    "Unique Values": "Unique Values",
    "Recency": "Average Recency (in days)",
    "URL Conformity": "URL Conformity Errors",
    "Credit Card Conformity": "Credit Card Conformity Errors",
    "Gender Conformity": "Gender Conformity Errors",
    "IP Conformity": "IP Conformity Errors",
    "Http Conformity": "HTTP Conformity Errors",
    "Date Conformity": "Date Conformity Errors",
    "Email Conformity": "Email Conformity Errors",
    "SSN Conformity": "SSN Conformity Errors",
    "Boolean Conformity": "Boolean Conformity Errors",
    "Data Outlier": "Data Outlier Errors",
}

export const chartDataCombinations = [{"combinations": [modelPerformance.PRECISION, modelPerformance.RECALL]}]

export const metricCategory = {
    DQM: "dqm",
    MLM: "mlm"
}

export const RECENCY_INTERNAL_ID = 19;
export const ROW_COUNT_INTERNAL_ID = 25;

export const metricMapping = {
    1: "Accuracy Drift",
    2: "Categorical Encoding Errors",
    3: "Categorical Ordinal Errors",
    4: "Credit Card Conformity Errors",
    5: "Data Drift",
    6: "Data Outliers",
    7: "Data Type Errors",
    8: "Date Conformity Errors",
    9: "Email Conformity Errors",
    10: "Feature Drift",
    11: "Fill Rate",
    12: "Gender Conformity Errors",
    13: "Http Conformity Errors",
    14: "IP Conformity Errors",
    15: "ML Data Consistency",
    16: "Model Drift",
    17: "Normalization Consistency",
    18: "Prediction Drift",
    19: "Recency",
    20: "Response Drift",
    21: "SSN Conformity Errors",
    22: "URL Conformity Errors",
    25 : "Row Count",
    26: "Unique Values",
    27: "Boolean Conformity"
}
export const METRIC_SCALE = {
  "Recency": "days.",
  "Data Drift":"",
};

export const RELATIONSHIP_METRIC = {
    11: "Data Completeness",
    19: "Data Timeliness",
    6: "Data Accuracy Errors",
    "Data Conformity Errors": [4, 8, 9, 12, 13, 14, 21, 22, 27],
    "Data Consistency Errors": [2, 3, 17, 7]
}

export const RELATIONSHIP_METRIC_ORDER = [11, 19, "Data Conformity Errors", 6, "Data Consistency Errors"];


// Time is in minutes. If 2 results of same dataset are within this interval,
// we will consider the results as same refresh
export const BUFFER_TIME_TO_IDENTIFY_REFRESH = 10;


export const RELATIONSHIP_STATIC_CARD = {
    11: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111],
    19: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
    5: [7.0, 9.5, 18.4, 25.2, 23.3, 18.3, 13.9, 9.6],
    6: [3.7, 3.3, 3.9, 5.1, 3.5, 3.8, 4.0, 5.0, 6.1, 3.7, 3.3, 6.4,
        6.9, 6.0, 6.8, 4.4, 4.0, 3.8, 5.0, 4.9, 9.2, 9.6, 9.5, 6.3,
        9.5, 10.8, 14.0, 11.5, 10.0, 10.2, 10.3, 9.4, 8.9, 10.6, 10.5, 11.1,
        10.4, 10.7, 11.3, 10.2, 9.6, 10.2, 11.1, 10.8, 13.0, 12.5, 12.5, 11.3,
        10.1
    ],
    "Data Conformity Errors": [1, 2, 4, 8, 16, 32, 64, 128, 256, 512],
    "Data Consistency Errors": [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
}

export const STATIC_CARDS = [
    [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111],
    [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
    [7.0, 9.5, 18.4, 25.2, 23.3, 18.3, 13.9, 9.6],
    [3.7, 3.3, 3.9, 5.1, 3.5, 3.8, 4.0, 5.0, 6.1, 3.7, 3.3, 6.4, 6.9, 6.0, 6.8, 4.4, 4.0, 3.8, 5.0, 4.9, 9.2, 9.6, 9.5, 6.3, 9.5, 10.8, 14.0, 11.5, 10.0, 10.2, 10.3, 9.4, 8.9, 10.6, 10.5, 11.1, 10.4, 10.7, 11.3, 10.2, 9.6, 10.2, 11.1, 10.8, 13.0, 12.5, 12.5, 11.3, 10.1],
    [1, 2, 4, 8, 16, 32, 64, 128, 256, 512],
    [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111],
    [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111],
    [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
    [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
]


export const THUMBNAIL_COLORS = {
    "VIOLET_VARS": ["#dac8f3", "#9690ea", "#796bef", "#545fef",
        "#689dee"],
    "GREEN_VARS": ["#bdecd0", "#8cf3b7", "#7cf1ac", "#2df381",
        "#06dc55"],
    "ORANGE_VARS": ["#f3e3e0", "#f6cc9a", "#eeae69", "#efbc50",
        "#e0bd40"],
    "RED_VARS": ["#FF6347", "#D3D3D3", "#FF6347", "#D3D3D3"]
};

export const ML_METRIC = {
    24: "Model Performance",
    23: "Model Performance",
    10: "Serving Features Drift Over Training",
    16: "Serving Features Drift",
    15: "ML Data Consistency Error",
}

export const ML_METRIC_2 = {
    24: "Model Performance",
    23: "Model Performance",
    10: "Serving Features Drift Over Training",
    16: "Serving Features Drift",
    15: "ML Data Consistency Error",
    18: "Prediction Drift",
    20: "Response Drift"
}



export const ML_METRIC_ORDER = [24, 23, 15, 16, 10];

export const ML_METRIC_COLOR = {
    1: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    10: {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    15: {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    16: {"cardBackground": "dashboard-stat-danger", "textColor": "text-danger"},
    18: {"cardBackground": "dashboard-stat-purple", "textColor": "text-purple"},
    20: {"cardBackground": "dashboard-stat-royalblue", "textColor": "text-royalblue"},
    24: {"cardBackground": "dashboard-stat-gray", "textColor": "text-gray"},
    23: {"cardBackground": "dashboard-stat-gray", "textColor": "text-gray"}
}

export const COMPLETENESS_METRIC = {
    11: "Fill Rate",
    25: "Row Count"

};
export const DUPLICATE_METRIC = 26
export const UNIQUENESS_METRIC = {
    26: "Unique Values"
}

export const FILL_RATE_RANGE_ONE = "Attribute Fill Rate < 25%"
export const FILL_RATE_RANGE_TWO = "Attribute Fill Rate 25% to 75%"
export const FILL_RATE_RANGE_THREE = "Attribute Fill Rate  > 75%"
export const ROW_COUNT_METRIC_CARD = "Total number of Rows"

export const OUTLIERS_METRIC_CARD = "Data Outliers"
export const WITH_OUTLIERS_METRIC_CARD = "No of Numerical Attributes with Outliers"
export const WITHOUT_OUTLIERS_METRIC_CARD = "No of Numerical Attributes without Outliers"


export const COMPLETENESS_CARDS = {
    [FILL_RATE_RANGE_ONE]: 25, [FILL_RATE_RANGE_TWO]: 75, [FILL_RATE_RANGE_THREE]: 100, [ROW_COUNT_METRIC_CARD]: 100,
}

export const COMPLETENESS_METRIC_COLOR = {
    "Attribute Fill Rate < 25%": {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    "Attribute Fill Rate 25% to 75%": {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    "Attribute Fill Rate  > 75%": {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    "Total number of Rows": {"cardBackground": "dashboard-stat-purple", "textColor": "text-purple"}

}


export const RECENCY_METRIC_CARD_ONE ="Datasets with Recency < One Day"
export const RECENCY_METRIC_CARD_TWO ="Datasets with Recency < One Week"
export const RECENCY_METRIC_CARD_THREE ="Datasets with Recency < One Month"
export const RECENCY_METRIC_CARD_FOUR ="Datasets with Recency > One Month"


export const TIMELINESS_CARDS = {
    "Datasets with Recency < One Day": 1, "Datasets with Recency < One Week": 7, "Datasets with Recency < One Month": 30, "Datasets with Recency > One Month": 9999999
}

export const TIMELINESS_METRIC_COLOR = {
    "Datasets with Recency < One Day": {"cardBackground": "dashboard-stat-purple", "textColor": "text-purple"},
    "Datasets with Recency < One Week": {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    "Datasets with Recency < One Month": {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    "Datasets with Recency > One Month": {"cardBackground": "dashboard-stat-info", "textColor": "text-info"}
}

export const ACCURACY_METRIC = {
    6: "Data Outliers"
};

export const NON_EDITABLE_METRIC = ["Unique Values"];

export const ACCURACY_METRIC_COLOR = {
    5: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    6: {"cardBackground": "dashboard-stat-success", "textColor": "text-success"}
}

export const TIMELINESS_METRIC = {
    19: "Recency"
}

export const CONFORMITY_METRIC = {
    4: "Credit Card Conformity Errors",
    8: "Date Conformity Errors",
    9: "Email Conformity Errors",
    12: "Gender Conformity Errors",
    13: "Http Conformity Errors",
    14: "IP Conformity Errors",
    21: "SSN Conformity Errors",
    22: "URL Conformity Errors",
    27: "Boolean Conformity Errors"
}

export const METRIC_CARD_COLORS = {
    0: { "cardBackground": "dashboard-stat-purple", "textColor": "text-purple", "chartLine": "areachartPurple"  },
    1: { "cardBackground": "dashboard-stat-success", "textColor": "text-success", "chartLine": "areachartsuccess" },
    2: { "cardBackground": "dashboard-stat-warning", "textColor": "text-warning", "chartLine": "areachartwarning" },
    3: { "cardBackground": "dashboard-stat-danger", "textColor": "text-danger", "chartLine": "areachartdanger" },
    4: { "cardBackground": "dashboard-stat-orange", "textColor": "text-orange", "chartLine": "areachartOrange" }
}

export const CONFORMITY_METRIC_COLOR = {
    4: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    8: {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    9: {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    12: {"cardBackground": "dashboard-stat-danger", "textColor": "text-danger"},
    13: {"cardBackground": "dashboard-stat-purple", "textColor": "text-purple"},
    14: {"cardBackground": "dashboard-stat-royalblue", "textColor": "text-royalblue"},
    21: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    22: {"cardBackground": "dashboard-stat-gray", "textColor": "text-gray"},
    27: {"cardBackground": "dashboard-stat-orange", "textColor": "text-orange"}
}

export const RELATIONSHIP_METRIC_COLOR = {
    11: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    19: {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    5: {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    6: {"cardBackground": "dashboard-stat-danger", "textColor": "text-danger"},
    "Data Conformity Errors": {"cardBackground": "dashboard-stat-purple", "textColor": "text-purple"},
    "Data Consistency Errors": {"cardBackground": "dashboard-stat-royalblue", "textColor": "text-royalblue"}
}

export const CONSISTENCY_METRIC = {
    2: "Categorical Consistency Errors",
    17: "Normalization Consistency Errors",
    7: "Data Type Consistency Errors",
}

export const CONSISTENCY_METRIC_COLOR = {
    2: {"cardBackground": "dashboard-stat-info", "textColor": "text-info"},
    3: {"cardBackground": "dashboard-stat-success", "textColor": "text-success"},
    17: {"cardBackground": "dashboard-stat-warning", "textColor": "text-warning"},
    7: {"cardBackground": "dashboard-stat-danger", "textColor": "text-danger"}
}

const compare_a_line_0 = "rgb(250,70,22)";
const compare_a_line_1 = "rgba(250,70,22,0.5)";
const compare_b_line_0 = "rgb(62,155,254)";
const compare_b_line_1 = "rgb(62,155,254, 0.5)";
const compare_c_line_0 = "rgb(78,210,204)";
const compare_c_line_1 = "rgb(78,210,204, 0.5)";

export const CLASS_COLORS = {
    "text-info": {"line": "rgb(76, 163, 255)", "area": "rgba(76, 163, 255,0.2)", "line1": "rgb(121,171,241)",},
    "text-success": {"line": "rgb(78,210,204)", "area": "rgba(78,210,204,0.2)", "line1": "rgb(130,246,185)"},
    "text-warning": {"line": "rgb(255,168,0)", "area": "rgba(255,168,0,0.2)", "line1": "rgb(243,203,111)",},
    "text-danger": {"line": "rgb(246,78,96)", "area": "rgba(246,78,96,0.2)", "line1": "rgb(236,130,142)",},
    "text-purple": {"line": "rgb(145,94,249)", "area": "rgba(145,94,249,0.2)", "line1": "rgb(88,84,137)"},
    "text-royalblue": {"line": "rgb(73,95,184)", "area": "rgba(73,95,184,0.2)", "line1": "rgb(145,164,243)"},
    "text-gray": {"line": "rgb(125,125,125)", "area": "rgba(125,125,125,0.2)", "line1": "rgb(135,85,85)"},
    "text-orange": {"line": "rgb(255,168,0)", "area": "rgba(255,168,0,0.2)", "line1": "rgb(243,203,111)",},
    "compare_a": {"line": compare_a_line_0, "area": "rgba(250,70,22,0.2)", compare_a_line_1,},
    "compare_b": {"line": compare_b_line_0, "area": "rgba(62,155,254,0.2)", "line1": compare_b_line_1,},
    "compare_c": {"line": compare_c_line_0, "area": "rgba(78,210,204,0.2)", "line1": compare_c_line_1,},
    "multi-line-colors": {
        "line1": compare_a_line_0, "line1_a": "rgba(250,70,22,0.5)",
        "line2": compare_b_line_0, "line2_a": compare_b_line_1,
        "line3": compare_c_line_0, "line3_a": compare_c_line_1
    },
}

export const MULTI_LINE_COLORS = "multi-line-colors";

export const MODEL_PERFORMANCE_THRESHOLDS = {
    RECALL: 0.6,
    PRECISION: 0.6,
    F1_SCORE: 0.6,
    ACCURACY: 0.6,
    RMSE: 50,
    SILHOUETTE: 0,
}

export const RECOMMENDATION_MODEL_DEFAULTS = {
    K_VALUE: null
}

export const K_RESULTS = ["Mean Average Recall @K", "Mean Average Precision @K"]
export const COEFFICIENT_VALUES = ["Spearman correlation coefficient", "Pearson's correlation coefficient"]
export const OTHER_ACCURACY_METRIC = ["mse", "mae", "rmse","r2"]

export const MODEL_ACCURACY = "Model Accuracy"
export const MODEL_EVALUATION = "Model Evaluation"
export const MODEL_MONITORING = "Model Monitoring"
export const FEATURE_IMPORTANCE = "Feature Importance"
export const MODEL_EXPLAINABILITY = "Model Explainability"

export const MODEL_RUNTIME_PROFILE = "Model Runtime-Profile";


export const ML_MODEL_WITH_MODEL_EVALUATION_METRICS = ["classification"]

export const MODEL_PERFORMANCE_GROUPS = {
    "Model Accuracy": {
        "data": [],
        "type": "all_accuracy",
        "header": "Model Accuracy Metrics",
        "sub_header": "Following are the charts which show the model accuracy metrics (eg: f1 score, precision, recall) of the configured model",
    },
    "Model Evaluation": {
        "data": [],
        "type": "all_evaluation",
        "header": "Model Evaluation Metrics",
        "sub_header": `Following are the charts which show the model evaluation metrics (eg: TP/TN/FP/FN, AUC) of the configured model`
    },
    "Model Monitoring": {
        "data": [],
        "type": "model_runtime",
        "header": "Model Runtime-Profile Metrics",
        "sub_header": `Following are the charts which show the model runtime-profile metrics (eg: cpu usage, memory usage, latency, invocation) of the configured model`
    },
    "Model Explainability": {
        "data": [],
        "type": "model_explainability",
        "header": "Model Explainability",
        "sub_header": `Following are the charts which show the Model Explainability of the serving data and training data`
    }
};


export const MODEL_PERFORMANCE_RESULT_GROUP_MAP = {
    "precision": MODEL_ACCURACY,
    "Weighted precision": MODEL_ACCURACY,
    "f1 score": MODEL_ACCURACY,
    "Weighted F(1) Score": MODEL_ACCURACY,
    "Accuracy": MODEL_ACCURACY,
    "recall": MODEL_ACCURACY,
    "Weighted recall": MODEL_ACCURACY,
    "Sensitivity": MODEL_ACCURACY,
    "Specificity": MODEL_ACCURACY,
    "precision vs recall": MODEL_ACCURACY,
    "Sensitivity vs Specificity": MODEL_ACCURACY,

    "Confusion matrix": MODEL_EVALUATION,
    "areaUnderPR": MODEL_EVALUATION,
    "areaUnderPR Error": MODEL_EVALUATION,
    "areaUnderROC": MODEL_EVALUATION,
    "areaUnderROC Error": MODEL_EVALUATION,
    "True Positive": MODEL_EVALUATION,
    "False Positive": MODEL_EVALUATION,
    "True Negative": MODEL_EVALUATION,
    "False Negative": MODEL_EVALUATION,
    "ROC Curve": MODEL_EVALUATION,

    "cpu_usage": MODEL_MONITORING,
    "memory_usage": MODEL_MONITORING,
    "model_latency": MODEL_MONITORING,
    "overhead_latency": MODEL_MONITORING,

    "mean_shap_value": MODEL_EXPLAINABILITY,


};

export const ATTRIBUTE_LEVEL_MODEL_ERROR_DESCRIPTIONS = {
    "Model Drift": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "features of the serving data in comparison with themselves over a period of time.",
        "title": "Feature drifts - By Serving data Attributes"
    },
    "Feature Drift": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "features of the serving data in comparison with the same features from training data.",
        "title": "Feature drifts - Serving data vs Training data"
    },
    "Feature Drift_temporal": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "different features used in the serving data over a period of time.",
        "title": "Drifts in the features of the Serving data over a period of time"
    },
    "Prediction Drift": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "prediction of the configured model.",
        "title": "Prediction Metrics - Drifts"
    },
    "Response Drift": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "business response of the configured model.",
        "title": "Business Response Metrics - Drifts"
    },
    "Accuracy Drift": {
        "desc": "Following are the model performance metrics with threshold errors (f1 score, Root Mean Squared Error, etc.).",
        "title": "Key 'Model Performance Metrics' Crossing Thresholds"
    },
    "ML Data Consistency": {
        "desc": "Following are the charts which showcase the errors noticed in " +
            "the consistency of serving data in comparison with the training data at individual features level.",
        "title": "Consistency Errors - Serving data versus Training data"
    },
    "default": {
        "desc": "Following are the charts which showcase the errors in " +
            "Models for ",
        "title": "Errors in the configured Model"
    },
}


export const DATA_SET_LEVEL_MODEL_ERROR_DESCRIPTIONS = {
    "Model Drift": {
        "desc": "Following are the charts which showcase the feature drifts in the " +
            "serving data of the Models",
        "title": "Feature Drifts - Entire Serving dataset"
    },
    "ML Data Consistency": {
        "desc": "Following chart highlights data consistency issues between serving and training data.",
        "title": "Consistency Errors - Serving data vs Training data"
    },
    "default": {
        "desc": "Following are the charts which showcase the errors in " +
            "Models for ",
        "title": "Errors in the configured Model"
    },
    "Custom Metrics": {
        "desc": "Following are the charts which showcase the drifts in the " +
            "custom metrics ",
        "title": "Custom ML Metrics - Drifts"
    },
}

export const SECTION_TYPE_MAPPING = {"type8": "Custom ML Metrics - Drifts" }

export const MODEL_DRIFT = "Model Drift";
// Alias name to be used for Model Drift while showing the chart
export const SERVING_DATA_DRIFT = "Serving Data Drift";
// Alias name for Attribute level Model drift charts
export const FEATURE_DRIFT_TEMPORAL = "Feature Drift in Serving Data";

// Alias name for feature drift in comparison with training attributes

export const FEATURE_DRIFT_ALIAS_SINGLE_ARROW_GREEN_TEXT = "Number of rows in serving data drifted from training data";
export const FEATURE_DRIFT_ALIAS_SINGLE_ARROW_RED_TEXT = "Number of rows in serving data drifting from training data exceeds threshold";

export const FEATURE_DRIFT_ALIAS_GREEN_TEXT = "% drift between serving and training attribute";
export const FEATURE_DRIFT_ALIAS_RED_TEXT = "% drift between serving and training attribute exceeds threshold";

export const FEATURE_DRIFT_ALIASES = [FEATURE_DRIFT_ALIAS_GREEN_TEXT, FEATURE_DRIFT_ALIAS_RED_TEXT];

export const FEATURE_DRIFT = "Feature Drift";

export const ML_BOX_PLOT_LIST = [MODEL_DRIFT, SERVING_DATA_DRIFT, FEATURE_DRIFT_TEMPORAL, FEATURE_DRIFT,
    "Response Drift", "Prediction Drift", "Accuracy Drift"]

export const DEFAULT_METRICS_MAP = {
    "dq": ["Fill Rate", "Data Outlier", "Data Drift",
        "Data Type Consistency", "Normalization Consistency", "Normalisation Consistency",
        "Categorical Encode", "Gender Conformity", "URL Conformity", "IP Conformity",
        "SSN Conformity", "Credit Card Conformity", "Http Conformity",
        "Email Conformity", "Date Conformity", "Boolean Conformity"],
    "recency": ["Recency"],
    "ml": ["Model Drift", "Prediction Drift",
        "Response Drift", FEATURE_DRIFT, "Accuracy Drift", "ML Data Consistency",
        SERVING_DATA_DRIFT, FEATURE_DRIFT_TEMPORAL, ...FEATURE_DRIFT_ALIASES]
}

export function getMetaInformationAboutChart(isDataSet, key) {
    let description;
    let isDefault = DEFAULT_METRICS_MAP.dq.includes(key);
    let metricType = isDefault ? "default" : "custom";
    let header = ''
    switch(key) {
       case "Data Drift":
           if (isDataSet) {
                 description = "Following are the charts which showcase the net aggregated data drift for the selected dataset(s)."
                 header = "Data Drift Errors - By Datasets";
            }
           else {
                  description = "Following are the charts which showcase data drift errors for a selected attribute."
                  header = "Data Drift Errors - By Attributes";
           }
           break
       case "Data Outlier":
           if (isDataSet) {
                 description = "Following are the charts which showcase data accuracy drift at a dataset level"
                 header = "Data Accuracy Errors - Dataset";
            }
           else {
                  description = "Following are the charts which showcase data accuracy drifts for a selected attribute."
                   header = "Data Accuracy Errors - By Attributes";
           }
           break
       case "Fill Rate":
           if (isDataSet) {
                 description = "Following are the charts which showcase fill rate drift at a dataset level"
                 header = "Data Completeness Drift - Dataset";
            }
           else {
                  description = "Following are the charts which showcase fill rate drifts for a selected attribute."
                   header = "Data Completeness Drift - By Attributes";
           }
           break
       case "Row Count":
            if (isDataSet) {
                 description = "Following are the charts which showcase row count drift at a dataset level"
                 header = "Data Completeness - Row Count Drift - Dataset";
            }
           else {
                  description = "Following are the charts which showcase row count drifts for a selected attribute."
                   header = "Row Count Drift - By Attributes";
           }
           break
       case "Unique Values":
            if (isDataSet) {
                 description = "Following are the charts which showcase Unique Values drift at a dataset level"
                 header = "Data Uniqueness Drift - Dataset";
            }
           else {
                  description = "Following are the charts which showcase Unique Values drifts for a selected attribute."
                   header = "Data Uniqueness Drift - By Attributes";
           }
           break
       case "Recency":
           if (isDataSet) {
                 description = "Following are the charts which showcase timeliness drift at a dataset level"
                 header = "Data Timeliness Drift - Dataset";
            }
           else {
                  description = "Following are the charts which showcase timeliness drifts for a selected attribute."
                   header = "Data Timeliness Drift - By Attributes";
           }
           break
       default:
           if (isDataSet) {
                 description = `Following are the charts which showcase the drifts in custom metric ${key} at a dataset level`
                 header = `Drifts in the custom metric ${key} - Dataset`;
            }
           else {
                  description = `Following are the charts which showcase errors in custom metric ${key} for a selected attribute.`
                  header = `Drifts in the custom metric ${key} - By Attributes`;
           }
    }
    if ( key.includes("Consistency") || key.includes('Categorical' ) || key.includes('Conformity')) {
       if (isDataSet) {
                 description = "Following are the charts which showcase " + key +" errors at a dataset level"
                 header = key + " Errors - Dataset";
            }
       else {
                  description = "Following are the charts which showcase "+ key +" errors for a selected attribute."
                  header = key +" Errors - By Attributes";
           }
    }
    return {
        "header": header,
        "description": description,
        "metricType": metricType
    }
}


export const MODEL_PERFORMANCE_DEFAULT_CHART_MAPPING = {
    "True Positive": "barChart",
    "False Positive": "barChart",
    "True Negative": "barChart",
    "False Negative": "barChart",
    "areaUnderPR": "areaChart",
    "areaUnderROC": "areaChart",
    "areaUnderROC Error": "areaChart",
    "areaUnderPR Error": "areaChart",
}

export const DEFAULT_ML_METRICS = ["Model Drift", "Feature Drift", "Prediction Drift",
    "Response Drift", "Accuracy Drift", "ML Data Consistency"];


export const CUSTOM_ML_METRIC ="Custom Metrics";
export const DATASET_LEVEL_ALLOWED_ML_METRICS = ["Model Drift", "ML Data Consistency"];
export const ATTRIBUTE_LEVEL_ALLOWED_ML_METRICS = ["Model Drift", "Prediction Drift",
    "Response Drift", "Feature Drift", "Accuracy Drift"];

export const MODEL_ALERT_ALLOWED_METRICS = {
    "feature": ["Feature Drift", "ML Data Consistency", "Model Drift"],
    "accuracy": ["Accuracy Drift"],
    "prediction": ["Prediction Drift"],
    "response": ["Response Drift"],
    }

export const DQ_ALERT_ALLOWED_METRICS = {
    "dataset_level": ["Fill Rate", "Row Count", "Recency", "Data Outlier", "Data Drift",
    "Unique Values"],
    "attribute_level_num": ["Fill Rate", "Data Outlier", "Data Drift"],
    "attribute_level_str": ["Fill Rate", "Data Drift"]
    }

/*
* Contains keys required to show applicable metrics for custom alerts.
* Refered from qualdo/compute_engine/compute_measure/estimators/datatypes.py - class DataType
 */
export const CONFORMITY_METRICS_CUSTOM_ALERTS = {
    "email": "Email Conformity",
    "url": "URL Conformity",
    "boolean": "Boolean Conformity",
    "date": "Date Conformity",
    "gender": "Gender Conformity",
    "http": "HTTP Conformity",
    "ip": "IP Conformity",
    "ssn": "SSN Conformity",
    "credit_card": "Credit Card Conformity"
    }

export const REQUIRES_FILTER = ["Prediction Drift", "Response Drift"];

/*
* Contains keys required to access model related sections.
* For Feature Drift we didn't have columnKey currently.
* There can be 2 type of columnKey for Feature Drift
* Training feature x Serving feature
 */
export const ML_FEATURE_KEYS = {
    "Prediction Drift": {
        "key": "prediction",
        "columnKey": "target_column_names"
    },
    "Response Drift": {
        "key": "response",
        "columnKey": "target_column_names"
    },
    "Feature Drift": {
        "key": "feature",
    },
    "Accuracy Drift": {
        "key": "accuracy",
        "columnKey": "target_column_names"
    }
}

export const MODEL_PERFORMANCE_COMBINATION_CHART = [
    {"y": modelPerformance.PRECISION, "y1": modelPerformance.RECALL},
    {"y": modelPerformance.SENSITIVITY, "y1": modelPerformance.SPECIFICITY}];

/*
* Contains priority score for each ml model metric for sorting purpose
*
* */
export const DEFAULT_ML_METRIC_PRIORITY = {
    "Model Drift": {"Dataset": 3, "Attribute": 4},
    "Feature Drift": {"Dataset": 100, "Attribute": 5},
    "Prediction Drift": {"Dataset": 10, "Attribute": 6},
    "Response Drift": {"Dataset": 10, "Attribute": 7},
    "Accuracy Drift": {"Dataset": 10, "Attribute": 1},
    "ML Data Consistency": {"Dataset": 2, "Attribute": 100},
}


/*
* Contains priority score for each metric for sorting purpose
*
* */
export const DEFAULT_METRIC_PRIORITY = {
    "Fill Rate": 10,
    "Row Count": 10,
    "Unique Values": 10,
    "Recency": 10,
    "Data Drift": 10,
    "Data Outlier": 10,
    "Data Type Consistency": 6,
    "Gender Conformity": 10,
    "Model Drift": 10,
    "Feature Drift": 10,
    "Prediction Drift": 10,
    "Response Drift": 10,
    "Email Conformity": 10,
    "Accuracy Drift": 10,
    "ML Data Consistency": 10,
    "SSN Conformity": 10,
    "Credit Card Conformity": 10,
    "Http Conformity": 10,
    "URL Conformity": 10,
    "IP Conformity": 10,
    "Normalization Consistency": 9,
    "Categorical Encode": 7,
    "Categorical Ordinal": 8,
    "Date Conformity": 10,
    "Boolean Conformity": 10,
    "Silhouette Score": 8,
    "Davies Bouldin Score": 9,
    "Calinski Harabasz Score": 10
}


// we are using "cmVzZXRQYXNzd29yZAo=", "b25lCg==", "emVybwo=" 
// instead of reset_password, 1, 0  respectively
// to avoid users to change it in inspect window.

export const MANDATE_RESET_PASSWORD = {
    "Reset_Password": "cmVzZXRQYXNzd29yZAo=",
    "One": "b25lCg==",
    "Zero": "emVybwo="
}


/*
* Contains priority score for each error for sorting purpose
*
* */
export const DEFAULT_ERROR_PRIORITY = {
    "NO_DRIFT": 10,
    "not_computed": 10,
    "Threshold Alert": 1
}

export const MODEL_PERFORMANCE_PREVIEW_PAGE = [modelPerformance.F1_SCORE,
    modelPerformance.PRECISION, modelPerformance.RECALL,
    modelPerformance.ACCURACY,
    modelPerformance.SENSITIVITY, modelPerformance.SPECIFICITY];

export const MODEL_MONITOR_PREVIEW_PAGE = [modelPerformance.CPU_USAGE,
    modelPerformance.MEMORY_USAGE, modelPerformance.MODEL_LATENCY,
    modelPerformance.OVERHEAD_LATENCY]

export const MIN_MAX_SCALE = "minMaxScale";

export  const DATA_CONSISTENCY = "Data Consistency";

export const PAYMENT_CARD_TYPES = {
    "visa": "creditCard_visa",
    "mastercard": "creditCard_master",
    "american-express": "creditCard_amex",
    "diners-club": "",
    "discover": "creditCard_discover",
    "jcb": "creditCard_jcb",
    "union-pay": "",
    "maestro": "",
};


export const QualdoPlanDurations = {
    MONTHLY: 0,
    YEARLY: 1
}


export const QualdoPlanCycle = {
    MONTHLY: "monthly",
    YEARLY: "annually",
    TRIAL_14_DAYS: "14_days",
}


export const DateParts = {
    YEAR: 0,
    MONTH: 1,
    DAY: 2,
}


export const QualdoEditionMapping = {
    "MME" : "Model Monitoring Edition",
    "DQE" : "Data Quality Edition",
    "EE" : "Enterprise Edition"
}

export const QualdoEditions = {
    DQ_EDITION: 0,
    ML_EDITION: "Model Monitoring Edition",
    ENTERPRISE_EDITION: 2
}

export const QualdoPlanVersions = {
    FREE: "free",
    STARTER: "starter",
    PROFESSIONAL: "professional"
}

export const DQ_CHART_POINTS = "dq_chart_points";

export const SUBSCRIPTION_PLANS = {
                "monthly": {
                    "free": {
                        "title": "Basic",
                        "price": "0",
                        "duration": "month",
                        "details": {
                            "currency": "$",
                            "data_size": 10,
                            "ml_models_size": 2,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    },
                    "starter": {
                        "title": "Starter",
                        "price": "79",
                        "duration": "month",
                        "details": {
                            "currency": "$",
                            "data_size": 100,
                            "ml_models_size": 10,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    },
                    "professional": {
                        "title": "Professional",
                        "price": "295",
                        "duration": "month",
                        "details": {
                            "data_size": 750,
                            "ml_models_size": 40,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    }
                },
                "annually": {
                    "free": {
                        "title": "Basic",
                        "price": "0",
                        "duration": "month",
                        "details": {
                            "currency": "$",
                            "data_size": 10,
                            "ml_models_size": 2,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    },
                    "starter": {
                        "title": "Starter",
                        "price": "61",
                        "duration": "month",
                        "details": {
                            "currency": "$",
                            "data_size": 100,
                            "ml_models_size": 10,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    },
                    "professional": {
                        "title": "Professional",
                        "price": "277",
                        "duration": "month",
                        "details": {
                            "currency": "$",
                            "data_size": 750,
                            "ml_models_size": 40,
                            "benefits": [
                                {"name": "Model Metrics"},
                                {"name": "Model Monitoring Visualizations"},
                                {"name": "Error and Threshold Notifications"},
                                {"name": "Alerts"}
                            ]
                        }
                    }
                },
            }


export const BRAIN_TREE_AUTH_TOKEN = process.env.REACT_APP_BRAINTREE_TOKEN;
export const MIXPANEL_KEY = process.env.REACT_APP_MIXPANEL_KEY;

/**
 * In .env file we do have REACT_APP_SAAS_EDITION
 * It will be in true for all SAAS .env file 
 * so we can you make use of this constant to differentiate SAAS and DRX UI as well as BIX(DRX)
 * else if it is false we will be setting the IS_NONE_SAAS_EDITION to true
 */
export const IS_SAAS_EDITION = process.env.REACT_APP_SAAS_EDITION === 'true' ? true : false
export const IS_NONE_SAAS_EDITION = process.env.REACT_APP_SAAS_EDITION === 'true' ? false : true
export const IS_AZURE_DRX = process.env.REACT_APP_MARKET_EDITION === "qualdo-drx-az" ? true : false

// ============= GTM RELATED CONSTANTS START ========
export const GTM_INTEGRATION_TOKEN = process.env.REACT_APP_GTM_AUTH;
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW_ENV;
export const GTM_EVENTS = {
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
};
// ============= GTM RELATED CONSTANTS END ========

export const trigger_name_regex = /[^A-Za-z0-9]/
export const trigger_special_char_regex = /^[!@#$%^&*)(+=._-]{0,}$/

export const NON_NUMERIC_COLUMNS = ["string", "date", "timestamp", "boolean"]

export const DATA_TYPE_MAPPING = {
    "string":"String",
    "date":"Date",
    "timestamp":"Timestamp",
    "boolean":"Boolean"
}

export const DATA_DRIFT = "Data Drift";
export const DATA_TYPE_CONSISTENCY = "Data Type Consistency";
export const NORMALIZATION_CONSISTENCY = "Normalization Consistency";
export const CATEGORICAL_ENCODE = "Categorical Encode";

export const ML_DATA_CONSISTENCY = "ML Data Consistency";
export const ML_ACCURACY_DRIFT = "Accuracy Drift";

export const REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART = [DATA_TYPE_CONSISTENCY, NORMALIZATION_CONSISTENCY,
    CATEGORICAL_ENCODE];

export const DATA_CONSISTENCY_METRICS = [CATEGORICAL_ENCODE, DATA_TYPE_CONSISTENCY, NORMALIZATION_CONSISTENCY];

export const ML_REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART = [ML_DATA_CONSISTENCY];

export const CONTRIBUTION_KEY = "contribution";

export const REFERENCE_TYPE_COLUMN_NAME = {
    [DATA_TYPE_CONSISTENCY]: "Reference Data type",
    [ML_DATA_CONSISTENCY]: "Training Data type"
};

export const CONSISTENCY_CONTRIBUTION_HEADERS = ["Date", "Attribute Name", "Data type",
    REFERENCE_TYPE_COLUMN_NAME[DATA_TYPE_CONSISTENCY]];

export const CONSISTENCY_CONTRIBUTION_HEADERS_ML = ["Date", "Attribute Name", "Data type",
    REFERENCE_TYPE_COLUMN_NAME[ML_DATA_CONSISTENCY]];

export const MODEL_PERFORMANCE_AS_MODEL_ERRORS = [modelPerformance.F1_SCORE,
    modelPerformance.PRECISION, modelPerformance.RECALL,
    modelPerformance.ACCURACY,
    modelPerformance.RMSE]


export const DATA_TYPE_CONSISTENCY_HEADERS = {
    [DATA_TYPE_CONSISTENCY]: CONSISTENCY_CONTRIBUTION_HEADERS,
    [ML_DATA_CONSISTENCY]: CONSISTENCY_CONTRIBUTION_HEADERS_ML
};


export const PAID_PLANS = [QualdoPlanVersions.STARTER, QualdoPlanVersions.PROFESSIONAL]

export const VALID_QUALDO_PLANS = [QualdoPlanVersions.STARTER, QualdoPlanVersions.PROFESSIONAL,
    QualdoPlanVersions.FREE];

export const VALID_QUALDO_PLAN_CYCLES = [QualdoPlanCycle.MONTHLY, QualdoPlanCycle.YEARLY, QualdoPlanCycle.TRIAL_14_DAYS];

export const FREE_PLAN_CYCLES = [QualdoPlanCycle.MONTHLY, QualdoPlanCycle.TRIAL_14_DAYS];
export const STARTER_PLAN_CYCLES = [QualdoPlanCycle.MONTHLY, QualdoPlanCycle.YEARLY];
export const PROFESSIONAL_PLAN_CYCLES = [QualdoPlanCycle.MONTHLY, QualdoPlanCycle.YEARLY];

export const VALID_QUALDO_EDITIONS = [QualdoEditions.ML_EDITION]
export const QUALDO_WEBSITE_URL = "https://www.qualdo.ai/monitor-ml-model-performance-monitoring/#pricing";
export const PAYMENT_FIELD_ID_MAP = {
    number: "card_number",
    cvv: "cvv",
    expirationDate: "expiration_date"
}

export const PAYMENT_COMPONENT_STYLES = {
    'input': {
        'font-size': '16px',
        'color': '#020202'
    },

    ':-moz-placeholder': {
        'font-family': "'Poppins', sans-serif",
        'font-size': '14px',
        'font-weight': '400',
        'color': '#c9c9c9',
        'opacity': '1'
    },
    '::-moz-placeholder': {
        'font-family': "'Poppins', sans-serif",
        'font-size': '14px',
        'font-weight': '400',
        'color': '#c9c9c9',
        'opacity': '1'
    },
    ':-ms-input-placeholder': {
        'font-family': "'Poppins', sans-serif",
        'font-size': '14px',
        'font-weight': '400',
        'color': '#c9c9c9',
        'opacity': '1'
    }
};

export const DRIFT_PATTERN_TYPES = {
    NO_DRIFT: "NO_DRIFT",
    NOT_COMPUTED: "not_computed",
    THRESHOLD_ALERT: "Threshold Alert",
    ERRORS_DETECTED: "Errors Detected",
    SUDDEN: "sudden",
    INCREMENTAL: "incremental",
    GRADUAL: "gradual",
    RECURRING: "recurring",
    BLIP: "blip",
    NOISE: "noise",
    DECREMENTAL: "decremental"

}



export const NON_ERROR_DRIFT_PATTERNS = [DRIFT_PATTERN_TYPES.NO_DRIFT, 
    DRIFT_PATTERN_TYPES.NOT_COMPUTED,
    DRIFT_PATTERN_TYPES.SUDDEN, DRIFT_PATTERN_TYPES.INCREMENTAL,
    DRIFT_PATTERN_TYPES.BLIP, DRIFT_PATTERN_TYPES.NOISE,
    DRIFT_PATTERN_TYPES.DECREMENTAL
];

export const MIN_MAX_SCALE_LIST = ["Recency", "Data Drift", "Data Type Consistency",
    "Normalization Consistency", "Normalisation Consistency"];

export const METRIC_ALIAS_NAMES = {
    "precision" : "Precision",
    "recall" : "Recall",
    "precision vs recall": "Precision vs Recall",
    "f1 score": "F1 score",
    "Weighted F(1) Score": "Weighted F1 Score",
    "Weighted precision": "Weighted Precision",
    "Weighted recall": "Weighted Recall",
    "rmse": "Root Mean Squared Error",
    "mse": "Mean Squared Error",
    "r2": "R-squared",
    "mae": "Mean Absolute Error",
    "memory_usage": "Memory Usage",
    "cpu_usage": "CPU Usage",
    "overhead_latency": "Overhead Latency",
    "model_latency": "Model Latency",
    "areaUnderROC": "Area Under ROC",
    "areaUnderPR": "Area Under PR",
    "areaUnderROC Error": "Area Under ROC Error",
    "areaUnderPR Error": "Area Under PR Error",
    "Accuracy": "Accuracy",
    "Sensitivity": "Sensitivity",
    "Specificity": "Specificity",
    "True Positive": "true positive",
    "False Positive": "false positive",
    "True Negative": "true negative",
    "False Negative": "false negative",
    "Spearman correlation coefficient": "Spearman Correlation Coefficient",
    "Pearson's correlation coefficient": "Pearson's Correlation Coefficient",
    "Matthews Correlation Coefficient": "Matthews Correlation Coefficient",
    "mean_shap_value": "Feature Contribution Value",
    "Homogeneity Completeness V-measure Score": "Homogeneity Completeness V-measure Score",
    "Silhouette Score": "Silhouette Score",
    "Calinski Harabasz Score": "Calinski Harabasz Score",
    "Davies Bouldin Score": "Davies Bouldin Score",
    "Rand Score": "Rand Score",
    "Adjusted Rand Score": "Adjusted Rand Score",
    "Adjusted Mutual Info Score": "Adjusted Mutual Info Score",
    "Normalized Mutual Info Score": "Normalized Mutual Info Score",
    "Fowlkes Mallows Score": "Fowlkes Mallows Score"
}

// Should be in sync with QualdoPaymentStatus in qualdo/qualdo_utils/constants.py
export const QUALDO_PAYMENT_STATUS = {
    TRIAL_EXPIRED: "trial_expired",
    PAYMENT_COMPLETED: "payment_completed",
    PAYMENT_PENDING: "payment_pending"
}

export const PENDING_PAYMENTS = [QUALDO_PAYMENT_STATUS.TRIAL_EXPIRED,
    QUALDO_PAYMENT_STATUS.PAYMENT_PENDING];

export const BUTTON_NAMES = {
    PAY_NOW: "Pay now",
    CHOOSE_PLAN: "Choose plan",
    INCREASE_LIMIT: "Increase limit"
}

export const EXCLUDED_COMPONENTS = ["dqErrorListViewTable"]
export const PLAN_PAGE_WARNINGS = {
    [QUALDO_PAYMENT_STATUS.TRIAL_EXPIRED]: [ "Free trial expiration. ",
    "Free-trial period and the extension has expired. Please subscribe to one of the plans to continue using Qualdo\u2122",
    BUTTON_NAMES.CHOOSE_PLAN],
    [QUALDO_PAYMENT_STATUS.PAYMENT_PENDING]: ["There is a pending payment.",
    "Your current bill is past-due. The warning extension period is also past. To continue using Qualdo\u2122, please pay immediately. For any assistance please contact the help desk below.",
    BUTTON_NAMES.PAY_NOW ],
    "data_limit_exceeded_paid_plans" : ["Data size limit reached.",
    "The size limit of the Total Data Processed by Qualdo\u2122 for the current plan is (100 GB).  You are starting to breach this limit. Please remember, you will be charged an extra $52 for every 100 GB data processing increase. This will show up in the next bill/invoice. You can also upgrade to another plan to continue using Qualdo\u2122.",
    BUTTON_NAMES.INCREASE_LIMIT ],
    "free_trial_exceeded": ["Data size limit reached.",
    "The size limit of the Total Data Processed by Qualdo\u2122 for the current plan is (10 GB). " +
    "You are starting to breach this limit. Please subscribe to one of the plans to continue using Qualdo\u2122.",
    BUTTON_NAMES.CHOOSE_PLAN]
    }

export const TEST_CONNECTION_MESSAGES = {
"azure_blob": {'stages': [{'stage': 1, 'name': 'Validate Storage Name', 'status': "in-progress"},
                                         {'stage': 2, 'name': 'Validate Secret Key', 'status': "in-progress"},
                                         {'stage': 3, 'name': 'Validate Container', 'status': "in-progress"}
                                         ]},
"azure_data_lake_gen2": {'stages': [{'stage': 1, 'name': 'Validate Storage Name', 'status': "in-progress"},
                                         {'stage': 2, 'name': 'Validate Secret Key', 'status': "in-progress"},
                                         {'stage': 3, 'name': 'Validate Container', 'status': "in-progress"}
                                         ]},
"azure_data_lake": {'stages': [{'stage': 1, 'name': 'Validate Tenant Id', 'status': "in-progress"},
                                             {'stage': 2, 'name': 'Validate Client Id', 'status': "in-progress"},
                                             {'stage': 3, 'name': 'Validate Client Secret', 'status': "in-progress"},
                                             {'stage': 4, 'name': 'Connect to Bucket', 'status': "in-progress"}
                                             ]},
"big_query": {'stages': [{'stage': 1, 'name': 'Validate Service Account JSON', 'status': "in-progress"},
                                             {'stage': 2, 'name': 'Validate schema Name', 'status': "in-progress"}
                                             ]},
"gcs": {'stages': [{'stage': 1, 'name': 'Validate Json', 'status': "in-progress"},
                                         {'stage': 2, 'name': 'Validate Bucket Name', 'status': "in-progress"}
                                         ]},
"s3": {'stages': [{'stage': 1, 'name': 'Validate Access key', 'status': "in-progress"},
                                         {'stage': 2, 'name': 'Validate Secret Access key', 'status': "in-progress"},
                                         {'stage': 3, 'name': 'Validate Bucket Name', 'status': "in-progress"},
                                         ]},
"redshift": {'stages': [{'stage': 1, 'name': 'Validate Host name', 'status': "in-progress"},
                                     {'stage': 2, 'name': 'Validate User name', 'status': "in-progress"},
                                     {'stage': 3, 'name': 'Validate Password', 'status': "in-progress"},
                                     {'stage': 4, 'name': 'Validate Schema Name', 'status': "in-progress"},
                                     {'stage': 5, 'name': 'Validate Table Name', 'status': "in-progress"}
                                     ]},                                
"snow_flake": {'stages': [{'stage': 1, 'name': 'Validate Account Name', 'status': "in-progress"},
                                             {'stage': 2, 'name': 'Validate Security Credentials', 'status': "in-progress"},
                                             {'stage': 3, 'name': 'Validate Database/schema/Table', 'status': "in-progress"}
                                             ]},
"clickhouse": {'stages':[{'stage': 1, 'name': 'Validate Host name', 'status': "in-progress"},
                                     {'stage': 2, 'name': 'Validate User name', 'status': "in-progress"},
                                     {'stage': 3, 'name': 'Validate Password', 'status': "in-progress"},
                                     {'stage': 4, 'name': 'Validate Schema Name', 'status': "in-progress"}
                                     ]},
"postgresql": {'stages': [{'stage': 1, 'name': 'Validate Host name', 'status': "in-progress"},
                                     {'stage': 2, 'name': 'Validate User name', 'status': "in-progress"},
                                     {'stage': 3, 'name': 'Validate Password', 'status': "in-progress"},
                                     {'stage': 4, 'name': 'Validate Schema Name', 'status': "in-progress"},
                                     {'stage': 5, 'name': 'Validate Table Name', 'status': "in-progress"}
                                     ]},
"mysql": {'stages':[{'stage': 1, 'name': 'Validate  Host', 'status': "in-progress"},
                     {'stage': 2, 'name': 'Validate Username/Password', 'status': "in-progress"},
                     {'stage': 3, 'name': 'Validate Database/Table', 'status': "in-progress"}
                     ]},
"azure_sql": {'stages': [{'stage': 1, 'name': 'Validate Host', 'status': "in-progress"},
                                             {'stage': 2, 'name': 'Validate Username/Password', 'status': "in-progress"},
                                             {'stage': 3, 'name': 'Validate Database/Schema/Table', 'status': "in-progress"}
                                             ]},
"gcp_sql": {'stages': [{'stage': 1, 'name': 'Validate Host', 'status': "in-progress"},
                                             {'stage': 2, 'name': 'Validate Username/Password', 'status': "in-progress"},
                                             {'stage': 3, 'name': 'Validate Database/Schema/Table', 'status': "in-progress"}
                                             ]},
"power_bi_connector": {'stages': [{'stage': 1, 'name': 'Validate User Name/password', 'status': "in-progress"},
                                     {'stage': 2, 'name': 'Validate Workspace', 'status': "in-progress"},
                                     {'stage': 3, 'name': 'Validate Database', 'status': "in-progress"},
                                     {'stage': 4, 'name': 'Validate Table Name', 'status': "in-progress"},
                                     ]},
"azure_databricks_table": {'stages': [{'stage': 1, 'name': 'Validate Host', 'status': "in-progress"},
                                        {'stage': 2, 'name': 'Validate Token', 'status': "in-progress"},
                                        {'stage': 3, 'name': 'Validate Path', 'status': "in-progress"},
                                        {'stage': 4, 'name': 'Validate Catalog/Schema/Table', 'status': "in-progress"},
]},
"azure_databricks_sql": {'stages': [{'stage': 1, 'name': 'Validate Host', 'status': "in-progress"},
                                        {'stage': 2, 'name': 'Validate Token', 'status': "in-progress"},
                                        {'stage': 3, 'name': 'Validate Path', 'status': "in-progress"},
                                        {'stage': 4, 'name': 'Validate Catalog/Schema/Table', 'status': "in-progress"},
]},
"azure_synapse_ds": {
        'stages': [
            {'stage': 1, 'name': 'Validate  Host', 'status': "in-progress"},
            {'stage': 2, 'name': 'Validate Username/Password', 'status': "in-progress"},
            {'stage': 3, 'name': 'Validate Database/Table', 'status': "in-progress"}
        ]
    }
}


export const PIPELINE_TEST_CONNECTION_MESSAGES = {
    "synapse": {'stages': [{'stage': 1, 'name': 'Validate Tenant Id', 'status': "in-progress"},
    {'stage': 2, 'name': 'Validate Client Id', 'status': "in-progress"},
    {'stage': 3, 'name': 'Validate Secret', 'status': "in-progress"},
    {'stage': 4, 'name': 'Connect to workspace', 'status': "in-progress"},
    {'stage': 5, 'name': 'Permission to read workspace', 'status': "in-progress"}
    ]}
}

export const DATA_LIMIT_EXCEED = {
    TITLE: "You are going to exceed your data usage limit",
    DESCRIPTION: "For extra data usage you will be charged $52 per 100 GB as part of the next" +
    " month billing cycle. To use extra data, please provide us the confirmation for further processing."
}


export const PLAN_ICON_MAPS = {
    "Starter": "plan_starter",
    "starter": "plan_starter",
    "Professional": "pricingIcon_professional",
    "professional": "pricingIcon_professional",
    "Free": "pricingIcon_free",
    "free": "pricingIcon_free"
}

export const PLAN_CARD_CLASS_MAPS = {
    "Starter": "card__qualdo-edition your-plan__starter",
    "Professional": "card__qualdo-edition your-plan__professional",
    "Free": "card__qualdo-edition your-plan__starter",
}

export const CYCLE_CARD_CLASS_MAPS = {
    "annually": "card__qualdo-edition your-plan__starter",
    "monthly": "card__qualdo-edition your-plan__professional",
    "Free": "card__qualdo-edition your-plan__starter",
}

export const CYCLE_ICON_MAPS = {
    "annually": "plan_starter",
    "monthly": "pricingIcon_professional",
    "Free": "pricingIcon_free",
    "free": "pricingIcon_free"
}
export const NO_ERROR_CHART_COLORS = {
    "Purple" : "#915EF9",
    "Green" : "#4ED2CC",
    "Blue" : "#4CA3FF",
    "Yellow" : "#FFA800"
}

export const NO_ERROR_COLOR_CLASS_MAP = {
    "#915EF9" : "f-16 lh-20 text-purple",
    "#4ED2CC" : "f-16 lh-20 text-success",
    "#4CA3FF" : "f-16 lh-20 text-info",
    "#FFA800" : "f-16 lh-20 text-warning"
}

export const LIGHT_COLOR_MAP = {
    "#915EF9" : "rgba(145, 94, 249, 0.25)",
    "#4ED2CC" : "rgba(78, 210, 204, 0.25)",
    "#4CA3FF" : "rgba(76, 163, 255, 0.25)",
    "#FFA800" : "rgba(255, 168, 0, 0.25)"
}
/*
 * These URLs will be shown only when the user is not logged.
 * When the user is logged in and they are reaching these URLS,
 * we will redirect them to configure page or any other page
 * based on their plan status and plan types
 */
export const ACCOUNT_FREE_URLS = ["/", "/signup", "/login", "/google_signup"];


export const DEFAULT_CURRENCY_SYMBOL = "$";

export const BOX_PLOT_METRICS = ["Data Drift", "Model Drift", "Prediction Drift", "Response Drift", "Feature Drift",
                                    "Accuracy Drift"];


export const MEMORY_UNITS = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export const SINGLE_ARROW_CHART_TYPE = "singleArrowChart";


export const GENERIC_CONSTANTS = {
    NA: "NA",
}

export const MONITOR_DQ_LIST_VIEW_HEADERS = ["Datasource Name", "Dataset Name", "Attribute Name", "DQ Metrics with Issues", "Action"];
export const MONITOR_DQP_LIST_VIEW_HEADERS = ["Datasource Name", "Dataset Name", "Attribute Name", "DQ Metrics", "Action"];

export const MONITOR_MODEL_ERROR_LIST_VIEW_HEADERS = ["Model Name", "Model Summary", "Model Error Summary", "Data Quality Issues", "Serving Features Drifting", "Performance Metrics with Issues", "Action"];

export const MONITOR_MODEL_PERF_LIST_VIEW_HEADERS = ["Model Name", "Model Summary",
    "Model Error Summary", "Performance Metrics", "Action"];

export const MONITOR_PERF_LIST_VIEW = {
    "Model Accuracy Metrics": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": THUMBNAIL_COLORS.GREEN_VARS
    },
    "Model Evaluation Metrics": {
        "cardBackground": "dashboard-stat-purple", "textColor": "text-purple",
        "colorArray": THUMBNAIL_COLORS.VIOLET_VARS

    },
    "Model Runtime-Profile Metrics": {
        "cardBackground": "dashboard-stat-warning", "textColor": "text-warning",
        "colorArray": THUMBNAIL_COLORS.ORANGE_VARS
    },
    "Model Explainability": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": THUMBNAIL_COLORS.GREEN_VARS
    },
    "Monitor Error": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": THUMBNAIL_COLORS.RED_VARS
    },
    "Monitor Data Quality Performance-1": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": THUMBNAIL_COLORS.GREEN_VARS
    },
    "Monitor Data Quality Performance-2": {
        "cardBackground": "dashboard-stat-purple",
        "textColor": "text-purple",
        "colorArray": THUMBNAIL_COLORS.VIOLET_VARS

    }
};

export const IMAGE_NAMES = {
    ICON_DOWN_RED: "icon_down_red",
    ICON_UP_GREEN: "icon_up_green",
};

export const LIMIT_ABOUT_TO_REACH = "You are going to breach the current plan’s data limit";

// This value should be in sync with
// 'qualdo/qualdo_utils/constants.py' -> MONITOR_RESULTS_PER_PAGE
export const MAX_CHARTS_PER_API = 500;


let env = process.env.REACT_APP_CUSTOM_BRAND_LOGO

export function videoUrl(edition,key){
    if(edition === 'logoDrxLight'){
        return urlDrx[key]
    }
    else{
        return urlMqx[key]
    }
}

let urlMqx = {
            "CONFIGURE_DATA_SOURCE_URL" : "https://eastus.av.mk.io/video-prod-media-kind/8000d890-ad15-478c-8d3a-1f3c68cfd304/Configure_Datasource_V2.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "CONFIGURE_MODEL_URL" : "https://eastus.av.mk.io/video-prod-media-kind/1544efed-780f-4fbd-9281-272cfb7f3174/Configure_Model_V2.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "PIPELINE_CHANNEL_URL"  : "https://eastus.av.mk.io/video-prod-media-kind/a2ef80ae-6b79-4ae1-ac7e-60186b7c3095/pipeline_channel.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "MONITOR_MODEL_URL" : "https://eastus.av.mk.io/video-prod-media-kind/f9b6e10d-14b4-4e6a-8534-92990665a8ec/Model_Monitor_Errors_V4.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "MONITOR_DATA_URL" : "https://eastus.av.mk.io/video-prod-media-kind/4cc3ecf7-f466-43a4-9bfb-24fc9899345b/Data_Quality_Errors_V3.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "PROFILE_DATA_URL" : "https://eastus.av.mk.io/video-prod-media-kind/64c1043b-16b7-4448-8a29-274caf21b3cf/Profile_Data.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "PROFILE_MODEL_URL" : "https://eastus.av.mk.io/video-prod-media-kind/c23588cb-7ee5-4ba7-84ec-7124ca4dba0e/Profile_Model.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "NOTIFICATION_URL" : "https://eastus.av.mk.io/video-prod-media-kind/ecaa726f-1f34-420f-8655-1ee2f710dfbb/notification_channel.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "DATA_QUALITY_URL" : "https://eastus.av.mk.io/video-prod-media-kind/b76b9585-eb57-4360-8d07-f4c807fe5b04/Dataquality_Alert_V2.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "MONITOR_MODEL_PERFORMANCE_URL" : "https://eastus.av.mk.io/video-prod-media-kind/614797e6-4231-4079-b8b4-863c72e995cb/Model_Monitor_Performance_V3.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
        }

let urlDrx ={
            "CONFIGURE_DATA_SOURCE_URL" : "https://eastus.av.mk.io/video-prod-media-kind/bc20d981-8c25-40eb-804b-a7e230f1a9a2/Datasource_Configuration_drx.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "CONFIGURE_MODEL_URL" : "https://eastus.av.mk.io/video-prod-media-kind/1544efed-780f-4fbd-9281-272cfb7f3174/Configure_Model_V2.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "PIPELINE_CHANNEL_URL" : "https://eastus.av.mk.io/video-prod-media-kind/f264d732-f622-443e-88ba-58c2b61bf525/Pipeline_Alert_drx.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "MONITOR_MODEL_URL" : "https://eastus.av.mk.io/video-prod-media-kind/f9b6e10d-14b4-4e6a-8534-92990665a8ec/Model_Monitor_Errors_V4.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "MONITOR_DATA_URL" : "https://eastus.av.mk.io/video-prod-media-kind/1aa25338-5c3d-4969-b804-6994b16b468b/Monitor_Page_drx.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "PROFILE_DATA_URL" : "https://eastus.av.mk.io/video-prod-media-kind/7274511c-5496-454d-af61-c11e468cc4e6/Profile_page_drx.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "PROFILE_MODEL_URL" : "https://eastus.av.mk.io/video-prod-media-kind/c23588cb-7ee5-4ba7-84ec-7124ca4dba0e/Profile_Model.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "NOTIFICATION_URL" : "https://eastus.av.mk.io/video-prod-media-kind/644583f1-5939-4a4c-816a-b937ebe21d76/Notification_Alert_drx.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "DATA_QUALITY_URL" : "https://eastus.av.mk.io/video-prod-media-kind/da69415c-3dd9-4787-9f4b-f06796e7ecc6/Alert_Configuration_drx.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
            "MONITOR_MODEL_PERFORMANCE_URL" : "https://eastus.av.mk.io/video-prod-media-kind/614797e6-4231-4079-b8b4-863c72e995cb/Model_Monitor_Performance_V3.ism/manifest(format=m3u8-cmaf,encryption=cbc)",
        }

export const CONFIGURE_DATA_SOURCE_URL = videoUrl(env,"CONFIGURE_DATA_SOURCE_URL")
export const CONFIGURE_MODEL_URL = videoUrl(env,"CONFIGURE_MODEL_URL")
export const PIPELINE_CHANNEL_URL  = videoUrl(env,"PIPELINE_CHANNEL_URL")
export const MONITOR_MODEL_URL = videoUrl(env,"MONITOR_MODEL_URL")
export const MONITOR_DATA_URL = videoUrl(env,"MONITOR_DATA_URL")
export const PROFILE_DATA_URL = videoUrl(env,"PROFILE_DATA_URL")
export const PROFILE_MODEL_URL = videoUrl(env,"PROFILE_MODEL_URL")
export const NOTIFICATION_URL = videoUrl(env,"NOTIFICATION_URL")
export const DATA_QUALITY_URL = videoUrl(env,"DATA_QUALITY_URL")
export const MONITOR_MODEL_PERFORMANCE_URL = videoUrl(env,"MONITOR_MODEL_PERFORMANCE_URL")


export const ATTRIBUTE_RELATIONSHIP = {"Batch_No": [{ "sourceDataset": "all_data_set.Batch_No", "destDataset": "stock_ledger.c_batch_no","percentage": 50, "id": "1", "relationship": "50%"}],
                                        "d_date":[{ "sourceDataset": "all_data_set.d_date", "destDataset": "stock_ledger.d_date","percentage": 60, "id": "1", "relationship": "60%"}],
                                        "item_code": [{ "sourceDataset": "all_data_set.item_code", "destDataset": "ZEDC_Tag_List.item_code","percentage": 20, "id": "1", "relationship": "20%"}],
                                        "Qty": [{ "sourceDataset": "all_data_set.Qty", "destDataset": "all_data_set.n_qty","percentage": 60, "id": "1", "relationship": "60%"}],
                                        "n_qty": [{ "sourceDataset": "all_data_set.Qty", "destDataset": "all_data_set.n_qty","percentage": 60, "id": "1", "relationship": "60%"}],
                                        "Tax_Amt": [{ "sourceDataset": "all_data_set.Tax_Amt", "destDataset": "all_data_set.n_taxable_amt","percentage": 60, "id": "1", "relationship": "60%"}],
                                        "n_taxable_amt": [{ "sourceDataset": "all_data_set.Tax_Amt", "destDataset": "all_data_set.n_taxable_amt","percentage": 60, "id": "1", "relationship": "60%"}],
                                        "brcode": [{ "sourceDataset": "branch_group_mst.brcode", "destDataset": "all_data_set.brcode","percentage": 50, "id": "1", "relationship": "60%"},
                                                    { "sourceDataset": "branch_group_mst.brcode", "destDataset": "branch.brcode","percentage": 50, "id": "2", "relationship": "60%"}],
                                         "branch": [{ "sourceDataset": "branch.branch", "destDataset": "stock_ledger.branch","percentage": 50, "id": "1", "relationship": "60%"},
                                                    { "sourceDataset": "branch_group_mst.brcode", "destDataset": "stock_ledger.c_br_code","percentage": 50, "id": "1", "relationship": "60%"}],
                                         "Category_Code": [{ "sourceDataset": "item_category.Category_Code", "destDataset": "item_master.Category_Code","percentage": 50, "id": "1", "relationship": "60%"}],
                                         "Cat_Head_Code": [{ "sourceDataset": "item_category.Cat_Head_Code", "destDataset": "item_master.Cat_Head_Code","percentage": 50, "id": "1", "relationship": "60%"}],
                                         "Category_Head_Code": [{ "sourceDataset": "item_category.Cat_Head_Code", "destDataset": "item_master.Category_Head_Code","percentage": 50, "id": "1", "relationship": "60%"}],
                                         "c_br_code": [{ "sourceDataset": "branch_group_mst.brcode", "destDataset": "all_data_set.brcode","percentage": 50, "id": "1", "relationship": "60%"},
                                                        { "sourceDataset": "branch_group_mst.brcode", "destDataset": "branch.brcode","percentage": 50, "id": "2", "relationship": "60%"},
                                                        { "sourceDataset": "branch_group_mst.brcode", "destDataset": "stock_ledger.c_br_code","percentage": 50, "id": "1", "relationship": "60%"}],
                                          "Year": [{ "sourceDataset": "stock_ledger.c_year", "destDataset": "all_data_set.Year","percentage": 50, "id": "1", "relationship": "60%"}],
                                          "c_year": [{ "sourceDataset": "stock_ledger.c_year", "destDataset": "all_data_set.Year","percentage": 50, "id": "1", "relationship": "60%"}],
                                          "c_item_code": [{ "sourceDataset": "item_master.item_code", "destDataset": "all_data_set.item_code","percentage": 50, "id": "1", "relationship": "60%"},
                                                        { "sourceDataset": "item_master.item_code", "destDataset": "stock_ledger.c_item_code","percentage": 50, "id": "1", "relationship": "60%"}],
                                           "c_batch_no": [{ "sourceDataset": "all_data_set.Batch_No", "destDataset": "stock_ledger.c_batch_no","percentage": 50, "id": "1", "relationship": "50%"}],
                                           "c_prefix": [{ "sourceDataset": "all_data_set.c_prefix", "destDataset": "stock_ledger.c_prefix","percentage": 50, "id": "1", "relationship": "50%"}],
                                           "Category_Name": [{ "sourceDataset": "item_master.Category_Name", "destDataset": "item_category.Item","percentage": 50, "id": "1", "relationship": "50%"}],
                                           "Item": [{ "sourceDataset": "item_master.Category_Name", "destDataset": "item_category.Item","percentage": 50, "id": "1", "relationship": "50%"}],
                                        }
export const ATTRIBUTE_VALUES = {"Batch_No": {"unique": 4, "duplicate": 1, "error": 4, "space": "10KB", "record": 10, "empty": 0},
                                 "d_date": {"unique": 4, "duplicate": 1, "error": 4, "space": "10KB", "record": 10, "empty": 0},
                                 "item_code": {"unique": 4, "duplicate": 1, "error": 4, "space": "10KB", "record": 10, "empty": 0},
                                 "Qty": {"unique": 4, "duplicate": 1, "error": 4, "space": "10KB", "record": 10, "empty": 0},
                                 "Tax_Amt": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "brcode": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "branch": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "Category_Code": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "Cat_Head_Code": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "Category_Head_Code": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "c_br_code": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "Year": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "c_year": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "c_item_code": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "c_batch_no": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "c_prefix": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "n_taxable_amt": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "n_qty": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "Category_Name": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0},
                                 "Item": {"unique": 6, "duplicate": 1, "error": 4, "space": "100KB", "record": 10, "empty": 0}
                                 }


export const VERSION_FORMATS = {
    "dd-mm-yyyy": "[\\d]{1,2}-[\\d]{1,2}-[\\d]{4}",
    "yyyy-mm-dd": "[\\d]{4}-[\\d]{1,2}-[\\d]{1,2}",
    "dd-mm-yy": "[\\d]{1,2}-[\\d]{1,2}-[\\d]{2}",
    "yy-mm-dd": "[\\d]{2}-[\\d]{1,2}-[\\d]{1,2}",
    "ddmmyyyy": "[\\d]{8}"
};



export const FILE_TYPE_FORMATS = {
    "file_replace" : "File Replace",
    "file_version" : "File Version",
    "folder_version" : "Folder Version"
}



export const DISPLAY_DATE_FORMAT = 'MMM DD, YYYY';
export const PIPELINE_DISPLAY_DATE_FORMAT = 'DD MMM YYYY';
export const PIPELINE_DATE_FORMAT_CHECK = 'YYYY-MM-DD';


export const MAX_DATA_POINTS_FOR_LIST_VIEW = 30;
export const MAX_POINTS_THUMBNAIL = 7;

export const DISABLE_CHECKBOXES_IN_CARDS = ["Categorical Encode", "Data Type Consistency",
    "Normalization Consistency"]


// The following constant should be in sync with
// qualdo\compute_engine\compute_measure\estimators\constants.py (DOUBLE_COLON)
export const DOUBLE_COLON = "::";

export const SQL_QUERY_DUMMY_PLACE_HOLDER = "select count(*) from $dataset$ where `attribute1` = 1";

export const SQL_QUERY_NOTE_1 = "In the query, use $dataset$ instead of dataset name.";

export const SQL_QUERY_NOTE_2 =  `For nested record columns, use
double colon ${DOUBLE_COLON} as separator between parent column and child column.`;

// This count will be used for fetch unread notifications during pre-fetch API call
export const MAX_UNREAD_PER_PAGE = 15;

// This count will be used for fetching all notifications during fetch API calls in /notification page.
export const MAX_NOTIFICATIONS_PER_PAGE = 500;

export const IS_NONE_CHECK = [null, undefined];

export const LOGGEDIN_USER_EMAIL = ["demouser@saturam.com", "hemamalni32@gmail.com"];
export const LOGGEDIN_USER_ID = ['78'];
export const PIPELINE_USER = 'demoaccmay24@gmail.com'
// give in lower case
export const NOT_SORTABLE = ["summary", "profile status", "datasets", "errors", "model summary",
                            "model error summary", "performance metrics", "data quality issues",
                            "serving features drifting", "performance metrics with issues",
                            "dq metrics with issues", 'action', "actions"]


// To be in sync with 'qualdo/qualdo_utils/constants.py' => ALLOWED_ML_STACKS
export const SUPPORTED_ML_STACK_OPTIONS = [{label: 'Tensor Flow', value: 'TF'}, {label: 'MXNet', value: 'MXNet'},
    {label: 'DataBricks', value: 'DataBricks'}, {label: 'Others', value: 'others'}];


// To be in sync with 'qualdo/qualdo_utils/constants.py' => ALLOWED_ML_TYPES
// and qualdo/compute_engine/compute_measure/estimators/ml_constants.py => SUPPORTED_MODELS
export const SUPPORTED_ML_TYPE_OPTIONS = [{label: 'Classification', value: 'classification'},
    {label: 'Regression', value: 'regression'},
    {label: 'Clustering', value: 'clustering'},
    {label: 'Recommendation-CB', value:'RCB'},
    {label: 'Recommendation', value: 'recommendation'},
    {label: 'Forecasting', value:'forecast'},
    {label: 'Others', value:'others'}];
// Removed
// {label: 'Others', value: 'others'}

export const SUPPORTED_ML_COLUMN_TYPES = [{label: 'Item', value: 'item'},
    {label: 'Rating', value: 'rating'}];

// To be in sync with 'qualdo/qualdo_utils/constants.py' => ALLOWED_ML_ALGO_TYPES
export const SUPPORTED_ML_ALGO_TYPES = [
    {label: 'Content based recommendation system', value: 'Content based recommendation system', type: "recommendation"},
    {label: 'Collaborative recommendation system', value: 'Collaborative recommendation system', type: "recommendation"},
    {label: 'Logistic Regression', value: 'Logistic Regression', type: "classification"},
    {label: 'k-Nearest Neighbors', value: 'k-Nearest Neighbors', type: "classification"},
    {label: 'Support Vector Machine', value: 'Support Vector Machine', type: "classification"},
    {label: 'Naive Bayes', value: 'Naive Bayes', type: "classification"},
    {label: 'Random Forest', value: 'Random Forest', type: "classification"},
    {label: 'Gradient Boosting', value: 'Gradient Boosting', type: "classification"},
    {label: 'Linear Regression', value: 'Linear Regression', type: "regression"},
    {label: 'Tree Regression', value: 'Tree Regression', type: "regression"},
    {label: 'GBT Regression', value: 'GBT Regression', type: "regression"},
    {label: 'Polynomial Regression', value: 'Polynomial Regression', type: "regression"},
    {label: 'Lasso Regression', value: 'Lasso Regression', type: "regression"},
    {label: 'K-Means', value: 'K-Means', type: "clustering"},
    {label: 'Affinity Propagation', value: 'Affinity Propagation', type: "clustering"},
    {label: 'Agglomerative Clustering', value: 'Agglomerative Clustering', type: "clustering"},
    {label: 'Spectral Clustering', value: 'Spectral Clustering', type: "clustering"},
    {label: 'Mean Shift', value: 'Mean Shift', type: "clustering"},
    {label: 'CNN', value: 'CNN', type: "regression classification"},
    {label: 'DNN', value: 'DNN', type: "regression classification"},
    {label: 'ARIMA', value: 'arima', type: "forecast"},
    {label: 'SARIMA', value: 'sarima', type: "forecast"},
    {label: 'Holt Vinters', value: 'vinters', type: "forecast"},
    {label: 'FB Prophet', value: 'fbprophet', type: "forecast"},
    {label: 'Cosine Similarity', value: 'Cosine Similarity', type:"RCB"},
    {label: 'Jaccard similarity', value: 'Jaccard similarity', type:"RCB"},
    {label: 'Bayesian Classifiers', value: 'Bayesian Classifiers', type:"RCB"},
    {label: 'Decision Trees', value: 'Decision Trees', type:"RCB"},
    {label: 'User-based CF', value: 'User-based CF', type:"recommendation"},
    {label: 'Item-based CF', value: 'Item-based CF', type:"recommendation"},
    {label: 'Matrix Factorization', value: 'Matrix Factorization', type:"recommendation"},
    {label: 'Deep Learning', value: 'Deep Learning', type:"recommendation"},
    {label: 'Others', value: 'others', type: "others"},
    ];
//{label: 'Others', value: 'others', type: "others"}

export const QUALITY_PERF_CHART_VIEW = {
    'drift': {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": ["#915EF9", "#4ED2CC", "#4CA3FF", "#FFA800"]
    },
    "completeness": {
        "cardBackground": "dashboard-stat-purple",
        "textColor": "text-purple",
        "colorArray":  [ "#4ED2CC", "#4CA3FF", "#FFA800", "#915EF9"]

    },
    "uniqueness":{
        "cardBackground": "dashboard-stat-purple",
        "textColor": "text-purple",
        "colorArray":  [ "#4ED2CC", "#4CA3FF", "#FFA800", "#915EF9"]

    },
    "timeliness": {
        "cardBackground": "dashboard-stat-warning",
        "textColor": "text-warning",
        "colorArray": ["#4CA3FF", "#FFA800", "#915EF9", "#4ED2CC"]
    },
    "accuracy": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": ["#FFA800", "#915EF9", "#4ED2CC", "#4CA3FF"]
    },
    "conformity": {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": ["#915EF9", "#4ED2CC", "#4CA3FF", "#FFA800"]
    },
    "consistency":  {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": ["#4ED2CC", "#4CA3FF", "#FFA800", "#915EF9"]
    },
    "correlation":  {
        "cardBackground": "dashboard-stat-success",
        "textColor": "text-success",
        "colorArray": ["#4ED2CC", "#4CA3FF", "#FFA800", "#915EF9"]
    },
};

export const REQUIRES_EXCLAMATION_TRIANGLE_ICON = ["Datasource Configuration Alert",
    "Model Monitoring Metrics Alert",
    "Datasets deletion info", "No dataset found", "Model dataset has null values", "Pipeline Configuration Alert",
    "Pipeline Configuration Failure Detected"];


export const CONFORMITY_METRIC_KEYS = ["Credit Card Conformity", "Date Conformity",
"Email Conformity", "Gender Conformity", "Http Conformity", "IP Conformity",
"SSN Conformity", "URL Conformity", "Boolean Conformity"]

export const METRICS_INFO = {
    "rmse": "Root Mean Squared Error",
    "mae": "Mean Absolute Error",
    "mean absolute error": "It is the arithmetic mean of magnitudes of difference between the prediction of an observation and the true value of that observation.",
    "root mean squared error": "It is the standard deviation of the residuals (prediction errors).",
    "mse": "It is the estimator measures the average of the squares of the errors—that is, the average squared difference between the estimated values and the actual value.",
    "r2": "It is the statistical measure that determines the proportion of variance in the dependent variable that can be explained by the independent variable. In other words, it shows how well the data fit the regression model",
    "precision": "It is the ratio of correctly predicted positive observations to the total predicted positive observations.",
    "mean average precision @k": "Precision at k is the proportion of recommended items in the top-k set that are relevant.",
    "weighted precision": "Weighted mean of precision with weights equal to class probability.",
    "f1 score": "It is the weighted average of Precision and Recall.",
    "Feature Importance": "Feature Importance of the attribute",
    "weighted f(1) score": "Weighted mean of f1-score with weights equal to class probability.",
    "accuracy": "It is a ratio of correctly predicted observation to the total observations.",
    "recall": " It is is the ratio of correctly predicted positive observations to the all observations in actual class - yes.",
    "mean average recall @k":"Recall at k is the proportion of relevant items found in the top-k recommendations.",
    "weighted recall": "Weighted mean of recall with weights equal to class probability.",
    "sensitivity" : " It is is the ratio of correctly predicted positive observations to the all observations in actual class - yes.",
    "specificity": "It is the ratio of correctly predicted negative observations to the all observations in actual class - no.",
    "confusion matrix": " It is a table with different combinations of predicted and actual values.",
    "areaunderpr": "It is a singular value that summarizes the precision vs recall curve.",
    "areaunderpr error": "It is a singular value that summarizes the error of precision vs recall curve.",
    "areaunderroc": "It is a single scalar value that measures the overall performance of a binary classifier on the positive class.",
    "areaunderroc error": "It is a single scalar value that measures the error in predictions of the model",
    "true positive": "These are the correctly predicted positive values which means that the value of actual class is yes and the value of predicted class is also yes.",
    "false positive": "These are wrongly predicted positives which means when the actual class is no and predicted class is yes.",
    "true negative": "These are the correctly predicted negative values which means that the value of actual class is no and value of predicted class is also no.",
    "false negative": "These are wrongly predicted negative values which means the actual class is yes but predicted class in no.",
    "spearman correlation coefficient": "It is a technique which can be used to discover the strength of a link between actual observation and predicted values. The coefficient closer to +/-1, the stronger the likely correlation.",
    "pearson's correlation coefficient": "It is a measure of the strength of a linear association between the actual observation and predicted variables. +/-1 indicates strong +/- accosiation.",
    "matthews correlation coefficient": "It is a measure of the correlation of actual observation with the predicted values. MCC value is always between -1 and 1, with 0 meaning that the prediction value is random w.r.t actual observation.",
    "mean_shap_value": "SHAP value breacks down the predictions to show the impact of each feature. The collective SHAP values can show how much each feature contributes, either positively or negatively, to the predicted value.",
    "calinski harabasz score": "It is the ratio of the sum of between-clusters dispersion and of within-cluster dispersion for all clusters.",
    "silhouette score": "It is the measure of how similar an object is to its own cluster (cohesion) compared to other clusters (separation).",
    "davies bouldin score": "It is the average similarity measure of each cluster with its most similar cluster, where similarity is the ratio of within-cluster distances to between-cluster distances.",
    "fowlkes mallows score": "It is the geometric mean between of the precision and recall.",
    "homogeneity completeness v-measure score": "It is the normalized conditional entropy measures of the clustering labeling to evaluate given the knowledge of a Ground Truth class labels of the same samples.",
    "rand score": "It is the similarity measure between two clusterings by considering all pairs of samples and counting pairs that are assigned in the same or different clusters in the predicted and true clusterings.",
    "normalized mutual info score": "It is the score to scale the Mutual Information results between 0 (no mutual information) and 1 (perfect correlation). Mutual Information is a function that measures the agreement of the two assignments, ignoring permutations.",
    "adjusted mutual info score": "It is the adjustment of the Mutual Information (MI) score to account for chance. Mutual Information is a function that measures the agreement of the two assignments, ignoring permutations.",
    "adjusted rand score": "It is the Rand Index adjusted for chance. Rand Index is the similarity measure between two clusterings by considering all pairs of samples and counting pairs that are assigned in the same or different clusters in the predicted and true clusterings.",
}

export const K_VALUE_TOOLTIP = "In the context of recommendation systems we are most likely " +
    "interested in recommending top-N items to the user. k is a user definable integer " +
    "that is set by the user to match the top-N recommendations objective.";

export const USE_SERVING_AS_PREDICTION = "By checking this, Qualdo uses Serving Dataset as Prediction Data";

// Delimiter options are used by recommendation models to split data
// To be in sync with qualdo/qualdo_utils/constants.py -> ALLOWED_DELIMITER_FOR_RECO
export const DELIMITER_OPTIONS = [
    {"label": ",", "value": ","},
    {"label": ";", "value": ";"},
    {"label": "|", "value": "|"},
    {"label": "custom", "value": "custom"},
];


export const TABLE_VIEW_CLASS = "btn btn-sm btn-outline-success mr-1";
export const TABLE_EDIT_CLASS = "btn btn-sm btn-outline-warning mr-1";
export const TABLE_DELETE_CLASS = "btn btn-sm btn-outline-danger show-danger-toast mr-1";
export const TABLE_COMP_MODELS_CLASS = "btn btn-sm btn-outline-purple mr-1";


export const MODEL_AB_TABLE_HEADERS = ["Model Name", "Model Version", "Datasource",
    "Environment", "Created By", "Created Time", "Actions"];

export const AB_MODELS_TABLE_TITLE = "CONFIGURED MODELS FOR AB TESTING";


export const AB_COMPARE_VIEW_TABLE_HEADERS = ["Model Name", "Model Summary",
    "Performance Metrics", "Action"];

export const SUFFIXED_DATE = {
    "1": "st", "2": "nd", "3": "rd"
};


export const MODEL_AB_COMPARE_SRC = "model_ab_compare_dc_src";

export const INDEX_IN_AB = {1: "A", 2: "B", 3: "C"};

export const AB_THUMBNAIL_LIMIT = 10;


// Has mapping between names in Advanced filter and chart names
export const AB_ADVANCE_FILTER_METRIC_NAMES = {
    "accuracy": "Accuracy",
    "precisionVsRecall": "precision vs recall",
    "sensitivityVsSpecificity": "Sensitivity vs Specificity",
    "weightedRecall": "Weighted recall",
    "f1score": "f1 score",
    "weightedF1Score": "Weighted F(1) Score",
};

export const DOCUMENT_BASE_LINK = "https://docs.qualdo.ai/";
export const DOCUMENT_LINK_MAPPING = {
    "index": "index.html",
    "data_source": "tutorials/datasources.html",
    "profile_data": "tutorials/profile.html#profile-data",
    "profile-data-relationship": "tutorials/profile.html#relationship",
    "profile-data-completeness": "tutorials/profile.html#completeness",
    "accuracy": "tutorials/profile.html#data-accuracy",
    "profile-data-timeliness": "tutorials/profile.html#data-timeliness",
    "conformity": "tutorials/profile.html#conformity",
    "profile-data-consistency": "tutorials/profile.html#consistency",
    "profile-data-uniqueness": "tutorials/profile.html#uniqueness",
    "profile-data-attribute": "tutorials/profile.html#attribute",
    "monitor-model-performance":"tutorials/insights.html#model-performance",
    "monitor-model-errors": "tutorials/insights.html#model-errors",
    "data-quality-errors": "tutorials/insights.html#data-quality-errors",
    "data-quality-performance": "tutorials/insights.html#data-quality-errors",
    "dqErrorListViewTable": "tutorials/insights.html#id11",
    "modelErrorListViewTable": "tutorials/insights.html#id3",
    "mpErrorListViewTable": "tutorials/insights.html#list-view",
    "modelABTestTable": "tutorials/insights.html#compare-view",
    "mpCompareViewTable": "tutorials/insights.html#compare-view",
    "requests": "account_security.html#join-team",
    "invitation": "account_security.html#invite-to-team",
    "teamMate": "account_security.html#team",
    "profile-models": "tutorials/profile.html#profile-models",
    "consume": "tutorials/consume.html#notification-channels",
    "consume_pipeline": "tutorials/consume.html#pipeline-channels",
    "alert": "tutorials/consume.html#configuring-an-alert",
    "metrics":"tutorials/metrics.html#custom-metrics",
    "datasource":"tutorials/datasources.html",
    "ml_model":"tutorials/model.html",
    "billingHistory":"account_security.html#billing-payment",
    "discover":"tutorials/discover.html"
    }


export const MONITOR_DATA_KEYS = ["dataFrom", "dataTo", "data_points_found", "filterEndDate", "filterStartDate", "min_difference"]

export const ALERT_TYPE = {
  "metric_value": "Metric value at dataset level",
  "metric_attribute_level": "Metric value at attribute level",
  "drift_value": "Drift value at dataset level",
  "drift_attribute_level": "Drift value at attribute level",
};

export const ABOVE_ALERT_SUPPORTED_TABLES = ["dqErrorListViewTable", "mpCompareViewTable"];

export const IAM_SECRET_KEY = process.env.REACT_APP_IAM_DECRYPT_KEY;

export const ROW_COUNT_NAME = 'Row Count'

export const POWER_BI_CONNECTOR = 'power_bi_connector'
export const AZURE_SYNAPSE_DS_CONNECTOR = 'azure_synapse_ds'

/* Reason why Synapse is not yet ready:
Currently Qualdo using its own storage location for Synapse connector which is incorrect.
We have to get it from user itself. Thats why it got paused. */

export const DATA_SOURCE_OPTIONS = [
            {
                "options": [{"label": "Azure Blob", "value": 'azure_blob'},
                    {"label": "Azure Data Lake Gen2", "value": 'azure_data_lake_gen2'},
                    {"label": "Azure Data Lake", "value": 'azure_data_lake'},
                    {"label": "Azure Databricks Table", "value": 'azure_databricks_table'},
                    {"label": "Azure Databricks SQL", "value": 'azure_databricks_sql'},
                    {"label": 'Azure SQLServer', "value": 'azure_sql'},
//                    {"label": 'Azure Synapse', "value": 'azure_synapse'},
                    {"label": 'Azure Data Factory', "value": 'azure_data_factory'},
                ],
                "label": "Azure Connectors"
            },
            {
                "options": [{"label": "AWS S3", "value": 's3'},
                            {"label": "AWS Redshift", "value": 'redshift'}],
                "label": "AWS Connectors"
            },
            {
                "options": [{"label": "Google BigQuery", "value": 'big_query'},
                    {"label": "GCP SQLServer", "value": 'gcp_sql'},
                    {"label": "Google Cloud Storage", "value": "gcs"}],
                "label": "GCP Connectors"
            },
            {
                "options": [{"label": "Power bi", "value": POWER_BI_CONNECTOR}],
                "label": "Power Bi Connectors"
            },
            {
                "options": [{"label": "Kafka", "value": 'kafka'}],
                "label": "Streaming Connectors"
            },
            {
                "options": [{"label": 'Snowflake', "value": 'snowflake'},
                    {"label": "Postgres", "value": 'postgresql'},
                    {"label": "MySQL", "value": 'mysql'},
                    {"label": "ClickHouse", "value": 'clickhouse'}],
                "label": "Database Connectors"
            },
            {
                "options": [{"label": "Looker", "value": 'looker'},
                    {"label": "Tableau", "value": 'tableau'},
                    {"label": 'Power BI', "value": 'sigma'}],
                "label": "BI Connectors"
            }, {
                "options": [{"label": "DBT", "value": 'dbt'}],
                "label": "Transformation Connectors"
            },
            {
                "options": [{"label": "SAP BODS", "value": 'dods'}],
                "label": "SAP Connectors"
            },
        ];

export const MANAGED_SERVICE_NAME = 'managed_service'

export const CYCLE ={
    'annually':"Annually",
    'monthly': "Monthly",
}

export const CLOUD ={
    "gcp_brandMark":"Google Cloud",
    "azure_brandMark":"AZURE Cloud",
    "aws_brandMark":"AWS Cloud"
}

export const GCLOUD = "GCP"
export const AWS_CLOUD = "AWS"

export const LOCAL_STORE_KEY = "yek_odlauq"

export const QUALDO_DOCUMENTATION = "Qualdo™ Documentation"

export const LOGIN_SOURCE = {
    NORMAL_LOGIN_SRC: 1,
    GOOGLE_LOGIN_SRC: 2,
    MSI_LOGIN_SRC: 3
};


export const SRC_ATTRIBUTE_COLUMN = "Source Attribute";
export const DEST_ATTRIBUTE_COLUMN = "Destination Attribute";

export const MODEL_TYPES = {
    BINARY_CLASSIFICATION: "binary_classification_model",
    MULTI_CLASS_CLASSIFICATION: "multi_class_classification_model",
    CLUSTERING_MODEL_WO_RESPONSE: "clustering_model_without_response_data",
    CLUSTERING_MODEL_WITH_RESPONSE: "clustering_model_with_response_data",
    REGRESSION: "regression",
    RECOMMENDATION: "recommendation"
}


// Represent the max allowed sleep time value (in minutes). To be in sync with backend code.
// qualdo/qualdo_utils/constants.py => MAX_SLEEP_TIME_LIMIT
export const MAX_SLEEP_TIME = 10080;
export const MAX_SLEEP_ERROR = `You have crossed maximum time limit range,
Please set lesser than ${MAX_SLEEP_TIME} minutes`;
export const NEG_SLEEP_ERROR = "Time interval should be a positive number";


export const FILE_TYPE_OPTIONS = [
    {"label":"delta", "value":"delta"},

    // Supported file type in Databricks table
    // {"label":"avro", "value":"avro"},
     {"label":"csv", "value":"csv"},
     {"label":"json", "value":"json"},
    // {"label":"orc", "value":"orc"},
     {"label":"parquet", "value":"parquet"},
    // {"label":"text", "value":"text"}
];


export const WINDOW_METRICS = ["Row Count"]

export const METRIC_SCALE_MAPPING = {
  "Data Accuracy Errors":"%",
  "Data Completeness":"%",
  "Data Timeliness":"days",
  "Fill Rate":"%",
  "Row Count":"",
  "Data Outlier":"%",
  "Data Outliers":"%",
  "Recency":"days",
  "Unique Values": "",
  "Accuracy Drift":"%",
  "Data Drift":"%",
  "Data Type Errors":"%",
  "Data Consistency":"%",
  "Data Consistency Errors":"%",
  "Date Conformity Errors":"%",
  "Email Conformity Errors":"%",
  "Categorical Encoding Errors":"%",
  "Categorical Ordinal Errors":"%",
  "Credit Card Conformity Errors":"%",
  "Gender Conformity Errors":"%",
  "Http Conformity Errors":"%",
  "IP Conformity Errors":"%",
  "Normalization Consistency Errors":"%",
  "SSN Conformity Errors":"%",
  "URL Conformity Errors":"%",
  "Data Conformity Errors":"%",
  "Data Type Consistency Errors":"%",
  "Categorical Consistency Errors":"%",
  "Boolean Conformity Errors":"%",
  "Data Type":"%",
  "Data Type Consistency":"%",
  "Date Conformity":"%",
  "Email Conformity":"%",
  "Categorical Encoding":"%",
  "Categorical Encode":"%",
  "Categorical Ordinal":"%",
  "Credit Card Conformity":"%",
  "Gender Conformity":"%",
  "Http Conformity":"%",
  "IP Conformity":"%",
  "Normalization Consistency":"%",
  "SSN Conformity":"%",
  "URL Conformity":"%",
  "Data Conformity":"%",
  "Boolean Conformity":"%",

  "Attribute Fill Rate < 25%":"%",
  "Attribute Fill Rate 25% to 75%":"%",
  "Attribute Fill Rate  > 75%":"%",
  "Total number of Rows":"",

  "Feature Drift":"%",
  "Prediction Drift":"%",
  "Response Drift":"%",
  "ML Data Consistency":"%",
  "Model Drift":"%",
  "No of Numerical Attributes with Outliers":"",
  "No of Numerical Attributes without Outliers":"",


  "Datasets with Recency < One Day":"%",
  "Datasets with Recency < One Week":"%",
  "Datasets with Recency < One Month":"%",
  "Datasets with Recency > One Month":"%",

  "No. of Datasets having Duplicate":"",
  "Total no of attributes having duplicate values":"",
  "Total no of duplicate rows in Datasets":"",



};

export const TAB_KEY_MAPPING = {
    "relationship":1,
    "completeness":2,
    "accuracy":3,
    "timeliness":4,
    "conformity":5,
    "consistency":6,
    "uniqueness":7,
    "attribute":8,
}

export const CONSISTENCY_MAPPING = {
    "Categorical Encode":"Categorical Consistency Errors",
    "Data Type Consistency" : "Data Type Consistency Errors",
    "Normalization Consistency" : "Normalization Consistency Errors",
}

export const CONSISTENCY_KEY = [
    "Data Type Consistency" ,
    "Normalization Consistency" ,
    "Categorical Encode",
]

export const RELATIONSHIP_METRIC_KEYS = [
    "Data Completeness" ,
    "Data Timeliness" ,
    "Data Accuracy Errors",
    "Data Conformity Errors",
    "Data Consistency Errors"
]

// Used in Discover page to decide whether to render suggestion or not
// Reference: https://www.npmjs.com/package/react-autosuggest#shouldrendersuggestions-optional
export const SKIP_SUGGESTION_RENDER = ['input-blurred', 'escape-pressed']