import React from 'react';
import { faLayerGroup, faTag, faCalendar, faDatabase } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import $ from 'jquery';
import {
    DISPLAY_DATE_FORMAT,
    TAB_KEY_MAPPING
} from '../../utils/constant';
import Select, {
    components,
} from 'react-select';
import CustomTreeSelectDataset from '../../components/customTreeSelectDataset';
import CustomTreeSelectAttribute from '../../components/customTreeSelectAttribute';
import {
    normalizeAttributeName,
} from "../../utils/attribute_name_utils";

const customSelectDatasource = ({ children, ...props }) => (
    <components.Control {...props} className="has-icon">
        <FontAwesomeIcon
            icon={faDatabase}
            className="form-control__icon"
        />
        {children}
    </components.Control>

);

class TreeFilter extends React.Component {
    constructor(props) {
        super(props);
        this.handleDatasourceoptions = this.handleDatasourceoptions.bind(this)
        this.handleDatasetsoptions = this.handleDatasetsoptions.bind(this)
        //this.handleAttributeoptions = this.handleAttributeoptions.bind(this)
        this.handleSelectedAttributes = this.handleSelectedAttributes.bind(this)
        this.handleSelectDate = this.handleSelectDate.bind(this);
        this.handleClearAll = this.handleClearAll.bind(this);
        this.loadFilter = this.loadFilter.bind(this)
        this.handleExpand = this.handleExpand.bind(this);
        this.handleExpandAttributes = this.handleExpandAttributes.bind(this);
        this.handleAttributesSelection = this.handleAttributesSelection.bind(this);

        // let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        // let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });

        let startDate = moment(this.props.startDate);
        let endDate = moment(this.props.endDate);

        this.state = {
            filterData: this.props.data,
            filterDataInitial: this.props.data,
            dateDisplay: startDate.format(DISPLAY_DATE_FORMAT) + ' - ' + endDate.format(DISPLAY_DATE_FORMAT),
            startDate: startDate,
            endDate: endDate,
            attributeOptions: [],
            datasetOptions: [],
            renderDatasetDD: false,
            seletedDatasource: this.props.seletedDatasourceId,
            seletedDatasourceLabel: this.props.seletedDatasourceName,
            filteredDatas: {}
        }
    }
    handleClearAll() {
        this.setState({ filterData: this.state.filterDataInitial });
    }
    handleExpand(pmData) {
        let expand = this.state.filterData;
        expand['parentDatasets'] = pmData;
        this.setState({ filterData: expand });
        this.setState({ filterData: expand });
    }

    componentDidMount() {

        let data = this.state.filterData;
        if(data!=="" && data!==undefined){

        let filterIntegrationData = this.state.filterData['datasetsOptions'].filter((data) =>
        (data.integration_id === this.state.seletedDatasource && data.checked === true)
        ||
        (data.children !== undefined && data.integration_id === this.state.seletedDatasource )
        )
        
        this.setState({ attributeOptions: [] })
        let mappedAttributes = this.state.filterData['attributesOptions']
        let finalList; // = this.state.attributeOptions
        let parentAttribute = []

        filterIntegrationData.map((selectedList, i) => {
            if (selectedList.checked === true || selectedList.children !== undefined) {
            /** OR condition is included If only child dataset is seleted */
                let splitLable = selectedList.label.split('( ')
                let loopData = []
                if (i === 0) {
                    parentAttribute.push({ "label": "All", "value": "*" })
                }
                if(mappedAttributes[selectedList.value]!==undefined){

                        let childAttributes = []
                        let parentName = null                        

                        if((selectedList.is_child_dataset === undefined || selectedList.is_child_dataset !== true) && selectedList.checked === true){
                            parentAttribute.push({ "label": splitLable[0], "disabled": true })
                            let checked = false
                            let parentAttributeList = mappedAttributes[selectedList.value]
                            parentAttributeList.forEach((attributeDetails) => {
                                let attribute_name = attributeDetails.label
                                let req_attribute_name = normalizeAttributeName(attribute_name)
                                attributeDetails['label'] = req_attribute_name
                            })
                            let AllChecked = parentAttributeList.filter((data)=> data.checked === true)
                            if(parentAttributeList.length === AllChecked.length){
                                /**If all attributes check from parent dataset marked All options as marked on attribute Dropdown */
                                checked=true
                            }                
                                        
                            parentAttribute.push({ "label": "All", "value": "*","checked":checked, "dataset_id": selectedList.value})
                            parentAttribute = parentAttribute.concat(mappedAttributes[selectedList.value])                            
                        }

                        if (selectedList.children !== undefined && selectedList.children.length > 0) {
                            selectedList.children.map((selectedListchild, j) => {
                                if (selectedListchild.checked === true && selectedListchild.value !== "*") {
                                    let checked=false;
                                    let childAttributeList = mappedAttributes[selectedListchild.value] //.slice(1,mappedAttributes[selectedListchild.value].length)
                                    childAttributeList.forEach((attributeDetails) => {
                                        let attribute_name = attributeDetails.label
                                        let req_attribute_name = normalizeAttributeName(attribute_name)
                                        attributeDetails['label'] = req_attribute_name
                                    })
                                    let AllChecked = childAttributeList.filter((data)=> data.checked === true)
                                    if(childAttributeList.length === AllChecked.length){
                                        /**If all attributes check from child-data set marked child-dataset as marked on attribute Dropdown */
                                        checked=true
                                    }                
                                    
                                    

                                    let expand=false
                                    if(AllChecked.length > 0){
                                        /**Expand the dropdown if anyone of the attribute is selected from the child dataset */
                                        expand = true
                                    }
                                    
                                    childAttributes = [{ "label": selectedListchild.label, "value": selectedListchild.value,"expanded":expand, checked:checked, "children": childAttributeList }]
                                    if(parentName === null ){
                                        parentName = selectedListchild.label.split('$')
                                        loopData = [{ "label": splitLable[0] + " - Partitions", "disabled": true }]
                                        parentAttribute = parentAttribute.concat(loopData)    
                                    }
                                    parentAttribute = parentAttribute.concat(childAttributes)
                                    return selectedListchild;
                                }
                                return selectedListchild;
                            })
                        }                    
                }

                finalList = parentAttribute
            }
            return selectedList;
        }
        )
        this.setState({ attributeOptions: finalList })
        }
    }    


    handleSelectedAttributes(selectedList, selectedNode=null) {
        let data = selectedList;
        let filterIntegrationData = data.filter((data) =>
            (data.integration_id === this.state.seletedDatasource && data.checked === true) 
        
            ||
            /** OR condition is included If only child dataset is seleted */
            (data.children !== undefined && data.integration_id === this.state.seletedDatasource )
        )
        // return;
        this.setState({ attributeOptions: [] })
        let mappedAttributes = this.state.filterData['attributesOptions']
        let finalList; // = this.state.attributeOptions
        let parentAttribute = []
        filterIntegrationData.map((selectedList, i) => {
            if (selectedList.checked === true || selectedList.children !== undefined) {
            /** OR condition is included If only child dataset is seleted */

                let splitLable = selectedList.label.split('( ')
                let loopData = []
                if (i === 0) {
                    parentAttribute.push({ "label": "All", "value": "*" })
                }
                if(mappedAttributes[selectedList.value]!==undefined){

                        if(selectedList.checked === true){
                            parentAttribute.push({ "label": splitLable[0], "disabled": true })
                            let checked = false
                            let parentAttributeList = mappedAttributes[selectedList.value]
                            parentAttributeList.forEach((attributeDetails) => {
                                let attribute_name = attributeDetails.label
                                let req_attribute_name = normalizeAttributeName(attribute_name)
                                attributeDetails['label'] = req_attribute_name
                            })
                            let AllChecked = parentAttributeList.filter((data)=> data.checked === true)
                            if(parentAttributeList.length === AllChecked.length){
                                /**If all attributes check from parent dataset marked All options as marked on attribute Dropdown */
                                checked=true
                            }                
                                        
                            parentAttribute.push({ "label": "All", "value": "*","checked":checked, "dataset_id": selectedList.value})
                            parentAttribute = parentAttribute.concat(mappedAttributes[selectedList.value])                            
                        }
                        
                        let childAttributes = []
                        let parentName = null                        
                        if (selectedList.children !== undefined && selectedList.children.length > 0) {
                            selectedList.children.map((selectedListchild, j) => {
                                if (selectedListchild.checked === true && selectedListchild.value !== "*") {
                                    let checked=false;
                                    let childAttributeList = mappedAttributes[selectedListchild.value] //.slice(1,mappedAttributes[selectedListchild.value].length)
                                    childAttributeList.forEach((attributeDetails) => {
                                        let attribute_name = attributeDetails.label
                                        let req_attribute_name = normalizeAttributeName(attribute_name)
                                        attributeDetails['label'] = req_attribute_name
                                    })
                                    let AllChecked = childAttributeList.filter((data)=> data.checked === true)
                                    if(childAttributeList.length === AllChecked.length){
                                        /**If all attributes check from child-data set marked child-dataset as marked on attribute Dropdown */
                                        checked=true
                                    }                
                                      
                                    childAttributes = [{ "label": selectedListchild.label, "value": selectedListchild.value, checked:checked, "children": childAttributeList }]
                                    if(parentName === null ){
                                        parentName = selectedListchild.label.split('$')
                                        loopData = [{ "label": splitLable[0] + " - Partitions", "disabled": true }]
                                        parentAttribute = parentAttribute.concat(loopData)    
                                    }
                                    parentAttribute = parentAttribute.concat(childAttributes)
                                    return selectedListchild;
                                }
                                return selectedListchild;
                            })
                        }                    
                }
                finalList = parentAttribute
            }

            return selectedList;
        }
        )

        this.setState({ attributeOptions: finalList })
    }




    handleExpandAttributes(pmData) {
        let expand = this.state.filterData;
        expand['parentDatasets'] = pmData;
        this.setState({ filterData: expand });
    }

    handleSelectDate(event, picker) {
        let startDate = picker.startDate;
        let endDate = picker.endDate;
        let customlabel = picker.chosenLabel === "Custom Range" ? startDate.format(DISPLAY_DATE_FORMAT) + ' - ' + endDate.format(DISPLAY_DATE_FORMAT) : picker.chosenLabel;
        this.setState({ dateDisplay: customlabel, startDate: startDate, endDate: endDate })


        let loadparams = {
            "integration_id": this.state.seletedDatasource,
            "integration_name": this.state.seletedDatasourceLabel,
            "tab_type": TAB_KEY_MAPPING[this.props.tab],
            "tab": TAB_KEY_MAPPING[this.props.tab],
            "start_date": startDate,
            "end_date": endDate
        }

        let filteredDatas = this.state.filteredDatas
        // if(Object.keys(this.state.filteredDatas).length > 0){
        //     let filteredDatas = this.state.filteredDatas

        // }
        let dateFilter = true
        
        /**Function used to Persist filtered values while applying date filter && switching over tabs*/
        this.props.handleDateFiltered(loadparams, filteredDatas,dateFilter)

        /**Function used to Persist filtered values while switching over tabs */
        this.props.loadDataSourceRender(loadparams, filteredDatas, dateFilter)
    }

    handleDatasourceoptions(key) {
        $(".textfield-outlined").removeClass("customselect-active");
        let datasourceID = key.value;
        let datasourceName = key.label;


        let loadparams = {
            "integration_id": datasourceID,
            "integration_name": datasourceName,
            "tab_type": TAB_KEY_MAPPING[this.props.tab],
            "tab": TAB_KEY_MAPPING[this.props.tab],
            "start_date": this.props.startDate,
            "end_date": this.props.endDate
        }
        this.setState({
            renderDatasetDD: true,
            seletedDatasource: datasourceID, 
            seletedDatasourceLabel: key.label,
            filteredDatas:loadparams
        })

        /**Function used to Persist filtered values while switching over tabs && switching over tabs*/
        this.props.handleDateFiltered(loadparams, this.state.filteredDatas)

        this.props.loadDataSourceRender(loadparams)
    }

    handleDatasetsoptions(key) {

    }

    /*
    handleAttributeoptions(selectedList) {


        // let data = selectedList;
        // let filterIntegrationData = data.filter((data)=> 
        //     data.integration_id === this.state.seletedDatasource && data.checked=== true
        // )

        let mappedAttributes = this.state.filterData['attributesOptions']
        let finalList = this.state.attributeOptions
        let data=[]
        let splitLable = selectedList.label.split('( ')
        // if (selectedList.value === "all") {
            // for(let child in mappedAttributes){
            //     childDatasetList = mappedAttributes[selectedList.value].concat(childDatasetList)
            //     this.state.filterData['parentDatasets'].map((data) => {
            //         if (data.children !== undefined && data.value === selectedList.value) {
            //             finalList.push({ "label": splitLable[0], "disabled": true })
            //             data.children.map((child) => {
            //                 let childAttributes = []
            //                 childDatasetList.push({ "label": child.label, "disabled": true })
            //                 childAttributes = [{ "label": child.label, "value": selectedList.value, "children": mappedAttributes[child.value] }]
            //                 childDatasetList = childDatasetList.concat(childAttributes)
            //                 // finalList.push({"label":selectedList.label, "disabled": true })
            //             })

            //             parentAttribute = mappedAttributes[selectedList.value].concat(childDatasetList)
            //         }
            //     })
            // }
          //  return
        //}

        if (selectedList.checked === false) {
            finalList = finalList.filter((data) => data.dataset_id !== selectedList.value && data.label !== splitLable[0] && data.label !== selectedList.label)
        }
        else {
            let childDatasetList = []
            let parentAttribute = mappedAttributes[selectedList.value].concat(childDatasetList)
            // let SelectedDataset = selectedList

            if (selectedList._children !== undefined && selectedList._children.length > 0) {
                this.state.filterData['parentDatasets'].map((data) => {
                    if (data.children !== undefined && data.value === selectedList.value) {
                        finalList.push({ "label": splitLable[0], "disabled": true })
                        data.children.map((child) => {
                            let childAttributes = []
                            childDatasetList.push({ "label": child.label, "disabled": true })
                            childAttributes = [{ "label": child.label, "value": selectedList.value, "children": mappedAttributes[child.value] }]
                            childDatasetList = childDatasetList.concat(childAttributes)
                            return child;
                            // finalList.push({"label":selectedList.label, "disabled": true })
                        })

                        parentAttribute = mappedAttributes[selectedList.value].concat(childDatasetList)
                    }
                    return data;
                })
            }
            data = [{ "label": selectedList.label, "value": selectedList.value, "children": parentAttribute }]
        }
        finalList = finalList.concat(data)
        this.setState({ attributeOptions: finalList })
    }*/

    handleAttributesSelection(selectedList) {
        this.setState({ attributeOptions: selectedList })
    }

    loadFilter(pm) {
        this.setState({ filteredDatas: pm })
        // this.state.filterData["attributesOptions"] = JSON.parse(JSON.stringify(this.state.filterData["attributesOptions"]).replaceAll(/"checked":true/g,'"checked":false'));
        this.props.handleFilteredData(pm);
    }

    handleFocus() {
        // $("#chooseDatasourceForDqError").parent(".textfield-outlined").addClass('customselect-active');   
    }

    handleBlur() {
        $(".textfield-outlined").removeClass("customselect-active");
    }

    render() {
        let ranges = {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        };

        return (
            <div className="qd-filter-section">
                <div className="qd-filter__content">
                    <div className="qd-filter__header">
                        <h5 className="qd-filter__title">Data Relationship Filter</h5>
                        <button type='button' className="close"><span className="sr-only">Close</span></button>
                    </div>
                    <div className="qd-filter__body">
                        <div className="qd-filter__options">
                            <div className={this.props.clearAllFilter === true ? "has-error textfield-outlined" : "textfield-outlined"}>
                                <Form.Label>Datasource</Form.Label>
                                <Select
                                    onFocus={this.handleFocus}
                                    value={this.props.clearAllFilter === true ? { value: "-1", label: "" } : { value: this.props.seletedDatasourceId, label: this.props.seletedDatasourceName }}
                                    id="chooseDatasourceForDqError"
                                    name="data_sources"
                                    options={this.state.filterData['datasourceOptions']}
                                    onChange={this.handleDatasourceoptions}
                                    classNamePrefix='form-control'
                                    placeholder="Choose Datasource"
                                    components={{ Control: customSelectDatasource }}
                                />
                                {/* <CustomTreeSelect
                                    id="chooseDatasourceForDqError"
                                    name="data_sources"
                                    // data={data_dataset}
                                    data={this.state.filterData['datasourceOptions']}
                                    classNamePrefix='form-control'
                                    loadFilter={this.loadFilter}
                                    prependIcon={faDatabase}
                                    placeholder={"Choose Datasource"}
                                />*/}
                            </div>
                            <div className="textfield-outlined ">
                                <Form.Label>Datasets </Form.Label>
                                <CustomTreeSelectDataset
                                    id="chooseDatassetForDqError"
                                    name="data_set"
                                    classNamePrefix='form-control'
                                    loadFilterDataset={this.loadFilter}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    seletedDatasource={this.state.seletedDatasource}
                                    data={this.props.clearAllFilter === true ? [{ "integration_id": this.state.seletedDatasource, "label": "No options", "disabled": true }] : this.state.filterData['parentDatasets']}
                                    prependIcon={faLayerGroup}
                                    placeholder={"Choose Dataset"}
                                    handleSelectedAttributes={this.handleSelectedAttributes}
                                    noMatches="No options"
                                    handleExpand={this.handleExpand}
                                    // handleAttributeoptions={this.handleAttributeoptions}
                                    renderDatasetDD={this.state.renderDatasetDD}
                                />
                            </div>
                            <div className="textfield-outlined">
                                <Form.Label>Attributes</Form.Label>
                                <CustomTreeSelectAttribute
                                    id="chooseAttributeForDqError"
                                    name="attributes"
                                    data={this.props.clearAllFilter === true || this.state.attributeOptions === undefined || (this.state.attributeOptions !== undefined && this.state.attributeOptions.length === 0) ? [{ "integration_id": this.state.seletedDatasource, "label": "No options", "disabled": true }] : this.state.attributeOptions}
                                    seletedDatasource={this.state.seletedDatasource}
                                    //  data={this.state.attributeOptions}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    // data={this.state.attributeOptions !== undefined && this.state.attributeOptions.length === 0 ? [{ "label": "No options", "disabled": true }] : this.state.attributeOptions}
                                    classNamePrefix='form-control'
                                    loadFilter={this.loadFilter}
                                    prependIcon={faTag}
                                    placeholder={"Choose Attributes"}
                                    noMatches="No options"
                                    handleExpand={this.handleExpandAttributes}
                                    handleAttributesSelection={this.handleAttributesSelection}
                                />
                            </div>
                            <span className="dot-separator"></span>
                            <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onApply={this.handleSelectDate}
                                ranges={ranges}>
                                <i>
                                    <FontAwesomeIcon icon={faCalendar} />
                                </i>
                                <span>
                                    {this.state.dateDisplay}
                                    {/* {this.state.endDate.format(DISPLAY_DATE_FORMAT)+' - '+ this.state.startDate.format(DISPLAY_DATE_FORMAT)} */}
                                </span>
                                <i className="icon-close ml-2"></i>
                            </DateRangePicker>
                            <div className="custom-control custom-switch custom-switch-sm text-muted">
                                <Form.Control
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="include_pii"
                                    checked
                                />
                                <Form.Label className="custom-control-label pl-2">Include PII</Form.Label>
                            </div>
                            <span className="line-separator"></span>
                            <button className="btn btn-filter-reset shadow-none px-2" onClick={this.props.clearAllFilterData}>Clear All</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default TreeFilter;
