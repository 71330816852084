import React from 'react';
import Select from "react-select";
import { DISPLAY_DATE_FORMAT, PIPELINE_DISPLAY_DATE_FORMAT} from "../utils/constant";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faArrowRight, faChartArea, faInfoCircle, faCheck, faPlusCircle, faAsterisk } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import FollowComponent from "./components/followComponent"
import { connect } from "react-redux";
import ThumbnailChartList from "./components/thumbnailChartList";
// import CustomSelect from "./components/customSelect";
import _ from 'lodash';
import Load from '../components/loadAction';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from '../components/image';
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";
import { addFollowAttributes } from "../redux/actions";
import $ from 'jquery';
import { faAngleDoubleDown} from "@fortawesome/free-solid-svg-icons";
import { getApi } from "../utils/event_handling";
import QualdoDataTable from "../components/bootstrapTable";
import PipelinePerformFullScreen from "./pipelinePerformFullScreen";
import Popover from 'react-bootstrap/Popover';
import {
    handleTime, handleChangeDataset, changeAttribute,
    handleSubmit, handleCheckClick, getPlaceholder
} from "./components/filter_common_utils";
import { browserHistory} from 'react-router';

const pipeline_page_cnt = 20;
const TIMEHOURS_12 = ['01','02','03','04','05','06','07','08','09','10','11','12','13'];
const TIMEHOURS_6 = ['01','02','03','04','05','06'];

class DqListViewContent extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getDQListTableComponents = this.getDQListTableComponents.bind(this);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.getFilteredData = this.getFilteredData.bind(this);
        this.changeDataSource = this.changeDataSource.bind(this);
        this.handleTime = handleTime.bind(this);
        this.changeAttribute = changeAttribute.bind(this);
        this.handleCheckClick = handleCheckClick.bind(this);
        this.handleSubmit = handleSubmit.bind(this);
        this.handleChangeDataset = handleChangeDataset.bind(this);
        this.changeDataSourceAdvanced = this.changeDataSourceAdvanced.bind(this);
        this.changeDataset = this.changeDataset.bind(this);
        this.closeFullScreenMode = this.closeFullScreenMode.bind(this);
        this.handleDQListViewTimeFilter = this.handleDQListViewTimeFilter.bind(this);
        this.getCustomClassForActions = this.getCustomClassForActions.bind(this);
        this.getPlaceholder = getPlaceholder.bind(this);
        this.changeGroup = this.changeGroup.bind(this);
        this.renderJobsSourceHtml = this.renderJobsSourceHtml.bind(this);
        this.applyTablewrapperClasses = this.applyTablewrapperClasses.bind(this);

        let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });
        this.getData = this.getData.bind(this);

        // TimeFilter
        this.getApi = getApi.bind(this);
        // this.timeFilter = this.timeFilter.bind(this);
        this.filterData = this.filterData.bind(this);
        this.paging = this.paging.bind(this);
        this.activeRun = this.activeRun.bind(this);
        this.pipelineSourceTableData = this.pipelineSourceTableData.bind(this);
        this.handlePipelineTreeTriger = this.handlePipelineTreeTriger.bind(this);
        this.errMessage = this.errMessage.bind(this); 
        this.pipelinesourceFilterSelection = this.pipelinesourceFilterSelection.bind(this); 
        this.innerpage = this.innerpage.bind(this); 
        this.pageTrack = this.pageTrack.bind(this); 
        this.prevDate = this.prevDate.bind(this);
        this.nextDate = this.nextDate.bind(this);
        this.nowDate = this.nowDate.bind(this);
        this.applyTimeFilter = this.applyTimeFilter.bind(this)
        this.timeFilterRenderHtml = this.timeFilterRenderHtml.bind(this);
        this.applyTimeFilterNow = this.applyTimeFilterNow.bind(this);
        this.datewisejobsdata = this.datewisejobsdata.bind(this);
        this.renderPipelineSourceHtml = this.renderPipelineSourceHtml.bind(this);
        this.gotoPipelineConfigure = this.gotoPipelineConfigure.bind(this);

        let listViewTableData = this.props.listViewTableData;
        this.state = {
            // initial: {
            //     data: null
            // },
            activeRunFilter:0,
            detailJobId: '',
            detailPipelineName: '',
            pipelinedatasource: null,
            timefilter: "12",
            pipelinelist: null,
            filterpipelinelist: null,
            selectedDataSet: [],
            selectedDataSourceID: null,
            isInFullScreenMode: false,
            startDate: startDate,
            endDate: endDate,
            selectedDatasource: null,
            datasets: [],
            showAttributes: true,
            attributeOptions: [],
            selectedGroup: null,
            originalAttributeOptions: [],
            selectedAttributes: [],
            fullScreenClassVal: "",
            currListViewTableData: listViewTableData,
            dataFilteredValues: [],
            completeTableData: listViewTableData,
            defaultDatasourceInside: null,
            lastProfilingTimeData: null,
            timeFilterApplied: false,
            fullScreenData: null,
            fullScreenAttributeData: null,
            keyData: `dqListView_${listViewTableData.length}`,
            initial: {
                datasource: "",
                dataset: "",
                attribute: "",
            },
            pipelinesource_data:"-1",
            initialendDate:endDate,
            initialstartDate:startDate,
            pipelinesource_filter:[],
            page_selected_pipeline:'',
            cur_page_selected_pipeline:1,
            paginationtrack:1,
            filter_pipeline_id:"All",
            detailJobList:[],
            selectedJobDetailsFailedRuns:'',
            selectedJobDetailsSucessRuns:'',
            selectedJobDetails:'',
            selectedPipelineInfo:''
        }
    }

    gotoPipelineConfigure(){
        //console.log("Clicked")
        let redirectPage = "/pipline"
        if(this.props.navigatePage !== undefined){
            redirectPage = this.props.navigatePage
        }
        browserHistory.push(redirectPage);
    }

    activeRun(e){
        const isChecked = e.target.checked;
        if(isChecked){
            this.setState({"activeRunFilter":1});
        }else{
            this.setState({"activeRunFilter":0});
        }
    }

    applyTablewrapperClasses(){
        if(parseInt(this.state.timefilter) === 12 || parseInt(this.state.timefilter) === 24){
            return "table-responsive pipeline-table";
        }else {
            return "table-responsive pipeline-table tbl-time-range--selected";
        }
    }

    changeDataSourceAdvanced(dataSourceOption) {
        let datasourceId = dataSourceOption.value;
        let datasets = []
        this.setState({datasets: datasets,
                        selectedDatasource: dataSourceOption,
                        page_selected_pipeline:datasourceId
                        });
   }
    pageTrack(pmCurrentpage){
        this.setState({"paginationtrack":pmCurrentpage,
        page_selected_pipeline:'',
        cur_page_selected_pipeline:1
        });

    }
    nextDate(){
        var endDate = moment(this.state.endDate);
        endDate = endDate.add(1, "days");
        endDate = this.state.initialendDate > endDate ? endDate : this.state.initialendDate;
        this.setState({"endDate":endDate},()=>this.timeFilterRenderHtml(this.state.timefilter));
    }   
    prevDate() {
        let  endDate = moment(this.state.endDate);
        endDate = endDate.subtract(1, "days");
        let startDate = this.state.startDate > endDate ?  endDate : this.state.startDate;
        this.setState({"endDate":endDate,"startDate":startDate},()=>this.timeFilterRenderHtml(this.state.timefilter));
    } 
    nowDate() {
        let  endDate = moment(this.state.initialendDate);
        this.setState({"endDate":endDate},()=>this.timeFilterRenderHtml(this.state.timefilter));
    } 

pipelinesourceFilterSelection() {
    let filter = [];
    $('input:checkbox[name=defaulErrorCheck]:checked').each(function() 
    {
        filter.push($(this).val());
    });
    this.setState({"pipelinesource_filter":filter});
    // pipelinesource_filter
}

    applyTimeFilterNow(){
        this.setState({
                       endDate:this.state.initialendDate,
                       startDate:this.state.initialstartDate}
                      );
    } 

    applyTimeFilter(time) {
      if(parseInt(time) === 12 || parseInt(time) === 24){

        $("th").eq(3).html(
        `<div class="tbl-time-range">
                                                            <div class="tbl-time-range__header">
                                                                <label class="text-dark"> Last Day Schedule - <strong id="lastSechduleDate">`+this.props.lastSechduleDate+`</strong></label>
                                                                <label>
                                                                </label>
                                                                <ul class="tbl-time-range__list">
                                                                    <li id="chart_time_1">&nbsp;</li>
                                                                    <li id="chart_time_2">&nbsp;</li>
                                                                    <li id="chart_time_3">&nbsp;</li>
                                                                    <li id="chart_time_4">&nbsp;</li>
                                                                    <li id="chart_time_5">&nbsp;</li>
                                                                    <li id="chart_time_6">&nbsp;</li>
                                                                    <li id="chart_time_7">&nbsp;</li>
                                                                    <li id="chart_time_8">&nbsp;</li>
                                                                    <li id="chart_time_9">&nbsp;</li>
                                                                    <li id="chart_time_10">&nbsp;</li>
                                                                    <li id="chart_time_11">&nbsp;</li>
                                                                    <li id="chart_time_12">&nbsp;</li>
                                                                    <li id="chart_time_13">&nbsp;</li>
                                                                </ul>
                                                            </div>
                                                        </div>
        `
        );
    }else {

        $("th").eq(3).html(
            `<div class="tbl-time-range">
                                                                <div class="tbl-time-range__header">
                                                                    <label class="text-dark"> Last Day Schedule - <strong id="lastSechduleDate">`+this.props.lastSechduleDate+`</strong></label>
                                                                    <label>
                                                                    </label>
                                                                    <ul class="tbl-time-range__list">
                                                                        <li id="chart_time_1">&nbsp;</li>
                                                                        <li id="chart_time_2">&nbsp;</li>
                                                                        <li id="chart_time_3">&nbsp;</li>
                                                                        <li id="chart_time_4">&nbsp;</li>
                                                                        <li id="chart_time_5">&nbsp;</li>
                                                                        <li id="chart_time_6">&nbsp;</li>
                                                                        <li id="chart_time_7">&nbsp;</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
            `
            );
    }
    this.timeFilterRenderHtml(time);
    if(time==="0"){
        this.setState({ "timefilter":parseInt(1),endDate:this.state.initialendDate,startDate:this.state.initialstartDate});
    }else{
        this.setState({ "timefilter":parseInt(time)});
    }
 }

 

 timeFilterRenderHtml(time){

    let timelen;
    if(parseInt(time) === 12 || parseInt(time) === 24){
        timelen = 13;
    }
    else {
        timelen = 7;
    }

    let curdate = this.state.initialendDate === this.state.endDate ? moment() : this.state.endDate
    let timeserieshrs = parseInt(time)+1;

    let timeseriesmins = moment(curdate)
    .subtract({'hours':time})
    .format('mm');

    // let timeseriesonehrs = moment(curdate)
    // .subtract({'hours':time})
    // .format('mm');

    for(let loop=0; loop<=timelen;loop++){
    if(parseInt(time) ===12 || parseInt(time) === 24 ||  parseInt(time) === 6){

        if(parseInt(time) ===12 || parseInt(time) === 6){
            $("#chart_time_"+loop).html(moment(curdate)
            .subtract({'hours':timeserieshrs})
            .format('hh A'));
            timeserieshrs = parseInt(timeserieshrs)-1
        }else {
            if(loop === 12){
                $("#chart_time_"+loop).html(moment(curdate)
            .subtract({'hours':timeserieshrs})
            .format('hh A'));
            timeserieshrs = parseInt(timeserieshrs)-1
            }else {
                $("#chart_time_"+loop).html(moment(curdate)
            .subtract({'hours':timeserieshrs})
            .format('hh A'));
            timeserieshrs = parseInt(timeserieshrs)-2
            }
        }
    } else {
        $("#chart_time_"+(timelen-loop)).html(moment(curdate)
        .subtract({'minutes': timeseriesmins})
        .format('hh:mm A'));
        timeseriesmins = parseInt(timeseriesmins)+parseInt(10)
    }
    }

 }


 getData() {
        this.getApi("get_pipelineprefetch");
    }


    errMessage(){
            let pmflag = 2;
            if(pmflag===1){
            return(<><div className="react-bootstrap-table__header">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="btn-group react-table__length" role="group">
                                                            <span>Show</span>
                                                            <select className="select-sm" id="paging" style={{ backgroundColor: "#fff", border: "1px solid #CED4DA", padding: "4px 10px", margin: "0 5px", color: "#484848" }}>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="20">20</option>
                                                                <option value="all">all</option>
                                                            </select>
                                                            <span>items</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            <Form.Check 
                                                                type="switch"
                                                                id="custom-switch"
                                                                label="Active Runs Only"
                                                                className="custom-switch-sm mr-3"
                                                                onChange={this.activeRun}
                                                            />
                                                            
                                                            <div className="btn-toolbar mr-2">
                                                                <div className="btn-toolbar__wrap">
                                                                    <div className="btn-toolbar__item">
                                                                        <Button variant=" ">Now</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn-toolbar mr-2">
                                                                <div className="btn-toolbar__wrap">
                                                                    <div className="btn-toolbar__item">
                                                                        <Button onClick={this.timeFilter.bind(this, "1")} variant=" " className={this.state.timefilter === "1hr" ? "active" : " "}>1hr</Button>
                                                                    </div>
                                                                    <div className="btn-toolbar__item">
                                                                        <Button onClick={this.timeFilter.bind(this, "6")} variant=" " className={this.state.timefilter === "6hr" ? "active" : " "}>6hr</Button>
                                                                    </div>
                                                                    <div className="btn-toolbar__item">
                                                                        <Button onClick={this.timeFilter.bind(this, "12")} variant=" " className={this.state.timefilter === "12hr" ? "active" : " "}>12hr</Button>
                                                                    </div>
                                                                    <div className="btn-toolbar__item">
                                                                        <Button onClick={this.timeFilter.bind(this, "24")} variant=" " className={this.state.timefilter === "24hr" ? "active" : " "} >24hr</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn-toolbar mr-3">
                                                                <div className="btn-toolbar__wrap">
                                                                    <div className="btn-toolbar__item">
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant=" " id="dropdown-basic">
                                                                                <svg className="" xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="18"
                                                                                    viewBox="0 0 24 24" width="18">
                                                                                    <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                                                                    <path d="M0 0h24v24H0z" fill="none" />
                                                                                </svg>
                                                                                <span className="filter-count">0</span>
                                                                                {/* d-inline-flex */}
                                                                                <span className="ml-1">Filter</span>
                                                                            </Dropdown.Toggle>
    
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item href="/"className="py-1">
                                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                                        <Form.Control
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            name="defaulErrorCheck"
                                                                                            id="pipeline-running" />
                                                                                        <Form.Label className="custom-control-label" for="pipeline-running">
                                                                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clip-path="url(#clip0_263_199)">
                                                                                                    <path d="M16.24 7.76C15.07 6.59 13.54 6 12 6V12L7.76 16.24C10.1 18.58 13.9 18.58 16.25 16.24C18.59 13.9 18.59 10.1 16.24 7.76V7.76ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="currentColor" />
                                                                                                </g>
                                                                                                <defs>
                                                                                                    <clipPath id="clip0_263_199">
                                                                                                        <rect width="24" height="24" fill="white" />
                                                                                                    </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                            <span className="ml-1">Running</span>
                                                                                        </Form.Label>
                                                                                    </div>
                                                                                </Dropdown.Item> 
                                                                                <Dropdown.Item href="#/action-1" className="py-1">
                                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                                        <Form.Control
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            name="defaulErrorCheck"
                                                                                            id="pipeline-scheduled" />
                                                                                        <Form.Label className="custom-control-label" for="pipeline-scheduled">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z" /></g></g></g></svg>
                                                                                            <span className="ml-1">Scheduled</span>
                                                                                        </Form.Label>
                                                                                    </div>
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item href="#/action-2" className="py-1">
                                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                                        <Form.Control
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            name="defaulErrorCheck"
                                                                                            id="pipeline-progress" />
                                                                                        <Form.Label className="custom-control-label" for="pipeline-progress">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5z" /></g></svg>
                                                                                            <span className="ml-1">In Progress</span>
                                                                                        </Form.Label>
                                                                                    </div>
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                                        <Form.Control
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            name="defaulErrorCheck"
                                                                                            id="pipeline-queued" />
                                                                                        <Form.Label className="custom-control-label" for="pipeline-queued">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" /></svg>
                                                                                            <span className="ml-1">Queued</span>
                                                                                        </Form.Label>
                                                                                    </div>
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                                        <Form.Control
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            name="defaulErrorCheck"
                                                                                            id="pipeline-done" />
                                                                                        <Form.Label className="custom-control-label" for="pipeline-done">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                            <span className="ml-1">Done</span>
                                                                                        </Form.Label>
                                                                                    </div>
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                                        <Form.Control
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            name="defaulErrorCheck"
                                                                                            id="pipeline-failed" />
                                                                                        <Form.Label className="custom-control-label" for="pipeline-failed">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" /></svg>
                                                                                            <span className="ml-1">Failed</span>
                                                                                        </Form.Label>
                                                                                    </div>
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <div className="btn-group react-table__filter" role="group">
                                                                <span>Search:</span>
                                                                <div className="table-search">
                                                                    <input placeholder="Search" id="searchBox"
                                                                        className="form-control" style={{ backgroundColor: "#ffffff" }}
                                                                        type="text" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="react-bootstrap-table table-responsive pipeline-table">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th width="50">R.No</th>
                                                            <th width="200">Pipeline Source</th>
                                                            <th width="226">Jobs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td colSpan={11}>
                                                        <div className="row">
                        <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                            <div className="placeholder-msg">
                                <div className="placeholder-msg__icon">
                                    <Image src="icon_placeholder_no_pipeline_data" />
                                </div>
                                <p>No runs identified for the chosen date</p>
                            </div>
                        </div>
                    </div>
                                                        </td></tr>              
    
                                                    </tbody>
                                                </table>
                                            </div> </>);
            }
    
            if(pmflag===2 && this.state.activeRunFilter === 0){
                return (<div className="row">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <div className="placeholder-msg">
                        <div className="placeholder-msg__icon">
                            <Image src="icon_placeholder_pipeline_configure" />
                        </div>
                        <div className="placeholder-msg__text">
                            <h6>No Pipeline Sources</h6>
                            <p>No Pipeline source connector configured</p>
                        </div>
                        <div className="placeholder-msg__action">
                            <button onClick={this.gotoPipelineConfigure} className="btn btn-outline btn-grey btn-circle"><i><FontAwesomeIcon icon={faPlusCircle} /></i>Add Pipelines Source</button>
                        </div>
                    </div>
                </div>
            </div>)
    
            }

            if(pmflag===2 && this.state.activeRunFilter === 1){
                return (<div className="row">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <div className="placeholder-msg">
                        <div className="placeholder-msg__icon">
                            <Image src="icon_placeholder_pipeline_configure" />
                        </div>
                        <div className="placeholder-msg__text">
                            <h6>Active Runs Not Found</h6>
                            {/* <p>No Pipeline source connector configured</p> */}
                        </div>
                        {/* <div className="placeholder-msg__action">
                            <button onClick={this.gotoPipelineConfigure} className="btn btn-outline btn-grey btn-circle"><i><FontAwesomeIcon icon={faPlusCircle} /></i>Add Pipelines Source</button>
                        </div> */}
                    </div>
                </div>
            </div>)
            }
    }
   

    millisecondToMinutesAndSeconds(millis) {
        millis = parseInt(millis);
        if(isNaN(millis) === true){
          return;
        }
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        if(minutes<1){
            return seconds +"s";
        }else{
            return minutes + "m" + (seconds < 10 ? '' : '') + seconds +"s";
        }
    }
    formatUTCDate(Your_Date){
    //    console.log("DATE"+Your_Date);
    //     console.log("DATE"+Your_Date+"--"+moment(Your_Date).format("d MMM, HH:MM"));
    //     return moment(Your_Date);
            return moment.utc(Your_Date).format("DD MMM, HH:MM")
        //return Date.parseExact(Your_Date, 'yyyy-mm-dd').toString('MM/dd/yyyy');
    }
    
    renderJobsSourceHtml(row,rowIndex){

        $("#lastSechduleDate").html(this.state.endDate.format(PIPELINE_DISPLAY_DATE_FORMAT))
        // let timeline = {"timeline": [{
        //     "date": "15-01-2023",
        //     "start": "13:41",
        //     "end": "13:45",
        //     "status": "success"
        // }]}
            let newarray; 
            let newarraydepend = [];
            let builddependdata = [];
            //let min,max; //,sucssscnt,failcnt;
            let startpage,endpage,totalpage,arrtotalpage=[];
            if(pipeline_page_cnt < row.Jobs.value.format_jobs_result.length) {
                totalpage = Math.round(row.Jobs.value.format_jobs_result.length/pipeline_page_cnt);
                for (let i = 1; i <= totalpage; i++) {
                    arrtotalpage.push(i);
                }
            }else {
                totalpage = 0;
            }
                        if(row.Jobs.value.format_jobs_result.length) {

            if(parseInt(row.Jobs.value.format_jobs_result[0].pipeline_integration_id) ===  parseInt(this.state.page_selected_pipeline)){
                if(this.state.cur_page_selected_pipeline === 1){
                    startpage = 0;
                    endpage = pipeline_page_cnt < row.Jobs.value.format_jobs_result.length ? pipeline_page_cnt : row.Jobs.value.pipelinedata.length
                }else{
                    startpage = (parseInt(this.state.cur_page_selected_pipeline)-1) * parseInt(pipeline_page_cnt);
                    endpage = startpage+pipeline_page_cnt;
                    endpage = endpage < row.Jobs.value.format_jobs_result.length ? endpage : row.Jobs.value.pipelinedata.length
                }
            }
            else {
                startpage = 0;
                endpage = pipeline_page_cnt < row.Jobs.value.format_jobs_result.length ? pipeline_page_cnt : row.Jobs.value.format_jobs_result.length
            }

        }

            let pagedata = row.Jobs.value.format_jobs_result.slice(startpage, endpage);

            // console.log("****************");
            // console.log(pagedata);
            // console.log("****************");
            if(pagedata.length === 0){
                return (<div className="row text-center">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <div className="placeholder-msg">
                        <div className="placeholder-msg__icon">
                            <Image src="icon_placeholder_no_pipeline_data" />
                        </div>
                        <p>No runs identified for the chosen date</p>
                    </div>
                </div>
                </div>);
            }
            
            let pipelineindex = pagedata[0].pipeline_integration_id;
            let is_parent = pagedata[0].is_parent;
            return(<table className="table table-tree">
            <tbody>
            {
            pagedata.map((pipelinejob, pipelinejobindex) => {
            newarray = pipelinejob;
            if(newarray!=="" && newarray!==undefined){
                if(pipelinejob.hasOwnProperty('dependent_job') === true ){
                    newarraydepend = pipelinejob.dependent_job;
                    if(newarraydepend!==undefined){
                        for(let dependloop=0;dependloop<newarraydepend.length;dependloop++){
                            for(let dependinnnerloop=0;dependinnnerloop<row.Jobs.value.format_jobs_result.length;dependinnnerloop++){
                                if(newarraydepend[dependloop]===row.Jobs.value.format_jobs_result[dependinnnerloop].pipeline_job_id) {
                                    builddependdata.push(row.Jobs.value.format_jobs_result[dependinnnerloop])
                                }
                            }
                        }
                    }
                } 

                let failedcntflag;
                if(pipelinejob.hasOwnProperty('dependent_job') === true){
                failedcntflag  = builddependdata.filter(function(item){
                    return item.status === "Failed";         
                }).length
                }
                return (
                    pipelinejob.hasOwnProperty('dependent_job') === true ?
                    <>
                    <tr id={"toggle_" + pipelineindex + "_" + pipelinejobindex}>
                    <td width="26">
                        <div id={pipelineindex + "_" + pipelinejobindex} 
                        className={failedcntflag > 0 ? "table-tree__toggle has-failed-jobs" : "table-tree__toggle"} onClick={this.handlePipelineTreeTriger.bind(this, pipelineindex + "_" + pipelinejobindex)}>
                        <i className="table-tree__toggle-icon"></i>
                        </div>
                    </td>
                    <td width="200">
                        <div className="table-tree__label"><span>{newarray.job_name}</span></div>
                    </td>
                    <td width="412" className="pt-0">
                        {this.showGraph1(pipelinejob,'testst')}
                    </td>
                    <td width="95">{this.formatUTCDate(newarray.last_run)}</td>
                    <td width="80"><div className="tbl-last-duration">{this.millisecondToMinutesAndSeconds(newarray.last_duration)}</div></td>
                    <td width="70">
                        <div className="d-flex align-items-center">
                        <span>{this.millisecondToMinutesAndSeconds(newarray.avg_duration)}</span>
                        </div>
                    </td>
                    <td width="95">N/A</td>
                    <td width="110">
                    <div className="tbl-job-attempts">
                        {parseInt(newarray.success_count)  >  0 ?
                        <div className="badge success-attempt-badge">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#9F9F9F">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                            </svg>
                            <span className="text-dark">{newarray.success_count}</span>
                        </div> : ""}
                        {   parseInt(newarray.failed_count)  >  0 ?
                        <div className="badge failed-attempt-badge ml-2">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#9F9F9F">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                            </svg>
                            <span className="text-dark">{newarray.failed_count}</span>
                        </div> : "" }
                        </div>
                    </td>
                    <td width="95">
                        {this.statusIcon(newarray.status)}
                    </td>
                    <td width="80">
                        <button className="btn btn-light" onClick={this.setFullScreen.bind(this, newarray.pipelinesourcename, pipelinejob.pipeline_job_id,row.Jobs.value.format_jobs_result,newarray)}> More  
                        <i className="ml-1">
                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#484848">
                            <rect fill="none" height="24" width="16" />
                            <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z" />
                        </svg>
                        </i>
                        </button>
                    </td>
                    </tr>
                    <tr id={pipelineindex + "_" + pipelinejobindex + "-nested"} className="table-tree__nested">
                    <td colspan="11">
                        <table className="table table-tree">
                        <tbody>
                            {
                            builddependdata!==undefined ?    
                            builddependdata.map((stage, index) => {
                            return (
                            <tr>
                                <td width="26">
                                </td>
                                <td width="200">
                                    <div className="table-tree__label"><span>{stage.job_name}</span></div>
                                </td>
                                <td width="412">
                                   { this.showGraph1(stage,stage.status) }
                                </td>
                                <td width="95" className="border-left-0">{this.formatUTCDate(stage.run_end_time)}</td>
                                <td width="80"><div className="tbl-last-duration">{this.millisecondToMinutesAndSeconds(stage.last_duration)}</div></td>
                                <td width="70">
                                    {this.millisecondToMinutesAndSeconds(stage.avg_duration)}
                                </td>
                                <td width="95">N/A</td>
                                <td width="110">
                                    <div className="tbl-job-attempts">
                                    {  
                                    parseInt(stage.success_count)  >  0 ?
                                    <div className="badge success-attempt-badge">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#9F9F9F">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                                        </svg>
                                        <span className="px-1 text-dark">{stage.success_count}</span>
                                    </div> : ""
                                    }
                                    {   parseInt(stage.failed_count)  >  0 ?
                                    <div className="badge failed-attempt-badge ml-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#9F9F9F">
                                            <path d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                                        </svg>
                                        <span className="px-1 text-dark">{stage.failed_count}</span>
                                    </div> : "" }
                                    </div>
                                </td>
                                <td width="95">
                                    {this.statusIcon(stage.status)}
                                </td>
                                <td width="80">
                                    <button onClick={this.setFullScreen.bind(this, stage.pipelinesourcename, stage.pipeline_job_id,row.Jobs.value.format_jobs_result,newarray)} className="btn btn-light">More 
                                    <i className="ml-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#484848">
                                        <rect fill="none" height="24" width="16" />
                                        <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z" />
                                    </svg>
                                    </i>
                                    </button>
                                </td>
                            </tr>
                            )
                            }) : ""
                            }
                        </tbody>
                        </table>
                    </td>
                    </tr>
                    </> :
                    is_parent === "true" ? 
                    <>
                    <tr id={"toggle_" + pipelineindex + "_" + pipelinejobindex}>
                    <td width="26">
                        
                        <div id={pipelineindex + "_" + pipelinejobindex} 
                        className={newarray.status==="Failed" ? "table-tree__toggle disabled" : "table-tree__toggle disabled"}
                        >
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>No Dependent Jobs
                                </Tooltip>
                            }
                        >
                        <i className="table-tree__toggle-icon"></i>
                        </OverlayTrigger>
                        </div>
                    </td>
                    <td width="200">
                        <div className="table-tree__label"><span>{newarray.job_name}</span></div>
                    </td>
                    <td width="412" className="pt-0">
                        {this.showGraph1(pipelinejob,newarray.status)}
                    </td>
                    <td width="95">{this.formatUTCDate(newarray.last_run)}</td>
                    <td width="80"><div className="tbl-last-duration has-indicator--">{this.millisecondToMinutesAndSeconds(newarray.last_duration)}</div></td>
                    <td width="70">
                        {this.millisecondToMinutesAndSeconds(newarray.avg_duration)}
                    </td>
                    <td width="95">N/A</td>
                    <td width="110">

                        <div className="tbl-job-attempts">
                        {parseInt(newarray.success_count)  >  0 ?
                        <div className="badge success-attempt-badge">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#9F9F9F">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                            </svg>
                            <span className="text-dark">{newarray.success_count}</span>
                        </div> : ""}
                        {   parseInt(newarray.failed_count)  >  0 ?
                        <div className="badge failed-attempt-badge ml-2">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#9F9F9F">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                            </svg>
                            <span className="text-dark">{newarray.failed_count}</span>
                        </div> : "" }
                        </div>
                    </td>
                    <td width="95">
                        {this.statusIcon(newarray.status)}
                    </td>
                    <td width="80">
                        <button className="btn btn-light" onClick={this.setFullScreen.bind(this, newarray.pipelinesourcename, pipelinejob.pipeline_job_id,row.Jobs.value.format_jobs_result,newarray)}> More  
                        <i className="ml-1">
                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#484848">
                            <rect fill="none" height="24" width="16" />
                            <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z" />
                        </svg>
                        </i>
                        </button>
                    </td>
                    </tr>
                    </> : "") 
            }else {
                return (<div></div>);
            }
            })}
            { pipeline_page_cnt<row.Jobs.value.format_jobs_result.length ?
            <tr>
                <td colSpan="20" style={{width:"100%"}} className="table-tree__footer">
                        <div className="row no-gutters">
                        { parseInt(this.state.page_selected_pipeline) !== parseInt(pipelineindex) ?
                            <div className={"col-6 paging_fotter_close"+pipelineindex}>
                            <button data-view="0" data-pipelineindex={pipelineindex} onClick={this.paging.bind(this,pipelineindex)} className="btn btn-link tbl-tree__expand-btn">
                                <i className="mr-1">
                                    <FontAwesomeIcon icon={faAngleDoubleDown} />
                                </i>
                                {pipeline_page_cnt} of {row.Jobs.value.format_jobs_result !== null ? row.Jobs.value.format_jobs_result.length : 0} rows - Click to view all Jobs
                            </button>
                            </div>
                            :
                            <>
                            <div className={"col-6 paging_fotter_exand"+pipelineindex}>
                            <button data-view="1" data-pipelineindex={pipelineindex}  onClick={this.paging.bind(this, pipelineindex)} className="btn btn-link tbl-tree__expand-btn --is-expanded">
                                <i className="mr-1">
                                   <FontAwesomeIcon icon={faAngleDoubleDown} />
                                </i>
                                Exit & Collapse this view
                            </button>
                            </div>
                        <div className="col-6">
                            <ul class="pagination react-bootstrap-table-page-btns-ul">
                                {
                                    parseInt(startpage) === 0 ? 
                                    ""
                                    :
                                    <>
                                    {/* <li class="page-item" title="first page"><a href={() => false}  class="page-link">&lt;&lt;</a></li> */}
                                    <li onClick={this.innerpage} data-page-id={parseInt(this.state.cur_page_selected_pipeline)-1} class="page-item" title="previous page" ><a onClick={this.innerpage} data-page-id={parseInt(this.state.cur_page_selected_pipeline)-1} href={() => false}  class="page-link">&lt;</a></li>
                                    </>  
                                }
                                {
                                    arrtotalpage.map((user) => (
                                        <li  onClick={this.innerpage} data-page-id={user} class={parseInt(user)===parseInt(this.state.cur_page_selected_pipeline) ? "active page-item" : ""}  title={user}><a onClick={this.innerpage} data-page-id={user} href={() => false}  class="page-link">{user}</a></li>
                                    ))
                                }

                                {parseInt(this.state.cur_page_selected_pipeline)===parseInt(totalpage) ?
                                "" :
                                <li  onClick={this.innerpage} data-page-id={parseInt(this.state.cur_page_selected_pipeline)+1} class="page-item" title="next page"><a href={() => false}  onClick={this.innerpage} data-page-id={parseInt(this.state.cur_page_selected_pipeline)+1} class="page-link">&gt;</a></li>
                                }
                                {/* <li class="page-item" title="last page"><a href={() => false}  class="page-link">&gt;&gt;</a></li> */}
                            </ul>
                        </div>
                        </>
                        }
                        </div>
                        </td>
            </tr> : ""
}
            </tbody>
            </table>)

    }
    renderPipelineSourceHtml(row,rowIndex){

        let totalcnt =  row.Jobs.value.format_jobs_result.length;
         let failedcnt = row.Jobs.value.format_jobs_result.filter(function(item){
                return item.status === "Failed";
             }).length
         let sucesscnt = row.Jobs.value.format_jobs_result.filter(function(item){
                return item.status === "Succeeded";
             }).length

        return (
            <>
            <p className="text-info"><i className="mr-1"><FontAwesomeIcon icon={faAsterisk} /></i>{row['Jobs']["value"]["pipelinesourcename"]}</p>
            <div className="qd-connector-box">
   <div className="qd-connector-box__header">
      <label className="qd-connector-box__label">Connector</label>
      { row['Jobs']["value"]["pipeline_type"] === "synapse" ?
            <Image src="connector_logo_azure_synapse" alt="" /> :
        row['Jobs']["value"]["pipeline_type"] === "airflow" ?   
           <Image src="connector_logo_airflow" alt="" />
          :
          row['Jobs']["value"]["pipeline_type"] === "adf" ?   
          <Image src="connector_logo_adf" alt="" />
          :
          "" 
      }
   </div>
   <div className="qd-connector-box__body">
      <ul className="connector-list">
         <li>
            <p>
               <span className="text-muted">
                  <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor">
                     <g>
                        <rect fill="none" height="24" width="24" />
                     </g>
                     <g>
                        <path d="M15,22h4c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-1v-2c0-1.1-0.9-2-2-2h-3V9h1c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2h-4 C8.9,2,8,2.9,8,4v3c0,1.1,0.9,2,2,2h1v2H8c-1.1,0-2,0.9-2,2v2H5c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-3 c0-1.1-0.9-2-2-2H8v-2h8v2h-1c-1.1,0-2,0.9-2,2v3C13,21.1,13.9,22,15,22z" />
                     </g>
                  </svg>
                  <label className="ml-1">Total No Jobs</label>
               </span>
               <strong>{totalcnt}</strong>
            </p>
         </li>
         <li>
            <p>
               <span className="text-success">
                  <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor">
                     <path d="M0 0h24v24H0z" fill="none" />
                     <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                  </svg>
                  <label className="ml-1">Success Jobs</label>
               </span>
               <strong>{sucesscnt}</strong>
            </p>
         </li>
         <li>
            <p>
               <span className="text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor">
                     <path d="M0 0h24v24H0z" fill="none" />
                     <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                  </svg>
                  <label className="ml-1">Failed Jobs</label>
               </span>
               <strong>{failedcnt}</strong>
            </p>
         </li>
      </ul>
   </div>
</div>
</>
        )
    }

    datewisejobsdata(pmData){
        // console.log("++++++++++++++++");
        // console.log(pmData);
        // console.log("++++++++++++++++");
        //  let filterjson = {};
         let newarray = [];
         let filterdate = moment(this.state.endDate).format('Y-MM-DD');
        //  filterdate  = "2023-06-29";
        for(let loop=0;loop<pmData.pipelinedata.length;loop++){
            if(pmData.pipelinedata[loop].payload!==undefined){
                if(pmData.pipelinedata[loop].payload[filterdate]!==undefined){
                    // console.log(pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]['status']);
                    // console.log(this.state.pipelinesource_filter.includes(pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]['status']));
                    if((pmData.pipelinedata[loop].payload[filterdate].length > 0 && this.state.pipelinesource_filter.length === 0) || (pmData.pipelinedata[loop].payload[filterdate].length > 0 && this.state.pipelinesource_filter.length > 0 && this.state.pipelinesource_filter.includes(pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]['status']))){
            //(this.state.pipelinesource_filter.length > 0 && this.state.pipelinesource_filter.includes(pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]['status']))

                         let obj = {}
                         //obj.pmData = pmData
                         obj.pipeline_type = pmData.pipeline_type;
                         obj.pipelinesourcename = pmData.pipelinesourcename;
                         obj.tenant_id = pmData.tenant_id;
                         obj.is_parent = pmData.pipelinedata[loop]['is_parent'];
                         obj.job_name = pmData.pipelinedata[loop]['job_name'];

                         
                         obj.pipeline_integration_id = pmData.pipelinedata[loop]['pipeline_integration_id'];
                         obj.pipeline_job_id = pmData.pipelinedata[loop]['pipeline_job_id'];
                        // obj.avg_duration  = pmData.pipelinedata[loop].payload.avg_duration_list[filterdate]
                        obj.avg_duration  = pmData.pipelinedata[loop]['avg_duration_list'];
                         if(pmData.pipelinedata[loop].payload.hasOwnProperty('dependent_job') === true ){
                            obj.dependent_job  = pmData.pipelinedata[loop].payload.dependent_job[filterdate]
                         }
                         obj.status = pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]['status'];
                         obj.total_runs    = pmData.pipelinedata[loop].payload[filterdate].length;
                         obj.success_count = pmData.pipelinedata[loop].payload[filterdate].filter(function(item){
                            return item.status === "Succeeded";         
                        }).length
                         obj.failed_count  = pmData.pipelinedata[loop].payload[filterdate].filter(function(item){
                            return item.status === "Failed";         
                        }).length
                         obj.last_run      = pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]["run_end_time"]
                         obj.last_duration = pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]["duration"]
                         obj.runsjob     = pmData.pipelinedata[loop].payload[filterdate]
                         obj.staus     = pmData.pipelinedata[loop].payload[filterdate]
                         
                         obj.successjobs = pmData.successjobs;
                         obj.failedjobs = pmData.failedjobs;
                         obj.totaljobs = pmData.totaljobs;

                         newarray.push(obj)
                    }
                }
            }
        }
        return newarray;
        // if(newarray.length >0){
        //     pmData.pipelinedata = newarray;
        // }
        // console.log("datewisejobsdata")
        // console.log(newarray);
        // console.log("datewisejobsdata")
    }

    pipelineSourceTableData(pmEndDate){

        // let pipelineDate = pmEndDate.format(PIPELINE_DISPLAY_DATE_FORMAT)

        let input_data = this.state.pipelinesource_data;
        // let endDateCheck = pmEndDate.format(PIPELINE_DATE_FORMAT_CHECK);
        // let endDateCheck = pmEndDate.format(PIPELINE_DATE_FORMAT_CHECK);
        // console.log(endDateCheck)

        // let filterdate = moment(this.state.endDate).format('Y-MM-DD');
     
      let headers=["Pipeline Source", "Jobs"];
      let final_op=[];
      let pipelinesource = [];
      let jobs = [];
      for(let i=0; i < input_data.length; i++) {
        // console.log("**************");
        // console.log(input_data);
        // //status
        // //input_data[i].status
        // console.log("**************");
            if(i===0){
                pipelinesource.push({ "label": "All", "value": "All" });
            }
            pipelinesource.push({ "label": input_data[i].pipeline_integration_name, "value": input_data[i].pipeline_integration_id });
            

            if(this.state.filter_pipeline_id === "All" || this.state.filter_pipeline_id === input_data[i].pipeline_integration_id  ){
            input_data[i].pipelinesourcename = input_data[i].pipeline_integration_name
            input_data[i].page_selected_pipeline = this.state.page_selected_pipeline;
            input_data[i].cur_page_selected_pipeline = this.state.cur_page_selected_pipeline;
            input_data[i].pipelinesourcetype = "synapse"
            input_data[i].successjobs = input_data[i].success_count
            input_data[i].failedjobs = input_data[i].failed_count
            input_data[i].totaljobs = input_data[i].total_count
            input_data[i].endDate =  this.state.endDate;
            input_data[i].timefilter =  this.state.timefilter;
            input_data[i].paginationtrack =  this.state.paginationtrack;
            input_data[i].format_jobs_result =  this.datewisejobsdata(input_data[i]);

            let activerunfiltercheck = 1;
            for(let loop = 0; loop<input_data[i].format_jobs_result.length;loop++){
                if(input_data[i].format_jobs_result[loop]['runsjob'].length > 0){
                    activerunfiltercheck = 0;
                }
            }



            if(this.state.activeRunFilter === 1 && activerunfiltercheck ===0 ){
                jobs.push({ "label": input_data[i].pipelinedata[0].payload[input_data[i].pipelinedata[0].payload.time_stamp[0]][0].job_name, "value": input_data[i].pipelinedata[0].payload[input_data[i].pipelinedata[0].payload.time_stamp[0]][0].job_name });
                let row=[];
                let d = input_data[i];
                row.push({"value": d["pipelinesourcename"], "type": "component"});
                row.push({"value": d, "type": "component"});
                final_op.push(row);
            }
            else if(this.state.activeRunFilter === 0) {

                jobs.push({ "label": input_data[i].pipelinedata[0].payload[input_data[i].pipelinedata[0].payload.time_stamp[0]][0].job_name, "value": input_data[i].pipelinedata[0].payload[input_data[i].pipelinedata[0].payload.time_stamp[0]][0].job_name });
                let row=[];
                let d = input_data[i];
                row.push({"value": d["pipelinesourcename"], "type": "component"});
                row.push({"value": d, "type": "component"});
                final_op.push(row);
            }

            }
            }
        let table_data ={"headers": headers, "data": final_op};
        return [table_data,pipelinesource,jobs];
       
    }

    handlePipelineTreeTriger(test, val) {
        $('#' + test + '-nested').toggleClass("is-open");
        $('#toggle_' + test).toggleClass("table-tree--parent");
    }
    filterData(pmValue, source) {

        let allvalues = JSON.parse(localStorage.getItem("piplineinitaldata"))

        // let allvalues = allvalues;
        if (source === 'pipelinedatasource') {
            let filterdat = allvalues;
            let filterdata = [];

            let filterdatlen = filterdat.length;
            for (let loop = 0; loop < filterdatlen; loop++) {
                if (filterdat[loop]["metadata"]["pipeline_name"] === pmValue.value) {
                    filterdata.push(filterdat[loop]);
                }
            }

            let datasets = []
            for (let loop = 0; loop < filterdata[0]['jobs'].length; loop++) {
                datasets.push({ "value": filterdata[0]['jobs'][loop]['job_id'], "label": filterdata[0]['jobs'][loop]['job_name'] });
            }
            this.setState({
                "datasets": datasets,
                "selectedDataSet": [],
                "filterpipelinelist": filterdata,
                "selectedDatasource": pmValue
            });
        }

        if (source === 'pipelinejob') {

            if (pmValue.length > 0) {

                if (pmValue[0].label === "All") {

                    let filterdat = allvalues;
                    let filterdata = [];
                    let filterdatlen = filterdat.length;
                    for (let loop = 0; loop < filterdatlen; loop++) {
                        if (filterdat[loop]["metadata"]["pipeline_name"] === this.state.selectedDatasource.value) {
                            filterdata.push(filterdat[loop]);
                        }
                    }
                    let datasets = []
                    for (let loop = 0; loop < filterdata[0]['jobs'].length; loop++) {
                        datasets.push({ "value": filterdata[0]['jobs'][loop]['job_id'], "label": filterdata[0]['jobs'][loop]['job_name'] });
                    }
                    this.setState({
                        "datasets": datasets,
                        "filterpipelinelist": filterdata,
                    });

                } else {

                    let filterdat = allvalues;
                    let filterdata = [];
                    let filterdatlen = allvalues.length;

                    // console.log(filterdatlen);
                    // console.log(filterdat);
                    // console.log("--------------------");

                    for (let loop = 0; loop < filterdatlen; loop++) {
                        if (allvalues[loop]["metadata"]["pipeline_name"] === this.state.selectedDatasource.value) {
                            filterdata.push(allvalues[loop]);
                        }
                    }

                    let joblist = [];
                    for (let loop = 0; loop < pmValue.length; loop++) {
                        joblist.push(pmValue[loop]['value']);
                    }

                    let joblistfilter = [];
                    for (let loop = 0; loop < filterdata[0]['jobs'].length; loop++) {
                        let index = joblist.indexOf(filterdata[0]['jobs'][loop]['job_id']);
                        if (index > -1) {
                            joblistfilter.push(filterdata[0]['jobs'][loop])
                        }
                    }



                    //console.log("========");
                    filterdata[0]['jobs'] = joblistfilter;
                    //console.log(filterdata);
                    //console.log(allvalues)
                    //console.log("========");

                    this.setState({
                        "datasets": this.state.datasets,
                        "selectedDataSet": pmValue,
                        "filterpipelinelist": filterdata,
                    });

                }
            } else {

                let filterdat = allvalues;
                let filterdata = [];
                let filterdatlen = filterdat.length;
                for (let loop = 0; loop < filterdatlen; loop++) {
                    if (filterdat[loop]["metadata"]["pipeline_name"] === this.state.selectedDatasource.value) {
                        filterdata.push(filterdat[loop]);
                    }
                }
                let datasets = []
                for (let loop = 0; loop < filterdata[0]['jobs'].length; loop++) {
                    datasets.push({ "value": filterdata[0]['jobs'][loop]['job_id'], "label": filterdata[0]['jobs'][loop]['job_name'] });
                }
                this.setState({
                    "datasets": datasets,
                    "selectedDataSet": [],
                    "filterpipelinelist": filterdata
                });
            }
        }

    }
    componentDidMount() {
        this.getData();
        //   setTimeout(
        //     () => this.applyTimeFilter(12), 
        //     1000
        //   );
        var localadditionalInfoFromParam = localStorage.getItem("additionalInfoFromParam");
        if (localadditionalInfoFromParam !== "") {
            const obj = JSON.parse(localadditionalInfoFromParam);
            this.changeDataSource(obj.datasource);
            this.changeDataset(obj.dataset);
            if (obj.attribute.length > 0) {
                this.setState({ "selectedAttributes": obj.attribute, "showDropDown": true });
            } else {
                this.setState({ "showDropDown": true });
            }
            setTimeout(function () { document.getElementById("btnadvancedfilter").click(); }, 50);
            localStorage.setItem("additionalInfoFromParam", "");
        }

        let latestdate = this.props.dataModule.lastProfilingTime;
        if (latestdate !== null && latestdate !== undefined) {
            let maxDate, minDate, str, date = [];
            //console.log(maxDate)
            latestdate = Object.keys(latestdate).forEach(function (key, index) {
                let lastProfiledDate = latestdate[key];
                if (lastProfiledDate === null) {
                    // Here 'return' is equivalent to 
                    // 'continue' in conventional for loop
                    return;
                }

                str = lastProfiledDate.substring(0, lastProfiledDate.length - 13);
                date.push(moment(str));
            });

            if (date.length > 0) {
                maxDate = moment.max(date).set({ "hour": 23, "minute": 59, "seconds": 59 })
                minDate = moment.min(date)
                if (minDate !== "" && minDate !== "") {
                    // this.setState({
                    //     startDate: minDate,
                    //     endDate: maxDate,
                    // });
                }
            }

        }

    }
    setFullScreen(pm, jobid,jobslist,newarray) {
        // this.setState({
        //     isInFullScreenMode: true,
        //     detailJobId: jobid,
        //     detailPipelineName: pm,
        //     detailJobList:jobslist
        // });
        // this.getApi("get_pipeline_run_details");

        // alert(jobid);
      //  alert(jobid)
        this.setState({
                isInFullScreenMode: true,
                detailJobId: jobid,
                detailPipelineName: pm,
                detailJobList:jobslist,
                selectedPipelineInfo:newarray
        },() => {
            this.getApi("get_pipeline_run_details");
        });

    }

    changeDataset(selectedDataSet) {

        this.setState({ selectedDataSet: selectedDataSet });
        this.filterData(selectedDataSet, 'pipelinejob');
    }

    changeGroup(selectedOption) {

        this.setState({
            selectedGroup: selectedOption
        });
    }

    changeDataSource(selectedDataSource) {
        this.setState({
            "selectedDatasource": selectedDataSource,
            filter_pipeline_id:selectedDataSource.value
        });
    }


    getFilteredData(data, startDate, endDate, considerEndAlone = false) {
        let updatedThumbnailCharts = []
        for (let _singleThumbnailData of data) {
            const actChartData = _.cloneDeep(_singleThumbnailData.chartData);
            let timeArr = actChartData.time;
            let valArr = actChartData.values;
            let driftPatterns = actChartData.drift_patterns;
            let versionNames = actChartData.version_name;
            let newValues = []
            let newDrift = []
            let newVersion = []
            if (timeArr === undefined) {
                continue;
            }
            let filteredArr
            if (considerEndAlone) {
                filteredArr = timeArr.filter(x => moment(x).isSameOrBefore(endDate));
            } else {
                filteredArr = timeArr.filter(x => moment(x).isSameOrBefore(endDate) && moment(x).isSameOrAfter(startDate));
            }

            const filteredLength = filteredArr.length;
            if (filteredLength === 0) {
                continue;
            }
            let startIndex = timeArr.indexOf(filteredArr[0]);
            let endIndex = timeArr.indexOf(filteredArr[filteredLength - 1]);
            for (let i = startIndex; i <= endIndex; i++) {
                newValues.push(valArr[i])
                if (versionNames !== undefined && versionNames !== null) {
                    newVersion.push(versionNames[i])
                }
                if (driftPatterns !== undefined) {
                    newDrift.push(driftPatterns[i])
                }
            }
            if (filteredLength === 1) {
                newValues.push(valArr[startIndex])
                if (versionNames !== undefined && versionNames !== null) {
                    newVersion.push(versionNames[startIndex])
                }
                if (driftPatterns !== undefined) {
                    newDrift.push(driftPatterns[startIndex])
                }
            }
            actChartData.time = filteredArr;
            actChartData.dq_chart_points = newValues;
            actChartData.values = newValues;
            actChartData.drift_patterns = newDrift;
            actChartData.version_name = newVersion;
            _singleThumbnailData.chartData = actChartData;
            updatedThumbnailCharts.push(_singleThumbnailData);
        }
        return updatedThumbnailCharts

    }

   // handleDQListViewTimeFilter(event, picker) {
    handleDQListViewTimeFilter(event,picker) {
        // console.log(picker)
        // console.log(event)
        // console.log(picker.startDate)
        // console.log(picker.endDate)
        // return;
        let startDate = moment(picker.startDate);
        let endDate = moment(picker.endDate);
        let filteredData = [];
        let dataFilteredValues = [];
        this.setState({
            startDate: startDate,
            endDate: endDate,
            dataFilteredValues: dataFilteredValues,
            timeFilterApplied: true,
            currListViewTableData: filteredData
        });


        $("#lastSechduleDate").html(endDate.format(PIPELINE_DISPLAY_DATE_FORMAT))
    }

    shouldComponentUpdate(nextProps, nextState) {
        //return this.state.endDate != nextState.endDate;
        // alert(1);
        return true;
      }

    // shouldComponentUpdate(nextProps) {
        
    //     alert(nextProps.endDate);
    //     alert(this.props.endDate);

    //     if (nextProps.endDate !== this.props.endDate) {
    //         return true;
    //      } else {
    //         return false;
    //      }


    //     // if (prevState.endDate !== this.state.endDate) {
    //     //     this.render();            
    //     // }
    //     // if (prevState.timefilter !== this.state.timefilter) {
    //     //     alert(1)
    //     // }
    //   }

    getDQListTableComponents(actionType, headerValue, cell) {
        if (headerValue === "Dataset Name") {
            if (cell.value === undefined) {
                return ""
            }
            return (<><b>{cell.value}</b>
                {cell.previousTime !== undefined && cell.previousTime !== null ? <OverlayTrigger placement="top"
                    tooltipInfo={"Refresh Date"}
                    overlay={
                        <Tooltip id={`tooltipId`}>
                            {cell.previousTime !== undefined && cell.previousTime !== null ? `Previous Refresh Date: ${cell.previousTime}` : ""}<br />
                        </Tooltip>
                    }>
                    <i className={"d-inline-block p-1 text-danger"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </i>
                </OverlayTrigger> : ""}
                <p className="text-muted mb-0 mt-2">Latest Refresh Date: </p>
                {cell.lastProfilingTime}<br /></>)
        }
        if (headerValue === "DQ Metrics with Issues") {
            if (cell.value.length > 0) {
                return (<ThumbnailChartList listData={cell.value}
                    key={"dqErrorsListView"}
                    isErrorChart={true}
                    noDataAvailable={cell.noDataAvailable}
                >
                </ThumbnailChartList>)
            } else {
                return (<div className="tbl-placeholder">
                    <h2>
                        <FontAwesomeIcon icon={faChartArea} />
                    </h2>
                    <h4>{cell.noDataAvailable === true ? "No Charts Found" : "No Errors Found"}</h4>
                </div>)
            }

        } else if (headerValue === "Action") {

            if (cell.level === "attribute") {
                let follow_attributes = this.props.monitorModule.follow_attributes
                let attributId = cell['attributeId'];
                let datasourceId = cell['dataSourceId'];
                let updatedFollowAttri = follow_attributes[datasourceId][attributId]
                cell.follow_status = updatedFollowAttri;
            }


            return (
                <div key={cell['level']} className="d-flex align-items-center w-100 h-100 justify-content-center">

                    {cell.level === "attribute" ?

                        <FollowComponent
                            follow_attribute_details={cell}
                            follow_attributes={this.props.monitorModule.follow_attributes}
                            addFollowAttributes={this.props.addFollowAttributes}
                        />
                        : ""
                    }
                    <div className="show-more monitor-row_show-more"
                        onClick={this.setFullScreen.bind(this, cell)}>
                        <span className="icon">
                            <FontAwesomeIcon icon={faArrowRight} />
                        </span>
                        <p>Detailed Charts</p>
                    </div>
                </div>
            );
        }


        return cell;
    }





    getCustomClassForActions() {
        /**
         * This function returns a "function" which will provide the class for <td> entries
         * i.e the cells of the tables
         * */

        return (cell, row, rowIndex, colIndex) => {
            if (colIndex === 5) {
                return "align-middle";
            }

            return "";
        };
    }


    closeFullScreenMode() {
        //    document.body.classList.remove("overflow-hidden");
        this.setState({
            isInFullScreenMode: false
            // fullScreenClassKey: "",
            // fullScreenAttributeData: null,
            // lastProfilingTime: null,
            // fullScreenData: null
        });
    }


    reset() {

        let allvalues = JSON.parse(localStorage.getItem("piplineinitaldata"))

        this.setState({
            filterpipelinelist: allvalues,
            selectedDatasource: [],
            selectedDataSet: [],
            selectedAttributes: [],
            initial: {
                datasource: "",
                dataset: "",
                attribute: "",
            }
        });
    }

    statusColor(status){
        let color;
        if (status === "Succeeded") {
            color = "#4ED2CC";
        }
        if (status === "Failed") {
            color = "#F64E60";
        }
        if (status === "Cancelled") {
            color = "#F64E60";
        }
        return color;
    }
    showGraph1(pmstatus,status){

//Time Calculation
let TIME_SLOT = []
let time = this.state.timefilter;
let timelen;
if(parseInt(time) === 12 || parseInt(time) === 24){
    timelen = 13;
}
else {
    timelen = 7;
}

let curdate = this.state.initialendDate === this.state.endDate ? moment() : this.state.endDate
let timeserieshrs = parseInt(time)+1;

let timeseriesmins = moment(curdate)
.subtract({'hours':time})
.format('mm');

for(let loop=1; loop<timelen;loop++){
if(parseInt(time) ===12 || parseInt(time) === 24 ||  parseInt(time) === 6){

    if(parseInt(time) ===12 || parseInt(time) === 6){

        TIME_SLOT.push(moment(curdate)
        .subtract({'hours':timeserieshrs})
        .format('hh'))
        timeserieshrs = parseInt(timeserieshrs)-1
    }else {
        if(loop === 12){

            TIME_SLOT.push(moment(curdate)
        .subtract({'hours':timeserieshrs})
        .format('hh'))

        timeserieshrs = parseInt(timeserieshrs)-1
        }else {

        TIME_SLOT.push(moment(curdate)
        .subtract({'hours':timeserieshrs})
        .format('hh'))

        timeserieshrs = parseInt(timeserieshrs)-2
        }
    }
} else {
    TIME_SLOT.push(moment(curdate)
        .subtract({'hours':timeserieshrs})
        .format('hh'))

    timeseriesmins = parseInt(timeseriesmins)+parseInt(10)
}
}


// console.log("Time Calculation")
// console.log(TIME_SLOT);


        //End Time Calculation


        let runtimehours   = [];
        let runtimemins    = [];
        let runstatus = [];
        for(let loop=0;loop<pmstatus.runsjob.length;loop++){
            let datesplit  = pmstatus.runsjob[loop]["run_started_time"].split("T");
            let time = String(datesplit[1]).split(":")
            let hours = time[0];
            let mins  = time[1];
            runtimehours.push(hours);
            runtimemins.push(mins);
            runstatus.push(pmstatus.runsjob[loop]["status"]);
            // status
        }

        // console.log("%%%%%%%%%%%%%%%%%%%%%")
        // console.log(runtimehours);
        // console.log("%%%%%%%%%%%%%%%%%%%%%")

        let chartcolor;
        // let color;
        let width = 5;
        let start=[1],end=[1];

        return <div class="tbl-time-range__content">
                <div class="tbl-time-range__cols">
                    <div class="tbl-time-range__col">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Previous Run Date: <strong className="font-weight-bold text-nowrap">10 Dec 2023 | 20:17:48</strong>
                                </Tooltip>
                            }
                        >
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="4" fill="#F9FBFD" />
                                <g clip-path="url(#clip0_923_34)">
                                    <path d="M8 13H12V11H8V8L4 12L8 16V13ZM15 13C15.55 13 16 12.55 16 12C16 11.45 15.55 11 15 11C14.45 11 14 11.45 14 12C14 12.55 14.45 13 15 13Z" fill="currentColor" />
                                    <path d="M18 4H20C20.5523 4 21 4.44772 21 5V19C21 19.5523 20.5523 20 20 20H18V4Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_923_34">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </OverlayTrigger>
                    </div>
                    <div class="tbl-time-range__col">
                        {
                            start[0] >= 4 && end[0] < 7 ?


                                <OverlayTrigger
                                    trigger="click"
                                    placement="top"
                                    overlay={
                                        <Popover className="pipeline-popover">
                                            <Popover.Title as="h3">{"estimate_blockage_process"}</Popover.Title>
                                            <Popover.Content>
                                                <div className="job-details">
                                                    <p>
                                                        <span><label>Run ID:</label><a href="/">4521710e</a></span>
                                                        <span className="job-details__action">
                                                            <span className="badge badge-light-success mr-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                                        Success
                                                                                                    </span>
                                                            <span className="job-runtime">
                                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20z" /></g></g></g></svg>
                                                                                                        0:01:13
                                                                                                    </span>
                                                        </span>
                                                    </p>
                                                    <p><span><label>Started:</label>Tue, 19 Dec 2022 07:30:08 GMT</span></p>
                                                    <p><span><label>End:</label>Tue, 19 Dec 2022 07:30:08 GMT</span></p>
                                                </div>
                                                <div className="job-details">
                                                    <p>
                                                        <span><label>Run ID:</label><a href="/">4521710e</a></span>
                                                        <span className="job-details__action">
                                                            <span className="badge badge-light-danger mr-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                                        Failed
                                                                                                    </span>
                                                            <span className="job-runtime">
                                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20z" /></g></g></g></svg>
                                                                                                        0:00:10
                                                                                                    </span>
                                                        </span>
                                                    </p>
                                                    <p><span><label>Started:</label>Tue, 19 Dec 2022 07:30:08 GMT</span></p>
                                                    <p><span><label>End:</label>Tue, 19 Dec 2022 07:30:08 GMT</span></p>
                                                    <p><span><label>Troubleshooting and Diagnosis:</label><a href="/">Check here</a></span></p>
                                                </div>
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <span style={{ width: "4px", height: "16px", backgroundColor: "#4ED2CC" }}></span>
                                </OverlayTrigger>
                                : ""
                        }

                    </div>
                    {
                    TIME_SLOT.map((person, i) => {
                    chartcolor = this.statusColor(runstatus[runtimehours.indexOf(person)])
                    return(<div class="tbl-time-range__col">
{
runtimehours.indexOf(person) > -1 ?
<span style={{ width: width + "px", height: "16px", backgroundColor: chartcolor }}></span> : ""
 }
                    </div>)
                    })
                }
                    {/* {
                    (parseInt(this.state.timefilter) >= 12) ?
                    TIMEHOURS_12.map((person, i) => {
                    chartcolor = this.statusColor(runstatus[runtimehours.indexOf(person)])
                    return(<div class="tbl-time-range__col">
{
runtimehours.indexOf(person) > -1 ?
<span style={{ width: width + "px", height: "16px", backgroundColor: chartcolor }}></span> : ""
 }
                    </div>)
                    })
                    :
                    TIMEHOURS_6.map((person, i) => {
                        {console.log("person"+person)}
                        chartcolor = this.statusColor(runstatus[runtimehours.indexOf(person)])
                        return(<div class="tbl-time-range__col">
                        {
runtimehours.indexOf(person) > -1 ?
<span style={{ width: width + "px", height: "16px", backgroundColor: chartcolor }}></span> : ""
 }
                        </div>)
                        })
                    } */}
                    <div class="tbl-time-range__col">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Next Run Date: <strong className="font-weight-bold text-nowrap">13 Dec 2023 20:12:20</strong>
                                </Tooltip>
                            }
                        >
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="4" transform="matrix(-1 0 0 1 24 0)" fill="#F9FBFD" />
                                <g clip-path="url(#clip0_923_40)">
                                    <path d="M16 13H12V11H16V8L20 12L16 16V13ZM9 13C8.45 13 8 12.55 8 12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12C10 12.55 9.55 13 9 13Z" fill="currentColor" />
                                    <path d="M6 4H4C3.44772 4 3 4.44772 3 5V19C3 19.5523 3.44772 20 4 20H6V4Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_923_40">
                                        <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </OverlayTrigger>
                    </div>
                </div>
                {

                    this.state.endDate.format(PIPELINE_DISPLAY_DATE_FORMAT)
                    === this.state.initialendDate.format(PIPELINE_DISPLAY_DATE_FORMAT) ?
                <div class="tbl-time-range__now">
                </div> : ""
            }
            </div>

    }

    showGraph(pmStatus,status) {
        if (pmStatus) {
            let start = String(pmStatus[0].start);
            start = start.split(":");
            let end = String(pmStatus[0].end);
            end = end.split(":");
            let color;
            let width = parseInt(end[1]) - parseInt(start[1]);
            if (status === "Succeeded") {
                color = "#4ED2CC";
            }
            if (status === "Failed") {
                color = "#F64E60";
            }
            if (status === "Cancelled") {
                color = "#F64E60";
            }
            
            return <div class="tbl-time-range__content">
                <div class="tbl-time-range__cols">
                    <div class="tbl-time-range__col">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Previous Run Date: <strong className="font-weight-bold text-nowrap">10 Dec 2023 | 20:17:48</strong>
                                </Tooltip>
                            }
                        >
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="4" fill="#F9FBFD" />
                                <g clip-path="url(#clip0_923_34)">
                                    <path d="M8 13H12V11H8V8L4 12L8 16V13ZM15 13C15.55 13 16 12.55 16 12C16 11.45 15.55 11 15 11C14.45 11 14 11.45 14 12C14 12.55 14.45 13 15 13Z" fill="currentColor" />
                                    <path d="M18 4H20C20.5523 4 21 4.44772 21 5V19C21 19.5523 20.5523 20 20 20H18V4Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_923_34">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </OverlayTrigger>
                    </div>
                    <div class="tbl-time-range__col">
                        {
                            start[0] >= 4 && end[0] < 7 ?


                                <OverlayTrigger
                                    trigger="click"
                                    placement="top"
                                    overlay={
                                        <Popover className="pipeline-popover">
                                            <Popover.Title as="h3">{"estimate_blockage_process"}</Popover.Title>
                                            <Popover.Content>
                                                <div className="job-details">
                                                    <p>
                                                        <span><label>Run ID:</label><a href="/">4521710e</a></span>
                                                        <span className="job-details__action">
                                                            <span className="badge badge-light-success mr-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                                        Success
                                                                                                    </span>
                                                            <span className="job-runtime">
                                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20z" /></g></g></g></svg>
                                                                                                        0:01:13
                                                                                                    </span>
                                                        </span>
                                                    </p>
                                                    <p><span><label>Started:</label>Tue, 19 Dec 2022 07:30:08 GMT</span></p>
                                                    <p><span><label>End:</label>Tue, 19 Dec 2022 07:30:08 GMT</span></p>
                                                </div>
                                                <div className="job-details">
                                                    <p>
                                                        <span><label>Run ID:</label><a href="/">4521710e</a></span>
                                                        <span className="job-details__action">
                                                            <span className="badge badge-light-danger mr-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                                        Failed
                                                                                                    </span>
                                                            <span className="job-runtime">
                                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20z" /></g></g></g></svg>
                                                                                                        0:00:10
                                                                                                    </span>
                                                        </span>
                                                    </p>
                                                    <p><span><label>Started:</label>Tue, 19 Dec 2022 07:30:08 GMT</span></p>
                                                    <p><span><label>End:</label>Tue, 19 Dec 2022 07:30:08 GMT</span></p>
                                                    <p><span><label>Troubleshooting and Diagnosis:</label><a href="/">Check here</a></span></p>
                                                </div>
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <span style={{ width: "4px", height: "16px", backgroundColor: "#4ED2CC" }}></span>
                                </OverlayTrigger>
                                : ""
                        }

                    </div>
                    {
                    (parseInt(this.state.timefilter) >= 12) ?  
                    TIMEHOURS_12.map((person, i) => {
                    return(<div class="tbl-time-range__col">
                        {   
                            (Math.floor(Math.random() * (12 - 1 + 1)) + 1) % 2 === 0 ?
                            <span style={{ width: width + "px", height: "16px", backgroundColor: color }}></span> : ""
                        }
                    </div>)
                    })
                    :
                    TIMEHOURS_6.map((person, i) => {
                        return(<div class="tbl-time-range__col">
                            {
                            (Math.floor(Math.random() * (6 - 1 + 1)) + 1) % 2 === 0 ?
                                <span style={{ width: width + "px", height: "16px", backgroundColor: color }}></span> : ""
                            }
                        </div>)
                        })
                    }
                    <div class="tbl-time-range__col">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Next Run Date: <strong className="font-weight-bold text-nowrap">13 Dec 2023 20:12:20</strong>
                                </Tooltip>
                            }
                        >
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="4" transform="matrix(-1 0 0 1 24 0)" fill="#F9FBFD" />
                                <g clip-path="url(#clip0_923_40)">
                                    <path d="M16 13H12V11H16V8L20 12L16 16V13ZM9 13C8.45 13 8 12.55 8 12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12C10 12.55 9.55 13 9 13Z" fill="currentColor" />
                                    <path d="M6 4H4C3.44772 4 3 4.44772 3 5V19C3 19.5523 3.44772 20 4 20H6V4Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_923_40">
                                        <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </OverlayTrigger>
                    </div>
                </div>
                {
                   
                    this.state.endDate.format(PIPELINE_DISPLAY_DATE_FORMAT)
                    === this.state.initialendDate.format(PIPELINE_DISPLAY_DATE_FORMAT) ?
                <div class="tbl-time-range__now">
                </div> : ""
            }   
            </div>
        }
    }

    statusIcon(pmStatus) {
        if (pmStatus === "Cancelled") {
            return <div className="d-flex align-items-center text-orange">
                        <svg width="16" height="16" className="mr-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.1 1.7V4.703C10.1 4.801 10.065 4.885 10.002 4.948L8.252 6.698C8.112 6.838 7.895 6.838 7.755 6.698L6.005 4.948C5.935 4.885 5.9 4.794 5.9 4.703V1.7C5.9 1.315 6.215 1 6.6 1H9.4C9.785 1 10.1 1.315 10.1 1.7ZM1.7 5.9H4.703C4.801 5.9 4.885 5.935 4.948 5.998L6.698 7.748C6.838 7.888 6.838 8.105 6.698 8.245L4.948 9.995C4.885 10.065 4.794 10.1 4.703 10.1H1.7C1.315 10.1 1 9.785 1 9.4V6.6C1 6.215 1.315 5.9 1.7 5.9ZM5.9 14.3V11.297C5.9 11.199 5.935 11.115 5.998 11.052L7.748 9.302C7.888 9.162 8.105 9.162 8.245 9.302L9.5534 10.6104C9.20316 11.1554 9 11.804 9 12.5C9 13.3871 9.33003 14.1971 9.874 14.814C9.74907 14.9293 9.58239 15 9.4 15H6.6C6.215 15 5.9 14.685 5.9 14.3ZM14.8175 9.87713C14.9307 9.75264 15 9.58752 15 9.407V6.607C15 6.222 14.685 5.907 14.3 5.907H11.297C11.199 5.9 11.115 5.935 11.045 6.005L9.295 7.755C9.155 7.895 9.155 8.112 9.295 8.252L10.6019 9.55889C11.1487 9.20528 11.8004 9 12.5 9C13.3888 9 14.2002 9.33129 14.8175 9.87713Z" fill="currentColor"></path><path d="M11.72 13.9867L12.6533 13.0533L13.5867 13.9867L13.9867 13.5867L13.0667 12.6667L14 11.7333L13.6 11.3333L12.6667 12.2667L11.7333 11.3333L11.3333 11.7333L12.2667 12.6667L11.3333 13.6L11.72 13.9867ZM12.6667 15.3333C11.9289 15.3333 11.3 15.0733 10.78 14.5533C10.26 14.0333 10 13.4044 10 12.6667C10 11.9289 10.26 11.3 10.78 10.78C11.3 10.26 11.9289 10 12.6667 10C13.4044 10 14.0333 10.26 14.5533 10.78C15.0733 11.3 15.3333 11.9289 15.3333 12.6667C15.3333 13.4044 15.0733 14.0333 14.5533 14.5533C14.0333 15.0733 13.4044 15.3333 12.6667 15.3333Z" fill="currentColor"></path></svg>Cancelled
                    </div>
        }
        if (pmStatus === "Succeeded") {
            return <div className="d-flex align-items-center text-success">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" className="mr-1" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                Done
            </div>
        }
        else if (pmStatus === "Failed") {
            return <div className="d-flex align-items-center text-danger">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" className="mr-1" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" /></svg>
                Failed
            </div>
        }
        else if (pmStatus === "Schedules" || pmStatus === "Scheduled") {
            return <div className="d-flex align-items-center text-purple">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" className="mr-1" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z" /></g></g></g></svg>
                Scheduled
            </div>
        }
        else if (pmStatus === "In Progre") {
            return <div className="d-flex align-items-center text-info">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" className="mr-1" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5z" /></g></svg>
                In Progress
            </div>
        }
        else if (pmStatus === "Queued") {
            return <div className="d-flex align-items-center text-warning">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" className="mr-1" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" /></svg>
                Queued
            </div>
        }
    }

    innerpage(event){
        this.setState({cur_page_selected_pipeline:event.target.attributes.getNamedItem('data-page-id').value})
    }

    paging(pm, pmid) {
          if(parseInt(pmid.target.attributes.getNamedItem('data-view').value)===0){
            this.setState({page_selected_pipeline:pmid.target.attributes.getNamedItem('data-pipelineindex').value})
         }else{
            this.setState({page_selected_pipeline:''})
         }
    }

    render() {
        
        //alert(this.state.paginationtrack)
        let tableData = '';
        let dataSourceOption = [];
        // let jobsOption = [];
        if(this.state.pipelinesource_data !== "-1" &&  this.state.pipelinesource_data !== "No Integeration Found") {
            let ppl_source = this.pipelineSourceTableData(this.state.endDate);
            tableData = ppl_source[0];
            // alert(tableData);
            dataSourceOption = ppl_source[1];
            // jobsOption = ppl_source[2];
        }

        setTimeout(
            () => {
                $("#paging").val("20");
            },
            3000
        );

        

        // let ranges = getDefaultDateRangeOptions();
        let dqSchema = yup.object({
            datasource: yup.string().required("Pipeline Source is a required field"),
            dataset: yup.string().required("Jobs is a required field"),
        });

        // let noDataFoundMessage = null;
       // let lastProfilingTimeDict = this.props.dataModule.lastProfilingTime;
        // let lastProfiledTime = lastProfilingTimeDict[this.state.selectedDataSourceID];
        // let containsNull = Object.values(lastProfilingTimeDict).filter(x => x !== null)
        // // if (containsNull.length === 0) {
        //     noDataFoundMessage = `Data profiling is in-progress`;
        // } else if (this.state.selectedDataSourceID && IS_NONE_CHECK.includes(lastProfiledTime) && tableData.data.length === 0) {
        //     noDataFoundMessage = `Data profiling is in-progress`;
        // }
        // alert(this.state.endDate)

        let allvalues = JSON.parse(localStorage.getItem("piplineinitaldata"))
      //  let tableData = {};
        return (
            <>



                {this.state.isInFullScreenMode === true ?
                    <PipelinePerformFullScreen
                        selectedJobDetails ={this.state.selectedJobDetails}
                        selectedPipelineInfo ={this.state.selectedPipelineInfo}
                        detailJobList={this.state.detailJobList}
                        closeFullScreenMode={this.closeFullScreenMode}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        ranges={this.state.ranges}
                        detailJobId={this.state.detailJobId}
                        detailPipelineName={this.state.detailPipelineName}
                        pipelinelist={allvalues}
                        filterpipelinelist={allvalues}
                        dataSourceOption={dataSourceOption}
                    /> : ""
                }

                {
                    tableData !== null ?
                        <div className="portlet-body pb-0">
                            <div className="qd-tab__content-action">
                                <div className="caption">
                                    <div className="alert moniker-alert" role="alert">
                                        <p className="mb-0">
                                            --
                                {/* <strong> {this.state.lastProfilingTimeData === null && this.state.lastProfilingTimeData !== undefined ? "--" : `Your data last profiled at ${this.state.lastProfilingTimeData}`}</strong> */}
                                        </p>
                                    </div>
                                </div>

                                <div className="actions d-flex">
                                <div className="action-left">
                                    <Select
                                        name="datasource"
                                        filterOption={({ label }, query) => label.includes(query)}
                                        defaultValue={this.state.selectedDatasource}
                                        id="chooseDatasourceDqError"
                                        options={dataSourceOption}
                                        onChange={this.changeDataSource}
                                        value={this.state.selectedDatasource}
                                        classNamePrefix='form-control'
                                        placeholder="Choose Pipeline Source"
                                    />
                                </div>
                                    {/* <CustomSelect
                                        isMulti
                                        isClearable={false}
                                        name="dataset"
                                        filterOption={({ label }, query) => label.includes(query)}
                                        id="chooseDatasetError"
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{ Option }}
                                        className="mr-2"
                                        allowSelectAll={true}
                                        value={this.state.selectedDataSet}
                                        options={this.state.datasets}
                                        onChange={this.changeDataset}
                                        controlShouldRenderValue={false}
                                        classNamePrefix='form-control'
                                        placeholder={getPlaceholder("Choose Jobs", this.state.selectedDataSet, this.state.datasets)}
                                    /> */}
                                    <div className="action-right">
                                    <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                        singleDatePicker={true}
                                        showDropdowns={true}
                                        onApply={this.handleDQListViewTimeFilter}
                                        >
                                        <i>
                                            <FontAwesomeIcon icon={faCalendar} />
                                        </i>
                                        <span
                                            className="ml-2 d-none d-md-inline-block">
                                           {this.state.endDate.format(DISPLAY_DATE_FORMAT)}
                                        </span>
                                    </DateRangePicker>
                                    <Formik
                                        enableReinitialize
                                        validationSchema={dqSchema}
                                        onSubmit={this.handleSubmit}
                                        initialValues={this.state.initial}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            setFieldValue,
                                            setValues,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setErrors,
                                            isValid,
                                        }) => (<><Dropdown className="qd_filter-btn d-inline" alignRight show={this.state.showDropDown}>
                                            {/* <Dropdown.Toggle onClick={() => { this.state.showDropDown === true ? this.setState({ showDropDown: false }) : this.setState({ showDropDown: true }) }} id="dropdown-autoclose-false" className="btn-light">
                                                <Image
                                                    src="icon_filter"
                                                />
                                            </Dropdown.Toggle> */}
                                            <Dropdown.Menu className="dropdown-menu-right">
                                                <Form noValidate name="signup"
                                                    className="login-form needs-validation"
                                                    onSubmit={handleSubmit}>
                                                    <div className="qd_filter__body">
                                                        <h4 className="filter_title">Select Date</h4>
                                                        <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                                            //startDate={this.state.startDate}
                                                            singleDatePicker={true}
                                                            onApply={this.handleTime}
                                                            //endDate={this.state.endDate}
                                                            //key={`date_${this.state.keyData}`}
                                                            >
                                                            <i>
                                                                <FontAwesomeIcon icon={faCalendar} />
                                                            </i>
                                                            <span
                                                                className="ml-2 d-none d-md-inline-block">
                                                                {this.state.endDate.format(DISPLAY_DATE_FORMAT)}
                                                            </span>
                                                        </DateRangePicker>
                                                        <Form.Group >
                                                            <Form.Label>Pipeline Source</Form.Label>
                                                            <Select
                                                                name="datasource"
                                                                id="datasourceNewFilter"
                                                                placeholder="Choose Pipeline Source"
                                                                defaultValue={this.state.selectedDatasource}
                                                                options={dataSourceOption}
                                                                isInvalid={errors.datasource && touched.datasource}
                                                                onChange={selectedOption => {
                                                                    handleChange("datasource")(selectedOption.value.toString());
                                                                    // this.filterData(selectedOption.value.toString(),'pipelinedatasource')
                                                                    this.changeDataSourceAdvanced(selectedOption);
                                                                    //setFieldValue("dataset", "")
                                                                }}
                                                                classNamePrefix='select-control'
                                                                value={this.state.selectedDatasource}
                                                            />
                                                            <ErrorMessage component="div" className="error-text" name="datasource" />
                                                        </Form.Group>
                                                        {/* <Form.Group >
                                                            <Form.Label>Jobs</Form.Label>
                                                            <CustomSelect
                                                                isMulti
                                                                isClearable={false}
                                                                name="dataset"
                                                                id="datasetNewFilter"
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                isInvalid={errors.dataset && touched.dataset}
                                                                components={{ Option }}
                                                                classNamePrefix='select-control'
                                                                options={jobsOption}
                                                                onChange={selectedOption => {
                                                                    if (selectedOption !== null && selectedOption.length !== 0) {
                                                                        handleChange("dataset")(selectedOption[0].value.toString());
                                                                    } else {
                                                                        handleChange("dataset")("");
                                                                    }
                                                                    this.handleChangeDataset(selectedOption);
                                                                }}
                                                                allowSelectAll={true}
                                                                placeholder={getPlaceholder("Choose Dataset", this.state.selectedDataSet, this.state.datasets)}
                                                                value={this.state.selectedDataSet}
                                                                controlShouldRenderValue={false}
                                                            />
                                                            <ErrorMessage component="div" className="error-text" name="dataset" />
                                                        </Form.Group> */}
                                                    </div>
                                                    <div className="d-flex justify-content-between border-top p-3 my-1">
                                                        <button type="button" className="btn btn-link" onClick={() => {
                                                            this.reset();
                                                            setTimeout(() => {
                                                                setValues(this.state.initial);
                                                                setTimeout(() => {
                                                                    setErrors({})
                                                                }, 1);
                                                            }, 500);
                                                        }}>Reset</button>
                                                        <div>
                                                            <Button className="btn-outline btn-grey btn-circle mr-2" onClick={() => { this.setState({ showDropDown: false }) }}>Cancel</Button>
                                                            <Button id="btnadvancedfilter" type="submit" className="btn-primary btn-circle"><FontAwesomeIcon icon={faCheck} /> Apply</Button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        </>
                                        )}
                                    </Formik>
                                    </div>
                                </div>
                            </div>

                            {/* <div>
                   HTML COntent
               </div> */}


                            <>

                                {/* <Gantt tasks={tasks} /> */}


                                <div className="portlet">
                                    <div className="portlet-title">
                                        <div className="caption">
                                            <span className="text-uppercase font-dark">Pipeline Performance</span>
                                        </div>
                                    </div>
                            <div className="portlet-body pb-0">
                            {
                                tableData!=="" ?
                            <QualdoDataTable
                            sizePerPage={6}
                            applyTablewrapperClasses = {this.applyTablewrapperClasses}
                            initialendDate={this.state.initialendDate}
                            pipelinesourceFilterSelection={this.pipelinesourceFilterSelection}
                            id="monitorPipelineListView"
                            data={tableData}
                            paginationcurpage={this.state.paginationtrack}
                            lastSechduleDate= {this.state.endDate.format(PIPELINE_DISPLAY_DATE_FORMAT)}
                            timefilter={this.state.timefilter}
                            applyTimeFilter={this.applyTimeFilter}
                            timeFilterActive={this.state.timefilter}
                            component_name="pipelineListViewTable"
                            renderPipelineSourceHtml = {this.renderPipelineSourceHtml}
                            renderJobsSourceHtml = {this.renderJobsSourceHtml}
                            activeRun = {this.activeRun}
                            showGraph= {this.showGraph}
                            statusIcon= {this.statusIcon}
                            setFullScreen = {this.setFullScreen}
                            handlePipelineTreeTriger = {this.handlePipelineTreeTriger}
                            errMessage = {this.errMessage}
                            page_selected_pipeline = {this.state.page_selected_pipeline}
                            pipelinesource_filter={this.state.pipelinesource_filter}
                            pageTrack={this.pageTrack}    
                            prevDate={this.prevDate}
                            nextDate={this.nextDate}
                            nowDate={this.nowDate}
                        />
                        :
                        this.state.pipelinesource_data === "No Integeration Found" ?
                          <div className="row">
                        <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                            <div className="placeholder-msg">
                                <div className="placeholder-msg__icon">
                                    <Image src="icon_placeholder_pipeline_configure" />
                                </div>
                                <div className="placeholder-msg__text">
                                    <h6>No Pipeline Sources</h6>
                                    <p>No Pipeline source connector configured</p>
                                </div>
                                <div className="placeholder-msg__action">
                                    <button onClick={this.gotoPipelineConfigure} className="btn btn-outline btn-grey btn-circle"><i><FontAwesomeIcon icon={faPlusCircle} /></i>Add Pipelines Source</button>
                                </div>
                            </div>
                        </div>
                    </div> : <Load/>
                            }    
                            

                                        {/* Table Header */}
                                        {/* <div className="react-bootstrap-table__header">
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="btn-group react-table__length" role="group">
                                                        <span>Show</span>
                                                        <select className="select-sm" id="paging" style={{ backgroundColor: "#fff", border: "1px solid #CED4DA", padding: "4px 10px", margin: "0 5px", color: "#484848" }}>
                                                            <option value="5">5</option>
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="all">all</option>
                                                        </select>
                                                        <span>items</span>
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <Form.Check 
                                                            type="switch"
                                                            id="custom-switch"
                                                            label="Active Runs Only"
                                                            className="custom-switch-sm mr-3"
                                                            onChange={this.activeRun}
                                                        />
                                                        
                                                        <div className="btn-toolbar mr-2">
                                                            <div className="btn-toolbar__wrap">
                                                                <div className="btn-toolbar__item">
                                                                    <Button variant=" ">Now</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="btn-toolbar mr-2">
                                                            <div className="btn-toolbar__wrap">
                                                                <div className="btn-toolbar__item">
                                                                    <Button onClick={this.timeFilter.bind(this, "1")} variant=" " className={this.state.timefilter === "1hr" ? "active" : " "}>1hr</Button>
                                                                </div>
                                                                <div className="btn-toolbar__item">
                                                                    <Button onClick={this.timeFilter.bind(this, "6")} variant=" " className={this.state.timefilter === "6hr" ? "active" : " "}>6hr</Button>
                                                                </div>
                                                                <div className="btn-toolbar__item">
                                                                    <Button onClick={this.timeFilter.bind(this, "12")} variant=" " className={this.state.timefilter === "12hr" ? "active" : " "}>12hr</Button>
                                                                </div>
                                                                <div className="btn-toolbar__item">
                                                                    <Button onClick={this.timeFilter.bind(this, "24")} variant=" " className={this.state.timefilter === "24hr" ? "active" : " "} >24hr</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="btn-toolbar mr-3">
                                                            <div className="btn-toolbar__wrap">
                                                                <div className="btn-toolbar__item">
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant=" " id="dropdown-basic">
                                                                            <svg className="d-none" xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="18"
                                                                                viewBox="0 0 24 24" width="18">
                                                                                <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                                                                <path d="M0 0h24v24H0z" fill="none" />
                                                                            </svg>
                                                                            <span className="filter-count d-inline-flex">0</span>
                                                                            <span className="ml-1">Filter</span>
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            {/* <Dropdown.Item href="/"className="py-1">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-running" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-running">
                                                                                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g clip-path="url(#clip0_263_199)">
                                                                                                <path d="M16.24 7.76C15.07 6.59 13.54 6 12 6V12L7.76 16.24C10.1 18.58 13.9 18.58 16.25 16.24C18.59 13.9 18.59 10.1 16.24 7.76V7.76ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="currentColor" />
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_263_199">
                                                                                                    <rect width="24" height="24" fill="white" />
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg>
                                                                                        <span className="ml-1">Running</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </Dropdown.Item> 
                                                                            <Dropdown.Item href="#/action-1" className="py-1">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-scheduled" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-scheduled">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z" /></g></g></g></svg>
                                                                                        <span className="ml-1">Scheduled</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-2" className="py-1">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-progress" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-progress">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5z" /></g></svg>
                                                                                        <span className="ml-1">In Progress</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-3" className="py-1">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-queued" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-queued">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" /></svg>
                                                                                        <span className="ml-1">Queued</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-3" className="py-1">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-done" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-done">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                        <span className="ml-1">Done</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-3" className="py-1">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-failed" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-failed">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" /></svg>
                                                                                        <span className="ml-1">Failed</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="btn-group react-table__filter" role="group">
                                                            <span>Search:</span>
                                                            <div className="table-search">
                                                                <input placeholder="Search" id="searchBox"
                                                                    className="form-control" style={{ backgroundColor: "#ffffff" }}
                                                                    type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* Table Main */}
                                        {/* <div className="react-bootstrap-table table-responsive pipeline-table">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th width="50">R.No</th>
                                                        <th width="200">Pipeline Datasource</th>
                                                        <th width="226">Jobs</th>
                                                        <th width="412">
                                                            <div className="tbl-time-range">
                                                                <div className="tbl-time-range__header">
                                                                    <label class="text-dark"> Last Day Schedule - <strong >{this.state.endDate.format(PIPELINE_DISPLAY_DATE_FORMAT)}</strong></label>
                                                                    <label>
                                                                    </label>
                                                                    <ul className="tbl-time-range__list">
                                                                        <li id="chart_time_1">&nbsp;</li>
                                                                        <li id="chart_time_2">&nbsp;</li>
                                                                        <li id="chart_time_3">&nbsp;</li>
                                                                        <li id="chart_time_4">&nbsp;</li>
                                                                        <li id="chart_time_5">&nbsp;</li>
                                                                        <li id="chart_time_6">&nbsp;</li>
                                                                        <li id="chart_time_7">&nbsp;</li>
                                                                        <li id="chart_time_8">&nbsp;</li>
                                                                        <li id="chart_time_9">&nbsp;</li>
                                                                        <li id="chart_time_10">&nbsp;</li>
                                                                        <li id="chart_time_11">&nbsp;</li>
                                                                        <li id="chart_time_12">&nbsp;</li>
                                                                        <li id="chart_time_13">&nbsp;</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th width="95">Last Run</th>
                                                        <th width="80">Last <br />Duration</th>
                                                        <th width="70">Avg. <br />Duration</th>
                                                        <th width="95">Next Run</th>
                                                        <th width="110">Total Attempts</th>
                                                        <th width="95">Status</th>
                                                        <th width="80">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    { 
                                                    this.state.activeRunFilter === 1 ?
                                                    <tr>
                                                        <td colspan="11">
                    <div className="row">
                    <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div className="placeholder-msg">
                            <div className="placeholder-msg__icon">
                                <Image src="icon_placeholder_no_pipeline_data" />
                            </div>
                            <div className="placeholder-msg__text">
                                <h6>No data available for active runs  </h6>
                            </div>
                        </div>
                    </div>
                </div> </td>

</tr> : "" }

                                                        
                                                    {
                                                        this.state.filterpipelinelist !== null && this.state.activeRunFilter === 0   ?
                                                            this.state.filterpipelinelist.map((pipeline, pipelineindex) => {
                                                                return (
                                                                    <tr>
                                                                        <td width="50">{pipelineindex + 1}</td>
                                                                        <td width="200">
                                                                            <p className="text-info"><i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>{pipeline.metadata.pipeline_name}</p>
                                                                            <div className="qd-connector-box">
                                                                                <div className="qd-connector-box__header">
                                                                                    <label className="qd-connector-box__label">Connector</label>
                                                                                    {
                                                                                        
                                                                                        pipeline.metadata.pipeline_type === "Teradata" ?
                                                                                            <Image src="connector_logo_teradata" alt="" />
                                                                                            :
                                                                                            pipeline.metadata.pipeline_type === "airflow" ?
                                                                                                <Image src="connector_logo_airflow" alt="" /> :

                                                                                                pipeline.metadata.pipeline_type === "snowflake" ?
                                                                                                    <Image src="connector_logo_snowflake" alt="" /> :

                                                                                                    <Image src="connector_logo_azure_synapse" alt="" />
                                                                                                }
                                                                                </div>
                                                                                <div className="qd-connector-box__body">
                                                                                    <ul className="connector-list">
                                                                                        <li>
                                                                                            <p>
                                                                                                <span className="text-muted">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M15,22h4c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-1v-2c0-1.1-0.9-2-2-2h-3V9h1c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2h-4 C8.9,2,8,2.9,8,4v3c0,1.1,0.9,2,2,2h1v2H8c-1.1,0-2,0.9-2,2v2H5c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-3 c0-1.1-0.9-2-2-2H8v-2h8v2h-1c-1.1,0-2,0.9-2,2v3C13,21.1,13.9,22,15,22z" /></g></svg>
                                                                                                    <label className="ml-1">Total No Jobs</label>
                                                                                                </span>
                                                                                                <strong>{pipeline.metadata.total_number_jobs}</strong>
                                                                                            </p>
                                                                                        </li>
                                                                                        <li>
                                                                                            <p>
                                                                                                <span className="text-success">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                                    <label className="ml-1">Success Jobs</label>
                                                                                                </span>
                                                                                                <strong>{pipeline.metadata.success_job}</strong>
                                                                                            </p>
                                                                                        </li>
                                                                                        <li>
                                                                                            <p>
                                                                                                <span className="text-danger">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" /></svg>
                                                                                                    <label className="ml-1">Failed Jobs</label>
                                                                                                </span>
                                                                                                <strong>{pipeline.metadata.failure_jobs}</strong>
                                                                                            </p>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td colspan="9" className="p-0">
                                                                            <table className="table table-tree">
                                                                                <tbody>
                                                                                    {pipeline.jobs.map((pipelinejob, pipelinejobindex) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr id={"toggle_" + pipelineindex + "_" + pipelinejobindex}>
                                                                                                    <td width="26">
                                                                                                        <div id={pipelineindex + "_" + pipelinejobindex} className="table-tree__toggle" onClick={this.handlePipelineTreeTriger.bind(this, pipelineindex + "_" + pipelinejobindex)}>
                                                                                                            <i className="table-tree__toggle-icon"></i>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td width="200">
                                                                                                        <div className="table-tree__label"><span>{pipelinejob.job_name}</span></div>
                                                                                                    </td>
                                                                                                    <td width="412" className="pt-0">

                                                                                                        {this.showGraph(pipelinejob.timeline)}

                                                                                                        
                                                                                                    </td>
                                                                                                    <td width="95">{pipelinejob.last_run}</td>
                                                                                                    <td width="80">
                                                                                                        <div className="d-flex align-items-center">
                                                                                                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <path opacity="0.5" d="M8.46443 16.9498L16.2426 9.17158C16.6331 8.78106 16.6331 8.1479 16.2426 7.75737C15.8521 7.36685 15.2189 7.36685 14.8284 7.75737L7.05021 15.5355C6.65969 15.9261 6.65969 16.5592 7.05021 16.9498C7.44074 17.3403 8.0739 17.3403 8.46443 16.9498Z" fill="#50CD89" />
                                                                                                                <path d="M14.8284 9.97161V15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891V8.05029C16.9497 7.49801 16.502 7.05029 15.9497 7.05029H8.11095C7.52516 7.05029 7.05029 7.52517 7.05029 8.11095C7.05029 8.69674 7.52516 9.17161 8.11095 9.17161H14.0284C14.4703 9.17161 14.8284 9.52979 14.8284 9.97161Z" fill="#50CD89" />
                                                                                                            </svg>
                                                                                                            <span>{pipelinejob.last_duration}</span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td width="70">
                                                                                                        {pipelinejob.avg_duration}
                                                                                                         <div className="d-flex align-items-center">
                                                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.5" d="M8.46443 16.9498L16.2426 9.17158C16.6331 8.78106 16.6331 8.1479 16.2426 7.75737C15.8521 7.36685 15.2189 7.36685 14.8284 7.75737L7.05021 15.5355C6.65969 15.9261 6.65969 16.5592 7.05021 16.9498C7.44074 17.3403 8.0739 17.3403 8.46443 16.9498Z" fill="#50CD89" />
                                                                    <path d="M14.8284 9.97161V15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891V8.05029C16.9497 7.49801 16.502 7.05029 15.9497 7.05029H8.11095C7.52516 7.05029 7.05029 7.52517 7.05029 8.11095C7.05029 8.69674 7.52516 9.17161 8.11095 9.17161H14.0284C14.4703 9.17161 14.8284 9.52979 14.8284 9.97161Z" fill="#50CD89" />
                                                                </svg>
                                                                <span>{pipelinejob.avg_duration}</span>
                                                            </div> 
                                                                                                    </td>
                                                                                                    <td width="95">{pipelinejob.next_run}</td>
                                                                                                    <td width="110">
                                                                                                        <div className="tbl-job-attempts">
                                                                                                            <div className="badge success-attempt-badge">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#4ED2CC"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                                                <span className="text-dark">{pipelinejob.total_attempts_success}</span>
                                                                                                            </div>
                                                                                                            <div className="badge failed-attempt-badge ml-2">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#f64e60"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" /></svg>
                                                                                                                <span className="text-dark">{pipelinejob.total_attempts_failure}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td width="95">
                                                                                                        {this.statusIcon(pipelinejob.status)}
                                                                                                         <div className="d-flex align-items-center text-success">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                <span className="ml-1">{this.statusIcon(pipelinejob.status)}</span>
                                                            </div> 
                                                                                                    </td>
                                                                                                    <td width="80">
                                                                                                        <button className="btn btn-light" onClick={this.setFullScreen.bind(this, pipeline.metadata.pipeline_name, pipelinejob.job_id)}> More <i className="ml-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#484848"><rect fill="none" height="24" width="16" /><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z" /></svg></i></button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr id={pipelineindex + "_" + pipelinejobindex + "-nested"} className="table-tree__nested">
                                                                                                    <td colspan="11">
                                                                                                        <table className="table table-tree">
                                                                                                            <tbody>
                                                                                                                {pipelinejob.stages.map((stage, index) => {
                                                                                                                    return (
                                                                                                                        <tr>
                                                                                                                            <td width="26">
                                                                                                                            </td>
                                                                                                                            <td width="200">
                                                                                                                                <div className="table-tree__label"><span>{stage.job_name}</span></div>
                                                                                                                            </td>
                                                                                                                            <td width="412">

                                                                                                                                {this.showGraph(stage.timeline)}

                                                                                                                                <div className="tbl-time-range__content">
                                                                                <div className="tbl-time-range__cols">
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                    <div className="tbl-time-range__col"></div>
                                                                                </div>
                                                                            </div> 
                                                                                                                            </td>
                                                                                                                            <td width="95" className="border-left-0">{stage.last_run}</td>
                                                                                                                            <td width="80">{stage.last_duration}</td>
                                                                                                                            <td width="70">
                                                                                                                                <div className="d-flex align-items-center">
                                                                                                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                        <path opacity="0.5" d="M8.46443 16.9498L16.2426 9.17158C16.6331 8.78106 16.6331 8.1479 16.2426 7.75737C15.8521 7.36685 15.2189 7.36685 14.8284 7.75737L7.05021 15.5355C6.65969 15.9261 6.65969 16.5592 7.05021 16.9498C7.44074 17.3403 8.0739 17.3403 8.46443 16.9498Z" fill="#50CD89" />
                                                                                                                                        <path d="M14.8284 9.97161V15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891V8.05029C16.9497 7.49801 16.502 7.05029 15.9497 7.05029H8.11095C7.52516 7.05029 7.05029 7.52517 7.05029 8.11095C7.05029 8.69674 7.52516 9.17161 8.11095 9.17161H14.0284C14.4703 9.17161 14.8284 9.52979 14.8284 9.97161Z" fill="#50CD89" />
                                                                                                                                    </svg>
                                                                                                                                    <span>{stage.last_duration}</span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td width="90">
                                                                                                                                {stage.avg_duration}
                                                                                                                            </td>
                                                                                                                            <td width="95">{stage.next_run}</td>
                                                                                                                            <td width="110">
                                                                                                                                <div className="tbl-job-attempts">
                                                                                                                                    <div className="badge success-attempt-badge">
                                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#4ED2CC"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                                                                        <span className="px-1 text-dark">{stage.total_attempts_success}</span>
                                                                                                                                    </div>
                                                                                                                                    <div className="badge failed-attempt-badge ml-2">
                                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#f64e60"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" /></svg>
                                                                                                                                        <span className="px-1 text-dark">{stage.total_attempts_failure}</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td width="95">
                                                                                                                                {this.statusIcon(stage.status)}

                                                                                                                                {/* <div className="d-flex align-items-center text-success">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                <span className="ml-1">{stage.status}</span>
                                                                            </div> 
                                                                                                                            </td>
                                                                                                                            <td width="80">
                                                                                                                                <button onClick={this.setFullScreen.bind(this, pipeline.metadata.pipeline_name, stage.job_id)} className="btn btn-light">More <i className="ml-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#484848"><rect fill="none" height="24" width="16" /><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z" /></svg></i></button>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                })}








                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>

                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                    <tr>
                                                                                        <td colSpan="9" className="table-tree__footer">
                                                                                            <div className="row no-gutters">
                                                                                                <div className={"col-6 paging_fotter_" + pipelineindex}>
                                                                                                     <button onClick={this.paging.bind(this, pipelineindex)} className="btn btn-link tbl-tree__expand-btn --is-expanded"><i className="mr-1"><FontAwesomeIcon icon={faAngleDoubleDown} /></i>10 of {this.state.filterpipelinelist !== null ? pipeline.jobs.length : 0} rows - Click to view all Jobs</button> 
                                                                                                </div>
                                                                                                <div className="col-6">

                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>




                                                                        </td>
                                                                    </tr>







                                                                )
                                                            })







                                                            : ""
                                                    }


                                                </tbody>
                                            </table>
                                        </div> */}
                                        {/* Table Footer */}
                                        { 
                                                    this.state.activeRunFilter === 0 ?
                                        <div className="react-bootstrap-table__footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <span className="react-table__info">
                                                        {
                                                            this.state.filterpipelinelist !== null ?

                                                                <span>Showing <b>1</b> to <b>{this.state.filterpipelinelist.length}</b> of
                                                        <b> {this.state.filterpipelinelist.length} </b>results</span> : ""

                                                        }
                                                    </span>
                                                </div>
                                                {/* <div className="col-sm-12 col-md-6">
                                                    <ul className="pagination float-md-right">
                                                        <li className="active page-item" title="1">
                                                            <a href="/"className="page-link">1</a>
                                                        </li>
                                                        <li className="page-item" title="2">
                                            <a href="/"className="page-link">2</a>
                                        </li>
                                                        <li className="page-item" title="next page">
                                                            <a href="/"className="page-link">&gt;</a>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div> : "" }
                                    </div>
                                </div>
                            </>


                        </div>
                        : <Load />
                }
                <div className="row d-none">
                    <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div className="placeholder-msg">
                            <div className="placeholder-msg__icon">
                                <Image src="icon_placeholder_pipeline_configure" />
                            </div>
                            <div className="placeholder-msg__text">
                                <h6>No Pipeline Sources</h6>
                                <p>No Pipeline source connector configured</p>
                            </div>
                            <div className="placeholder-msg__action">
                                <button onClick={this.gotoPipelineConfigure} className="btn btn-outline btn-grey btn-circle"><i><FontAwesomeIcon icon={faPlusCircle} /></i>Add Pipelines Source</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-none">
                    <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div className="placeholder-msg">
                            <div className="placeholder-msg__icon">
                                <Image src="icon_placeholder_no_pipeline_data" />
                            </div>
                            <div className="placeholder-msg__text">
                                <h6>No data available for the chosen date</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, { addFollowAttributes })(DqListViewContent);