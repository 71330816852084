import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
// import { DATA_SET, ATTRIBUTE } from "../../utils/constant";
// import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import {
    normalizeChildDatasetName
} from '../../utils/attribute_name_utils';
import CustomSelect from "./customSelect";
import { components } from "react-select";

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
    </div>
);

export const Option = props => {
    return (
        <div className={props.data.type === undefined || props.data.value === props.data.type || props.selectProps.name === "model" ? "parent-checkbox" : "child-checkbox"}>
            <components.Option {...props}>
                {/* <input
            type="checkbox"
            className={props.type === undefined || props.value === props.type ? "parent-checkbox" : "child-checkbox"}
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label> */}
                <div className="custom-control custom-checkbox custom-control-inline">
                    <Form.Control
                        type="checkbox"
                        id={props.value}
                        className="custom-control-input"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <Form.Label className="custom-control-label">{props.label}</Form.Label>
                </div>
            </components.Option>
        </div>
    );
};

export const FilterOptions = forwardRef((props, ref) => {
    // let propsAttributes = props.attributeOptions;
    // if (propsAttributes === undefined || propsAttributes === null) {
    //     propsAttributes = [];
    // }
  
    // let datasetsList = props.monitorModule.mappedDatasetInfo.datasetLists
    // for (let currOption of propsAttributes){
    //     let reqAttributeName = currOption.label;
    //     reqAttributeName = normalizeAttributeName(reqAttributeName)
    //     currOption.label = reqAttributeName;
    // }


    // let propsDatasets = props.dataSetOptions;
    // if (propsDatasets === undefined || propsDatasets === null) {
    //     propsDatasets = [];
    // }

    // for (let currOption of propsDatasets){
    //     let reqDatasetName = currOption.label;
    //     reqDatasetName = normalizeChildDatasetName(reqDatasetName)
    //     currOption.label = reqDatasetName;
    // }

    let [dataSourceOption, setDataSourceOption] = useState(props.dataSourceOption);
    let [dataSetOption, setDataSetOption] = useState(props.dataSetOptions);
    let [attributeOption, setAttributeOption] = useState(props.attributeOptions);
    // let [preSelectedDatasetsList, setPreSelectedDatasetsList] = useState(props.preSelectedDatasetsList);
    let [selectedEnvironmentOption, setSelectedEnvironmentOption] = useState(props.preSelectedEnvironment);
    let [selectedDataSourceOption, setSelectedDataSourceOption] = useState(props.preSelectedDataSource);
    let [selectedDataSetOption, setSelectedDataSetOption] = useState(props.preSelectedDatasets);
    let [selectedAttributeOption, setSelectedAttributeOption] = useState(props.preSelectedAttributes);
    let [selectedAllAttributeOption, setSelectedAllAttributeOption] = useState(props.preSelectedAttributes);

    let [isFilterSubmitted, setFilterSubmitted] = useState(false);
    let [isformResetFlag, setFormResetFlag] = useState(props.formResetFlag);

    useImperativeHandle(ref, () => ({
        setDefaultValuesFromParent(environment, dataSource, dataSet, attribute) {
            if (environment !== null) {
                setSelectedEnvironmentOption(environment);
            }
            if (dataSource !== null) {
                setSelectedDataSourceOption(dataSource);
            }
            if (dataSet !== null) {
                setSelectedDataSetOption(dataSet);
            }
            if (attribute !== null) {
                setSelectedAttributeOption(attribute);
            }
            if (attribute !== null) {
                setSelectedAllAttributeOption(attribute);
            }
        }
    }));

    React.useEffect(() => {
        setSelectedEnvironmentOption(props.preSelectedEnvironment);
    }, [props.preSelectedEnvironment])

    React.useEffect(() => {
        setSelectedDataSourceOption(props.preSelectedDataSource);
    }, [props.preSelectedDataSource])

    React.useEffect(() => {
        setSelectedDataSetOption(props.preSelectedDatasets);
    }, [props.preSelectedDatasets])

    React.useEffect(() => {
        setDataSourceOption(props.dataSourceOption);
    }, [props.dataSourceOption])

    React.useEffect(() => {
        // let dataSetOptions = props.dataSetOptions;
        // let option = [];
        let groupedlist = [];
        let allattributes = [];
        // let datasetsList = props.monitorModule.mappedDatasetInfo.datasetLists
        // if(datasetsList !== undefined && datasetsList !== null){
        //     option = datasetsList.filter((item) => item.dataSourceId === dataSetOptions.value)
        // }
        if(props.attributeOptions !== undefined && props.attributeOptions !== null){
            allattributes = props.attributeOptions
            // if(props.preSelectedDatasetsList !== null || props.preSelectedDatasetsList !== undefined){
            //     groupedlist = allattributes.map((item)=> preSelectedDatasetsList.includes(item.datasetId))
            // }
            let preSelectedDatasetsList = []
            let options
            if(props.preSelectedDatasets !== -1 && props.preSelectedDatasets !== null && props.preSelectedDatasets !== undefined){
                preSelectedDatasetsList = props.preSelectedDatasets.map((item)=> item.key)
                for(let id of preSelectedDatasetsList){
                    options = allattributes.filter((item)=> id !=="*" && item.datasetId === id)
                    if(options.length === 0){
                        continue
                    }
                    groupedlist.push({'label':normalizeChildDatasetName(options[0]['datasetName']),'options':options})
                }
            }   
        }
        // if(dataSetOptions !== null && selectedDataSourceOption!== undefined) {
        //     dataSetOptions.forEach(dataset => {
        //      let attributes = props.dataModule.dataSetAttributeMapping[dataset.value];
        //      if(attributes!==undefined){
        //      option = [];
        //      attributes.forEach((attributeId) => {
        //                      let reqAttributeName = parseMetaMapping(ATTRIBUTE, dataset.value, attributeId);
        //                      reqAttributeName = normalizeAttributeName(reqAttributeName);
        //                      option.push({"value": attributeId, "label": reqAttributeName});
        //                      allattributes.push({"value": attributeId, "label": reqAttributeName, "DatasetName":normalizeChildDatasetName(parseMetaMapping(DATA_SET, selectedDataSourceOption.value,dataset.value))})
        //      });
        //      groupedlist.push({'label':normalizeChildDatasetName(parseMetaMapping(DATA_SET, selectedDataSourceOption.value,dataset.value)),'options':option})
        //     }

        //     })
            
        //  }
        setAttributeOption(groupedlist);
        setSelectedAllAttributeOption(allattributes);

        setDataSetOption(props.dataSetOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataSetOptions])

    React.useEffect(() => {
        setFormResetFlag(props.formResetFlag);
    }, [props.formResetFlag])

    React.useEffect(() => {
        setSelectedAttributeOption(props.preSelectedAttributes);
    }, [props.preSelectedAttributes])


    // React.useEffect(() => {
    //     setSelectedAllAttributeOption(props.preSelectedAttributes);
    // }, [props.preSelectedAttributes])

    if (isformResetFlag === 1) {
        setFormResetFlag(0);
        setDataSourceOption([]);
        setDataSetOption([]);
        setAttributeOption([]);
        setSelectedDataSetOption(-1);
        setSelectedDataSourceOption(-1);
        setSelectedAttributeOption(-1);
        setSelectedEnvironmentOption(-1);
        setSelectedAllAttributeOption(-1);
    }

    // function parseMetaMapping(type, parentId, id) {
    //     let metaMapping = props.dataModule.metaData;
    //     let prefix;
    //     switch (type) {
    //         case DATA_SET: {
    //             if (selectedEnvironmentOption !== undefined) {
    //                 prefix = `${selectedEnvironmentOption.value}.${parentId}.${id}`;
    //                 const filtered = Object.keys(metaMapping)
    //                     .filter(key => key.startsWith(prefix))
    //                     .reduce((obj, key) => {
    //                         obj[key] = metaMapping[key];
    //                         return obj;
    //                     }, {});
    //                 let dataSetName = filtered[Object.keys(filtered)[0]];
    //                 return datasetNameFromMappedValue(dataSetName)
    //             }
    //             break;
    //         }
    //         case ATTRIBUTE: {
    //             if (selectedEnvironmentOption !== undefined) {
    //                 prefix = `${selectedEnvironmentOption.value}.${selectedDataSourceOption.value}.${parentId}.${id}`;
    //                 const filtered = Object.keys(metaMapping)
    //                     .filter(key => key === prefix)
    //                     .reduce((obj, key) => {
    //                         obj[key] = metaMapping[key];
    //                         return obj;
    //                     }, {});
    //                 let attributeNameMapVal = filtered[Object.keys(filtered)[0]];
    //                 let attributeName = attributeNameFromMappedValue(attributeNameMapVal);
    //                 return attributeName;
    //             }
    //             break;
    //         }
    //         default:
    //             return "NA";
    //     }
    // }

    // Set Handle Enviroment and corresponding Datasource while user changing the Env   
    function setSelectedEnvironment(event) {
        let option = [];
        let selectedEnvironment = event.value;
        let dataSources = props.dataModule.environmentDataSourceMapping[selectedEnvironment];
        dataSources.forEach((dataSourceId) => {
            option.push({ "value": dataSourceId, "label": props.dataModule.integrations[dataSourceId] });
        });

        //  
        setAttributeOption([]);
        setDataSetOption([]);
        setDataSourceOption(option);
        setSelectedEnvironmentOption(event);
        setSelectedDataSourceOption(-1);
        setSelectedDataSetOption(-1);
        setSelectedAttributeOption(-1);
        setSelectedAllAttributeOption(-1);
    }

    // Set Handle Datasource and corresponding dataset while user changing the Datasource
    function setSelectedDataSource(event) {
        let option = [];
        let selectedDataSource = event.value;
        // let dataSets = props.dataModule.dataSourceDataSetMapping[selectedDataSource];
        let datasetsList = props.monitorModule.mappedDatasetInfo.datasetLists
        
        if(datasetsList !== undefined && datasetsList !== null){
            option = datasetsList.filter((item) => item.dataSourceId === selectedDataSource)
        }
            // if (dataSets !== undefined && dataSets !== null) {
        //     dataSets.forEach((dataSetId) => {
        //         option.push({"value": dataSetId, "label": normalizeChildDatasetName(parseMetaMapping(DATA_SET, selectedDataSource, dataSetId))});
        //     })
        // }

        setAttributeOption([]);
        setDataSetOption(option);
        setSelectedDataSourceOption(event);
        setSelectedDataSetOption(-1);
        setSelectedAttributeOption(-1);
        setSelectedAllAttributeOption(-1);
    }


    function setSelectedAttributeGroup(event) {
        setSelectedAttributeOption(event);
    }

    function setSelectedDataSetGroup(event) {
        let option = [];
        let groupedlist = [];
        let allattributes = [];

        let attributeList = props.monitorModule.mappedDatasetInfo.attributeList
        if (event !== null) {
            if (event.length > 0) {
                event.forEach(dataset => {
                    if(dataset.value !== "*"){
                        if(attributeList !== undefined && attributeList !== null){
                            option = attributeList.filter((item) => item.datasetId === dataset.value)
                            // Merge the new list with existing list
                            allattributes = [...allattributes,...option]
                            groupedlist.push({ 'label': normalizeChildDatasetName(option[0]['datasetName']), 'options': option })    
                        }
                    }
                    // if (dataset.value !== "*") {
                    //     let attributes = props.dataModule.dataSetAttributeMapping[dataset.value];
                    //     if (attributes !== undefined) {
                    //         option = [];
                    //         attributes.forEach((attributeId) => {
                    //             let reqAttributeName = parseMetaMapping(ATTRIBUTE, dataset.value, attributeId);
                    //             reqAttributeName = normalizeAttributeName(reqAttributeName);
                    //             option.push({ "value": attributeId, "label": reqAttributeName });
                    //             allattributes.push({ "value": attributeId, "label": reqAttributeName, "DatasetName": normalizeChildDatasetName(parseMetaMapping(DATA_SET, selectedDataSourceOption.value, dataset.value)) })
                    //         });
                    //         groupedlist.push({ 'label': normalizeChildDatasetName(parseMetaMapping(DATA_SET, selectedDataSourceOption.value, dataset.value)), 'options': option })
                    //     }
                    // }
                })
                setAttributeOption(groupedlist);
                setSelectedAllAttributeOption(allattributes);
                setSelectedDataSetOption(event);
            } else {
                setAttributeOption(-1);
                setSelectedAllAttributeOption(-1);
                setSelectedDataSetOption(event);
                setSelectedAttributeOption(-1)
            }
        }
    }


    // function setSelectedDataSet(event) {
    //     let option = [];
    //     let selectedDataSet = [];
    //     if (event !== -1 && event !== null) {
    //         event.forEach((item) => {
    //             selectedDataSet.push(item.value);
    //             let attributes = props.dataModule.dataSetAttributeMapping[item.value];
    //             // attributes.forEach((attributeId) => {
    //             //     let reqAttributeName = parseMetaMapping(ATTRIBUTE, item.value, attributeId);
    //             //     reqAttributeName = normalizeAttributeName(reqAttributeName);
    //             //     option.push({"value": attributeId, "label": reqAttributeName});
    //             // });
    //         });
    //         setSelectedDataSetOption(event);
    //         setSelectedAttributeOption(-1);
    //     } else {
    //         setSelectedDataSetOption(-1);
    //         setSelectedAttributeOption(-1);
    //         setSelectedAllAttributeOption(-1);
    //     }
    //     setAttributeOption(option);

    // }

    // function setSelectedAttribute(event) {
    //     let selectedAttribute = [];
    //     if (event !== -1 && event !== null) {
    //         event.forEach((item) => {
    //             selectedAttribute.push(item.value);
    //         });
    //         setSelectedAttributeOption(event);
    //     } else {
    //         setSelectedAttributeOption(-1);
    //         setSelectedAllAttributeOption(-1);
    //     }
    // }

    function con(defaultText, selected, data) {
        if (selected === undefined || selected === null) {
            return "";
        }
        if (selected.length === 0 || selected === -1) {
            return defaultText
        } else if (selected.length > data.length) {
            return "All selected"
        } else {
            return selected.length + " selected"
        }
    }


    function handleFilterClose() {
        setSelectedDataSourceOption(props.preSelectedDataSource);
        setSelectedEnvironmentOption(props.preSelectedEnvironment);
        setSelectedDataSetOption(props.preSelectedDatasets);
        setSelectedAttributeOption(props.preSelectedAttributes);
        props.closeFilterModal();
    }

    function handleReset() {
        setFormResetFlag(1);
    }

    function setFilteredValues() {
        setFilterSubmitted(true);
        let filteredValues = [];
        let prefix;
        if (selectedEnvironmentOption !== -1 && selectedDataSourceOption !== -1) {
            prefix = `${selectedEnvironmentOption.value}.${selectedDataSourceOption.value}`;
            if (selectedDataSetOption !== undefined && selectedDataSetOption !== null && selectedDataSetOption !== -1 && selectedDataSetOption.length > 0) {
                selectedDataSetOption.forEach((dataSet) => {
                    if (selectedAttributeOption !== null && selectedAttributeOption !== -1 && selectedAttributeOption.length > 0) {
                        selectedAttributeOption.forEach((attribute) => {
                            filteredValues.push(`${prefix}.${dataSet.value}.${attribute.value}`);
                        });
                    } else {
                        filteredValues.push(`${prefix}.${dataSet.value}`);
                    }
                });
            } else {
                filteredValues.push(prefix);
            }
            props.setFilteredValues(filteredValues)
            props.closeFilterModal();
            setFilterSubmitted(false);
            props.setChipData(selectedEnvironmentOption, selectedDataSourceOption, selectedDataSetOption, selectedAttributeOption);
            
        }

    }


    return (
        <Modal show={props.show} style={{ display: "block", paddingRight: "15px" }} centered
            onHide={handleFilterClose}>
            <Modal.Header closeButton>
                <h5 className="modal-title" id="exampleModalLongTitle">
                    {props.filterTitle}
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Form id='myForm'
                    className="form"
                >
                    <div className="form-group">
                        <label htmlFor="">Environment <span className="text-danger">*</span>:</label>
                        <Select
                            name="environments"
                            filterOption={({ label }, query) => label.includes(query)}
                            id={props.id + "Environment"}
                            onChange={setSelectedEnvironment}
                            options={props.environments}
                            placeholder="Choose Environment"
                            classNamePrefix='select-control'
                            value={selectedEnvironmentOption === -1 ? '' : selectedEnvironmentOption}
                            defaultValue={selectedEnvironmentOption === -1 ? '' : selectedEnvironmentOption}
                        />
                        {selectedEnvironmentOption === -1 && isFilterSubmitted ?
                            <span className='error-text'> {"Please Select an Environment"} </span> : ""}
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Datasource <span className="text-danger">*</span>:</label>
                        <Select name="data_sources"
                            filterOption={({ label }, query) => {
                                if (typeof (label) !== String) {
                                    return true;
                                }

                                return label.includes(query)
                            }}
                            options={dataSourceOption}
                            id={props.id + "Datasource"}
                            onChange={setSelectedDataSource}
                            placeholder="Choose Data source"
                            classNamePrefix='select-control'
                            value={selectedDataSourceOption === -1 ? '' : selectedDataSourceOption}
                            defaultValue={selectedDataSourceOption === -1 ? '' : selectedDataSourceOption}
                        />
                        {selectedDataSourceOption === -1 && isFilterSubmitted ?
                            <span className='error-text'>{"Please Select a Datasource"}</span> : ""}
                    </div>
                    {/* <div className="form-group d-none">
                        <label htmlFor="">Dataset :</label>
                        <Select isMulti={true} name="schemas"
                            filterOption={({ label }, query) => label.includes(query)}
                            options={dataSetOption}
                            id={props.id + "Schemas"}
                            onChange={setSelectedDataSet}
                            placeholder="Choose Dataset"
                            classNamePrefix='select-control'
                            value={selectedDataSetOption === -1 ? '' : selectedDataSetOption}
                            defaultValue={selectedDataSetOption === -1 ? '' : selectedDataSetOption}
                        />

                    </div>
                    <div className="form-group d-none">
                        <label htmlFor="">Attribute :</label>
                        <Select isMulti={true} name="columns"
                            // filterOption={({label}, query) => label.includes(query)}
                            options={attributeOption}
                            id={props.id + "Columns"}
                            onChange={setSelectedAttribute}
                            placeholder="Choose Attributes"
                            classNamePrefix='select-control'
                            value={selectedAttributeOption === -1 ? '' : selectedAttributeOption}
                            defaultValue={selectedAttributeOption === -1 ? '' : selectedAttributeOption}
                        />
                    </div> */}

                    <Form.Group >
                        <Form.Label>Dataset:</Form.Label>
                        <CustomSelect
                            isMulti
                            isClearable={false}
                            name="dataset"
                            id="datasetNewFilter"
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            // isInvalid={errors.dataset && touched.dataset}
                            components={{ Option }}
                            classNamePrefix='select-control'
                            options={dataSetOption}
                            onChange={setSelectedDataSetGroup}
                            allowSelectAll={true}
                            placeholder={con("Choose Dataset", selectedDataSetOption, dataSetOption)}
                            value={selectedDataSetOption}
                            controlShouldRenderValue={false}
                            skloader={props.skloader}
                        />
                        {/* <ErrorMessage component="div" className="error-text" name="dataset" /> */}
                    </Form.Group>


                    <Form.Group >
                        <Form.Label>Attribute:</Form.Label>
                        <CustomSelect
                            isMulti
                            isClearable={false}
                            name="attribute"
                            id="attributeNewFilter"
                            options={attributeOption}
                            originalAttributeOptions={selectedAllAttributeOption}
                            placeholder={con("Choose Attribute", selectedAttributeOption, selectedAllAttributeOption)}
                            onChange={selectedOption => {
                                setSelectedAttributeGroup(selectedOption)
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                                Option
                            }}
                            value={selectedAttributeOption}
                            allowSelectAll={true}
                            controlShouldRenderValue={false}
                            classNamePrefix='select-control'
                            formatGroupLabel={formatGroupLabel}
                        />
                        {/* <ErrorMessage component="div" className="error-text" name="dataset" /> */}
                    </Form.Group>
                    <div className="form-group mb-0 mt-2">
                        <span className="switch switch-sm switch-info">
                            <label className="m-0">
                                Include PII
                                <input className="form-check-input" disabled checked={true} type="checkbox" />
                                <span className="m-0 mx-2"></span>
                            </label>
                        </span>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <button type="button" onClick={handleReset} className="btn btn-link">Reset</button>
                <div>
                    <button type="button" onClick={handleFilterClose}
                        className="btn btn-outline btn-grey btn-circle">Close</button>
                    <button type="button" onClick={setFilteredValues}
                        className="btn btn-primary btn-circle show-success-toast">Apply</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
});