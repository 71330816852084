import React from 'react';
import { hideRequestNotification } from "../../utils/event_handling";
import {ALERT_NAME_DESC,METRIC_SCALE, ALERT_TYPE, ROW_COUNT_NAME } from "../../utils/constant";
import {roundOffResult} from "../../utils/common_utils"
import { browserHistory } from 'react-router';

class viewNotification extends React.Component {
     
     constructor(props) {
          super(props);
          this.hideRequestNotification = hideRequestNotification.bind(this);
          this.getNotificationFullView = this.getNotificationFullView.bind(this);
     }

    gotoTeamRequests() {
        window.location.subpath = "request"
        browserHistory.push("/teammate");
   }

     goToPlan() {
           window.location.href = "/choose-plan"
     }

     redirectPlanPage(evt){
          if (evt !== null && evt !== undefined){
               let notificationId = evt.notification_id;
               if (notificationId !== null && notificationId !== undefined){
                    this.hideRequestNotification(this.props.selectedView.notification_id);
               }
          }
          this.goToPlan();
     }

     updateRequestNotification(evt) {
          if (evt !== null && evt !== undefined){
               let notificationId = evt.notification_id;
               if (notificationId !== null && notificationId !== undefined){
                    this.hideRequestNotification(this.props.selectedView.notification_id);
               }
          }
          this.gotoTeamRequests();
      }

      getNotificationFullView(selectedViewAdditionalInfo, fullNotificationData){
         const notificationCreatedTime = fullNotificationData.created_time;
         let dataSourceName = selectedViewAdditionalInfo.datasource_name;
         const datasets = selectedViewAdditionalInfo.datasets;
         let associated_models = selectedViewAdditionalInfo.associated_models;
         if (associated_models === undefined || associated_models === null) {
             associated_models = [];
         }

         return (
             <>
                 <br/>
                 {datasets.map(x => (<>{x} <br/> </>))}
                 <br/>
                 {associated_models.length === 0 ?
                     ''
                     :
                     `Models associated with deleted datasets: ` +
                     `${associated_models}`
                 }
                 <br/>
                 Datasource: {dataSourceName}
                 <br/>
                 Event Time: {notificationCreatedTime}
             </>
         );
      }

      getNotificationHeaderComponent(){
          let notificationType = this.props.selectedView.notification_type;
          if (notificationType === "Data Quality Error"){
               let is_dataset_error = this.props.selectedView.additional_info["is_dataset_error"]
               let attributeLevel= this.props.selectedView.additional_info["attribute_name"]
               let attribute_name = null; 
               if (is_dataset_error === false && attributeLevel !== undefined && attributeLevel !== null){
                    attribute_name = attributeLevel
               }
               return(
                    <div className="qd-section-header">
                    {
                    attribute_name != null ?
                         <h4 className="qd-section-header-title">
                             Subject: Data quality gate failed for {attribute_name} in
                             dataset {this.props.selectedView.additional_info.data_set_name}
                         </h4>
                         :
                         <h4 className="qd-section-header-title">
                             Subject: Data quality gate failed for the
                             dataset {this.props.selectedView.additional_info.data_set_name}
                         </h4>
                    }
                    </div>
               );
          } else if(notificationType === "Model Error"){
               return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject: Model quality gate failed for
                             the model {this.props.selectedView.additional_info.model_name}
                         </h4>
                    </div>
               );
          }
          else if(notificationType === "Schema Drift"){
               return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject: Schema Drift detected in dataset -- {this.props.selectedView.additional_info.dataset_id}
                         </h4>
                    </div>
               );
          }
          else if(notificationType === "Limit Exceeded Alert"){
               return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject: Your {this.props.selectedView.additional_info.type} usage exceeded
                             for current Plan
                         </h4>
                    </div>
               );
          }
          else if(notificationType === "Payment Failure Alert"){
               return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject: Your payment for
                             order #{this.props.selectedView.additional_info.transaction_id} not completed
                         </h4>
                    </div>
               );
          }
          else if(notificationType === "Pipeline Configuration Alert"){
            return(
                 <div className="qd-section-header">
                      <h4 className="qd-section-header-title">
                          Subject: Unable to monitor {this.props.selectedView.additional_info.alert_name}
                      </h4>
                 </div>
            );
            }
         else if(notificationType === "Pipeline Configuration Failure Detected"){
            return(
                     <div className="qd-section-header">
                          <h4 className="qd-section-header-title">
                              Subject: {this.props.selectedView.additional_info.alert_name}
                          </h4>
                     </div>
                );
            }
          else if(notificationType === "Datasource Configuration Alert"){
            if(this.props.selectedView.additional_info.alert_name === "Partition Identifier Not Found") {
                return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject:  Missing Partition Identifier in the dataset {this.props.selectedView.additional_info.data_set_name}
                         </h4>
                    </div>
               );
            }
            else if(this.props.selectedView.additional_info.alert_name === "Datatype Mismatch Found") {
                return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject:  Datatype mismatch found in Incremental Data Identifier in a configured datasource
                         </h4>
                    </div>
               );
            }
            else if(this.props.selectedView.additional_info.alert_name === "Incremental Data Identifier Not Found") {
                return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject:  Missing Incremental Data Identifier in the dataset {this.props.selectedView.additional_info.data_set_name}
                         </h4>
                    </div>
               );
            }
            else if(this.props.selectedView.additional_info.alert_name === "Error in Starting Index") {
                if (this.props.selectedView.additional_info.error_type === "Datatype Mismatch") {
                    return(
                        <div className="qd-section-header">
                             <h4 className="qd-section-header-title">
                                 Subject:  Incorrect datatype for Starting Index in the dataset {this.props.selectedView.additional_info.data_set_name}
                             </h4>
                        </div>
                   );
                }
                else if (this.props.selectedView.additional_info.error_type === "Data Not Found For Value") {
                    return(
                        <div className="qd-section-header">
                             <h4 className="qd-section-header-title">
                                 Subject:  Incorrect value for Starting Index in the dataset {this.props.selectedView.additional_info.data_set_name}
                             </h4>
                        </div>
                   );
                }
                else if (this.props.selectedView.additional_info.error_type === "Data Not Found For Query") {
                    return(
                        <div className="qd-section-header">
                             <h4 className="qd-section-header-title">
                                 Subject:  No data found for the starting index query in the dataset {this.props.selectedView.additional_info.data_set_name}
                             </h4>
                        </div>
                   );
                }
                else if (this.props.selectedView.additional_info.error_type === "Invalid Query") {
                    return(
                        <div className="qd-section-header">
                             <h4 className="qd-section-header-title">
                                 Subject:  Invalid query for the starting index query in the dataset {this.props.selectedView.additional_info.data_set_name}
                             </h4>
                        </div>
                   );
                }
            }
            else{
                return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject: Incremental data identifier
                             missing for the dataset
                             {this.props.selectedView.additional_info !== undefined &&  this.props.selectedView.additional_info !== null? this.props.selectedView.additional_info.data_set_name : ""}
                         </h4>
                    </div>
               );
            }
         }
         else if(notificationType === "Missing Dataset"){
               return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject: Your configured datasource does not have the right file version
                         </h4>
                    </div>
               );
          }else if(notificationType === "Model Monitoring Metrics Alert"){
               return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject: Your configured model monitoring dataset
                             in the model contains the string type columns
                         </h4>
                    </div>
               );
          }else if(notificationType === "Datasets deletion info"){
               return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject: Some of your datasets are missing
                         </h4>
                    </div>
               );
          }else if(notificationType === "Model Dataset Not Refreshed"){
               return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject: Dataset(s) associated with one of your configured models has not been refreshed
                         </h4>
                    </div>
               );
          }
          else if(notificationType === "No dataset found"){
               return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject:  Dataset not found in the configured datasource
                         </h4>
                    </div>
               );
          }
          else if(notificationType === "Model dataset has null values"){
               return(
                    <div className="qd-section-header">
                         <h4 className="qd-section-header-title">
                             Subject:  Dataset(s) associated with one of your configured models contain(s) missing data
                         </h4>
                    </div>
               );
          } else if (notificationType === "Custom") {
              const selectedViewAdditionalInfo = this.props.selectedView.additional_info;
              let customSubject = selectedViewAdditionalInfo.subject;
              if (customSubject === undefined || customSubject === null) {
                  customSubject = "Data has error";
              }

              return (
                  <div className="qd-section-header">
                      <h4 className="qd-section-header-title">
                          Subject: {`${customSubject}`}
                      </h4>
                  </div>
              )
          } else {
              return '';
          }

      }

    getNotificationSubheaderComponent() {
        let notificationType = this.props.selectedView.notification_type;
        if (notificationType === "Data Quality Error") {
            return (
                <div>Notification generated based on the
                    configured data quality threshold in the Metrics section of Qualdo.
                </div>
            );
        } else if (notificationType === "Model Error") {
            return (
                <div>Notification generated based on the configured drift threshold
                    in the Metrics section of Qualdo.</div>
            );
        } else if (notificationType === "Custom") {
            const addOnInfo = this.props.selectedView.additional_info;
            let title_1 = addOnInfo.title_1;
            if (title_1 === undefined) {
                title_1 = "Error information";
            }

            return (
                <div>{`${title_1}`}</div>
            );
        } else {
            return '';
        }
    }

      getModelorDataset(){
          let notificationType = this.props.selectedView.notification_type;
          let return_value ={};
          if (notificationType === "Data Quality Error"){
               let dataSetName = this.props.selectedView.additional_info.data_set_name;
               return_value["key"] = "Dataset";
               return_value["value"] = dataSetName;
               return return_value
          } else if(notificationType === "Model Error"){
               let modelName = this.props.selectedView.additional_info.model_name;
               return_value["key"] = "Model";
               return_value["value"] = modelName;
               return return_value;
          }

          return return_value;
      }

    getAttributeNotification(alert_name, is_dataset_error, is_user_defined, alert_type) {
        let result = this.props.selectedView.additional_info
        let scale = METRIC_SCALE[alert_name];
        if(scale === undefined)
        {
            scale = "%";
        }       
        let attribute_level_info = result.attribute_level_info;
        if (attribute_level_info === undefined || attribute_level_info === null) {
            return "";
        }        
        let elements = []
        let attrIndex=0;
        for (let info in attribute_level_info) {
            attrIndex = attrIndex + 1;
            let current_value = attribute_level_info[info]
            elements.push(<div key={String(attrIndex)}><br/>
                {/*
                    (this.props.selectedView.notification_type !== "Model Error") ?
                        <>
                        {this.getModelorDataset()["key"]}: {this.getModelorDataset()["value"]}<br/>
                        </>
                        : ''
                */}
                {/* {is_dataset_error === false &&
                    <>
                    Datasource: {result["integration_name"]}<br/>
                    </>
                } */}
                Attribute Name: {current_value.attribute_name}<br/>
                { (is_user_defined === true) ?
                    (alert_type === "drift_value" || alert_type === "drift_attribute_level") ?
                        <>
                            Current Metric Value: {roundOffResult(current_value.metric_value)} {scale} <br/>
                            Current Drift Value: {roundOffResult(current_value.actual_fill_rate)} {scale}<br/>
                        </>
                        :
                        <>
                            Current Metric Value: {roundOffResult(current_value.actual_fill_rate)} {scale} <br/>
                        </>
                    :
                    <>
                        Current Metric Error Value: {roundOffResult(current_value.actual_fill_rate)} {scale} <br/>
                    </>
                }
            </div>)
        }
        return elements
    }

    getModelDatasetsNotRefreshed() {
        let result = this.props.selectedView.additional_info.datasets;
        if (result === undefined || result === null) {
            return "";
        }
        let elements = []
        for (let info in result) {
            let current_value = result[info]
            elements.push(<div><br/>
                Dataset name: {current_value.dataset_name}<br/>
            </div>)
        }
        return elements
    }
    getPoJobRunIdWithIdentifiedTime(RunData) {
        let elements = []
        for (let info in RunData) {
            let JobName = RunData[info].pipeline_job_name
            let JobRunId = RunData[info].pipeline_run_id
            let IdentifiedTime = RunData[info].identified_time
            elements.push(<div><br/>
                 <b>Pipeline Job Name</b>: {JobName} <br/>
                 <b>Pipeline Run ID</b>: {JobRunId}<br/>
                 <b>Identified At</b>: {IdentifiedTime}<br/>
            </div>)
        }
        return elements
    }

    render() {
        const selectedNotificationType = this.props.selectedView.notification_type;


        if (selectedNotificationType === "request") {
            return (
                <div id="qd-section__section" className="qd-section qd-grid-item">
                    <div className="qd-section_content settings-account">
                        <div className="qd-section-header">
                            <h4 className="qd-section-header-title">Invitation Request
                                From {this.props.selectedView.username}</h4>
                            {/* <small>35 days ago</small> */}
                        </div>
                        <div className="qd-section-body">
                            <div className="row row-sm">
                                <div className="col-md-12 pl-4">
                                    <h4 className="mb-0 text-secondary">Hello {localStorage.getItem('user_name')},</h4> <br />
                                    You have received a request from {this.props.selectedView.username} ({this.props.selectedView.email}) to join your team on Qualdo.<br /> <br />
                                    To add {this.props.selectedView.username} to your team(s), please click on the following link(s):<br /> <br />
                                    <button onClick={evt => this.updateRequestNotification(this.props.selectedView)}
                                        className="btn btn-secondary">Click here
                                    </button><br/>
                                    <br/>If you do not recognize this request or prefer not to add {this.props.selectedView.username}  to your Qualdo team, you may simply disregard this email.
                                    <br/><br/>Best regards, The Qualdo Team
                                </div>
                                {/* <div className="col-md-12 pl-4">
                                    <h4 className="mb-0 text-secondary">Hey there,</h4> <br/>
                                    {this.props.selectedView.username} ({this.props.selectedView.email}) would like to
                                    join your team on Qualdo.<br/> <br/>
                                    Click on the following links to add {this.props.selectedView.username} to the
                                    appropriate team(s).<br/> <br/>
                                    {localStorage.getItem('user_name')} <br/> <br/>
                                    If you do not wish to add this person to your Qualdo team, please disregard this
                                    email. <br/> <br/>
                                    To add {this.props.selectedView.username} to your Qualdo team, <br/> <br/>
                                    <button onClick={evt => this.updateRequestNotification(this.props.selectedView)}
                                            className="btn btn-secondary">Click here
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } 
        else if (selectedNotificationType === "request_auto_deleted") {
            return (
                <div id="qd-section__section" className="qd-section qd-grid-item">
                    <div className="qd-section_content settings-account">
                        <div className="qd-section-header">
                            <h4 className="qd-section-header-title">Request Auto Deleted
                                From {this.props.selectedView.username}</h4>
                            {/* <small>35 days ago</small> */}
                        </div>
                        <div className="qd-section-body">
                            <div className="row row-sm">
                                <div className="col-md-12 pl-4">
                                    <h4 className="mb-0 text-secondary">Hey there,</h4> <br/>
                                    {this.props.selectedView.username} ({this.props.selectedView.email}) request to join
                                    team auto deleted.<br/> <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            const selectedViewAdditionalInfo = this.props.selectedView.additional_info;

            if (selectedNotificationType === "Data Quality Error" || selectedNotificationType === "Model Error") {
                let alert_name;
                let attribute_size = 0;
                let is_dataset_error = true;
                let selectedViewAlertName = null;
                if (selectedViewAdditionalInfo !== undefined && selectedViewAdditionalInfo !== null) {
                    selectedViewAlertName = selectedViewAdditionalInfo.alert_name;
                }

                if (selectedViewAlertName !== undefined && selectedViewAlertName !== null &&
                    selectedViewAdditionalInfo !== null) {

                    alert_name = selectedViewAlertName;
                    if (alert_name !== null && alert_name !== undefined && alert_name.includes("gate")) {
                        alert_name = alert_name.replace('gate', '').trim();
                        if (ALERT_NAME_DESC[alert_name] !== undefined) {
                            alert_name = ALERT_NAME_DESC[alert_name];
                        }
                    }
                    let attribute_level_info = selectedViewAdditionalInfo.attribute_level_info;
                    if (attribute_level_info !== undefined) {
                        attribute_size = attribute_level_info.length
                    }
                    is_dataset_error = selectedViewAdditionalInfo.is_dataset_error;
                    // Identifying dataset error implementation not done before.
                    // So, response without is_dataset_error are all dataset errors
                    if (is_dataset_error === undefined) {
                        is_dataset_error = true;
                    }
                }

                let configuredQualityGate = "";
                if (selectedViewAlertName !== undefined && selectedViewAlertName !== null) {
                    configuredQualityGate = selectedViewAlertName.replace('gate', '');
                }

                let alertNameToBeShown = ""
                if (selectedViewAlertName !== undefined && selectedViewAlertName !== null) {
                    alertNameToBeShown = alert_name
                }
                
                let alert_type = ""
                if (selectedViewAdditionalInfo !== null && selectedViewAdditionalInfo.alert_computation_type !== null){
                    alert_type = selectedViewAdditionalInfo.alert_computation_type;
                }

                const is_user_defined = selectedViewAdditionalInfo !== null && selectedViewAdditionalInfo.is_ud_alert !== null ? selectedViewAdditionalInfo.is_ud_alert : "NA";
                const configuredMetricName = selectedViewAdditionalInfo !== null && selectedViewAdditionalInfo.metric_name !== null ? selectedViewAdditionalInfo.metric_name : "NA";
                let confThresholdToBeShown = selectedViewAdditionalInfo !== undefined ? selectedViewAdditionalInfo.expected_fill_rate : '';
                let computedMetricResValue = selectedViewAdditionalInfo !== undefined ? selectedViewAdditionalInfo.actual_fill_rate : '';
                computedMetricResValue = roundOffResult(computedMetricResValue);

                let computedDriftResValue = selectedViewAdditionalInfo !== undefined ? selectedViewAdditionalInfo.actual_metric_value : '';
                computedDriftResValue = roundOffResult(computedDriftResValue);

                const configuredCondition = selectedViewAdditionalInfo !== null && selectedViewAdditionalInfo.condition !== null ? selectedViewAdditionalInfo.condition : "";
                const eventTimeToShow = selectedViewAdditionalInfo !== null && selectedViewAdditionalInfo.event_time !== null ? selectedViewAdditionalInfo.event_time : "NA";
                const currRowCount = selectedViewAdditionalInfo !== undefined ? selectedViewAdditionalInfo.curr_row_count : '';
                const prevRowCount = selectedViewAdditionalInfo !== undefined ? selectedViewAdditionalInfo.prev_row_count : '';
                const currIncrement = selectedViewAdditionalInfo !== undefined ? selectedViewAdditionalInfo.incremental_row_count : '';
                const prevIncrement = selectedViewAdditionalInfo !== undefined ? selectedViewAdditionalInfo.prev_incremental_row_count : '';
                const averageRowCount = selectedViewAdditionalInfo !== undefined ? selectedViewAdditionalInfo.average_row_count : '';
                let scale = METRIC_SCALE[alertNameToBeShown];

                if(scale === undefined)
                {
                 scale = "%";
                }
                return (
                    <div id="qd-section__section"
                         key={this.props.selectedView.notification_id}
                         className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <h4 className="mb-0 text-secondary">
                                            {this.getNotificationSubheaderComponent()}
                                        </h4>
                                        <br/>
                                        { (is_user_defined === true) ?
                                            <>
                                                Alert Name: {alertNameToBeShown}<br/>
                                                Metric Name: {configuredMetricName}<br/>
                                                Alert Type: {ALERT_TYPE[alert_type]}<br/>
                                                Configured Threshold: {confThresholdToBeShown} {scale} <br/>
                                                Configured Quality Gate: {configuredMetricName}
                                                {configuredCondition}
                                                {confThresholdToBeShown} {scale} <br/>
                                                Current Metric Value: {computedMetricResValue} {scale}<br/>
                                                {/* Current Drift Value: {computedMetricResValue-confThresholdToBeShown} {scale}<br/> */}
                                            </>
                                            :
                                            <>
                                                <b>Environment:</b> {selectedViewAdditionalInfo.env_name}<br/>
                                                <b>Datasource:</b> {selectedViewAdditionalInfo.integration_name}<br/>
                                                <b>Dataset:</b> {selectedViewAdditionalInfo.data_set_name}<br/><br/>
                                                <br/>
                                                    <h4 className="mb-0 text-secondary">
                                                        {
                                                            <div>Metric</div>
                                                        }
                                                    </h4>
                                                <br/>
                                                Metric Name: {alertNameToBeShown}<br/>
                                                Configured Threshold: {confThresholdToBeShown} {scale} <br/>
                                                Configured Quality Gate: {configuredQualityGate} {configuredCondition} {confThresholdToBeShown} {scale} <br/>
                                            </>
                                        }
                                        Event Time: {eventTimeToShow}<br/><br/>
                                        {
                                            (is_dataset_error === true) ?
                                                <>
                                                    <br/>
                                                    <h4 className="mb-0 text-secondary">
                                                        {
                                                            <div>Dataset Level Error</div>
                                                        }
                                                    </h4>
                                                    <br/>
                                                    {/* {
                                                        (selectedNotificationType !== "Model Error") ?
                                                            <>
                                                                {this.getModelorDataset()["key"]}: {this.getModelorDataset()["value"]}<br/>
                                                            </>
                                                            : ''
                                                    } */}
                                                    { (is_user_defined === true)?
                                                        (alert_type === "drift_value" || alert_type === "drift_attribute_level") ?
                                                            <>
                                                                Current Metric
                                                                Value: {computedMetricResValue} {scale}<br/>
                                                                Current Drift
                                                                Value: {computedDriftResValue} {scale}<br/>
                                                            </>
                                                            :
                                                             <>
                                                                 Current Metric Value: {computedMetricResValue} {scale}<br/>
                                                             </>
                                                        :
                                                        (alertNameToBeShown === ROW_COUNT_NAME) ?
                                                        <>

                                                            Current Metric Value: {computedMetricResValue} {scale} <br/>
                                                            Total Row Count in Previous Refresh: {prevRowCount} rows<br/>
                                                            Total Row Count in Current Refresh: {currRowCount} rows<br/>
                                                            Number of New Rows in Previous Refresh: {prevIncrement} rows<br/>
                                                            Number of New Rows in Current Refresh: {currIncrement} rows<br/>
                                                            Average of New Rows : {roundOffResult(averageRowCount)} {scale} <br/>
                                                        </>
                                                        :
                                                        <>
                                                            Current Metric Error
                                                            Value: {computedMetricResValue} {scale}<br/>
                                                        </>
                                                    }
                                                </>
                                                : ''
                                        }
                                        {
                                            (attribute_size > 0) ?
                                                <>
                                                    <br/> <h4 className="mb-0 text-secondary">{<div>Attribute Level Errors</div>}</h4>
                                                    {this.getAttributeNotification(alert_name, is_dataset_error, is_user_defined, alert_type)}
                                                </>
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                );
            }
            else if (selectedNotificationType === "Limit Exceeded Alert") {
                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <h4 className="mb-0 text-secondary">Hi {this.props.selectedView.username},</h4>
                                        <br/><br/>
                                        Your {selectedViewAdditionalInfo.type} limit for current plan has
                                        exceeded. <br/><br/>
                                        Click on the following links to upgrade your plan.<br/><br/>
                                        <button onClick={evt => this.redirectPlanPage(this.props.selectedView)}
                                                className="btn btn-secondary">Upgrade Plan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedNotificationType === "Schema Drift") {
                let added_items = selectedViewAdditionalInfo.increased_column;
                if (added_items === undefined || added_items === null) {
                    added_items = [];
                }
                let deleted_items = selectedViewAdditionalInfo.decreased_column;
                if (deleted_items === undefined || deleted_items === null) {
                    deleted_items = [];
                }
                let changed_items = selectedViewAdditionalInfo.datatype_change;
                if (changed_items === undefined || changed_items === null) {
                    changed_items = [];
                }

                let max_alert_limit=selectedViewAdditionalInfo.max_alert_limit
                let alert_limit_reached_notification_content = "(The above schema drift has been detected repeatedly in the last " + max_alert_limit + " refreshes.  This will be considered as an expected change and notifications will not be sent in the future)"

                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <h4 className="mb-0 text-secondary"> Notification generated based on the schema drift detected in - </h4>
                                        <br/><br/>
                                        <div> <b>Environment: </b> {selectedViewAdditionalInfo.env_name} </div>
                                        <div> <b>Datasource: </b> {selectedViewAdditionalInfo.integration_name} </div>
                                        <div> <b>Dataset: </b> {selectedViewAdditionalInfo.dataset_id}<br/><br/> </div>
                                        {selectedViewAdditionalInfo.increased_column.length > 0 ?  <div> <b>Drift Type:</b>   Attribute added<br/><br/>  {added_items.map((x,index) => (<ol key={index} ><b>Attribute:</b> {x.attribute_name} <br/> <b>Attribute Datatype:</b> {x.attribute_type} <br/> <b>Identified Time:</b> {selectedViewAdditionalInfo.identified_time} </ol>))} </div> : ''
                                        }
                                        {selectedViewAdditionalInfo.decreased_column.length > 0 ? <div> <b>Drift Type:</b>   Attribute Deleted <br/> <br/> {deleted_items.map((x,index) => (<ol key={index} ><b>Attribute:</b> {x.attribute_name} <br/> <b>Attribute Datatype:</b> {x.attribute_type} <br/> <b>Identified Time:</b> {selectedViewAdditionalInfo.identified_time} </ol>))} </div> : ''
                                        }
                                        {selectedViewAdditionalInfo.datatype_change.length > 0 ? <div> <b>Drift Type:</b>    Datatype Changed in attributes <br/> <br/> {changed_items.map((x,index) => (<ol key={index} ><b>Attribute:</b> {x.attribute_name} <br/> <b>Current Datatype:</b> {x.current_data_type} <br/> <b>Previous Datatype:</b> {x.prev_data_type} <br/> <b>Identified Time:</b> {selectedViewAdditionalInfo.identified_time} <br/> {x.alert_limit_reached === true ? <div> <br/> {alert_limit_reached_notification_content} <br/><br/> </div>: ''} </ol>))} </div> : ''
                                        }
                                        <div> For the attributes that have been identified as having a change in their datatype, the 'Current Datatype' will be considered for Data Quality Profiling. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedNotificationType === "Payment Failure Alert") {
                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <h4 className="mb-0 text-secondary">Hi {this.props.selectedView.username},</h4>
                                        <br/><br/>
                                        Your payment for order #{selectedViewAdditionalInfo.transaction_id} not
                                        completed due to following reason <br/><br/>
                                        Reason for this failure is {selectedViewAdditionalInfo.error_msg}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedNotificationType === "Pipeline Configuration Alert") {
                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <h4 className="mb-0 text-secondary"> We have identified an issue preventing the monitoring of the following pipeline source configuration:</h4><br/>
                                        <b>Pipeline Environment Name </b>: {selectedViewAdditionalInfo.pipeline_integration_environment !== null ? selectedViewAdditionalInfo.pipeline_integration_environment : "NA"}<br/>
                                        <b>Pipeline Type</b>: {selectedViewAdditionalInfo.pipeline_type !== null ? selectedViewAdditionalInfo.pipeline_type : "NA"}<br/>
                                        <b>Pipeline Source Name</b>: {selectedViewAdditionalInfo.pipeline_integration_name !== null ? selectedViewAdditionalInfo.pipeline_integration_name : "NA"}<br/>
                                        <b>Reason</b>: Unable to connect to the above pipeline source. Please verify the credentials or permissions associated with your pipeline source/workspace. <br/>
                                        <b>Identified on</b> : {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}<br/>
                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedNotificationType === "Pipeline Configuration Failure Detected") {
                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <h4 className="mb-0 text-secondary"> A run failure has been detected in the following pipeline:</h4>
                                        <b>Pipeline Environment Name </b>: {selectedViewAdditionalInfo.pipeline_integration_environment !== null ? selectedViewAdditionalInfo.pipeline_integration_environment : "NA"}<br/>
                                        <b>Pipeline Type</b>: {selectedViewAdditionalInfo.pipeline_type !== null ? selectedViewAdditionalInfo.pipeline_type : "NA"}<br/>
                                        <b>Pipeline Source Name</b>: {selectedViewAdditionalInfo.pipeline_integration_name !== null ? selectedViewAdditionalInfo.pipeline_integration_name : "NA"}<br/>
                                        <b>Pipeline Job Run Status</b>: {selectedViewAdditionalInfo.pipeline_run_status !== null ? selectedViewAdditionalInfo.pipeline_run_status : "NA"}<br/>
                                        <br/> <h4 className="mb-0 text-secondary">{<div>Run Details:</div>}</h4>
                                        {selectedViewAdditionalInfo.pipeline_job_run_data !== null ? this.getPoJobRunIdWithIdentifiedTime(selectedViewAdditionalInfo.pipeline_job_run_data) : "NA"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedNotificationType === "Datasource Configuration Alert") {
                if (selectedViewAdditionalInfo.alert_name === "Partition Identifier Not Found") {
                    return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                    <h4 className="mb-0 text-secondary">The following datasets in your configured datasource {selectedViewAdditionalInfo.integration_name} have missing partition identifier</h4><br/>
                                        Datasource: {selectedViewAdditionalInfo.integration_name !== null ? selectedViewAdditionalInfo.integration_name : "NA"}<br/>
                                        Dataset: {selectedViewAdditionalInfo.data_set_name !== null ? selectedViewAdditionalInfo.data_set_name : "NA"}<br/>
                                        Partition Identifier: {selectedViewAdditionalInfo.column_name !== null ? selectedViewAdditionalInfo.column_name : "NA"}<br/>
                                        Identified on : {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}<br/>
                                    <p>The configured 'Partition Identifier' is not found in the dataset. 
                                        The dataset, as a whole, will be monitored without any logical partitions. 
                                        Please reconfigure the datasource in order to modify the partition identifier.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                }
                else if (selectedViewAdditionalInfo.alert_name === "Datatype Mismatch Found") {
                    return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                    <h4 className="mb-0 text-secondary">Datatype mismatch for attribute name {selectedViewAdditionalInfo.attribute_name} configured as 
                                    incremental data identifier in dataset {selectedViewAdditionalInfo.data_set_name}</h4><br/>
                                        Datasource: {selectedViewAdditionalInfo.integration_name !== null ? selectedViewAdditionalInfo.integration_name : "NA"}<br/>
                                        Dataset: {selectedViewAdditionalInfo.data_set_name !== null ? selectedViewAdditionalInfo.data_set_name : "NA"}<br/>
                                        Attribute: {selectedViewAdditionalInfo.attribute_name !== null ? selectedViewAdditionalInfo.attribute_name : "NA"}<br/>
                                        Error: {selectedViewAdditionalInfo.error !== null ? selectedViewAdditionalInfo.error : "NA"}<br/>
                                        Identified on : {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}<br/>
                                    <p>The configured 'Incremental Data Identifier' of the dataset has mismatch in datatype.
                                        Expected incremental data identifier datatype is 'Timestamp/DateTime'. 
                                        Qualdo will handle the refresh using Qualdo's default way.
                                        Please reconfigure the datasource in order to modify the incremental data identifier.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                }
                else if (selectedViewAdditionalInfo.alert_name === "Incremental Data Identifier Not Found") {
                    return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                    <h4 className="mb-0 text-secondary">The following datasets in your configured datasource {selectedViewAdditionalInfo.integration_name} have missing incremental data identifier</h4><br/>
                                        Datasource: {selectedViewAdditionalInfo.integration_name !== null ? selectedViewAdditionalInfo.integration_name : "NA"}<br/>
                                        Dataset: {selectedViewAdditionalInfo.data_set_name !== null ? selectedViewAdditionalInfo.data_set_name : "NA"}<br/>
                                        Error: {selectedViewAdditionalInfo.error !== null ? selectedViewAdditionalInfo.error : "NA"}<br/>
                                        Identified on : {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}<br/>
                                    <p>The configured 'Incremental Data Identifier' is not found in the dataset.
                                        Qualdo will monitor the data quality and data reliability of the refresh using the default way.
                                        Please reconfigure the datasource in order to modify the incremental data identifier.
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                }
                else if (selectedViewAdditionalInfo.alert_name === "Error in Starting Index") {
                    if (selectedViewAdditionalInfo.error_type === "Datatype Mismatch") {
                        return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                    <h4 className="mb-0 text-secondary"> The following dataset has 'Starting Index' which is not a Date or Datetime</h4><br/>
                                        Datasource: {selectedViewAdditionalInfo.integration_name !== null ? selectedViewAdditionalInfo.integration_name : "NA"}<br/>
                                        Dataset: {selectedViewAdditionalInfo.data_set_name !== null ? selectedViewAdditionalInfo.data_set_name : "NA"}<br/>
                                        Incremental Data Identifier: {selectedViewAdditionalInfo.column_name !== null ? selectedViewAdditionalInfo.column_name : "NA"}<br/>
                                        Starting Index: {selectedViewAdditionalInfo.starting_index !== null ? selectedViewAdditionalInfo.starting_index : "NA"}<br/>
                                        Error: {selectedViewAdditionalInfo.error !== null ? selectedViewAdditionalInfo.error : "NA"}<br/>
                                        Identified on : {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}<br/>
                                    <p>Qualdo will not profile and monitor the data quality and data reliability of the dataset {selectedViewAdditionalInfo.data_set_name}. 
                                        Please reconfigure with the correct Starting Index to profile and monitor the data quality and data reliability of the dataset {selectedViewAdditionalInfo.data_set_name}.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                }
                    else if(selectedViewAdditionalInfo.error_type === "Data Not Found For Value") {
                        return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                    <h4 className="mb-0 text-secondary"> No records found for the following dataset configured with 'Starting Index'</h4><br/>
                                        Datasource: {selectedViewAdditionalInfo.integration_name !== null ? selectedViewAdditionalInfo.integration_name : "NA"}<br/>
                                        Dataset: {selectedViewAdditionalInfo.data_set_name !== null ? selectedViewAdditionalInfo.data_set_name : "NA"}<br/>
                                        Incremental Data Identifier: {selectedViewAdditionalInfo.column_name !== null ? selectedViewAdditionalInfo.column_name : "NA"}<br/>
                                        Starting Index: {selectedViewAdditionalInfo.starting_index !== null ? selectedViewAdditionalInfo.starting_index : "NA"}<br/>
                                        Error: {selectedViewAdditionalInfo.error !== null ? selectedViewAdditionalInfo.error : "NA"}<br/>
                                        Identified on : {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}<br/>
                                    <p>Qualdo will not profile and monitor the data quality and data reliability of the dataset {selectedViewAdditionalInfo.data_set_name}. 
                                        Please reconfigure with the correct Starting Index to profile and monitor the data quality and data reliability of the dataset {selectedViewAdditionalInfo.data_set_name}.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                }
                    else if(selectedViewAdditionalInfo.error_type === "Data Not Found For Query") {
                        return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                    <h4 className="mb-0 text-secondary"> No records found for the following dataset configured with 'Starting Index' by query</h4><br/>
                                        Datasource: {selectedViewAdditionalInfo.integration_name !== null ? selectedViewAdditionalInfo.integration_name : "NA"}<br/>
                                        Dataset: {selectedViewAdditionalInfo.data_set_name !== null ? selectedViewAdditionalInfo.data_set_name : "NA"}<br/>
                                        Starting Index Query: {selectedViewAdditionalInfo.starting_index_query !== null ? selectedViewAdditionalInfo.starting_index_query : "NA"}<br/>
                                        Error: {selectedViewAdditionalInfo.error !== null ? selectedViewAdditionalInfo.error : "NA"}<br/>
                                        Identified on : {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}<br/>
                                    <p>Qualdo will not profile and monitor the data quality and data reliability of the dataset {selectedViewAdditionalInfo.data_set_name} with specified query.
                                        Please reconfigure with the correct Starting Index Query to profile and monitor the data quality and data reliability of the dataset {selectedViewAdditionalInfo.data_set_name}.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                }
                    else if(selectedViewAdditionalInfo.error_type === "Invalid Query") {
                        return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                    <h4 className="mb-0 text-secondary"> No records found for the following dataset configure with 'Starting Index'</h4><br/>
                                        Datasource: {selectedViewAdditionalInfo.integration_name !== null ? selectedViewAdditionalInfo.integration_name : "NA"}<br/>
                                        Dataset: {selectedViewAdditionalInfo.data_set_name !== null ? selectedViewAdditionalInfo.data_set_name : "NA"}<br/>
                                        Starting Index Query: {selectedViewAdditionalInfo.starting_index_query !== null ? selectedViewAdditionalInfo.starting_index_query : "NA"}<br/>
                                        Error: {selectedViewAdditionalInfo.error !== null ? selectedViewAdditionalInfo.error : "NA"}<br/>
                                        Identified on : {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}<br/>
                                    <p>Qualdo will not profile and monitor the data quality and data reliability of the dataset {selectedViewAdditionalInfo.data_set_name} with specified query.
                                        Please reconfigure with the correct Starting Index Query to profile and monitor the data quality and data reliability of the dataset {selectedViewAdditionalInfo.data_set_name}.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                }
                }
                else { 
                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <p>Datasource: {selectedViewAdditionalInfo ? selectedViewAdditionalInfo.datasource : ""}<br></br>
                                            Dataset: {selectedViewAdditionalInfo ? selectedViewAdditionalInfo.data_set_name : ""}<br></br>
                                            Configured incremental data
                                            identifier: {selectedViewAdditionalInfo ? selectedViewAdditionalInfo.date_column : ""}<br></br>
                                            Dataset refreshed
                                            time: {selectedViewAdditionalInfo ? selectedViewAdditionalInfo.refreshed_time : ""}<br></br>
                                        </p>
                                        <p>The configured incremental data identifier for the dataset is missing in
                                            the refresh. We will be handling the refresh using Qualdo's default way
                                            until the incremental data identifier is not available in the dataset.
                                            Please reconfigure the datasource in case the incremental data identifier
                                            is modified.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                }
            }
            else if (selectedNotificationType === "Missing Dataset") {
                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <h4 className="mb-0 text-secondary">Dataset versions incorrect</h4><br/>
                                        Datasource: {selectedViewAdditionalInfo.integration_name !== null ? selectedViewAdditionalInfo.integration_name : "NA"}<br/>
                                        Dataset: {selectedViewAdditionalInfo.data_set_name !== null ? selectedViewAdditionalInfo.data_set_name : "NA"}<br/>
                                        Identified
                                        on: {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedNotificationType === "Model Monitoring Metrics Alert") {
                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        Datasource: {selectedViewAdditionalInfo.datasource !== null ? selectedViewAdditionalInfo.datasource : "NA"}<br/>
                                        Dataset: {selectedViewAdditionalInfo.data_set_name !== null ? selectedViewAdditionalInfo.data_set_name : "NA"}<br/>
                                        Identified string
                                        columns: {selectedViewAdditionalInfo.error_columns !== null ? selectedViewAdditionalInfo.error_columns.map((data, index)=><>
                                            {data}{index === selectedViewAdditionalInfo.error_columns.length-1 ? ".":", "}
                                        </>) : "NA"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedNotificationType === "Datasets deletion info") {
                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        The following datasets are missing

                                        {this.getNotificationFullView(selectedViewAdditionalInfo, this.props.selectedView)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedNotificationType === "Model Dataset Not Refreshed") {
                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <h4 className="mb-0 text-secondary">We identified that some of the dataset(s) associated with one of your configured models have not been refreshed. The associated charts for those dataset(s) will not be displayed in the Monitor page.</h4><br/>
                                        Datasource: {selectedViewAdditionalInfo.integration_name !== null ? selectedViewAdditionalInfo.integration_name : "NA"}<br/>
                                        Model: {selectedViewAdditionalInfo.model_name !== null ? selectedViewAdditionalInfo.model_name : "NA"}<br/>
                                        Identified on: {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}<br/>
                                        <br/> <h4 className="mb-0 text-secondary">{<div>List of Datasets not refreshed:</div>}</h4>
                                        {this.getModelDatasetsNotRefreshed()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedNotificationType === "No dataset found") {
                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <h4 className="mb-0 text-secondary">We identified that there is no dataset found in the configured datasource.</h4><br/>
                                        Datasource: {selectedViewAdditionalInfo.integration_name !== null ? selectedViewAdditionalInfo.integration_name : "NA"}<br/>
                                        Identified on : {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}<br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedNotificationType === "Model dataset has null values") {
                return (<div id="qd-section__section" className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <h4 className="mb-0 text-secondary">We identified that there are empty/null values present in the dataset(s) associated with one of your configured models.The model performance metrics are not computed and hence the associated charts for the model will not be displayed in the Monitor page.</h4><br/>
                                        Datasource: {selectedViewAdditionalInfo.integration_name !== null ? selectedViewAdditionalInfo.integration_name : "NA"}<br/>
                                        Model: {selectedViewAdditionalInfo.model_name !== null ? selectedViewAdditionalInfo.model_name : "NA"}<br/>
                                        Identified on : {selectedViewAdditionalInfo.identified_time !== null ? selectedViewAdditionalInfo.identified_time : "NA"}<br/>
                                        <br/> <h4 className="mb-0 text-secondary">{<div>List of Datasets with Missing Data:</div>}</h4>
                                        {this.getModelDatasetsNotRefreshed()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else if (selectedNotificationType === "Custom"){
                let items = selectedViewAdditionalInfo.section_1_items;
                if (items === undefined || items === null) {
                    items = [];
                }

                return (
                    <div id="qd-section__section"
                         key={this.props.selectedView.notification_id}
                         className="qd-section qd-grid-item">
                        <div className="qd-section_content settings-account">
                            {this.getNotificationHeaderComponent()}
                            <div className="qd-section-body">
                                <div className="row row-sm">
                                    <div className="col-md-12 pl-4">
                                        <h4 className="mb-0 text-secondary">
                                            {this.getNotificationSubheaderComponent()}
                                        </h4>
                                        <br/>

                                        {items.map(x => (<>{x} <br/> </>))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                );
            }
            else {
                return ''
            }
        }

    }
}

export default viewNotification;
