import React from 'react';
import CopyRights from '../../components/copyrights';
import {browserHistory} from 'react-router';
import { faEnvelope, faFlag, faBuilding, faPhoneAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { iam_ip } from "../../utils/ip_details";
import { call_post_api }  from "../../utils/event_handling";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { encryptForLocalUsage } from "../../utils/common_utils";
import { LOCAL_STORE_KEY } from "../../utils/constant"

class GoogleSignup extends React.Component {
    constructor(props) {
        super(props);
        this.googleSignUp = this.googleSignUp.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.state = {
            name:localStorage.getItem('user_name'),
            email: localStorage.getItem('emailId'),
            errors: null
        };
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    hideAlert() {
        this.setState({
          errors: null,
          hideSubmitButton: false
        });
    }

    googleSignUp(event) {
        this.disableButton();
        const {email,phone,company} =event
        const google_data = {email: email, phone: phone, company: company};
        const qualdoPlan = localStorage.getItem("qualdoPlan");
        const qualdoPlanCycle = localStorage.getItem("qualdoPlanCycle");
        const qualdoEdition = localStorage.getItem("qualdoEdition");
        if (qualdoPlan !== undefined && qualdoPlan !== null) {
            google_data["plan"] = qualdoPlan;
        }

        if (qualdoPlanCycle !== undefined && qualdoPlanCycle !== null) {
            google_data["cycle"] = qualdoPlanCycle;
        }

        if (qualdoEdition !== undefined && qualdoEdition !== null) {
            google_data["edition"] =  qualdoEdition;
        }
            call_post_api(iam_ip, 'update_google_user', google_data).then((response) => {
                if(response.code === 200) {
                    const now = new Date();
                    const item = {
                                value: response['response']['auth_token'],
                                user_id : response['response']['user_id'],
                                team_id: response['response']['team_id'],
                                expiry: now.getTime() + 150*60000
                            };
                    setTimeout(() => browserHistory.push('/'), 9000000);
                    localStorage.setItem('sessionToken', JSON.stringify(item));
                    let teamID = response['response']['team_id'];
                    localStorage.setItem('team_id', teamID);
                    localStorage.setItem('user_name', response['response']['user_name']);
                    localStorage.setItem('user_id', response['response']['user_id']);
                    localStorage.setItem("org_id", response['response']['org_id']);
                    localStorage.setItem('user_signup_type', response['response']['user_signup_type']);
                    localStorage.setItem("permissions", response["response"]["permissions"]);
                    localStorage.setItem("DataSetupStatus", false);
                    const accountFeatures = response["account_features"]["plan_details"];

                    /**
                    * Storing warning_message key into accountFeature and update buffer and expire key name
                    */
                    accountFeatures['warning_message'] = response["account_features"]["warning_message"]
                    accountFeatures['isPlanExpired'] = accountFeatures["is_plan_expired"]

                    // Encrypt User Details
                    let encrypt_data = encryptForLocalUsage(accountFeatures)
                    localStorage.setItem(LOCAL_STORE_KEY, encrypt_data)

                    // localStorage.setItem("warning_message", response["account_features"]["warning_message"]);
                    // localStorage.setItem("isPlanExpired",accountFeatures["is_plan_expired"])

                    localStorage.setItem("plan_details", JSON.stringify(accountFeatures))            
                    
                    let feature_access = response['response']["feature_access"];
                    const encryptedFA = encryptForLocalUsage(feature_access);
                    localStorage.setItem('featureAccess', encryptedFA);
                    if (feature_access["skip_setup"] === true)
                    {
                        browserHistory.push("/quick-start");
                    }
                    else{
                        browserHistory.push("/setup");
                    }
                } else if(response.code === 401 && response.message === "invalid_plan"){
                    browserHistory.push("/invalid-request");
                }else {
                    this.setState({errors:response.message, hideSubmitButton: false});
                }
            });
    }
    render() {
        let prefilled_user_name = localStorage.getItem('user_name');
        let prefilled_email = localStorage.getItem('emailId');
        if (prefilled_email === "undefined" || prefilled_email === null || prefilled_user_name === "undefined" || prefilled_user_name === null){
            window.location = '/';
            return null;
        }

        const loginSchema = yup.object({
            company: yup.string().min(2,"Company must be at least 2 characters").required("Company is a required field"),
            phone: yup.mixed().required("Mobile number is a required field").test(
              'mobile number test',
              'Mobile Number is not valid. Enter valid Country code',
              function test(value) {
                  try{
                        const phoneUtil = PhoneNumberUtil.getInstance()
                        if(value[0] !== '+'){
                        value = '+' + value}
                        const val = phoneUtil.parse(value)
                        let result  = phoneUtil.isValidNumber(val)
                        return result
                      }
                    catch(error){
                    console.error(error)
                    return false
                    }
                }
            )
        });
        return (
            <div className="prelogin-content">
                <h4 className="login-welcome-text mt-0">Get Started in 30 seconds</h4>
                <div className="credit-card-info">
                    <h5>No credit card required</h5>
                    <p>Try it free for 14 days and monitor as many models and data as you like.</p>
                </div>
                { this.state.errors ?
                        <div className="alert alert-sm has-close alert-danger alert-dismissible fade show modal-h" role="alert">
                             {this.state.errors}
                            <button type="button" className="close close-sm" onClick={() => this.hideAlert()} data-dismiss="alert" aria-label="Close"/>
                        </div> : ""
                }
                <Formik
                        validationSchema={loginSchema}
                        onSubmit={this.googleSignUp}
                        initialValues={{
                            name: localStorage.getItem('user_name'),
                            email: localStorage.getItem('emailId'),
                            company: '', phone: ''
                        }}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                    }) => (
                <Form className="login-form" name="google_signup_complete" onSubmit={handleSubmit} method="post">
                    <div className="prelogin-form-container">
                        <Form.Group controlId="name">
                            <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faFlag}/>
                                    </i>
                                <Form.Control
                                        className="form-group"
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={!!errors.name}/>
                                <Form.Control.Feedback type="invalid">
                                       { errors.name }
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="email">
                            <InputGroup bsPrefix="icon-input-group">
                                <i>
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                </i>
                                <Form.Control
                                        className="form-group"
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Email"
                                        value={values.email}
                                        readonly />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="company">
                            <InputGroup bsPrefix="icon-input-group">
                                <i>
                                    <FontAwesomeIcon icon={faBuilding}/>
                                </i>
                                <Form.Control
                                    className="form-group"
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Company"
                                    value={values.company}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.company && touched.company}
                                />
                                <Form.Control.Feedback type="invalid">
                                       { touched.company && errors.company }
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="phone">
                            <InputGroup bsPrefix="icon-input-group">
                                <i>
                                    <FontAwesomeIcon icon={faPhoneAlt}/>
                                </i>
                                <Form.Control
                                    className="form-group"
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Mobile Number"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.phone && touched.phone}
                                />
                                <Form.Control.Feedback type="invalid">
                                       { touched.phone && errors.phone }
                                </Form.Control.Feedback>
                            </InputGroup>
                            <Form.Text className="text-muted font-italic">Please provide Country code</Form.Text><br/>
                        </Form.Group>
                        <CopyRights classDetails="text-copyright text-left" text="By signing up, you agree to the"/>
                    </div>
                    <div className="form-action-btn prelogin-btn-group">
                        <a href="/" className="btn btn-icon btn-circle btn-outline-secondary"><i><FontAwesomeIcon icon={faArrowLeft}/></i> Back</a>
                        <button type="submit" disabled={this.state.hideSubmitButton} onClick={() => this.props.setEmail(this.state.email)} className="btn btn-circle btn-secondary mx-1"><i className="icon">
                        </i>Submit</button>
                        {
                            this.state.hideSubmitButton ? <button type="button" className="btn loading-btn loading-btn--pending"></button> : ''
                        }
                    </div>
                </Form> )}
            </Formik>
                <a className="h6" href="/"><u>Already have an account?</u></a>
            </div>
        );
    }
}

export default GoogleSignup;
