import React from 'react';
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { CopyRightsFooter } from '../components/copyrights';
import ErrorHandler from '../components/error_500';
import * as d3 from 'd3';
import {postMixpanel} from '../mixpanel';
// import { browserHistory } from 'react-router';
import DrxReportFullScreen from "./components/dqReportFullScreen"
import {logoutPageRedirect} from "../utils/common_utils";
import PipelinePerformance from "./components/pipeline_performance";

import {
    getPerformanceChartData,
    getModelErrTableData,
    setShowMoreData,
    convertModelDetails,
    getModelErrorsOfPerformanceCharts,
    getMLModelOptionsForGivenMapping,
    mapModelToDataSource,
    getModelPerfListViewTableData,
    setDQShowMoreData,
    getMonitorCompareViewTableData,
    getFeatureAccess,
    getModelNameMappedCharts, getTotalShowMoreDataPointsCount,
    extractDatasetInfoForMonitorListView,
  //  getMonitorTabDQPListViewTableData,

} from "../utils/common_utils";
import { DQ_MONITOR_SECTIONS, IS_NONE_CHECK, IS_SAAS_EDITION } from "../utils/constant";
import { connect } from "react-redux";
import { getApi, postUIlogs} from "../utils/event_handling";
import MonitorDQTabs from "./monitorDQTabs";
import MonitorMPTabs from "./monitorMPTabs";
import MonitorERTabs from "./monitorERTabs";
import MonitorDQPTabs from  "./monitorDQPTabs";
import ModelBias from "./modelBias";
import _ from "lodash";
import {PIPELINE_USER} from "../utils/constant";

 import store from "../redux/store";
import Load from '../components/loadAction';
 import {
     preFetchMonitorPreview,
     preFetchMonitorInfo,
      preFetchMonitorModelInfo,
      preFetchModelDetails,
     preFetchMetrics,
     // preFetchFollowAttributes,
     //fromMonitorData,
      getLastProfilingTime,
      preFetchMonitorListViewData
     // preFetchMonitorMetaData
 } from "../utils/monitorDataFetch";

 import {
     // preFetchRelationshipData,
     // preFetchMetaData,
     // preFetchUserNotifications,
    // prefetchDatasetDetails, prefetchRecencyDetails, prefetchRecentHistory,
      prefetchModelABData,
    // prefetchDataSize,
 //    preFetchMetadataDetails
 } from "../utils/dataFetch";

import {
    setDataSource,
    setMlDataSource,
    addRelationship,
    addMetaData,
    addMLModelMapping,
    setMLModel,
    addDataSource,
    addInfo,
    addLastProfilingTime,
    setEndDate,
    setStartDate,
    addDiscoveryStatus,
    addProcessingStatus,
    addEnvironment,
    addEnvironmentDataSourceMapping,
    addDataSourceDataSetMapping,
    addDataSetAttributeMapping,
    setAttribute,
    setDataSet,
    addMonitorPreview,
    addPerformancePreview,
    addCompletenessDataSetInfo,
    addCompletenessAttributeInfo,
    addTimelinessDataSetInfo,
    addTimelinessAttributeInfo,
    addConformityDataSetInfo,
    addConformityAttributeInfo,
    addAccuracyDataSetInfo,
    addAccuracyAttributeInfo,
    addDriftDataSetInfo,
    addDriftAttributeInfo,
    addConsistencyDataSetInfo,
    addConsistencyAttributeInfo,
    addMLDataSetInfo,
    addMLAttributeInfo,
    addMLModel,
    addModelPerformance,
    addModelDetails,
    addFollowAttributes,
    addMonitorDQListViewData,
    addMetrics,
    // addUserNotificationToState,
    //addDatasetVersions,
    addUniquenessDataSetInfo,
    addUniquenessAttributeInfo,
    //addRecencyDetails,
    //addRecentHistory,
    //addDataSize,
    addRecencyPreview,
    prefetchMonitorState,
    noIntegration,
    storeMetadataDetails,
    mappedDatasetInfo,
    listViewTableData,
    setModelABDataToList
} from "../redux/actions";

import {
        getMetaDatas,
        // getMappedMetadatas,
        // getFollowAttributes
} from "../utils/monitorEventHandling"
// import { get_api_call_cnt, get_api_call_complete_cnt, post_api_call_cnt, post_api_call_complete_cnt } from "../utils/event_handling";

const dq_chartview_metrics =  [{"label":"Data Drift","value":"drift"},{"label":"Data Completeness","value":"completeness"},{"label":"Data Uniqueness","value":"uniqueness"},{"label":"Data Timeliness","value":"timeliness"},{"label":"Data Accuracy","value":"accuracy"},{"label":"Data Conformity","value":"conformity"},{"label":"Data Consistency","value":"consistency"}]

class Monitor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.pre_load = true
        // let tab = "modelPerf";
        let tab = "pipeline";
        let childTab = null;
        let additionalInfoFromParam = null;

        // Following line is for removing additional parameters from url
        // browserHistory.replace("/monitor");
        if (this.props.activeTabKey !== undefined) {
            tab = this.props.activeTabKey
        }

        if (this.props.innerTab !== undefined) {
            childTab = this.props.innerTab
        }
        localStorage.setItem("additionalInfoFromParam", "");
        if (this.props.additionalInfo !== undefined) {
            additionalInfoFromParam = this.props.additionalInfo
            localStorage.setItem("additionalInfoFromParam", additionalInfoFromParam);
        }


        if (this.props.route !== undefined && this.props.route.activeTabKey !== undefined &&
            this.props.route.activeTabKey !== null) {
            tab = this.props.route.activeTabKey;
        }

        tab = "dq";

         let hideMonitorModelTab = true;
         let featureAccess = getFeatureAccess()
         if (!IS_NONE_CHECK.includes(featureAccess)) {
            const monitorModel = featureAccess["models"];
            hideMonitorModelTab = monitorModel === false;
         }

          hideMonitorModelTab = false;


        let enableDQReportsTab = false;
        if (!IS_NONE_CHECK.includes(featureAccess)) {
            const DQReportsTab = featureAccess["dq-reports"];
            enableDQReportsTab = DQReportsTab === true;
        }
        enableDQReportsTab = true;

        tab = hideMonitorModelTab ? "dq" : tab;
        this.hideContent = this.hideContent.bind(this);
        this.getModelErrTableData = getModelErrTableData.bind(this);
        this.getModelErrorsOfPerformanceCharts = getModelErrorsOfPerformanceCharts.bind(this);
        this.setShowMoreData = setShowMoreData.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.getPerformanceChartData = getPerformanceChartData.bind(this);
        this.getData = this.getData.bind(this);
        this.getApi = getApi.bind(this);
        this.drxReport = this.drxReport.bind(this);
        this.closedrxReportfullscreen = this.closedrxReportfullscreen.bind(this);

        this.convertModelDetails = convertModelDetails.bind(this);
        this.getMetaDatas = getMetaDatas.bind(this);
        this.setCustomFilterValues = this.setCustomFilterValues.bind(this);
         this.preFetchMonitorInfo = preFetchMonitorInfo.bind(this);
         this.preFetchMonitorListViewData = preFetchMonitorListViewData.bind(this);
        // this.getMappedMetadatas = getMappedMetadatas.bind(this);
        // this.getFollowAttributes = getFollowAttributes.bind(this);
        let reduxPerformanceResult = this.props.monitorModule.mlModelPerformanceInfo;
        let resultList = Object.keys(reduxPerformanceResult).map(x => reduxPerformanceResult[x]);

        const metaDataMapping = this.props.dataModule.metaData;
        const mappedDatasetInfo = extractDatasetInfoForMonitorListView(metaDataMapping);



        let timelinessShowMore = setDQShowMoreData(this.props.monitorModule.timelinessDataSetInfo,
            this.props.monitorModule.timelinessAttributeInfo, this.props.dataModule,
            null, DQ_MONITOR_SECTIONS.TIMELINESS);

        const propsDataModule = this.props.dataModule;


        let fullModelInfo = []

        let modelPerformanceData = []
        let mlModelDetails = []
        let datasetLevelInfo = []
        let attributeLevelInfo = []
        let errorCharts = []
        let showMoreData = []
        let completeFullscreenDataModelPerf = []
        let tableData = []
        let tableDataModelPerfListView = []
        let mlModelMapping = []
        let modelDataSourceDetails = []

        // ----- AB testing -> Model Performance Compare View table data preparation begins ----

        let modelABData = []
        let modelInfoMap = []
        let mappedChartData = []
        let mpCompareViewData = []
        // eslint-disable-next-line
        let mpCompareViewTableData = []
        // eslint-disable-next-line
        let mpCompareViewDetailedChartData = []
        // ----- AB testing -> Model Performance Compare View table data preparation over ------
        let currDataCount = []

        if (hideMonitorModelTab === false) {
            fullModelInfo = this.props.monitorModule.mlModelDetails;
            modelPerformanceData = this.getPerformanceChartData(resultList,
                this.props.monitorModule.mlModelMapping, true, mappedDatasetInfo, fullModelInfo);

            for (let chartData of modelPerformanceData) {
                chartData["errorChart"] = true;
                if (chartData["drift_patterns"] === undefined) {
                    chartData["drift_patterns"] = [];
                }
            }

            mlModelDetails = this.convertModelDetails(fullModelInfo);
            datasetLevelInfo = _.cloneDeep(this.props.monitorModule.mlModelDataSetInfo)
            attributeLevelInfo = _.cloneDeep(this.props.monitorModule.mlModelAttributeInfo)
            errorCharts = this.getModelErrorsOfPerformanceCharts(modelPerformanceData);

            showMoreData = this.setShowMoreData(datasetLevelInfo, attributeLevelInfo,
                mlModelDetails, errorCharts);


            tableData = this.getModelErrTableData(this.props.monitorModule, this.props.dataModule, modelPerformanceData, showMoreData);

            completeFullscreenDataModelPerf = getPerformanceChartData(resultList, mlModelMapping, false, mappedDatasetInfo, fullModelInfo);

            modelDataSourceDetails = mapModelToDataSource(fullModelInfo);

            tableDataModelPerfListView = getModelPerfListViewTableData(completeFullscreenDataModelPerf,
                modelDataSourceDetails,
                propsDataModule,
                mlModelMapping, fullModelInfo);

            mlModelMapping = this.props.monitorModule.mlModelMapping;

            // ----- AB testing -> Model Performance Compare View table data preparation begins ----

            modelABData = this.props.dataModule.modelABData;
            modelInfoMap = this.props.dataModule.modelInfoMap;
            mappedChartData = getModelNameMappedCharts(completeFullscreenDataModelPerf);
            mpCompareViewData = getMonitorCompareViewTableData(modelABData, modelInfoMap,
                mappedChartData, showMoreData);
            mpCompareViewTableData = mpCompareViewData["tableRows"];
            mpCompareViewDetailedChartData = mpCompareViewData["abDetailedChartData"];

            // ----- AB testing -> Model Performance Compare View table data preparation over ------
            // eslint-disable-next-line
            currDataCount = getTotalShowMoreDataPointsCount(showMoreData);

        }

        // const monitorDQListViewData = this.props.monitorModule.monitorDQListView;
        // let showLoader = true;
        // if (monitorDQListViewData === undefined || monitorDQListViewData === null) {
        //     showLoader = true;
        // } else {
        //     showLoader = !monitorDQListViewData.apiFetchOver;
        // }

        this.state = {
            isDrxReportFullscreenMode:false,
            enableDQReportsTab: enableDQReportsTab,
            errorOccurred: false,
            // showLoader: false,
            closeAction: false,
            hideMonitorModelTab: hideMonitorModelTab,
            activeTabKey: tab,
            innerTab: childTab,
            mappedDatasetInfo: mappedDatasetInfo,
            additionalInfoFromParam: additionalInfoFromParam,
            modelPerformanceDataModelErr: modelPerformanceData,
            integrationsMap: null,
            showMoreDataCount: currDataCount,
            tableData: tableData["finalData"],
            attributeOptions: tableData["attributeOptions"],
            showMoreData: showMoreData,
            mlModelOptions: getMLModelOptionsForGivenMapping(mlModelDetails),
            tableUpdated: false,
            timelinessShowMore: timelinessShowMore,

            /*Following are values are for model performance tab*/
            fullModelInfo: fullModelInfo,
            propsDataModule: propsDataModule,
            completeFullscreenDataModelPerf: completeFullscreenDataModelPerf,
            tableDataModelPerfListView: tableDataModelPerfListView,
            pre_load: true,
            // monitorDQListView:{},

            // Advance Filter
            filterData:[],
            showLoader:true,
            metaData:{},
            follow_attributes:{},
            integrationMapping:{},
            default_filter_data:{},
            inProgress: null,
            noDataFound:null,

        }
        this.getMetaDatas();
    }

    setCustomFilterValues(pmdata) {
        this.setState({default_filter_data:pmdata})
    }

    preFetch() {
         let teamID = localStorage.getItem('team_id')
         let tenantData = { "tenant_id": teamID };


        //    Alert Prefetch : alert_applicable_metrics
        //    used on alert page
        // if (!this.props.monitorModule.preFetchMetadataDetails) {
        //     preFetchMetadataDetails(tenantData, storeMetadataDetails, store)
        // }

          if (IS_SAAS_EDITION && !this.props.monitorModule.lastProfilingTime){
             getLastProfilingTime(tenantData, this.props.addLastProfilingTime, this.props.setStartDate, this.props.setEndDate,);
         }

        //    Monitor Prefetch
        // Prefetch happens for every Integrations
        // Gets the result and iterate for all integrations
         if (IS_SAAS_EDITION && !this.props.monitorModule.preFetchMonitorListViewData) {
             this.preFetchMonitorListViewData(teamID, this.props.addMonitorDQListViewData);
         }

        // this.getMappedMetadatas();
        // this.getFollowAttributes();
        // Monitor Prefetch.
        // Single api call : /model_performance/results/info
         if (IS_SAAS_EDITION && !this.props.monitorModule.preFetchMonitorPreview) {
         preFetchMonitorPreview(tenantData, this.props.addMonitorPreview, this.props.addPerformancePreview,
             this.props.addModelPerformance);
         }

        // getLastProfilingTime(tenantData, this.props.addLastProfilingTime, this.props.setStartDate, this.props.setEndDate,);

        // // Data Prefetch
        // // Single Api call and interating the result to get exact metadata values
        // if (!this.props.dataModule.preFetchMetaData) {
        //     preFetchMonitorMetaData(
        //         tenantData, this.props.addMetaData, this.props.setDataSet, this.props.setAttribute
        //     );
        // }
        // if (!this.props.dataModule.preFetchMetaData) {
        //     preFetchMetaData(
        //         tenantData, this.props.addMetaData, this.props.addEnvironment,
        //         this.props.addEnvironmentDataSourceMapping, this.props.addDataSourceDataSetMapping,
        //         this.props.addDataSetAttributeMapping, this.props.setDataSet, this.props.setAttribute,
        //         this.props.setDataSource, this.props.setMlDataSource, this.props.addRelationship, this.props.addDataSource,
        //         this.props.addInfo, this.props.addLastProfilingTime, this.props.setStartDate, this.props.setEndDate, this.props.addDiscoveryStatus,
        //         this.props.addProcessingStatus, this.props.noIntegration
        //     );
        // }
        // // Monitor Prefetch
        // // Prefetch happens for every Integrations
        // // Gets the result and iterate for all integrations
//         if (!this.props.dataModule.integrations) {
         if (IS_SAAS_EDITION) {
             for (const key of Object.keys(this.props.dataModule.integrations)) {
                 if (!(key in this.props.monitorModule.fetchInfo && this.props.monitorModule.fetchInfo[key])) {
         this.preFetchMonitorInfo(
             tenantData, this.props.addCompletenessDataSetInfo, this.props.addCompletenessAttributeInfo,
             this.props.addTimelinessDataSetInfo, this.props.addTimelinessAttributeInfo,
             this.props.addAccuracyDataSetInfo, this.props.addAccuracyAttributeInfo,
             this.props.addConformityDataSetInfo, this.props.addConformityAttributeInfo,
             this.props.addConsistencyDataSetInfo, this.props.addConsistencyAttributeInfo,
             this.props.addDriftDataSetInfo, this.props.addDriftAttributeInfo, this.props.addMLDataSetInfo,
             this.props.addMLAttributeInfo, this.props.addMonitorPreview, this.props.addUniquenessDataSetInfo,
             this.props.addUniquenessAttributeInfo, this.props.addRecencyPreview,
             this.props.prefetchMonitorState, this.props.setDataSource
         );
                 }
             }
         }
        //    Monitor Prefetch
        // Prefetch happens for every Integrations
        // Gets the result and iterate for all integrations
        // }
        // // Monitor Model Prefetch : model/map
        // // store the value in redux
         if (IS_SAAS_EDITION && !this.props.monitorModule.preFetchMonitorModelInfo) {
             preFetchMonitorModelInfo(
                 teamID, this.props.addMLModelMapping,
                 this.props.addMLDataSetInfo, this.props.addMLAttributeInfo,
                 this.props.addMLModel, this.props.setMLModel
             );
         }

        // // ML Monitor prefetch : ml-models
        // // Profile ML filter and ML tab
         if (IS_SAAS_EDITION && !this.props.monitorModule.preFetchModelDetails) {
             preFetchModelDetails(teamID, this.props.addModelDetails);
         }

        // custom Metrics Prefetch used in custom metric page
        // Fetch and Store
         if (IS_SAAS_EDITION && this.props.monitorModule.preFetchMetrics) {
             preFetchMetrics(teamID, this.props.addMetrics);
         }

        //    Notification Prefetch: user_notification/unread
        //    Used in navigation header
        //    Fetch and store
        // if (!this.props.dataModule.preFetchUserNotifications) {
        //     // Fetch first page result of user notifications
        //     preFetchUserNotifications(tenantData, addUserNotificationToState, store);

        // }

        //  Data Prefetch: /datasets
        //  Used in charts discover, profile
        //  Fetch and store
        // if (!this.props.dataModule.prefetchDatasetDetails) {// Fetch dataset version details
        //     prefetchDatasetDetails(tenantData, addDatasetVersions, store);
        // }

        // Data Prefetch: recency/all
        // Used in Discovery and profile
        // Fetch and store
        // if (!this.props.dataModule.prefetchRecencyDetails) {// Fetch Recency Details
        //     prefetchRecencyDetails(tenantData, addRecencyDetails, store);
        // }

        // Discover prefetch: recent_search
        // user in discover page
        // fetch and store
        // if (!this.props.dataModule.prefetchRecentHistory) { // Fetch recent search history - discover page
        //     prefetchRecentHistory(tenantData, addRecentHistory, store);
        // }

        // Fetch and store datasize : /data_size
        // simple prefetch
        // if (!this.props.dataModule.prefetchDataSize) {// Fetch data size - discover page
        //     prefetchDataSize(tenantData, addDataSize, store)
        // }

        // // Monitor Model Prefetch used in configure and monitor : model_ab_data
         if (IS_SAAS_EDITION && !this.props.dataModule.prefetchModelABData) { // Fetch Model AB configuration list
             prefetchModelABData(tenantData, setModelABDataToList, store);
         }

        // Monitor Prefetch: follow_attributes
        // used in monitor page
        // fetch and store
        // if (!this.props.monitorModule.preFetchFollowAttributes) {
        //     preFetchFollowAttributes(tenantData, addFollowAttributes, store);
        // }

        //    Alert Prefetch : alert_applicable_metrics
        //    used on alert page
        // if (!this.props.monitorModule.preFetchMetadataDetails) {
        //     preFetchMetadataDetails(tenantData, storeMetadataDetails, store)
        // }
    }

    // formData() {
    //     if (this.props.dataModule.metaData !== undefined) {
    //         const metaDataMapping = this.props.dataModule.metaData;
    //         const monitorDQListViewData = this.props.monitorModule.monitorDQListView;
    //         const timelinessShowMore = setDQShowMoreData(this.props.monitorModule.timelinessDataSetInfo,
    //             this.props.monitorModule.timelinessAttributeInfo, this.props.dataModule,
    //             null, DQ_MONITOR_SECTIONS.TIMELINESS);

    //         const mappedDatasetInfos = extractDatasetInfoForMonitorListView(metaDataMapping);
    //         const listViewTableDatas = getMonitorTabDQPListViewTableData(mappedDatasetInfos, monitorDQListViewData, this.props.monitorModule, timelinessShowMore);
    //         fromMonitorData(this.props.mappedDatasetInfo, this.props.listViewTableData, mappedDatasetInfos, listViewTableDatas, store)
    //         this.formDataOnce = 0
    //     }
    // }


    componentDidMount() {
        let logoutPage = logoutPageRedirect()
        if (logoutPage) {
            return ''
        }

        // let prefetch = (Object.keys(this.props.dataModule.environmentDataSourceMapping).length !==
        //     Object.keys(this.props.dataModule.environments).length) ||
        //     (Object.keys(this.props.dataModule.integrations).length !==
        //         Object.keys(this.props.dataModule.relationship).length)
        // let initial_prefetch = Object.keys(this.props.dataModule.environmentDataSourceMapping).length === 0 &&
        //     Object.keys(this.props.dataModule.environments).length === 0 &&
        //     Object.keys(this.props.dataModule.integrations).length === 0 &&
        //     Object.keys(this.props.dataModule.relationship).length === 0
        //     // this.preFetch();
        //     // var intervalId = setInterval(this.preFetchMonitorGraph, 1000);
        //     // store intervalId in the state so it can be accessed later:
        //     // this.setState({
        //     //     intervalId: intervalId
        //     // });
        // }
        // else {
        //     this.pre_load = false
        //     this.setState({ pre_load: false })
        // }
        this.preFetch()
        this.getData();
    }

    componentDidUpdate(prevProps) {
        let hideMonitorModelTab = true;
        let featureAccess = getFeatureAccess()

        // if (this.state.pre_load === true  && get_api_call_cnt === get_api_call_complete_cnt && post_api_call_cnt === post_api_call_complete_cnt) {
        //     this.setState({pre_load:false})
        // }

        if (!IS_NONE_CHECK.includes(featureAccess)) {
            const monitorModel = featureAccess["models"];
            hideMonitorModelTab = monitorModel === false;
        }
        if (hideMonitorModelTab === false) {

            if ((this.state.pre_load === false && this.props.dataModule !== prevProps.dataModule) || (this.props.monitorModule !== prevProps.monitorModule)) {
                const mlModelDetails = this.convertModelDetails(this.props.monitorModule.mlModelDetails);
                const datasetLevelInfo = _.cloneDeep(this.props.monitorModule.mlModelDataSetInfo)
                const attributeLevelInfo = _.cloneDeep(this.props.monitorModule.mlModelAttributeInfo)
                let errorCharts = this.getModelErrorsOfPerformanceCharts(this.state.modelPerformanceDataModelErr);
                const showMoreData = this.setShowMoreData(datasetLevelInfo, attributeLevelInfo, mlModelDetails, errorCharts);
                let tableData = getModelErrTableData(this.props.monitorModule, this.props.dataModule, this.state.modelPerformanceDataModelErr, showMoreData);

                this.setState({
                    tableData: tableData["finalData"],
                    attributeOptions: tableData["attributeOptions"],
                    tableUpdated: !this.state.tableUpdated,
                    mlModelOptions: getMLModelOptionsForGivenMapping(mlModelDetails),
                    showMoreData: showMoreData
                })
            }
        }
    }


    getData() {
        // this.getApi("integrations_profile");

    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    componentWillUnmount() {
        document.body.classList.remove("overflow-hidden");
        d3.selectAll(".d3-tip").remove();
    }


    static getDerivedStateFromProps(props, state) {
        let prevFullModelInfo = state.fullModelInfo;
        let prevPropsDataModuleInfo = state.propsDataModule;
        const currPropsDataModule = props.dataModule;
        let finalObj = {}
        let hideMonitorModelTab = true;
        let featureAccess = getFeatureAccess()

        if (!IS_NONE_CHECK.includes(featureAccess)) {
            const monitorModel = featureAccess["models"];
            hideMonitorModelTab = monitorModel === false;
        }
        if (hideMonitorModelTab === false) {
            let reduxPerformanceResult = props.monitorModule.mlModelPerformanceInfo;
            const mlModelMapping = props.monitorModule.mlModelMapping;

            let resultList = Object.keys(reduxPerformanceResult).map(x => reduxPerformanceResult[x]);
            let fullModelInfo = props.monitorModule.mlModelDetails;
            let modelPerformanceData = getPerformanceChartData(resultList, mlModelMapping,
                true, state.mappedDatasetInfo, fullModelInfo);

            if (modelPerformanceData.length > state.modelPerformanceDataModelErr.length) {
                return {
                    modelPerformanceDataModelErr: modelPerformanceData
                }
            }

            /* Getting Model performance chart data */
            let completeFullscreenDataModelPerf = getPerformanceChartData(resultList, mlModelMapping, false, state.mappedDatasetInfo, fullModelInfo);
            let fullScreenDataChanged = (completeFullscreenDataModelPerf.length !==
                state.completeFullscreenDataModelPerf.length);

            /* Getting Model AB combined chart&table data preparation starts */
            const modelABDataProps = props.dataModule.modelABData;

            let modelABDataState = state.modelABData;
            if (modelABDataState === null || modelABDataState === undefined) {
                modelABDataState = [];
            }

            const currDataCount = getTotalShowMoreDataPointsCount(state.showMoreData);
            if (currDataCount !== state.showMoreDataCount) {
                fullScreenDataChanged = true;
            }

            if ((!IS_NONE_CHECK.includes(modelABDataProps) &&
                modelABDataProps.length !== modelABDataState.length) || fullScreenDataChanged) {
                // We will update this Model AB data when AB data is updated or
                // model performance chart data is changed
                const modelInfoMap = props.dataModule.modelInfoMap;
                const mappedChartData = getModelNameMappedCharts(completeFullscreenDataModelPerf);
                const mpCompareViewData = getMonitorCompareViewTableData(modelABDataProps, modelInfoMap,
                    mappedChartData, state.showMoreData);

                const mpCompareViewTableData = mpCompareViewData["tableRows"];
                const mpCompareViewDetailedChartData = mpCompareViewData["abDetailedChartData"];
                finalObj = {
                    modelABData: modelABDataProps,
                    showMoreDataCount: currDataCount,
                    mpCompareViewTableData: mpCompareViewTableData,
                    mpCompareViewDetailedChartData: mpCompareViewDetailedChartData,
                }
                // Return new values to state
            }
            /* ==== Model AB combined chart&table data preparation ends === */

            /* Getting data for Model performance - List view */
            let currFullModelInfo = props.monitorModule.mlModelDetails;

            const modelDataSourceDetails = mapModelToDataSource(currFullModelInfo);

            const modulePropsChanged = (Object.values(prevPropsDataModuleInfo) !==
                Object.values(currPropsDataModule));

            if (fullScreenDataChanged ||
                currFullModelInfo !== prevFullModelInfo ||
                modulePropsChanged || modelDataSourceDetails !== state.modelDataSourceDetails) {
                let tableDataModelPerfListView = getModelPerfListViewTableData(completeFullscreenDataModelPerf, modelDataSourceDetails,
                    currPropsDataModule, mlModelMapping, currFullModelInfo);
                const timelinessShowMore = setDQShowMoreData(props.monitorModule.timelinessDataSetInfo,
                    props.monitorModule.timelinessAttributeInfo, props.dataModule,
                    null, DQ_MONITOR_SECTIONS.TIMELINESS);
                finalObj = {
                    ...finalObj, ...{
                        fullModelInfo: currFullModelInfo,
                        tableDataModelPerfListView: _.cloneDeep(tableDataModelPerfListView),
                        propsDataModule: currPropsDataModule,
                        completeFullscreenDataModelPerf: completeFullscreenDataModelPerf,
                        mlModelOptions: getMLModelOptionsForGivenMapping(currFullModelInfo),
                        modelDataSourceDetails: modelDataSourceDetails,
                        modelPerfTableUpdated: !state.modelPerfTableUpdated,
                      //  showLoader: !state.monitorDQListView.apiFetchOver,
                        timelinessShowMore: timelinessShowMore
                    }
                }
            }
            // else if(this.state.pre_load === false){

            // }
            else {

                const modulePropsChanged = (Object.values(prevPropsDataModuleInfo) !==
                Object.values(currPropsDataModule));

                if (modulePropsChanged ) {
                    const timelinessShowMore = setDQShowMoreData(props.monitorModule.timelinessDataSetInfo,
                        props.monitorModule.timelinessAttributeInfo, props.dataModule,
                        null, DQ_MONITOR_SECTIONS.TIMELINESS);
                    finalObj = {
                        ...finalObj, ...{
                            propsDataModule: currPropsDataModule,
                            // showLoader: !state.monitorDQListView.apiFetchOver,
                            // showLoader: !state.monitorDQListView.apiFetchOver,
                            timelinessShowMore: timelinessShowMore
                        }
                    }
                }

                if (Object.keys(finalObj).length > 0) {
                    return finalObj
                }
            }
            if (Object.keys(finalObj).length > 0) {
                return finalObj
            }
        }
        return null;
    }

    hideContent() {
        this.setState({
            closeAction: true,
        });
    }

    changeTab(tab) {
        postMixpanel("Monitor -> " + tab + " Tab Page")
        this.setState({ activeTabKey: tab });
    }
    drxReport(){
        document.body.classList.add("overflow-hidden");
        this.setState({
            isDrxReportFullscreenMode:true,
            DrxReportFullScreenClassKey:"show",
            DrxReportName:"testing"
        });
    }
    closedrxReportfullscreen(){
        document.body.classList.remove("overflow-hidden");
        this.setState({isDrxReportFullscreenMode:false,DrxReportFullScreenClassKey:""});
    }



    render() {
        let logoutPage = logoutPageRedirect()
        if (logoutPage) {
            return ''
        }
        let hidePipelineSourceTab = false;
        let featureAccess = getFeatureAccess()
        if (!IS_NONE_CHECK.includes(featureAccess)){
            const confPagePipelineData = featureAccess["pipeline"];
            if(confPagePipelineData!==undefined){
                hidePipelineSourceTab = confPagePipelineData === true;
            }
        }
        hidePipelineSourceTab = true;
        let firstDatasetUp = this.props.dataModule.tenantId === -1

        let defaultKey = this.state.hideMonitorModelTab ? "dq" : "modelPerf"
        return (
            this.state.errorOccurred ?
                <ErrorHandler />
                :
                <>
                    <NavigationHeader page="monitor" />
                    <main>
                        <BreadCrumb icon='chartArea' title='Monitor' />
                        <Tab.Container defaultActiveKey={defaultKey}
                            activeKey={this.state.activeTabKey}
                            onSelect={this.changeTab}>
                            <div className="qd-tab__header">
                                <div className="container-fluid">
                                    <div className="qd-tab__header-wrapper">
                                        <div className="qd-tab__header-menu">
                                            <Nav variant="tabs">
                                            <Nav.Item>
                                                    <Nav.Link eventKey="dqp" disabled={this.state.pre_load}>
                                                        <span className="qd-menu__link-text">Data Quality Performance</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="dq" disabled={this.state.pre_load}>
                                                        <span className="qd-menu__link-text">Data Quality Errors</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {this.state.hideMonitorModelTab ? "" : <><Nav.Item>
                                                    <Nav.Link eventKey="modelPerf" disabled={this.state.pre_load}>
                                                        <span className="qd-menu__link-text">Model Performance</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="ml" disabled={this.state.pre_load}>
                                                            <span className="qd-menu__link-text">Model Errors</span>
                                                        </Nav.Link>
                                                    </Nav.Item></>
                                                }

                                                {hidePipelineSourceTab ?
                                            <>
                                            <Nav.Item>
                                            <Nav.Link eventKey="pipeline">
                                                    <span className="qd-menu__link-text">Pipeline Performance</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="pipelineerror">
                                                    <span className="qd-menu__link-text">Pipeline Errors</span>
                                                </Nav.Link>
                                            </Nav.Item> */}
                                            </>
                                             : ""
                                            }
                                                                                            {this.state.enableDQReportsTab === true ?
                                            <Nav.Item>
                                                <Nav.Link onClick={this.drxReport}>
                                                    <span className="qd-menu__link-text">Monthly Report</span>
                                                </Nav.Link>
                                            </Nav.Item> : ""
                                            }
                                            </Nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="qd-container">
                                <div className="qd-body">
                                    <div className="qd-tab__content" id={this.state.activeTabKey + "Tab"}>
                                        {this.state.pre_load === true && this.state.filterData.length === 0 ? <Load /> :
                                            <Tab.Content>
                                                 <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                    eventKey="dqp">
                                                    <MonitorDQPTabs
                                                        timelinessData={this.state.timelinessShowMore}
                                                        additionalInfoFromParamdp={this.state.additionalInfoFromParam}
                                                        firstDatasetUp={firstDatasetUp}
                                                        activeTabKey = {this.state.activeTabKey}
                                                        filterData={this.state.filterData}
                                                        metaData = {this.state.metaData}
                                                        mappedDatasetInfo = {this.state.mappedDatasetInfo}
                                                        follow_attributes={this.state.follow_attributes}
                                                        integrationMapping={this.state.integrationMapping}
                                                        default_filter_data={this.state.default_filter_data}
                                                        setCustomFilterValues={this.setCustomFilterValues}
                                                        inProgress={this.state.inProgress}
                                                        noDataFound={this.state.noDataFound}

                                                  />
                                                </Tab.Pane>
                                                <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                    eventKey="dq">
                                                    {this.state.activeTabKey === 'dq' ?
                                                        <MonitorDQTabs
                                                            timelinessData={this.state.timelinessShowMore}
                                                            firstDatasetUp={firstDatasetUp}
                                                            activeTabKey = {this.state.activeTabKey}
                                                            filterData={this.state.filterData}
                                                            dqChartViewmetrics={dq_chartview_metrics}
                                                            tab={this.state.activeTabKey}
                                                            metaData = {this.state.metaData}
                                                            mappedDatasetInfo = {this.state.mappedDatasetInfo}
                                                            follow_attributes={this.state.follow_attributes}
                                                            integrationMapping={this.state.integrationMapping}
                                                            default_filter_data={this.state.default_filter_data}
                                                            setCustomFilterValues={this.setCustomFilterValues}
                                                            inProgress={this.state.inProgress}
                                                            noDataFound={this.state.noDataFound}

                                                            />
                                                        :
                                                        ''
                                                    }
                                                </Tab.Pane>
                                                {this.state.hideMonitorModelTab ? "" : <><Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                    eventKey="modelPerf">
                                                    <MonitorMPTabs
                                                        integrationsMap={this.state.integrationsMap}
                                                        innerTab={this.state.innerTab}
                                                        additionalInfoFromParam={this.state.additionalInfoFromParam}
                                                        tableDataListView={this.state.tableDataModelPerfListView}
                                                        completeFullscreenData={this.state.completeFullscreenDataModelPerf}
                                                        tableUpdated={this.state.modelPerfTableUpdated}
                                                        availableModelABData={this.state.modelABData}
                                                        mpCompareViewTableData={this.state.mpCompareViewTableData}
                                                        mpCompareViewDetailedChartData={this.state.mpCompareViewDetailedChartData}
                                                        firstDatasetUp={firstDatasetUp}
                                                        showLoader={this.state.pre_load}
                                                        modelDataSourceDetails={this.state.modelDataSourceDetails}
                                                        mlModelOptions={this.state.mlModelOptions}
                                                        modelErrShowMoreData={this.state.showMoreData}
                                                        attributeOptions={this.state.attributeOptions}
                                                        filterData={this.state.filterData}
                                                        default_filter_data={this.state.default_filter_data}
                                                        setCustomFilterValues={this.setCustomFilterValues}
                                                    />
                                                </Tab.Pane>
                                                    <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                        eventKey="ml">
                                                        <MonitorERTabs
                                                            modelPerformanceData={this.state.modelPerformanceDataModelErr}
                                                            integrationsMap={this.state.integrationsMap}
                                                            tableData={this.state.tableData}
                                                            attributeOptions={this.state.attributeOptions}
                                                            firstDatasetUp={firstDatasetUp}
                                                            showLoader={this.state.pre_load}
                                                            timelinessShowMore={this.state.timelinessShowMore}
                                                            showMoreData={this.state.showMoreData}
                                                            tableUpdated={this.state.tableUpdated}
                                                            mlModelOptions={this.state.mlModelOptions}
                                                            filterData={this.state.filterData}
                                                            default_filter_data={this.state.default_filter_data}
                                                            setCustomFilterValues={this.setCustomFilterValues}

                                                        />
                                                    </Tab.Pane></>}

                                                <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="pipeline">
                                                <PipelinePerformance />
                                                </Tab.Pane>
                                                <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                eventKey="pipelineerror">
                                                <PipelinePerformance />
                                            </Tab.Pane>
                                            </Tab.Content>
                                        }

                                    </div>
                            </div>
                        </div>
                    </Tab.Container>
                </main>
                <CopyRightsFooter/>
                {this.state.isDrxReportFullscreenMode ?
                    <DrxReportFullScreen fullScreenClassVal={this.state.DrxReportFullScreenClassKey}
                                            title="Title"
                                            key={this.state.selectedModelInFullScreen}
                                            closedrxReportfullscreen = {this.closedrxReportfullscreen}
                    >
                    </DrxReportFullScreen> : ''
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps,
    {
        addMetaData,
        addMLModelMapping,
        setMLModel,
        setModelABDataToList,
        addEnvironment,
        addEnvironmentDataSourceMapping,
        addDataSourceDataSetMapping,
        addDataSetAttributeMapping,
        setDataSet,
        setAttribute,
        setDataSource,
        setMlDataSource,
        addRelationship,
        addDataSource,
        addInfo,
        addLastProfilingTime,
        setStartDate,
        setEndDate,
        addDiscoveryStatus,
        addProcessingStatus,
        addMonitorPreview,
        addPerformancePreview,
        addModelPerformance,
        addCompletenessDataSetInfo,
        addCompletenessAttributeInfo,
        addTimelinessDataSetInfo,
        addTimelinessAttributeInfo,
        addAccuracyDataSetInfo,
        addAccuracyAttributeInfo,
        addConformityDataSetInfo,
        addConformityAttributeInfo,
        addConsistencyDataSetInfo,
        addConsistencyAttributeInfo,
        addDriftDataSetInfo,
        addDriftAttributeInfo,
        addMLDataSetInfo,
        addMLAttributeInfo,
        addUniquenessDataSetInfo,
        addUniquenessAttributeInfo,
        addRecencyPreview,
        addMLModel,
        addModelDetails,
        addMetrics,
        addMonitorDQListViewData,
        prefetchMonitorState,
        noIntegration,
        mappedDatasetInfo,
        listViewTableData,
        addFollowAttributes,
        storeMetadataDetails
    }
)(Monitor);
