import React from 'react';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import{triggers} from "../../utils/constant";
import 'react-multi-email/style.css';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { postEmailData }  from "../../utils/event_handling";
import { userValidation } from '../../utils/common_utils';



class EmailForm extends React.Component {
    constructor(props) {
        super(props);
        this.postEmailData = postEmailData.bind(this);
        this.scheduleChange = this.scheduleChange.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
        this.state = {
            hideSubmitButton: false,
            errorMsg: '',
            emailData: {
                groupName: '',
                email: [],
                notificationType: triggers.INSTANT_NOTIFICATION,
                schedule: triggers.DAY_SCHEDULE,
            },
            emailData_edit:"",
            notificationKey: triggers.EMPTY_NOTIFICATION_KEY,
            isChecked :true
        };
    }

    componentDidMount() {
        this.fill_details();
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    fill_details() {
        if (this.props.exConsumeDetails === undefined) {
            return;
        }
        let emailData = this.state.emailData;
        const exEmailData = this.props.exConsumeDetails.consume_item;

        emailData.groupName = exEmailData.consume_group_name;

        const configDetails = exEmailData.config_details;
        emailData.email = configDetails.email_id;
        emailData.notificationType = configDetails.notification_type;
        emailData.schedule = configDetails.schedule;
        let isDefaultErrorEnabled = configDetails.is_default_error_enabled;
        this.setState({
            emailData_edit: emailData, isChecked: isDefaultErrorEnabled
        });
    }

    requiresConsumeUpdate(emailData) {
        const exConsumeDetails = this.props.exConsumeDetails.consume_item;
        if (emailData.consume_group_name !== exConsumeDetails.consume_group_name) {
            return true;
        }

        const curr_config_details = emailData.config_details;
        const ex_config_details = exConsumeDetails.config_details;

        if (curr_config_details.email_id !== ex_config_details.email_id) {
           return true;
        }
        if (curr_config_details.notification_type !== ex_config_details.notification_type) {
            return true;
        }

        if (curr_config_details.schedule !== ex_config_details.schedule) {
            return true;
        }
        
        if (curr_config_details.isEmailErrorChecked !== ex_config_details.isEmailErrorChecked) {
            return true;
        }

        if (curr_config_details.is_default_error_enabled !== ex_config_details.is_default_error_enabled) {
            return true;
        }


        return false;
    }

    enableRecurringTimeInterval(){
        if (this.state.emailData.notificationType === triggers.RECURRING_NOTIFICATION) {
            return(
                <Form.Group  className="row">
                        <Form.Label className="col-md-5 col-lg-4 col-form-label">Schedule :</Form.Label>
                        <div className="col-md-7 col-lg-5">
                            <div className="custom-control custom-radio custom-control-inline pt-2">
                                <Form.Control
                                       type="radio"
                                       name="schedule"
                                       id={this.props.exConsumeDetails !== undefined ? "1HourRecurringRadioEx":"1HourRecurringRadio"}
                                       value="1hour"
                                       onChange={this.scheduleChange}
                                       checked={this.state.emailData.schedule === triggers.HOUR_SCHEDULE}
                                       className="custom-control-input"/>
                                <Form.Label className="custom-control-label"
                                            htmlFor={this.props.exConsumeDetails !== undefined ? "1HourRecurringRadioEx":"1HourRecurringRadio"}>
                                Hourly
                                </Form.Label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline pt-2">
                                <Form.Control
                                      type="radio"
                                      name="schedule"
                                      id={this.props.exConsumeDetails !== undefined ? "24HourRecurringRadioEx":"24HourRecurringRadio"}
                                      value="24hours"
                                      onChange={this.scheduleChange}
                                      checked={this.state.emailData.schedule === triggers.DAY_SCHEDULE}
                                      className="custom-control-input"/>
                                <Form.Label className="custom-control-label"
                                            htmlFor={this.props.exConsumeDetails !== undefined ? "24HourRecurringRadioEx":"24HourRecurringRadio"}>
                                Daily
                                </Form.Label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline pt-2">
                                <Form.Control
                                      type="radio"
                                      name="schedule"
                                      id={this.props.exConsumeDetails !== undefined ? "1weekRecurringRadioEx":"1weekRecurringRadio"}
                                      value="1week"
                                      onChange={this.scheduleChange}
                                      checked={this.state.emailData.schedule === triggers.WEEK_SCHEDULE}
                                      className="custom-control-input"/>
                                <Form.Label className="custom-control-label"
                                      htmlFor={this.props.exConsumeDetails !== undefined ? "1weekRecurringRadioEx":"1weekRecurringRadio"}>
                                Weekly
                                </Form.Label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline pt-2">
                                <Form.Control
                                      type="radio"
                                      name="schedule"
                                      id={this.props.exConsumeDetails !== undefined ? "1monthlyRecurringRadioEx":"1monthlyRecurringRadio"}
                                      value="1month"
                                      onChange={this.scheduleChange}
                                      checked={this.state.emailData.schedule === triggers.MONTH_SCHEDULE}
                                      className="custom-control-input"/>
                                <Form.Label className="custom-control-label"
                                     htmlFor={this.props.exConsumeDetails !== undefined ? "1monthlyRecurringRadioEx":"1monthlyRecurringRadio"}>
                                Monthly
                                </Form.Label>
                            </div>
                        </div>
                </Form.Group>
            );

        }
    }

    radioHandler = (status) => {
        let emailData = this.state.emailData;
        emailData.notificationType = status;
        this.setState({emailData: emailData});
    }

    scheduleChange = e => {
         let emailData = this.state.emailData;
         emailData.schedule = e.target.value;
         this.setState({emailData_edit: emailData});
    }

    toggleChange = () => {
        this.setState({isChecked: !this.state.isChecked});
    }

    render() {
        const emailSchema = yup.object({
            groupName:  yup.string().min(1,"Name must be at least 1 characters").required("Notification name is a required field").test(
                'Notification name test',
                'invalid Notification name',        
                function test(value) {        
                   let val = userValidation(value,"input");       
                   return val;
            }
            ),
        });
        let emails = this.state.emailData.email;
        return (
            <>
                     <Formik
                          validationSchema={emailSchema}
                          onSubmit={this.postEmailData}
                          initialValues={this.state.emailData_edit}
                          enableReinitialize={true}
                     >
                     {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                     }) => (
                        <Form className="form-label-right-md" onSubmit={handleSubmit}>
                            <Form.Group controlId="groupName" className="row">
                                <Form.Label className="col-md-5 col-lg-4 col-form-label">Notification Name <span className="text-danger">*</span> : </Form.Label>
                                <div className="col-md-7 col-lg-5">
                                    <Form.Control
                                          type="text"
                                          name="groupName"
                                          value={values.groupName}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          isInvalid={errors.groupName && touched.groupName}
                                          className="form-control"
                                          placeholder="Eg: My Personal Email"/>
                                    <Form.Control.Feedback type="invalid">
                                          { touched.groupName && errors.groupName }
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                            <Form.Group controlId="email" className="row">
                                <Form.Label className="col-md-5 col-lg-4 col-form-label">Email <span className="text-danger">*</span> :</Form.Label>
                                <div className="col-md-7 col-lg-5">
                                <ReactMultiEmail
                                         name="email"
                                         className="overflow-hidden"
                                         emails={emails}
                                         placeholder='Email'
                                         onChange={(_emails: string[]) => {
                                                let emails = this.state.emailData;
                                                emails.email = _emails;
                                                this.setState({ emails: _emails });
                                         }}
                                         validateEmail={email => {
                                                 return isEmail(email);
                                         }}
                                         getLabel={(
                                            email: string,
                                            index: number,
                                            removeEmail: (index: number) => void,
                                         ) => {
                                         return (
                                              <div data-tag key={index}>
                                                <div data-tag-item>
                                                  {email} <span data-tag-handle onClick={() => removeEmail(index)}>×
                                                </span>
                                                </div>
                                              </div>
                                         );
                                         }}
                                         />
                                        {this.state.errorMsg !== '' && this.state.emailData.email.length === 0 ? <div className='error-text'>{this.state.errorMsg}</div> : ''}
                                </div>
                            </Form.Group>
                            <Form.Group className="row" key = {this.state.notificationKey}>
                                <Form.Label className="col-md-5 col-lg-4 col-form-label">Digest :</Form.Label>
                                <div className="col-md-7 col-lg-5">
                                    <div className="custom-control custom-radio custom-control-inline pt-2">
                                        <Form.Control
                                              type="radio"
                                              name="notificationType"
                                              onChange={handleChange}
                                              checked={this.state.emailData.notificationType === "instant"}
                                              onClick={(e) => this.radioHandler("instant")}
                                              id={this.props.exConsumeDetails !== undefined ? "instantEx":"instant"}
                                              value="instant"
                                              className="custom-control-input"/>
                                        <Form.Label className="custom-control-label" htmlFor={this.props.exConsumeDetails !== undefined ? "instantEx":"instant"}>
                                            Instantly-Once
                                        </Form.Label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline pt-2">
                                        <Form.Control
                                              type="radio"
                                              name="notificationType"
                                              onClick={(e) => this.radioHandler("recurring")}
                                              id={this.props.exConsumeDetails !== undefined ? "recurringEx":"recurring"}
                                              onChange={handleChange}
                                              value="recurring"
                                              checked={this.state.emailData.notificationType === "recurring"}
                                              className="custom-control-input"/>
                                        <Form.Label className="custom-control-label" htmlFor={this.props.exConsumeDetails !== undefined ? "recurringEx":"recurring"}>
                                            Recurring
                                        </Form.Label>
                                    </div>
                                    <div className="custom-control custom-checkbox custom-control-inline pt-2">
                                        <Form.Control
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="defaulErrorCheck"
                                                checked={this.state.isChecked}
                                                onChange={this.toggleChange}
                                                id={this.props.exConsumeDetails !== undefined ? "customCheck2Ex":"customCheck2"}/>
                                        <Form.Label className="custom-control-label" htmlFor={this.props.exConsumeDetails !== undefined ? "customCheck2Ex":"customCheck2"}>
                                            Send qualdo generated errors & notifications to this email
                                        </Form.Label>
                                    </div>
                                </div>
                            </Form.Group>
                            {this.state.emailData.notificationType === triggers.RECURRING_NOTIFICATION? this.enableRecurringTimeInterval(): ""}
                            <Form.Group className="row">

                                <div className="col-md-5 offset-sm-4">
                                    <button type="button" onClick={() => this.props.exConsumeDetails ? this.props.closeModal() :this.props.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                    <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle">
                                        <FontAwesomeIcon icon={faCheck}/>Submit
                                    </button>
                                </div>
                            </Form.Group>
                        </Form>
                        )}
                     </Formik>
            </>
        );

    }
}


export default EmailForm;

