import React from 'react';
import RcTooltip from 'rc-tooltip';
import Load from '../components/loadAction';
import {IS_NONE_CHECK} from "../utils/constant";

const renderTooltipSummary = (props) => {
    return (
    <span>
      {props}
    </span>)
  }
  ;

class Summary extends React.Component{
      constructor(props, context) {
        super(props, context);
        this.state={
            datasourceCount: this.props.datasourceCount,
            datasetCount: this.props.datasetCount,
            attributeCount: this.props.attributeCount,
            sectionName: this.props.sectionName,
            datasetIds: this.props.datasetIds,
            lastRefresh: this.props.lastRefresh,
            datasetRow: this.props.datasetRow,
            recencyData: this.props.recencyData,
            datasetName: this.props.datasetName,
            attributeName: this.props.attributeName,
            dataType: this.props.dataType,
            nativeType: this.props.nativeType,
            duplicateRows: this.props.duplicateRows,
            size: this.props.sizeData,
            profileData: this.props.profileData,
            businessRule: this.props.businessRule

        }
      }

      componentDidUpdate(prevProps){
             if(prevProps !== this.props) {
               this.setState({sectionName: this.props.sectionName,
                             datasetCount: this.props.datasetCount,
                             datasetIds: this.props.datasetIds,
                             duplicateRows: this.props.duplicateRows,
                             attributeCount: this.props.attributeCount,
                             lastRefresh: this.props.lastRefresh,
                             datasetRow: this.props.datasetRow,
                             recencyData: this.props.recencyData,
                             datasetName: this.props.datasetName,
                             attributeName: this.props.attributeName,
                             dataType: this.props.dataType,
                             nativeType: this.props.nativeType,
                             businessRule: this.props.businessRule,
                             size: this.props.sizeData,
                             profileData: this.props.profileData,
                             datasourceCount: this.props.datasourceCount})
             }
      }

      render() {
        let size = this.state.size

        let profileData = this.state.profileData;


         switch(this.state.sectionName) {
             case "Environment":
                  return(<div className="portlet-table clearfix">
                              <div className="form-group"><label>Datasources count :</label>
                                   <span className="portlet-table-value">{this.state.datasourceCount}</span>
                              </div>
                              <div className="form-group"><label>Datasets count :</label>
                                   <span className="portlet-table-value">{this.state.datasetCount}</span>
                              </div>
                              <div className="form-group"><label>Total datasource size :</label>
                                   <span className="portlet-table-value">{size}</span>
                              </div>
                              <div className="form-group">
                                   <span className="portlet-table-value"></span>
                              </div>
                          </div>);
             case "Datasource":
                  return(<div className="portlet-table clearfix">
                              <div className="form-group"><label>Datasets count :</label>
                                   <span className="portlet-table-value">{this.state.datasetCount}</span>
                              </div>
                              <div className="form-group"><label>Datasource size :</label>
                                   <span className="portlet-table-value">{size}</span>
                              </div>
                              <div className="form-group"><label>Datasets with recency  > one month :</label>
                                   <span className="portlet-table-value">{this.state.recencyData}</span>
                              </div>
                              <div className="form-group"><label>Total no of rows with duplicates :</label>
                                   <span className="portlet-table-value">{this.state.duplicateRows}</span>
                              </div>
                          </div>);
             case "Dataset":
                  return(<div className="portlet-table clearfix">
                              <div className="form-group"><label>Dataset Name :</label>
                              {!IS_NONE_CHECK.includes(this.state.datasetName) && this.state.datasetName.toString().length >= 15 ? <RcTooltip placement="top" overlay={renderTooltipSummary(this.state.datasetName)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                    <span className="portlet-table-value">{this.state.datasetName !== null ? this.state.datasetName : "NA"}</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{this.state.datasetName !== null ? this.state.datasetName : "NA"}</span>
                                                    }
                                   {/* <span className="portlet-table-value">{this.state.datasetName}</span> */}
                              </div>
                              <div className="form-group"><label>Attributes count :</label>
                                   <span className="portlet-table-value">{this.state.attributeCount}</span>
                              </div>
                              <div className="form-group"><label>No of rows :</label>
                                   <span className="portlet-table-value">{this.state.datasetRow}</span>
                              </div>
                              <div className="form-group"><label>Duplicate rows :</label>
                                   <span className="portlet-table-value">{this.state.duplicateRows}</span>
                              </div>
                              <div className="form-group"><label>Dataset size :</label>
                                   <span className="portlet-table-value">{size}</span>
                              </div>

                      <div className="form-group"><label>Last Refresh Time :</label>
                          {
                              !IS_NONE_CHECK.includes(this.state.lastRefresh) && this.state.lastRefresh.toString().length >= 15 ?
                                  <RcTooltip 
                                    placement="top" 
                                    overlay={renderTooltipSummary(this.state.lastRefresh)} 
                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                  >
                                      <span className="portlet-table-value">
                                          {this.state.lastRefresh}
                                      </span>
                                  </RcTooltip>
                                  :
                                  <span className="portlet-table-value">
                                      {this.state.lastRefresh}
                                  </span>
                          }
                      </div>

                          </div>);
             case "Attribute":
                let reqAttributeType = this.state.dataType !== null ? this.state.dataType : "NA";
                let nativeType = this.state.nativeType;
                 if (nativeType !== undefined && nativeType !== null) {
                     reqAttributeType = nativeType;
                 }

                  return(profileData !== null ? <div className="portlet-table clearfix">
                                                <div className="form-group">
                                                    <label>Dataset Name :</label>
                                                    {!IS_NONE_CHECK.includes(this.state.datasetName) && this.state.datasetName.toString().length >=15 ? <RcTooltip placement="top" overlay={renderTooltipSummary(this.state.datasetName)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                        <span className="portlet-table-value">{this.state.datasetName !== null ?this.state.datasetName : "NA"}</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{this.state.datasetName !== null ?this.state.datasetName : "NA"}</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Attribute Name :</label>
                                                    {!IS_NONE_CHECK.includes(this.state.attributeName) && this.state.attributeName.toString().length >= 15 ? <RcTooltip placement="top" overlay={renderTooltipSummary(this.state.attributeName)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                    <span className="portlet-table-value">{this.state.attributeName !== null ? this.state.attributeName : "NA"}</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{this.state.attributeName !== null ? this.state.attributeName : "NA"}</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Type :</label>
                                                    {
                                                    reqAttributeType.toString().length >= 15 ? 
                                                    <RcTooltip placement="top"
                                                                    overlay={renderTooltipSummary(reqAttributeType)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                        <span className="portlet-table-value">{reqAttributeType}</span>
                                                    </RcTooltip> 
                                                    : 
                                                    <span className="portlet-table-value">{reqAttributeType}</span>
                                                    }
                                                    
                                                </div>
                                                <div className="form-group">
                                                    <label>Empty :</label>
                                                    {!IS_NONE_CHECK.includes(profileData.empty.value) && profileData.empty.value.toString().length >= 15 ? <RcTooltip placement="top" overlay={renderTooltipSummary(profileData.empty.value)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                       <span className="portlet-table-value">{profileData.empty.value !== null ? profileData.empty.value : "NA"}</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{profileData.empty.value !== null ? profileData.empty.value : "NA"}</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Max :</label>
                                                    {!IS_NONE_CHECK.includes(profileData.max.value) && profileData.max.value.toString().length >= 15 ?
                                                    <RcTooltip placement="top" overlay={renderTooltipSummary(profileData.max.value)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                    <span className="portlet-table-value">{profileData.max.value !== null ? profileData.max.value : "NA"}</span>
                                                    </RcTooltip>
                                                    :<span className="portlet-table-value">{profileData.max.value !== null ? profileData.max.value : "NA"}</span>
                                                     }
                                                </div>
                                                <div className="form-group">
                                                    <label>Min :</label>
                                                    {!IS_NONE_CHECK.includes(profileData.min.value) && profileData.min.value.toString().length >= 15 ?
                                                    <RcTooltip placement="top" overlay={renderTooltipSummary(profileData.min.value)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                    <span className="portlet-table-value">{profileData.min.value !== null ? profileData.min.value : "NA"}</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{profileData.min.value !== null ? profileData.min.value : "NA"}</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Mean :</label>
                                                    {!IS_NONE_CHECK.includes(profileData.avg.value) && profileData.avg.value.toString().length >= 15 ?
                                                    <RcTooltip placement="top" overlay={renderTooltipSummary(profileData.avg.value)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                    <span className="portlet-table-value">{profileData.avg.value !== null? profileData.avg.value : "NA"}</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{profileData.avg.value !== null? profileData.avg.value : "NA"}</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Mode :</label>
                                                    {!IS_NONE_CHECK.includes(profileData.mode.value) && profileData.mode.value.toString().length >= 15 ?
                                                    <RcTooltip placement="top" overlay={renderTooltipSummary(profileData.mode.value)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                    <span className="portlet-table-value">{profileData.mode.value !== null ? profileData.mode.value : "NA"}</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{profileData.mode.value !== null ? profileData.mode.value : "NA"}</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Average :</label>
                                                    {!IS_NONE_CHECK.includes(profileData.avg.value) && profileData.avg.value.toString().length >= 15 ?
                                                    <RcTooltip placement="top" overlay={renderTooltipSummary(profileData.avg.value)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                    <span className="portlet-table-value">{profileData.avg.value !== null ? profileData.avg.value  : "NA"}</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{profileData.avg.value !== null ? profileData.avg.value  : "NA"}</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>STD :</label>
                                                    {!IS_NONE_CHECK.includes(profileData.std.value) && profileData.std.value.toString().length >= 15 ?
                                                    <RcTooltip placement="top" overlay={renderTooltipSummary(profileData.std.value)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                    <span className="portlet-table-value">{profileData.std.value !== null ? profileData.std.value : "NA"}</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{profileData.std.value !== null ? profileData.std.value : "NA"}</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Unique :</label>
                                                    {!IS_NONE_CHECK.includes(profileData.unique.value) && profileData.unique.value.toString().length >= 15 ?
                                                    <RcTooltip placement="top" overlay={renderTooltipSummary(profileData.unique.value)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                    <span className="portlet-table-value">{profileData.unique.value !==null ? profileData.unique.value : "NA" }</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{profileData.unique.value !==null ? profileData.unique.value : "NA" }</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Duplicate :</label>
                                                    {!IS_NONE_CHECK.includes(profileData.duplicate.value) && profileData.duplicate.value.toString().length >=15 ?
                                                    <RcTooltip placement="top" overlay={renderTooltipSummary(profileData.duplicate.value)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                    <span className="portlet-table-value">{profileData.duplicate.value !== null ? profileData.duplicate.value : "NA"}</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{profileData.duplicate.value !== null ? profileData.duplicate.value : "NA"}</span>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Business Rule :</label>
                                                    {!IS_NONE_CHECK.includes(this.state.businessRule) && this.state.businessRule.toString().length >= 15 ?
                                                    <RcTooltip placement="top" overlay={renderTooltipSummary(this.state.businessRule)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                    <span className="portlet-table-value">{this.state.businessRule !== null ? this.state.businessRule : "NA"}</span>
                                                    </RcTooltip>
                                                    :
                                                    <span className="portlet-table-value">{this.state.businessRule !== null ? this.state.businessRule : "NA"}</span>
                                                    }
                                                </div>
                          </div> : <Load/>);
             default:
                  return ''
         }

      }
}

export default Summary;