import React from 'react';
import Image from '../components/image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt, faSearch, faAsterisk, faClock, faChevronRight, faServer, faDatabase, faLayerGroup} from '@fortawesome/free-solid-svg-icons';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import $ from 'jquery';
import moment from "moment";
// import ganttChart1 from '../assets/img/gantt-chart1.png';
import { DISPLAY_DATE_FORMAT } from "../utils/constant";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
// import { Gantt, Task} from 'gantt-task-react';
import gantt_chart from '../assets/img/gantt-chart.png';
import "gantt-task-react/dist/index.css";
import 'react-rangeslider/lib/index.css';
import PipelineLineage from "./pipelineLineage";
import PipelineLineageErr from "./pipelineLineageErrTroubleShoot";
import Slider from 'react-rangeslider';

// let tasks: Task[] = [{
// 	"start": new Date(2023, 4, 5),
// 	"end": new Date(2023, 4, 15),
// 	"name": "Some Project",
// 	"id": "ProjectSample",
// 	"type": "project",
//     "isDisabled": true,
// 	"hideChildren": false
// }, {
// 	"start": new Date(2023, 4, 15),
// 	"end": new Date(2023, 4, 20),
// 	"name": "Idea",
//     "isDisabled": true,

// 	"id": "Task 0",
// 	"type": "task",
// 	"project": "ProjectSample"
// }, {
// 	"start": new Date(2023, 4, 20),
// 	"end": new Date(2023, 4, 25),
// 	"name": "Research",
// 	"id": "Task 1",
//     "isDisabled": true,

// 	"type": "task",
// 	"project": "ProjectSample"
// }, {
// 	"start": new Date(2023, 4, 25),
// 	"end": new Date(2023, 4, 30),
// 	"name": "Discussion with team",
// 	"id": "Task 2",
// 	"type": "task",
//     "isDisabled": true,

// 	"project": "ProjectSample"
// }, {
// 	"start": new Date(2023, 5, 20),
// 	"end": new Date(2023, 5, 25),
// 	"name": "Developing",
// 	"id": "Task 3",
// 	"type": "task",
//     "isDisabled": true,

// 	"project": "ProjectSample"
// }, {
// 	"start": new Date(2023, 4, 20),
// 	"end": new Date(2023, 4, 25),
// 	"name": "Review",
// 	"id": "Task 4",
// 	"type": "task",
//     "isDisabled": true,

// 	"project": "ProjectSample"
// }, {
// 	"start": new Date(2023, 4, 25),
// 	"end": new Date(2023, 4, 30),
// 	"name": "Release",
// 	"id": "Task 6",
// 	"type": "task",
//     "isDisabled": true,

// 	"project": "ProjectSample"
// }, {
// 	"start": new Date(2023, 4, 20),
// 	"end": new Date(2023, 4, 20),
// 	"name": "All Stage Completed",
// 	"id": "Task 9",
// 	"type": "task",
//     "isDisabled": true,

// 	"project": "ProjectSample"
// }]

export default class PipelinePerformFullScreen extends React.Component {
    constructor(props) {
        super(props);
        this.handleTab = this.handleTab.bind(this);
        this.gobackfullscreen = this.gobackfullscreen.bind(this);
        this.changeDataSourceFullScreen = this.changeDataSourceFullScreen.bind(this);
        this.showDetailsPipeline = this.showDetailsPipeline.bind(this);
        this.handleNestedTreeNode = this.handleNestedTreeNode.bind(this);

        this.state = {
            current_tab: "ji",
            totaljob: '',
            totalsucceed: '',
            totalfailedjobs: '',
            selectedDatasourceFullScreen: null,
            selectedDatasourceFullScreenID: null,
            jobname: null,
            runscount: null,
            jobsrunsuccesscnt: null,
            jobsrunfailedcnt: null,
            jobsrun: null,
            jobsrunfailed: null,
            filterData: null,
            troubleshooting: [],
            detailJobList:[],
            rangeValue: 0,
            detailviewid:""
        }
    }

    handleNestedTreeNode(pm, id) {
        $('#nested-node_' + pm).toggleClass("has-nested-nodes");
    }
    millisecondToMinutesAndSeconds(millis) {
        millis = parseInt(millis);
        if(isNaN(millis) === true){
          return;
        }
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        if(minutes<1){
            return seconds +"s";
        }else{
            return minutes + "m" + (seconds < 10 ? '' : '') + seconds +"s";
        }
    }
    formatUTCDate(Your_Date){
                return moment.utc(Your_Date).format("DD MMM, HH:MM")
    }

    gobackfullscreen() {
        $("#grant_chart_view").addClass("d-none")
        $("#remove_chart_view").removeClass("d-none")
        $(".lineage-graph-sidebar").removeClass("show")
        this.setState({
            detailviewid:""
        });
    }

    showDetailsPipeline(pm, test) {

       // alert(JSON.stringify(pm));
        this.setState({
            detailviewid: pm.job_run_id,
            detailviewstatus: pm.run_status_text,
            detailviewtiming: this.formatUTCDate(pm.run_started_time),
            detailviewsuccesscnt: pm.stage_count.stage_success_count,
            detailviewfailedcnt: pm.stage_count.stage_failed_count,
            detailviewjobname: pm.job_name,
            detailviewduration: this.millisecondToMinutesAndSeconds(pm.duration),
            detailviewstages: pm.stages
        })
        // $("#detailsid").html(pm)
        $("#grant_chart_view").removeClass("d-none")
        $("#remove_chart_view").addClass("d-none")
    }

    changeDataSourceFullScreen(selectedDataSource) {
        
        return 1;
        /*let selectedDataSourceID = selectedDataSource.value;
        // filterpipelinelist:JSON.parse(localStorage.getItem("piplineinitaldata")),
        let filterdat = this.props.filterpipelinelist;

        let filterdata;
        let filterdatlen = filterdat.length;


        for (let loop = 0; loop < filterdatlen; loop++) {
            if (filterdat[loop]["metadata"]["pipeline_name"] === selectedDataSourceID) {
                filterdata = filterdat[loop].jobs;
            }
        }


        this.setState({
            selectedDatasourceFullScreen: selectedDataSource,
            selectedDataSourceID: selectedDataSourceID,
            filterData: filterdata
        });
        */
    }


    handleTab(key) {
        this.setState({ current_tab: key });
    }


    componentDidMount() {
        // let filterdat = this.props.filterpipelinelist;
        let totaljob;
        let totalsucceed;
        let totalfailedjobs;
        let jobname;
        let runscount;
        let troubleshooting = [];
        let jobsrunsuccess = [];
        let jobsrunfailed = [];
        let jobsrun = [];
        // let last_run;

        // alert(this.props.detailPipelineName);

        // let filterdatlen = filterdat.length;
        // for (let loop = 0; loop < filterdatlen; loop++) {
        //     if (filterdat[loop]["metadata"]["pipeline_name"] === this.props.detailPipelineName) {
        //         totaljob = filterdat[loop]["metadata"]["total_number_jobs"];
        //         totalsucceed = filterdat[loop]["metadata"]["success_job"];
        //         totalfailedjobs = filterdat[loop]["metadata"]["failure_jobs"];
        //         let joblen = filterdat[loop]["jobs"].length;
        //         for (let loopinner = 0; loopinner < joblen; loopinner++) {
        //             if (filterdat[loop]["jobs"][loopinner]["job_id"] === this.props.detailJobId) {

        //                 jobname = filterdat[loop]["jobs"][loopinner]["job_name"];
        //                 runscount = filterdat[loop]["jobs"][loopinner]["runs"].length;
        //                 jobsrunsuccess = filterdat[loop]["jobs"][loopinner]["runs"].filter(function (el) {
        //                     return el.status === "Success";
        //                 });

        //                 jobsrunfailed = filterdat[loop]["jobs"][loopinner]["runs"].filter(function (el) {
        //                     return el.status === "Failed";
        //                 });

        //                 jobsrun = filterdat[loop]["jobs"][loopinner]["runs"];
        //                 troubleshooting[0] = jobname;
        //                 if (filterdat[loop]["jobs"][loopinner].hasOwnProperty('stages')) {
        //                     for (let stagesloopinner = 0; stagesloopinner < filterdat[loop]["jobs"][loopinner]['stages'].length; stagesloopinner++) {
        //                         troubleshooting.push(filterdat[loop]["jobs"][loopinner]['stages'][stagesloopinner]["job_name"]);
        //                     }
        //                 }
        //                 else {
        //                     alert(123)
        //                 }
        //             }
        //         }
        //     }
        // }



        // let filterdat1 = this.props.filterpipelinelist;
        // let filterdata1;
        // let filterdatlen1 = filterdat1.length;


        // for (let loop = 0; loop < filterdatlen1; loop++) {
        //     if (filterdat1[loop]["metadata"]["pipeline_name"] === this.props.detailPipelineName) {
        //         filterdata1 = filterdat1[loop].jobs;
        //     }
        // }


        this.setState({
            
            // filterData: filterdata1,
            selectedDataSourceID: this.props.detailPipelineName,
            selectedDatasourceFullScreen: { "label": this.props.detailPipelineName, "value": this.props.detailPipelineName },
            totaljob: totaljob,
            totalsucceed: totalsucceed,
            totalfailedjobs: totalfailedjobs,
            jobname: jobname,
            runscount: runscount,
            jobsrunsuccesscnt: jobsrunsuccess.length,
            jobsrunfailedcnt: jobsrunfailed.length,
            jobsrun: jobsrun,
            jobsrunfailed: jobsrunfailed,
            troubleshooting: troubleshooting,
            detailJobList:this.props.detailJobList
        });





    }


    statusIcon(pmStatus) {
        if (pmStatus === "Done") {
            return <div className="d-flex align-items-center text-success">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                <span className="ml-1">Done</span>
            </div>
        }
        else if (pmStatus === "Failed") {
            return <div className="d-flex align-items-center text-danger">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                <span className="ml-1"></span>
            </div>
        }
        else if (pmStatus === "Schedules" || pmStatus === "Scheduled") {
            return <div className="d-flex align-items-center text-purple">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                <span className="ml-1"></span>
            </div>
        }
        else if (pmStatus === "In Progre") {
            return <div className="d-flex align-items-center text-info">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5" /></g></svg>
                <span className="ml-1"></span>
            </div>
        }
        else if (pmStatus === "Queued") {
            return <div className="d-flex align-items-center text-warning">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2" /></svg>
                <span className="ml-1"></span>
            </div>
        }
    }





    statusIcon_old(pmStatus) {
        if (pmStatus === "Done") {
            return (<OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip >
                        Success
        </Tooltip>
                }
            >
                <i className="status-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#4ED2CC"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                </i>
            </OverlayTrigger>
            )
        }
        else if (pmStatus === "Failed") {
            return (<OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip >
                        Failed
        </Tooltip>
                }
            >
                <i className="status-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#F64E60"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                </i>
            </OverlayTrigger>)
        }
        else if (pmStatus === "Schedules" || pmStatus === "Scheduled") {
            return <div className="d-flex align-items-center text-purple">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
            </div>
        }
        else if (pmStatus === "In Progre") {

            return (<OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip >
                        Failed
            </Tooltip>
                }
            >
                <i className="status-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#F64E60"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                </i>
            </OverlayTrigger>)

        }
    }

    setRangeValue = (event) => {
        this.setState({rangeValue : event});
    }

    render() {

        // alert(this.state.selectedDataSourceID);


        //
        console.log("val",this.state.detailviewstatus)

        return (
            <>
                <div className="qd-fullscreen qd-pipeline-fullscreen show">
                    <div className="qd-fs_dialog">
                        <div className="qd-fs_content bg-white">
                            <div className="qd-fs_header">
                                <div className="header_content">
                                    <Image
                                        className="qd-header__brand-logo-default"
                                        src="logo"
                                    />
                                </div>
                                <div className="actions">
                                    <div className="actions_left">
                                        <Dropdown className="qd-env-dropdown qd-env--light" alignRight>
                                            <Dropdown.Toggle 
                                                variant=""
                                                className="qd-env-dropdown__btn"
                                            >
                                                <span className="env-tag">E</span>
                                                <span className="env-info">
                                                    <label>Pipeline Environment</label>
                                                    <strong className="env-name">Environment</strong>
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div className="qd-search-bar">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <button id="button-addon4" type="button" className="btn"><FontAwesomeIcon icon={faSearch} /></button>
                                                        </div>
                                                        <input type="search" placeholder="Search..." className="form-control" />
                                                    </div>
                                                </div>
                                                <Dropdown.Header>Pipeline Environment</Dropdown.Header>
                                                <Dropdown.Item><span className="env-tag" style={{backgroundColor: '#00c7d4'}}>M</span>My Environment</Dropdown.Item>
                                                <Dropdown.Item><span className="env-tag" style={{backgroundColor: '#ffc700'}}>P</span>Production</Dropdown.Item>
                                                <Dropdown.Item><span className="env-tag" style={{backgroundColor: '#4d9ff9'}}>E</span>Environment</Dropdown.Item>
                                                <Dropdown.Item><span className="env-tag" style={{backgroundColor: '#915cff'}}>V</span>Verizon envi</Dropdown.Item>
                                                <Dropdown.Item><span className="env-tag" style={{backgroundColor: '#ff6c7c'}}>D</span>Dev envi</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        {/* <Dropdown className="has-env-tag">
                                        <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
                                            <div className="tag">
                                                <span>E</span>
                                            </div>
                                            <i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>
                                            <span className="ml-1">Test</span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-3">
                                                <i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>
                                                <span className="ml-1">Test</span>
                                            </Dropdown.Item>
                                                 <Dropdown.Item href="#/action-1">
                                                <i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>
                                                <span className="ml-1">Pipe_source_3</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                                <i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>
                                                <span className="ml-1">Pipe_source_4</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">
                                                <i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>
                                                <span className="ml-1">Pipe_source_5</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                        </Dropdown> */}
                                    </div>
                                    <div className="actions_right">
                                        {/* <DateRangePicker containerClass="btn btn-datapicker reportrange mx-2"
                                            startDate={this.props.startDate}
                                            endDate={this.props.endDate}
                                            key={`date_${this.state.keyData}`}
                                            ranges={this.props.ranges}>
                                            <i>
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </i>
                                            <span
                                                className="ml-2 d-none d-md-inline-block">
                                                {this.props.startDate.format(DISPLAY_DATE_FORMAT)} - {this.props.endDate.format(DISPLAY_DATE_FORMAT)}
                                            </span>
                                        </DateRangePicker> */}


                                    <DateRangePicker containerClass="btn btn-datapicker reportrange mx-2"
                                        singleDatePicker={true}
                                        >
                                        <i>
                                            <FontAwesomeIcon icon={faCalendar} />
                                        </i>
                                        <span
                                            className="ml-2 d-none d-md-inline-block">
                                           {this.props.endDate.format(DISPLAY_DATE_FORMAT)}
                                        </span>
                                    </DateRangePicker>

                                        <button type="button" onClick={this.props.closeFullScreenMode}
                                        className="close" aria-label="Close">
                                        <span aria-hidden="true" />
                                        <span className="sr-only">Close</span>
                                    </button>
                                    </div>
                                </div>
                            </div>
                            <div className="qd-fs_body overflow-hidden p-0">
                                <div className="qd-grid">
                                    <div className="qd-fs_aside">
                                        <div className="tree-wrap">
                                            <div className="tree__header">
                                                <div className="py-2 border-bottom">
                                                    {/* <small className="text-muted">{this.props.pipelinelist.length} Pipeline Datasource Configured</small> */}
                                                    {/* <Dropdown>
                                                            <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
                                                                <i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>
                                                                <span className="ml-1">Pipe_source_1</span>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-3">
                                                                    <i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>
                                                                    <span className="ml-1">Pipe_source_2</span>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-1">
                                                                    <i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>
                                                                    <span className="ml-1">Pipe_source_3</span>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2">
                                                                    <i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>
                                                                    <span className="ml-1">Pipe_source_4</span>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-3">
                                                                    <i className="mr-1"><FontAwesomeIcon icon={faDatabase} /></i>
                                                                    <span className="ml-1">Pipe_source_5</span>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown> */}

                                                    <Select
                                                        name="datasource1"
                                                        filterOption={({ label }, query) => label.includes(query)}
                                                        defaultValue={this.state.selectedDatasourceFullScreen}
                                                        id="chooseDatasourceDqError1"
                                                        options={this.props.dataSourceOption}
                                                        onChange={this.changeDataSourceFullScreen}
                                                        value={this.state.selectedDatasourceFullScreen}
                                                        classNamePrefix='form-control'
                                                        placeholder="Pipeline Datasource"
                                                    />

                                                </div>
                                                <div className="tree__title">
                                                    <div className="caption">
                                                        <span className="text-uppercase">Jobs</span>
                                                    </div>
                                                    <div className="action">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="light" className="btn-sm mr-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#AAAEB2"><path d="M0 0h24v24H0V0" fill="none" /><path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2" /></svg>
                                                                <span className="sr-only">Sort</span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheck"
                                                                            id="pipeline-running" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-running">
                                                                            Name
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-1" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheck"
                                                                            id="pipeline-scheduled" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-scheduled">
                                                                            Last Run Time
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheck"
                                                                            id="pipeline-progress" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-progress">
                                                                            Duration
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheck"
                                                                            id="pipeline-queued" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-queued">
                                                                            Sort by Failed
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="light" className="btn-sm">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#AAAEB2"><path d="M0 0h24v24H0V0" fill="none" /><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6" /></svg>
                                                                <span className="sr-only">Filter</span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheck"
                                                                            id="pipeline-running" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-running">
                                                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clip-path="url(#clip0_263_199)">
                                                                                    <path d="M16.24 7.76C15.07 6.59 13.54 6 12 6V12L7.76 16.24C10.1 18.58 13.9 18.58 16.25 16.24C18.59 13.9 18.59 10.1 16.24 7.76V7.76ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20" fill="currentColor" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_263_199">
                                                                                        <rect width="24" height="24" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                            <span className="ml-1">Running</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-1" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheck"
                                                                            id="pipeline-scheduled" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-scheduled">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                            <span className="ml-1">Scheduled</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheck"
                                                                            id="pipeline-progress" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-progress">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5" /></g></svg>
                                                                            <span className="ml-1">In Progress</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheck"
                                                                            id="pipeline-queued" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-queued">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2" /></svg>
                                                                            <span className="ml-1">Queued</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheck"
                                                                            id="pipeline-done" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-done">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                                                                            <span className="ml-1">Done</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheck"
                                                                            id="pipeline-failed" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-failed">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                            <span className="ml-1">Failed</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                {/* Start:Search Box */}
                                                <div className="qd-search-box">
                                                    <input className="form-control" placeholder="search" />
                                                </div>
                                                {/* End:Search Box */}
                                            </div>
                                            <div className="tree__body">
                                                <div className="tree__group">
                                                    <div className="tree__group-header">
                                                        <h6 className="title">Pipeline_zero</h6>
                                                        <ol className="breadcrumb">
                                                            <li className="breadcrumb-item">
                                                                <i><svg xmlns="http://www.w3.org/2000/svg"  className="d-block" enable-background="new 0 0 24 24" height="12px" viewBox="0 0 24 24" width="12px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M15,22h4c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-1v-2c0-1.1-0.9-2-2-2h-3V9h1c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2h-4 C8.9,2,8,2.9,8,4v3c0,1.1,0.9,2,2,2h1v2H8c-1.1,0-2,0.9-2,2v2H5c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-3 c0-1.1-0.9-2-2-2H8v-2h8v2h-1c-1.1,0-2,0.9-2,2v3C13,21.1,13.9,22,15,22" /></g></svg></i> 
                                                                20
                                                            </li>
                                                        </ol>
                                                    </div>
                                                    <div className="tree__group-body">
                                                        <div className="tree">
                                                            <ul className="tree__nodes">
                                                                {

                                                                    this.state.detailJobList !== null && this.state.detailJobList !== undefined ?
                                                                        this.state.detailJobList.map((pipeline, pipelineindex) => {

                                                                            return (<li id={"nested-node_" + pipelineindex} className="tree__node">
                                                                                <div className="tree__node-item">
                                                                                    <div className="tree__node-label">
                                                                                        <div className="tree__node-toggle" onClick={this.handleNestedTreeNode.bind(this, pipelineindex)}>
                                                                                            <i className="tree__node-toggle-icon"></i>
                                                                                        </div>
                                                                                        <span className="tree__node-text">{pipeline.job_name}</span>
                                                                                    </div>
                                                                                    {this.statusIcon(pipeline.status)}
                                                                                </div>
                                                                                <ul className="tree__nodes tree__node--nested">

                                                                                    {/* {pipeline.stages.map((stage, index) => {
                                                                                        return (
                                                                                            <li className="tree__node">
                                                                                                <div className="tree__node-item">
                                                                                                    <div className="tree__node-label">
                                                                                                        <div className="tree__node-toggle">
                                                                                            <i className="tree__node-toggle-icon"></i>
                                                                                        </div>
                                                                                                        <span className="tree__node-text">{stage.job_name}</span>
                                                                                                    </div>
                                                                                                    {this.statusIcon(stage.status)}
                                                                                                </div>
                                                                                            </li>)

                                                                                    })} */}
                                                                                </ul>
                                                                            </li>)

                                                                        }) : ""

                                                                }

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="tree d-none">
                                                    <ul className="tree__nodes">
                                                        <li id="nested-node1" className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle" onClick={this.handleNestedTreeNode.bind()}>
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">everything_everywhere_job</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Running
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clip-path="url(#clip0_263_199)">
                                                                                <path d="M16.24 7.76C15.07 6.59 13.54 6 12 6V12L7.76 16.24C10.1 18.58 13.9 18.58 16.25 16.24C18.59 13.9 18.59 10.1 16.24 7.76V7.76ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20" fill="#43CED7" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_263_199">
                                                                                    <rect width="24" height="24" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </i>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <ul className="tree__nodes tree__node--nested">
                                                                <li className="tree__node">
                                                                    <div className="tree__node-item">
                                                                        <div className="tree__node-label">
                                                                            <div className="tree__node-toggle">
                                                                                <i className="tree__node-toggle-icon"></i>
                                                                            </div>
                                                                            <span className="tree__node-text">per_stage_system_lag</span>
                                                                        </div>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip >
                                                                                    Queued
                                                                            </Tooltip>
                                                                            }
                                                                        >
                                                                            <i className="status-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill=" #FFA800"><path d="M0 0h24v24H0" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2" /></svg>
                                                                            </i>
                                                                        </OverlayTrigger>

                                                                    </div>
                                                                </li>
                                                                <li className="tree__node">
                                                                    <div className="tree__node-item">
                                                                        <div className="tree__node-label">
                                                                            <div className="tree__node-toggle">
                                                                                <i className="tree__node-toggle-icon"></i>
                                                                            </div>
                                                                            <span className="tree__node-text">backlog_bytes</span>
                                                                        </div>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip >
                                                                                    In Progress
                                                                            </Tooltip>
                                                                            }
                                                                        >
                                                                            <i className="status-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill=" #4CA3FF"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5" /></g></svg>
                                                                            </i>
                                                                        </OverlayTrigger>

                                                                    </div>
                                                                </li>
                                                                <li className="tree__node">
                                                                    <div className="tree__node-item">
                                                                        <div className="tree__node-label">
                                                                            <div className="tree__node-toggle">
                                                                                <i className="tree__node-toggle-icon"></i>
                                                                            </div>
                                                                            <span className="tree__node-text">backlog_elements</span>
                                                                        </div>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip >
                                                                                    Success
                                                                            </Tooltip>
                                                                            }
                                                                        >
                                                                            <i className="status-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#4ED2CC"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                                                                            </i>
                                                                        </OverlayTrigger>

                                                                    </div>
                                                                </li>
                                                                <li className="tree__node">
                                                                    <div className="tree__node-item">
                                                                        <div className="tree__node-label">
                                                                            <div className="tree__node-toggle">
                                                                                <i className="tree__node-toggle-icon"></i>
                                                                            </div>
                                                                            <span className="tree__node-text">total_limit</span>
                                                                        </div>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip >
                                                                                    Failed
                                                                            </Tooltip>
                                                                            }
                                                                        >
                                                                            <i className="status-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#F64E60"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                            </i>
                                                                        </OverlayTrigger>

                                                                    </div>
                                                                </li>
                                                                <li className="tree__node">
                                                                    <div className="tree__node-item">
                                                                        <div className="tree__node-label">
                                                                            <div className="tree__node-toggle">
                                                                                <i className="tree__node-toggle-icon"></i>
                                                                            </div>
                                                                            <span className="tree__node-text">per_stage_system_lag</span>
                                                                        </div>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip >
                                                                                    Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                            }
                                                                        >
                                                                            <i className="status-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                            </i>
                                                                        </OverlayTrigger>

                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">refresh_forcast_model_job</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">timers_pending_count</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">write_latencies</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">per_stage_system_lag</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Queued
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill=" #FFA800"><path d="M0 0h24v24H0" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2" /></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">backlog_bytes</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            In Progress
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill=" #4CA3FF"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5" /></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">backlog_elements</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Success
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#4ED2CC"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">total_limit</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Failed
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#F64E60"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">per_stage_system_lag</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">estimated_backlog_proc</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">everything_everywhere_job</span>
                                                                </div>
                                                                <i className="status-icon">
                                                                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g clip-path="url(#clip0_263_199)">
                                                                            <path d="M16.24 7.76C15.07 6.59 13.54 6 12 6V12L7.76 16.24C10.1 18.58 13.9 18.58 16.25 16.24C18.59 13.9 18.59 10.1 16.24 7.76V7.76ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20" fill="#43CED7" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_263_199">
                                                                                <rect width="24" height="24" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">refresh_forcast_model_job</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">timers_pending_count</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">write_latencies</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">per_stage_system_lag</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Queued
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill=" #FFA800"><path d="M0 0h24v24H0" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2" /></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">backlog_bytes</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            In Progress
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill=" #4CA3FF"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5" /></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">backlog_elements</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Success
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#4ED2CC"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">total_limit</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Failed
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#F64E60"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">per_stage_system_lag</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">estimated_backlog_proc</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">per_stage_system_lag</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Queued
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill=" #FFA800"><path d="M0 0h24v24H0" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2" /></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">backlog_bytes</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            In Progress
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill=" #4CA3FF"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5" /></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">backlog_elements</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Success
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#4ED2CC"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">total_limit</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Failed
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#F64E60"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">per_stage_system_lag</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                        <li className="tree__node">
                                                            <div className="tree__node-item">
                                                                <div className="tree__node-label">
                                                                    <div className="tree__node-toggle">
                                                                        <i className="tree__node-toggle-icon"></i>
                                                                    </div>
                                                                    <span className="tree__node-text">estimated_backlog_proc</span>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip >
                                                                            Schedule: <strong>At 00:00 GMT, Only Sunday</strong>.
                                                                            </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="status-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                    </i>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            */}

                                        </div>
                                    </div>
                                    <div className="qd-fs_main-content">
                                    <div className="qd-pipeline-fs_content" id="remove_chart_view">
                                        {/* Start: Pipeline Details */}
                                        <div className="qd-pipeline-details">
                                            <div className="qd-pipeline__name">
                                                <i className="mr-1"><FontAwesomeIcon icon={faAsterisk} /></i>{this.props.detailPipelineName}
                                                <div className="divider"></div>
                                                <span className="badge">



{
    console.log(this.props.selectedPipelineInfo)
}

{ 

this.props.selectedPipelineInfo["pipeline_type"] === "synapse" ?
            <Image src="connector_logo_azure_synapse" alt="" /> :
            this.props.selectedPipelineInfo["pipeline_type"] === "airflow" ?   
           <Image src="connector_logo_airflow" alt="" />
          :
          this.props.selectedPipelineInfo["pipeline_type"] === "adf" ?   
          <Image src="connector_logo_adf" alt="" />
          :
          "" 
      }
        </span>
                                            </div>
                                            <ul className="qd-pipeline__stats">
                                                    <li>
                                                        <div className="badge badge-white">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M15,22h4c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-1v-2c0-1.1-0.9-2-2-2h-3V9h1c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2h-4 C8.9,2,8,2.9,8,4v3c0,1.1,0.9,2,2,2h1v2H8c-1.1,0-2,0.9-2,2v2H5c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-3 c0-1.1-0.9-2-2-2H8v-2h8v2h-1c-1.1,0-2,0.9-2,2v3C13,21.1,13.9,22,15,22" /></g></svg></i>
                                                            <span>
                                                                <label className="mr-1">Total Jobs -</label>
                                                                <strong>{this.props.selectedPipelineInfo.totaljobs}</strong>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="badge badge-light-success text-dark">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg></i>
                                                            <span>
                                                                <label className="mr-1">Succeeded Jobs -</label>
                                                                <strong>{this.props.selectedPipelineInfo.successjobs}</strong>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="badge badge-light-danger text-dark">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg></i>
                                                            <span>
                                                                <label className="mr-1">Failed Jobs -</label>
                                                                <strong>{this.props.selectedPipelineInfo.failedjobs}</strong>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="badge badge-light-orange text-dark">
                                                            <i className="mr-1"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.583 6.597h1.064v1.058h-2.72a.607.607 0 0 0-.612.6v1.147H3.282a.611.611 0 0 0-.616.605v2.72c0 .335.277.606.616.606H6.05c.34 0 .616-.272.617-.605v-2.721a.611.611 0 0 0-.616-.605H5.02V8.347h5.961v1.055H9.948a.611.611 0 0 0-.616.605v2.72c0 .335.276.606.616.606h.135a2.67 2.67 0 0 1 3.25-3.25v-.076a.611.611 0 0 0-.616-.605h-1.031V8.256c0-.331-.275-.601-.612-.601H8.352V6.597h1.065A.577.577 0 0 0 10 6.024V3.24a.579.579 0 0 0-.584-.574H6.583a.579.579 0 0 0-.584.574v2.784c0 .316.262.573.584.573zm6.073 6.36-.7.7-.29-.29.7-.7-.7-.7.3-.3.7.7.7-.7.3.3-.7.7.69.69-.3.3-.7-.7zm-1.405 1.124c.39.39.862.585 1.415.585s1.025-.194 1.415-.585c.39-.39.585-.861.585-1.415 0-.553-.195-1.024-.585-1.415a1.928 1.928 0 0 0-1.415-.585c-.553 0-1.025.195-1.415.585-.39.39-.585.862-.585 1.415 0 .554.195 1.025.585 1.415z" fill="currentColor"/></svg></i>
                                                            <span>
                                                                <label className="mr-1">Cancelled Jobs -</label>
                                                                <strong>0</strong>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="badge badge-light-blue text-dark">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="14" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5" /></g></svg></i>
                                                            <span>
                                                                <label className="mr-1">In Progress -</label>
                                                                <strong>0</strong>
                                                            </span>
                                                        </div>
                                                    </li>
                                                </ul>
                                        </div>
                                        {/* End: Pipeline Details */}
                                        {/* Start: Pipeline Jobs Info */}
                                        <div className="qd-jobs-wrap">
                                            <Tab.Container  defaultActiveKey={this.state.current_tab} onSelect={this.handleTab}>
                                                <div className="qd-jobs__header">
                                                    <div className="qd-jobs__header-title">
                                                        <h5><i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M15,22h4c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-1v-2c0-1.1-0.9-2-2-2h-3V9h1c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2h-4 C8.9,2,8,2.9,8,4v3c0,1.1,0.9,2,2,2h1v2H8c-1.1,0-2,0.9-2,2v2H5c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-3 c0-1.1-0.9-2-2-2H8v-2h8v2h-1c-1.1,0-2,0.9-2,2v3C13,21.1,13.9,22,15,22" /></g></svg></i>{this.props.selectedPipelineInfo.job_name}</h5>
                                                        <div className="divider"></div>
                                                        <div className="qd-jobs__stats">
                                                            <div className="badge badge-light mr-2">
                                                                <i className="mr-1"><svg xmlns="http://Fwww.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg></i>
                                                                <span>Latest Run: <strong>{this.formatUTCDate(this.props.selectedPipelineInfo.last_run)}</strong><span className="divider"></span><strong>{this.millisecondToMinutesAndSeconds(this.props.selectedPipelineInfo.last_duration)}</strong></span>
                                                            </div>
                                                            <div className="badge badge-light mr-2">
                                                                <i className="mr-1"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.986 13.667c-1.443 0-2.701-.475-3.774-1.426-1.073-.95-1.69-2.142-1.853-3.574h1.02a4.56 4.56 0 0 0 1.555 2.863c.868.758 1.886 1.137 3.052 1.137 1.3 0 2.403-.453 3.309-1.358C12.2 10.402 12.653 9.3 12.653 8c0-1.3-.453-2.403-1.358-3.308-.906-.906-2.009-1.359-3.309-1.359-.728 0-1.41.162-2.047.486a5.039 5.039 0 0 0-1.645 1.335h1.744v1H2.653V2.769h1v1.58a5.659 5.659 0 0 1 1.944-1.487 5.579 5.579 0 0 1 2.39-.529c.786 0 1.522.15 2.21.447a5.737 5.737 0 0 1 1.797 1.212c.511.512.915 1.11 1.213 1.798.297.687.446 1.424.446 2.21s-.149 1.523-.446 2.21a5.737 5.737 0 0 1-1.213 1.798 5.737 5.737 0 0 1-1.797 1.213 5.503 5.503 0 0 1-2.21.446zm2.002-2.982L7.506 8.203V4.667h1v3.13l2.184 2.185-.702.703z" fill="#000" fill-opacity=".5"/></svg></i>
                                                                <span>Avg.Duration <strong>{this.millisecondToMinutesAndSeconds(this.props.selectedPipelineInfo.last_duration)}</strong></span>
                                                            </div>
                                                            <div className="badge badge-light mr-2">
                                                                <i className="mr-1"><svg xmlns="http://Fwww.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg></i>
                                                                <span>At <strong>00:00 GMT,</strong> Daily (@daily)</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tabbable-line">
                                                        <Nav variant="tabs" id="page-nav-tabs" className="pl-1">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="ji" >
                                                                    <span className="qd-menu__link-text">Jobs Info</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="jr">
                                                                    <span className="qd-menu__link-text">All Runs</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="fr">
                                                                    <span className="qd-menu__link-text">Failed Runs</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="td">
                                                                    <span className="qd-menu__link-text">Lineage</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>

                                                </div>
                                                <div className="qd-jobs__content">
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="ji" className="p-3">
                                                                <ul className="qd-jobs__status">
                                                                    <li>
                                                                        <div className="qd-jobs__status-card">
                                                                            <div className="card-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M15 7.5V2H9v5.5l3 3 3-3zM7.5 9H2v6h5.5l3-3-3-3zM9 16.5V22h6v-5.5l-3-3-3 3zM16.5 9l-3 3 3 3H22V9h-5.5" /></svg>
                                                                            </div>
                                                                            <div className="card-content">
                                                                                <label className="card-label">Total Runs</label>
                                                                                <p className="card-text"><strong>{this.props.selectedJobDetails!=="" ? this.props.selectedJobDetails.pipeline_metadata.run_counts.total_run : ""}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="qd-jobs__status-card">
                                                                            <div className="card-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                                                                            </div>
                                                                            <div className="card-content">
                                                                                <label className="card-label">Success Runs</label>
                                                                                <p className="card-text"><strong>{this.props.selectedJobDetails!=="" ? this.props.selectedJobDetails.pipeline_metadata.run_counts.run_success_count : ""}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="qd-jobs__status-card">
                                                                            <div className="card-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                            </div>
                                                                            <div className="card-content">
                                                                                <label className="card-label">Failed Runs</label>
                                                                                <p className="card-text"><strong>{this.props.selectedJobDetails!=="" ? this.props.selectedJobDetails.pipeline_metadata.run_counts.run_failed_count : ""}</strong></p>
                                                                                {/* <p className="card-text"><strong>{this.props.selectedJobDetails.run_counts.run_failed_count}</strong></p> */}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    {/* <li>
                                                                        <div className="qd-jobs__status-card">
                                                                            <div className="card-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7" /></svg>
                                                                            </div>
                                                                            <div className="card-content">
                                                                                <label className="card-label">Triggered Success Runs</label>
                                                                                <p className="card-text"><strong>{this.state.jobsrunsuccesscnt}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="qd-jobs__status-card">
                                                                            <div className="card-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7" /></svg>
                                                                            </div>
                                                                            <div className="card-content">
                                                                                <label className="card-label">Triggered Failed Runs</label>
                                                                                <p className="card-text"><strong>{this.state.jobsrunfailedcnt}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </li> */}
                                                                    <li>
                                                                        <div className="qd-jobs__status-card">
                                                                            <div className="card-icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.15 7.054V2.55c0-.577-.473-1.05-1.05-1.05H9.9c-.578 0-1.05.472-1.05 1.05v4.505c0 .136.053.272.158.367l2.625 2.625c.21.21.535.21.745 0l2.625-2.625a.507.507 0 0 0 .147-.367zM7.054 8.85H2.55c-.577 0-1.05.473-1.05 1.05v4.2c0 .578.472 1.05 1.05 1.05h4.505a.493.493 0 0 0 .367-.158l2.625-2.625a.52.52 0 0 0 0-.745L7.422 8.997a.507.507 0 0 0-.367-.147zm1.796 8.095v4.505c0 .578.473 1.05 1.05 1.05h4.2c.274 0 .524-.106.711-.28a5.23 5.23 0 0 1-1.311-3.47c0-1.044.305-2.017.83-2.834l-1.963-1.963a.52.52 0 0 0-.745 0l-2.625 2.625a.507.507 0 0 0-.147.367zM22.5 14.11c0 .271-.104.519-.274.706A5.23 5.23 0 0 0 18.75 13.5c-1.05 0-2.027.308-2.847.838l-1.96-1.96a.52.52 0 0 1 0-.745l2.624-2.625a.47.47 0 0 1 .378-.147h4.505c.578 0 1.05.472 1.05 1.05v4.2zm-3.52 5.47-1.4 1.4-.58-.58 1.4-1.4-1.4-1.4.6-.6 1.4 1.4 1.4-1.4.6.6-1.4 1.4 1.38 1.38-.6.6-1.4-1.4zm-2.81 2.25c.78.78 1.723 1.17 2.83 1.17s2.05-.39 2.83-1.17C22.61 21.05 23 20.107 23 19s-.39-2.05-1.17-2.83C21.05 15.39 20.107 15 19 15s-2.05.39-2.83 1.17C15.39 16.95 15 17.893 15 19s.39 2.05 1.17 2.83z" fill="currentColor"/>
                                                                            </svg>
                                                                            </div>
                                                                            <div className="card-content">
                                                                                <label className="card-label">Cancelled Runs</label>
                                                                                <p className="card-text"><strong>{this.state.jobsrunfailedcnt}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>

                                                                <table className="table table-bordered">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="250">Latest Tick</td>
                                                                            <td><i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8" /><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67" /></svg></i>{this.formatUTCDate(this.props.selectedPipelineInfo.last_run)} GMT</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Job</td>
                                                                            <td><i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M15,22h4c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-1v-2c0-1.1-0.9-2-2-2h-3V9h1c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2h-4 C8.9,2,8,2.9,8,4v3c0,1.1,0.9,2,2,2h1v2H8c-1.1,0-2,0.9-2,2v2H5c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-3 c0-1.1-0.9-2-2-2H8v-2h8v2h-1c-1.1,0-2,0.9-2,2v3C13,21.1,13.9,22,15,22" /></g></svg></i>{this.props.selectedPipelineInfo.job_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Schedule</td>
                                                                            <td>At 00:00 GMT, Daily (@daily)</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Execution Timezone</td>
                                                                            <td>GMT</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Description</td>
                                                                            <td>None</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Resources</td>
                                                                            <td>None</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Metadata</td>
                                                                            <td>None</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            
                                                                <div className="portlet d-none">
                                                                    <div className="portlet-title">
                                                                        <div className="caption">
                                                                            <span className="font-dark">Stages</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="portlet-body" style={{height: '200px'}}></div>
                                                                </div>
                                                            </Tab.Pane>
                                                        <Tab.Pane eventKey="jr" className="p-3">
                                                                <div className="portlet">
                                                                    <div className="portlet-title">
                                                                        <div className="caption">
                                                                            <span className="font-dark"><span className="text-muted">Job:</span>{this.props.selectedPipelineInfo.job_name}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="portlet-body">
                                                                        <div className="react-bootstrap-table__header">
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <div className="btn-group react-table__length" role="group">
                                                                                        <span>Show</span>
                                                                                        <select className="select-sm" style={{ backgroundColor: "#fff", border: "1px solid #CED4DA", padding: "4px 10px", margin: "0 5px", color: "#484848" }}>
                                                                                            <option>All</option>
                                                                                            <option>10</option>
                                                                                        </select>
                                                                                        <span>items</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="btn-group react-table__filter" role="group">
                                                                                        <span>Search:</span>
                                                                                        <div className="table-search">
                                                                                            <input placeholder="Search" id="searchBox"
                                                                                                className="form-control" style={{ backgroundColor: "#ffffff" }}
                                                                                                type="text" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="react-bootstrap-table table-responsive">
                                                                            <table className="table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>R.No</th>
                                                                                        <th>Run ID</th>
                                                                                        <th>Timing</th>
                                                                                        <th>Job</th>
                                                                                        <th>Status</th>
                                                                                        {/* <th>Actions</th> */}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>

                                                                                    {
                                                                                        this.props.selectedJobDetails.runs !== undefined ?
                                                                                        this.props.selectedJobDetails.runs.map((pipeline, pipelineindex) => {
                                                                                                return (
                                                                                                    <tr>
                                                                                                        <td>{pipelineindex + 1}</td>
                                                                                                        <td><a  href="#/"  className="text-info" onClick={this.showDetailsPipeline.bind(this, pipeline)}>{pipeline.job_run_id}</a></td>

                                                                                                        <td>
                                                                                                            <dl>
                                                                                                                <dt>
                                                                                                                    {this.formatUTCDate(pipeline['run_started_time'])}</dt>
                                                                                                                <dd className="text-muted"><i><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20" /></g></g></g></svg></i> {this.millisecondToMinutesAndSeconds(pipeline.duration)}</dd>
                                                                                                            </dl>

                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <dl>
                                                                                                                <dt className="mb-2"> {pipeline['job_name']} <a href="#/"  className="text-info" ><i className="ml-1"><FontAwesomeIcon icon={faExternalLinkAlt} /></i></a></dt>
                                                                                                                <dd style={{ Width: "600px", whiteSpace: 'normal' }}>
                                                                                                                    {pipeline.stage_count.stages_name.map((stages) => {

                                                                                                                        return (<span className="badge badge-light mr-1 mb-1">{stages}</span>)

                                                                                                                    })}
                                                                                                                </dd>
                                                                                                            </dl>


                                                                                                        </td>

                                                                                                        <td>

                                                                                                            {pipeline.run_status_text === "Succeeded" ?
                                                                                                                <div className="badge badge-light-success">
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg><span className="ml-1">Success</span>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div className="badge badge-light-danger">
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg><span className="ml-1">Failed</span>
                                                                                                                </div>

                                                                                                            }
                                                                                                        </td>
                                                                                                        {/*<td>&nbsp;</td>*/}
                                                                                                    </tr>);

                                                                                            })
                                                                                            : ""
                                                                                    }


                                                                                    <tr>
                                                                                            <td>1</td>
                                                                                            <td><a className="text-info" onClick={this.gobackfullscreen} href="#!">6efee51f</a></td>
                                                                                            <td>
                                                                                                <dl>
                                                                                                    <dt>25 Dec, 05:30</dt>
                                                                                                    <dd className="text-muted"><i><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20" /></g></g></g></svg></i> 0:00:31 GMT</dd>
                                                                                                </dl>
                                                                                            </td>
                                                                                            <td>
                                                                                                <dl>
                                                                                                    <dt className="mb-2">timers_pending_count <a className="text-info" href="#!"><i className="ml-1"><FontAwesomeIcon icon={faExternalLinkAlt} /></i></a></dt>
                                                                                                    <dd style={{ Width: "600px", whiteSpace: 'normal' }}>
                                                                                                        <span className="badge badge-light mr-1 mb-1">refresh_forcast_model_job_schedule</span>
                                                                                                        <span className="badge badge-light mr-1 mb-1">Git_commit_hash: 0b0ef69167f34b20231bd53</span>
                                                                                                        <span className="badge badge-light mr-1 mb-1">Git_commit_hash: 0b0ef69167f34b20231bd53</span>
                                                                                                        <span className="badge badge-light">refresh_forcast_model_job_schedule</span>
                                                                                                    </dd>
                                                                                                </dl>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="badge badge-light-success">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg><span className="ml-1">Success</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>1</td>
                                                                                            <td><a className="text-info" href="#!">6efee51f</a></td>
                                                                                            <td>
                                                                                                <dl>
                                                                                                    <dt>25 Dec, 05:30</dt>
                                                                                                    <dd className="text-muted"><i><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20" /></g></g></g></svg></i> 0:00:31 GMT</dd>
                                                                                                </dl>
                                                                                            </td>
                                                                                             <td>
                                                                                                <dl>
                                                                                                    <dt className="mb-2">timers_pending_count <a className="text-info" href="#!"><i><FontAwesomeIcon icon={faExternalLinkAlt} /></i></a></dt>
                                                                                                    <dd style={{ Width: "600px", whiteSpace: 'normal' }}>
                                                                                                        <span className="badge badge-light mr-1 mb-1">refresh_forcast_model_job_schedule</span>
                                                                                                        <span className="badge badge-light mr-1 mb-1">Git_commit_hash: 0b0ef69167f34b20231bd53</span>
                                                                                                        <span className="badge badge-light">refresh_forcast_model_job_schedule</span>
                                                                                                    </dd>
                                                                                                </dl>
                                                                                            </td> 
                                                                                            <td>
                                                                                                <div className="badge badge-light-danger">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg><span className="ml-1">Failed</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="react-bootstrap-table__footer">
                                                                            <div className="row">
                                                                                <div className="col-sm-12 col-md-6">
                                                                                    {/* <span className="react-table__info">Showing <b>1</b> to <b>
                                                                                        {
                                                                                            this.state.jobsrun !== null ? this.state.jobsrun.length : 0}
                                                                                    </b> of
                                                                                            <b> {
                                                                                            this.state.jobsrun !== null ? this.state.jobsrun.length : 0}</b> results</span> */}
                                                                                </div>
                                                                                <div className="col-sm-12 col-md-6">
                                                                                    <ul className="pagination float-md-right">
                                                                                        <li className="active page-item" title="1">
                                                                                            <a href="#/"  className="page-link">1</a>
                                                                                        </li>
                                                                                        {/* <li className="page-item" title="2">
                                                                                                <a href="#/"  className="page-link">2</a>
                                                                                            </li> */}
                                                                                        <li className="page-item" title="next page">
                                                                                            <a href="#/"  className="page-link">&gt;</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="fr" className="p-3">
<div className="portlet">
    <div className="portlet-title">
        <div className="caption">
            <span className="font-dark"><span className="text-muted">Job:</span>{this.props.selectedPipelineInfo.job_name}</span>
        </div>
    </div>
    <div className="portlet-body">
        <div className="react-bootstrap-table__header">
            <div className="row">
                <div className="col-6">
                    <div className="btn-group react-table__length" role="group">
                        <span>Show</span>
                        <select className="select-sm" style={{ backgroundColor: "#fff", border: "1px solid #CED4DA", padding: "4px 10px", margin: "0 5px", color: "#484848" }}>
                            <option>All</option>
                            <option>10</option>
                        </select>
                        <span>items</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="btn-group react-table__filter" role="group">
                        <span>Search:</span>
                        <div className="table-search">
                            <input placeholder="Search" id="searchBox"
                                className="form-control" style={{ backgroundColor: "#ffffff" }}
                                type="text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="react-bootstrap-table table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>R.No</th>
                        <th>Run ID</th>
                        <th>Timing</th>
                        <th>Job</th>
                        <th>Status</th>
                        {/* <th>Actions</th> */}
                    </tr>
                </thead>
                <tbody>

                    {
                        this.props.selectedJobDetails.runs !== undefined ?
                        this.props.selectedJobDetails.runs.map((pipeline, pipelineindex) => {
                                return (
                                    pipeline.run_status_text !== "Succeeded" ?
                                    <tr>
                                        <td>{pipelineindex + 1}</td>
                                        <td><a  href="#/"  className="text-info" onClick={this.showDetailsPipeline.bind(this, pipeline)}>{pipeline.job_run_id}</a></td>

                                        <td>
                                            <dl>
                                                <dt>
                                                    {this.formatUTCDate(pipeline['run_started_time'])}</dt>
                                                <dd className="text-muted"><i><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20" /></g></g></g></svg></i> {this.millisecondToMinutesAndSeconds(pipeline.duration)}</dd>
                                            </dl>

                                        </td>
                                        <td>
                                            <dl>
                                                <dt className="mb-2"> {pipeline['job_name']} <a href="#/"  className="text-info" ><i className="ml-1"><FontAwesomeIcon icon={faExternalLinkAlt} /></i></a></dt>
                                                <dd style={{ Width: "600px", whiteSpace: 'normal' }}>
                                                    {pipeline.stage_count.stages_name.map((stages) => {

                                                        return (<span className="badge badge-light mr-1 mb-1">{stages}</span>)

                                                    })}
                                                </dd>
                                            </dl>


                                        </td>

                                        <td>

                                            {pipeline.run_status_text === "Succeeded" ?
                                                <div className="badge badge-light-success">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg><span className="ml-1">Success</span>
                                                </div>
                                                :
                                                <div className="badge badge-light-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg><span className="ml-1">Failed</span>
                                                </div>

                                            }
                                        </td>
                                        {/*<td>&nbsp;</td>*/}
                                    </tr> : ""
                                    );

                            })
                            : ""
                    }


                    {/* <tr>
                            <td>1</td>
                            <td><a className="text-info" onClick={this.gobackfullscreen} href="/#">6efee51f</a></td>
                            <td>
                                <dl>
                                    <dt>25 Dec, 05:30</dt>
                                    <dd className="text-muted"><i><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20" /></g></g></g></svg></i> 0:00:31 GMT</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt className="mb-2">timers_pending_count <a className="text-info" href="/#"><i className="ml-1"><FontAwesomeIcon icon={faExternalLinkAlt} /></i></a></dt>
                                    <dd style={{ Width: "600px", whiteSpace: 'normal' }}>
                                        <span className="badge badge-light mr-1 mb-1">refresh_forcast_model_job_schedule</span>
                                        <span className="badge badge-light mr-1 mb-1">Git_commit_hash: 0b0ef69167f34b20231bd53</span>
                                        <span className="badge badge-light mr-1 mb-1">Git_commit_hash: 0b0ef69167f34b20231bd53</span>
                                        <span className="badge badge-light">refresh_forcast_model_job_schedule</span>
                                    </dd>
                                </dl>
                            </td>
                            <td>
                                <div className="badge badge-light-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg><span className="ml-1">Success</span>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td><a className="text-info" href="/#">6efee51f</a></td>
                            <td>
                                <dl>
                                    <dt>25 Dec, 05:30</dt>
                                    <dd className="text-muted"><i><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20" /></g></g></g></svg></i> 0:00:31 GMT</dd>
                                </dl>
                            </td>
                             <td>
                                <dl>
                                    <dt className="mb-2">timers_pending_count <a className="text-info" href="/#"><i><FontAwesomeIcon icon={faExternalLinkAlt} /></i></a></dt>
                                    <dd style={{ Width: "600px", whiteSpace: 'normal' }}>
                                        <span className="badge badge-light mr-1 mb-1">refresh_forcast_model_job_schedule</span>
                                        <span className="badge badge-light mr-1 mb-1">Git_commit_hash: 0b0ef69167f34b20231bd53</span>
                                        <span className="badge badge-light">refresh_forcast_model_job_schedule</span>
                                    </dd>
                                </dl>
                            </td> 
                            <td>
                                <div className="badge badge-light-danger">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg><span className="ml-1">Failed</span>
                                </div>
                            </td>
                            <td></td>
                        </tr> */}
                </tbody>
            </table>
        </div>
        <div className="react-bootstrap-table__footer">
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    {/* <span className="react-table__info">Showing <b>1</b> to <b>
                        {
                            this.state.jobsrun !== null ? this.state.jobsrun.length : 0}
                    </b> of
                            <b> {
                            this.state.jobsrun !== null ? this.state.jobsrun.length : 0}</b> results</span> */}
                </div>
                <div className="col-sm-12 col-md-6">
                    <ul className="pagination float-md-right">
                        <li className="active page-item" title="1">
                            <a href="#/"  className="page-link">1</a>
                        </li>
                        {/* <li className="page-item" title="2">
                                <a href="#/"  className="page-link">2</a>
                            </li> */}
                        <li className="page-item" title="next page">
                            <a href="#/"  className="page-link">&gt;</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</Tab.Pane>
                                                       
                                                        <Tab.Pane eventKey="td">
                                                                <div className="portlet lineage-graph-portlet border-0">
                                                                    <div className="portlet-title">
                                                                        <div className="caption">
                                                                            <span className="font-dark"></span>
                                                                            <p>Click on task or dataset in order to see their downstream</p>
                                                                        </div>
                                                                        <div className="actions">
                                                                            <div className="lg-toolbar">
                                                                                <div className="lg-toolbar-item">
                                                                                    <p class="mb-0"><label class="text-muted mb-0 d-none d-xl-inline-block">Last Sync:</label> <strong>Today at 04:00:00 PM</strong></p>
                                                                                </div>
                                                                                <div className="lg-toolbar-item">
                                                                                    <Form className="mr-3">
                                                                                        <Form.Group controlId="formBasicRangeCustom" className="form-inline flex-nowrap mb-0">
                                                                                            <Form.Label className="d-none d-xl-inline-block">Zoom</Form.Label>
                                                                                            <Slider
                                                                                                min={0}
                                                                                                max={100}
                                                                                                tooltip={false}
                                                                                                value={this.state.rangeValue}
                                                                                                onChange={this.setRangeValue}
                                                                                            />
                                                                                            {/* <RangeSlider
                                                                                                value={this.state.rangeValue}
                                                                                                tooltip="off"
                                                                                                onChange={(changeEvent) => this.setRangeValue(changeEvent.target.value)}
                                                                                            /> */}
                                                                                            <span className="rangeslider__label">{this.state.rangeValue + "%"}</span>
                                                                                        </Form.Group>
                                                                                    </Form>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                            <Tooltip>Zoom Reset</Tooltip>
                                                                                        }
                                                                                        >
                                                                                        <i>
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clip-path="url(#clip0_2066_27139)">
                                                                                                <path d="M12 8C9.79 8 8 9.79 8 12C8 14.21 9.79 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM4 15C3.45 15 3 15.45 3 16V19C3 20.1 3.9 21 5 21H8C8.55 21 9 20.55 9 20C9 19.45 8.55 19 8 19H6C5.45 19 5 18.55 5 18V16C5 15.45 4.55 15 4 15ZM5 6C5 5.45 5.45 5 6 5H8C8.55 5 9 4.55 9 4C9 3.45 8.55 3 8 3H5C3.9 3 3 3.9 3 5V8C3 8.55 3.45 9 4 9C4.55 9 5 8.55 5 8V6ZM19 3H16C15.45 3 15 3.45 15 4C15 4.55 15.45 5 16 5H18C18.55 5 19 5.45 19 6V8C19 8.55 19.45 9 20 9C20.55 9 21 8.55 21 8V5C21 3.9 20.1 3 19 3ZM19 18C19 18.55 18.55 19 18 19H16C15.45 19 15 19.45 15 20C15 20.55 15.45 21 16 21H19C20.1 21 21 20.1 21 19V16C21 15.45 20.55 15 20 15C19.45 15 19 15.45 19 16V18Z" fill="#A1A5B7"/>
                                                                                                </g>
                                                                                                <defs>
                                                                                                <clipPath id="clip0_2066_27139">
                                                                                                <rect width="24" height="24" fill="white"/>
                                                                                                </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                        </i>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                                <div className="lg-toolbar-item">
                                                                                    <button class="btn btn-light-secondary">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="portlet-body p-0">
                                                                    {this.state.detailviewid === "" ? 

                                                                        <PipelineLineage
                                                                        height="calc(100vh - 78px)"
                                                                        /> : ""
                                                                                    }
                                                                        <div className="lineage-graph-sidebar">
                                                                            <div className="lg-sidebar__content">
                                                                                <div className="lg-sidebar__header">
                                                                                    <div className="caption">
                                                                                        <span>Task</span>
                                                                                        <p>Pipeline_1 / SkinThickness</p>
                                                                                    </div>
                                                                                    <div className="action">
                                                                                        <button type="button" className="close"><span className="sr-only">Close</span></button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="lg-sidebar__body">
                                                                                    <div className="lg-section">
                                                                                        <h5 className="lg-section__title">Origin</h5>
                                                                                        <div className="lg-section__body">
                                                                                            <div className="pipeline-details">
                                                                                                <p><label>Pipeline:</label><span><i><FontAwesomeIcon icon={faAsterisk} /></i>Pipe_source_1</span></p>
                                                                                                <p><label>Run:</label><span><i><FontAwesomeIcon icon={faClock} /></i><strong>At 12:07:12 GMT,</strong>Only Sunday</span></p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="lg-section">
                                                                                        <h5 className="lg-section__title">Error Message</h5>
                                                                                        <div className="lg-section__body">
                                                                                            <div className="highlight">
                                                                                                <pre className="chroma">
                                                                                                    <code className="language-js">
                                                                                                        <span>_error: &#x7B;</span><br />
                                                                                                        <span>&nbsp;&nbsp;&nbsp;"errorCode":"", </span><br />
                                                                                                        <span>&nbsp;&nbsp;&nbsp;"message":"", </span><br />
                                                                                                        <span>&nbsp;&nbsp;&nbsp;"failureType":"", </span><br />
                                                                                                        <span>&nbsp;&nbsp;&nbsp;"target":"Source_abd_destination_lst", </span><br />
                                                                                                        <span>&nbsp;&nbsp;&nbsp;"details":"", </span><br />
                                                                                                        <span>&#x7D;</span>
                                                                                                    </code>
                                                                                                </pre>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="lg-section">
                                                                                        <h5 className="lg-section__title">Impact Analysis</h5>
                                                                                        <div className="lg-section__body">
                                                                                            <Tab.Container defaultActiveKey="aj">
                                                                                                <div className="tabbable-line">
                                                                                                    <Nav variant="tabs" id="page-nav-tabs">
                                                                                                        <Nav.Item>
                                                                                                            <Nav.Link eventKey="aj" >
                                                                                                                <span className="qd-menu__link-text">Affected Jobs <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                            </Nav.Link>
                                                                                                        </Nav.Item>
                                                                                                        <Nav.Item>
                                                                                                            <Nav.Link eventKey="ad">
                                                                                                                <span className="qd-menu__link-text">Affected Datasets <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                            </Nav.Link>
                                                                                                        </Nav.Item>
                                                                                                    </Nav>
                                                                                                </div>
                                                                                                <Tab.Content className="pt-2">
                                                                                                    <Tab.Pane eventKey="aj">
                                                                                                        <ul className="pipeline-affected-list">
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </Tab.Pane>
                                                                                                    <Tab.Pane eventKey="ad">
                                                                                                        <ul className="pipeline-affected-list">
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </Tab.Pane>
                                                                                                </Tab.Content>
                                                                                            </Tab.Container>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="pipeline-custom-tree">
                                                                            <ul className="pipeline-ct__nodes">
                                                                                <li className="pipeline-ct__node">
                                                                                    <div className="pipeline-ct__node-item">
                                                                                        {
                                                                                            this.state.troubleshooting.length > 0 ? this.state.troubleshooting[0] : ""
                                                                                        }

                                                                                    </div>
                                                                                    <ul className="pipeline-ct__nodes">
                                                                                        {
                                                                                            this.state.troubleshooting.map((pipelinejob, index) => {
                                                                                                return (
                                                                                                    index > 0 ?
                                                                                                        <li className="pipeline-ct__node">
                                                                                                            <div className="pipeline-ct__node-item">{pipelinejob}</div>
                                                                                                        </li> : ""
                                                                                                )
                                                                                            })}
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        </div> */}


                                                                        {/* <div className="pipeline-custom-tree">
                                                                                <ul className="pipeline-ct__nodes">
                                                                                    <li className="pipeline-ct__node">
                                                                                        <div className="pipeline-ct__node-item">Data Ingestion Pipeline</div>
                                                                                        <ul className="pipeline-ct__nodes">
                                                                                            <li className="pipeline-ct__node">
                                                                                                <div className="pipeline-ct__node-item">Data Quality Check Pipeline</div>
                                                                                            </li>
                                                                                            <li className="pipeline-ct__node">
                                                                                                <div className="pipeline-ct__node-item">ML Training Pipeline</div>
                                                                                            </li>
                                                                                            <li className="pipeline-ct__node">
                                                                                                <div className="pipeline-ct__node-item">Data Dashboarding Pipeline</div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul> 
                                                                            </div> */}
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                        {/* End: Pipeline Jobs Info */}
                                    </div>

                                    {/* Pipeline Run Content */}
                                    <div className="qd-jobs-run-wrapper d-none" id="grant_chart_view">
                                        <div className="qd-jr__header">
                                            <div className="qd-jr__header-left">
                                                <a href="#/"  onClick={this.gobackfullscreen}><i>
                                                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#767676"><rect fill="none" height="24" width="24" /><path d="M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19" /></svg>
                                                </i></a>
                                            </div>
                                            <div className="qd-jr__header-right">
                                                <div className="qd-jr__header-title">
                                                    <h4 id="detailsid">{this.state.detailviewid}</h4>
                                                    <div className="divider"></div>
                                                    {
                                                        this.state.detailviewstatus !== "Failed" ?
                                                            <div className="badge badge-light-success mr-2">
                                                                <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg></i>
                                                            Success
                                                        </div>
                                                            :
                                                            <div className="badge badge-light-danger mr-2">
                                                                <i className="mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg></i>
                                                            Failed
                                                        </div>
                                                    }
                                                    <div className="badge bg-white mr-2">
                                                        <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0V0" fill="none" /><path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8" /></svg></i>Run of {this.state.detailviewjobname}
                                                    </div>
                                                    <div className="badge bg-white mr-2">
                                                        <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8" /><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67" /></svg></i>{this.state.detailviewtiming}
                                                    </div>
                                                    <div className="badge bg-white mr-2">
                                                        <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20" /></g></g></g></svg></i>{this.state.detailviewduration}
                                                    </div>
                                                </div>
                                                <ul className="qd-jobs__status">
                                                    <li>
                                                        <div className="qd-jobs__status-card bg-white">
                                                            <div className="card-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#86899A"><g><path d="M0,0h24v24H0V0" fill="none" /></g><g><path d="M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M10.94,18L7.4,14.46l1.41-1.41 l2.12,2.12l4.24-4.24l1.41,1.41L10.94,18z M13,9V3.5L18.5,9H13" /></g></svg>                                                                </div>
                                                            <div className="card-content">
                                                                <label className="card-label">Total Tasks</label>
                                                                <p className="card-text"><strong>{this.state.detailviewsuccesscnt+this.state.detailviewfailedcnt}</strong></p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="qd-jobs__status-card bg-white">
                                                            <div className="card-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#86899A"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                                                            </div>
                                                            <div className="card-content">
                                                                <label className="card-label">Success Tasks</label>
                                                                <p className="card-text"><strong>{this.state.detailviewsuccesscnt}</strong></p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="qd-jobs__status-card bg-white">
                                                            <div className="card-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#86899A"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                            </div>
                                                            <div className="card-content">
                                                                <label className="card-label">Failed Tasks</label>
                                                                <p className="card-text"><strong>{this.state.detailviewfailedcnt}</strong></p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="qd-jr__body">
                                            <div className="row no-gutters">
                                                <div className="col">
                                                <Tab.Container defaultActiveKey="pp_attribute">
                                                        <div className="tabbable-line">
                                                            <Nav variant="tabs" id="page-nav-tabs" className="pl-3">
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="pp_timedView">
                                                                        <span className="qd-menu__link-text">Timed View</span>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                               <Nav.Item>
                                                                    <Nav.Link eventKey="pp_attribute">
                                                                        <span className="qd-menu__link-text">
                                                                        { this.state.detailviewstatus === "Failed" ? 
                                                                            "Troubleshooting & Diagnostics" : "Lineage" } 
                                                                            </span>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </div>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="pp_timedView">
                                                            <div className="row no-gutters">
                                                                <div className="col">
                                                                <img src={gantt_chart} alt="" /> 
                                                                {/* <div style={{"width":"98%"}}>
                                                                <Gantt tasks={tasks} 
                                                                        listCellWidth={false}

                                                                />
                                                                </div> */}
                                                                    {/* <img src={ganttChart1} alt="" width="100%" /> */}
                                                                </div>
                                                            </div>
                                                            <div className="row no-gutters">
                                                                <div className="col-9 border-right">
                                                                    <div className="p-3 d-flex align-items-center">
                                                                        <div className="qd-search-box">
                                                                            <input className="form-control" placeholder="search" />
                                                                        </div>
                                                                        <div className="badge badge-light px-2 ml-2">
                                                                            <div class="form-check form-check-inline mr-0">
                                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                                                <label class="form-check-label" for="defaultCheck1">
                                                                                    Info
                                                                                        </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="badge badge-light is-checked px-2 ml-2">
                                                                            <div class="form-check form-check-inline mr-0">
                                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                                                                <label class="form-check-label" for="defaultCheck1">
                                                                                    Debug
                                                                                        </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="badge badge-light is-checked px-2 ml-2">
                                                                            <div class="form-check form-check-inline mr-0">
                                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                                                                <label class="form-check-label" for="defaultCheck1">
                                                                                    Warning
                                                                                        </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="badge badge-light is-checked px-2 ml-2">
                                                                            <div class="form-check form-check-inline mr-0">
                                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                                                                <label class="form-check-label" for="defaultCheck1">
                                                                                    Error
                                                                                        </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="badge badge-light is-checked px-2 ml-2">
                                                                            <div class="form-check form-check-inline mr-0">
                                                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                                                                <label class="form-check-label" for="defaultCheck1">
                                                                                    Event
                                                                                        </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: "15%" }}>OP</th>
                                                                                <th style={{ width: "15%" }}>Event Type</th>
                                                                                <th style={{ width: "55%" }}>Info</th>
                                                                                <th style={{ width: "15%" }}>Timestamp</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {
                                                                    this.state.detailviewstatus === "Success" ?
                                                                        <div className="col-3">
                                                                            <div className="job-run-summary">
                                                                                <div className="job-run-summary__title">
                                                                                    <h6>Succeeded (4)</h6>
                                                                                </div>
                                                                                <div className="job-run-summary__body">
                                                                                    <ul className="job-run-summary__list">
                                                                                        <li>
                                                                                            <label>Orders</label>
                                                                                            <span>0.0805s</span>
                                                                                        </li>
                                                                                        <li>
                                                                                            <label>Users</label>
                                                                                            <span>0.269s</span>
                                                                                        </li>
                                                                                        <li>
                                                                                            <label>run_dbt_da47d</label>
                                                                                            <span>5.941s</span>
                                                                                        </li>
                                                                                        <li>
                                                                                            <label>order_forecast_model</label>
                                                                                            <span>0.323s</span>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div> : ""
                                                                }


                                                            </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="pp_attribute">
                                                                <div className="portlet lineage-graph-portlet border-0">
                                                                    <div className="portlet-title">
                                                                        <div className="caption">
                                                                            {
                                                                                this.state.detailviewstatus === "Failed" ?                                                                             <span className="text-danger">Affected assets are colored in red</span>
:""
                                                                            }
                                                                            <p>Click on task or dataset in order to see their downstream</p>
                                                                        </div>
                                                                        <div className="actions">
                                                                            <div className="lg-toolbar">
                                                                                <div className="lg-toolbar-item">
                                                                                    <p class="mb-0"><label class="text-muted mb-0 d-none d-xl-inline-block">Last Sync:</label> <strong>Today at 04:00:00 PM</strong></p>
                                                                                </div>
                                                                                <div className="lg-toolbar-item">
                                                                                    <Form className="mr-3">
                                                                                        <Form.Group controlId="formBasicRangeCustom" className="form-inline flex-nowrap mb-0">
                                                                                            <Form.Label className="d-none d-xl-inline-block">Zoom</Form.Label>
                                                                                            <Slider
                                                                                                min={0}
                                                                                                max={100}
                                                                                                tooltip={false}
                                                                                                value={this.state.rangeValue}
                                                                                                onChange={this.setRangeValue}
                                                                                            />
                                                                                            <span className="rangeslider__label">{this.state.rangeValue + "%"}</span>
                                                                                        </Form.Group>
                                                                                    </Form>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                            <Tooltip>Zoom Reset</Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <i>
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clip-path="url(#clip0_2066_27139)">
                                                                                                    <path d="M12 8C9.79 8 8 9.79 8 12C8 14.21 9.79 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM4 15C3.45 15 3 15.45 3 16V19C3 20.1 3.9 21 5 21H8C8.55 21 9 20.55 9 20C9 19.45 8.55 19 8 19H6C5.45 19 5 18.55 5 18V16C5 15.45 4.55 15 4 15ZM5 6C5 5.45 5.45 5 6 5H8C8.55 5 9 4.55 9 4C9 3.45 8.55 3 8 3H5C3.9 3 3 3.9 3 5V8C3 8.55 3.45 9 4 9C4.55 9 5 8.55 5 8V6ZM19 3H16C15.45 3 15 3.45 15 4C15 4.55 15.45 5 16 5H18C18.55 5 19 5.45 19 6V8C19 8.55 19.45 9 20 9C20.55 9 21 8.55 21 8V5C21 3.9 20.1 3 19 3ZM19 18C19 18.55 18.55 19 18 19H16C15.45 19 15 19.45 15 20C15 20.55 15.45 21 16 21H19C20.1 21 21 20.1 21 19V16C21 15.45 20.55 15 20 15C19.45 15 19 15.45 19 16V18Z" fill="#A1A5B7" />
                                                                                                </g>
                                                                                                <defs>
                                                                                                    <clipPath id="clip0_2066_27139">
                                                                                                        <rect width="24" height="24" fill="white" />
                                                                                                    </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                        </i>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                                <div className="lg-toolbar-item">
                                                                                    <button class="btn btn-light-secondary">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="portlet-body p-0">
                                                                        
                                                                        {this.state.detailviewid !== "" ? 
                                                                        <PipelineLineageErr
                                                                            height="calc(100vh - 78px)"
                                                                            type="100"
                                                                            /> : "" }

                                                                        <div className="lineage-graph-sidebar">
                                                                            <div className="lg-sidebar__content">
                                                                                <div className="lg-sidebar__header">
                                                                                    <div className="caption">
                                                                                        <span>Attribute</span>
                                                                                        <p>severity</p>
                                                                                    </div>
                                                                                    <div className="action">
                                                                                        <button type="button" className="close"><span className="sr-only">Close</span></button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="lg-sidebar__body">
                                                                                    <div className="lg-section">
                                                                                        <h5 className="lg-section__title">Details:</h5>
                                                                                        <div className="lg-section__body">
                                                                                            <div className="pipeline-details">
                                                                                                <p><label><i><FontAwesomeIcon icon={faServer} /></i>Environment:</label><span>aws_qualdo</span></p>
                                                                                                <p><label><i><FontAwesomeIcon icon={faDatabase} /></i>Datasource:</label><span>Centralops docker</span></p>
                                                                                                <p><label><i><FontAwesomeIcon icon={faLayerGroup} /></i>Dataset:</label><span>qualdo_central_ops</span></p>
                                                                                                <p><label><i></i>DQ Issue :</label><span>Recency</span></p>
                                                                                                <p><label><i><FontAwesomeIcon icon={faClock} /></i>Occurred at :</label><span>At <strong>12:07:12 GMT</strong>, only Sunday</span></p>
                                                                                                <p><label><i></i>Run Id :</label><span>qp23422</span></p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="lg-section">
                                                                                        <h5 className="lg-section__title">Recency</h5>
                                                                                        <div className="lg-section__body">

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="lg-section">
                                                                                        <h5 className="lg-section__title">Troubleshooting</h5>
                                                                                        <div className="lg-section__body">
                                                                                            <Tab.Container defaultActiveKey="ts_jobs">
                                                                                                <div className="tabbable-line">
                                                                                                    <Nav variant="tabs" id="page-nav-tabs">
                                                                                                        <Nav.Item>
                                                                                                            <Nav.Link eventKey="ts_jobs" >
                                                                                                                <span className="qd-menu__link-text">Jobs <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                            </Nav.Link>
                                                                                                        </Nav.Item>
                                                                                                        <Nav.Item>
                                                                                                            <Nav.Link eventKey="ts_dataset">
                                                                                                                <span className="qd-menu__link-text">Datasets <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                            </Nav.Link>
                                                                                                        </Nav.Item>
                                                                                                    </Nav>
                                                                                                </div>
                                                                                                <Tab.Content className="pt-2">
                                                                                                    <Tab.Pane eventKey="ts_jobs">
                                                                                                        <ul className="pipeline-affected-list">
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </Tab.Pane>
                                                                                                    <Tab.Pane eventKey="ts_dataset">
                                                                                                        <ul className="pipeline-affected-list">
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </Tab.Pane>
                                                                                                </Tab.Content>
                                                                                            </Tab.Container>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="lg-section">
                                                                                        <h5 className="lg-section__title">Impact Analysis</h5>
                                                                                        <div className="lg-section__body">
                                                                                            <Tab.Container defaultActiveKey="ia_jobs">
                                                                                                <div className="tabbable-line">
                                                                                                    <Nav variant="tabs" id="page-nav-tabs">
                                                                                                        <Nav.Item>
                                                                                                            <Nav.Link eventKey="ia_jobs" >
                                                                                                                <span className="qd-menu__link-text">Affected Jobs <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                            </Nav.Link>
                                                                                                        </Nav.Item>
                                                                                                        <Nav.Item>
                                                                                                            <Nav.Link eventKey="ia_dataset">
                                                                                                                <span className="qd-menu__link-text">Affected Datasets <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                            </Nav.Link>
                                                                                                        </Nav.Item>
                                                                                                    </Nav>
                                                                                                </div>
                                                                                                <Tab.Content className="pt-2">
                                                                                                    <Tab.Pane eventKey="ia_jobs">
                                                                                                        <ul className="pipeline-affected-list">
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </Tab.Pane>
                                                                                                    <Tab.Pane eventKey="ia_dataset">
                                                                                                        <ul className="pipeline-affected-list">
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="#/" onClick={this.loadGanntChart}>
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none" />
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd" />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </Tab.Pane>
                                                                                                </Tab.Content>
                                                                                            </Tab.Container>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Tab.Container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}