import React from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {postDatasource}  from "../../../../utils/event_handling";
import {userValidation} from "../../../../utils/common_utils"
import {TEST_CONNECTION_MESSAGES, VERSION_FORMATS, FILE_TYPE_FORMATS} from "../../../../utils/constant";
import { faEye, faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'react-bootstrap/InputGroup';


class AdlGen2Form extends React.Component {
        constructor(props) {
        super(props);
        this.fill_details = this.fill_details.bind(this);
        this.postDatasource = postDatasource.bind(this);
        this.postAzureData = this.postAzureData.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangesecretKeyIcon = this.ChangesecretKeyIcon.bind(this);
        this.state = {
            formType_option:null,
            isChecked: true,
            hideSubmitButton: false,
            fileType: null,
            selectOptionKey:"emptyFileType",
            integration_data: this.props.integration_data,
            integration_data_edit: "",
            refreshType: {"label":"File Replace", "value":"file_replace"},
            versionFormat: null,
            versionOccurence: {"label":"prefix", "value":"prefix"},
            secretKeyIcon:faEye,
            secretKeyType:'password',

        };
       }

       componentDidMount() {
        this.fill_details();
       }

       toggleChange = () => {
            this.setState({isChecked: !this.state.isChecked});
       }

       fill_details() {
            if (this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) {
               let integration_data =  this.state.integration_data;
               integration_data.integration_name = this.props.ex_integration_details["integration_name"];
               integration_data.integration_id = this.props.ex_integration_details["integration_id"];
               integration_data.bucket_name = this.props.ex_integration_details.integration_config.bucket;
               integration_data.sub_path = this.props.ex_integration_details.integration_config.sub_path;
               integration_data.error_storage = this.props.ex_integration_details.integration_config.error_storage;
               let fileType = this.props.ex_integration_details.integration_config.filetype;
               integration_data.fileType = fileType
               let refreshType = this.props.ex_integration_details.integration_config.refresh_type;
               integration_data.refreshType = refreshType;
               let versionType = this.props.ex_integration_details.integration_config.version_type;
               let versionFormat = this.props.ex_integration_details.integration_config.version_format;
               let versionOccurence = this.props.ex_integration_details.integration_config.version_occurence;
               integration_data.versionOccurence = versionOccurence;
               if (versionType !== "custom") {
                  integration_data.versionFormat = versionType;
                  this.setState({versionFormat: {"label": versionType, "value": versionType}});
               } else {
                  integration_data.versionFormat = versionType;
                  integration_data.versionFormatRegex = versionFormat;
                  this.setState({versionFormat: {"label": versionType, "value": versionType}});
               }
               let defaultErrorStorage = this.props.ex_integration_details.integration_config.default_error_storage;
               if (defaultErrorStorage === undefined) {
                    defaultErrorStorage = true;
               }
               this.setState({isChecked: defaultErrorStorage,
                            selectOptionKey: "updatedFileType",
                            fileType:{"label": fileType, "value": fileType},
                            refreshType: {"label": FILE_TYPE_FORMATS[refreshType], "value": refreshType},
                            versionOccurence: {"label": versionOccurence, "value": versionOccurence}}
                            );

               integration_data.blob_secret = this.props.ex_integration_details.auth_config.secret_key;
               integration_data.storage_name = this.props.ex_integration_details.integration_config.storage_name;

               this.setState({
                            integration_type: this.props.formType,
                            integration_data_edit: integration_data
                            });
            }

       }

       postAzureData(event) {
            this.disableButton();
            const {integration_name,error_storage,storage_name,blob_secret,sub_path,
            bucket_name,fileType, versionFormat, versionFormatRegex} = event;
            let version_format;
            let version_type;
            if (versionFormat === "custom") {
                version_format = versionFormatRegex;
                version_type = "custom";
            } else {
                version_format = VERSION_FORMATS[versionFormat];
                version_type = versionFormat;
            }
            let azureData = {"integration_name":integration_name,
                                 "integration_id":this.state.integration_data.integration_id,
                                 "data_store_type":"filesystem",
                                 "error_storage": error_storage,
                                 "bucket_name": bucket_name,
                                 "sub_path": sub_path,
                                 "filetype": fileType,
                                 "refresh_type": this.state.refreshType.value,
                                 "version_format": version_format,
                                 "version_type": version_type,
                                 "version_occurence": this.state.versionOccurence.value,
                                 "env_id":this.props.env_id
                            }
            azureData["integration_type"] = "azure_data_lake_gen2"
            azureData["storage_name"] = storage_name
            azureData["blob_secret"] = blob_secret

            let default_message = TEST_CONNECTION_MESSAGES[this.props.formType];
            this.props.closeTestConnection();
            this.props.setSubmitButton(true);
            this.props.setData(azureData, default_message);
            this.postDatasource(azureData);

        }


        getSchema() {
           const subPath = RegExp(/^(?!\/)/);

           let azureSchema = '';
           if(this.props.formType === "azure_blob" && this.state.isChecked === true &&
           this.state.refreshType.value ==="file_replace") {
                  azureSchema = yup.object({
                        integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                              'DatasourceName test',
                              'invalid Datasource name',
                              function test(value) {
                                 let val = userValidation(value,"input");
                                 return val;
                          }
                          ),
                        storage_name: yup.string().required('Storage Name is a required field'),
                        blob_secret: yup.string().required('Blob Secret is a required field'),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        fileType: yup.string().required('File type is a required field')
                  });
           }else if(this.props.formType === "azure_blob" && this.state.isChecked === false &&
           this.state.refreshType.value ==="file_replace") {
                  azureSchema = yup.object({
                        integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                              'DatasourceName test',
                              'invalid Datasource name',
                              function test(value) {
                                 let val = userValidation(value,"input");
                                 return val;
                          }
                          ),
                        storage_name: yup.string().required('Storage Name is a required field'),
                        blob_secret: yup.string().required('Blob Secret is a required field'),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        error_storage: yup.string().required('Error Storage is a required field'),
                        fileType: yup.string().required('File type is a required field')
                  });
           }else if (this.props.formType === "azure_blob" && this.state.isChecked === true &&
           this.state.refreshType.value !=="file_replace") {
                  azureSchema = yup.object({
                        integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                              'DatasourceName test',
                              'invalid Datasource name',
                              function test(value) {
                                 let val = userValidation(value,"input");
                                 return val;
                          }
                          ),                        storage_name: yup.string().required('Storage Name is a required field'),
                        blob_secret: yup.string().required('Blob Secret is a required field'),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        fileType: yup.string().required('File type is a required field'),
                        versionFormat: yup.string().required('Refresh Format is a required field')
                  });
           }else if(this.props.formType === "azure_blob" && this.state.isChecked === false &&
           this.state.refreshType.value !=="file_replace") {
                  azureSchema = yup.object({
                        integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                              'DatasourceName test',
                              'invalid Datasource name',
                              function test(value) {
                                 let val = userValidation(value,"input");
                                 return val;
                          }
                          ),                        storage_name: yup.string().required('Storage Name is a required field'),
                        blob_secret: yup.string().required('Blob Secret is a required field'),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        error_storage: yup.string().required('Error Storage is a required field'),
                        fileType: yup.string().required('File type is a required field'),
                        versionFormat: yup.string().required('Refresh Format is a required field')
                  });
           }

           return azureSchema;


        }

        disableButton() {
            this.setState({hideSubmitButton: true});
        }

        ChangesecretKeyIcon() {
            let state = this.state.secretKeyIcon;
            if (state.iconName === "eye") {
                this.setState({secretKeyIcon: faEyeSlash});
                this.setState({secretKeyType: "text"})
              } else {
                this.setState({secretKeyIcon: faEye});
                this.setState({secretKeyType: "password"})
              }
        }

        render() {
            let azureSchema = this.getSchema();
            let file_option = [
                    {"label":"csv", "value":"csv"},
                    {"label":"json", "value":"json"},
                    {"label":"parquet", "value":"parquet"},
                    {"label":"avro", "value":"avro"},
                    {"label":"delta", "value":"delta"},
                    {"label":"excel", "value":"excel"}
               ];
            let refresh_option = [
                    {"label":"File Replace", "value":"file_replace"},
                    {"label":"File Version", "value":"file_version"},
                    {"label": "Folder Version", "value":"folder_version"}
               ];
            let version_option = [
                    {"label":"dd-mm-yyyy", "value":"dd-mm-yyyy"},
                    {"label":"yyyy-mm-dd", "value":"yyyy-mm-dd"},
                    {"label": "dd-mm-yy", "value":"dd-mm-yy"},
                    {"label": "custom", "value": "custom" }
               ];
            let occurence_options = [
                {"label":"prefix", "value":"prefix"},
                {"label":"suffix", "value":"suffix"}
            ]

            let initial_values = {
                integration_name: '', error_storage: '', secret_key: '', storage_name: '',
                blob_secret: '', adl_client_id: '', sub_path: '', adl_tenant_id: '', bucket_name: '', fileType: '',
                refreshType: this.state.refreshType, versionFormat: '',
                versionOccurence: this.state.versionOccurence
            };
            let edit_integration = null;
            if(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null){
                edit_integration = true;
            }
            else{
                 edit_integration = false;
            }
            return (
                  <>
                        <Formik
                              validationSchema={azureSchema}
                              onSubmit={this.postAzureData}
                              validateOnBlur={false}
                              initialValues={(edit_integration === true) ? this.state.integration_data_edit : initial_values}
                              enableReinitialize={true}                        >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              isValid,
                        }) => (
                        <Form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <Form.Group controlId="integration_name" className="form-group col-md-6">
                                <Form.Label>Datasource Name <span className="text-danger">*</span></Form.Label>

                                <Form.Control
                                      type="text"
                                      className="form-control"
                                      name="integration_name"
                                      value={values.integration_name === undefined ? '' : values.integration_name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      isInvalid={errors.integration_name && touched.integration_name}
                                      placeholder="Datasource Name"
                                />
                                <Form.Control.Feedback type="invalid">
                                           { touched.integration_name && errors.integration_name }
                                </Form.Control.Feedback>
                            </Form.Group>
                                  <>
                                    <Form.Group controlId="storage_name" className="col-md-6">
                                         <Form.Label>Storage Name <span className="text-danger">*</span></Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="storage_name"
                                               value={values.storage_name === undefined ? "" : values.storage_name}
                                               disabled={edit_integration ? true : false}
                                               onChange={handleChange}
                                               placeholder="Storage Name"
                                               onBlur={handleBlur}
                                               isInvalid={errors.storage_name && touched.storage_name}
                                         />
                                         <Form.Control.Feedback type="invalid">
                                           { touched.storage_name && errors.storage_name }
                                         </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="bucket_name" className="col-md-6">
                                         <Form.Label>Bucket Name <span className="text-danger">*</span></Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="bucket_name"
                                               value={values.bucket_name === undefined ? "" : values.bucket_name}
                                               disabled={edit_integration ? true : false}
                                               onChange={handleChange}
                                               placeholder="Bucket Name"
                                               onBlur={handleBlur}
                                               isInvalid={errors.bucket_name && touched.bucket_name}
                                         />
                                         <Form.Control.Feedback type="invalid">
                                           { touched.bucket_name && errors.bucket_name }
                                         </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="sub_path" className="col-md-6">
                                         <Form.Label>Sub Path</Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="sub_path"
                                               value={values.sub_path === undefined ? "" : values.sub_path}
                                               disabled={edit_integration ? true : false}
                                               onChange={handleChange}
                                               placeholder="Sub Path"
                                               onBlur={handleBlur}
                                               isInvalid={errors.sub_path}
                                         />
                                         <Form.Control.Feedback type="invalid">
                                           {errors.sub_path }
                                         </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="fileType" className="col-md-6">
                                          <Form.Label>File Type <span className="text-danger">*</span></Form.Label>
                                                <Select name="fileType"
                                                        filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                        onChange={selectedOption => {
                                                                   handleChange("fileType")(selectedOption.value);
                                                        }}
                                                        isInvalid={errors.fileType && touched.fileType}
                                                        id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "blobFileTypeEdit" : "blobFileTypeAdd"}
                                                        key={this.state.selectOptionKey}
                                                        defaultValue={this.state.fileType}
                                                        options={file_option}
                                                        placeholder="Choose file type"
                                                        classNamePrefix='select-control'
                                                />
                                          <ErrorMessage component="div" className="error-text" name="fileType" />
                                    </Form.Group>
                                    <Form.Group controlId="blob_secret" className="col-md-6">
                                         <Form.Label>Blob Secret <span className="text-danger">*</span></Form.Label>
                                         <InputGroup bsPrefix="icon-input-group">
                                          <i>
                                                      <FontAwesomeIcon icon={faLock}/>
                                          </i>
                                                <Form.Control
                                                      type={this.state.secretKeyType}
                                                      className="form-control"
                                                      name="blob_secret"
                                                      value={values.blob_secret === undefined ? "" : values.blob_secret}
                                                      onChange={handleChange}
                                                      placeholder="Blob Secret"
                                                      onBlur={handleBlur}
                                                      isInvalid={errors.blob_secret && touched.blob_secret}
                                                      autoComplete={this.props.autocomplete}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                           { touched.blob_secret && errors.blob_secret }
                                         </Form.Control.Feedback>
                                          <i className="security-eye">
                                                <FontAwesomeIcon onClick={this.ChangesecretKeyIcon} icon={this.state.secretKeyIcon} className="security-eye"/>
                                          </i>
                                          </InputGroup>

                                    </Form.Group>
                                    <Form.Group controlId="refreshType" className="col-md-6">
                                          <Form.Label>Refresh Type <span className="text-danger">*</span></Form.Label>
                                                <Select name="refreshType"
                                                        filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                        onChange={selectedOption => {
                                                                    handleChange("refreshType")(selectedOption.value);
                                                                    this.setState ({
                                                                    refreshType: {"label":selectedOption["label"], "value":selectedOption["value"]}});
                                                                    }}
                                                        isInvalid={errors.refreshType && touched.refreshType}
                                                        id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "blobRefreshTypeEdit" : "blobRefreshTypeAdd"}
                                                        key={this.state.selectOptionKey}
                                                        defaultValue={this.state.refreshType}
                                                        options={refresh_option}
                                                        placeholder="Choose refresh type"
                                                        classNamePrefix='select-control'
                                                        isDisabled={edit_integration}
                                                />
                                          <ErrorMessage component="div" className="error-text" name="refreshType" />
                                    </Form.Group>
                                    { this.state.refreshType !== null && (this.state.refreshType.value === "file_version" || this.state.refreshType.value === "folder_version") ?
                                        <Form.Group controlId="versionFormat"
                                        className="col-md-6">
                                              <Form.Label>Refresh Format <span className="text-danger">*</span></Form.Label>
                                                    <Select name="versionFormat"
                                                            filterOption={({label}, query) => label.includes(query)}
                                                            onChange={selectedOption => {
                                                                    this.setState ({
                                                                    versionFormat: {"label": selectedOption["value"]    ,"value": selectedOption["value"]}});
                                                                    handleChange("versionFormat")(selectedOption.value);}}
                                                            isInvalid={errors.versionFormat && touched.versionFormat}
                                                            id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "blobVersionTypeEdit" : "blobVersionTypeAdd"}
                                                            key={this.state.selectOptionKey}
                                                            defaultValue={this.state.versionFormat}
                                                            options={version_option}
                                                            placeholder="Choose Refresh Format"
                                                            classNamePrefix='select-control'
                                                            isDisabled={edit_integration}
                                                    />
                                              <ErrorMessage component="div" className="error-text" name="versionFormat" />
                                        </Form.Group> : ""
                                    }
                                    { this.state.versionFormat !== null && (this.state.versionFormat.value === "custom" && this.state.refreshType.value !== "file_replace") ?
                                        <Form.Group controlId="versionFormatRegex" className="col-md-6">
                                             <Form.Label>Custom Refresh Format</Form.Label>
                                             <Form.Control
                                                   type="text"
                                                   className="form-control"
                                                   name="versionFormatRegex"
                                                   value={values.versionFormatRegex === undefined ? "" : values.versionFormatRegex}
                                                   onChange={handleChange}
                                                   placeholder="Enter the custom regex that matches your version pattern here"
                                                   onBlur={handleBlur}
                                                   isInvalid={errors.versionFormatRegex && touched.versionFormatRegex}
                                                   isDisabled={edit_integration}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                               { touched.versionFormatRegex && errors.versionFormatRegex }
                                             </Form.Control.Feedback>
                                        </Form.Group> : ""
                                    }
                                    { this.state.refreshType !== null && (this.state.refreshType.value === "file_version" || this.state.refreshType.value === "folder_version") ?
                                        <Form.Group controlId="versionOccurence" className="col-md-6">
                                              <Form.Label>Refresh Format Occurrence <span className="text-danger">*</span></Form.Label>
                                                    <Select name="versionOccurence"
                                                            filterOption={({label}, query) => label.includes(query)}
                                                            onChange={selectedOption => {
                                                                        handleChange("versionOccurence")(selectedOption.value);
                                                                        this.setState ({
                                                                        versionOccurence: {"label":selectedOption["label"], "value":selectedOption["value"]}});
                                                                        }}
                                                            isInvalid={errors.versionOccurence && touched.versionOccurence}
                                                            id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "blobOccurenceTypeEdit" : "blobOccurenceTypeAdd"}
                                                            key={this.state.selectOptionKey}
                                                            defaultValue={this.state.versionOccurence}
                                                            options={occurence_options}
                                                            placeholder="Choose Refresh Format Occurrence"
                                                            classNamePrefix='select-control'
                                                            isDisabled={edit_integration}
                                                    />
                                              <ErrorMessage component="div" className="error-text" name="versionOccurence" />
                                        </Form.Group> : ""
                                    }
                                  </>
                            <div className="col-md-12 py-2">
                                 <div className="custom-control custom-checkbox">
                                    <Form.Control
                                          type="checkbox"
                                          name="errorColumn"
                                          className="custom-control-input"
                                          onChange={this.toggleChange}
                                          checked={this.state.isChecked}
                                    />
                                    <Form.Label className="custom-control-label">Store Data Issues and Errors in Qualdo™</Form.Label>
                                 </div>
                            </div>
                            {this.state.isChecked === false ?
                            <Form.Group controlId="error_storage" className="col-md-6">
                                <Form.Label>Error Storage Sub Path <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                      type="text"
                                      className="form-control"
                                      name="error_storage"
                                      placeholder="Error Storage Sub Path"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      isInvalid={errors.error_storage && touched.error_storage}
                                      value={values.error_storage}/>
                                <Form.Control.Feedback type="invalid">
                                           { touched.error_storage && errors.error_storage }
                                </Form.Control.Feedback>
                             </Form.Group> : ''}
                        </div>
                        <hr className="clearfix"/>
                        <div className="text-right">
                            {this.props.showCancel ?
                                <button type="button"
                                        onClick={() => this.props.ex_integration_details ?
                                            this.props.closeModal() :
                                            this.props.setPage(false)}
                                        className="btn btn-outline btn-grey btn-circle mr-2">
                                    Cancel
                                </button> : ''
                            }
                             <button type="submit"
                                     disabled={this.state.hideSubmitButton}
                                     className="btn btn-primary btn-circle mr-2">
                                 Test Connection
                             </button>
                        </div>
                        </Form>)}
                        </Formik>
                </>
            );
        }
}
export default AdlGen2Form;
