import React from 'react';
import QualdoDataTable from '../components/bootstrapTable';
import {METADATA_MAP_VALUE_SEPARATOR, normalizeAttributeName, normalizeChildDatasetName} from '../utils/attribute_name_utils';
import AttributeNameComp from "../components/tableCellComponents/attributeNameComp";
import {SRC_ATTRIBUTE_COLUMN, DEST_ATTRIBUTE_COLUMN} from '../utils/constant';
import ProgressBar from 'react-bootstrap/ProgressBar';

const ATTRIBUTE_NAME_COLUMNS = [SRC_ATTRIBUTE_COLUMN, DEST_ATTRIBUTE_COLUMN];

class RelationshipListView extends React.Component{
     constructor(props, context) {
        super(props, context);
        this.formTableData = this.formTableData.bind(this);
        this.renderDiscoverTableComponents = this.renderDiscoverTableComponents.bind(this);
        this.state = {

        }
     }

   renderDiscoverTableComponents(actionType, headerValue, cell) {

      if (ATTRIBUTE_NAME_COLUMNS.includes(headerValue)) {
         return (
            <AttributeNameComp tooltipPlacement={"right"}
               attributeName={cell}>
            </AttributeNameComp>
         )
      } else if(headerValue === "Relationship Score") {
         let percentage = cell.value * 100;
         return (<div className="data-progress-percentage">
                     <div className="progress">
                          <ProgressBar now={percentage} />
                     </div>
                     <h5 className="data-progress-value">{`${percentage}%`}</h5>
                </div>);
    }

      return cell;
   }

     formTableData() {
            let filteredValues = this.props.filteredValues;
            let data = this.props.dataModule.relationship[this.props.selectedDataSource]
            let metaData = this.props.dataModule.metaData
            let relationData = [];
            let finalData = [];
            if(data !== undefined) {
               if (!Array.isArray(data)) {
                  data = data.attribute;
              }
                
                relationData = data.filter((item) => item.name.startsWith(filteredValues[0]))
                for (let i=0;i<relationData.length; i++) {
                    let individualData = relationData[i]
                    let originalName = metaData[individualData.name]
                    if(metaData[individualData.name] === undefined) {
                      continue
                    }

                    originalName = originalName.split(METADATA_MAP_VALUE_SEPARATOR)
                    let sourceDataset = normalizeChildDatasetName(originalName[2]);
                    let sourceAttribute = originalName[originalName.length - 1]
                    let score = individualData.confidence_score;
                    for(let j=0;j<individualData.imports.length;j++) {
                       let tableList = []
                       let destinationMeta = metaData[individualData.imports[j]]
                       if(destinationMeta === undefined) {
                         continue
                       }
                       destinationMeta = destinationMeta.split(METADATA_MAP_VALUE_SEPARATOR)
                       let destinationDataset = normalizeChildDatasetName(destinationMeta[2])
                       let destinationAttribute = normalizeChildDatasetName(destinationMeta[destinationMeta.length - 1]);
                       sourceAttribute = normalizeAttributeName(sourceAttribute);
                       destinationAttribute = normalizeAttributeName(destinationAttribute);
                       tableList.push({"value": sourceDataset, "type": "td"});
                       tableList.push({"value": sourceAttribute, "type": "td"});
                       tableList.push({"value": destinationDataset, "type": "td"});
                       tableList.push({"value": destinationAttribute, "type": "td"});
                       tableList.push({"value": score, "type": "progressBar"});
                       finalData.push(tableList);
                    }


                }
            }
            return finalData;



     }

     render() {
        let tableList = this.formTableData()
        let tableData = {"headers": ["Source Dataset", SRC_ATTRIBUTE_COLUMN, "Destination Dataset", 
                                     DEST_ATTRIBUTE_COLUMN, "Relationship Score"], 
                          "data": tableList
                        }

        return (
           <QualdoDataTable
              customGetActionComponent={this.renderDiscoverTableComponents}
              data={tableData}
              component_name='discover'
           />
        );
     }

}

export default RelationshipListView;