import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartArea} from "@fortawesome/free-solid-svg-icons";

class NoErrorComponent extends React.Component {

    constructor(props, context) {
        super(props, context);
        let message = this.props.message;
        if (message === undefined || message === null) {
            message = "No Error found in the latest computation.";
        }

        let footer = this.props.footer;
        if (footer === undefined || footer === null) {
            footer = "Please check after sometime."
        }
        this.state = {
            message: message,
            footer: footer
        }
    }

    render() {
        return (
            <div className="text-center text-muted py-5 w-100">
                <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                <h4>{this.state.message}</h4>
                <p>{this.state.footer}</p>
            </div>
        );
    }
}

export default NoErrorComponent;
