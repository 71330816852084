import React from 'react';
import Image from './image';
import {removeLocalStorageKeys, checkExpiryPowerBI, getProtocol} from "../utils/common_utils";
import {browserHistory} from 'react-router';


class ErrorHandler extends React.Component {
     constructor(props) {
        super(props);
        this.goToLogin = this.goToLogin.bind(this);
   }
    componentWillUnmount() {
         var element = document.getElementById("root");
         element.classList.add("main-wrap");
    }

    goToLogin() {
        removeLocalStorageKeys();
        if(checkExpiryPowerBI()===false){
        browserHistory.push('/');
        }else{
         let powerbiip = process.env.REACT_APP_POWERBI_BASE_URL;
         let protocol = getProtocol(powerbiip);
         window.location = protocol+'//'+powerbiip;
        }
    }

    render(){
        var element = document.getElementById("root");
        element.classList.remove("main-wrap");
        return (
           <>
              <div className="opps-wrap">
                 <div className="opps-content">
                    <div className="opps-infographic">
                        <Image className="opps-infographic_human" src="oopsHuman" />
                        <Image className="opps-infographic_globe" src="oopsGlobe" />
                    </div>
                    <div className="opps-text">
                      <h3>Oops! Something went wrong…</h3>
                      <p>Go back to the previous page and try again. If you think something is broken, report a problem.</p>
                    </div>
                    <div className="opps-button">
                      <button onClick={this.goToLogin} className="btn btn-primary btn-lg btn-circle shadow">Back to home</button>
                    </div>
                 </div>
              </div>
           </>
        );
    }
}

export default ErrorHandler;
